import { useEffect, useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import InputAdornment from "@mui/material/InputAdornment";
import { Typography } from "@mui/material";

const SeasonPicker = ({ size, startText, controlledValue, setControlledValue  }) => {

  const [value, setValue] = useState(
    dayjs.utc(`${dayjs().year()}-${controlledValue}`) || dayjs.utc());
  
  useEffect(
    ()=>{
      if (setControlledValue && typeof setControlledValue === 'function') {
        if(value){
          setControlledValue(value.format('MM-DD'))
        }
      }
    },
    [value]
  )

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        views={["month", "day"]}
        format="MMM DD"
        value={value}
        onChange={(newValue) => setValue(newValue)}
        slotProps={{
          textField: (props) => ({
            InputProps: {
              ...props.InputProps,
              startAdornment: startText ? (
                <InputAdornment position="start" sx={{ marginRight: "2px" }}>
                  <Typography fontWeight={600} sx={{ color: "#000" }}>
                    {startText}
                  </Typography>
                </InputAdornment>
              ) : null,
            },
          }),
        }}
        sx={{
          "& input": {
            fontSize: size === "small" ? "15px" : "16px",
            padding: size === "small" ? "7.2px 8px 7.2px !important" : "11.5px 12px",
            "&::placeholder": { fontSize: "12px" },
          },
          "& svg": {
            width: size === "small" ? "20px" : "24px",
            height: size === "small" ? "20px" : "24px",
          },
          "& button": { padding: size === "small" ? "4px" : "8px" },
          "& .MuiInputAdornment-positionEnd": { marginLeft: size === "small" ? "-4px" : "8px" },
        }}
      />
    </LocalizationProvider>
  );
};

export default SeasonPicker;
