import api from "./api";

export async function listRoutesTwo() {
  const result = await api.get("/api/routes2/routes/?page_size=50");
  return result;
}
export async function getRoute(id) {
  const result = await api.get(`/api/routes2/routes/${id}/`);
  return result;
}

export async function createRoute(payload) {
  const result = await api.post("/api/routes2/routes/", payload);
  return result;
}

export async function updateRoute(id, payload) {
  const result = await api.patch(`/api/routes2/routes/${id}/`, payload);
  return result;
}

export async function routesReports(date) {
  const result = await api.get(
    `/api/routes2/route_reports/?date=${date}&page_size=50`
  );
  return result;
}
export async function emailRouteReports(date) {
  const result = await api.get(
    `/api/routes2/email_route_reports/?date=${date}`
  );
  return result;
}
export async function getReport(id) {
  const result = await api.get(`/api/routes2/route_reports/?id=${id}`);
  return result;
}

export async function markPickUp(id, qty) {
  const result = await api.patch(`/api/routes2/pick_sheet_item/${id}/`, {
    picked_up: qty,
  });
  return result;
}
export async function bulkPatchPickSheets(data) {
  const result = await api.post(`/api/routes2/bulk_patch`, data);
  return result;
}
export async function updateHandover(id, payload) {
  const result = await api.patch(`/api/routes2/handovers/${id}/`, payload);
  return result;
}

export async function compatibleRoutesToPickFor(route_vendor_id) {
  const res = await api.get(
    `/api/routes2/can_pick_for/?route_from=${route_vendor_id}`
  );
  return res;
}

export async function createRoutePickUp(from_route_vendor, to_route) {
  const res = await api.post(`/api/routes2/routes_pickups/`, {
    pick_up_from: from_route_vendor,
    pick_up_for: to_route,
  });
  return res;
}

export async function deleteRoutePickUp(routePickUpId) {
  const res = await api.delete(`/api/routes2/routes_pickups/${routePickUpId}/`);
  return res;
}

export async function bulkPatch(data) {
  const res = await api.post(`/api/orders/bulk_patch/`, data);
  return res;
}

export async function explainMissingRoutes(data) {
  const res = await api.post(`/api/routes2/no_routes_explain/`, data);
  return res;
}
