import { Link, useNavigate } from "react-router-dom";
import useAppDispatch from "../../hooks/useAppDispatch";
import { IconButton, Typography } from "@mui/material";
import { CheckCircleOutlineRounded, HighlightOff } from "@mui/icons-material";
import { DATE_FORMAT_FRONT } from "../../utils/constants";
import dayjs from "dayjs";
import ConfirmDialog from "../../components/ConfirmDialog";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import ChangesHistory from "../../components/History";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import React, { useState } from "react";
import VendorCategoryOverrideForm from "./VendorCategoryOverrideForm";
import { deleteVendorCategoryOverride } from "../../redux/module/override";
import TableButtons from "../../components/TableComponents/TableButtons";

function VendorRenderer(params) {
  return (
    <span className="my-renderer">
      <Link
          to={`/vendors/?name=${params.data.vendor.name}`}
        style={{ textDecoration: "none" }}
      >
        {params.data.vendor.name}
      </Link>
    </span>
  );
}
function CategoriesRenderer(params) {
  return (
    <span className="my-renderer">
      <Link
          to={`/categories/?name=${params.data.category.name}`}
        style={{ textDecoration: "none" }}
      >
        {params.data.category.name}
      </Link>
    </span>
  );
}

function OverrideLinkRenderer(props) {
  const dispatch = useAppDispatch();

  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [open, setOpen] = useState(false);
  
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  
  const handleDelete = async () => {
    await dispatch(deleteVendorCategoryOverride(props.data.id));
  };

  const handleEdit = () => {
    handleOpen()
  }
 
  
  return (
    <>      
      <VendorCategoryOverrideForm open={open} handleClose={handleClose} override={props.data} />
      <TableButtons
        handleEdit={handleEdit}
        handleDelete={handleDelete}
        deletePopupEntity="user"
        historyData={props.data.history || []}
      />    
    </>
  );
}

const OverrideActiveRenderer = (params) => {
  return <Typography sx={{ color: "#1D1C1C", fontSize: "14px", margin: "0 auto" }}>
    {params.data.is_current ? <CheckCircleOutlineRounded color="success" /> : <HighlightOff color="error" />}
  </Typography>;
};

const status =  {
  headerName: "Status",
  field: "is_current",
  cellRenderer: OverrideActiveRenderer,
  minWidth: 80,
  maxWidth: 80,
};

const vendor_name = {
  headerName: "Vendor",
  field: "vendor.name",
  wrapText: true,
  autoHeight: true,
  cellRenderer: VendorRenderer,
  minWidth: 200,
};
const category_name = {
  headerName: "Category",
  field: "category.name",
  wrapText: true,
  autoHeight: true,
  cellRenderer: CategoriesRenderer,
  minWidth: 200,
};


export const start_date = {
  headerName: "Start date",
  field: "start_date",
  autoHeight: true,
  valueGetter: (params) => `${params.data.start_date ? dayjs.utc(params.data.start_date).format(DATE_FORMAT_FRONT) : "---"}`,
  
  minWidth: 100,
};
export const end_date = {
  headerName: "End date",
  field: "end_date",
  autoHeight: true,
  valueGetter: (params) => `${params.data.end_date ? dayjs.utc(params.data.end_date).format(DATE_FORMAT_FRONT) : "---" }`,
  minWidth: 100,
};
export const override = {
  headerName: "Override",
  field: "override",
  autoHeight: true,
  valueGetter: (params) => `${params.data.override}%`,
  minWidth: 100,
};


const override_link = {
  field: "",
  sorting: false,
  sort: false,
  sortable: false,
  autoHeight: true,
  filter: false,
  cellRenderer: OverrideLinkRenderer,
  minWidth: 120,
  maxWidth: 120,
  
};

const vendorCategoryOverrideTableColumns = [status, vendor_name, category_name, override, start_date, end_date, override_link];

export default vendorCategoryOverrideTableColumns;
