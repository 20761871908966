import { Navigate, Outlet, useResolvedPath } from "react-router-dom";
import useAppSelector from "../hooks/useAppSelector";

const AuthLayout = () => {
  const authUser = useAppSelector((state) => state.user.authUser);
  const isInitialized = useAppSelector((state) => state.user.isInitialized);
  const { pathname } = useResolvedPath();

  if (isInitialized && authUser) {
    return <Navigate to="/orders" />;
  }

  if (pathname === "/auth/otp?") {
    return <Outlet />;
  }

  if (pathname === "/auth") {
    return <Navigate to="/auth/login" />;
  }

  return <Outlet />;
};

export default AuthLayout;
