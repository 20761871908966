import { useEffect, useState } from "react";
import { RouterProvider } from "react-router-dom";
import { ThemeProvider, Snackbar, Alert } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import ModalProvider from "mui-modal-provider";
import { router } from "./routing";
import useAppDispatch from "./hooks/useAppDispatch";
import { fetchAuthUser } from "./redux/module/user";
import theme from "./theme";
import { SnackbarProvider } from "notistack";
//import rg4js from "raygun4js";
import { useSelector } from "react-redux";
import Smartlook from "smartlook-client";

//rg4js("apiKey", "83XeBItypJ9Zs1UUuWUCpQ");
//rg4js("enableCrashReporting", true);
//rg4js("enablePulse", true);

const App = () => {
  const dispatch = useAppDispatch();
  const { error } = useSelector((state) => state.error);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  useEffect(() => {
    Smartlook.init("6ca537ea798f90f9df3f3940c68132a6b6eb459f");
    Smartlook.resume();
    console.log("recording url: ", Smartlook.playUrl);
    Smartlook.record({
      emails: true,
      forms: true,
      numbers: false,
    });
  }, []);
  const handleSnackbarClose = (e) => {
    setSnackbarOpen(false);
    dispatch({ type: "error/clearError" });
  };

  useEffect(() => {
    dispatch(fetchAuthUser());
    console.log("is initialized", Smartlook.initialized());
    console.log("recording url: ", Smartlook.playUrl);
  }, [dispatch]);

  useEffect(() => {
    if (error) setSnackbarOpen(true);
  }, [error]);

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        classes={{ containerRoot: "z-alert" }}
        maxSnack={5}
        preventDuplicate
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
        SnackbarProps={{ style: { top: 64 } }}
      >
        <CssBaseline />
        <ModalProvider>
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={5000}
            onClose={handleSnackbarClose}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            sx={{ zIndex: 9999 }}
          >
            <Alert
              onClose={handleSnackbarClose}
              severity={"error"}
              sx={{ width: "100%" }}
            >
              {JSON.stringify(`${error}`).length > 5000
                ? "ERROR OCCURRED"
                : JSON.stringify(`${error}`)}
            </Alert>
          </Snackbar>

          <RouterProvider router={router} />
        </ModalProvider>
      </SnackbarProvider>
    </ThemeProvider>
  );
};

export default App;
