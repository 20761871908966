import React, { useState } from "react";
import {
  IconButton,
  ImageListItem,
  ImageListItemBar,
  Typography,
} from "@mui/material";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import ConfirmDialog from "../../components/ConfirmDialog";
import {
  deleteProductImage,
  deleteProductVariantImage,
} from "../../distflowAPI/products";

const ProductImageListItem = ({
  productId,
  variantId,
  item,
  setImages,
  fetchItem,
  type,
}) => {
  const [confirmOpen, setConfirmOpen] = useState(false);

  const handleDelete = async () => {
    if (item.id) {
      if (type === "product") {
        await deleteProductImage(productId, item.id);
      } else {
        await deleteProductVariantImage(productId, variantId, item.id);
      }
      await fetchItem();
    } else {
      setImages((prev) => prev.filter((image) => image.name !== item.name));
    }
  };

  return (
    <>
      <ConfirmDialog
        title="Delete image?"
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={handleDelete}
      >
        Are you sure you want to delete this image? This action can't be undone!
      </ConfirmDialog>
      <ImageListItem
        style={{ border: "1px solid #DBDFEA", borderRadius: "5px" }}
      >
        <PreviewableImage src={`${item.image || item.preview}`} />

        <ImageListItemBar
          actionIcon={
            <IconButton
              sx={{ color: "rgba(255, 255, 255, 0.54)" }}
              onClick={() => {
                setConfirmOpen(true);
              }}
            >
              <DeleteForeverOutlinedIcon sx={{ color: "white" }} />
            </IconButton>
          }
        />
      </ImageListItem>
    </>
  );
};

export default ProductImageListItem;
function PreviewableImage({ src }) {
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);

  const togglePreview = () => {
    setIsPreviewOpen(!isPreviewOpen);
  };

  return (
    <>
      {/* Main Image */}
      <img
        src={src}
        loading="lazy"
        onClick={togglePreview}
        alt="Previewable content"
        style={{
          cursor: "pointer",
          overflow: "hidden",
          objectPosition: "center",
          maxWidth: "100%", // Ensure it fits the container
        }}
      />

      {/* Modal for Fullscreen Preview */}
      {isPreviewOpen && (
        <div
          onClick={togglePreview} // Close modal when clicking outside the image
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.8)", // Dark background
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 1000,
            cursor: "pointer",
          }}
        >
          <img
            src={src}
            style={{
              width: "80vw", // 80% of viewport width
              height: "80vh", // 80% of viewport height
              objectFit: "contain",
              cursor: "auto", // Default cursor over the image
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
              borderRadius: "8px",
            }}
            alt="Fullscreen preview"
          />
        </div>
      )}
    </>
  );
}
