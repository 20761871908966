import CloseIcon from "@mui/icons-material/Close";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { Box, Dialog, Typography } from "@mui/material";
import dayjs from "dayjs";
import { downloadFile } from "../../distflowAPI/downloadFile";
const weekOfYear = require("dayjs/plugin/weekOfYear");
dayjs.extend(weekOfYear);

const style = { padding: "30px", height: "30rem", mb: "20px" };

export function EmailDetails({ row, onClose }) {
  return (
    <Dialog open={true} onClose={onClose} fullWidth maxWidth="lg">
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "10px",
          padding: "20px 30px",
        }}
      >
        <Typography
          variant="body1"
          sx={{ fontWeight: 600, fontSize: "1.5rem" }}
        >
          Email Details
        </Typography>
        <CloseIcon
          onClick={onClose}
          sx={{ cursor: "pointer", fontSize: "1rem" }}
        />
      </Box>
      <Box sx={{ borderBottom: "1px solid #f6f6f7" }}></Box>
      <Box sx={style}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            mb: "2rem",
          }}
        >
          <Typography
            variant="body1"
            sx={{ fontWeight: 600, fontSize: "1.5rem" }}
          >
            Subject: {row.subject}
          </Typography>
          <Typography
            sx={{
              textAlign: "right",
              display: "flex",
              alignItems: "flex-end",
              fontWeight: 600,
              color: "#6a6a6a",
              fontSize: ".7rem",
            }}
          >
            {dayjs(row.created_at).format("ddd, D MMM YYYY h:mm A")}
          </Typography>
        </Box>
        <Box
          sx={{
            height: "20px",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <Typography variant="body1">
            Recipients:
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                gap: "6px",
                mt: "10px",
              }}
            >
              {row.recipients.split(",").map((recipient, index) => {
                return (
                  <Typography
                    key={index}
                    variant="body2"
                    sx={{
                      textDecoration: "underline",
                      cursor: "pointer",
                      color: "#3f51b5",
                      padding: "5px 10px",
                      backgroundColor: "#f6f6f7",
                      borderRadius: "5px",
                      width: "fit-content",
                    }}
                  >
                    {recipient}
                  </Typography>
                );
              })}
            </Box>
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              mt: "10px",
              mb: "10px",
            }}
          >
            <Typography variant="body1">
              <b>Message:</b>
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                border: "1px solid #f6f6f7",
                padding: "10px",
                borderRadius: "5px",
                backgroundColor: "#f6f6f7",
              }}
            >
              <div dangerouslySetInnerHTML={{ __html: row.message }} />
            </Box>
          </Box>

          {row.attachements.length > 0 ? (
            row.attachements.map((attachment, index) => {
              return (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "4px",
                    padding: "10px",
                    paddingRight: "20px",
                    borderRadius: "5px",
                    border: "1px solid #e3e3e3",
                    backgroundColor: "#f6f6f7",
                    transition: "all 0.3s",
                    width: "fit-content",
                    "&:hover": {
                      backgroundColor: "#dedddd",
                    },
                  }}
                  onClick={
                    () => downloadFile(attachment.file, attachment.filename) // works on production
                  }
                >
                  <AttachFileIcon />
                  <Typography variant="body1">{attachment.filename}</Typography>
                </Box>
              );
            })
          ) : (
            <Typography variant="body1">No Attachements</Typography>
          )}
        </Box>
      </Box>
    </Dialog>
  );
}
