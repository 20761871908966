import {
  Typography,
  FormGroup,
  FormControlLabel,
  TextField,
  Checkbox,
  FormControl,
  FormLabel,
  FormHelperText,
  RadioGroup,
  Radio,
  Box,
} from "@mui/material";
import Block from "../../components/Block";
import useAppDispatch from "../../hooks/useAppDispatch";
import useAppSelector from "../../hooks/useAppSelector";
import {
  setChangeDates,
  setIsRangeChange,
  setNote,
  setSelectedDays,
} from "../../redux/module/addRouteChanges";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

const weekdays = [
  { key: "is_sunday", label: "Sun" },
  { key: "is_monday", label: "Mon" },
  { key: "is_tuesday", label: "Tue" },
  { key: "is_wednesday", label: "Wed" },
  { key: "is_thursday", label: "Thu" },
  { key: "is_friday", label: "Fri/Erev" },
];

const AddRouteScheduleForm = () => {
  const dispatch = useAppDispatch();
  const note = useAppSelector((state) => state.addRouteChanges.note);
  const isRangeChange = useAppSelector(
    (state) => state.addRouteChanges.isRangeChange
  );
  const changeDates = useAppSelector(
    (state) => state.addRouteChanges.changeDates
  );
  const selectedDays = useAppSelector(
    (state) => state.addRouteChanges.selectedDays
  );
  const routeToEdit = useAppSelector(
    (state) => state.addRouteChanges.routeToEdit
  );
  const errors = useAppSelector((state) => state.addRouteChanges.formErrors);

  const now = dayjs();

  return (
    <Block>
      <Typography
        fontSize="20px"
        fontWeight={500}
        sx={{ color: "#000", mb: "15px" }}
      >
        Schedule
      </Typography>
      <FormControl fullWidth sx={{ marginBottom: 1 }}>
        <RadioGroup
          row
          onChange={(e) =>
            dispatch(setIsRangeChange(e.target.value === "range"))
          }
          value={isRangeChange ? "range" : "specificDate"}
        >
          <FormControlLabel
            value="specificDate"
            control={<Radio />}
            label="Specific Date"
          />
          <FormControlLabel value="range" control={<Radio />} label="Range" />
        </RadioGroup>
      </FormControl>
      <FormControl fullWidth sx={{ marginBottom: 3 }}>
        <FormLabel sx={{ marginBottom: "-4px" }}>Days</FormLabel>
        <FormGroup row>
          {weekdays.map((item) => (
            <FormControlLabel
              key={item.key}
              control={<Checkbox />}
              label={item.label}
              checked={selectedDays[item.key]}
              onChange={(e) =>
                dispatch(setSelectedDays({ [item.key]: e.target.checked }))
              }
            />
          ))}
        </FormGroup>
        {errors.selectedDays && (
          <FormHelperText error>{errors.selectedDays}</FormHelperText>
        )}
      </FormControl>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: { xs: "1fr", sm: "1fr 1fr" },
          gap: "8px 22px",
          mb: 4,
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          {isRangeChange ? (
            <>
              <FormControl>
                <FormLabel>Start Date</FormLabel>
                <DatePicker
                  minDate={routeToEdit ? dayjs(routeToEdit.start_date) : now}
                  value={dayjs(changeDates.start)}
                  onChange={(start) =>
                    dispatch(setChangeDates({ start: start.toISOString() }))
                  }
                />
              </FormControl>
              <FormControl>
                <FormLabel>End Date</FormLabel>
                <DatePicker
                  minDate={now}
                  value={dayjs(changeDates.end)}
                  onChange={(end) =>
                    dispatch(setChangeDates({ end: end.toISOString() }))
                  }
                />
              </FormControl>
            </>
          ) : (
            <FormControl>
              <FormLabel>Date</FormLabel>
              <DatePicker
                minDate={now}
                value={dayjs(changeDates.start)}
                onChange={(start) =>
                  dispatch(setChangeDates({ start: start.toISOString() }))
                }
              />
            </FormControl>
          )}
        </LocalizationProvider>
      </Box>
      <FormControl fullWidth sx={{ marginBottom: 3 }}>
        <FormLabel>Note</FormLabel>
        <TextField
          rows="4"
          multiline
          data-testid="test-note-id"
          value={note}
          onChange={(e) => dispatch(setNote(e.target.value))}
        />
      </FormControl>
    </Block>
  );
};

export default AddRouteScheduleForm;
