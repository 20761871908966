import React, { useState, useEffect } from "react";
import { Button, Typography, Stack, Box } from "@mui/material";
import DataTable from "../../../components/DataTable";
import { NavLink, useSearchParams } from "react-router-dom";
import routeTableColumns from "./routesTableColumns";
import useAppSelector from "../../../hooks/useAppSelector";
import {
  selectRouteNumberOfPages,
  cleanTableSettings,
  fetchRoutes, setRoutesTableSettings
} from "../../../redux/module/route";
import useAppDispatch from "../../../hooks/useAppDispatch";
import { formatAgGridFilters, formatAgGridSorting } from "../../../distflowAPI/common";
import RoutesFilterBlock from "./RoutesFilterBlock";
import PageLoading from "../../../components/PageLoading";

const defaultColDef = {
  flex: 1,
  filter: false,
  floatingFilter: false,
};

const RoutesTwo = () => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  
  const {routes, pageSize, page, orderBy, routesLoading} = useAppSelector((state) => state.route);
  const numberOfPages = useAppSelector(selectRouteNumberOfPages);

  useEffect(() => {
    dispatch(fetchRoutes({ page, pageSize, filterBy: [{column: "is_change", value: false}], orderBy, searchParams }));
  }, [pageSize, page, orderBy, searchParams]);

  
  const handleChange = (field, value) => {
    if (field === "filterBy" || field === "pageSize") {
      dispatch(setRoutesTableSettings({ field: "page", value: 1 }));
    }
    dispatch(setRoutesTableSettings({ field, value }));
  };

  useEffect(() => {
    return () => {
      dispatch(cleanTableSettings())
    }
  }, []);

  return (
    <>
      <PageLoading dataLoading={routesLoading} />
      
      <Typography fontSize="23px" fontWeight={800} component="h2" data-testid="page-title">
        Routes
      </Typography>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        alignItems={{ xs: "space-between", sm: "center" }}
        justifyContent="space-between"
        spacing={1.5}
        my="25px"
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          spacing="15px"
        >
          <Button component={NavLink} to="/routes2/routes/new" variant="contained">
            Add New
          </Button>
        </Stack>
      </Stack>

      <RoutesFilterBlock />
      
      <Box className="table-wrapper">
        <DataTable
          rowData={routes}
          columnDefs={routeTableColumns}
          defaultColDef={defaultColDef}
          suppressSorting={true} //disable default sorting
          loading={routesLoading}
          rowHeight={53}
          headerHeight={60}
          page={page}
          pageSize={pageSize}
          setPageSize={(pageSize) => handleChange("pageSize", pageSize)}
          noPages={numberOfPages}
          setPage={(page) => handleChange("page", page)}
        />
      </Box>
    </>
  );
};

export default RoutesTwo;
