import DataTable from "../../components/DataTable";
import {
  Box,
  Stack,
  Typography,
  Button,
  RadioGroup,
  Radio,
  FormControlLabel,
  Alert,
  Grid,
  Input,
  IconButton,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  formatAgGridFilters,
  formatAgGridSorting,
} from "../../distflowAPI/common";
import { Link, useSearchParams } from "react-router-dom";
import PageLoading from "../../components/PageLoading";
import {
  cCimportBankTransactions,
  cClistBankTransactions,
  cClistBankTransactionsBatches,
  cCupdateBankDeposit,
  cCupdateCheckBatch,
} from "../../distflowAPI/ordersApi";
import Block from "../../components/Block";
import DatePicker from "../../components/DatePicker2";
import CCMatchingTransactionsModal from "./MatchingTransactionsModal";
import {
  Cancel,
  CancelOutlined,
  Check,
  Edit,
  Save,
  SaveOutlined,
} from "@mui/icons-material";
import dayjs from "dayjs";
import { smallButton } from "../../utils/smallButton";

const defaultColDef = {
  flex: 1,
  filter: false,
  floatingFilter: false,
};
function BatchNameRenderer({ params }) {
  const [editing, setEditing] = useState(false);
  const [newName, setNewName] = useState(
    params.data.name || `Batch #${params.data.id}`
  );
  return (
    <Stack direction={"row"} spacing={1}>
      {editing ? (
        <Stack direction={"row"} spacing={1}>
          <TextField
            value={newName}
            onChange={(e) => setNewName(e.target.value)}
          />
          <IconButton
            onClick={() => {
              cCupdateCheckBatch(params.data.id, { name: newName }).then(
                (res) => {
                  setEditing(false);
                }
              );
            }}
          >
            <SaveOutlined />
          </IconButton>
          <IconButton
            onClick={() => {
              setNewName(params.data.name);
              setEditing(false);
            }}
          >
            <CancelOutlined />
          </IconButton>
        </Stack>
      ) : (
        <Stack direction={"row"} spacing={1}>
          <Typography>
            <Link to={`/invoices/cc_bank_transactions_batch/${params.data.id}`}>
              {newName}
            </Link>
          </Typography>
          <IconButton onClick={() => setEditing(true)}>
            <Edit
              style={{
                maxWidth: "20px",
                maxHeight: "18px",
              }}
            />
          </IconButton>
        </Stack>
      )}
    </Stack>
  );
}
const columnsBatches = [
  {
    headerName: "Batch",
    field: "name",
    maxWidth: 550,
    minWidth: 300,
    cellRenderer: (params) => <BatchNameRenderer params={params} />,
    sortable: true,
    comparator: () => {},
  },
  {
    headerName: "Uploaded At",
    field: "created",
    maxWidth: 250,
    sortable: true,
    comparator: () => {},
    valueGetter: (params) => dayjs(params.data.created).format("MMMM DD, YYYY"),
  },
  {
    headerName: "Total transactions",
    field: "transactions",
    maxWidth: 200,
    sortable: true,
    comparator: () => {},
  },
  {
    headerName: "Matched",
    field: "matched_transactions",
    maxWidth: 200,
    sortable: true,
    comparator: () => {},
  },
  {
    headerName: "Non Matched",
    field: "unmatched_transactions",
    maxWidth: 200,
    sortable: true,
    comparator: () => {},
  },
  {
    headerName: "Total $",
    field: "total_deposits",
    maxWidth: 200,
    sortable: true,
    comparator: () => {},
  },
  {
    headerName: "Matched $",
    field: "total_matches",
    maxWidth: 250,
    sortable: true,
    comparator: () => {},
  },
  {
    headerName: "Non Matched $",
    valueGetter: (params) =>
      (params.data.total_deposits - params.data.total_matches).toFixed(2),
    maxWidth: 250,
    sortable: true,
    comparator: () => {},
  },
  {
    headerName: "Uploaded By",
    valueGetter: (params) =>
      `${params.data.uploaded_by.first_name} ${params.data.uploaded_by.last_name}`,
    field: "uploaded_by",
    sortable: true,
    comparator: () => {},
  },
  {
    headerName: "status",
    cellRenderer: (params) =>
      params.data.total_deposits - params.data.total_matches > 1 ? (
        <Typography color={"red"}>INCOMPLETE</Typography>
      ) : (
        <Typography color={"green"}>COMPLETE</Typography>
      ),
    maxWidth: 250,
    sortable: true,
    comparator: () => {},
  },
];
function NoteRenderer(params) {
  const [isEditing, setIsEditing] = useState(false);
  const [val, setVal] = useState(params.data.note);
  const [note, setNote] = useState(val);
  function updateNote() {
    cCupdateBankDeposit(params.data.id, { note }).then((res) => {
      setVal(note);
      setIsEditing(false);
    });
  }
  return (
    <Stack
      direction={"row"}
      justifyContent="space-between"
      alignItems={"center"}
    >
      {isEditing ? (
        <>
          <Input value={note} onChange={(e) => setNote(e.target.value)} />
          <IconButton onClick={() => updateNote()}>
            <Check />
          </IconButton>
          <IconButton
            onClick={() => {
              setNote(val);
              setIsEditing(false);
            }}
          >
            <Cancel />
          </IconButton>
        </>
      ) : (
        <>
          <Typography>{note || "-"}</Typography>
          <IconButton onClick={() => setIsEditing(true)}>
            <Edit />
          </IconButton>
        </>
      )}
    </Stack>
  );
}
export default function CCBankTransactions() {
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [statements, setStatements] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [orderBy, setOrderBy] = useState([]);
  const [filterBy, setFilterBy] = useState([]);
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [view, setView] = useState("BATCH");
  const [batches, setBatches] = useState([]);
  const [filters, setFilters] = useState({
    matched: null,
    date__gte: null,
    date__lte: null,
    amount__gte: null,
    amount__lte: null,
  });
  const [open, setOpen] = useState(false);
  useEffect(() => {
    let newFilterBy = "";
    let cc = 0;
    Object.keys(filters).map((fk, fi) => {
      if (filters[fk]) {
        let filterVal = filters[fk];
        if (fk.includes("date")) {
          filterVal = filterVal.format("YYYY-MM-DD");
        }
        newFilterBy += `${cc > 0 ? "&" : ""}${fk}=${filterVal}`;
        cc++;
      }
    });
    setFilterBy(newFilterBy);
  }, [filters]);
  function updateStatementTable() {
    if (view == "TRANSACTIONS") {
      setIsLoading(true);
      cClistBankTransactions(page, pageSize, orderBy, filterBy).then((res) => {
        const npages = parseInt(res.data.count / pageSize);
        if (npages == res.data.count) {
          setNumberOfPages(npages);
        } else {
          setNumberOfPages(npages + 1);
        }
        setStatements(res.data.results);
        setIsLoading(false);
      });
    }
    if (view == "BATCH") {
      setIsLoading(true);
      cClistBankTransactionsBatches(page, pageSize, orderBy, filterBy).then(
        (res) => {
          const npages = parseInt(res.data.count / pageSize);
          if (npages == res.data.count) {
            setNumberOfPages(npages);
          } else {
            setNumberOfPages(npages + 1);
          }
          setBatches(res.data.results);
          setIsLoading(false);
        }
      );
    }
  }
  useEffect(() => {
    updateStatementTable();
  }, [page, filterBy, orderBy]);
  useEffect(() => {
    if (page == 1) {
      updateStatementTable();
    }
    setPage(1);
  }, [pageSize, view]);

  const handleChange = (field, value) => {
    if (field === "filterBy" || field === "pageSize") {
      setPage(1);
    }
    setFilterBy({ field, value });
  };

  const getRowStyle = (params) => {
    if (params.data.status === "VOIDED") {
      return { background: "#fff0f0" };
    }
  };
  const [csvFiles, setCsvFiles] = useState();
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  function handleSubmit() {
    setError("");
    setSuccess("");
    if (!csvFiles) {
      setError("Include a valid file");
    }

    const payload = new FormData();
    for (let i = 0; i < csvFiles.length; i++) {
      console.log("file is: ", csvFiles[i]);
      payload.append(`csv_files.${i}`, csvFiles[i], csvFiles[i].name);
    }
    cCimportBankTransactions(payload).then(
      (res) => {
        setSuccess(
          `Upload complete, items imported: ${res.data.total_created}`
        );
        if (res.data.errors.length > 0) {
          setError(`Total errors: ${res.data.errors.length}`);
        }
      },
      (err) => setError("Error importing data")
    );
  }
  const columns = [
    {
      headerName: "Date",
      field: "date",
      maxWidth: 150,
      sortable: true,
      comparator: () => {},
    },
    {
      headerName: "Amount",
      field: "amount",
      maxWidth: 150,
      sortable: true,
      comparator: () => {},
    },
    {
      headerName: "Account No",
      field: "account_number",
      maxWidth: 150,
      sortable: true,
      comparator: () => {},
    },
    {
      headerName: "Bank Id",
      field: "bank_id",
      maxWidth: 150,
      sortable: true,
      comparator: () => {},
    },
    {
      headerName: "Check Number",
      field: "check_number",
      maxWidth: 150,
      sortable: true,
      comparator: () => {},
    },
    {
      headerName: "Note",
      field: "note",
      cellRenderer: NoteRenderer,
    },
    {
      headerName: "Matched Payment",
      field: "payment",
      maxWidth: 250,
      cellRenderer: (params) => {
        console.log("params", params);
        return (
          <>
            {params.node.data.payment && (
              <Stack direction={"row"} spacing={2} alignItems={"center"}>
                <Typography>
                  <Link
                    to={`/invoices/payments/${params.node.data.payment_group}/details`}
                  >
                    #{params.node.data.payment}
                  </Link>
                </Typography>
                <Button
                  onClick={() => {
                    cCupdateBankDeposit(params.node.data.id, { payment: null });
                    updateStatementTable();
                  }}
                  variant="outlined"
                >
                  Unmatch
                </Button>
              </Stack>
            )}
            {!params.node.data?.payment && (
              <Stack direction={"row"} spacing={2} alignItems={"center"}>
                <Button
                  variant="outlined"
                  onClick={() => handleOpen(params.rowIndex)}
                  className="MuiButton-sizeSmall"
                >
                  Match ({params.node.data.cc_possible_matches.length} P.M)
                </Button>
              </Stack>
            )}
          </>
        );
      },
      sortable: true,
    },
    {
      headerName: "Auto?",
      maxWidth: 90,
      valueGetter: (params) =>
        params.node.data.payment
          ? params.node.data.manual_match
            ? "No"
            : "Yes"
          : "-",
    },
  ];
  function handleOpen(rowIndex) {
    setSelectedTransaction(rowIndex);
    setOpen(true);
  }

  return (
    <>
      <PageLoading dataLoading={isLoading} />
      <Stack direction={"row"} spacing={4} justifyContent={"space-between"}>
        <Stack>
          <CCMatchingTransactionsModal
            statements={statements}
            selectedTrans={selectedTransaction}
            open={open}
            setOpen={setOpen}
          />
          <Typography
            fontSize="23px"
            fontWeight={800}
            component="h2"
            data-testid="page-title"
          >
            CC Transactions
          </Typography>
        </Stack>

        {success && <Alert>{success}</Alert>}
        {error && <Alert severity="error">{error}</Alert>}
      </Stack>
      <Block>
        <Grid container spacing={3}>
          <Grid item md="12">
            <Stack direction="row" justifyContent={"space-between"}>
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                spacing={3}
              >
                <RadioGroup
                  row
                  value={view}
                  onChange={(e) => setView(e.target.value)}
                >
                  <FormControlLabel
                    value="BATCH"
                    control={<Radio />}
                    label="batch uploads"
                  />
                  <FormControlLabel
                    value="TRANSACTIONS"
                    control={<Radio />}
                    label="bank transactions"
                  />
                </RadioGroup>
                {view == "BATCH" && (
                  <>
                    <DatePicker
                      controlledValue={filters.date__gte}
                      setControlledValue={(e) =>
                        setFilters({ ...filters, date__gte: e })
                      }
                      label={"Created After"}
                      height={33}
                    />
                    <DatePicker
                      controlledValue={filters.date__lte}
                      setControlledValue={(e) =>
                        setFilters({ ...filters, date__lte: e })
                      }
                      label={"Created Before"}
                      height={33}
                    />
                  </>
                )}
                {view == "TRANSACTIONS" && (
                  <>
                    <RadioGroup
                      row
                      value={filters.matched}
                      onChange={(e) =>
                        setFilters({ ...filters, matched: e.target.value })
                      }
                    >
                      <FormControlLabel
                        value={null}
                        control={<Radio />}
                        label="All"
                      />
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label="Matched"
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label="Non Matched"
                      />
                    </RadioGroup>
                  </>
                )}
              </Stack>
              <Stack direction={"row"} sx={{ border: "1px" }}>
                <Box>
                  <Typography>Import bank statement</Typography>
                  <input
                    type="file"
                    onChange={(e) => setCsvFiles(e.target.files)}
                    multiple
                  />
                </Box>
                <Box>
                  <Button variant="contained" onClick={handleSubmit}>
                    Submit
                  </Button>
                </Box>
              </Stack>
            </Stack>
          </Grid>
          {view == "TRANSACTIONS" && (
            <Grid item md="12">
              <Stack direction={"row"} spacing={3}>
                <DatePicker
                  controlledValue={filters.date__gte}
                  setControlledValue={(e) =>
                    setFilters({ ...filters, date__gte: e })
                  }
                  label={"Created After"}
                />
                <DatePicker
                  controlledValue={filters.date__lte}
                  setControlledValue={(e) =>
                    setFilters({ ...filters, date__lte: e })
                  }
                  label={"Created Before"}
                />
              </Stack>
            </Grid>
          )}
        </Grid>
      </Block>
      <Stack mt={2}>
        <Box className="table-wrapper">
          {view == "TRANSACTIONS" && (
            <DataTable
              rowData={statements}
              columnDefs={columns}
              getRowStyle={getRowStyle}
              defaultColDef={defaultColDef}
              loading={isLoading}
              suppressSorting={true} //disable default sorting
              rowHeight={53}
              headerHeight={72}
              onSortChanged={(event) =>
                setOrderBy(
                  formatAgGridSorting(event.columnApi.columnModel.gridColumns)
                )
              }
              onFilterChanged={(event) =>
                handleChange(
                  "filterBy",
                  formatAgGridFilters(event.api.getFilterModel())
                )
              }
              page={page}
              pageSize={pageSize}
              setPageSize={(pageSize) => setPageSize(pageSize)}
              noPages={numberOfPages}
              setPage={(page) => setPage(page)}
            />
          )}
          {view == "BATCH" && (
            <DataTable
              rowData={batches}
              columnDefs={columnsBatches}
              getRowStyle={getRowStyle}
              defaultColDef={defaultColDef}
              loading={isLoading}
              suppressSorting={true} //disable default sorting
              rowHeight={53}
              headerHeight={72}
              onSortChanged={(event) =>
                setOrderBy(
                  formatAgGridSorting(event.columnApi.columnModel.gridColumns)
                )
              }
              onFilterChanged={(event) =>
                handleChange(
                  "filterBy",
                  formatAgGridFilters(event.api.getFilterModel())
                )
              }
              page={page}
              pageSize={pageSize}
              setPageSize={(pageSize) => setPageSize(pageSize)}
              noPages={numberOfPages}
              setPage={(page) => setPage(page)}
            />
          )}
        </Box>
      </Stack>
    </>
  );
}
