import React, { useState } from "react";
import { IconButton, TableCell, TableRow } from "@mui/material";
import { CheckCircleOutlineRounded, HighlightOff } from "@mui/icons-material";
import dayjs from "dayjs";
import ConfirmDialog from "../../components/ConfirmDialog";
import { useModal } from "mui-modal-provider";
import UpdateOverrideModal from "../../components/UpdateOverrideModal";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import { fixedNumber } from "../../utils";
import ChangesHistory from "../../components/History";

const VariantOverrideItem = ({ override, deleteVU, updateVU, vid, cost }) => {
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const { showModal } = useModal();

  const handleDelete = () => {
    deleteVU(vid, override.id);
  };
  const handleUpdate = () => {
    showModal(UpdateOverrideModal, {
      override,
      updateOverrideFunc: updateVU,
    });
  };

  return (
    <TableRow>
      <ConfirmDialog
        title="Delete product variant override?"
        open={isOpenDelete}
        setOpen={setIsOpenDelete}
        onConfirm={handleDelete}
      >
        {`Are you sure you want to delete this product variant override?`}
      </ConfirmDialog>
      <TableCell>
        {override.is_current ? (
          <CheckCircleOutlineRounded color="success" fontSize="small" />
        ) : (
          <HighlightOff color="error" fontSize="small" />
        )}
      </TableCell>
      <TableCell>
        {override.start_date
          ? dayjs.utc(override.start_date).format("MMM, DD, YY")
          : ""}
      </TableCell>
      <TableCell>
        Exp:{" "}
        {override.end_date
          ? dayjs.utc(override.end_date).format("MMM, DD, YY")
          : "N/A"}
      </TableCell>
      <TableCell>{fixedNumber(override.override)}%</TableCell>
      <TableCell>
        ${fixedNumber(Number(cost) + (Number(cost) * override.override) / 100)}
      </TableCell>
      <TableCell>
        <IconButton onClick={handleUpdate}>
          <EditOutlinedIcon fontSize={"small"} />
        </IconButton>
        <IconButton onClick={() => setIsOpenDelete(true)}>
          <DeleteForeverOutlinedIcon fontSize={"small"} />
        </IconButton>
        <ChangesHistory
          historyData={override.history || []}
          fontSize={"small"}
        />
      </TableCell>
    </TableRow>
  );
};

export default VariantOverrideItem;
