import { BaseVariant, enqueueSnackbar, OptionsObject } from "notistack";
import { useEffect, useState } from "react";
import { DEBOUNCE_VALUE } from "./constants";

/**
 * @type {OptionsObject}
 * Notistack base config
 */
const baseConfig = {
  persist: false,
};

/**
 * @type {OptionsObject}
 * Notistack Error config
 */
const errorConfig = {
  ...baseConfig,
  variant: "error",
};

/**
 * @type {OptionsObject}
 * Notistack success config
 */
const successConfig = {
  ...baseConfig,
  variant: "success",
};

/**
 * @template T
 * @param {Array<T>} array
 * @param {keyof T | ((item: T) => string)} keyProperty
 * @returns {Record<string, T>}
 */
export function arrayToDictionary(array, keyProperty) {
  const dictionary = {};
  const iteratorFunction =
    typeof keyProperty === "function"
      ? keyProperty
      : (item) => item[keyProperty];
  for (let i = 0; i < array.length; i++) {
    const item = array[i];
    dictionary[iteratorFunction(item)] = item;
  }
  return dictionary;
}

/**
 * @template T
 * @param {Array<T>} collection
 * @param {keyof T | ((item: T) => string)} iterator
 * @returns {Record<string, Array<T>>}
 */
export function groupBy(collection, iterator) {
  const iteratorFunction =
    typeof iterator === "function" ? iterator : (item) => item[iterator];

  return collection.reduce((acc, item) => {
    const key = iteratorFunction(item);
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(item);
    return acc;
  }, {});
}

/**
 * Fire Error notification
 * @param {String} message
 */
export const fireErrorNotification = (message) => {
  notify("error", message);
};

/**
 * Fire success notification
 * @param {String} message
 */
export const fireSuccessNotification = (message) => {
  notify("success", message);
};

/**
 * Fire info notification
 * @param {String} message
 */
export const fireInfoNotification = (message) => {
  enqueueSnackbar(message, {
    ...baseConfig,
    variant: "info",
  });
};

/**
 * Generic wrapper on top of the {enqueueSnackbar} function
 * @param {BaseVariant} type
 * @param {String} message
 */
export const notify = (type = "error", message) => {
  const config = type === "error" ? errorConfig : successConfig;
  enqueueSnackbar(message, config);
};

export const getNearestPrimitiveValue = (obj) => {
  for (const key in obj) {
    if (typeof obj[key] === "object") {
      const childValue = getNearestPrimitiveValue(obj[key]);
      if (childValue) {
        return childValue;
      }
    } else if (obj[key]) {
      return obj[key];
    }
  }
};

export function useSearchDebounce(delay = DEBOUNCE_VALUE, init_val = "") {
  const [search, setSearch] = useState(init_val);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    const delayFn = setTimeout(() => setSearch(searchQuery), delay);
    return () => clearTimeout(delayFn);
  }, [searchQuery, delay]);

  return [search, setSearchQuery];
}

export const fixedNumber = (number) => {
  return `${(+number).toFixed(2)}`;
};

export function arraysAreEqual(arr1, arr2) {
  if (arr1.length !== arr2.length) {
    return false;
  }
  const sortedArr1 = arr1.slice().sort();
  const sortedArr2 = arr2.slice().sort();
  for (let i = 0; i < arr1.length; i++) {
    if (sortedArr1[i] !== sortedArr2[i]) {
      return false;
    }
  }
  return true;
}

export function errorToJson(err) {
  if (typeof err == "object") {
    if (Object.keys(err).length > 10) {
      const newErr = {};
      const keys = Object.keys(err);
      for (let i = 0; i < 10; i++) {
        newErr[keys[i]] = err[keys[i]];
      }
      return flattenJSON(newErr);
    }
    return flattenJSON(err);
  }
  return { error: "Unexpected error" };
}

const flattenJSON = (obj = {}, res = {}, extraKey = "") => {
  for (let key in obj) {
    if (typeof obj[key] !== "object") {
      res[extraKey + key] = obj[key];
    } else {
      flattenJSON(obj[key], res, `${extraKey}${key} > `);
    }
  }
  return res;
};

export function dynamicSort(property) {
  var sortOrder = 1;
  if (property[0] === "-") {
    sortOrder = -1;
    property = property.substr(1);
  }
  return function (a, b) {
    /* next line works with strings and numbers,
     * and you may want to customize it to your needs
     */
    var result =
      a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
    return result * sortOrder;
  };
}

export function validateNum(oldVal, newVal) {
  const r = /^\$?[0-9]+\.?[0-9]?[0-9]?$/;
  if (newVal && r.test(newVal)) {
    return newVal;
  }
  return oldVal || newVal;
}

export function getNoPages(count, pageSize) {
  if (count == pageSize) {
    return parseInt(count / pageSize);
  }
  return parseInt(count / pageSize) + 1;
}

export const renderErrors = (errorObject) => {
  if (typeof errorObject === "object") {
    return (
      <span>
        {Object.entries(errorObject).map(([key, value]) => (
          <span key={key}>
            <strong>{key}:</strong> {renderErrors(value)}
          </span>
        ))}
      </span>
    );
  } else if (Array.isArray(errorObject)) {
    return (
      <span>
        {errorObject.map((error, index) => (
          <span key={index}>{error}</span>
        ))}
      </span>
    );
  } else {
    return errorObject;
  }
};

export const sortBySku = (arr) => {
  return arr.sort((a, b) => {
    if (a.sku < b.sku) {
      return -1;
    }
    if (a.sku > b.sku) {
      return 1;
    }
    return 0;
  });
};
