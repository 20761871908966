import {
  createAccountEmail,
  deleteAccountEmail,
  listAccountEmails,
  updateAccountEmail,
} from "../../distflowAPI/accountsApi";
import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import Block from "../../components/Block";
import ConfirmDialog from "../../components/ConfirmDialog";
import MultipleEmailsForm from "../../components/shared/MultipleEmailsForm";

export default function AccountEmails({ accountId }) {
  const [emails, setEmails] = useState([]);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [email, setEmail] = useState({
    email: "",
    id: null,
  });

  function updateTable() {
    listAccountEmails(accountId).then((res) => setEmails(res.data.results));
  }

  useEffect(() => {
    updateTable();
  }, [accountId]);

  function handleAddEmail(newEmail, callback) {
    createAccountEmail(Number(accountId), newEmail).then((res) => {
      setEmails([res.data, ...emails]);
      callback();
    });
  }

  function handleDelete(emailId, email) {
    setEmail({ email, id: emailId });
    setIsOpenDelete(true);
  }

  function handleDelete_() {
    deleteAccountEmail(accountId, email.id).then((res) => updateTable());
  }

  function handleSave(emailId, updatedEmail, callback) {
    updateAccountEmail(accountId, emailId, updatedEmail).then((res) => {
      updateTable();
      callback();
    });
  }
  return (
    <Block sx={{ mb: 2, py: 2, px: 3, height: "100%" }}>
      <Typography variant={"h6"} mb={1}>
        Account Emails
      </Typography>
      <MultipleEmailsForm
        emails={emails}
        setEmails={setEmails}
        addEmail={handleAddEmail}
        updateEmail={handleSave}
        deleteEmail={handleDelete}
      />

      <ConfirmDialog
        title="Delete Email?"
        open={isOpenDelete}
        setOpen={setIsOpenDelete}
        onConfirm={handleDelete_}
      >
        {`Are you sure you want to delete "${email.email}"?`}
      </ConfirmDialog>
    </Block>
  );
}
