/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import {
  acknowledgeAllNotification,
  acknowledgeNotification,
  listNotifications,
  notificationsCount,
} from "../../distflowAPI/notifications";
import DataTable from "../../components/DataTable";
import { formatAgGridSorting } from "../../distflowAPI/common";
import { getNoPages } from "../../utils";
import { useSearchParams } from "react-router-dom";
import NotificationsFilter from "../../components/Notifications/Filter";
import columns from "../../components/Notifications/NotificationColumns";

const cardStyle = {
  bgcolor: "#fff",
  padding: "20px",
  border: "1px solid #E0E0E0",
  borderRadius: "5px",
};
dayjs.extend(relativeTime);

function Notifications() {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(false);
  const [uncknowledgedNotificationCount, setUncknowledgedNotificationCount] =
    useState(0);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [orderBy, setOrderBy] = useState([]);
  const [count, setCount] = useState(0);
  const [searchParams] = useSearchParams({ acknowledged: "true" });

  const fetchNotifications = async () => {
    setLoading(true);

    await listNotifications(page, pageSize, orderBy, searchParams)
      .then((response) => {
        setNotifications(response.data.results);
        setCount(response.data.count);
      })
      .catch((error) => {
        console.log(error);
      });

    setLoading(false);
  };

  const fetchNotificationsCount = async () => {
    await notificationsCount()
      .then((response) => {
        setUncknowledgedNotificationCount(response.data.count);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const setNotificationAsAcknowledged = async (notificationId) => {
    await acknowledgeNotification(notificationId)
      .then(() => {
        fetchNotifications();
        fetchNotificationsCount();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const setAllNotificationsAsAcknowledged = async () => {
    acknowledgeAllNotification().then(() => {
      fetchNotifications();
      fetchNotificationsCount();
    });
  };

  const RowColumns = columns(setNotificationAsAcknowledged);

  useEffect(() => {
    fetchNotifications();
  }, [page, pageSize, orderBy, searchParams]);

  useEffect(() => {
    fetchNotificationsCount();
  }, []);

  return (
    <>
      <Stack
        direction="column"
        alignItems="flex-start"
        justifyContent="flex-start"
      >
        <Typography
          fontSize="23px"
          fontWeight={800}
          component="h2"
          data-testid="page-title"
        >
          Notifications
        </Typography>
        <Typography
          fontSize="14px"
          fontWeight={500}
          component="p"
          data-testid="description"
          color={"#6E6E6E"}
        >
          Manage notifications
        </Typography>
      </Stack>

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing="15px"
        my="20px"
      ></Stack>

      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Box sx={cardStyle}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box>
                <Typography
                  fontSize="18px"
                  fontWeight={600}
                  component="h3"
                  data-testid="page-title"
                >
                  Notification History
                </Typography>
                <Typography
                  fontSize="14px"
                  fontWeight={500}
                  component="p"
                  data-testid="description"
                  color={"#6E6E6E"}
                >
                  Uncknowledged Notifications: {uncknowledgedNotificationCount}
                </Typography>
              </Box>
              <Button
                className="btn-height"
                variant="contained"
                onClick={() => {
                  setAllNotificationsAsAcknowledged();
                }}
              >
                Acknowledge All
              </Button>
            </Stack>

            <NotificationsFilter />

            <Stack my="20px">
              <DataTable
                rowData={notifications}
                loading={loading}
                setPageSize={setPageSize}
                columnDefs={RowColumns}
                onSortChanged={(event) =>
                  setOrderBy(
                    formatAgGridSorting(event.columnApi.columnModel.gridColumns)
                  )
                }
                page={page}
                pageSize={pageSize}
                noPages={getNoPages(count, pageSize)}
                setPage={setPage}
              />
            </Stack>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default Notifications;
