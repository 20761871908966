import React, { useEffect, useState } from "react";
import { fetchUser, updateUser } from "../../redux/module/user";
import useAppDispatch from "../../hooks/useAppDispatch";
import useAppSelector from "../../hooks/useAppSelector";
import PageLoading from "../../components/PageLoading";
import { useParams, useSearchParams } from "react-router-dom";
import ConfirmDialog from "../../components/ConfirmDialog";
import UpdateItemTitle from "../../components/UpdateItemTitle";
import { Box } from "@mui/material";
import {
  fetchCustomers,
  selectCustomerNumberOfPages,
  setCustomerTableSettings,
} from "../../redux/module/customer";
import DataTable from "../../components/DataTable";
import {
  formatAgGridFilters,
  formatAgGridSorting,
} from "../../distflowAPI/common";
import manageUserAccountsTableColumns from "./manageUserAccountsTableColumns";
import { arraysAreEqual } from "../../utils";

const defaultColDef = {
  flex: 1,
  filter: false,
  floatingFilter: false,
};

const ManageUserAccounts = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const { currentRoute, previousRoute } = useAppSelector(
    (state) => state.utils,
  );

  const { user, usersLoading } = useAppSelector((state) => state.user);
  const { customers, pageSize, page, orderBy, filterBy, customersLoading } =
    useAppSelector((state) => state.customer);
  const numberOfPages = useAppSelector(selectCustomerNumberOfPages);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isOpenDiscard, setIsOpenDiscard] = useState(false);
  const [selectedCustomersId, setSelectedCustomersId] = useState([]);
  const [selectedDefaultCustomersId, setSelectedDefaultCustomersId] = useState([
    68,
  ]);
  const [backButtonLink, setBackButtonLink] = useState("");

  async function handleSubmit(event) {
    event.preventDefault();
    setIsSubmitting(true);
    setIsSubmitting(false);
  }

  useEffect(() => {
    setBackButtonLink(currentRoute.pathname + currentRoute.search);
  }, []);
  useEffect(() => {
    if (id) {
      dispatch(fetchUser(id));
    }
  }, [id]);

  useEffect(() => {
    dispatch(fetchCustomers({ page, pageSize, filterBy, orderBy }));
  }, [pageSize, page, filterBy, orderBy]);

  useEffect(() => {
    if (user?.customers?.length) {
      setSelectedDefaultCustomersId([68]);
      setSelectedCustomersId([68]);
    }
  }, [user]);

  useEffect(() => {
    const params = new URLSearchParams();
    filterBy.forEach((item) => {
      params.set(item.column, item.value);
    });
    setSearchParams(params);
  }, [filterBy]);

  const handleDiscard = () => {
    dispatch(fetchUser(id));
  };

  const handleChange = (field, value) => {
    if (field === "filterBy" || field === "pageSize") {
      dispatch(setCustomerTableSettings({ field: "page", value: 1 }));
    }
    dispatch(setCustomerTableSettings({ field, value }));
  };

  const onSelectionChanged = (e) => {
    const selected = e.api.getSelectedRows();
    const selectedIds = selected.map((el) => el.id);
    setSelectedCustomersId(selectedIds);
  };

  return (
    <Box maxWidth="1394px" margin=" 30px auto 0">
      <PageLoading dataLoading={usersLoading} />
      <ConfirmDialog
        title="Discard updates?"
        open={isOpenDiscard}
        setOpen={setIsOpenDiscard}
        onConfirm={handleDiscard}
      >
        {`Are you sure you want to discard all changes?`}
      </ConfirmDialog>
      <UpdateItemTitle
        item={"User Accounts"}
        backLink={backButtonLink}
        setIsOpenDiscard={setIsOpenDiscard}
        isSubmitting={isSubmitting}
      />
      <Box mt={3} className="user-customers-table">
        <DataTable
          selectedRowIds={selectedDefaultCustomersId}
          rowData={customers}
          columnDefs={manageUserAccountsTableColumns}
          defaultColDef={defaultColDef}
          suppressSorting={true} //disable default sorting
          rowSelection={"multiple"}
          onSelectionChanged={onSelectionChanged}
          suppressRowClickSelection={true}
          rowHeight={53}
          headerHeight={60}
          loading={customersLoading}
          onSortChanged={(event) =>
            handleChange(
              "orderBy",
              formatAgGridSorting(event.columnApi.columnModel.gridColumns),
            )
          }
          onFilterChanged={(event) =>
            handleChange(
              "filterBy",
              formatAgGridFilters(event.api.getFilterModel()),
              [],
            )
          }
          page={page}
          pageSize={pageSize}
          setPageSize={(pageSize) => handleChange("pageSize", pageSize)}
          noPages={numberOfPages}
          setPage={(page) => handleChange("page", page)}
        />
      </Box>
    </Box>
  );
};

export default ManageUserAccounts;
