import React from "react";
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  Button,
} from "@mui/material";

const ConfirmDialog = (props) => {
  const { title, children, open, setOpen, onConfirm, autoClose } = props;
  const approveButtonType = props.approveButtonType ?? "error";

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="confirm-dialog"
      style={{
        zIndex: "2000!impotant",
      }}
    >
      <DialogTitle id="confirm-dialog">{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={() => setOpen(false)}>
          No
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            if(autoClose !== false){
              setOpen(false);
            }
            
            onConfirm();
          }}
          color={approveButtonType}
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
