import { useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";

const TinymceEditor = ({value, onEditorChange}) => {
  const editorRef = useRef(null);
  return (
    <Editor apiKey="gpr4nec4hr3fdzfph9jv42wv8eh6qdn41p2gneq5h7kq8cpy"
      onInit={(evt, editor) => (editorRef.current = editor)}
      value={value}
      onEditorChange={onEditorChange}
      init={{
        menubar: "file edit view format",
        height: 200,
        plugins: [
          "advlist autolink lists link image charmap print preview anchor",
          "searchreplace visualblocks code fullscreen",
          "insertdatetime media table paste code",
        ],
        content_style: "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
        toolbar:
          "undo redo | formatselect | " +
          "bold italic | alignleft aligncenter " +
          "alignright alignjustify | outdent indent",
      }}
    />
  );
};

export default TinymceEditor;
