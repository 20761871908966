import { Link } from "react-router-dom";
import { Typography } from "@mui/material";
import useAppDispatch from "../../hooks/useAppDispatch";
import { removeAccount } from "../../redux/module/account";
import TableButtons from "../../components/TableComponents/TableButtons";
import { removeCategory } from "../../redux/module/category";

function CategoryIconRenderer(params) {
  return params.data.icon ? (
    <img
      src={params.data.icon}
      width="40px"
      height="40px"
      style={{
        paddingTop: "5px",
        paddingBottom: "5px",
        objectFit: "contain",
      }}
    />
  ) : (
    ""
  );
}
function CategoryNameRenderer(params) {
  return (
    <span className="my-renderer">
      <Typography style={{ textDecoration: "none" }}>
        {params.data.name}
      </Typography>
    </span>
  );
}

function CategoryProductsCountRenderer(params) {
  return (
    <span className="my-renderer">
      <Link
        to={`/products/?category__name=${params.data.name}`}
        style={{ textDecoration: "none" }}
      >
        {params.data.products_count}
      </Link>
    </span>
  );
}

function CategoryLinkRenderer(props) {
  const dispatch = useAppDispatch();

  const handleDelete = async () => {
    await dispatch(removeCategory(props.data.id));
  };

  return (
    <TableButtons
      link={`/categories/${props.data.id}`}
      handleDelete={handleDelete}
      deletePopupEntity="category"
      historyData={props.data.history || []}
    />
  );
}

const category_icon = {
  autoHeight: true,
  filter: false,
  cellRendererSelector: () => {
    return {
      component: CategoryIconRenderer,
    };
  },
  flex: 1,
  minWidth: 70,
};

const category_name = {
  headerName: "Category",
  field: "name",
  wrapText: true,
  autoHeight: true,
  sortable: true,
  comparator: () => {}, //disable default sorting
  filterParams: {
    filterOptions: ["contains"],
    maxNumConditions: 1,
  },
  filter: true,
  floatingFilter: true,
  valueGetter: (params) => {
    return `${params.data.name}`;
  },
  cellRendererSelector: () => {
    return {
      component: CategoryNameRenderer,
    };
  },
  flex: 6,
  minWidth: 300,
};

const category_tree_name = {
  headerName: "Category Tree Name",
  field: "tree_name",
  wrapText: true,
  autoHeight: true,
  sortable: true,
  comparator: () => {}, //disable default sorting
  filterParams: {
    filterOptions: ["contains"],
    suppressAndOrCondition: true,
  },
  filter: true,
  floatingFilter: true,
  flex: 6,
  minWidth: 300,
};

const category_products_count = {
  headerName: "Products",
  field: "products_count",
  comparator: () => {}, //disable default sorting
  filterParams: {
    filterOptions: ["contains"],
    suppressAndOrCondition: true,
  },
  filter: false,
  sortable: true,
  cellRendererSelector: () => {
    return {
      component: CategoryProductsCountRenderer,
    };
  },
  flex: 8,
  minWidth: 100,
};

const category_link = {
  field: "",
  sorting: false,
  sort: false,
  sortable: false,
  autoHeight: true,
  filter: false,
  cellRenderer: CategoryLinkRenderer,
  flex: 2,
  minWidth: 120,
};

const categoryTableColumns = [
  category_icon,
  category_name,
  category_tree_name,
  category_products_count,
  category_link,
];

export default categoryTableColumns;
