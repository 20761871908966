// TODO:
import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import driversApi, { deleteDriver, listDrivers, patchDriver, postDriver } from "../../distflowAPI/driversApi";
import { signOut, userSlice } from "./user";
import { arrayToDictionary, fireErrorNotification, fireSuccessNotification } from "../../utils";
import { getUsers } from "../../distflowAPI/user";

/**
 * @typedef {Object} User
 * @property {number} User.id
 * @property {string} User.email
 * @property {string} User.first_name
 * @property {string} User.last_name
 * @property {boolean} User.is_active
 * @typedef {Object} Driver
 * @property {number} Driver.id
 * @property {User} Driver.user
 */

/**
 * @type {{
 *  drivers: Array<Driver>;
 *  driversMap: Record<string, Driver>;
 *  driversLoading: boolean;
 * }}
 */
const initialState = {
  drivers: [],
  driver: {},
  pageSize: 50,
  page: 1,
  orderBy: [],
  filterBy: [],
  totalCount: 0,
  driversMap: {},
  driversLoading: false,
};

// export const fetchDrivers = createAsyncThunk("driver/fetchDrivers", () =>
//   listDrivers()
// );

export const fetchDrivers = createAsyncThunk("driver/fetchDrivers", async (data, thunkAPI) => {
  const { page, pageSize, filterBy, orderBy } = data;
  return driversApi.list(pageSize, page, orderBy, filterBy);
});

export const createDriver = createAsyncThunk("driver/createDriver", async (driverInfo, {rejectWithValue}) => {
  try {
    const res = await postDriver(driverInfo);
    return res.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const updateDriver = createAsyncThunk("driver/updateDriver", async (data, {rejectWithValue}) => {
  const {driverId, driverInfo} = data;

  try {
    const res = await patchDriver(driverId, driverInfo);
    return res.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const removeDriver = createAsyncThunk("driver/removeDriver", async (driverId, {rejectWithValue}) => {
  try {
    const res = await deleteDriver(driverId);
    return driverId;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});


export const driverSlice = createSlice({
  name: "driver",
  initialState,
  reducers: {
    setDriversTableSettings(state, { payload }) {
      state[payload.field] = payload.value;
    },
    cleanTableSettings(state) {
      state.pageSize = 50;
      state.page = 1;
      state.orderBy = [];
      state.filterBy = [];
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDrivers.pending, (state) => {
        state.driversLoading = true;
      })
      .addCase(fetchDrivers.fulfilled, (state, action) => {
        state.driversLoading = false;
        state.drivers = action.payload.results;
        state.totalCount = action.payload.count;        
        state.driversMap = arrayToDictionary(action.payload.results, "id");
      })
      .addCase(fetchDrivers.rejected, (state) => {
        state.driversLoading = false;
      })
      .addCase(createDriver.fulfilled, (state, action) => {
        fireSuccessNotification(`Driver created successfully`)
        state.totalCount = state.totalCount + 1;
        if (state.drivers.length < state.pageSize) {
          state.drivers = [...state.drivers, action.payload];
        }
      })
      .addCase(createDriver.rejected, (state, action) => {
        const errorFields = Object.keys(action.payload);
        errorFields.forEach(field => {
            if (field === "user") {
              const errorOwnerFields = Object.keys(action.payload[field]);
              errorOwnerFields.forEach(ownerField => {
                fireErrorNotification(`${ownerField}: ${action.payload[field][ownerField][0]}`);
              });
            } else {
              fireErrorNotification(`${field}: ${action.payload[field][0]}`);
            }
          }
        );
      })
      .addCase(updateDriver.fulfilled, (state, action) => {
        fireSuccessNotification(`Driver updated successfully`)
        state.driver = action.payload;
      })
      .addCase(updateDriver.rejected, (state, action) => {
        const errorFields = Object.keys(action.payload)
        errorFields.forEach(field => {
          fireErrorNotification(`${field}: ${action.payload[field][0]}`)
        })
      })
      .addCase(removeDriver.fulfilled, (state, action) => {
        fireSuccessNotification(`Driver deleted successfully`)
        state.driver = { };
        state.drivers = state.drivers.filter(el => el.id !== action.payload)
      })
      .addCase(removeDriver.rejected, (state, action) => {
        const protected_elements = action.payload.protected_elements;
        fireErrorNotification(`Protected by: 
          ${protected_elements.map(el => ` id: ${el.id} label: ${el.label}`)}`);
      })
      .addCase(signOut, () => initialState);
  },
});

export const selectDriversNumberOfPages = createSelector(
  (state) => state.driver.totalCount,
  (state) => state.driver.pageSize,
  (totalCount, pageSize) => Math.ceil(totalCount / pageSize)
);

export const {
  setDriversTableSettings, cleanTableSettings
} = driverSlice.actions;


export default driverSlice.reducer;
