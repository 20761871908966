import React from "react";
import { DragOverlay } from "@dnd-kit/core";
import DraggableDriver from "./DraggableDriver";
import { DraggableAccount } from "../../components/draggable/DraggableAccount";
import useKeyBy from "../../hooks/useKeyBy";

const AccountAndDriverDragOverlay = ({
  allDrivers,
  allAccounts,
  activeDraggableItem,
}) => {
  const allDriversMap = useKeyBy(allDrivers, "id");
  const allAccountsMap = useKeyBy(allAccounts, "id");

  return (
    <DragOverlay>
      {activeDraggableItem?.over === "driver" ? (
        <DraggableDriver
          title={allDriversMap[activeDraggableItem.id]?.user.first_name}
        />
      ) : activeDraggableItem?.over === "account" ? (
        <DraggableAccount
          title={allAccountsMap[activeDraggableItem.id]?.name}
          subtitle={allAccountsMap[activeDraggableItem.id]?.address}
        />
      ) : null}
    </DragOverlay>
  );
};

export default AccountAndDriverDragOverlay;
