import {
  Typography,
  FormGroup,
  FormControlLabel,
  TextField,
  Checkbox,
  FormControl,
  FormLabel,
  FormHelperText,
} from "@mui/material";
import Block from "../../components/Block";
import Switcher from "../../components/Switcher";
import useAppDispatch from "../../hooks/useAppDispatch";
import useAppSelector from "../../hooks/useAppSelector";
import {
  setIsActive,
  setNote,
  setSelectedDays,
} from "../../redux/module/addEditRoute";

const weekdays = [
  { key: "is_sunday", label: "Sun" },
  { key: "is_monday", label: "Mon" },
  { key: "is_tuesday", label: "Tue" },
  { key: "is_wednesday", label: "Wed" },
  { key: "is_thursday", label: "Thu" },
  { key: "is_friday", label: "Fri/Erev" },
];

const AddRouteScheduleForm = () => {
  const dispatch = useAppDispatch();
  const note = useAppSelector((state) => state.addEditRoute.note);
  const isActive = useAppSelector((state) => state.addEditRoute.isActive);
  const selectedDays = useAppSelector(
    (state) => state.addEditRoute.selectedDays
  );
  const errors = useAppSelector((state) => state.addEditRoute.formErrors);

  return (
    <Block>
      <Typography
        fontSize="20px"
        fontWeight={500}
        sx={{ color: "#000", mb: "15px" }}
      >
        Schedule
      </Typography>
      <FormControl fullWidth sx={{ marginBottom: 3 }}>
        <FormLabel sx={{ marginBottom: "-4px" }}>Days</FormLabel>
        <FormGroup row>
          {weekdays.map((item) => (
            <FormControlLabel
              key={item.key}
              control={<Checkbox />}
              label={item.label}
              checked={selectedDays[item.key]}
              onChange={(e) =>
                dispatch(setSelectedDays({ [item.key]: e.target.checked }))
              }
            />
          ))}
        </FormGroup>
        {errors.selectedDays && (
          <FormHelperText error>{errors.selectedDays}</FormHelperText>
        )}
      </FormControl>
      <FormControl fullWidth sx={{ marginBottom: 3 }}>
        <FormLabel>Note</FormLabel>
        <TextField
          rows="4"
          data-testid={`test-note-id`}
          multiline
          value={note}
          onChange={(e) => dispatch(setNote(e.target.value))}
        />
      </FormControl>
      <Switcher
        label="Active"
        checked={isActive}
        onChange={(e) => dispatch(setIsActive(e.target.checked))}
      />
    </Block>
  );
};

export default AddRouteScheduleForm;
