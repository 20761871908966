import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { signOut } from "./user";
import { fireErrorNotification, fireSuccessNotification } from "../../utils";
import vendorCategoryOverridesApi from "../../distflowAPI/overrideApi";
import { createCustomer, removeCustomerOverride, updateCustomer } from "./customer";


const initialState = {
  vendorCategoryOverride: {},
  vendorCategoryOverrides: [],
  overridesLoading: false,
  pageSize: 50,
  page: 1,
  orderBy: [],
  filterBy: [],
  totalCount: 0,
};

export const fetchVendorCategoryOverrides = createAsyncThunk(
  "override/fetchVendorCategoryOverrides",
  async (data, { dispatch, getState }) => {
    const { page, pageSize, filterBy, orderBy, searchParams } = data;
    return await vendorCategoryOverridesApi.list(pageSize, page, orderBy,filterBy, searchParams);
  }
);

export const createVendorCategoryOverride = createAsyncThunk(
  "override/createVendorCategoryOverride",
  async (payload, { rejectWithValue }) => {
    try {
      const res = await vendorCategoryOverridesApi.create(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateVendorCategoryOverride = createAsyncThunk(
  "override/updateVendorCategoryOverride",
  async ({ id, payload }, { rejectWithValue }) => {
    try {
      const res = await vendorCategoryOverridesApi.update(id, payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const deleteVendorCategoryOverride = createAsyncThunk(
  "override/deleteVendorCategoryOverride",
  async (id, { rejectWithValue }) => {
    try {
      const res = await vendorCategoryOverridesApi.delete(id);
      return id;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const overrideSlice = createSlice({
  name: "override",
  initialState,
  reducers: {
    setOverrideTableSettings(state, { payload }) {
      state[payload.field] = payload.value;
    },
    cleanTableSettings(state) {
      state.pageSize = 50
      state.page = 1
      state.orderBy = []
      state.filterBy = []
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchVendorCategoryOverrides.pending, (state) => {
        state.overridesLoading = true;
      })
      .addCase(fetchVendorCategoryOverrides.fulfilled, (state, action) => {
        state.overridesLoading = false;
        state.vendorCategoryOverrides = action.payload.results;
        state.totalCount = action.payload.count;
      })
      .addCase(createVendorCategoryOverride.pending, (state) => {
        state.overrideLoading = true;
      })
      .addCase(createVendorCategoryOverride.fulfilled, (state, action) => {
        state.overrideLoading = false;
        fireSuccessNotification(`Vendor Category override created successfully`);
        state.totalCount = state.totalCount + 1;
        if (state.vendorCategoryOverrides.length < state.pageSize) {
          state.vendorCategoryOverrides = [...state.vendorCategoryOverrides, action.payload];
        }
      })
      .addCase(createVendorCategoryOverride.rejected, (state, action) => {
        state.overrideLoading = false;
        const errorFields = Object.keys(action.payload);
        errorFields.forEach(field => {
          fireErrorNotification(`${field}: ${action.payload[field][0]}`);
        });
      })
      .addCase(updateVendorCategoryOverride.fulfilled, (state, action) => {
        state.vendorCategoryOverride = action.payload;
        state.vendorCategoryOverrides = state.vendorCategoryOverrides.map(el => {
          if (el.id === action.payload.id) {
            return action.payload
          } else {
            return el
          }
        })        
        fireSuccessNotification(`Vendor Category Override updated successfully`);
      })
      .addCase(updateVendorCategoryOverride.rejected, (state, action) => {
        const errorFields = Object.keys(action.payload);
        errorFields.forEach(field => {
          fireErrorNotification(`${field}: ${action.payload[field][0]}`);
        });
      })
      .addCase(deleteVendorCategoryOverride.fulfilled, (state, action) => {
        fireSuccessNotification(`Vendor Category Override deleted successfully`);
        state.vendorCategoryOverrides = state.vendorCategoryOverrides.filter(el => el.id !== action.payload);
      })
      .addCase(signOut, () => initialState);
  },
});

export const { setOverrideTableSettings, cleanTableSettings } = overrideSlice.actions;

export const selectOverrideNumberOfPages = createSelector(
  (state) => state.override.totalCount,
  (state) => state.override.pageSize,
  (totalCount, pageSize) => Math.ceil(totalCount / pageSize)
);

export default overrideSlice.reducer;
