import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import Block from "../../components/Block";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  bulkPushOffAutoOrders,
  listAccountAutomaticOrders,
  listVariants,
} from "../../distflowAPI/ordersApi";
import accountsApi from "../../distflowAPI/accountsApi";
import { useSearchParamsState } from "react-use-search-params-state";
import AccountSelect from "../../components/AsyncSelects/AccountSelect";
import TagSelect from "../../components/AsyncSelects/TagSelect";
import { getTag } from "../../distflowAPI/tagsApi";
import debounce from "lodash.debounce";
import AutoOrderConfigurations from "./AutoOrderConfigurations";
import AutoOrdersProductsList from "./AutoOrdersProductsList";
import AutoOrdersAddProduct from "./AutoOrdersAddProduct";
import DatePickerCustom2 from "../../components/DatePicker2";
import { DATE_FORMAT } from "../../utils/constants";

const filtersDefaults = {
  accountId: { type: "number", default: null },
  category: { type: "string", default: "" },
  vendor: { type: "string", default: "" },
  search: { type: "string", default: "" },
  sku: { type: "string", default: "" },
  day: { type: "string", default: "all" },
  tags: { type: "string", default: "" },
};

const ManageAutoOrders = () => {
  const [filterParams, setFilterParams] = useSearchParamsState(filtersDefaults);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [openDisableMultiple, setOpenDisableMultiple] = useState(false);
  const [accountAutomaticOrdersLoading, setAccountAutomaticOrdersLoading] =
    useState(false);
  const [variants, setVariants] = useState([]);
  const [accountOrders, setAccountOrders] = useState([]);
  const [account, setAccount] = useState({
    label: "",
    value: filterParams.accountId || null,
  });

  const [selectedTag, setSelectedTag] = useState([]);

  useEffect(() => {
    if (filterParams.accountId) {
      accountsApi
        .findById(filterParams.accountId)
        .then((res) =>
          setAccount({ value: res.data.id, label: res.data.name }),
        );
    }
    if (filterParams.tags) {
      const tags = filterParams.tags.split(",");
      tags.forEach(async (tagId) => {
        await getTag(tagId).then((res) => {
          setSelectedTag((prev) => [
            ...prev,
            { value: res.data.id, label: res.data.name },
          ]);
        });
      });
    }
  }, []);

  function updateAccountRelated() {
    if (account && account.value) {
      listVariants(20, 1, [], searchParams).then((res) => {
        setVariants(res.results);
      });
      setAccountAutomaticOrdersLoading(true);
      listAccountAutomaticOrders(account.value, filterParams.day)
        .then((res) => setAccountOrders(res.data.results))
        .finally(() => {
          setAccountAutomaticOrdersLoading(false);
        });
    } else {
      setVariants([]);
    }
  }

  useEffect(() => {
    updateAccountRelated();
  }, [searchParams]);

  const handleBackClick = () => {
    navigate(`/orders?date=${searchParams.get("date")}`);
  };

  const removeOrders = () => {
    setAccountOrders([]);
  };

  return (
    <Box maxWidth="1600px" margin=" 30px auto 0">
      <Stack
        direction={{ xs: "column", sm: "row" }}
        alignItems="center"
        justifyContent="space-between"
        spacing="20px"
        mb={5}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent={{ xs: "space-between", sm: "flex-srart" }}
          sx={{ width: { xs: "100%", sm: "initial" }, gap: "20px" }}
        >
          <Button
            className="btn-height"
            onClick={handleBackClick}
            variant="outlined"
            startIcon={<ArrowBackIcon />}
          >
            Back
          </Button>
          <Typography
            variant="pageTitle"
            noWrap
            component="p"
            data-testid="page-title"
          >
            Order Configurations
          </Typography>
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          flexWrap="wrap"
          sx={{
            gap: "11px",
            "@media(max-width: 480px)": {
              width: "100%",
              button: { width: "100%" },
            },
          }}
        >
          <Button color="primary" variant="contained">
            Save
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => setOpenDisableMultiple(true)}
          >
            Disable Multiple accounts
          </Button>
        </Stack>
      </Stack>
      <DisableMultiple
        open={openDisableMultiple}
        setOpen={setOpenDisableMultiple}
      />
      <Grid container columnSpacing={{ xs: 1, sm: "20px" }} margin="36px 0 0">
        <Grid item width="100%" xl={6}>
          <Block>
            <Box data-testid="add-product-form">
              <AutoOrdersAddProduct
                filterParams={filterParams}
                setFilterParams={setFilterParams}
                setAccount={setAccount}
                account={account}
                selectedTag={selectedTag}
                setSelectedTag={setSelectedTag}
                isShowSearchAccount
                removeOrders={removeOrders}
              />
            </Box>
          </Block>

          <Box mt="-13px" data-testid="auto-order-products-list">
            <AutoOrdersProductsList
              account={account}
              updateAccountRelated={updateAccountRelated}
              variants={variants}
              accountOrders={accountOrders}
            />
          </Box>
        </Grid>

        <Grid item width="100%" xl={6} data-testid="search-product-list">
          <Block isLoading={accountAutomaticOrdersLoading}>
            <AutoOrderConfigurations
              day={filterParams.day}
              onChange={(value) => setFilterParams({ day: value })}
              accountOrders={accountOrders}
              account={account}
              updateAccountRelated={updateAccountRelated}
            />
          </Block>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ManageAutoOrders;

function DisableMultiple({ open, setOpen }) {
  const [date, setDate] = useState(null);
  const [dateTill, setDateTill] = useState(null);
  const [selectedCustomersAccounting, setSelectedCustomersAccounting] =
    useState([]);
  function handleSubmit() {
    const df = date ? date.format(DATE_FORMAT) : "";
    const dt = dateTill ? dateTill.format(DATE_FORMAT) : "";
    bulkPushOffAutoOrders(
      selectedCustomersAccounting.map((acc) => acc.value),
      df,
      dt,
    ).then(
      (res) => {
        alert(`successfully disabled from ${df} to ${dt}`);
        setOpen(false);
      },
      (err) => {
        alert("Error occurred");
      },
    );
  }
  return (
    <Dialog open={open}>
      <DialogTitle>
        <Typography>Cancel recurring orders</Typography>
      </DialogTitle>
      <DialogContent>
        <Stack spacing={1}>
          <Stack>
            <Typography>Disable from</Typography>
            <DatePickerCustom2
              controlledValue={date}
              setControlledValue={setDate}
            />
            <Typography>Disable Till</Typography>
            <DatePickerCustom2
              controlledValue={dateTill}
              setControlledValue={setDateTill}
            />
          </Stack>

          <AccountSelect
            label="Accounts to deactivate for"
            id="multiple-limit-tags-xx"
            value={selectedCustomersAccounting}
            onChange={(_event, newValue) => {
              setSelectedCustomersAccounting(newValue);
            }}
            multiple={true}
            //setCustomerSearch={setCustomerSearchAccounting}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack direction={"row"} spacing={1}>
          <Button
            onClick={() => setOpen(false)}
            variant="contained"
            color={"error"}
          >
            Cancel
          </Button>
          <Button variant="contained" color={"success"} onClick={handleSubmit}>
            Save
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
