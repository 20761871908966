import { Grid, Typography, Button, Box } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { REDUCER_ACTIONS } from "./config";
export default function AttachmentComponent({ file, dispatch }) {

  function handleDelete(){
    dispatch({
      type: REDUCER_ACTIONS.REMOVE_FILE,
      payload: file,
    });
  }
  return (
    <>
      <Box
        sx={{
          padding: "10px 20px",
          display: "flex",
          justifyContent: "space-between",
          border: "1px solid #cecece",
        }}
      >
        <Box>
          <Typography sx={{ fontWeight: "bolder" }}>
            File name:{" "}
            <span style={{ fontWeight: "lighter" }}>{file.name}</span>
          </Typography>
          <Typography sx={{ fontWeight: "bolder" }}>
            Size:{" "}
            <span style={{ fontWeight: "lighter" }}>
              {(file.size / (1000 * 1000)).toFixed(2)} Mb
            </span>
          </Typography>
        </Box>
        <Box>
          <Button
            sx={{
              padding: "4px 5px",
              fontSize: "12px",
              minWidth: "0",
              width: "30px",
              height: "30px !important",
            }}
            variant="contained"
            onClick={() => {
              handleDelete();
            }}
            color="error"
            size="small"
          >
            <DeleteIcon fontSize="small" />
          </Button>
        </Box>
      </Box>
    </>
  );
}
