import { useState, useEffect } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import CategorySelect from "../../components/AsyncSelects/CategorySelect";
import useAppDispatch from "../../hooks/useAppDispatch";
import { createCategory } from "../../redux/module/category";
import IconSelect from "./IconSelect";

/**
 * @param {{
 *  open: boolean;
 *  onClose: () => void;
 *  initialData?: import("../../redux/module/category").Category;
 *  onSubmit: (data: FormData) => void
 * }} props
 * @returns
 */

const initialValue = {
  name: "",
  parent: {
    value: "",
    label: "",
  },
  icon: null
}

const AddCategoryFrom = ({ open, handleClose }) => {
  const dispatch = useAppDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [categoryInfo, setCategoryInfo] = useState(initialValue);
  
  async function handleSubmit(event) {
    event.preventDefault();
    setIsSubmitting(true);

    const formData = new FormData();
    formData.append("parent", categoryInfo.parent.value);
    formData.append("name", categoryInfo.name);
    if(categoryInfo.icon) {
      formData.append("icon", categoryInfo.icon);
    }

    const data = await dispatch(createCategory(formData));
    if (data.payload.id) {
      handleClose();
    }
    setIsSubmitting(false);
  }

  useEffect(() => {
    setCategoryInfo(initialValue)
  }, [open]);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle sx={{ pb: 1 }}>Create a new Category</DialogTitle>
      <form id="CategoryCreation" onSubmit={handleSubmit}>
        <DialogContent sx={{ pt: 2, pb: 1 }}>
          <TextField
            required
            name="name"
            data-testid="test-name-id"
            label="Category name"
            type="text"
            id="name"
            fullWidth
            value={categoryInfo.name}
            onChange={(e) => setCategoryInfo({ ...categoryInfo, name: e.target.value })}
            autoComplete="off"
          />
          <CategorySelect
            fullWidth
            label="Parent category"
            name="parent"
            onChange={(_e, newValue) => setCategoryInfo({ ...categoryInfo, parent: newValue })}
            extraStyles={{
              mt: 1,
              mb: 2
            }}
          />
          <IconSelect categoryInfo={categoryInfo} setCategoryInfo={setCategoryInfo}/>
        </DialogContent>
        <DialogActions>
          <Button 
            variant="contained" 
            color="primary" 
            type="submit" 
            disabled={isSubmitting}
          >
            Create Category
          </Button>
          <Button onClick={handleClose} variant="contained" color="error">
            Cancel
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default AddCategoryFrom;
