import React, { useEffect, useState } from "react";
import {
  Box, Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography
} from "@mui/material";
import useAppSelector from "../../hooks/useAppSelector";
import GradingOutlinedIcon from "@mui/icons-material/GradingOutlined";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import CustomerAutoOrders from "./CustomerAutoOrders";
import CustomerActualOrders from "./CustomerActualOrders";

const CustomerOrders = () => {

  const { customerAccounts, customerOrders, customersLoading } = useAppSelector(
    (state) => state.customer
  );

  const [day, setDay] = useState("all");
  const [orderType, setOrderType] = useState("actual");
  const [account, setAccount] = useState({ label: "All", value: null });
  const [selectedAccountId, setSelectedAccountId] = useState(0);

  const handleChange = (e) => {
    const accountId = e.target.value;
    setDay("all");
    setSelectedAccountId(accountId);
    if (accountId) {
      setAccount({
        label: customerAccounts.find(el => el.id === accountId).name,
        value: accountId
      });
    } else {
      setAccount({ label: "All", value: null });
    }
  };

  useEffect(() => {
    if(customerAccounts.length === 1) {
      setAccount({
        label: customerAccounts[0].name,
        value: customerAccounts[0].id
      })
    }
  }, [customerAccounts]);

  return (
    <Box>
      <Stack direction="row" justifyContent="space-between" alignItems="center" mb={3}>
        <Typography component="p" variant="h6">
          {orderType === "auto" ? "Automatic orders" : "Actual orders"}
        </Typography>
        <Stack direction="row" gap={1}>         
          <ToggleButtonGroup
            value={orderType}
            exclusive
            onChange={() => setOrderType(prev => prev === "auto" ? "actual" : "auto")}
            sx={{
              bgcolor: "#ffffff",
              "& button": {
                padding: "8px"
              },
              "& button.Mui-selected": {
                backgroundColor: "#6576FF",
                "& svg": { fill: "#fff" },
                "&:hover": { backgroundColor: "#6576FF" }
              }
            }}
          >
            <ToggleButton value="actual" title={"Actual orders"}>
              <ListAltOutlinedIcon />
            </ToggleButton>
            <ToggleButton value="auto" title={"Auto orders"}>
              <GradingOutlinedIcon />
            </ToggleButton>
          </ToggleButtonGroup>
          {customerAccounts.length > 1 &&           
            <FormControl sx={{ minWidth: 250 }} size="small">
              <InputLabel id="demo-select-small-label">Account</InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={selectedAccountId}
                label="Account"
                onChange={handleChange}
              >
                {[{ id: 0, name: "All Accounts" }, ...customerAccounts].map(el => (
                  <MenuItem value={el.id} key={el.id}>
                    {el.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>          
          }
        </Stack>
      </Stack>
      {orderType === "auto" ?
        <CustomerAutoOrders
          day={day}
          account={account}
          setDay={setDay}
        />
        :
        <CustomerActualOrders
          account={account}
        />
      }
      {/*<DataTable rowData={overrides} columnDefs={customerOverridesTable} defaultColDef={defaultColDef} rowHeight={73} />*/}
    </Box>
  );
};

export default CustomerOrders;