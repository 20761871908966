import { Button, Typography, Stack, TextField } from "@mui/material";
import Block from "../../../components/Block";
import { useState, useEffect } from "react";
import { updatePaymentGroup } from "../../../distflowAPI/paymentsApi";


function PaymentNotes({payment, id}){
  const [note, setNote] = useState("");
  const [editNote, setEditNote] = useState(false);
  async function updateNote() {
    const data = updatePaymentGroup(
      id,
      {
        notes: note,
      },
    );

    if (data.payload.id) {
      setEditNote(false);
    }
  }
  useEffect(() => {
    setNote(payment.notes);
  }, [payment])
  return <Block>
  <Stack>
    {payment.notes && (
      <>
        <Typography fontWeight={600}>Notes:</Typography>
        {editNote ? (
          <>
            <TextField
              rows={5}
              fullWidth
              data-testid="test-note-id"
              multiline
              value={note}
              onChange={(e) => setNote(e.target.value)}
            />
            <Stack direction={"row"} marginTop={"5px"}>
              <Button
                color="success"
                variant="contained"
                sx={{ marginRight: "5px" }}
                onClick={() => updateNote()}
              >
                Save
              </Button>
              <Button
                onClick={() => setEditNote(false)}
                color="error"
                variant="contained"
              >
                Cancel
              </Button>
            </Stack>
          </>
        ) : (
          <>
            <Typography sx={{ whiteSpace: "pre-line" }}>
              {payment.notes}
            </Typography>
            <Stack justifyContent={"end"} direction={"row"}>
              <Button
                onClick={() => setEditNote(true)}
                sx={{ maxWidth: "120px", padding: "3px 4px" }}
                color="info"
                variant="contained"
              >
                Update Note
              </Button>
            </Stack>
          </>
        )}
      </>
    )}
    {!payment.notes && (
      <>
        <Typography fontWeight={600}>Add notes:</Typography>
        <>
          <TextField
            rows={5}
            fullWidth
            data-testid="test-note-id"
            multiline
            value={note || payment.notes || ""}
            onChange={(e) => setNote(e.target.value)}
          />
          <Stack direction={"row"} marginTop={"5px"}>
            <Button
              color="success"
              variant="contained"
              sx={{ marginRight: "5px" }}
              onClick={() => updateNote()}
            >
              Save
            </Button>
          </Stack>
        </>
      </>
    )}
  </Stack>
</Block>
}

export default PaymentNotes