import api from "./api";
import { filtersToApi } from "./common";

export async function listWhitelistedIps(page = 1, pageSize = 1, filter = []) {
  const filterParam = filter.length ? `&${filtersToApi(filter)}` : "";
  const params = `page_size=${pageSize}&page=${page}${filterParam}`;

  const result = await api.get(`/api/settings/4/?${params}`);
  return result;
}

export async function updateWhitelistedIps(data) {
  const result = await api.patch(`/api/settings/4/`, data);
  return result;
}
