import { Link, NavLink } from "react-router-dom";
import { Button, Chip, IconButton, Stack, Typography } from "@mui/material";
import useAppDispatch from "../../hooks/useAppDispatch";
import TableButtons from "../../components/TableComponents/TableButtons";
import dayjs from "dayjs";
import { DATE_FORMAT_FRONT } from "../../utils/constants";
import HtmlTooltip from "../../components/HtmlToolTip";
import { Flag } from "@mui/icons-material";
import { deletePayment } from "../../distflowAPI/paymentsApi";

const orderDemo = {
  payments: [],
  issued_date: "2023-05-31T21:28:33.488816Z",
  notes: null,
};

function LinkRenderer(props) {
  const dispatch = useAppDispatch();

  const handleDelete = async () => {
    deletePayment(props.data.id).then((res) => props.updateTable());
  };

  return (
    <TableButtons
      link={`/invoices/payments/${props.data.id}`}
      deletePopupEntity="payment"
      historyData={props.data.history || []}
      handleDelete={handleDelete}
      allowEdit={false}
    />
  );
}

function MatchSelector(props) {
  return (
    <Stack direction={"row"} spacing={2} alignItems={"center"}>
      <Button variant="outlined" color="secondary">
        Match
      </Button>
    </Stack>
  );
}

function InvoiceNameRenderer(params) {
  return (
    <span className="my-renderer">
      <Link
        to={`/invoices/payments/${params.data.id}/details`}
        style={{ textDecoration: "none" }}
      >
        #{params.data.id}
      </Link>
    </span>
  );
}

function InvoiceNameRenderer2(params) {
  function matchColor(match_probability) {
    if (match_probability == "HIGH") {
      return {
        color: "success",
        text: "High Prob.",
        hex: "#3FC6A5",
      };
    }
    if (match_probability == "MID") {
      return {
        color: "primary",
        text: "Probable",
        hex: "#6576ff",
      };
    }
    if (match_probability == "LOW") {
      return {
        color: "warning",
        text: "Low Prob.",
        hex: "orange",
      };
    }
    return "";
  }

  return (
    <span className="my-renderer">
      <Link
        to={`/invoices/payments/${params.data.id}/details`}
        style={{ textDecoration: "none" }}
      >
        #{params.data.id}
      </Link>
      {!params.data.possibleMatch && (
        <HtmlTooltip
          title={
            <div>
              <Typography
                fontWeight={800}
                color={params.data.match.length == 0 ? "red" : "green"}
              >
                {params.data.match.length == 0
                  ? "This payment has no matching deposit"
                  : "Payment matches deposit"}
              </Typography>
            </div>
          }
        >
          <IconButton>
            <Flag color={params.data.match.length == 0 ? "error" : "success"} />
          </IconButton>
        </HtmlTooltip>
      )}
      {params.data.possibleMatch && (
        <HtmlTooltip
          title={
            <div>
              <Typography fontWeight={800}>
                {params.data.possibleMatch.note}
              </Typography>
            </div>
          }
        >
          <Chip
            label={matchColor(params.data.possibleMatch.match_probability).text}
            variant="oulined"
            sx={{
              backgroundColor: matchColor(
                params.data.possibleMatch.match_probability,
              ).hex,
              marginLeft: 1,
              height: "auto",
              "& .MuiChip-label": {
                display: "block",
                whiteSpace: "pre-line",
                padding: 1,
              },
            }}
            size="small"
          />
        </HtmlTooltip>
      )}
    </span>
  );
}

function CustomerNameRenderer(params) {
  return (
    <span className="my-renderer">
      <Link
        to={`/accounts/${params.data.account.id}`}
        style={{ textDecoration: "none" }}
      >
        {params.data.account.name}
      </Link>
    </span>
  );
}

const order_id = {
  flex: 4,
  headerName: "Order",
  valueGetter: (params) => {
    return `#${params.data.order}`;
  },
};

const account_name = {
  headerName: "Account",
  wrapText: true,
  autoHeight: true,
  sortable: true,
  comparator: () => {}, //disable default sorting
  filterParams: {
    filterOptions: ["contains"],
    suppressAndOrCondition: true,
  },
  filter: true,
  floatingFilter: true,
  valueGetter: (params) => {

    return `${params.data.account.name}`;
  },
  cellRendererSelector: () => {
    return {
      component: CustomerNameRenderer,
    };
  },
  flex: 4,
};

const invoice_id = {
  headerName: "ID",
  cellRendererSelector: () => {
    return {
      component: InvoiceNameRenderer,
    };
  },
  maxWidth: 150,
};

const customer_email = {
  headerName: "Email",
  field: "email",
  wrapText: true,
  autoHeight: true,
  sortable: true,
  comparator: () => {}, //disable default sorting
  filterParams: {
    filterOptions: ["contains"],
    suppressAndOrCondition: true,
  },
  filter: true,
  floatingFilter: true,
  valueGetter: (params) => `${params.data.owner.email}`,
  flex: 4,
};

const RouteRenderer = (params) => {
  // const navigate = useNavigate();
  // ;
  return (
    <Stack direction={"row"}>
      {params.data.routes.map((item) => {
        return (
          <Button
            size="small"
            variant="gray"
            component={NavLink}
            to={`/routes2/?id=${item?.id}`}
            sx={{
              bgcolor: "#F5F5F5",
              border: "1px solid #DBDFEA !important",
              padding: "0 9px !important",
              fontSize: "14px !important",
            }}
          >
            {item?.name}
          </Button>
        );
      })}
    </Stack>
  );
};

const routes = {
  headerName: "Routes",
  cellRendererSelector: () => {
    return {
      component: RouteRenderer,
    };
  },
  flex: 4,
};

const payment_method = {
  headerName: "Method",
  field: "method",
  //valueGetter: (params)=>
  valueGetter: (params) =>
    params.data.method == "CHEQUE"
      ? "check"
      : params.data.method?.replace("_", " ").toLowerCase(),
  maxWidth: 130,
};

const payment_date = {
  headerName: "Date",
  field: "payment_date",
  valueGetter: (params) => {
    return dayjs.utc(params.data.payment_date).format(DATE_FORMAT_FRONT);
  },
  maxWidth: 130,
  sortable: true,
  comparator: () => {},
};

const payment_amount = {
  headerName: "Total Allocated",
  field: "total_allocated",
  valueGetter: (params) => {
    return `$${
      params.data.invoice?.total_amount || params.data?.total_allocated
    }`;
  },
  maxWidth: 160,
  sortable: true,
  comparator: () => {},
};
const payment_paid = {
  headerName: "Total Paid",
  field: "paid",
  valueGetter: (params) => `$${params.data.paid}`,
  maxWidth: 130,
  sortable: true,
  comparator: () => {},
};
const payment_reference = {
  headerName: "Memo",
  field: "notes",
  valueGetter: (params) => `${params.data.notes || ""}`,
  sortable: true,
  comparator: () => {},
};
const payment_remaining = {
  headerName: "Balance",
  valueGetter: (params) =>
    `$${params.data.invoice?.balance || params.data?.balance}`,
  maxWidth: 150,
  sortable: true,
  comparator: () => {},
  field: "balance",
};
const payment_customer = {
  headerName: "Account",
  cellRenderer: (params) => {
    if (params.data.account) {
      return <Link to={`/customers/dashboard/${params.data.customer?.id}/orders`} target="_new" style={{ textDecoration: "none" }} >
        {params.data.account?.name};
      </Link>;
    }
    return  <Link to={`/customers/dashboard/${params.data.customer?.id}/orders`} target="_new" style={{ textDecoration: "none" }} >
      * {params.data.customer?.name};
    </Link>;
  },
  maxWidth: 250,
  sortable: true,
  comparator: () => {},
  field: "account__name",
};
const payment_link = {
  field: "",
  sorting: false,
  sort: false,
  sortable: false,
  autoHeight: true,
  filter: false,
  cellRenderer: (props) => <LinkRenderer {...props} />,
  flex: 2,
  minWidth: 120,
  maxWidth: 120,
};

const action = {
  field: "",
  sorting: false,
  sort: false,
  sortable: false,
  autoHeight: true,
  filter: false,
  cellRenderer: MatchSelector,
  minWidth: 120,
  maxWidth: 120,
};

function getPaymentMethod(t) {
  return t.method == "CHEQUE"
    ? "check"
    : t.method.replace("_", " ").toLowerCase();
}
function getReference(t) {
  if (t.reference) {
    return `#${t.reference}`;
  }
  if (t.cc_digits) {
    return `#${t.cc_digits}`;
  }
}
const payment_transactions = {
  headerName: "Transactions",
  cellRenderer: (params) => (
    <Stack>
      {params.data?.transactions
        ?.filter((t) => t.method != "CUSTOMER_CREDIT")
        .map((t) => (
          <Typography>
            {getPaymentMethod(t)} {getReference(t)}{" "}
            {t.voided && <small style={{ color: "red" }}>VOIDED</small>}
          </Typography>
        ))}
    </Stack>
  ),
};

const paymentTableColumns = [
  invoice_id,
  payment_customer,
  payment_date,
  payment_amount,
  payment_paid,
  payment_remaining,
  //payment_method,
  payment_transactions,
  payment_reference,
];

function get_payment_link(adds) {
  return {
    field: "",
    sorting: false,
    sort: false,
    sortable: false,
    autoHeight: true,
    filter: false,
    cellRenderer: (props) => <LinkRenderer {...props} {...adds} />,
    flex: 2,
    minWidth: 120,
    maxWidth: 120,
  };
}

export function getPaymentColumns(adds) {
  return paymentTableColumns.concat(get_payment_link(adds));
}

export const transactionTableColumns = [
  invoice_id,
  payment_customer,
  payment_amount,
  payment_remaining,
  payment_method,
  payment_reference,
  payment_date,
];

export default paymentTableColumns;
