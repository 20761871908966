import { Typography, Grid } from "@mui/material";
import PickListItem from "./PickListItem";

const PickList = ({ renderAsNotes, items }) => {
    const variants = Array.from(new Set(items.map(x => x.variantId)));
    const getItemsByVariantId = (variant) => renderAsNotes ?
        items.filter(x => x.note && x.note.length > 0 && x.variantId == variant) :
        items.filter(x => x.variantId == variant);

    const updatePickupStatusIfNeeded = (vendorId) => {

        //if (items.some)

        // let state = "NOT_PICKED_UP";
        // if (amount >= item.pickedAmount) {
        //     state = "PICKED_UP";
        // }

        // let pickedUpTimestamp = null;
        // if (state == "PICKED_UP") {
        //     pickedUpTimestamp = new Date().toISOString();
        // }

        // if (pickup.id) {
        //     dispatch(updateRouteReportPickupStatus({ id: pickup.id, state, pickedUpTimestamp }));
        // }
        // else {
        //     dispatch(createRouteReportPickupVendor({ vendor: pickup.vendor, state, pickedUpTimestamp }));
        // }
    }

    const renderer = (variant, index) => {
        const items = getItemsByVariantId(variant);

        return (
            <Grid item width="50%" key={index} sx={{ marginBottom: '5px' }}>
                <PickListItem
                    renderAsNote={renderAsNotes}
                    updatePickupStatusIfNeeded={updatePickupStatusIfNeeded}
                    items={items} />
            </Grid>);
    }

    if (items && items.length == 0) {
        if (renderAsNotes) {
            return <Typography>No notes to show</Typography>
        }
        else {
            return <Typography>No items to show</Typography>
        }
    }

    return (
        <>
            <Grid container columnSpacing={{ xs: 1 }}>
                {variants && variants.map((variant, index) => renderer(variant, index))}
            </Grid>
        </>
    )
}

export default PickList;
