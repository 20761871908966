import { Stack, Typography } from "@mui/material";

const salesmenTableCols = [
  {
    headerName: "ID",
    field: "id",
    maxWidth: 80,
    flex:1
  },
  {
    headerName: "First Name",
    field: "user__first_name",
    valueGetter: (params) => params?.data?.user?.first_name,
    maxWidth: 120,
    cellRenderer: (params) => (
      <Typography>
        <a href={`/salesmen/${params?.data?.id}`}>
          {params?.data?.user?.first_name}
        </a>
      </Typography>
    ),
    flex:2
  },
  {
    headerName: "Last Name",
    field: "user__last_name",
    valueGetter: (params) => params?.data?.user?.last_name,
    maxWidth: 120,
    flex:2
  },
  {
    headerName: "Email",
    field: "user__email",
    valueGetter: (params) => params?.data?.user?.email,
    maxWidth: 500,
    minWidth: 300,
    flex:3
  },
  {
    headerName: "Comission %",
    field: "percentage",
    maxWidth: 150,
    flex:2,
    cellRenderer:(params)=><Typography>{params.data.percentage}%</Typography>
  },
  {
    headerName: "Accounts",
    field: "accounts",
    cellRenderer:(params)=><Stack direction={'row'} spacing={1}>
      {params.data.attributed_accounts.slice(0,2).map(ac=><Typography>{ac.name}, </Typography>)}
      {params.data.attributed_accounts.length>2 && <Typography>And {params.data.attributed_accounts.length-2} others</Typography>}
    </Stack>,
    minWidth: 300,
    flex:10
  },
];

export default salesmenTableCols;
