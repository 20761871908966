import React from "react";
import AsyncAutocompleteSelect from "./AsyncAutocompleteSelect";
import routesApi from "../../distflowAPI/routesApi";

/**
 * @param {Omit<
 *  import("./AsyncAutocompleteSelect").AsyncAutocompleteSelectProps<
 *    import("../../redux/module/route").Route
 *  >,
 *  'fetchOptions'
 * >} props
 */
const RouteSelect = (props) => (
  <AsyncAutocompleteSelect
    fetchOptions={(searchTerm) =>
      routesApi
        .list(10, 1, [], [{ column: "name", value: searchTerm }, {column: "is_change", value: false}])
        .then((res) =>
          res.results.map((item) => ({
            value: item.id,
            label: item.name,
          }))
        )
    }
    {...props}
  />
);

export default RouteSelect;
