import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Grid,
  Stack,
  TextField,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Alert,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import Block from "../../components/Block";
import CustomerSelect from "../../components/AsyncSelects/CustomerSelect";
import {
  createAccountOverride,
  fetchAccount,
  fetchAccountOverrides,
  removeAccount,
  removeAccountOverride,
  updateAccount,
  updateAccountOverride,
} from "../../redux/module/account";
import useAppDispatch from "../../hooks/useAppDispatch";
import useAppSelector from "../../hooks/useAppSelector";
import UpchargeAddRefactoredForm from "../../components/UpchargeAddRefactoredForm";
import OverridesRefactoredTable from "../../components/OverridesRefactoredTable";
import ConfirmDialog from "../../components/ConfirmDialog";
import UpdateItemTitle from "../../components/UpdateItemTitle";
import PageLoading from "../../components/PageLoading";
import AccountNotes from "./AccountNotes";
import AccountRestrictions from "./AccountRestrictions";
import RestrictCategoryAccounts from "./RestrictCategoryAccounts";
import { listSalesmen } from "../../distflowAPI/salesmenApi";
import { listCustomerGroups } from "../../distflowAPI/territoriesApi";

import AccountEmails from "./accountEmails";
import AccountPhones from "./accountPhones";
import AccountTypeSelect from "../../components/AsyncSelects/AccountTypeSelect";
import TerritorySelect from "../../components/AsyncSelects/TerritorySelect";
import Separator from "../../components/Separator";
import StatementGroupSelect from "../../components/AsyncSelects/StatementGroupSelect";
import { cloneAccount } from "../../distflowAPI/accountsApi";
import RestrictVendorAccounts from "./RestrictVendorAccounts";

const UpdateAccount = () => {
  const { id } = useParams();
  const {
    account,
    accountOverrides,
    accountsLoading,
    accountOverridesLoading,
  } = useAppSelector((state) => state.account);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isOpenDiscard, setIsOpenDiscard] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [cleanOverrideFormFlag, setCleanOverrideFormFlag] = useState(false);
  const [overrides, setOverrides] = useState([]);
  const [salesMen, setSalesMen] = useState([]);
  const [accountTypes, setAccountsTypes] = useState([]);
  const [openCloneAccount, setOpenCloneAccount] = useState(false);
  useEffect(() => {
    listSalesmen(1, 50, [{ column: "search", value: "" }]).then((res) => {
      const newSalesMen = [];
      res.data.results.map((item) =>
        newSalesMen.push({
          label: `${item.user.first_name} ${item.user.last_name}`,
          value: item.id,
        })
      );
      setSalesMen(newSalesMen);
    });
  }, []);
  useEffect(() => {
    listCustomerGroups().then((res) => {
      const newAccountsTypes = [];
      res.data.results.map((item) =>
        newAccountsTypes.push({
          label: `${item.name}`,
          value: item.id,
        })
      );
      setAccountsTypes(newAccountsTypes);
    });
  }, []);
  const [accountInfo, setAccountInfo] = useState({
    name: "",
    territory: {
      value: null,
      label: "",
    },
    customer: {
      value: 1,
      label: "",
    },
    attributed_sales_man: {
      label: "",
      value: null,
    },
    account_type: {
      label: "",
      value: null,
    },
    address: "",
    invoice_net: "",
    internal_id: "",
    cn_pd_id: "",
    city: "",
    state: "",
    zipcode: "",
    billing_city: "",
    billing_state: "",
    billing_zip: "",
    billing_address: "",
    address_2: "",
    statement_group: { value: null, label: "" },
  });

  async function handleSubmit(event) {
    event.preventDefault();
    setIsSubmitting(true);
    console.log("updating account with: ", {
      accountId: id,
      accountInfo: {
        name: accountInfo.name,
        territory: accountInfo.territory?.value,
        customer: accountInfo.customer?.value,
        address: accountInfo.address,
        invoice_net: accountInfo.invoice_net,
        internal_id: accountInfo.internal_id,
        cn_pd_id: accountInfo.cn_pd_id,
        attributed_sales_man: accountInfo.attributed_sales_man?.value,
        account_type: accountInfo.account_type?.value,
        statement_group: accountInfo.statement_group,
      },
    });
    await dispatch(
      updateAccount({
        accountId: id,
        accountInfo: {
          name: accountInfo.name,
          territory: accountInfo.territory?.value,
          customer: accountInfo.customer?.value,
          address: accountInfo.address,
          invoice_net: accountInfo.invoice_net,
          internal_id: accountInfo.internal_id,
          cn_pd_id: accountInfo.cn_pd_id,
          attributed_sales_man: accountInfo.attributed_sales_man?.value,
          account_type: accountInfo.account_type?.value || null,
          city: accountInfo.city,
          state: accountInfo.state,
          zipcode: accountInfo.zipcode,
          billing_city: accountInfo.billing_city,
          billing_state: accountInfo.billing_state,
          billing_zip: accountInfo.billing_zip,
          billing_address: accountInfo.billing_address,
          address_2: accountInfo.address_2,
          statement_group: accountInfo.statement_group?.value || null,
        },
      })
    );
    setIsSubmitting(false);
  }

  useEffect(() => {
    if (id) {
      dispatch(fetchAccount(id));
      dispatch(fetchAccountOverrides(id));
    }
  }, [id]);

  useEffect(() => {
    if (account.id === +id) {
      setAccountInfo({
        name: account.name,
        territory: {
          value: account.territory?.id,
          label: account.territory?.name || "",
        },
        customer: {
          value: account.customer?.id,
          label: account.customer?.name,
        },
        address: account.address,
        invoice_net: account.invoice_net || "",
        internal_id: account.internal_id || "",
        cn_pd_id: account?.cn_pd_id || null,
        attributed_sales_man: {
          value: account?.attributed_sales_man?.id,
          label: account?.attributed_sales_man?.name || "",
        },
        account_type: {
          value: account?.account_type?.id,
          label: account?.account_type?.name || "",
        },
        city: account.city,
        state: account.state,
        zipcode: account.zipcode,
        billing_city: account.billing_city,
        billing_state: account.billing_state,
        billing_zip: account.billing_zip,
        billing_address: account.billing_address,
        address_2: account.address_2,
        statement_group: account.statement_group,
      });
      console.log("set account groups to: ", accountInfo.statement_group);
    }
  }, [account]);

  useEffect(() => {
    if (accountOverrides[0]?.account === +id || !accountOverrides.length) {
      setOverrides(accountOverrides);
    }
  }, [accountOverrides]);

  const getIsAllChargeInactive = () => {
    return !accountOverrides.filter((el) => el.is_current === true).length;
  };

  const handleDiscard = () => {
    dispatch(fetchAccount(id));
    setCleanOverrideFormFlag(true);
  };

  const handleDelete = async () => {
    const resp = await dispatch(removeAccount(id));
    if (resp.payload === id) {
      navigate(-1);
    }
  };

  return (
    <Box maxWidth="1394px" margin=" 30px auto 0">
      <PageLoading dataLoading={accountsLoading || accountOverridesLoading} />
      <CloneAccount
        open={openCloneAccount}
        setOpen={setOpenCloneAccount}
        accountInfo={{
          id: id,
          name: accountInfo.name,
          internal_id: accountInfo.internal_id,
        }}
      />
      <ConfirmDialog
        title="Discard updates?"
        open={isOpenDiscard}
        setOpen={setIsOpenDiscard}
        onConfirm={handleDiscard}
      >
        {`Are you sure you want to discard all changes?`}
      </ConfirmDialog>
      <ConfirmDialog
        title="Delete account?"
        open={isOpenDelete}
        setOpen={setIsOpenDelete}
        onConfirm={handleDelete}
      >
        {`Are you sure you want to delete this account?`}
      </ConfirmDialog>
      <form id="CategoryUpdate" onSubmit={handleSubmit}>
        <UpdateItemTitle
          item={"Account"}
          setIsOpenDelete={setIsOpenDelete}
          setIsOpenDiscard={setIsOpenDiscard}
          isSubmitting={isSubmitting}
          canClone={true}
          onClone={() => {
            setOpenCloneAccount(true);
          }}
        />
        <Grid
          container
          columnSpacing={{ xs: 1, sm: 3, xl: 1 }}
          margin="20px 0 0"
        >
          <Grid item md={9} lg={9} sx={{ maxWidth: "908px", width: "100%" }}>
            <Block>
              <Typography variant={"h6"} mb={2}>
                Account General Info
              </Typography>
              <TextField
                required
                name="name"
                data-testid={`test-name-id`}
                label="Account name"
                type="text"
                id="name"
                fullWidth
                autoComplete="off"
                value={accountInfo.name}
                onChange={(e) =>
                  setAccountInfo({ ...accountInfo, name: e.target.value })
                }
                sx={{ pt: 1, pb: 1 }}
              />
              <CustomerSelect
                label="Customer"
                name="customer"
                required
                value={accountInfo.customer}
                onChange={(_e, newValue) =>
                  setAccountInfo({ ...accountInfo, customer: newValue })
                }
              />
              <Separator />
              <Typography>Shipping Address</Typography>
              <Stack direction={"row"} spacing={1} mt={2}>
                <TextField
                  name="address"
                  label="Address"
                  data-testid={`test-address-id`}
                  required
                  type="text"
                  id="address"
                  autoComplete="off"
                  value={accountInfo.address}
                  onChange={(e) =>
                    setAccountInfo({ ...accountInfo, address: e.target.value })
                  }
                  fullWidth
                />
                <TextField
                  name="address_2"
                  label="Address #2"
                  data-testid={`test-address-id`}
                  type="text"
                  id="address_2"
                  autoComplete="off"
                  value={accountInfo.address_2}
                  onChange={(e) =>
                    setAccountInfo({
                      ...accountInfo,
                      address_2: e.target.value,
                    })
                  }
                  fullWidth
                />
                <TextField
                  name="city"
                  label="City"
                  data-testid={`test-address-id`}
                  required
                  type="text"
                  id="city"
                  autoComplete="off"
                  value={accountInfo.city}
                  onChange={(e) =>
                    setAccountInfo({ ...accountInfo, city: e.target.value })
                  }
                  fullWidth
                  sx={{ maxWidth: "15%" }}
                />
                <TextField
                  name="state"
                  label="State"
                  data-testid={`test-address-id`}
                  required
                  type="text"
                  id="state"
                  autoComplete="off"
                  value={accountInfo.state}
                  onChange={(e) =>
                    setAccountInfo({ ...accountInfo, state: e.target.value })
                  }
                  fullWidth
                  sx={{ maxWidth: "10%" }}
                />
                <TextField
                  name="zipcode"
                  label="Zip"
                  data-testid={`test-address-id`}
                  required
                  type="text"
                  id="zip"
                  autoComplete="off"
                  value={accountInfo.zipcode}
                  onChange={(e) =>
                    setAccountInfo({ ...accountInfo, zipcode: e.target.value })
                  }
                  fullWidth
                  sx={{ maxWidth: "10%" }}
                />
              </Stack>
              <Separator />
              <Typography>Billing Address</Typography>
              <Stack direction={"row"} spacing={1} mt={2}>
                <TextField
                  name="billing_address"
                  label="Address"
                  data-testid={`test-address-id`}
                  type="text"
                  id="address"
                  autoComplete="off"
                  value={accountInfo.billing_address}
                  onChange={(e) =>
                    setAccountInfo({
                      ...accountInfo,
                      billing_address: e.target.value,
                    })
                  }
                  fullWidth
                />
                <TextField
                  name="billing_city"
                  label="City"
                  data-testid={`test-address-id`}
                  type="text"
                  id="billing_city"
                  autoComplete="off"
                  value={accountInfo.billing_city}
                  onChange={(e) =>
                    setAccountInfo({
                      ...accountInfo,
                      billing_city: e.target.value,
                    })
                  }
                  fullWidth
                  sx={{ maxWidth: "20%" }}
                />
                <TextField
                  name="billing_state"
                  label="State"
                  data-testid={`test-address-id`}
                  type="text"
                  id="billing_state"
                  autoComplete="off"
                  value={accountInfo.billing_state}
                  onChange={(e) =>
                    setAccountInfo({
                      ...accountInfo,
                      billing_state: e.target.value,
                    })
                  }
                  fullWidth
                  sx={{ maxWidth: "20%" }}
                />
                <TextField
                  name="billing_zip"
                  label="Zip"
                  data-testid={`test-address-id`}
                  type="text"
                  id="billing_zip"
                  autoComplete="off"
                  value={accountInfo.billing_zip}
                  onChange={(e) =>
                    setAccountInfo({
                      ...accountInfo,
                      billing_zip: e.target.value,
                    })
                  }
                  fullWidth
                  sx={{ maxWidth: "20%" }}
                />
              </Stack>
              <StatementGroupSelect
                label="Statement Groups"
                name="StatementGroup"
                value={accountInfo.statement_group}
                onChange={(_e, newValue) =>
                  setAccountInfo({ ...accountInfo, statement_group: newValue })
                }
                required={false}
                sx={{ marginTop: 1 }}
              />
              <Separator />
              <Stack direction={"row"} spacing={1} mt={1}>
                <TextField
                  name="internal_id"
                  label="Internal Id"
                  data-testid={`test-internal_id`}
                  type="text"
                  id="internal_id"
                  fullWidth
                  autoComplete="off"
                  value={accountInfo.internal_id}
                  onChange={(e) =>
                    setAccountInfo({
                      ...accountInfo,
                      internal_id: e.target.value,
                    })
                  }
                />
                <TextField
                  name="cn_pd_id"
                  label="CN PD Number"
                  data-testid={`test-cn_pd_id`}
                  type="text"
                  id="cn_pd_id"
                  fullWidth
                  autoComplete="off"
                  value={accountInfo.cn_pd_id}
                  onChange={(e) =>
                    setAccountInfo({
                      ...accountInfo,
                      cn_pd_id: e.target.value,
                    })
                  }
                />
                <TextField
                  name="invoice_net "
                  label="Invoice net"
                  data-testid={`test-invoice-net-id`}
                  type="number"
                  id="invoice_net"
                  fullWidth
                  autoComplete="off"
                  value={accountInfo.invoice_net}
                  onChange={(e) =>
                    setAccountInfo({
                      ...accountInfo,
                      invoice_net: e.target.value,
                    })
                  }
                  sx={{ mt: 1 }}
                />
              </Stack>
              <Stack direction={"row"} spacing={1} mt={1}>
                <TerritorySelect
                  label="Territory (pricing)"
                  name="territory"
                  value={accountInfo.territory}
                  onChange={(_e, newValue) =>
                    setAccountInfo({ ...accountInfo, territory: newValue })
                  }
                />
                <AccountTypeSelect
                  label="Account Type"
                  name="account_type"
                  required={false}
                  value={accountInfo.account_type}
                  onChange={(_e, newValue) =>
                    setAccountInfo({ ...accountInfo, account_type: newValue })
                  }
                />
                <Autocomplete
                  fullWidth
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={"Attributed to"}
                      required={false}
                      helperText={"Attribute account to sales person"}
                      placeholder={"Attribute to sales person"}
                    />
                  )}
                  classes={{ popper: "autocomplete-popper" }}
                  value={accountInfo.attributed_sales_man}
                  onChange={(_e, newValue) =>
                    setAccountInfo({
                      ...accountInfo,
                      attributed_sales_man: newValue,
                    })
                  }
                  options={salesMen}
                  sx={{
                    "& .MuiInputBase-root": {
                      padding: "0 65px 0 9px",
                    },
                    "& .MuiInputBase-input": {
                      padding: "8.5px 4px 8.5px 5px !important",
                    },
                    "& .MuiFormControl-root": {
                      minHeight: "40px !important",
                    },
                    mt: 1,
                  }}
                />
              </Stack>
            </Block>
          </Grid>
        </Grid>
      </form>
      <Grid container columnSpacing={{ xl: 1 }} alignItems="stretch">
        <Grid item md={4.5}>
          <AccountEmails accountId={id} />
        </Grid>
        <Grid item md={4.5}>
          <AccountPhones accountId={id} />
        </Grid>
      </Grid>

      <AccountNotes />
      <AccountRestrictions />
      <RestrictCategoryAccounts />
      <UpchargeAddRefactoredForm
        fetchOverrides={fetchAccountOverrides}
        createOverride={createAccountOverride}
        cleanOverrideFormFlag={cleanOverrideFormFlag}
        setCleanOverrideFormFlag={setCleanOverrideFormFlag}
        elementKey="account"
      />
      <Grid
        container
        columnSpacing={{ xs: 1, sm: 3, xl: "50px" }}
        margin="20px 0 0"
      >
        <Grid item md={9} lg={9} sx={{ maxWidth: "908px", width: "100%" }}>
          <Block>
            <Typography component="p" variant="label" mb="11px">
              Account Upcharges{" "}
              {getIsAllChargeInactive() && (
                <span style={{ color: "red" }}>(No active up charge)</span>
              )}
            </Typography>
            <Box sx={{ margin: { xs: "0 -16px -16px", sm: "0 -24px -24px" } }}>
              <OverridesRefactoredTable
                overrides={overrides}
                fetchOverrides={fetchAccountOverrides}
                removeOverride={removeAccountOverride}
                updateOverride={updateAccountOverride}
                elementKey="account"
              />
            </Box>
          </Block>
        </Grid>
      </Grid>
    </Box>
  );
};
export default UpdateAccount;

function CloneAccount({ open, setOpen, accountInfo }) {
  const [name, setName] = useState(accountInfo?.name);
  const [internalId, setInternalId] = useState(accountInfo?.internal_id);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  function onConfirm() {
    setIsSaving(true);
    const payload = {
      name,
      internal_id: internalId,
    };
    setError("");
    setSuccess({});
    cloneAccount(accountInfo?.id, payload)
      .then(
        (res) => {
          window.open(`/accounts/${res.data.id}`, "_blank", "noreferrer");
          setSuccess({
            accountId: res.data.id,
          });
        },
        (err) => {
          console.log("error is: ", err?.response?.data);
          if (err?.response?.data) {
            setError(JSON.stringify(err?.response?.data));
          }
        }
      )
      .finally(() => setIsSaving(false));
  }

  useEffect(() => {
    setName(accountInfo?.name);
    setInternalId(accountInfo?.internal_id);
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      maxWidth="md"
      sx={{
        "& .MuiPaper-root": {
          width: "100%",
          maxWidth: "500px",
        },
      }}
    >
      <DialogTitle id="confirm-dialog">Clone Account</DialogTitle>
      <DialogContent>
        <Stack spacing={1} padding={2}>
          {Object.keys(success).length > 0 && (
            <Alert>
              <a
                href={`/accounts/${success.accountId}`}
                target="_blank"
                rel="noreferrer"
              >
                Clone created successfully, click to open.
              </a>
            </Alert>
          )}
          {error && <Alert severity={"error"}>{error}</Alert>}
          <TextField
            name={"name"}
            value={name}
            onChange={(e) => setName(e.target.value)}
            label={"Name"}
          />
          <TextField
            name={"internal_id"}
            value={internalId}
            onChange={(e) => setInternalId(e.target.value)}
            label={"internal Id"}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={() => setOpen(false)}>
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            onConfirm();
          }}
          color={"success"}
          disabled={isSaving}
        >
          Clone
        </Button>
      </DialogActions>
    </Dialog>
  );
}
