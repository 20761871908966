import React from "react";
import AsyncAutocompleteSelect from "./AsyncAutocompleteSelect";
import categoriesApi from "../../distflowAPI/categoriesApi";
import { listVendors } from "../../distflowAPI/vendorsApi";
import { listTags } from "../../distflowAPI/tagsApi";

/**
 * @param {Omit<
 *  import("./AsyncAutocompleteSelect").AsyncAutocompleteSelectProps<
 *    import("../../redux/module/category").Category
 *  >,
 *  'fetchOptions'
 * >} props
 */
const TagSelect = (props) => (
  <AsyncAutocompleteSelect
    fetchOptions={(searchTerm) =>
      listTags(10, 1, [], [{ column: "search", value: searchTerm }])
        .then((res) =>
          res.results.map((item) => ({
            value: item.id,
            label: item.name,
          }))
        )
    }
    {...props}
  />
);

export default TagSelect;
