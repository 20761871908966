import api from "./api";
import { filtersToApi, orderingToApi } from "./common";

/**
 * @example
 * // basic example
 * const testApi = new ApiBase("/test");
 * // example with extra api route
 * class ExtraApi extends ApiBase {
 *   someExtraApi() {
 *     return api.get("/api/extra-api-url/some-extra-api/");
 *   }
 * }
 *
 * const extraApi = new ExtraApi("/extra-api-url")
 */
export default class ApiBase {
  urlPrefix;
  /**
   * @param {string} urlPrefix
   */
  constructor(urlPrefix) {
    this.urlPrefix = urlPrefix;
  }

  async list(
    pageSize = 10,
    page = 1,
    orderBy = [],
    filterBy = [],
    search_params = null,
  ) {
    const orderByString = orderingToApi(orderBy);
    const filterByString = filtersToApi(filterBy, search_params);
    const params = `page_size=${pageSize}&page=${page}&ordering=${orderByString}&${filterByString}`;
    const result = await api.get(`/api${this.urlPrefix}/?${params}`);
    return result.data;
  }

  findById(id) {
    return api.get(`/api${this.urlPrefix}/${id}/`);
  }

  create(data) {
    return api.post(`/api${this.urlPrefix}/`, data);
  }

  update(id, data) {
    return api.patch(`/api${this.urlPrefix}/${id}/`, data);
  }

  delete(id) {
    return api.delete(`/api${this.urlPrefix}/${id}/`);
  }
}
