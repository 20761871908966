import React, { useCallback, useState } from "react";
import { Box, Chip, Grid, Stack, TextField, Typography } from "@mui/material";
import TagSelect from "../../components/AsyncSelects/TagSelect";
import AccountSelect from "../../components/AsyncSelects/AccountSelect";
import debounce from "lodash.debounce";
import { useSearchParams } from "react-router-dom";

const AutoOrdersAddProduct = ({
                                filterParams,
                                setFilterParams,
                                setAccount,
                                account,
                                selectedTag,
                                setSelectedTag,
                                isShowSearchAccount,
                                removeOrders
                              }) => {

  const [searchParams, setSearchParams] = useSearchParams();

  const [search, setSearch] = useState(filterParams.search || "");
  const [sku, setSku] = useState(filterParams.sku || "");
  const [category, setCategory] = useState(filterParams.category || "");
  const [vendor, setVendor] = useState(filterParams.vendor || "");

  const handleTagsChange = (_event, newValue) => {
    setSelectedTag(newValue);
    setFilterParams({ tags: newValue.map((item) => item.value).join(",") });
  };

  const handleTagDelete = (item) => {
    setSelectedTag(prev => prev.filter(el => el.value !== item.value));
    const tags = filterParams.tags.split(",").filter(el => +el !== item.value).join(",");
    setFilterParams({ tags });
  };


  const handleSetSearchParams = useCallback(debounce((input, value) => {
    setFilterParams({ [input]: value });
  }, 500), [searchParams]);


  const handleSkuChange = (e) => {
    setSku(e.target.value);
    handleSetSearchParams("sku", e.target.value);
  };
  const handleCategoryChange = (e) => {
    setCategory(e.target.value);
    handleSetSearchParams("category", e.target.value);
  };
  const handleVendorChange = (e) => {
    setVendor(e.target.value);
    handleSetSearchParams("vendor", e.target.value);
  };
  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    handleSetSearchParams("search", e.target.value);
  };
  return (
    <>
      <Typography fontSize="23px" fontWeight={600} mb="17px">
        Add Products
      </Typography>
      <Box mb={2}>
        <Grid
          container
          columnSpacing={{ xs: 1, sm: "5px" }}
          margin="3px 0 0"
        >
          {isShowSearchAccount &&
            <Grid item width="100%" xl={12}>
              <Box>
                <Typography
                  fontSize="14px"
                  component="p"
                  variant="label"
                  mb="9px"
                >
                  Account
                </Typography>
                <AccountSelect
                  required
                  name="account"
                  value={account}
                  onChange={(event, newValue) => {
                    if (newValue && newValue.value) {
                      setAccount(newValue);
                      setFilterParams({ accountId: newValue.value });
                    } else {
                      setAccount({
                        label: "",
                        value: null
                      });
                      setFilterParams({ accountId: "" });
                      removeOrders();
                    }
                  }}
                />
              </Box>
            </Grid>
          }

          <Grid item width="100%" xl={12}>
            <Box>
              <Typography
                fontSize="14px"
                component="p"
                variant="label"
                mb="9px"
              >
                Search
              </Typography>
              <TextField
                fullWidth
                disabled={!account?.value}
                data-testid="test-search-id"
                variant="outlined"
                value={search}
                onChange={handleSearchChange}
              />
            </Box>
          </Grid>
          <Grid item width="100%" xl={6}>
            <Box>
              <Typography
                fontSize="14px"
                component="p"
                variant="label"
                mb="9px"
              >
                SKU
              </Typography>
              <TextField
                fullWidth
                disabled={!account?.value}
                variant="outlined"
                value={sku}
                onChange={handleSkuChange}
              />
            </Box>
          </Grid>
          <Grid item width="100%" xl={6}>
            <Box>
              <Typography
                fontSize="14px"
                component="p"
                variant="label"
                mb="9px"
              >
                Category
              </Typography>
              <TextField
                fullWidth
                disabled={!account?.value}
                variant="outlined"
                value={category}
                onChange={handleCategoryChange}
              />
            </Box>
          </Grid>
          <Grid item width="100%" xl={6}>
            <Box>
              <Typography
                fontSize="14px"
                component="p"
                variant="label"
                mb="9px"
              >
                Vendor
              </Typography>
              <TextField
                fullWidth
                variant="outlined"
                disabled={!account?.value}
                value={vendor}
                onChange={handleVendorChange}
              />
            </Box>
          </Grid>
          <Grid item width="100%" xl={6}>
            <Box>
              <Typography
                fontSize="14px"
                component="p"
                variant="label"
                mb="9px"
              >
                TAGS
              </Typography>
              <TagSelect
                limitTags={3}
                multiple
                disabled={!account?.value}
                id="multiple-limit-tags"
                value={selectedTag}
                onChange={handleTagsChange}
                extraStyles={{
                  "& .MuiButtonBase-root": {
                    display: "none"
                  },
                  "& .MuiAutocomplete-tag": {
                    display: "none"
                  }
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Stack direction="row" flexWrap="wrap" gap="7px">
        {selectedTag.map((item) => (
          <Chip
            key={item.value}
            id={item.value}
            label={item.label}
            onDelete={() => handleTagDelete(item)}
          />
        ))}
      </Stack>
    </>
  );
};

export default AutoOrdersAddProduct;