import api from "./api";
import { orderingToApi } from "./common";

export async function listEmailQueue(pageSize, page, filter = [], order = []) {
  const { recipients, subject, emailed, start_date, end_date, search } = filter;
  console.log("filter", filter);
  const orderingParam = order.length ? `&ordering=${orderingToApi(order)}` : "";
  const params = `page_size=${pageSize}&page=${page}&search=${search}&recipients=${recipients}&subject=${subject}&emailed=${emailed}&start_date=${start_date}&end_date=${end_date}${orderingParam}`;

  const result = await api.get(`/api/emailqueue/queue/?${params}`);
  return result;
}

export async function processEmailQueue() {
  const result = await api.get(`/api/emailqueue/process/`);
  return result;
}
