import React, { useEffect, useState } from "react";
import Block from "../../components/Block";
import {
  Box,
  FormControlLabel,
  Grid,
  OutlinedInput,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useSearchDebounce } from "../../utils";
import ArrowUpwardOutlinedIcon from "@mui/icons-material/ArrowUpwardOutlined";
import ArrowDownwardOutlinedIcon from "@mui/icons-material/ArrowDownwardOutlined";
import { DATE_FORMAT } from "../../utils/constants";
import dayjs from "dayjs";
import DatePicker2 from "../../components/DatePicker2";

const PaymentFilters = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [filterDB, setFilterDB] = useSearchDebounce();
  const [amountWay, setAmountWay] = useState("amount_paid__gte");
  const [paymentDateWay, setPaymentDateWay] = useState("payment_date__gte");
  const [balanceWay, setBalanceWay] = useState("balance__gt");

  const [filters, setFilters] = useState({
    reference: searchParams.get("reference") || "",
    account__name: searchParams.get("account__name") || "",
    customer__name: searchParams.get("customer__name") || "",
    [amountWay]:
      searchParams.get("amount_paid__gte") ||
      searchParams.get("amount_paid__lte") ||
      "",
    [paymentDateWay]:
      searchParams.get("payment_date__gte") ||
      searchParams.get("payment_date__lte") ||
      "",
    [balanceWay]:
      searchParams.get("balance__gt") || searchParams.get("balance__lt") || "",
  });

  const handleChange = (value) => {
    setSearchParams((params) => {
      const newParams = new URLSearchParams(params);
      Object.keys(value).forEach((key) => {
        if (!!value[key]) {
          newParams.set(key, value[key]);
        } else {
          newParams.delete(key);
        }
      });
      return newParams;
    });
  };

  useEffect(() => {
    const isSearchParamsEmpty = Array.from(searchParams.values()).every(
      (value) => value === null || value === "",
    );
    if (isSearchParamsEmpty) {
      setFilters({
        account__name: "",
        [amountWay]: "",
        [paymentDateWay]: "",
        [balanceWay]: "",
        method: "",
      });
    }
  }, [searchParams]);

  useEffect(() => {
    setFilterDB(filters);
  }, [filters]);

  useEffect(() => {
    handleChange(filterDB);
  }, [filterDB]);

  const handleWayChange = (way, field) => {
    const reverse = {
      balance: way === "balance__gt" ? "balance__lt" : "balance__gt",
      amount:
        way === "amount_paid__gte" ? "amount_paid__lte" : "amount_paid__gte",
      paymentDate:
        way === "payment_date__gte" ? "payment_date__lte" : "payment_date__gte",
    }[field];

    if (field === "balance") {
      setBalanceWay(way);
    } else if (field === "amount") {
      setAmountWay(way);
    } else if (field === "paymentDate") {
      setPaymentDateWay(way);
    }

    setFilters((prev) => ({
      ...prev,
      [way]: prev[reverse],
      [reverse]: prev[way],
    }));
  };

  return (
    <Grid container spacing={1} rowGap="10px">
      <Grid item md={2}>
        <Typography mb="5px">Reference</Typography>
        <OutlinedInput
          size="small"
          sx={{ height: "40px" }}
          fullWidth
          value={filters.reference}
          onChange={(e) =>
            setFilters({ ...filters, reference: e.target.value })
          }
        />
      </Grid>
      <Grid item md={2}>
        <Typography mb="5px">Account</Typography>
        <OutlinedInput
          size="small"
          sx={{ height: "40px" }}
          fullWidth
          value={filters.account__name}
          onChange={(e) =>
            setFilters({ ...filters, account__name: e.target.value })
          }
        />
      </Grid>
      <Grid item md={2}>
        <Typography mb="5px">Customer</Typography>
        <OutlinedInput
          size="small"
          sx={{ height: "40px" }}
          fullWidth
          value={filters.customer__name}
          onChange={(e) =>
            setFilters({ ...filters, customer__name: e.target.value })
          }
        />
      </Grid>
      <Grid item md={2}>
        <Typography mb="5px">Total</Typography>
        <Stack direction={"row"} gap={1}>
          <OutlinedInput
            size="small"
            sx={{ height: "40px" }}
            fullWidth
            value={filters[amountWay]}
            type="number"
            onChange={(e) =>
              setFilters({ ...filters, [amountWay]: e.target.value })
            }
            endAdornment={
              <Box
                sx={{
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                }}
                onClick={() =>
                  handleWayChange(
                    amountWay === "amount_paid__gte"
                      ? "amount_paid__lte"
                      : "amount_paid__gte",
                    "amount",
                  )
                }
              >
                {amountWay === "amount_paid__gte" ? (
                  <ArrowUpwardOutlinedIcon fontSize="small" />
                ) : (
                  <ArrowDownwardOutlinedIcon fontSize="small" />
                )}
              </Box>
            }
          />
        </Stack>
      </Grid>
      <Grid item md={2}>
        <Typography mb="5px">Balance</Typography>
        <Stack direction={"row"} gap={1}>
          <OutlinedInput
            size="small"
            sx={{ height: "40px" }}
            fullWidth
            value={filters[balanceWay]}
            type="number"
            onChange={(e) =>
              setFilters({ ...filters, [balanceWay]: e.target.value })
            }
            endAdornment={
              <Box
                sx={{
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                }}
                onClick={() =>
                  handleWayChange(
                    balanceWay === "balance__gt"
                      ? "balance__lt"
                      : "balance__gt",
                    "balance",
                  )
                }
              >
                {balanceWay === "balance__gt" ? (
                  <ArrowUpwardOutlinedIcon fontSize="small" />
                ) : (
                  <ArrowDownwardOutlinedIcon fontSize="small" />
                )}
              </Box>
            }
          />
        </Stack>
      </Grid>
      <Grid
        item
        className="date-picker"
        md={2}
      >
        <Stack direction="row" gap={1} alignItems="center" mb="5px">
          <Typography>Payment date</Typography>
          <Box
            sx={{
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
            }}
            onClick={() =>
              handleWayChange(
                paymentDateWay === "payment_date__gte"
                  ? "payment_date__lte"
                  : "payment_date__gte",
                "paymentDate",
              )
            }
          >
            {paymentDateWay === "payment_date__gte" ? (
              <ArrowUpwardOutlinedIcon fontSize="small" />
            ) : (
              <ArrowDownwardOutlinedIcon fontSize="small" />
            )}
          </Box>
        </Stack>
        <DatePicker2
          controlledValue={
            paymentDateWay ? dayjs.utc(filters[paymentDateWay]) : null
          }
          setControlledValue={(newValue) => {
            setFilters({
              ...filters,
              [paymentDateWay]: dayjs(newValue).format(DATE_FORMAT),
            });
          }}
          additionlStyles={{
            height: "40px",
            width: "100%",
            "& .MuiInputBase-root.Mui-error .MuiOutlinedInput-notchedOutline": {
              borderColor: "#DBDFEA",
            },
          }}
        />
      </Grid>
      {/** 
      <Grid xs={12} item>
        <Typography mb="5px">Payment method</Typography>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          sx={{ border: "none !important", padding: "0 !important" }}
          value={filters.method}
          onChange={(e) => setFilters({ ...filters, method: e.target.value })}
        >
          <FormControlLabel value="" control={<Radio />} label="All" />
          <FormControlLabel
            value="CREDIT_CARD"
            control={<Radio />}
            label="Credit card"
          />
          <FormControlLabel
            value="QUICK_PAY"
            control={<Radio />}
            label="Quick Pay"
          />
          <FormControlLabel value="CASH" control={<Radio />} label="Cash" />
          <FormControlLabel value="ACH" control={<Radio />} label="ACH" />
          <FormControlLabel
            value="CUSTOMER_CREDIT"
            control={<Radio />}
            label="Customer credit"
          />
          <FormControlLabel value="CHEQUE" control={<Radio />} label="Check" />
          <FormControlLabel
            value="WIRE_TRANSFER"
            control={<Radio />}
            label="Wire transfer"
          />
        </RadioGroup>
      </Grid>
      */}
    </Grid>
  );
};

export default PaymentFilters;
