import { Divider, IconButton, Stack } from "@mui/material";
import { ReactComponent as NavArrow } from "../../images/navigation-arrow.svg";

const SliderArrows = (props) => {
  return (
    <Stack
      direction="row"
      sx={{
        "& button": {
          width: "35px",
          padding: "35px",
          bgcolor: "#EBEBEB",
          borderRadius: 0,
          "&:hover": { bgcolor: "#e8e8e8" },
        },
        borderRadius: "5px",
        overflow: "hidden",
      }}
    >
      <IconButton onClick={props.onPrevClick}>
        <NavArrow />
      </IconButton>
      <Divider orientation="vertical" sx={{ borderColor: "#DEDEDE" }} />
      <IconButton onClick={props.onNextClick} sx={{ "& svg": { transform: "rotate(180deg)" } }}>
        <NavArrow />
      </IconButton>
    </Stack>
  );
};

export default SliderArrows;
