import {
  Grid,
  Box,
  TextField,
  Typography,
  Button,
  Stack,
  Tab,
  Alert,
} from "@mui/material";
import Block from "../../components/Block";
import { useParams } from "react-router-dom";
import UpdateItemTitle from "../../components/UpdateItemTitle";
import CommissionInput from "./ComissionInput";
import { useEffect, useState } from "react";
import ConfirmDialog from "../../components/ConfirmDialog";
import CommissionPayoutsTable from "./CommissionPayoutsTable";
import CheckIcon from "@mui/icons-material/Check";
import DeleteIcon from "@mui/icons-material/Delete";
import CommissionTable from "./CommisionTable";
import { getSalesman, patchSalesman } from "../../distflowAPI/salesmenApi";
import { TabContext, TabPanel, TabList } from "@mui/lab";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";

import ApartmentOutlinedIcon from "@mui/icons-material/ApartmentOutlined";
import SalesManInvoices from "./SalesManInvoices";
import AccountSelect from "../../components/AsyncSelects/AccountSelect";

const tabsList = [
  { id: "personal", label: "Personal", icon: <AccountCircleOutlinedIcon /> },
  { id: "comissions", label: "Commissions", icon: <ApartmentOutlinedIcon /> },
  { id: "payouts", label: "Payouts", icon: <LocalOfferOutlinedIcon /> },
  { id: "invoices", label: "Invoices", icon: <LocalOfferOutlinedIcon /> },
];
export default function UpdateSalesmen() {
  const { id } = useParams();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isOpenDiscard, setIsOpenDiscard] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [actionVal, setActionVal] = useState("personal");
  const commissions = 14;
  const handleDiscard = () => {};
  const handleDelete = async () => {};

  return (
    <>
      <Box maxWidth="1394px" margin=" 30px auto 0">
        <ConfirmDialog
          title="Delete salesman?"
          open={isOpenDelete}
          setOpen={setIsOpenDelete}
          onConfirm={handleDelete}
        >
          {`Are you sure you want to delete this salesman?`}
        </ConfirmDialog>

        <Grid
          container
          columnSpacing={{ xs: 1, sm: 3, xl: "50px" }}
          margin="20px 0 0"
        >
          <Grid item md={12} lg={12} sx={{ maxWidth: "908px", width: "100%" }}>
            <Block
              sx={{
                padding: { xs: "16px 0", sm: "13px 0 24px" },
                marginBottom: "24px",
              }}
            >
              <TabContext value={actionVal}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList
                    aria-label="lab API tabs example"
                    variant="scrollable"
                    onChange={(e, v) => setActionVal(v)}
                  >
                    {tabsList.map((el) => (
                      <Tab
                        key={el.id}
                        icon={el.icon}
                        value={el.id}
                        iconPosition="start"
                        label={el.label}
                      />
                    ))}
                  </TabList>
                </Box>
                <TabPanel value="personal">
                  <SalesManInfo salesManId={id} />
                </TabPanel>
                <TabPanel value="comissions">
                  <Comissions salesManId={id} />
                </TabPanel>
                <TabPanel value="payouts">
                  <Payouts salesManId={id} />
                </TabPanel>
                <TabPanel value="invoices">
                  <Invocies salesManId={id} />
                </TabPanel>
              </TabContext>
            </Block>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

function Comissions({ salesManId }) {
  return <CommissionTable salesManId={salesManId} />;
}

function Payouts({ salesManId }) {
  return <CommissionPayoutsTable salesManId={salesManId} />;
}

function Invocies({ salesManId }) {
  return <SalesManInvoices salesManId={salesManId} />;
}

function SalesManInfo({ salesManId }) {
  const [salesManData, setSalesManData] = useState({});
  const [showUpdateCommission, setShowUpdateCommission] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [percentage, setPercentage] = useState("");
  const [password, setPassword] = useState(null);
  const [selectedAccounts, setSelectedAccounts] = useState([]);
  const [accountSearch, setAccountSearch] = useState("");
  const [errors, setErrors] = useState({});
  const [success, setSuccess] = useState("");
  useEffect(() => {
    getSalesman(salesManId).then((res) => {
      setFirstName(res.data.user.first_name);
      setLastName(res.data.user.last_name);
      setEmail(res.data.user.email);
      setPercentage(res.data.percentage);
      setPhoneNumber(res.data.phone_number);
      const newAttributedAccounts = [];
      res.data.attributed_accounts.map((ac) =>
        newAttributedAccounts.push({
          label: ac.name,
          value: ac.id,
        }),
      );
      setSelectedAccounts(newAttributedAccounts);
    });
  }, [salesManId]);

  function handleSubmit(e) {
    setSuccess("");
    setErrors({});
    e.preventDefault();
    const payload = {
      first_name: firstName,
      last_name: lastName,
      email,
      percentage,
      phone_number: phoneNumber,
      attributed_accounts: selectedAccounts.map((item) => item.value),
    };
    patchSalesman(salesManId, payload).then(
      (res) => {
        setSuccess("Updated successfullt");
      },
      (err) => setErrors(err.response.data),
    );
  }
  return (
    <>
      <Stack>
        <Stack>
          <form id="updateSalesMan" onSubmit={handleSubmit}>
            {success && <Alert sx={{ marginBottom: 2 }}>{success}</Alert>}
            {Object.keys(errors).map((ek) => (
              <Alert sx={{ marginBottom: 2 }} severity={"error"}>
                {ek}:{errors[ek]}
              </Alert>
            ))}
            <TextField
              required={true}
              name="firstName"
              label="First Name"
              value={firstName}
              onChange={(e) => {
                setFirstName(e.target.value);
              }}
              fullWidth
              sx={{ margin: "8px" }}
            />
            <TextField
              required={true}
              name="name"
              label="First Name"
              value={lastName}
              onChange={(e) => {
                setLastName(e.target.value);
              }}
              fullWidth
              sx={{ margin: "8px" }}
            />
            <TextField
              required={true}
              name="name"
              label="Email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              fullWidth
              sx={{ margin: "8px" }}
              type="email"
            />
            <TextField
              required={false}
              name="name"
              label="Password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              fullWidth
              sx={{ margin: "8px" }}
              type="password"
            />
            <TextField
              required={true}
              name="percentage"
              label="Comission %"
              value={percentage}
              onChange={(e) => {
                setPercentage(e.target.value);
              }}
              fullWidth
              sx={{ margin: "8px" }}
              type="number"
            />
            <AccountSelect
              multiple
              style={{ margin: "8px" }}
              name="accounts"
              label="Salesman's Accounts"
              required
              value={selectedAccounts}
              onChange={(_event, newValue) => {
                setSelectedAccounts(newValue);
              }}
              setAccountSearch={setAccountSearch}
              required={false}
            />
          </form>
        </Stack>
        <Stack justifyContent={"end"} alignItems={"end"}>
          <Button
            variant="contained"
            color="success"
            sx={{ maxWidth: "10%" }}
            form="updateSalesMan"
            type="submit"
          >
            Save
          </Button>
        </Stack>
      </Stack>
    </>
  );
}
