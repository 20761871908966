import React, { useEffect, useState } from "react";
import AutoOrderConfigurations from "../../pages/manageAutoOrders/AutoOrderConfigurations";
import { cleanCustomerAutoOrders, fetchCustomerAutoOrders } from "../../redux/module/customer";
import useAppDispatch from "../../hooks/useAppDispatch";
import useAppSelector from "../../hooks/useAppSelector";
import { useSearchParamsState } from "react-use-search-params-state";
import AutoOrdersAddProduct from "../../pages/manageAutoOrders/AutoOrdersAddProduct";
import AutoOrdersProductsList from "../../pages/manageAutoOrders/AutoOrdersProductsList";
import { useSearchParams } from "react-router-dom";
import { fetchProductVariantsList } from "../../redux/module/product";
import { Box } from "@mui/material";

const filtersDefaults = {
  accountId: { type: "number", default: null },
  category: { type: "string", default: "" },
  vendor: { type: "string", default: "" },
  search: { type: "string", default: "" },
  sku: { type: "string", default: "" },
  day: { type: "string", default: "all" },
  tags: { type: "string", default: "" }
};

const CustomerAutoOrders = ({ account, day, setDay }) => {

  const [filterParams, setFilterParams] = useSearchParamsState(filtersDefaults);
  const [searchParams] = useSearchParams();

  const dispatch = useAppDispatch();
  const { customerAutoOrders, customersLoading } = useAppSelector(
    (state) => state.customer
  );
  const { variants } = useAppSelector(
    (state) => state.product
  );

  const [updateTableFlag, setUpdateTableFlag] = useState(false);
  const [selectedTag, setSelectedTag] = useState([]);


  useEffect(() => {
    if (account && account.value) {
      dispatch(fetchCustomerAutoOrders({ accountId: account.value, day }));
    }
  }, [account, day, updateTableFlag]);

  useEffect(() => {
    if (account && account.value) {
      dispatch(fetchProductVariantsList({
        pageSize: 20,
        page: 1,
        filters: [{ value: account.value, column: "accountId" }],
        searchParams: searchParams
      }));
    }
  }, [searchParams, account]);

  useEffect(() => {
    return () => {
      dispatch(cleanCustomerAutoOrders());
    };
  }, []);

  const handleProductsUpdate = () => {
    setUpdateTableFlag(!updateTableFlag);
  };
  
  const removeOrders = () => {
    dispatch(cleanCustomerAutoOrders());
  }

  return (
    <>
      <Box mb={2}>
        <AutoOrderConfigurations
          day={day}
          onChange={(value) => setDay(value)}
          accountOrders={customerAutoOrders}
          account={account}
          title=""
          isLoading={customersLoading}
          updateAccountRelated={handleProductsUpdate}
          removeOrders={removeOrders}
        />
      </Box>     
      {account.value && 
        <>
          <AutoOrdersAddProduct
            filterParams={filterParams}
            setFilterParams={setFilterParams}
            account={account}
            selectedTag={selectedTag}
            setSelectedTag={setSelectedTag}
          />
          <AutoOrdersProductsList
            account={account}
            updateAccountRelated={handleProductsUpdate}
            variants={variants}
            accountOrders={customerAutoOrders}
          />
        </>
       }     
    </>
  );
};

export default CustomerAutoOrders;