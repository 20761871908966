import { AddCircleOutline, DeleteOutline } from "@mui/icons-material";
import {
  IconButton,
  Input,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { REDUCER_ACTIONS } from "../config";
function InvoiceFees({ data, dispatch }) {
  return (
    <Stack spacing={1}>
      <Stack direction={"row"} spacing={1}>
        <Typography variant="h6">Fees</Typography>
        <IconButton
          onClick={() => dispatch({ type: REDUCER_ACTIONS.ADD_FEE })}
          color="success"
        >
          <AddCircleOutline />
        </IconButton>
      </Stack>
      {data.fees.length == 0 && (
        <Typography>
          This invoice has no fees, click the button below to create one
        </Typography>
      )}
      {data.fees.length > 0 && (
        <Table sx={{ marginBottom: 2 }}>
          <TableBody>
            <TableRow>
              <TableCell>
                {" "}
                <Typography fontSize="14px" fontWeight={600} mb="0px">
                  Description
                </Typography>
              </TableCell>
              <TableCell>
                <Typography fontSize="14px" fontWeight={600} mb="0px">
                  Value
                </Typography>
              </TableCell>
              <TableCell>
                <Typography></Typography>
              </TableCell>
            </TableRow>
            {data.fees.map((fee, index) => (
              <TableRow>
                <TableCell style={{ width: "60%" }}>
                  <Input
                    value={fee.name}
                    onChange={(e) =>
                      dispatch({
                        type: REDUCER_ACTIONS.UPDATE_FEE,
                        name: "name",
                        value: e.target.value,
                        index,
                      })
                    }
                    fullWidth
                  />
                </TableCell>
                <TableCell style={{ width: "20%" }}>
                  <Stack direction={"row"} alignItems={"center"} spacing={1}>
                    <Typography>$</Typography>
                    <Input
                      value={fee.value}
                      onChange={(e) =>
                        dispatch({
                          type: REDUCER_ACTIONS.UPDATE_FEE,
                          name: "value",
                          value: e.target.value,
                          index,
                        })
                      }
                      fullWidth
                    />
                  </Stack>
                </TableCell>
                <TableCell width={"5%"}>
                  <IconButton
                    color="error"
                    onClick={() => {
                      dispatch({ type: REDUCER_ACTIONS.DELETE_FEE, index });
                    }}
                  >
                    <DeleteOutline />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </Stack>
  );
}
export default InvoiceFees;
