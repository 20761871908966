import React, { useEffect, useState } from "react";
import { Box, FormControlLabel, Grid, OutlinedInput, Radio, RadioGroup, Stack, Typography } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import ArrowUpwardOutlinedIcon from "@mui/icons-material/ArrowUpwardOutlined";
import ArrowDownwardOutlinedIcon from "@mui/icons-material/ArrowDownwardOutlined";
import { useSearchDebounce } from "../../../utils";
import Block from "../../../components/Block";

const RoutesFilterBlock = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [filterDB, setFilterDB] = useSearchDebounce();
  const [filters, setFilters] = useState({
    name: "",
    vendor: "",
    account: "",
  });

  const handleChange = (value) => {
    setSearchParams((params) => {
      const newParams = new URLSearchParams(params);
      Object.keys(value).forEach(key => {
        if (!!value[key]) {
          newParams.set(key, value[key]);
        } else {
          newParams.delete(key);
        }
      });
      return newParams;
    });
  };

  useEffect(() => {
    setFilterDB(filters);
  }, [filters]);


  useEffect(() => {
    handleChange(filterDB);
  }, [filterDB]);
  

  return (
    <Block>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={4}>
          <Typography marginBottom={1}>Route</Typography>
          <OutlinedInput
            size="small"
            sx={{ height: "40px" }}
            fullWidth
            value={filters.name}
            onChange={(e) => setFilters({ ...filters, name: e.target.value })}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography marginBottom={1}>Vendor</Typography>
          <OutlinedInput
            size="small"
            sx={{ height: "40px" }}
            fullWidth      
            value={filters.vendor}
            onChange={(e) => setFilters({ ...filters, vendor: e.target.value })}
          />
        </Grid>  
        <Grid item xs={12} sm={4}>
          <Typography marginBottom={1}>Account</Typography>
          <OutlinedInput
            size="small"
            sx={{ height: "40px" }}
            fullWidth
      
            value={filters.account}
            onChange={(e) => setFilters({ ...filters, account: e.target.value })}
          />
        </Grid>    
      </Grid>
    </Block>
  );
};

export default RoutesFilterBlock;