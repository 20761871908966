
import {
    Button,
    Stack,
    Typography,
    Grid,
    TextField,
    Select,
    Alert,
    Box,
    Autocomplete,
    Input
  } from "@mui/material";
  import ArrowBackIcon from "@mui/icons-material/ArrowBack";
  import { NavLink, useNavigate } from "react-router-dom";
  import Block from "../../components/Block";
  import Separator from "../../components/Separator";
  import { useEffect } from "react";
  import { useParams, useSearchParams } from "react-router-dom";

const SettingsForm = ({data, dataOnChange, handleSubmit, errors, success, isSubmitting, id})=>{
    return (
        <Box maxWidth="1394px" margin=" 30px auto 0">
        <form id="CategoryUpdate" onSubmit={handleSubmit}>
      <Stack direction={{ xs: "column", sm: "row" }} alignItems="center" justifyContent="space-between" spacing="20px">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent={{ xs: "space-between", sm: "flex-srart" }}
          sx={{ width: { xs: "100%", sm: "initial" }, gap: "20px" }}
        >
          <Button className="btn-height" onClick={() => window.location.href='/settings'} variant="outlined" startIcon={<ArrowBackIcon />}>
            Back
          </Button>
          <Typography variant="pageTitle" noWrap component="p">
            {id == 'create'?'Create Settings':'Update Settings'}
          </Typography>
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          flexWrap="wrap"
          sx={{
            gap: "11px",
            "@media(max-width: 480px)": { width: "100%", button: { width: "100%" } },
          }}
        >
          <Button className="btn-height" variant="outlined" onClick={() => window.location.href='/settings'}>Discard</Button>
          <Button className="btn-height" color="black" variant="contained" type="submit" disabled={isSubmitting}>
            Save & continue Editing
          </Button>
        </Stack>
      </Stack>
      {Object.keys(errors).map(key=><Alert sx={{mb:1}} severity='error'>{errors[key]}</Alert>)}
      {success && <Alert severity="success" sx={{mt:2}}>{success}</Alert>}
      <Grid container columnSpacing={{ xs: 1, sm: 3, xl: "50px" }} margin="20px 0 0">
        <Grid item md={9} lg={9} sx={{ maxWidth: "908px", width: "100%" }}>
          <Block>
            <Typography component="p" variant="label" mb="11px">
              Code
            </Typography>
            <TextField data-testid="test-code-id" fullWidth variant="outlined" name='code' label='code' required value={data.code} onChange={(e)=>dataOnChange(e)}/>
            <Typography component="p" variant="label" mb="11px" mt="11px">
              Value
            </Typography>
            <TextField data-testid="test-value-id" name='value' label='value' required value={data.value} onChange={(e)=>dataOnChange(e)} rows={6} multiline style={{width:'100%'}}/>
            <Typography component="p" variant="label" mb="11px" mt="11px">
              Notes
            </Typography>
            <TextField data-testid="test-notes-id" name='notes' label='notes' required value={data.notes} onChange={(e)=>dataOnChange(e)} rows={3} multiline style={{width:'100%'}}/>
          </Block>
        </Grid>
      </Grid>
      </form>
    </Box>
    )
}

export default SettingsForm