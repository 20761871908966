import React, { useEffect, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField } from "@mui/material";
import useAppDispatch from "../../hooks/useAppDispatch";
import CategorySelect from "../../components/AsyncSelects/CategorySelect";
import VendorSelect from "../../components/AsyncSelects/VendorSelect";
import DatePicker from "../../components/DatePicker2";
import { createVendorCategoryOverride, updateVendorCategoryOverride } from "../../redux/module/override";
import { DATE_FORMAT } from "../../utils/constants";
import dayjs from "dayjs";
import ErrorPopupBoundary from "../../components/ErrorPopupBoundary";
import { ErrorBoundary } from "react-error-boundary";

const initialValue = {
  vendor: { value: null, label: "" },
  category: { value: null, label: "" },
  override: "",
  startDate: null,
  endDate: null
};

const VendorCategoryOverrideForm = ({ open, handleClose, override }) => {
  const dispatch = useAppDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [overrideInfo, setOverrideInfo] = useState(initialValue);

  async function handleSubmit(event) {
    event.preventDefault();
    setIsSubmitting(true);
    const payload = {
      vendor: overrideInfo.vendor.value,
      category: overrideInfo.category.value,
      override: overrideInfo.override,
      start_date: dayjs(overrideInfo.startDate).format(DATE_FORMAT),
      end_date: overrideInfo.endDate ? dayjs(overrideInfo.endDate).format(DATE_FORMAT) : null
    };
    let data;
    if (override) {
      data = await dispatch(updateVendorCategoryOverride({ id: override.id, payload }));
    } else {
      data = await dispatch(createVendorCategoryOverride(payload));
    }
    if (data.payload.id) {
      handleClose();
    }
    setIsSubmitting(false);
  }

  useEffect(() => {
    if (override) {
      setOverrideInfo({
        vendor: { value: override.vendor.id, label: override.vendor.name },
        category: { value: override.category.id, label: override.category.name },
        override: override.override,
        startDate: override.start_date,
        endDate: override.end_date
      });
    } else {
      setOverrideInfo(initialValue);
    }
  }, [open]);


  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <ErrorBoundary fallback={<ErrorPopupBoundary onClose={handleClose} />}>
        <DialogTitle sx={{ pb: 1 }}>{override ? "Update" : "Create"} Vendor Category Override</DialogTitle>
        <form id="tagCreation" onSubmit={handleSubmit}>
          <DialogContent sx={{ pt: 2, pb: 1 }}>
            <VendorSelect
              fullWidth
              required
              label="Vendor"
              name="vendor"
              value={overrideInfo.vendor}
              onChange={(_e, newValue) => setOverrideInfo({ ...overrideInfo, vendor: newValue })}
            />
            <CategorySelect
              required
              fullWidth
              label="Category"
              name="category"
              value={overrideInfo.category}
              onChange={(_e, newValue) => setOverrideInfo({ ...overrideInfo, category: newValue })}
              extraStyles={{
                mt: 1
              }}
            />
            <TextField
              required
              sx={{ mt: 1 }}
              name="name"
              label="Override"
              type="text"
              id="name"
              fullWidth
              value={overrideInfo.override}
              onChange={(e) => setOverrideInfo({ ...overrideInfo, override: e.target.value })}
              autoComplete="off"
            />
            <Stack direction="row" sx={{ mt: 1 }} gap={1}>
              <DatePicker
                label="Start date"
                controlledValue={overrideInfo.startDate ? dayjs.utc(overrideInfo.startDate) : null}
                setControlledValue={(newValue) => setOverrideInfo({ ...overrideInfo, startDate: newValue })}
                required
              />
              <DatePicker
                label="End date"
                controlledValue={overrideInfo.endDate ? dayjs.utc(overrideInfo.endDate) : null}
                setControlledValue={(newValue) => setOverrideInfo({ ...overrideInfo, endDate: newValue })}
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={isSubmitting}
            >
              {override ? "Update" : "Create"} Override
            </Button>
            <Button onClick={handleClose} variant="contained" color="error">
              Cancel
            </Button>
          </DialogActions>
        </form>
      </ErrorBoundary>
    </Dialog>
  );
};

export default VendorCategoryOverrideForm;
