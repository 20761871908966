import { fireErrorNotification } from "../utils";

function ErrorPopupBoundary() {

  fireErrorNotification(`Something went wrong! We will solve this problem as soon as possible.`);
  
  return <></>
}

export default ErrorPopupBoundary;
