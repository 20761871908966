import { useState } from 'react';
import { useModal } from "mui-modal-provider";

import { Stack, Box, Button, TextField, IconButton, Tooltip, Grid } from "@mui/material";

import { ReactComponent as CheckAllIcon } from "../../images/check-all-big.svg";
import { ReactComponent as SubstituteIcon } from "../../images/exchange.svg";
import { ReactComponent as SwapIcon } from "../../images/swap.svg";

import ConfirmDialog from "../../components/ConfirmDialog";
import SubstituteItemDialog from './SubstituteItemDialog';
import SwapItemDialog from './SwapItemDialog';

const PickListItemActions = ({
  note,
  setNote,
  saveNote,
  addOrEditNoteShown,
  setAddOrEditNoteShown,
  discardNoteAddOrEdit,
  setAllPickedUp
}) => {

  const { showModal } = useModal();

  const [markAllAsPickedUpConfirmOpen, setMarkAllAsPickedUpConfirmOpen] = useState(false);

  return (
    <>

      <ConfirmDialog
        title="Confirm your action"
        approveButtonType='success'
        open={markAllAsPickedUpConfirmOpen}
        setOpen={setMarkAllAsPickedUpConfirmOpen}
        onConfirm={setAllPickedUp}
      >
        Mark all items as picked up?
      </ConfirmDialog>

      <Grid container direction="row" sx={{ marginTop: 1 }} display="flex" justifyContent='space-between'>
        <Grid item width="80%">
          <Stack direction="row" display="flex" justifyContent="start" spacing={1}>
            <Button
              display="flex"
              alignSelf="end"
              size="sm"
              onClick={(e) => addOrEditNoteShown ? saveNote() : setAddOrEditNoteShown(true)}
              variant={'contained'}
              color={addOrEditNoteShown ? 'success' : 'primary'}>{addOrEditNoteShown ? 'Save' : (!note || note?.length == 0 ? 'Add Note' : "Update Note")}
            </Button>
            {addOrEditNoteShown && (
              <Button
                size="sm"
                display="flex"
                onClick={discardNoteAddOrEdit}
                variant={'contained'}
                color={'secondary'}>
                Cancel
              </Button>
            )}
          </Stack>
        </Grid>
        <Grid item width="20%">
          <Stack direction="row" justifyContent="space-between" flexDirection="row-reverse">
            <Tooltip title="Mark all items as picked up">
              <IconButton onClick={e => setMarkAllAsPickedUpConfirmOpen(true)}>
                <CheckAllIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Swap an item">
              <IconButton onClick={e => showModal(SwapItemDialog)}>
                <SwapIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Substitute an item">
              <IconButton onClick={e => showModal(SubstituteItemDialog)}>
                <SubstituteIcon />
              </IconButton>
            </Tooltip>
          </Stack>
        </Grid>

      </Grid>
      {addOrEditNoteShown && (
        <Box sx={{ marginTop: 1 }}>
          <TextField
            sx={{ minHeight: '100px' }}
            data-testid="test-note-id"
            placeholder='Note'
            multiline={true}
            rows={4}
            fullWidth={true}
            value={note}
            onChange={e => setNote(e.target.value)} />
        </Box>
      )}
    </>
  )
}

export default PickListItemActions;