import React, { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import Block from "../../components/Block";
import {
  createCustomerOverride,
  fetchCustomer,
  fetchCustomerOverrides,
  removeCustomer,
  removeCustomerOverride,
  updateCustomer,
  updateCustomerOverride,
} from "../../redux/module/customer";
import useAppDispatch from "../../hooks/useAppDispatch";
import useAppSelector from "../../hooks/useAppSelector";
import OverridesRefactoredTable from "../../components/OverridesRefactoredTable";
import UpchargeAddRefactoredForm from "../../components/UpchargeAddRefactoredForm";
import ConfirmDialog from "../../components/ConfirmDialog";
import UpdateItemTitle from "../../components/UpdateItemTitle";
import CustomerForm from "./CustomerForm";
import { v4 as uuidv4 } from "uuid";
import PageLoading from "../../components/PageLoading";
import MultipleEmailsForm from "../../components/shared/MultipleEmailsForm";
import {
  deleteCustomerEmail,
  patchCustomerEmail,
  postCustomerEmail,
} from "../../distflowAPI/customersApi";

const UpdateCustomer = () => {
  const { id } = useParams();
  const {
    customer,
    customerOverrides,
    customersLoading,
    customerEmailsLoading,
    customerOverridesLoading,
  } = useAppSelector((state) => state.customer);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isOpenDiscard, setIsOpenDiscard] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [cleanOverrideFormFlag, setCleanOverrideFormFlag] = useState(false);
  const [overrides, setOverrides] = useState([]);
  const [customerInfo, setCustomerInfo] = useState({
    owner: {
      first_name: "",
      last_name: "",
      email: "",
      password: "",
    },
    name: "",
    internal_id: "",
    address: "",
    city: "",
    state: "",
    zipcode: "",
    invoice_net: "",
    group: null,
    emails: [{ id: uuidv4(), email: "" }],
    phones: [{ id: uuidv4(), phone: "" }],
  });

  const [emails, setEmails] = useState([
    // TODO: remove this mock data when API is ready
  ]);
  const [isOpenDeleteEmail, setIsOpenDeleteEmail] = useState(false);
  const [email, setEmail] = useState({
    email: "",
    id: null,
  });

  async function handleSubmit(event) {
    event.preventDefault();
    setIsSubmitting(true);
    const payload = {
      owner: {
        first_name: customerInfo.owner.first_name,
        last_name: customerInfo.owner.last_name,
        email: customerInfo.owner.email,
        password: customerInfo.owner.password || undefined,
      },
      name: customerInfo.name,
      internal_id: customerInfo.internal_id,
      address: customerInfo.address,
      city: customerInfo.city,
      state: customerInfo.state,
      zipcode: customerInfo.zipcode,
      invoice_net: customerInfo.invoice_net,
      group: customerInfo.group.value,
    };

    await dispatch(
      updateCustomer({ customerId: id, customerInfo: payload })
    ).finally(() => {
      setIsSubmitting(false);
    });

    setIsSubmitting(false);
  }
  const fetchData = async () => {
    await dispatch(fetchCustomer(id));
    await dispatch(fetchCustomerOverrides(id));
    // await dispatch(fetchCustomerPhones(id));
  };
  useEffect(() => {
    if (id) {
      fetchData();
    }
  }, [id]);

  useEffect(() => {
    if (customer?.id === +id) {
      setCustomerInfo({
        owner: {
          first_name: customer.owner?.first_name || "",
          last_name: customer.owner?.last_name || "",
          email: customer.owner?.email || "",
          password: "",
        },
        name: customer.name,
        internal_id: customer.internal_id,
        address: customer.address,
        city: customer.city,
        state: customer.state,
        zipcode: customer.zipcode,
        invoice_net: customer.invoice_net || 30,
        group: {
          value: customer.group?.id || null,
          label: customer.group?.name || "",
        },
        emails: customer.emails?.length ? customer.emails : [],
        phones: customer.phones?.length ? customer.phones : [],
      });
      setEmails(customer.emails);
    }
  }, [customer]);

  useEffect(() => {
    if (customerOverrides[0]?.customer === +id || !customerOverrides.length) {
      setOverrides(customerOverrides);
    }
  }, [customerOverrides]);

  const getIsAllChargeInactive = () => {
    return !customerOverrides.filter((el) => el.is_current === true).length;
  };

  const handleDiscard = () => {
    dispatch(fetchCustomer(id));
    setCleanOverrideFormFlag(true);
  };

  const handleDelete = async () => {
    const resp = await dispatch(removeCustomer(id));
    if (resp.payload === id) {
      navigate(-1);
    }
  };

  const handleAddEmail = (newEmail, callback) => {
    postCustomerEmail(Number(id), newEmail).then((res) => {
      setEmails([res.data, ...emails]);
      callback();
    });
  };

  const handleSave = async (emailId, updatedEmail, callback) => {
    patchCustomerEmail(id, emailId, updatedEmail).then((res) => {
      fetchData();
      callback();
    });
    callback();
  };

  function handleDeleteEamil(emailId, email) {
    setEmail({ email, id: emailId });
    setIsOpenDeleteEmail(true);
  }

  function handleDeleteEamil_() {
    deleteCustomerEmail(id, email.id).then((res) => fetchData());
  }

  return (
    <Box maxWidth="1394px" margin=" 30px auto 0">
      <PageLoading dataLoading={customersLoading || customerOverridesLoading} />

      <ConfirmDialog
        title="Discard updates?"
        open={isOpenDiscard}
        setOpen={setIsOpenDiscard}
        onConfirm={handleDiscard}
      >
        {`Are you sure you want to discard all changes?`}
      </ConfirmDialog>
      <ConfirmDialog
        title="Delete user?"
        open={isOpenDelete}
        setOpen={setIsOpenDelete}
        onConfirm={handleDelete}
      >
        {`Are you sure you want to delete this customer?`}
      </ConfirmDialog>
      <form id="CategoryUpdate" onSubmit={handleSubmit}>
        <UpdateItemTitle
          item={"Customer"}
          setIsOpenDelete={setIsOpenDelete}
          setIsOpenDiscard={setIsOpenDiscard}
          isSubmitting={isSubmitting}
        />
        <Grid
          container
          columnSpacing={{ xs: 1, sm: 3, xl: "10px" }}
          margin="20px 0 0"
        >
          <Grid item md={9} lg={9} sx={{ maxWidth: "908px", width: "100%" }}>
            <Block>
              <CustomerForm
                customerInfo={customerInfo}
                setCustomerInfo={setCustomerInfo}
              />
            </Block>
          </Grid>
          <Grid item md={9} lg={9} sx={{ maxWidth: "500px", width: "100%" }}>
            <Block>
              <Typography component="p" variant="label" mb="11px">
                Customer Emails
              </Typography>
              <MultipleEmailsForm
                emails={emails}
                setEmails={setEmails}
                addEmail={handleAddEmail}
                updateEmail={handleSave}
                deleteEmail={handleDeleteEamil}
              />
              <ConfirmDialog
                title="Delete Email?"
                open={isOpenDeleteEmail}
                setOpen={setIsOpenDeleteEmail}
                onConfirm={handleDeleteEamil_}
              >
                {`Are you sure you want to delete "${email.email}"?`}
              </ConfirmDialog>
            </Block>
          </Grid>
        </Grid>
      </form>
      <UpchargeAddRefactoredForm
        fetchOverrides={fetchCustomerOverrides}
        createOverride={createCustomerOverride}
        cleanOverrideFormFlag={cleanOverrideFormFlag}
        setCleanOverrideFormFlag={setCleanOverrideFormFlag}
        elementKey="customer"
      />
      <Grid
        container
        columnSpacing={{ xs: 1, sm: 3, xl: "50px" }}
        margin="20px 0 0"
      >
        <Grid item md={9} lg={9} sx={{ maxWidth: "908px", width: "100%" }}>
          <Block>
            <Typography component="p" variant="label" mb="11px">
              Customer Upcharges{" "}
              {getIsAllChargeInactive() && (
                <span style={{ color: "red" }}>(No active up charge)</span>
              )}
            </Typography>
            <Box sx={{ margin: { xs: "0 -16px -16px", sm: "0 -24px -24px" } }}>
              <OverridesRefactoredTable
                overrides={overrides}
                fetchOverrides={fetchCustomerOverrides}
                removeOverride={removeCustomerOverride}
                updateOverride={updateCustomerOverride}
                elementKey="customer"
              />
            </Box>
          </Block>
        </Grid>
      </Grid>
    </Box>
  );
};
export default UpdateCustomer;
