import api from "./api";
import { orderingToApi, filtersToApi } from "./common";

export async function listVendors(pageSize, page, search) {
  const params = `page_size=${pageSize}&page=${page}&search=${search}`;
  const result = await api.get(`/api/vendors/vendors/?${params}`);
  return result.data;
}

export function getVendors(page, pageSize, filter, order) {
  const orderingParam = order.length ? `&ordering=${orderingToApi(order)}` : "";
  const filterParam = filter.length ? `&${filtersToApi(filter)}` : "";

  const params = `page_size=${pageSize}&page=${page}${orderingParam}${filterParam}`;

  return api.get(`/api/vendors/vendors/?${params}`);
}

export function createVendorApi(payload) {
  return api.post(`/api/vendors/vendors/`, payload);
}

export function patchVendor(vendorId, vendorInfo) {
  return api.patch(`/api/vendors/vendors/${vendorId}/`, vendorInfo);
}

export function deleteVendor(vendorId) {
  return api.delete(`/api/vendors/vendors/${vendorId}/`);
}

export function getVendor(vendorId) {
  return api.get(`/api/vendors/vendors/${vendorId}/`);
}

export function getVendorOverrides(vendorId) {
  return api.get(`/api/vendors/vendors/${vendorId}/overrides/`);
}

export function postVendorOverride(vendorId, payload) {
  return api.post(`/api/vendors/vendors/${vendorId}/overrides/`, payload);
}

export function patchVendorOverride(vendorId, overrideId, payload) {
  return api.patch(
    `/api/vendors/vendors/${vendorId}/overrides/${overrideId}/`,
    payload
  );
}

export async function deleteVendorOverride(vendorId, overrideId) {
  return api.delete(
    `/api/vendors/vendors/${vendorId}/overrides/${overrideId}/`
  );
}

export async function emailVendorOrders(date, vendorIds, operation) {
  const res = await api.get(
    `/api/orders/email_export_orders/?date=${date}&vendorIds=${vendorIds}&operation=${operation}`
  );
  return res;
}
