import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { signOut } from "./user";
import { fireErrorNotification, fireSuccessNotification } from "../../utils";
import groupsApi from "../../distflowAPI/groupsApi";


const initialState = {
  group: {},
  groups: [],
  groupsLoading: false,
  pageSize: 50,
  page: 1,
  orderBy: [],
  filterBy: [],
  totalCount: 0
};

export const fetchGroups = createAsyncThunk(
  "group/fetchGroups",
  async (data, { dispatch, getState }) => {
    const { page, pageSize, filterBy, orderBy } = data;    
    return await groupsApi.list(pageSize, page, orderBy,filterBy,);
  }
);

export const fetchGroup = createAsyncThunk("group/fetchGroup", async (id, thunkAPI) => {
  const res = await groupsApi.findById(id);
  return res.data;
});

export const createGroup = createAsyncThunk("group/createGroup", async (data, {rejectWithValue}) => {
  try {
    const res = await groupsApi.create(data);
    return res.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const updateGroup = createAsyncThunk("group/updateGroup", async (data, {rejectWithValue}) => {
  const {id, payload} = data;

  try {
    const res = await groupsApi.update(id, payload);
    return res.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const deleteGroup = createAsyncThunk("group/deleteGroup", async (id, {rejectWithValue}) => {
  try {
    const res = await groupsApi.delete(id);
    return id;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const groupSlice = createSlice({
  name: "group",
  initialState,
  reducers: {
    setGroupTableSettings(state, { payload }) {
      state[payload.field] = payload.value;
    },
    cleanTableSettings(state) {
      state.pageSize = 50
      state.page = 1
      state.orderBy = []
      state.filterBy = []
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchGroups.pending, (state) => {
        state.groupsLoading = true;
      })
      .addCase(fetchGroups.fulfilled, (state, action) => {
        state.groupsLoading = false;
        state.groups = action.payload.results;
        state.totalCount = action.payload.count;
      })
      .addCase(fetchGroups.rejected, (state) => {
        state.groupsLoading = false;
      })
      .addCase(fetchGroup.fulfilled, (state, {payload}) => {
        state.group = payload;
      })
      .addCase(fetchGroup.pending, (state) => {
        state.groupsLoading = true;
      })
      .addCase(createGroup.fulfilled, (state, action) => {
        state.groupsLoading = false;
        fireSuccessNotification(`Group created successfully`);
        state.totalCount = state.totalCount + 1;
        if (state.groups.length < state.pageSize) {
          state.groups = [...state.groups, action.payload];
        }
      })
      .addCase(createGroup.rejected, (state, action) => {
        state.groupsLoading = false;
        const errorFields = Object.keys(action.payload)
        errorFields.forEach(field => {
          fireErrorNotification(`${field}: ${action.payload[field][0]}`)
        })
      })
      .addCase(updateGroup.fulfilled, (state, action) => {
        fireSuccessNotification(`Group updated successfully`)
        state.group = action.payload;
        state.groups = state.groups.map(el => {
          if (el.id === action.payload.id) {
            return action.payload;
          } else {
            return el;
          }
        });
      })
      .addCase(updateGroup.rejected, (state, action) => {
        const errorFields = Object.keys(action.payload)
        errorFields.forEach(field => {
          fireErrorNotification(`${field}: ${action.payload[field][0]}`)
        })
      })
      .addCase(deleteGroup.fulfilled, (state, action) => {
        fireSuccessNotification(`Group deleted successfully`)
        state.group = {};
        state.groups = state.groups.filter(el => el.id !== action.payload)
      })
      .addCase(deleteGroup.rejected, (state, action) => {
        const protected_elements = action.payload.protected_elements;
        fireErrorNotification(`Protected by: 
          ${protected_elements.map(el => ` id: ${el.id} label: ${el.label}`)}`);
      })
      .addCase(signOut, () => initialState);
  },
});

export const {setGroupTableSettings , cleanTableSettings } = groupSlice.actions;

export const selectGroupNumberOfPages = createSelector(
  (state) => state.group.totalCount,
  (state) => state.group.pageSize,
  (totalCount, pageSize) => Math.ceil(totalCount / pageSize)
);

export default groupSlice.reducer;
