import React, { useEffect, useState } from "react";
import Block from "../../components/Block";
import { Box, FormControlLabel, Grid, OutlinedInput, Radio, RadioGroup, Stack, Typography } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useSearchDebounce } from "../../utils";
import ArrowUpwardOutlinedIcon from "@mui/icons-material/ArrowUpwardOutlined";
import ArrowDownwardOutlinedIcon from "@mui/icons-material/ArrowDownwardOutlined";
import { DATE_FORMAT } from "../../utils/constants";
import dayjs from "dayjs";
import DatePicker2 from "../../components/DatePicker2";

const VendorCategoryFilters = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [filterDB, setFilterDB] = useSearchDebounce();
  const [overrideWay, setOverrideWay] = useState("override_gte");
  const [startWay, setStartWay] = useState("start_date_gte");
  const [endWay, setEndWay] = useState("end_date_gte");
  const [filters, setFilters] = useState({
    category_name: "",
    vendor_name: "",
    [overrideWay]: "",
    [startWay]: null,
    [endWay]: null,
  });

  const handleChange = (value) => {
    setSearchParams((params) => {
      const newParams = new URLSearchParams(params);
      Object.keys(value).forEach(key => {
        if (!!value[key]) {
          newParams.set(key, value[key]);
        } else {
          newParams.delete(key);
        }
      });
      return newParams;
    });
  };

  useEffect(() => {
    setFilterDB(filters);
  }, [filters]);


  useEffect(() => {
    handleChange(filterDB);
  }, [filterDB]);


  const handleWayChange = (way, field) => {
    const reverse = {
      override: way === "override_gte" ? "override_lte" : "override_gte",
      start: way === "start_date_gte" ? "start_date_lte" : "start_date_gte",
      end: way === "end_date_gte" ? "end_date_lte" : "end_date_gte"
    }[field];

    if (field === "override") {
      setOverrideWay(way);
    } else if (field === "start") {
      setStartWay(way);
    } else {
      setEndWay(way);
    }
    setFilters(prev => ({
      ...prev,
      [way]: prev[reverse],
      [reverse]: prev[way]
    }));
  };

  return (
    <Block>
      <Grid container spacing={1} rowGap="15px">
        <Grid item xs={12} sm={5}>
          <Typography mb="5px">Vendor</Typography>
          <OutlinedInput
          className="btn-height"
            size="small"
            sx={{ height: "40px" }}
            fullWidth
            value={filters.vendor_name}
            onChange={(e) => setFilters({ ...filters, vendor_name: e.target.value })}
          />
        </Grid>
        <Grid item xs={12} sm={5}>
          <Typography mb="5px">Category</Typography>
          <OutlinedInput
          className="btn-height"
            size="small"
            sx={{ height: "40px" }}
            fullWidth

            value={filters.category_name}
            onChange={(e) => setFilters({ ...filters, category_name: e.target.value })}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Typography mb="5px">Override</Typography>
          <Stack direction={"row"} gap={1}>
            <OutlinedInput
            className="btn-height"
              size="small"
              sx={{ height: "40px" }}
              fullWidth
              value={filters[overrideWay]}
              type="number"
              onChange={(e) =>
                setFilters({ ...filters, [overrideWay]: e.target.value })
              }
              endAdornment={
                <Box
                  sx={{
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center"
                  }}

                  onClick={() => handleWayChange(overrideWay === "override_gte" ? "override_lte" : "override_gte", "override")}>
                  {overrideWay === "override_gte" ? <ArrowUpwardOutlinedIcon fontSize="small" /> :
                    <ArrowDownwardOutlinedIcon fontSize="small" />}
                </Box>
              }
            />
          </Stack>
        </Grid>

        {/* <div className="date-start"> */}
          <Grid className="date-picker" item sx={{
            width: {
              sm: "220px", xs: "100%"
            }
          }}>
            <Stack direction="row" gap={1} alignItems="center" mb={'5px'}>
              <Typography>Start date</Typography>
              <Box
                sx={{
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center"
                }}

                onClick={() => handleWayChange(startWay === "start_date_gte" ? "start_date_lte" : "start_date_gte", "start")}>
                {startWay === "start_date_gte" ? <ArrowUpwardOutlinedIcon fontSize="small" /> :
                  <ArrowDownwardOutlinedIcon fontSize="small" />}
              </Box>
            </Stack>
            <DatePicker2
              controlledValue={startWay ? dayjs.utc(filters[startWay]) : null}
              setControlledValue={(newValue) => {
                setFilters({ ...filters, [startWay]: newValue ? dayjs(newValue).format(DATE_FORMAT) : "" });
              }}
              additionlStyles={{
                height: "40px",
                width: "100%",
                "& .MuiInputBase-root.Mui-error .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#DBDFEA"
                }
              }}
            />
          </Grid>
          <Grid className="date-picker" item sx={{
            width: {
              sm: "220px", xs: "100%"
            }
          }}>
            <Stack direction="row" gap={1} alignItems="center" mb={'5px'}>
              <Typography>End date</Typography>
              <Box
                sx={{
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center"
                }}

              onClick={() => handleWayChange(endWay === "end_date_gte" ? "end_date_lte" : "end_date_gte", "end")}>
              {endWay === "end_date_gte" ? <ArrowUpwardOutlinedIcon fontSize="small" /> :
                <ArrowDownwardOutlinedIcon fontSize="small" />}
            </Box>
          </Stack>
          <DatePicker2
            controlledValue={endWay ? dayjs.utc(filters[endWay]) : null}
            setControlledValue={(newValue) => {
              setFilters({ ...filters, [endWay]: newValue ? dayjs(newValue).format(DATE_FORMAT) : "" });
            }}
            additionlStyles={{
              height: "40px",
              width: "100%",
              "& .MuiInputBase-root.Mui-error .MuiOutlinedInput-notchedOutline": {
                borderColor: "#DBDFEA"
              }
            }}
          />
        </Grid>
      </Grid>
    </Block>
  );
};

export default VendorCategoryFilters;