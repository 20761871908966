import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { REDUCER_ACTIONS } from "../config";
import { getItemDicount, getItemUnitPrice } from "../config";

function InvoiceFooter({ data, dispatch, children }) {
  function getItems() {
    return data.items.filter(
      (it) => !["CANCELED", "PENDING"].includes(it.status)
    );
  }
  function getItemsTotal() {
    return getItems()
      ?.reduce(
        (accumulator, orderItem) =>
          accumulator + orderItem.delivered_qty * getItemUnitPrice(orderItem),
        0
      )
      .toFixed(2);
  }
  function getCostTotalOrdered() {
    return getItems()
      ?.reduce((sum, item) => {
        let unitCost = parseFloat(item.unit_cost);
        let Qty = parseFloat(item.quantity);
        return parseFloat(sum) + unitCost * Qty;
      }, 0)
      .toFixed(2);
  }

  function getCostTotalBillable() {
    return getItems()
      ?.reduce((sum, item) => {
        let unitCost = parseFloat(item.unit_cost) || 0;
        let Qty = parseFloat(item.delivered_qty) || 0;
        return parseFloat(sum) + unitCost * Qty;
      }, 0)
      .toFixed(2);
  }

  function getDiscountTotal() {
    return getItems()
      ?.reduce(
        (accumulator, orderItem) =>
          accumulator + getItemDicount(orderItem) * orderItem.quantity,
        0
      )
      .toFixed(2);
  }
  function getReturnsTotal() {
    return data.returns
      .reduce(
        (accumulator, orderItem) =>
          accumulator + orderItem.quantity * orderItem.unit_price,
        0
      )
      .toFixed(2);
  }
  function getFeesTotal() {
    return data.fees
      .reduce((accumulator, fee) => accumulator + parseFloat(fee.value), 0)
      .toFixed(2);
  }
  function getInvoiceTotal() {
    return (
      parseFloat(getItemsTotal()) +
      parseFloat(getFeesTotal()) -
      parseFloat(getReturnsTotal()) -
      parseFloat(getDiscountTotal())
    ).toFixed(2);
  }
  function getBalance() {
    return (getInvoiceTotal() - data.total_paid).toFixed(2);
  }
  return (
    <Grid container>
      <Grid md={8}>
        <Stack spacing={1}>
          {children}
          <Typography fontWeight={600}>Notes:</Typography>

          <TextField
            rows={5}
            fullWidth
            data-testid="test-note-id"
            multiline
            value={data.notes}
            onChange={(e) =>
              dispatch({
                type: REDUCER_ACTIONS.SET_VALUE,
                name: "notes",
                value: e.target.value,
              })
            }
          />
        </Stack>
      </Grid>
      <Grid item md={4}>
        <Typography
          fontSize="14px"
          fontWeight={400}
          mb="0px"
          mt="12px"
          align="right"
          mr="12px"
        >
          <b>Order Total:</b> ${getItemsTotal()}
        </Typography>
        <Typography
          fontSize="14px"
          fontWeight={400}
          mb="0px"
          align="right"
          mr="12px"
        >
          <b>Cost Total </b> <small>(Ordered)</small>
          <b>:</b> ${getCostTotalOrdered()}
        </Typography>
        <Typography
          fontSize="14px"
          fontWeight={400}
          mb="0px"
          align="right"
          mr="12px"
        >
          <b>Cost Total </b> <small>(Billable)</small>
          <b>:</b> ${getCostTotalBillable()}
        </Typography>
        <Typography
          fontSize="14px"
          fontWeight={400}
          mb="0px"
          align="right"
          mr="12px"
        >
          <b>Discount Total:</b> $-
          {getDiscountTotal()}
        </Typography>
        <Typography
          fontSize="14px"
          fontWeight={400}
          mb="0px"
          align="right"
          mr="12px"
          color={"red"}
        >
          <b>Returns Total:</b>- ${getReturnsTotal()}
        </Typography>

        <Typography
          fontSize="14px"
          fontWeight={400}
          mb="0px"
          align="right"
          mr="12px"
        >
          <b>Fees:</b>
          {" $"}
          {getFeesTotal()}
        </Typography>

        <Typography
          fontSize="14px"
          fontWeight={400}
          mb="0px"
          mt="12px"
          align="right"
          mr="12px"
        >
          <b>Total: </b>${getInvoiceTotal()}
          {data.status == "VOIDED" && "(voided)"}
        </Typography>
        <>
          <Typography
            fontSize="14px"
            fontWeight={400}
            mb="0px"
            mt="2px"
            align="right"
            mr="12px"
          >
            <b>Paid amount:</b> ${data.total_paid}
          </Typography>
          <Typography
            fontSize="14px"
            fontWeight={400}
            mb="0px"
            mt="2px"
            align="right"
            mr="12px"
          >
            <b>Balance:</b> ${getBalance()}
          </Typography>
        </>
      </Grid>
    </Grid>
  );
}

export default InvoiceFooter;
