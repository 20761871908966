import api from "./api";

export async function listCredits() {
  const res = await api.get(`/api/orders/credits/`);
  return res;
}

export async function createCredit(payload) {
  const res = await api.post(`/api/orders/credits/`, payload);
  return res;
}

export async function updateCredit(creditId, payload) {
  const res = await api.patch(`/api/orders/credits/${creditId}`, payload);
  return res;
}

export async function deleteCredit(creditId) {
  const res = await api.delete(`/api/orders/credits/${creditId}`);
}
