import { Box, Input } from "@mui/material";
import { useState } from "react";

export default function CommissionInput({ commission, setCommission }) {
  

  const handleCommissionChange = (e) => {
    const newValue = e.target.value;
    const regex = /^(\d{1,2}(\.\d{0,2})?|100)$/;
    if (regex.test(newValue) || newValue === "") {
      setCommission(newValue);
    }
  };

  return (
    <Box>
      <Input
        size="small"
        sx={{ width: "40px" }}
        value={commission}
        onChange={handleCommissionChange}
      />
    </Box>
  );
}
