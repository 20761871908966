import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import accountsApi, {
  deleteAccountOverride,
  getAccountOverrides,
  patchAccount,
  patchAccountOverride,
  postAccountOverride,
} from "../../distflowAPI/accountsApi";
import { enqueueSnackbar } from "notistack";
import { signOut } from "./user";
import {
  arrayToDictionary,
  fireErrorNotification,
  fireSuccessNotification,
} from "../../utils";

/**
 * @typedef {Object} Account
 * @property {number} Account.id
 * @property {string} Account.name
 * @property {import("./territory").Territory} Account.territory
 * @property {string} Account.created_at
 * @property {string} Account.updated_at
 * @property {{ id: number; name: string }} Account.customer
 * @property {string} Account.address
 */

/**
 * @type {{
 *  account: Account;
 *  accountOverrides: [];
 *  accounts: Array<Account>;
 *  accountMap: Record<number, Account>;
 *  accountsLoading: boolean;
 *  pageSize: number;
 *  page: number;
 *  orderBy: [];
 *  filterBy: {};
 *  totalCount: number;
 *  createLoading: boolean;
 * }}
 */
const initialState = {
  account: {},
  accountOverrides: [],
  accounts: [],
  accountMap: {},
  accountsLoading: false,
  accountOverridesLoading: false,
  pageSize: 50,
  page: 1,
  orderBy: [{ field: "internal_id", sort: "asc" }],
  filterBy: [{ column: "myCustomers", value: false }],
  totalCount: 0,
  createLoading: false,
};

export const fetchAccounts = createAsyncThunk(
  "account/fetchAccounts",
  async (data, { dispatch, getState }) => {
    const { page, pageSize, filterBy, orderBy, searchParams } = data;
    return await accountsApi.list(
      pageSize,
      page,
      orderBy,
      filterBy,
      searchParams,
    );
  },
);

export const createAccount = createAsyncThunk(
  "account/createAccount",
  async (account, { rejectWithValue }) => {
    try {
      const response = await accountsApi.create(account);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const fetchAccountById = createAsyncThunk(
  "account/fetchAccountById",
  async ({ id }, { getState, rejectWithValue }) => {
    const state = getState().account;
    if (state.accountMap[id]) {
      return state.accountMap[id];
    }
    try {
      const response = await accountsApi.findById(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const fetchAccount = createAsyncThunk(
  "account/fetchAccount",
  async (accountId, { getState, rejectWithValue }) => {
    const res = await accountsApi.findById(accountId);
    return res.data;
  },
);

export const updateAccount = createAsyncThunk(
  "account/updateAccount",
  async (data, { getState, rejectWithValue }) => {
    const { accountId, accountInfo } = data;

    try {
      const res = await patchAccount(accountId, accountInfo);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const removeAccount = createAsyncThunk(
  "account/removeAccount",
  async (accountId, { rejectWithValue }) => {
    try {
      const res = await accountsApi.delete(accountId);
      return accountId;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const fetchAccountOverrides = createAsyncThunk(
  "account/fetchAccountOverrides",
  async (accountId, thunkAPI) => {
    const res = await getAccountOverrides(accountId);
    return res.data.results;
  },
);

export const createAccountOverride = createAsyncThunk(
  "account/createAccountOverride",
  async (data, { rejectWithValue }) => {
    const { id, ...payload } = data;
    try {
      const res = await postAccountOverride(id, payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const updateAccountOverride = createAsyncThunk(
  "account/updateAccountOverride",
  async (data, { rejectWithValue }) => {
    const { id, overrideId, payload } = data;
    try {
      const res = await patchAccountOverride(id, overrideId, payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const removeAccountOverride = createAsyncThunk(
  "account/removeAccountOverride",
  async (data) => {
    const { id, overrideId } = data;
    const res = await deleteAccountOverride(id, overrideId);
    return { overrideId };
  },
);

export const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    cleanAccountData(state, { payload }) {
      state.account = {};
    },
    setAccountTableSettings(state, { payload }) {
      state[payload.field] = payload.value;
    },
    cleanTableSettings(state) {
      state.pageSize = 25;
      state.page = 1;
      state.orderBy = [];
      state.filterBy = [{ column: "myCustomers", value: false }];
    },
  },
  extraReducers: (builder) => {
    builder
      // <---------- all accounts ---------->
      .addCase(fetchAccounts.pending, (state) => {
        state.accountsLoading = true;
      })
      .addCase(fetchAccounts.fulfilled, (state, action) => {
        state.accountsLoading = false;
        state.accounts = action.payload.results;
        state.accountMap = {
          ...state.accountMap,
          ...arrayToDictionary(action.payload.results, "id"),
        };
        state.totalCount = action.payload.count;
      })
      .addCase(fetchAccounts.rejected, (state) => {
        state.accountsLoading = false;
      })
      // <---------- current account ---------->
      .addCase(fetchAccount.pending, (state, action) => {
        state.accountsLoading = true;
      })
      .addCase(fetchAccount.fulfilled, (state, action) => {
        state.account = action.payload;
        state.accountsLoading = false;
      })
      .addCase(fetchAccountById.fulfilled, (state, action) => {
        state.accountMap[action.meta.arg.id] = action.payload;
      })
      .addCase(createAccount.pending, (state) => {
        state.createLoading = true;
      })
      .addCase(createAccount.fulfilled, (state, action) => {
        state.createLoading = false;
        enqueueSnackbar(`Account created successfully`, {
          variant: "success",
        });
        state.totalCount = state.totalCount + 1;
        if (state.accounts.length < state.pageSize) {
          state.accounts = [...state.accounts, action.payload];
        }
      })
      .addCase(createAccount.rejected, (state, action) => {
        state.createLoading = false;
        const errorFields = Object.keys(action.payload);
        errorFields.forEach((field) => {
          fireErrorNotification(`${field}: ${action.payload[field][0]}`);
        });
      })
      .addCase(updateAccount.fulfilled, (state, action) => {
        state.account = action.payload;
        fireSuccessNotification(`Account updated successfully`);
      })
      .addCase(updateAccount.rejected, (state, action) => {
        const errorFields = Object.keys(action.payload);
        errorFields.forEach((field) => {
          fireErrorNotification(`${field}: ${action.payload[field][0]}`);
        });
      })
      .addCase(removeAccount.fulfilled, (state, action) => {
        fireSuccessNotification(`Account deleted successfully`);
        state.account = {};
        state.accounts = state.accounts.filter(
          (el) => el.id !== action.payload,
        );
      })
      .addCase(removeAccount.rejected, (state, action) => {
        const protected_elements = action.payload.protected_elements;
        fireErrorNotification(`Protected by: 
          ${protected_elements.map(
            (el) => ` id: ${el.id} label: ${el.label}`,
          )}`);
      })
      // <---------- current account overrides ---------->
      .addCase(fetchAccountOverrides.pending, (state, action) => {
        state.accountOverridesLoading = true;
      })
      .addCase(fetchAccountOverrides.fulfilled, (state, action) => {
        state.accountOverrides = action.payload;
        state.accountOverridesLoading = false;
      })
      .addCase(createAccountOverride.fulfilled, (state, action) => {
        state.accountOverrides.push(action.payload);
        fireSuccessNotification(`Account override created successfully`);
      })
      .addCase(createAccountOverride.rejected, (state, action) => {
        const errorFields = Object.keys(action.payload);
        errorFields.forEach((field) => {
          fireErrorNotification(`${field}: ${action.payload[field][0]}`);
        });
      })
      .addCase(updateAccountOverride.fulfilled, (state, action) => {
        fireSuccessNotification(`Account override updated successfully`);
        state.accountOverrides = state.accountOverrides.map((el) => {
          if (el.id === action.payload.id) {
            return action.payload;
          } else {
            return el;
          }
        });
      })
      .addCase(updateAccountOverride.rejected, (state, action) => {
        const errorFields = Object.keys(action.payload);
        errorFields.forEach((field) => {
          fireErrorNotification(`${field}: ${action.payload[field][0]}`);
        });
      })
      .addCase(removeAccountOverride.fulfilled, (state, action) => {
        fireSuccessNotification(`Override deleted successfully`);
        state.accountOverrides = state.accountOverrides.filter(
          (el) => el.id !== action.payload.overrideId,
        );
      })
      .addCase(signOut, () => initialState);
  },
});

export const { setAccountTableSettings, cleanAccountData, cleanTableSettings } =
  accountSlice.actions;

export const selectAccountNumberOfPages = createSelector(
  (state) => state.account.totalCount,
  (state) => state.account.pageSize,
  (totalCount, pageSize) => Math.ceil(totalCount / pageSize),
);

export default accountSlice.reducer;
