import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import DataTable from "../../components/DataTable";
import salesmenTableCols from "./salesmenTableCols";
import AddSalesman from "./AddSalesman";
import { useEffect, useState } from "react";
import { listSalesmen } from "../../distflowAPI/salesmenApi";
import {
  formatAgGridFilters,
  formatAgGridSorting,
} from "../../distflowAPI/common";
import DatePicker2 from "../../components/DatePicker2";
import { downloadFile } from "../../distflowAPI/downloadFile";

export default function Salesmen() {
  const [showAddSalesman, setShowAddSalesman] = useState(false);
  const [showExportStreet, setShowExportStreet] = useState(false);
  const [salesMen, setSalesMen] = useState([]);
  const [orderBy, setOrderBy] = useState([]);
  const [filterBy, setFilterBy] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [dataLoading, setDataLoading] = useState(false);
  const [noPages, setNoPages] = useState(1);

  function refreshTable() {
    setDataLoading(true);
    listSalesmen(page, pageSize, filterBy, orderBy).then((res) => {
      setSalesMen(res.data.results);
      setNoPages(parseInt(res.count / pageSize));
      setDataLoading(false);
    });
  }

  useEffect(() => {
    refreshTable();
  }, [filterBy, orderBy, page, pageSize]);
  return (
    <Box>
      <AddSalesman
        open={showAddSalesman}
        setOpen={setShowAddSalesman}
        refreshTable={refreshTable}
      />
      <ExportStreetInvoice
        open={showExportStreet}
        setOpen={setShowExportStreet}
      />
      <Box
        sx={{ display: "flex", justifyContent: "space-between", mb: "20px" }}
      >
        <Typography
          fontSize="23px"
          fontWeight={800}
          component="h2"
          data-testid="page-title"
        >
          Salesmen
        </Typography>
        <Stack direction={"row"} spacing={1}>
          <Button
            variant="contained"
            color="success"
            onClick={() => {
              setShowAddSalesman(true);
            }}
          >
            Add a Saleman
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setShowExportStreet(true);
            }}
          >
            Export Street Invoice
          </Button>
        </Stack>
      </Box>
      <Box>
        <DataTable
          columnDefs={salesmenTableCols}
          rowData={salesMen}
          rowHeight={53}
          headerHeight={60}
          loading={dataLoading}
          onSortChanged={(event) =>
            setOrderBy(
              formatAgGridSorting(event.columnApi.columnModel.gridColumns),
            )
          }
          onFilterChanged={(event) =>
            setFilterBy(formatAgGridFilters(event.api.getFilterModel()) || [])
          }
          page={page}
          pageSize={pageSize}
          setPageSize={(pageSize) => setPageSize(pageSize)}
          noPages={noPages}
          setPage={(page) => setPage(page)}
        />
      </Box>
    </Box>
  );
}

function ExportStreetInvoice({ open, setOpen }) {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  function handleExport() {
    const fileName = `StreetInvoiceExport ${startDate.format(
      "MM/DD/YYYY",
    )} - ${endDate.format("MM/DD/YYYY")}.csv`;
    downloadFile(
      `/api/orders/export_sales_men_orders/?start_date=${startDate.format(
        "YYYY-MM-DD",
      )}&end_date=${endDate.format("YYYY-MM-DD")}`,
      fileName,
    ).then((res) => setOpen(false));
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle sx={{ fontWeight: "bolder", mb: "30px" }}>
          Export street invoice orders
        </DialogTitle>
        <DialogContent>
          <Stack direction={"row"} spacing={1}>
            <DatePicker2
              controlledValue={startDate}
              setControlledValue={setStartDate}
              label={"start date"}
            />
            <DatePicker2
              controlledValue={endDate}
              setControlledValue={setEndDate}
              label={"end date"}
            />
          </Stack>
        </DialogContent>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            m: "20px",
            gap: "10px",
          }}
        >
          <Button
            variant="contained"
            color="success"
            onClick={() => handleExport()}
          >
            Export
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              setOpen(false);
            }}
          >
            Cancel
          </Button>
        </Box>
      </Dialog>
    </>
  );
}
