const data =  {
    480: {
        slidesPerView: 4,
    },
    640: {
        slidesPerView: 7,
    },
    768: {
        slidesPerView: 10,
    },
    1300: {
        slidesPerView: 14,
    },
}

export default data;