import { useEffect, useState } from "react";
import useAppDispatch from "../../hooks/useAppDispatch";
import useAppSelector from "../../hooks/useAppSelector";

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Tooltip,
    Typography,
    Grid,
    Stack,
    Box,
    TextField,
    Badge
} from "@mui/material";

import {
    createRouteReportPickupItem,
    updateRouteReportPickupItemAmount,
    fetchCurrentSelectedRouteReport,
    // createRouteReportPickupVendor,

    // updateRouteReportPickupStatus,
    // calculateSelectedReportPickupItems
} from "../../redux/module/routeReports";
import { styled } from '@mui/material/styles';
import { fireErrorNotification, fireSuccessNotification } from "../../utils";
import PickListItemActions from "./PickListItemActions";
import { orderItemStatusMap } from "../orders/consts";

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        top: -1,
    },
}));

const PickListItem = ({ renderAsNote, items, updatePickupStatusIfNeeded }) => {
    const dispatch = useAppDispatch();

    const selectedReport = useAppSelector((state) => state.routeReports.selectedReport);
    const keyedAccounts = useAppSelector((state) => state.account.accountMap);
    const keyedProducts = useAppSelector((state) => state.product.keyedProducts);
    const keyedVariants = useAppSelector((state) => state.product.keyedVariants);
    const routes = useAppSelector((state) => state.route.routes);

    const [totalToBePickedAmount, setTotalToBePickedUpAmount] = useState();
    const [totalPickedUp, setTotalPickedUp] = useState();

    const [expandPerItem, setExpandPerItem] = useState(false);
    const [expandedItem, setExpandedItem] = useState();

    const [note, setNote] = useState();
    const [addOrEditNoteShown, setAddOrEditNoteShown] = useState(false);

    useEffect(() => {
        if (items && items.length > 0) {
            setNote(items[0].note);

            const totalToBePickedAmount = items.map(x => x.quantity).reduce((acc, curr) => {
                return acc + curr;
            }, 0);

            const totalPickedUp = items.map(x => x.pickedAmount).reduce((acc, curr) => {
                return acc + curr;
            }, 0);

            setTotalPickedUp(totalPickedUp);
            setTotalToBePickedUpAmount(totalToBePickedAmount);
        }
    }, [items]);

    // const expandWrapper = (event) => {
    //     event.stopPropagation();

    //     // Do not let the user to make changes unless the route is started
    //     if (!selectedReport.route_report) {
    //         fireErrorNotification('The route is not started yet');
    //         return;
    //     }

    //     if (renderAsNote) {
    //         event.preventDefault();
    //         return;
    //     }

    //     setExpand((prev) => !prev);
    // }

    const amountChangedWrapper = (e) => {
        const { value } = e.target;
        savePickedUpAmountPerItem(value);
        //savePickedUpAmount(value);
    }

    const keyUpWrapper = (e) => {
        if (e.key !== 'Enter') {
            return;
        }

        const { value } = e.target;

        savePickedUpAmountPerItem(value);
    }

    const savePickedUpAmountPerItem = async (amount) => {
        if (!amount || Number.isNaN(amount)) {
            setExpandedItem();
            setExpandPerItem(false);
            return;
        }

        const { id, variantId, accountId, vendorId, status, unitShort, pickedAmount } = expandedItem;
        if (status == orderItemStatusMap.CANCELED.key) {
            fireErrorNotification("This item is cancelled");
            setExpandedItem();
            setExpandPerItem(false);
            return;
        }

        if (Number(pickedAmount) === Number(amount)) {
            setExpandedItem();
            setExpandPerItem(false);
            return;
        }

        if (id) {
            const updateStatus = await dispatch(updateRouteReportPickupItemAmount({ id, amount }));
            if (updateStatus.error || updateStatus.payload.error) {
                fireErrorNotification(updateStatus.payload.error);
                hideExpanded();
                return;
            }
        } else if (selectedReport.route_report) {
            const createdStatus = await dispatch(createRouteReportPickupItem({ accountId, variantId, amount, vendorId, unit: unitShort }));
            if (createdStatus.error || createdStatus.payload.error) {
                fireErrorNotification(createdStatus.payload.error);
                hideExpanded();
                return;
            }
        }

        hideExpanded();

        dispatch(fetchCurrentSelectedRouteReport());
        fireSuccessNotification("Amount saved successfully");
    }

    const hideExpanded = () => {
        setExpandedItem();
        setExpandPerItem(false);
    }

    const saveNote = () => {
        const item = items[0];
        const { id, variantId, vendorId, amount } = item;

        if (!selectedReport.route_report) {
            fireErrorNotification('The route is not started yet');
            setAddOrEditNoteShown(false);
            return;
        }

        if (id) {
            dispatch(updateRouteReportPickupItemAmount({ id, amount, note }));
        } else if (selectedReport.route_report) {
            dispatch(createRouteReportPickupItem({ variantId, amount, vendorId, note }))
        }

        dispatch(fetchCurrentSelectedRouteReport());
        setAddOrEditNoteShown(false);
        fireSuccessNotification("Note saved successfully");
    }

    const getVariantName = () => {
        if (!items || items.length == 0) {
            return '';
        }

        const item = items[0];
        return keyedVariants[item.variantId]?.name;
    }

    const getProductName = () => {
        if (!items || items.length == 0) {
            return '';
        }

        const item = items[0];
        return keyedProducts[item.productId]?.name;
    }

    const discardNoteAddOrEdit = () => {
        setAddOrEditNoteShown(false);
        setNote(items[0].note);
    }

    const checkIfTheSameItem = (item) => {
        if (!expandedItem) {
            return false;
        }

        return item.variantId == expandedItem.variantId && item.unitShort == expandedItem.unitShort && item.accountId == expandedItem.accountId;
    }

    const setAllPickedUp = async () => {
        const itemsToCreate = items.filter(x => x.id == 0);
        const itemsToUpdate = items.filter(x => x.id != 0);

        for (let itemToCreate of itemsToCreate) {
            const { variantId, accountId, vendorId, status, unitShort } = itemToCreate;
            const amount = itemToCreate.quantity;
            const createdStatus = await dispatch(createRouteReportPickupItem({ accountId, variantId, amount, vendorId, unit: unitShort }));
            if (createdStatus.error || createdStatus.payload.error) {
                fireErrorNotification(createdStatus.payload.error);
                return;
            }
        }

        for (let itemToUpdate of itemsToUpdate) {
            const { id } = itemToUpdate;
            const amount = itemToUpdate.quantity;
            const updateResult = await dispatch(updateRouteReportPickupItemAmount({ id, amount }));
            if (updateResult.error || updateResult.payload.error) {
                fireErrorNotification(updateResult.payload.error);
                return;
            }
        }

        dispatch(fetchCurrentSelectedRouteReport());
        fireSuccessNotification("Items updated successfully");
    }

    const innerItemsRenderer = () => {
        // if (renderAsNote) {
        //     return (
        //         <Grid
        //             container
        //             sx={{ padding: '2px' }}
        //             width="100%"
        //             display="flex"
        //             alignItems='center'
        //             justifyContent='space-between'
        //         >
        //             <Grid item width="100%">
        //                 <Typography
        //                     sx={{ padding: 1 }}
        //                     multiline={true}
        //                     disabled
        //                     fullWidth={true}
        //                 >{note}</Typography>
        //             </Grid>

        //             <Grid item width="100%" display="flex" sx={{ marginTop: '5px', marginBottom: '5px' }}>
        //                 <Stack
        //                     width="100%"
        //                     direction="row"
        //                     justifyContent="space-between"
        //                     alignContent="center"
        //                     spacing={4}
        //                 >
        //                     {items[0].updated_at ? (<Typography
        //                         display="flex"
        //                         alignSelf="center"
        //                         fontSize="12px">
        //                         Updated at {dayjs(items[0].updated_at).format('h:mm a')} by {userMap[items[0].user]?.first_name}
        //                     </Typography>) : <Typography></Typography>}
        //                 </Stack>
        //             </Grid>
        //         </Grid>
        //     )
        // }

        return items.map((item, index) => {
            return (
                <Grid container
                    key={index}
                    width="100%"
                    display="flex"
                    alignItems='center'
                    sx={{
                        borderBottom: index == items.length - 1 ? 'none' : '1px solid #BABABA',
                    }}>
                    <Grid item width="70%">
                        <Typography sx={{
                            padding: '2px',
                            marginLeft: '2px',
                            // here we display the pickup route
                            backgroundColor: item.pickupRoute ? '#3FC6A5' : 'unset'
                        }}> {item.pickupRoute ? `Pickup for route ${routes.find(x => x.id == item.pickupRouteId)?.name}` : keyedAccounts[item.accountId]?.name}</Typography>
                    </Grid>
                    <Grid
                        item
                        width="30%"
                        display="flex"
                        sx={{
                            borderLeft: '1px solid #BABABA',
                        }}
                        justifyContent="center"
                        alignContent="center">
                        <Tooltip title={item?.status ? orderItemStatusMap[item.status].name : orderItemStatusMap[orderItemStatusMap.PENDING.key].name}>
                            <Grid container>
                                <Grid item width="90%" display="flex" alignContent="center" justifyContent="center">
                                    {checkIfTheSameItem(item) &&
                                        <TextField
                                            sx={{
                                                height: '25px',
                                                marginLeft: '2px',
                                                marginRight: '10px',
                                                padding: '2px'
                                            }}
                                            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                            autoFocus
                                            data-testid="test-picked-amount-id"
                                            defaultValue={item.pickedAmount}
                                            onBlur={amountChangedWrapper}
                                            onClick={e => { e.stopPropagation(); }}
                                            onKeyUp={keyUpWrapper}
                                            size="small" />}
                                    {!checkIfTheSameItem(item) &&
                                        <Typography
                                            display='flex'
                                            alignSelf="center"
                                            fontSize={14}
                                            onClick={() => { setExpandPerItem(prev => !prev); setExpandedItem(item); }}
                                            sx={{ cursor: 'pointer' }}>
                                            {item.quantity} {item.unit}
                                        </Typography>}
                                </Grid>
                                <Grid item width="10%">
                                    <StyledBadge badgeContent={1} variant='dot' color={item?.status ? orderItemStatusMap[item.status].colorText : orderItemStatusMap[orderItemStatusMap.PENDING.key].colorText} />
                                </Grid>
                            </Grid>
                        </Tooltip>
                    </Grid>
                </Grid>
            )
        });
    }

    const issuesRenderer = () => {
        return items.filter(x => x.status == orderItemStatusMap.APPROVED.key && x.quantity != x.pickedAmount).map((item, index) => (
            <Grid container
                key={index}
                width="100%"
                display="flex"
                alignItems='center'
                sx={{
                    borderBottom: index == items.length - 1 ? 'none' : '1px solid #BABABA',
                }}>
                <Grid
                    item
                    width="100%"
                    display="flex"
                    justifyContent="start"
                    alignContent="center">
                    <Typography color={'red'}>Needs to pickup {item.quantity} {item.unit} but picked up {item.pickedAmount ?? 0}</Typography>
                </Grid>
            </Grid>
        ));
    }

    return (
        <Accordion
            defaultExpanded={renderAsNote}
            sx={{
                boxShadow: "none",
                border: "1px solid #DBDFEA",
                borderRadius: "5px",
                marginBottom: '5px',
                "&.Mui-expanded": {
                    margin: 0,
                },
                "&::before": {
                    content: "none",
                },
                "& .MuiAccordionSummary-root.Mui-expanded": {
                    minHeight: "65px",
                },
                "& .MuiAccordionSummary-content.Mui-expanded": {
                    margin: 0,
                },
            }}>
            <AccordionSummary>
                <Grid container width="100%">
                    <Grid item width="65%" display="flex" alignItems="center">
                        <Stack direction="column" display="flex">
                            <Typography
                                sx={{
                                    fontSize: '18px',
                                }}
                                // noWrap
                                display="flex"
                                flex="start">{getProductName()}</Typography>

                            <Typography sx={{
                                fontSize: '12px'
                            }} display="flex" flex="start">{getVariantName()}</Typography>
                        </Stack>

                    </Grid>
                    <Grid item width="35%" display="flex" alignItems="center">
                        <Stack width="100%" direction='row' display="flex" justifyContent="space-around" alignItems="center">
                            {/* {!renderAsNote && expand ? (<TextField
                                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                autoFocus={true}
                                display="flex"
                                onChange={e => setAmount(e.target.value)}
                                value={amount}
                                fullWidth={false}
                                flex="start"
                                sx={{
                                    width: '60px',
                                    "&::before": {
                                        content: "none",
                                    }
                                }}
                                onBlur={amountChangedWrapper}
                                onClick={e => { e.stopPropagation(); }}
                                onKeyUp={keyUpWrapper}
                                size="small"
                            />) : null} */}
                            <Typography display="flex" flex="end" color="#7A7A7A">{totalPickedUp} / {totalToBePickedAmount} {Array.from(new Set(items.map(x => x.unitShort))).join(', ')}</Typography>
                        </Stack>
                    </Grid>
                </Grid>
            </AccordionSummary>
            <AccordionDetails>
                <Box sx={{
                    border: "1px solid #DBDFEA",
                    borderRadius: "5px",
                    boxShadow: 'none',
                    backgroundColor: '#F8F8F8',

                }}>
                    {innerItemsRenderer()}
                </Box>

                {issuesRenderer()}

                <Grid width="100%" sx={{ marginTop: '5px' }}>
                    <Box sx={{
                        border: "1px solid #DBDFEA",
                        borderRadius: "5px",
                        padding: '1px',
                        boxShadow: 'none',
                        backgroundColor: '#F8F8F8'
                    }}>
                        <Typography
                            sx={{ fontStyle: 'oblique' }}
                            noWrap
                            multiline={true}
                            disabled
                            fullWidth={true}
                        >{items[0] && items[0].note ? `${items[0].note}` : 'No notes for this item'}</Typography>
                    </Box>
                </Grid>

                <PickListItemActions
                    note={note}
                    addOrEditNoteShown={addOrEditNoteShown}
                    setNote={setNote}
                    saveNote={saveNote}
                    setAddOrEditNoteShown={setAddOrEditNoteShown}
                    discardNoteAddOrEdit={discardNoteAddOrEdit}
                    setAllPickedUp={setAllPickedUp} />
            </AccordionDetails>
        </Accordion >
    )
}

export default PickListItem;
