import { Link } from "react-router-dom";
import useAppDispatch from "../../hooks/useAppDispatch";
import TableButtons from "../../components/TableComponents/TableButtons";
import { removeCustomerAccount } from "../../redux/module/customer";

const account_name = {
  headerName: "Account",
  field: "name",
  wrapText: true,
  autoHeight: true,
  valueGetter: (params) => {
    return `${params.data.name}`;
  },
  cellRendererSelector: (params) => {
    return {
      component: AccountNameRenderer,
    };
  },
  flex: 4,
  minWidth: 150,
};

const account_territory = {
  headerName: "Territory",
  colId: "territory__name",
  field: "territory.name",
  valueGetter: (params) => params.data.territory?.name,
  comparator: () => {}, //disable default sorting
  minWidth: 150,
};

const account_address = {
  headerName: "Address",
  field: "address",
  wrapText: true,
  autoHeight: true,
  flex: 5,
  minWidth: 200,
};

const RenderAccountLink = (props) => {
  const dispatch = useAppDispatch();

  const handleDelete = async () => {
    await dispatch(removeCustomerAccount(props.data.id));
  };

  return (
    <TableButtons
      link={`/accounts/${props.data.id}`}
      handleDelete={handleDelete}
      deletePopupEntity="account"
      historyData={props.data.history || []}
    />
  );
};

const RendererPhoneNums = (params) => {
  return (
    <ol>
      {params.data.phone_numbers.map((item, index) => {
        return <li key={index}>{item.phone}</li>;
      })}
    </ol>
  );
};

const account_link = {
  field: "",
  sorting: false,
  sort: false,
  sortable: false,
  autoHeight: true,
  filter: false,
  cellRenderer: RenderAccountLink,
  flex: 2,
  minWidth: 120,
};

function AccountNameRenderer(params) {
  return (
    <span className="my-renderer">
      <Link
        to={`/accounts/${params.data.id}`}
        style={{ textDecoration: "none" }}
      >
        {params.data.name}
      </Link>
    </span>
  );
}

const account_email = {
  headerName: "Emails",
  field: "email",
  wrapText: true,
  autoHeight: true,
  valueGetter: (params) =>
    params.data.emails.map((item) => item.email)?.join(", "),
  flex: 4,
  minWidth: 200,
};
const account_phones = {
  headerName: "Phone Numbers",
  field: "phone_numbers",
  wrapText: true,
  autoHeight: true,
  /*  valueGetter: (params) =>
    params.data.phone_numbers
      .map((item) => `${item.phone}${item.is_default ? " (Primary)" : ""}`)
      ?.join(", "), */
  flex: 4,
  minWidth: 200,
  cellRenderer: RendererPhoneNums,
};
const internal_id = {
  headerName: "Internal Id",
  field: "internal_id",
  minWidth: 120,
};

const customerAccountsTable = [
  internal_id,
  account_name,
  account_email,
  account_phones,
  account_territory,
  account_address,
  account_link,
];

export default customerAccountsTable;
