import { memo } from "react";
import { useDroppable } from "@dnd-kit/core";
import { Box } from "@mui/material";

export const Droppable = memo((props) => {
  const { setNodeRef } = useDroppable({
    id: props.id,
    data: {
      type: props.type,
    },
  });

  return (
    <Box width="100%" ref={setNodeRef}>
      {props.children}
    </Box>
  );
});
