import React, { useState, useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Block from "../../../components/Block";
import {
  Grid,
  Stack,
  Box,
  Typography,
  Input,
  OutlinedInput,
} from "@mui/material";

function itemPriority(items) {
  const result = items.map((item, index) => {
    return { ...item, priority: index };
  });
  return result;
}

const Item = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  margin: theme.spacing(1),
}));

function AccountsDnD({
  accounts,
  routeData,
  setRouteData,
  accountSearch,
  setAccountSearch,
}) {
  const [items, setItems] = useState({
    accounts: accounts.filter(
      (account) =>
        !routeData.route_accounts.map((rv) => rv.id).includes(account.id)
    ),
    route_accounts: routeData.route_accounts,
  });
  useEffect(() => {
    setItems({
      accounts: accounts.filter(
        (account) =>
          !routeData.route_accounts.map((rv) => rv.id).includes(account.id)
      ),
      route_accounts: routeData.route_accounts,
    });
  }, [accounts, routeData]);
  const onDragEnd = (result) => {
    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const start = items[source.droppableId];
    const finish = items[destination.droppableId];

    if (start === finish) {
      // gotta reorder
      const newItems = Array.from(start);
      const [removed] = newItems.splice(source.index, 1);
      newItems.splice(destination.index, 0, removed);
      if (source.droppableId == "route_accounts") {
        setRouteData({ ...routeData, route_accounts: itemPriority(newItems) });
      }

      //setItems(newState);
      return;
    }

    // Moving from one list to another
    const startItems = Array.from(start);
    const [removed] = startItems.splice(source.index, 1);
    const newStart = { ...items, [source.droppableId]: startItems };

    const finishItems = Array.from(finish);
    finishItems.splice(destination.index, 0, removed);
    const newFinish = { ...items, [destination.droppableId]: finishItems };

    const newState = {
      ...items,
      [source.droppableId]: newStart[source.droppableId],
      [destination.droppableId]: newFinish[destination.droppableId],
    };
    if (source.droppableId == "accounts") {
      setRouteData({
        ...routeData,
        route_accounts: newFinish[destination.droppableId],
      });
    }
    if (source.droppableId == "route_accounts") {
      setRouteData({
        ...routeData,
        route_accounts: newStart[source.droppableId],
      });
    }
    //setItems(newState);
  };

  return (
    <Block>
      <Typography component="p" variant="label" mb="11px">
        Accounts
      </Typography>
      <DragDropContext onDragEnd={onDragEnd}>
        <Grid
          container
          columnSpacing={{ xs: 1, sm: 3, xl: "10px" }}
          margin="0px 0 0"
        >
          <Grid item md={8} lg={8} sx={{ maxWidth: "908px", width: "100%" }}>
            <Droppable droppableId={"route_accounts"} key={"route_accounts"}>
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  style={{
                    width: "100%",
                    textAlign: "center",
                    fontSize: "12px",
                    color: "#8E8E8E",
                    minHeight: 200,
                    height: "100%",
                    padding: "5px",
                    borderRadius: "5px",
                    backgroundColor: "#fafafa",
                  }}
                >
                  {items.route_accounts.map((item, index) => (
                    <Draggable
                      draggableId={`${item.id}`}
                      index={index}
                      key={item.id}
                    >
                      {(provided) => (
                        <Item
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          {item.name}
                        </Item>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </Grid>
          <Grid item md={4} lg={4} sx={{ maxWidth: "908px", width: "100%" }}>
            <div style={{ padding: "8px" }}>
              <Typography variant="inputLabel" component="p">
                Search Accounts
              </Typography>
              <OutlinedInput
                fullWidth
                name="accountSearch"
                value={accountSearch}
                onChange={(e) => setAccountSearch(e.target.value)}
              />
            </div>

            <Droppable droppableId={"accounts"} key={"accounts"}>
              {(provided) => (
                <Box
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  style={{
                    width: "100%",
                    textAlign: "center",
                    fontSize: "12px",
                    color: "#8E8E8E",
                    minHeight: 200,
                    height: "calc(100% - 90px)",
                    padding: "5px",
                    borderRadius: "5px",
                    backgroundColor: "#fafafa",
                  }}
                >
                  {items.accounts.slice(0,15).map((item, index) => (
                    <Draggable
                      draggableId={`${item.id}`}
                      index={index}
                      key={item.id}
                    >
                      {(provided) => (
                        <Item
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          {item.name}
                        </Item>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </Box>
              )}
            </Droppable>
          </Grid>
        </Grid>
      </DragDropContext>
    </Block>
  );
}

export default AccountsDnD;
