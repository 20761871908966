import { useEffect, useState } from "react";
import { Box, Button, Grid, Stack, TextField, Typography } from "@mui/material";
import Block from "./Block";
import { useParams } from "react-router-dom";
import DatePicker from "./DatePicker2";
import useAppDispatch from "../hooks/useAppDispatch";
import { DATE_FORMAT } from "../utils/constants";

const UpchargeAddRefactoredForm = ({
  fetchOverrides,
  createOverride,
  elementKey,
  cleanOverrideFormFlag,
  setCleanOverrideFormFlag,
}) => {
  const { id } = useParams();
  const dispatch = useAppDispatch();

  const [override, setOverride] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [note, setNote] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const cleanAllFields = () => {
    setNote("");
    setOverride("");
    setStartDate(null);
    setEndDate(null);
  };

  async function handleSubmit(event) {
    event.preventDefault();
    setIsSubmitting(true);
    const payload = {
      id,
      override,
      start_date: startDate?.format(DATE_FORMAT) || null,
      end_date: endDate?.format(DATE_FORMAT) || null,
      note: note || null,
      [elementKey]: id,
    };

    const data = await dispatch(createOverride(payload));
    if (data.payload.id) {
      await dispatch(fetchOverrides(id));
      cleanAllFields();
    }
    setIsSubmitting(false);
  }

  useEffect(() => {
    if (cleanOverrideFormFlag) {
      cleanAllFields();
      setCleanOverrideFormFlag(false);
    }
  }, [cleanOverrideFormFlag]);

  return (
    <Grid
      container
      columnSpacing={{ xs: 1, sm: 3, xl: "50px" }}
      margin="20px 0 0"
    >
      <Grid item md={9} lg={9} sx={{ maxWidth: "908px", width: "100%" }}>
        <form onSubmit={handleSubmit}>
          <Block>
            <Grid
              container
              spacing={2}
              mb={2}
              sx={{
                maxWidth: "541px",
                width: "100%",
              }}
            >
              <Grid item xs={12} sm={6}>
                <TextField
                  data-testid={`test-upcharge-id`}
                  fullWidth
                  sx={{
                    maxWidth: "262px",
                  }}
                  type="number"
                  variant="outlined"
                  name="upcharge"
                  label="Upcharge"
                  required
                  value={override}
                  onChange={(e) => setOverride(e.target.value)}
                />
              </Grid>
              <Grid
                item
                xs={6}
                sx={{
                  display: {
                    xs: "none",
                    sm: "grid",
                  },
                }}
              />
              <Grid item xs={12} sm={6}>
                <DatePicker
                  testId="start-date-id"
                  label="Start date"
                  controlledValue={startDate}
                  setControlledValue={setStartDate}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <DatePicker
                  testId="end-date-id"
                  label="End Date"
                  controlledValue={endDate}
                  setControlledValue={setEndDate}
                />
              </Grid>
            </Grid>
            <Stack
              direction="row"
              alignItems="flex-end"
              flexWrap="wrap"
              gap="17px"
            >
              <Box
                display={"flex"}
                gap={"17px"}
                alignItems={"center"}
                sx={{
                  width: "100%",
                  flexDirection: {
                    sm: "row",
                    xs: "column",
                  },
                  alignItems: {
                    sm: "center",
                    xs: "flex-start",
                  },
                }}
              >
                <TextField
                  fullWidth
                  variant="outlined"
                  data-testid={`test-note-id`}
                  name="note"
                  label="Note"
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                  type="text"
                />
              </Box>
            </Stack>
            <Stack direction="row" justifyContent="end" mt="10px">
              <Button
                type="submit"
                variant="contained"
                disabled={isSubmitting}
                sx={{ pt: 1, pb: 1 }}
              >
                Create Override
              </Button>
            </Stack>
          </Block>
        </form>
      </Grid>
    </Grid>
  );
};

export default UpchargeAddRefactoredForm;
