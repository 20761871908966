import React from "react";

import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import DeleteForeverTwoToneIcon from "@mui/icons-material/DeleteForeverTwoTone";
import EditLocationTwoToneIcon from "@mui/icons-material/EditLocationTwoTone";

export default function IPsList({
  whitelistedIps,
  setUpdateOpen,
  setDeleteOpen,
  setSelected,
}) {
  return (
    <Stack className="list-container" sx={{ my: "25px", width: "100%" }}>
      <List
        sx={{
          width: "100%",
          bgcolor: "background.paper",
          position: "relative",
          overflow: "auto",
          maxHeight: 700,
          px: 2,
          zIndex: "0 !important",
        }}
      >
        {whitelistedIps.length === 0 && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Typography variant="h5">No whitelisted IPs</Typography>
          </Box>
        )}

        {whitelistedIps.map((value, i) => (
          <ListItem
            key={i}
            disableGutters
            secondaryAction={
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
                spacing="5px"
              >
                <IconButton
                  edge="end"
                  aria-label="edit"
                  onClick={() => {
                    setSelected({
                      index: i,
                      value: value,
                    });
                    setUpdateOpen(true);
                  }}
                >
                  <EditLocationTwoToneIcon />
                </IconButton>
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => {
                    setSelected({
                      index: i,
                      value: value,
                    });
                    setDeleteOpen(true);
                  }}
                >
                  <DeleteForeverTwoToneIcon />
                </IconButton>
              </Stack>
            }
            sx={{
              borderBottom:
                i === whitelistedIps.length - 1 ? "none" : "1px solid #E6E6E6",
              padding: "10px 0",
            }}
          >
            <ListItemText primary={value} />
          </ListItem>
        ))}
      </List>
    </Stack>
  );
}
