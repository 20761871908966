import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Input,
  Stack,
  Typography,
} from "@mui/material";
import { EditRoad } from "@mui/icons-material";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import CheckIcon from "@mui/icons-material/Check";
import DeleteIcon from "@mui/icons-material/Delete";
import SelectRouteModal from "./SelectRouteModal";
import ChangesHistory from "../History";
import DiscountIcon from "@mui/icons-material/Discount";
import NumberInput from "../NumberInput";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

const OrderItemsModalItem = ({
  item,
  changeItemVal,
  changeItemRoute,
  itemsRoutes,
}) => {
  const [history, setHistory] = useState({});
  const [changeRoute, setChangeRoute] = useState(false);
  const [showSetNote, setShowSetNote] = useState(false);
  const [showSetDiscount, setShowSetDiscount] = useState(false);
  const [discountValue, setDiscountValue] = useState(item.discount_value || 0);
  const [priceEdit, setPriceEdit] = useState(item.discount_type || null);
  const [errors, setErrors] = useState({});
  const [isCostEdit, setIsCostEdit] = useState(false);
  const [isPriceEdit, setIsPriceEdit] = useState(false);

  const toggleSetNote = () => {
    setShowSetNote(!showSetNote);
  };
  const toggleSetDiscount = () => {
    setShowSetDiscount(!showSetDiscount);
  };
  const getItemPrice = () => {
    if (
      item.item_details?.break_into_pieces &&
      item.unit != item.item_details?.sold_by
    ) {
      return (
        parseFloat(item.item_details?.price?.price) /
        parseInt(item.item_details?.sold_by_qty)
      );
    }
    return parseFloat(item.item_details?.price?.price);
  };

  const handleCountChange = (event) => {
    const nameVal = `${item.item}-${item.unit}-${item.display_name || ""}`;
    changeItemVal(nameVal, { qty: event.target.value });
  };

  const handleBlur = () => {
    const nameVal = `${item.item}-${item.unit}-${item.display_name || ""}`;
    changeItemVal(nameVal, { qty: item.qty || 1 });
  };

  function getDiscount() {
    if (item.discount_value && !isNaN(item.discount_value)) {
      if (item.discount_type == "PERCENT") {
        return getItemPrice() * (item.discount_value / 100);
      } else {
        return item.discount_value;
      }
    }
    return 0;
  }

  function getDiscountText() {
    const discount = getDiscount();
    if (discount) {
      return `${discount > 0 ? "-" : "+"}$${(discount * item.qty).toFixed(2)}`;
    }
    return "";
  }

  const getItemGeneralPrice = () => {
    if (item.qty) {
      return ((getItemPrice() - getDiscount()) * item.qty).toFixed(2);
    }
    return "0.00";
  };

  const handleSaveRoute = (newRoute) => {
    const nameVal = `${item.item}-${item.unit}-${item.display_name || ""}`;
    changeItemRoute(nameVal, {
      route: { id: newRoute.value, name: newRoute.label },
      manual_route: true,
    });
  };

  const handleSubmitDiscount = () => {
    setErrors({});
    const unit_price = item?.item_details?.price?.price || item?.unit_price;
    const newVal = {
      discount_type: "PERCENT",
      discount_value: discountValue,
    };
    if (priceEdit === "PERCENT") {
      if (discountValue <= 100 && discountValue >= 0) {
        changeItemVal(
          item.item + "-" + item.unit + "-" + (item.display_name || ""),
          newVal
        );
      } else {
        setErrors({
          discount: `discount value should not exceed 100% ${discountValue}`,
        });
      }
    }
    if (priceEdit === "DOLLAR") {
      if (discountValue >= 0 && discountValue <= unit_price) {
        newVal["discount_type"] = "DOLLAR";
        changeItemVal(
          item.item + "-" + item.unit + "-" + (item.display_name || ""),
          newVal
        );
      } else {
        setErrors({ discount: "discount should not exceed the item price" });
      }
    }
    if (priceEdit === "TARGET_PRICE") {
      if (!isNaN(parseFloat(discountValue))) {
        newVal["discount_type"] = "DOLLAR";
        newVal["discount_value"] = unit_price - discountValue;
        changeItemVal(
          item.item + "-" + item.unit + "-" + (item.display_name || ""),
          newVal
        );
      } else {
        setErrors({
          discount: `discount should not be less than 0`,
        });
      }
    }
    setShowSetDiscount(false);
  };

  /// DISCOUNT SUBMITTING LOGIC HERE

  useEffect(() => {
    // if (item.order_item_id) {
    //   getOrderItemHistory(item.order_item_id).then(
    //     (res) => {
    //       setHistory(res.data);
    //     },
    //     (error) => {},
    //   );
    // }
  }, [item]);

  function showItemHistory() {}

  const selectedButton = {
    fontWeight: 600,
    padding: "6px",
    marginRight: "0",
  };
  const unselectedButton = {
    fontWeight: 200,
    padding: "6px",
    marginRight: "0",
  };

  const inputStyle = {
    width: "50px",
    fontSize: "14px",
    color: "black",
    backgroundColor: "transparent",
    border: "none",
    textAlign: "center",
    padding: "1px 0px",
    flexShrink: 0,
  };
  return (
    <Stack
      sx={{
        padding: "10px 14px",
        borderRadius: "4px",
        backgroundColor: "#F5F5F5",
        marginTop: "8px",
      }}
    >
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={9}>
            <Stack
              direction={"row"}
              alignContent={"center"}
              alignItems={"center"}
              spacing={2}
              marginTop={"5px"}
            >
              <Typography
                sx={{
                  fontSize: "13px",
                  fontWeight: "500",
                }}
              >
                {item.display_name || item.item_details?.name} (
                {item.unit || item.item_details?.sold_by}
                {item.unit && item.unit !== item.item_details?.sold_by && (
                  <small> broken to piece</small>
                )}{" "}
                <small>
                  {" "}
                  ${getItemPrice().toFixed(2)}/
                  {item.unit || item.item_details?.sold_by}
                </small>
                )
                {item.unit &&
                  item.unit !== item.item_details?.sold_by &&
                  item.qty < item.item_details?.piece_min_qty && (
                    <small style={{ color: "red" }}>
                      {" "}
                      quantity should be gt than{" "}
                      {item.item_details?.piece_min_qty}{" "}
                    </small>
                  )}
                {item.qty &&
                  item?.item_details?.order_qty &&
                  parseFloat(item.qty) < item?.item_details?.order_qty && (
                    <small style={{ color: "red" }}>
                      {" "}
                      quantity should be {">="} {item?.item_details?.order_qty}{" "}
                    </small>
                  )}
              </Typography>
            </Stack>
          </Grid>

          <Grid item xs={12} sm={3}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "20px",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              <Stack direction={"row"} spacing={1}>
                {getDiscountText() && (
                  <Typography
                    style={{ color: "red" }}
                    fontSize={"11px"}
                    alignSelf={"end"}
                  >
                    ({getDiscountText()})
                  </Typography>
                )}
                <Typography sx={{ fontSize: "13px", fontWeight: "600" }}>
                  ${getItemGeneralPrice()}
                </Typography>
              </Stack>

              <Input
                value={item.qty}
                fullWidth
                style={{
                  width: "30px",
                  fontSize: "14px",
                  color: "black",
                  backgroundColor: "transparent",
                  border: "none",
                  textAlign: "center",
                  padding: "1px 0px",
                  flexShrink: 0,
                  //marginBottom:'5px'
                  //padding:"0px",
                }}
                type="text"
                onChange={(e) => handleCountChange(e)}
                onBlur={handleBlur}
              />
              {/* BUTTONS */}
              <Box sx={{ display: "flex", gap: "4px" }}>
                {(item.status === "PENDING" ||
                  item.status === "AUTO_APPROVE" ||
                  item.status === "CANCELED" ||
                  !item.status) && (
                  <Button
                    sx={{
                      padding: "4px 5px",
                      fontSize: "12px",
                      minWidth: "0",
                      width: "30px",
                      height: "30px !important",
                    }}
                    variant="contained"
                    onClick={() => {
                      console.log(item.display_name || "");
                      changeItemVal(
                        item.item +
                          "-" +
                          item.unit +
                          "-" +
                          (item.display_name || ""),
                        {
                          status: "APPROVED",
                        }
                      );
                    }}
                    color="success"
                    size="small"
                  >
                    <CheckIcon fontSize="small" />
                  </Button>
                )}
                {(item.status === "APPROVED" ||
                  item.status === "AUTO_APPROVE" ||
                  item.status === "PENDING" ||
                  !item.status) && (
                  <Button
                    sx={{
                      padding: "4px 5px",
                      fontSize: "12px",
                      minWidth: "0",
                      width: "30px",
                      height: "30px !important",
                    }}
                    variant="contained"
                    onClick={() =>
                      changeItemVal(
                        item.item +
                          "-" +
                          item.unit +
                          "-" +
                          (item.display_name || ""),
                        {
                          status: "CANCELED",
                        }
                      )
                    }
                    color="error"
                    size="small"
                  >
                    <DeleteIcon fontSize="small" />
                  </Button>
                )}
                <Button
                  sx={{
                    padding: "4px 5px",
                    fontSize: "12px",
                    minWidth: "0",
                    width: "30px",
                    height: "30px !important",
                  }}
                  variant="contained"
                  color="secondary"
                  size="small"
                  onClick={() => {
                    window.open(
                      `/products/${
                        item.product || item?.item_details?.product
                      }/variants/${item.item}`,
                      "_blank"
                    );
                  }}
                >
                  <VisibilityIcon fontSize="small" />
                </Button>
                {item.order_item_id && (
                  <ChangesHistory
                    historyData={history || []}
                    fontSize={"small"}
                    historyIcon={true}
                  />
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          my=".2rem"
        >
          <Stack direction="row">
            <Typography fontSize={"11px"} marginRight={"4px"}>
              SKU: {item?.item_details?.sku}
            </Typography>
            {item?.vendor_details?.vendorName && (
              <Typography fontSize={"11px"}>
                Vendor: {item?.vendor_details?.vendorName}
              </Typography>
            )}
            {item?.item_details?.vendor_details?.vendorName && (
              <Typography fontSize={"11px"}>
                Vendor: {item?.item_details?.vendor_details?.vendorName}
              </Typography>
            )}
            {item.item_details?.vendor_item_id && (
              <Typography fontSize={"11px"} marginLeft={"4px"}>
                Vendor item #: {item?.item_details?.vendor_item_id}
              </Typography>
            )}
          </Stack>
        </Stack>
        {item?.unusual_qty && (
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            my=".2rem"
          >
            <Stack direction="row">
              <Typography fontSize={"11px"} color={"red"}>
                {item?.unusual_qty_msg}
              </Typography>
            </Stack>
          </Stack>
        )}
      </Grid>

      <Grid item xs={12}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            my: "10px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: "10px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "4px",
              }}
            >
              {item?.item_details?.cost && isCostEdit ? (
                <Input
                  fullWidth
                  style={inputStyle}
                  type="number"
                  value={item?.unit_cost || item?.item_details?.cost}
                  onChange={(e) =>
                    changeItemVal(
                      `${item.item}-${item.unit}-${item.display_name || ""}`,
                      {
                        unit_cost: e.target.value,
                      }
                    )
                  }
                />
              ) : (
                <Typography
                  fontSize={"14px"}
                  marginRight={"4px"}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "4px",
                  }}
                >
                  {item?.unit_cost ||
                    (item?.item_details?.cost && (
                      <span>
                        Cost: ${item?.unit_cost || item?.item_details?.cost}{" "}
                      </span>
                    ))}
                </Typography>
              )}

              <IconButton
                onClick={() => setIsCostEdit(!isCostEdit)}
                sx={{
                  display: "flex",
                  flexShrink: "0",
                }}
              >
                {isCostEdit ? (
                  <CheckIcon fontSize="small" />
                ) : (
                  <EditOutlinedIcon fontSize="small" />
                )}
              </IconButton>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "4px",
              }}
            >
              {isPriceEdit ? (
                <Input
                  fullWidth
                  style={inputStyle}
                  type="number"
                  value={item?.unit_price || item?.item_details?.price?.price}
                  onChange={(e) =>
                    changeItemVal(
                      `${item.item}-${item.unit}-${item.display_name || ""}`,
                      {
                        unit_price: Number(e.target.value),
                      }
                    )
                  }
                />
              ) : (
                <Typography
                  fontSize={"14px"}
                  marginRight={"4px"}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "4px",
                  }}
                >
                  {item?.unit_price ||
                    (item?.item_details?.price?.price && (
                      <span>
                        Price: $
                        {item?.unit_price || item?.item_details?.price?.price}{" "}
                      </span>
                    ))}
                </Typography>
              )}

              <IconButton
                onClick={() => setIsPriceEdit(!isPriceEdit)}
                sx={{
                  display: "flex",
                  flexShrink: "0",
                }}
              >
                {isPriceEdit ? (
                  <CheckIcon fontSize="small" />
                ) : (
                  <EditOutlinedIcon fontSize="small" />
                )}
              </IconButton>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "20px",
              }}
            >
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
                gap={1}
                sx={{ minWidth: "100px" }}
              >
                <RouteDisplay item={item} itemsRoutes={itemsRoutes} />
                <Button
                  sx={{
                    display: "flex",
                    flexShrink: 0,
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "4px",
                    fontSize: "12px",
                    minWidth: "30px",
                    width: "30px",
                    height: "30px !important",
                  }}
                  // variant="contained"
                  // color="primary"
                  size="small"
                  onClick={() => setChangeRoute(true)}
                >
                  <EditRoad fontSize="small" />
                </Button>
              </Stack>

              <SelectRouteModal
                item={item}
                changeRoute={changeRoute}
                setChangeRoute={setChangeRoute}
                handleSaveRoute={handleSaveRoute}
              />
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              gap: "20px",
            }}
          >
            <Box
              onClick={toggleSetDiscount}
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "4px",
                cursor: "pointer",
                color: "#0288d1",
              }}
            >
              {showSetDiscount ? (
                <RemoveCircleOutlineIcon fontSize="small" color="inherit" />
              ) : (
                <AddCircleOutlineIcon fontSize="small" color="inherit" />
              )}

              <Typography fontSize="14px" fontWeight={500} color="inherit">
                {showSetDiscount ? "Close Discount" : "Add Discount"}
              </Typography>
            </Box>
            <Box
              onClick={toggleSetNote}
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "4px",
                cursor: "pointer",
                color: "#0288d1",
              }}
            >
              {showSetNote ? (
                <RemoveCircleOutlineIcon fontSize="small" color="inherit" />
              ) : (
                <AddCircleOutlineIcon fontSize="small" color="inherit" />
              )}
              <Typography fontSize="14px" fontWeight={500} color="inherit">
                {showSetNote ? "Close Note" : "Add Note"}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Grid>

      {item.notes && (
        <Grid item xs={12}>
          <Typography fontSize={"12px"}>
            <b>Schedule Note:</b> {item.notes}
          </Typography>
        </Grid>
      )}
      <Grid item xs={12}>
        {showSetDiscount && (
          <Stack direction={"row"} spacing={2}>
            <div>
              <Button
                sx={priceEdit === "PERCENT" ? selectedButton : unselectedButton}
                onClick={() => {
                  setPriceEdit("PERCENT");
                  setDiscountValue(0);
                }}
              >
                Percentage
              </Button>
              <Button
                sx={priceEdit === "DOLLAR" ? selectedButton : unselectedButton}
                onClick={() => {
                  setPriceEdit("DOLLAR");
                  setDiscountValue(0);
                }}
              >
                Dollar AMNT
              </Button>
              <Button
                sx={
                  priceEdit === "TARGET_PRICE"
                    ? selectedButton
                    : unselectedButton
                }
                onClick={() => {
                  setPriceEdit("TARGET_PRICE");
                  setDiscountValue(
                    item?.item_details?.price?.price || item?.unit_price
                  );
                }}
              >
                Target Price
              </Button>
            </div>
            <div>
              {priceEdit === "PERCENT" && (
                <>
                  <NumberInput
                    setValue={setDiscountValue}
                    value={discountValue}
                    unit={"-%"}
                    setPriceEdit={setPriceEdit}
                    handleClick={handleSubmitDiscount}
                  />
                </>
              )}
              {priceEdit === "DOLLAR" && (
                <>
                  <NumberInput
                    setValue={setDiscountValue}
                    value={discountValue}
                    unit={"-$"}
                    setPriceEdit={setPriceEdit}
                    handleClick={handleSubmitDiscount}
                  />
                </>
              )}
              {priceEdit === "TARGET_PRICE" && (
                <>
                  <NumberInput
                    setValue={setDiscountValue}
                    value={discountValue}
                    unit={"$"}
                    setPriceEdit={setPriceEdit}
                    handleClick={handleSubmitDiscount}
                  />
                </>
              )}
            </div>
          </Stack>
        )}
      </Grid>
      <Grid item xs={12}>
        {showSetNote && (
          <Input
            size="small"
            fullWidth
            placeholder="Add note"
            sx={{ fontSize: "14px" }}
            value={item?.invoice_note || ""}
            onChange={(e) =>
              changeItemVal(
                `${item.item}-${item.unit}-${item.display_name || ""}`,
                {
                  invoice_note: e.target.value || (item?.invoice_note ?? ""),
                }
              )
            }
          />
        )}
        {!showSetNote && item.invoice_note && (
          <Typography sx={{ fontSize: "13px", fontWeight: "500" }}>
            {item.invoice_note}
          </Typography>
        )}
      </Grid>
      {Object.keys(errors).length > 0 && (
        <Grid xs={12} item>
          <Stack>
            {Object.keys(errors).map((ek) => (
              <Typography fontSize={10} color={"red"}>
                {errors[ek]}
              </Typography>
            ))}
          </Stack>
        </Grid>
      )}
    </Stack>
  );
};

function RouteDisplay({ item, itemsRoutes }) {
  function routeOrTbd() {
    if (
      itemsRoutes &&
      itemsRoutes[
        `${item.item}-${item.unit}-${item.display_name || item.name || ""}`
      ]
    ) {
      const name =
        itemsRoutes[
          `${item.item}-${item.unit}-${item.display_name || item.name}`
        ].name || "No Route";
      return { name, color: name == "No Route" ? "red" : "" };
    }
    return { name: "calculating...", color: "yellow" };
  }

  return (
    <Stack direction="column" justifyContent="center" gap={1}>
      {item.route && item.route.id && (
        <Typography
          sx={{
            fontSize: "11px",
            fontWeight: "400",
          }}
        >
          {item.route.name}
        </Typography>
      )}
      {(!item.route || !item.route?.id) && (
        <Typography
          sx={{
            fontSize: "11px",
            fontWeight: "400",
            color: routeOrTbd().color,
          }}
        >
          {!item.route ? "No route" : routeOrTbd().name}
        </Typography>
      )}
    </Stack>
  );
}

export default OrderItemsModalItem;

{
  /*
<IconButton
                variant="outlined"
                sx={{
                  padding: "4px 5px",
                  fontSize: "12px",
                  minWidth: "0",
                  //width: "30px",
                  height: "30px !important",
                }}
                onClick={() => {
                  window.open(
                    `/products/${
                      item.product || item?.item_details?.product
                    }/variants/${item.item}`,
                    "_blank",
                  );
                }}
              >
                <VisibilityIcon />
              </IconButton>
*/
}
