import GoogleMapReact from 'google-map-react'

// const SimpleMap = withScriptjs(withGoogleMap((props) =>
//     <GoogleMap
//         defaultZoom={8}
//         defaultCenter={{ lat: -34.397, lng: 150.644 }}
//     >
//         {props.isMarkerShown && <Marker position={{ lat: -34.397, lng: 150.644 }} />}
//     </GoogleMap>
// ))

const LocationPin = ({ text }) => (
    <div className="pin">
        {/* <Icon icon={locationIcon} className="pin-icon" /> */}
        <p className="pin-text">{text}</p>
    </div>
)

const SimpleMapWrapper = ({ location, height = 50 }) => {
    return (
        <div style={{ height, width: 'auto' }}>
            <GoogleMapReact
                bootstrapURLKeys={{ key: 'AIzaSyBX7CNIKlFdaCz2ssO5pa58yDIBxOwbcCQ' }}
                center={{lat: Number(location.lat), lng: Number(location.lng)}}
                defaultZoom={12}
            >
                {location && <LocationPin
                    lat={location.lat}
                    lng={location.lng}
                    text={location.address}
                />}
            </GoogleMapReact>
        </div>
    )
}

export default SimpleMapWrapper;