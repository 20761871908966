import { useState, useRef, useEffect } from "react";
import {
  Button,
  Typography,
  Stack,
  TextField,
  Box,
  RadioGroup,
  FormControlLabel,
  Radio,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Input,
  Select,
  MenuItem,
} from "@mui/material";
import { NavLink } from "react-router-dom";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import Block from "../../components/Block";
import SearchIcon from "@mui/icons-material/Search";
import DataTable from "../../components/DataTable";
import { Link } from "react-router-dom";
import {
  createCpAlert,
  deleteCpAlert,
  listCpAlerts,
  patchCpAlert,
} from "../../distflowAPI/settingsApi";
import { DATE_FORMAT, DATE_FORMAT_FRONT } from "../../utils/constants";
import dayjs from "dayjs";
import DatePickerCustom2 from "../../components/DatePicker2";
import { smallButton } from "../../utils/smallButton";

const colorChoices = {
  WARNING: "orange",
  ERROR: "red",
  SUCCESS: "green",
  INFO: "blue",
};
const defaultColDef = {
  flex: 1,
  filter: false,
  floatingFilter: false,
};

function getDateOrEmpty(date) {
  const val = dayjs(date);
  return val.isValid() ? val.format(DATE_FORMAT_FRONT) : "-";
}

const CustomerPortalAlert = () => {
  const columnDefs = [
    {
      headerName: "Message",
      field: "message",
      autoHeight: true,
      filter: false,
      flex: 7,
    },
    {
      headerName: "Start Date",
      field: "start_date",
      valueGetter: (params) => getDateOrEmpty(params.data.start_date),
    },
    {
      headerName: "End Date",
      field: "end_date",
      valueGetter: (params) => getDateOrEmpty(params.data.end_date),
    },
    {
      headerName: "Alert Color",
      field: "severity",
      valueGetter: (params) => colorChoices[params.data.severity],
    },
    {
      headerName: "Actions",
      cellRenderer: (params) => (
        <Stack direction={"row"} spacing={2}>
          <Button
            variant="contained"
            sx={smallButton}
            onClick={() => {
              setIsCreateOpen(false);
              setMessageToEdit(params.data);
            }}
          >
            Edit
          </Button>
          <Button
            variant="contained"
            color="error"
            sx={smallButton}
            onClick={() =>
              deleteCpAlert(params.data.id).then((res) => updateTable())
            }
          >
            Delete
          </Button>
        </Stack>
      ),
      flex: 1.5,
    },
  ];
  const [rawTableData, setrawTableData] = useState([]);
  const gridRef = useRef();
  const [isCreateOpen, setIsCreateOpen] = useState(false);
  const [messageToEdit, setMessageToEdit] = useState(false);
  function updateTable() {
    listCpAlerts().then((res) => {
      setrawTableData(res.data.results);
    });
  }
  useEffect(() => {
    updateTable();
  }, []);
  return (
    <>
      <Typography
        fontSize="23px"
        fontWeight={800}
        component="h2"
        data-testid="page-title"
      >
        Alerts
      </Typography>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        alignItems={{ xs: "space-between", sm: "center" }}
        justifyContent="space-between"
        spacing={1.5}
        my="25px"
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          spacing="15px"
        >
          <Button
            className="btn-height"
            to="/settings/create"
            variant="contained"
            onClick={() => setIsCreateOpen(true)}
          >
            Add New
          </Button>
        </Stack>
      </Stack>

      <Box className="table-wrapper">
        <DataTable
          rowData={rawTableData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          rowHeight={53}
          headerHeight={60}
          ref={gridRef}
        />
      </Box>
      <CreateAlert
        open={isCreateOpen}
        setOpen={setIsCreateOpen}
        onConfirm={(payload) => {
          createCpAlert(payload).then(
            (res) => {
              console.log("res is: ", res);
              updateTable();
              setIsCreateOpen(false);
            },
            (err) => {
              console.log("error: ", err);
            }
          );
        }}
      />
      <UpdateAlert
        messageToEdit={messageToEdit}
        setMessageToEdit={setMessageToEdit}
        onConfirm={(payload) => {
          patchCpAlert(messageToEdit.id, payload).then(
            (res) => {
              console.log("res is: ", res);
              updateTable();
              setIsCreateOpen(false);
              setMessageToEdit(false);
            },
            (err) => {
              console.log("error: ", err);
            }
          );
        }}
      />
    </>
  );
};

export default CustomerPortalAlert;

function AlertCreateEdit({ open, setOpen, messageToEdit, onConfirm }) {
  const [message, setMessage] = useState(messageToEdit?.message || "");
  const [startDate, setStartDate] = useState(messageToEdit?.start_date || null);
  const [endDate, setEndDate] = useState(messageToEdit?.end_date || null);
  const [severity, setSeverity] = useState(
    messageToEdit?.severity || "WARNING"
  );
  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Typography>Create a new alert</Typography>
      </DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <Typography>Message</Typography>
          <Input
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            fullWidth
          />
          <Stack direction={"row"} spacing={2}>
            <DatePickerCustom2
              label={"Start Date"}
              controlledValue={startDate}
              setControlledValue={setStartDate}
            />
            <DatePickerCustom2
              label={"End Date"}
              controlledValue={endDate}
              setControlledValue={setEndDate}
            />
            <Select
              value={severity}
              label="Color"
              onChange={(e) => {
                setSeverity(e.target.value);
              }}
              sx={{ minWidth: 180, zIndex: 1800, maxHeight: 40 }}
              MenuProps={{
                style: { zIndex: 35001 },
              }}
            >
              {Object.keys(colorChoices).map((colorKey) => {
                return (
                  <MenuItem value={colorKey}>{colorChoices[colorKey]}</MenuItem>
                );
              })}
            </Select>
          </Stack>
          {message && (
            <>
              <Typography>Preview</Typography>
              <Alert severity={severity.toLowerCase()}>{message}</Alert>
            </>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          variant={"contained"}
          color={"error"}
          onClick={() => setOpen(false)}
        >
          Cancel
        </Button>
        <Button
          variant={"contained"}
          onClick={() =>
            onConfirm({
              message,
              start_date: startDate
                ? startDate.isValid()
                  ? startDate.format(DATE_FORMAT)
                  : null
                : null,
              end_date: endDate
                ? endDate.isValid()
                  ? endDate.format(DATE_FORMAT)
                  : null
                : null,
              severity,
            })
          }
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function CreateAlert({ open, setOpen, onConfirm }) {
  return (
    <AlertCreateEdit open={open} setOpen={setOpen} onConfirm={onConfirm} />
  );
}
function UpdateAlert({ messageToEdit, setMessageToEdit, onConfirm }) {
  return (
    <AlertCreateEdit
      open={messageToEdit !== false}
      setOpen={setMessageToEdit}
      onConfirm={onConfirm}
      messageToEdit={messageToEdit}
    />
  );
}
