import React, { useState } from "react";

import { Typography, Button, Stack, OutlinedInput } from "@mui/material";
import { useWhitelistedIps } from "./useWhitelistedIps";
import DialogComponent from "../../components/DialogComponent";
import IPsList from "./IPsList";

const WhitelistedIps = () => {
  const [selected, setSelected] = useState(null);
  const [updateOpen, setUpdateOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [addOpen, setAddOpen] = useState(false);

  const { whitelistedIps, data, updateWhitelistedIpsData } =
    useWhitelistedIps();

  const handleAdd = () => {
    const newData = {
      ...data,
      value: {
        ips: [...data.value.ips, selected],
      },
    };
    updateWhitelistedIpsData(newData);
    setAddOpen(false);
    setSelected(null);
  };

  const handleUpdate = () => {
    const newData = {
      ...data,
      value: {
        ips: data.value.ips.map((val, i) =>
          i === selected.index ? selected.value : val
        ),
      },
    };
    updateWhitelistedIpsData(newData);
    setUpdateOpen(false);
    setSelected(null);
  };

  const handleDelete = () => {
    const newData = {
      ...data,
      value: {
        ips: data.value.ips.filter((val, i) => i !== selected.index),
      },
    };
    updateWhitelistedIpsData(newData);
    setDeleteOpen(false);
  };

  return (
    <>
      <Stack
        direction="column"
        alignItems="flex-start"
        justifyContent="flex-start"
      >
        <Typography
          fontSize="23px"
          fontWeight={800}
          component="h2"
          data-testid="page-title"
        >
          Whitelisted IPs
        </Typography>
        <Typography
          fontSize="14px"
          fontWeight={500}
          component="p"
          data-testid="description"
          color={"#6E6E6E"}
        >
          Choose which IP addresses are allowed to access your account.
        </Typography>
      </Stack>

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing="15px"
        my="20px"
      >
        <Button
          className="btn-height"
          variant="contained"
          onClick={() => {
            setSelected(null);
            setAddOpen(true);
          }}
        >
          Add IP Address
        </Button>
      </Stack>

      <IPsList
        whitelistedIps={whitelistedIps}
        setUpdateOpen={setUpdateOpen}
        setDeleteOpen={setDeleteOpen}
        setSelected={setSelected}
      />

      <DialogComponent
        open={addOpen}
        handleClose={() => setAddOpen(false)}
        title="Add Whitelisted IP"
        children={
          <OutlinedInput
            placeholder="IP Address"
            sx={{ height: "40px" }}
            fullWidth
            value={selected || ""}
            onChange={(e) => {
              setSelected(e.target.value);
            }}
          />
        }
        actions={
          <DialogActions
            setOpen={setAddOpen}
            handleAction={handleAdd}
            actionLabel="Add"
          />
        }
      />

      <DialogComponent
        open={updateOpen}
        handleClose={() => setUpdateOpen(false)}
        title="Edit Whitelisted IP"
        children={
          <OutlinedInput
            placeholder="IP Address"
            sx={{ height: "40px" }}
            fullWidth
            value={selected ? selected.value : ""}
            onInput={(e) => {
              setSelected({
                ...selected,
                value: e.target.value,
              });
            }}
          />
        }
        actions={
          <DialogActions
            setOpen={setUpdateOpen}
            handleAction={handleUpdate}
            actionLabel="Update"
          />
        }
      />

      <DialogComponent
        open={deleteOpen}
        handleClose={() => setDeleteOpen(false)}
        title={"Are you sure you want to delete this IP?"}
        children={
          <Typography
            fontSize="14px"
            fontWeight={500}
            component="p"
            data-testid="description"
            color={"#6E6E6E"}
          >
            IP Address: {selected ? selected.value : ""}
          </Typography>
        }
        actions={
          <DialogActions
            setOpen={setDeleteOpen}
            handleAction={handleDelete}
            actionLabel="Delete"
            color="error"
          />
        }
      />
    </>
  );
};

export default WhitelistedIps;

const DialogActions = ({ setOpen, handleAction, actionLabel, color }) => (
  <>
    <Button variant="outlined" onClick={() => setOpen(false)}>
      Cancel
    </Button>
    <Button
      variant="contained"
      color={color || "primary"}
      onClick={() => handleAction()}
    >
      {actionLabel}
    </Button>
  </>
);
