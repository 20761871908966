import {
  Button,
  Grid,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  IconButton,
  TextField,
} from "@mui/material";
import Block from "../../components/Block";
import DataTable from "../../components/DataTable";
import ConfirmDialog from "../../components/ConfirmDialog";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";

import {
  getNoteCustomer,
  postCustomerNote,
  updateNoteCustomer,
  deleteNoteCustomer,
} from "../../distflowAPI/customersApi";

import { useEffect, useState } from "react";
import { parseISO, format } from "date-fns";
import { useParams } from "react-router-dom";

export default function CustomerNotes() {
  const { id } = useParams();
  const columns = [
    { headerName: "Notes", field: "note", minWidth: 400, sortable: true },
    {
      headerName: "Creation Date",
      field: "created_at",
      sortable: true,
      valueGetter: (params) => {
        return format(parseISO(params.data.created_at), "dd MMMM yyyy");
      },
    },
    {
      headerName: "Update Date",
      field: "updated_at",
      sortable: true,
      valueGetter: (params) => {
        return format(parseISO(params.data.updated_at), "dd MMMM yyyy");
      },
    },
    { headerName: "Actions", cellRenderer: buttonsRenderer, maxWidth: 100 },
  ];

  const defaultColDef = {
    flex: 1,
    filter: false,
    floatingFilter: false,
  };
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [rowData, setRowData] = useState([]);
  function fetchNotes() {
    getNoteCustomer(id).then((res) => {
      setRowData(res.data.results);
    });
  }

  useEffect(() => {
    fetchNotes();
  }, []);
  function buttonsRenderer(params) {
    const rowData = params.data;
    const handleEditClick = () => {
      fetchNotes();
    };

    const handleDeleteClick = () => {
      setConfirmModalOpen(true);
    };

    function EditNoteModal() {
      const [open, setOpen] = useState(false);
      const [note, setNote] = useState(rowData.note);
      const id = rowData.id;
      const customer = rowData.customer;
      function handleClick() {
        updateNoteCustomer(id, note, customer)
          .then((res) => {
            fetchNotes();
          })
          .catch((error) => {
            console.error(error);
            setOpen(false);
          });
      }

      return (
        <>
          <IconButton onClick={() => setOpen(true)}>
            <EditOutlinedIcon />
          </IconButton>
          <Dialog
            open={open}
            onClose={() => setOpen(false)}
            style={{
              zIndex: "2000!impotant",
            }}
          >
            <DialogTitle>Edit Note</DialogTitle>
            <DialogContent>
              <TextField
                fullWidth
                multiline
                placeholder="Type the note..."
                variant="outlined"
                sx={{ width: "500px" }}
                value={note}
                onChange={(e) => setNote(e.target.value)}
              />
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                onClick={() => {
                  setOpen(false);
                  handleClick();
                  fetchNotes();
                }}
                color="success"
              >
                Confirm
              </Button>
              <Button variant="contained" onClick={() => setOpen(false)}>
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        </>
      );
    }

    return (
      <div>
        <ConfirmDialog
          title="Note Delete"
          children="Do you want to delete this Note ?"
          open={confirmModalOpen}
          setOpen={setConfirmModalOpen}
          onConfirm={() => {
            deleteNoteCustomer(rowData.id).then((res) => {
              fetchNotes();
            });
          }}
        />
        <EditNoteModal />
        <IconButton
          onClick={() => {
            handleDeleteClick();
          }}
        >
          <DeleteForeverOutlinedIcon />
        </IconButton>
      </div>
    );
  }

  function AddNoteModal(props) {
    const [open, setOpen] = useState(false);
    const [note, setNote] = useState("");
    const { id } = useParams();

    function handleClick() {
      postCustomerNote(id, note)
        .then((res) => {
          fetchNotes();
        })
        .catch((error) => {
          console.error(error);
          setOpen(false);
        });
    }

    return (
      <>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            type="submit"
            variant="contained"
            sx={{ pt: 1, pb: 1, mb: 1 }}
            onClick={() => setOpen(true)}
          >
            Create a Note
          </Button>
        </div>
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          style={{
            zIndex: "2000!impotant",
          }}
        >
          <DialogTitle>Add a New Note</DialogTitle>
          <DialogContent>
            <TextField
              fullWidth
              multiline
              placeholder="Type the note..."
              variant="outlined"
              sx={{ width: "500px" }}
              value={note}
              onChange={(e) => setNote(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              onClick={() => {
                setOpen(false);
                handleClick();
                fetchNotes();
              }}
              color="success"
            >
              Confirm
            </Button>
            <Button variant="contained" onClick={() => setOpen(false)}>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
  return (
    <>
      <Block>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <h3 style={{ fontWeight: 600 }}>Customer Notes</h3>
          <AddNoteModal style={{ marginBottom: "0" }} />
        </div>
        <DataTable
          columnDefs={columns}
          rowData={rowData}
          defaultColDef={defaultColDef}
          // gridOptions={gridOptions}
        />
      </Block>
    </>
  );
}
