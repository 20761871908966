import axios from "axios";
import { store } from "../redux/store";
import { signOut } from "../redux/module/user";
import { setError } from "../redux/module/errors";

//18.188.3.43
//3.133.159.145

//3.144.29.103 dev
//3.141.93.207 prod
export function getApiUrl() {
  return process.env.REACT_APP_RIAD_API_URL;
}

const api = axios.create({
  baseURL: getApiUrl(),
});

// TODO: move to helpers
export function getToken() {
  const token = localStorage.getItem("accessToken");
  return token;
}

api.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    // api.defaults.headers.common = { Authorization: getToken() };
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (Number(error.response.status) === 401) {
      store.dispatch(signOut());
    }
    // show error message
    if (error && Number(error?.response?.status) !== 401) {
      if (Number(error?.response?.status) === 407) {
        return error.response;
      }
      store.dispatch(setError(error.response.data.error));
      return Promise.reject(error);
    }

    return Promise.reject(error);
  }
);

api.defaults.headers.common.Authorization = getToken();

export default api;
