import api from "./api"
import { orderingToApi, filtersToApi } from "./common";
import ApiBase from "./apiBase";
import customersApi from "./customersApi";

const driversApi = new ApiBase("/routes2/drivers");

export default driversApi;

export const listDrivers = async () => {
    const result = await api.get('/api/routes/drivers/');
    return result.data;
}

export async function listDrivers2(pageSize=100,page=1, search=''){
    const params = `page_size=${pageSize}&page=${page}&search=${search}`;
    const result = await api.get(`/api/routes2/drivers/?${params}`);
    return result
}

export async function postDriver(payload){
    const result = await api.post('/api/routes2/drivers/', payload)
    return result
}

export async function patchDriver(id, payload){
    const result = await api.patch(`/api/routes2/drivers/${id}/`, payload)
    return result
}
export function deleteDriver(id) {
    return api.delete(`/api/routes2/drivers/${id}/`);
}