import { Button } from "@mui/material";
import { useState } from "react";
import Dropzone from "react-dropzone";
import useFile from "../hooks/useFile";

const FileDropzone = ({setImages}) => {

  const { isFileValid } = useFile()
  
  const handleDropChange = (acceptedFiles) => {
    const validFiles = acceptedFiles.filter(file => isFileValid(file))        
    const newFiles = validFiles.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    )
    setImages((prev) => [...prev, ...newFiles])
  };

  return (
    <Dropzone onDrop={(acceptedFiles) => handleDropChange(acceptedFiles)}>
      {({ getRootProps, getInputProps }) => (
        <section>
          <div {...getRootProps()} className="dropzone upload-zone dz-clickable">
            <input {...getInputProps()} />
              <div className="dz-message">
                <span className="dz-message-text">Drag and drop file</span>
                <span className="dz-message-or">or</span>
                <Button color="primary" variant="contained">
                  SELECT
                </Button>
              </div>
            </div>
        </section>
      )}
    </Dropzone>
  );
};

export default FileDropzone;
