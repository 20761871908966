import { useCallback, useEffect, useState, useRef } from "react";
import {
  Box,
  Button,
  Grid,
  Stack,
  Typography,
  FormGroup,
  FormControlLabel,
  TextField,
  Chip,
  CircularProgress,
  FormHelperText,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import {
  restrictToVerticalAxis,
  restrictToWindowEdges,
} from "@dnd-kit/modifiers";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Block from "../../components/Block";
import { Droppable } from "../../components/Droppable";
import LoadingButton from "../../components/LoadingButton";
import { SortableVendor } from "../addEditRoute/SortableVendor";
import { DraggableAccount } from "../../components/draggable/DraggableAccount";
import { SortableAccount } from "../addEditRoute/SortableAccount";
import AddRouteScheduleForm from "./AddRouteScheduleForm";
import { listVendors } from "../../distflowAPI/vendorsApi";
import useAppDispatch from "../../hooks/useAppDispatch";
import useAppSelector from "../../hooks/useAppSelector";
import {
  addAccount,
  addNoteToAccount,
  createRouteChange,
  fetchRouteAccounts,
  fetchRouteChangeToEdit,
  handleAccountsSort,
  removeAccount,
  removeSelectedDriver,
  resetState,
  setAccountSearchTerm,
  setDriverSearchTerm,
} from "../../redux/module/addRouteChanges";
import { fetchRoutes } from "../../redux/module/route";
import {
  fetchDrivers,
  setSelectedDriver,
} from "../../redux/module/addRouteChanges";
import { arrayToDictionary } from "../../utils";
import debounce from "lodash.debounce";
import Separator from "../../components/Separator";
import RouteSelectControl from "./RouteSelectControl";
import AccountNoteDialog from "../addEditRoute/AccountNoteDialog";
import ManagePickupsDialog from "../addEditRoute/ManagePickupsDialog";
import DraggableDriver from "../addEditRoute/DraggableDriver";
import AccountAndDriverDragOverlay from "../addEditRoute/AccountAndDriverDragOverlay";
import { DEBOUNCE_VALUE } from "../../utils/constants";

const AddEditRoute = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [activeDraggableItem, setActiveDraggableItem] = useState(null);

  const accounts = useAppSelector((state) => state.addRouteChanges.accounts);
  const allAccounts = useAppSelector(
    (state) => state.addRouteChanges.allAccounts,
  );
  const accountSearchTerm = useAppSelector(
    (state) => state.addRouteChanges.accountSearchTerm,
  );
  const createLoading = useAppSelector(
    (state) => state.addRouteChanges.createLoading,
  );
  const updateLoading = useAppSelector(
    (state) => state.addRouteChanges.updateLoading,
  );

  const vendor_rules = useAppSelector(
    (state) => state.addRouteChanges.vendor_rules,
  );

  const accounts_calc = useAppSelector(
    (state) => state.addRouteChanges.accounts_calc,
  );
  const errors = useAppSelector((state) => state.addRouteChanges.formErrors);

  const loading = useAppSelector((state) => state.addRouteChanges.loading);
  const isRouteSelected = useAppSelector(
    (state) => !!state.addRouteChanges.currentRoute,
  );

  const currentRoute = useAppSelector(
    (state) => state.addRouteChanges.currentRoute,
  );

  const saveLoading = createLoading || updateLoading;

  const [vendors, setVendors] = useState([]);
  const [searchVendorsKeyword, setSearchVendorsKeyword] = useState("");

  const [managePickupsVendorId, setManagePickupsVendorId] = useState();
  const [showManagePickups, setShowManagePickups] = useState(false);

  const [accountIdForNote, setAccountIdForNote] = useState();
  const accountIdForNoteRef = useRef(accountIdForNote);

  const [showAccountNoteDialog, setShowAccountNoteDialog] = useState(false);

  useEffect(() => {
    return () => {
      dispatch(resetState());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!id) {
      return;
    }
    dispatch(fetchRouteChangeToEdit(id));
  }, [id]);

  useEffect(() => {
    listVendors(100, 1, [], [], []).then((response) => {
      const vendors = response.results.map((x) => ({
        ...x,
        show: true,
        deleted: false,
      }));
      setVendors(vendors);
    });

    dispatch(fetchDrivers());
    dispatch(fetchRouteAccounts());
    dispatch(fetchRoutes({}));
  }, [dispatch]);

  useEffect(() => {
    if (vendors.length > 0 && vendor_rules.length > 0) {
      const newVendors = [...vendors];
      for (const vendor_rule of vendor_rules) {
        // only the changes that are permanent
        if (vendor_rule.schedule_change === null) {
          continue;
        }
        const v = newVendors.find((x) => x.id == vendor_rule.vendor.id);
        if (v) {
          v.type = vendor_rule.type;
          if (vendor_rule.type === "DISABLED") {
            v.disabled = true;
          } else {
            v.pickup_route = vendor_rule.pickup_route;
          }
        }
      }

      setVendors(newVendors);
    }
  }, [vendors.length, vendor_rules]);

  useEffect(() => {
    if (accounts_calc) {
      if (accounts_calc.length > 0 && allAccounts.length > 0) {
        for (const account of accounts_calc) {
          dispatch(
            addNoteToAccount({
              accountId: account.account,
              note: account.note,
            }),
          );
        }
      }
    }
  }, [accounts_calc]);

  const selectedDriverId = useAppSelector(
    (state) => state.addRouteChanges.selectedDriverId,
  );
  const allDrivers = useAppSelector(
    (state) => state.addRouteChanges.allDrivers,
  );
  const driverSearchTerm = useAppSelector(
    (state) => state.addRouteChanges.driverSearchTerm,
  );

  const selectedDriver = allDrivers.find(
    (d) => d.id === Number(selectedDriverId),
  );

  const saveRouteChanges = () => {
    const dict = arrayToDictionary(allAccounts, "id");
    const _accounts = accounts.map((x, index) => {
      const id = Number(x.split("-")[1]);

      return {
        account: id,
        note: dict[id]?.note ?? "",
        display_order: index + 1,
        type: "REGULAR",
      };
    });

    //return;
    const requestBody = {
      accounts: _accounts,
      vendor_rules: vendors
        .filter((x) => x.type)
        .map((vendor) => ({
          type: vendor.type,
          vendor: vendor.id,
          pickup_route: vendor.pickup_route ?? null,
        })),
    };

    dispatch(createRouteChange(requestBody))
      .unwrap()
      .then(() => navigate("/routes/routes-changes"));
  };

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  function handleDragStart(event) {
    const [over, id] = event.active.id.split("-");
    setActiveDraggableItem({ over, id: Number(id) });
  }

  function handleDragEnd(event) {
    const { over, active } = event;

    if (over && active.data.current.supports.includes(over.data.current.type)) {
      if (over.id === "driver") {
        dispatch(setSelectedDriver(active.id));
      }
      if (over.id === "account") {
        dispatch(addAccount(active.id));
      }

      setActiveDraggableItem(null);
    }
  }

  function sortVendor(event) {
    const { over, active } = event;
    if (active.id !== over.id) {
      setVendors((items) => {
        const oldItem = items.find((x) => x.id === active.id);
        const newItem = items.find((x) => x.id === over.id);
        const oldIndex = items.indexOf(oldItem);
        const newIndex = items.indexOf(newItem);

        return arrayMove(items, oldIndex, newIndex);
      });
    }
  }

  function sortAccount(event) {
    const { over, active } = event;
    dispatch(handleAccountsSort({ activeId: active.id, overId: over.id }));
  }

  const toggleVendor = (id, state) => {
    setVendors((prev) => {
      const vendor = prev.find((x) => x.id == id);
      if (vendor) {
        vendor.disabled = state;
        if (vendor.disabled) {
          vendor.type = "DISABLED";
        } else {
          delete vendor.type;
        }
      }

      return [...prev];
    });
  };

  const openManagePickupsDialog = (vendorId) => {
    setManagePickupsVendorId(vendorId);
    setShowManagePickups(true);
  };

  const closeManagePickupsDialog = (pickupRouteId) => {
    const vendor = vendors.find((x) => x.id == managePickupsVendorId);
    if (vendor) {
      setVendors((prev) => {
        const oldIndex = prev.indexOf(vendor);
        const newState = [...prev];
        const updatedVendor = {
          ...vendor,
          type: "PICKUP_ROUTE",
          pickup_route: pickupRouteId,
        };
        newState[oldIndex] = updatedVendor;
        return newState;
      });
    }

    setShowManagePickups(false);
  };

  const handleAccountSearchTermChange = useCallback(
    debounce(
      (e) => dispatch(setAccountSearchTerm(e.target.value)),
      DEBOUNCE_VALUE,
    ),
    [],
  );

  const openAddNoteDialog = (accountId) => {
    setAccountIdForNote(accountId);
    accountIdForNoteRef.current = accountId;
    setShowAccountNoteDialog(true);
  };

  const closeAddNoteDialog = (save = false, note) => {
    if (save) {
      dispatch(addNoteToAccount({ note, accountId: accountIdForNote }));
    }
    setShowAccountNoteDialog(false);
    setAccountIdForNote(null);
    accountIdForNoteRef.current = null;
  };

  return (
    <>
      <ManagePickupsDialog
        open={showManagePickups}
        close={closeManagePickupsDialog}
        name={
          managePickupsVendorId &&
          vendors?.find((x) => x.id == managePickupsVendorId)?.name
        }
        currentRouteId={currentRoute?.id}
      />

      <AccountNoteDialog
        open={showAccountNoteDialog}
        close={closeAddNoteDialog}
        name={
          accountIdForNoteRef.current
            ? allAccounts?.find((x) => x.id == accountIdForNoteRef.current)
                ?.name
            : ""
        }
        existingNote={
          accountIdForNoteRef.current
            ? allAccounts?.find((x) => x.id == accountIdForNoteRef.current)
                ?.note
            : ""
        }
      />
      {loading && (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Stack
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            spacing="15px"
          >
            <Typography>Please wait while loading route details</Typography>
            <CircularProgress />
          </Stack>
        </Box>
      )}

      {!loading && (
        <Box maxWidth="1525px" margin=" 30px auto 0">
          <Stack
            direction={{ xs: "column", sm: "row" }}
            alignItems="center"
            justifyContent="space-between"
            spacing="20px"
            mb={3}
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent={{ xs: "space-between", sm: "flex-srart" }}
              sx={{ width: { xs: "100%", sm: "initial" }, gap: "20px" }}
            >
              <Button
                onClick={() => navigate(-1)}
                variant="outlined"
                startIcon={<ArrowBackIcon />}
              >
                Back
              </Button>
              <Typography variant="pageTitle" noWrap component="p">
                {id ? "Edit" : "Add"} Route Change
              </Typography>
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
              flexWrap="wrap"
              sx={{
                gap: "11px",
                "@media(max-width: 480px)": {
                  width: "100%",
                  button: { width: "100%" },
                },
              }}
            >
              <Button variant="outlined" onClick={() => navigate(-1)}>
                Discard
              </Button>
              <LoadingButton
                color="primary"
                variant="contained"
                onClick={saveRouteChanges}
                loading={saveLoading}
                disabled={!isRouteSelected}
              >
                Save
              </LoadingButton>
            </Stack>
          </Stack>

          <DndContext onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
            <Grid container columnSpacing={{ xs: 1, sm: 3, xl: "50px" }}>
              <Grid
                item
                md={6}
                lg={7}
                sx={{
                  maxWidth: "858px",
                  width: "100%",
                  maxHeight: "700px",
                  overflowY: "auto",
                  marginBottom: "24px",
                }}
              >
                <Block>
                  <RouteSelectControl />
                  <Separator
                    sx={{ margin: { xs: "15px -16px", sm: "15px -24px" } }}
                  />
                  <FormGroup sx={{ marginBottom: "-7px" }}>
                    <FormControlLabel
                      label="Driver"
                      labelPlacement="start"
                      sx={{
                        margin: 0,
                        gap: "16px",
                        "& span": { fontWeight: 500 },
                        cursor: "auto",
                      }}
                      control={
                        <Droppable id="driver" type="driver">
                          <Box
                            sx={{
                              width: "100%",
                              textAlign: "center",
                              fontSize: "12px",
                              color: "#8E8E8E",
                              border: selectedDriverId
                                ? "none"
                                : "1px dashed #DBDFEA",
                              borderRadius: "5px",
                              padding: selectedDriverId ? 0 : "10px",
                            }}
                          >
                            {selectedDriverId ? (
                              <Stack direction="row" spacing={1}>
                                <Chip
                                  label={selectedDriver?.user.first_name}
                                  onDelete={() =>
                                    dispatch(removeSelectedDriver())
                                  }
                                />
                              </Stack>
                            ) : (
                              "Drag and drop from the right panel"
                            )}
                          </Box>
                          {errors.driver && (
                            <FormHelperText error>
                              {errors.driver}
                            </FormHelperText>
                          )}
                        </Droppable>
                      }
                    />
                  </FormGroup>
                </Block>
                <Block
                  sx={{
                    border: "1px #DBDFEA",
                    borderRadius: "5px",
                    marginBottom: 6,
                  }}
                >
                  <AddRouteScheduleForm />
                </Block>

                <Block
                  sx={{
                    border: "1px dashed #DBDFEA",
                    borderRadius: "5px",
                    marginBottom: "24px",
                    overflow: "hidden",
                  }}
                >
                  <FormGroup sx={{ padding: "16px 24px" }}>
                    <FormControlLabel
                      label="Vendors"
                      labelPlacement="start"
                      sx={{
                        justifyContent: "space-between",
                        margin: 0,
                        gap: "16px",
                        "& span": { fontWeight: 500 },
                      }}
                      control={
                        <TextField
                          margin="none"
                          data-testid="test-vendor-search-id"
                          placeholder="Search"
                          variant="outlined"
                          value={searchVendorsKeyword}
                          onChange={(e) =>
                            setSearchVendorsKeyword(e.target.value)
                          }
                        />
                      }
                    />
                  </FormGroup>
                  <DndContext
                    sensors={sensors}
                    collisionDetection={closestCenter}
                    onDragEnd={sortVendor}
                    modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}
                  >
                    <SortableContext
                      items={vendors}
                      strategy={verticalListSortingStrategy}
                    >
                      {vendors.map((vendor) => (
                        <SortableVendor
                          key={vendor.id}
                          id={vendor.id}
                          title={vendor.name}
                          subtitle={vendor.address}
                          disabled={vendor.disabled}
                          pickup_route={vendor.pickup_route}
                          toggleVendor={toggleVendor}
                          openManagePickupsDialog={openManagePickupsDialog}
                        />
                      ))}
                    </SortableContext>
                  </DndContext>
                </Block>

                <Droppable id="account" type="account">
                  <Block
                    sx={{
                      border: "1px dashed #DBDFEA",
                      borderRadius: "5px",
                      marginBottom: "24px",
                      overflow: "hidden",
                    }}
                  >
                    <FormGroup sx={{ padding: "16px 24px" }}>
                      <FormControlLabel
                        label="Accounts"
                        labelPlacement="start"
                        sx={{
                          justifyContent: "space-between",
                          margin: 0,
                          gap: "16px",
                          "& span": { fontWeight: 500 },
                        }}
                        control={
                          <TextField
                            margin="none"
                            data-testid="test-account-search-id"
                            placeholder="Search"
                            variant="outlined"
                          />
                        }
                      />
                    </FormGroup>
                    <DndContext
                      sensors={sensors}
                      collisionDetection={closestCenter}
                      onDragEnd={sortAccount}
                      modifiers={[
                        restrictToVerticalAxis,
                        restrictToWindowEdges,
                      ]}
                    >
                      <SortableContext
                        items={accounts}
                        strategy={verticalListSortingStrategy}
                      >
                        <Box>
                          {accounts.length == 0 && (
                            <Box
                              sx={{
                                textAlign: "center",
                                fontSize: "12px",
                                color: "#8E8E8E",
                                border: "1px dashed #DBDFEA",
                                borderRadius: "5px",
                                padding: "10px",
                                margin: "5px",
                              }}
                            >
                              Drag and drop from the right panel
                            </Box>
                          )}
                          {accounts?.map((acc) => {
                            const id = Number(acc.split("-")[1]);
                            const account =
                              allAccounts.find((x) => x.id === id) || {};
                            return (
                              <SortableAccount
                                key={acc}
                                id={acc}
                                title={account.name}
                                subtitle={account.address}
                                onClick={() => dispatch(removeAccount(acc))}
                                note={account.note}
                                openAddNoteDialog={() =>
                                  openAddNoteDialog(account.id)
                                }
                              />
                            );
                          })}
                          {errors.accounts && (
                            <FormHelperText error style={{ paddingInline: 8 }}>
                              {errors.accounts}
                            </FormHelperText>
                          )}
                        </Box>
                      </SortableContext>
                    </DndContext>
                  </Block>
                </Droppable>
              </Grid>

              <Grid
                item
                md={6}
                lg={5}
                sx={{ width: "100%", maxHeight: "700px", overflowY: "auto" }}
              >
                <Block>
                  <TextField
                    margin="none"
                    data-testid="test-driver-search-id"
                    value={driverSearchTerm}
                    onChange={(e) =>
                      dispatch(setDriverSearchTerm(e.target.value))
                    }
                    placeholder="Search Drivers"
                    fullWidth
                    variant="outlined"
                    sx={{ margin: "10px 0 20px" }}
                  />
                  <Stack direction="row" flexWrap="wrap" gap="14px">
                    {allDrivers
                      .filter((x) => x.id !== Number(selectedDriverId))
                      .map((driver) => (
                        <DraggableDriver
                          id={`driver-${driver.id}`}
                          key={`driver-${driver.id}`}
                          title={driver.user.first_name}
                        />
                      ))}
                  </Stack>

                  <AccountAndDriverDragOverlay
                    allDrivers={allDrivers}
                    allAccounts={allAccounts}
                    activeDraggableItem={activeDraggableItem}
                  />
                </Block>

                <Block>
                  <Stack
                    direction="row"
                    flexWrap={{ xs: "wrap", sm: "nowrap" }}
                    gap="14px"
                    margin="10px 0 20px"
                  >
                    <TextField
                      margin="none"
                      data-testid="test-accounts-search-id"
                      placeholder="Search Accounts"
                      fullWidth
                      variant="outlined"
                      defaultValue={accountSearchTerm}
                      onChange={handleAccountSearchTermChange}
                    />
                  </Stack>
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: { xs: "1fr", sm: "1fr 1fr" },
                      gap: "8px 22px",
                    }}
                  >
                    {allAccounts
                      .filter(
                        (item) =>
                          item.show &&
                          !accounts.find(
                            (x) => Number(x.split("-")[1]) === item.id,
                          ),
                      )
                      .map((acc) => (
                        <DraggableAccount
                          key={acc.id}
                          id={`account-${acc.id}`}
                          title={acc.name}
                          subtitle={acc.address}
                        />
                      ))}
                  </Box>
                </Block>
              </Grid>
            </Grid>
            <LoadingButton
              color="primary"
              variant="contained"
              sx={{ marginBottom: "30px" }}
              onClick={saveRouteChanges}
              loading={saveLoading}
              disabled={!isRouteSelected}
            >
              Save
            </LoadingButton>
          </DndContext>
        </Box>
      )}
    </>
  );
};

export default AddEditRoute;
