import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import DatePicker from "./DatePicker2";
import React, { useState } from "react";
import dayjs from "dayjs";
import useAppDispatch from "../hooks/useAppDispatch";
import { DATE_FORMAT } from "../utils/constants";
import ErrorPopupBoundary from "./ErrorPopupBoundary";
import { ErrorBoundary } from "react-error-boundary";

const UpdateOverrideRefactoredModal = ({ data, fetchOverrides, updateOverride, elementKey, ...props }) => {
  const dispatch = useAppDispatch();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [override, setOverride] = useState(data.override);
  const [startDate, setStartDate] = useState(data.start_date ? dayjs.utc(data.start_date) : dayjs(new Date()));
  const [endDate, setEndDate] = useState(data.end_date ? dayjs.utc(data.end_date) : null);
  const [note, setNote] = useState(data.note);

  async function handleSubmit(event) {
    setIsSubmitting(true);
    event.preventDefault();
    const payload = {
      override,
      start_date: startDate?.format(DATE_FORMAT) || null,
      end_date: endDate?.format(DATE_FORMAT) || null,
      note: note || null,
      [elementKey]: data[elementKey]
    };

    const resp = await dispatch(updateOverride({ id: data[elementKey], overrideId: data.id, payload }));

    if (resp.payload?.id) {
      await dispatch(fetchOverrides(data[elementKey]));
      props.onClose();
    }
    setIsSubmitting(false);
  }

  return (
    <Dialog {...props} maxWidth="sm" fullWidth>
      <ErrorBoundary fallback={<ErrorPopupBoundary onClose={props.onClose} />}>
        <DialogTitle sx={{ pb: 1 }}>Update {elementKey} upcharge</DialogTitle>
        <form id="overrideUpdateForm" onSubmit={handleSubmit}>
          <DialogContent sx={{ pt: 1, pb: 1 }}>
            <Stack direction="row" alignItems="flex-end" flexWrap="wrap" gap="17px" mb="20px">
              <Box>
                <Typography component="p" variant="label" mb="11px">
                  Upcharge
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="upcharge"
                  data-testid={`test-upcharge-id`}
                  label="Upcharge"
                  required
                  value={override}
                  type="number"
                  onChange={(e) => setOverride(e.target.value)} />
              </Box>
            </Stack>
            <Grid container columnSpacing={{ xs: 1, sm: 3, xl: "10px" }} margin="20px 0 0">
              <Grid item md={6} lg={6}>
                <Typography component="p" variant="label" mb="11px">
                  Start Date
                </Typography>
                <DatePicker label="Start date" controlledValue={startDate} setControlledValue={setStartDate} />
              </Grid>
              <Grid item md={6} lg={6}>
                <Typography component="p" variant="label" mb="11px" name="endDate">
                  End Date
                </Typography>
                <DatePicker controlledValue={endDate} setControlledValue={setEndDate} />
              </Grid>
            </Grid>
            <Grid container columnSpacing={{ xs: 1, sm: 3, xl: "10px" }} margin="20px 0 0">
              <Grid item md={12} lg={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="note"
                  label="Note"
                  value={note}
                  onChange={(e) => setNote(e.target.value)} type="text" />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="primary" type="submit" disabled={isSubmitting}>Update Override</Button>
            <Button onClick={() => props.onClose()} variant="contained" color="error">Cancel</Button>
          </DialogActions>
        </form>
      </ErrorBoundary>
    </Dialog>
  );
};

export default UpdateOverrideRefactoredModal;
