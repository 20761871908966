import React, { useState } from "react";
import { Button, Dialog, DialogContent, DialogTitle, MenuItem, Select, Stack } from "@mui/material";
import useAppSelector from "../../hooks/useAppSelector";
import ErrorPopupBoundary from "../../components/ErrorPopupBoundary";
import { ErrorBoundary } from "react-error-boundary";

const ManagePickupsDialog = ({ open, name, close, currentRouteId }) => {
  const routes = useAppSelector((state) => state.route.routes);
  const [pickupRouteId, setPickupRouteId] = useState(0);

  const changePickupRoute = (event) => {
    setPickupRouteId(event.target.value);
  };

  const closeWrapper = () => {
    if (pickupRouteId && pickupRouteId != 0) {
      close(pickupRouteId);
    } else {
      close();
    }
  };

  return (
    <Dialog
      open={open}
      disableEscapeKeyDown={false}
      closeAfterTransition={true}
      onClose={close}
    >
      <ErrorBoundary fallback={<ErrorPopupBoundary onClose={close} />}>
        <DialogTitle>Manage Pickup for {name}</DialogTitle>
        <DialogContent>
          <Stack direction="column" spacing="15px">
            <Select
              value={pickupRouteId}
              onChange={changePickupRoute}
              size="small"
              MenuProps={{
                style: {
                  maxHeight: 400,
                  zIndex: 1500
                }
              }}
            >
              <MenuItem key={0} value={0}>
                None
              </MenuItem>
              {routes &&
                routes
                  .filter(
                    (x) => !currentRouteId || x.id !== Number(currentRouteId)
                  )
                  .map((route) => (
                    <MenuItem key={route.id} value={route.id}>
                      {route.name}
                    </MenuItem>
                  ))}
            </Select>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
              flexWrap="wrap"
              sx={{
                gap: "11px",
                "@media(max-width: 480px)": {
                  width: "100%",
                  button: { width: "100%" }
                }
              }}
            >
              <Button variant="outlined" onClick={closeWrapper}>
                Discard
              </Button>
              <Button color="primary" variant="contained" onClick={closeWrapper}>
                Save
              </Button>
            </Stack>
          </Stack>
        </DialogContent>
      </ErrorBoundary>
    </Dialog>
  );
};

export default ManagePickupsDialog;
