import { Link } from "react-router-dom";
import useAppDispatch from "../../hooks/useAppDispatch";
import TableButtons from "../../components/TableComponents/TableButtons";
import { removeAccount } from "../../redux/module/account";
import { Stack, Typography } from "@mui/material";

const account_name = {
  headerName: "Account",
  field: "name",
  wrapText: true,
  autoHeight: true,
  sortable: true,
  comparator: () => {}, //disable default sorting
  filterParams: {
    filterOptions: ["contains"],
    suppressAndOrCondition: true,
  },
  filter: true,
  floatingFilter: true,
  valueGetter: (params) => {
    return `${params.data.name}`;
  },
  cellRendererSelector: (params) => {
    return {
      component: AccountNameRenderer,
    };
  },
  flex: 4,
  minWidth: 200,
};

const account_customer = {
  headerName: "Customer",
  field: "customer__name",
  wrapText: true,
  autoHeight: true,
  sortable: true,
  comparator: () => {}, //disable default sorting
  filterParams: {
    filterOptions: ["contains"],
    suppressAndOrCondition: true,
  },
  filter: true,
  floatingFilter: true,
  valueGetter: (params) => {
    return `${params.data.customer.name}`;
  },
  cellRendererSelector: (params) => {
    return {
      component: AccountCustomerRenderer,
    };
  },
  flex: 4,
  minWidth: 200,
};

const account_territory = {
  headerName: "Territory",
  colId: "territory__name",
  field: "territory.name",
  autoHeight: true,
  flex: 3,
  filterParams: {
    filterOptions: ["contains"],
    maxNumConditions: 1,
  },
  filter: true,
  floatingFilter: true,
  sortable: true,
  valueGetter: (params) => params.data.territory?.name,
  comparator: () => {}, //disable default sorting
  minWidth: 200,
};

const account_address = {
  headerName: "Address",
  field: "address",
  wrapText: true,
  autoHeight: true,
  flex: 5,
  filterParams: {
    filterOptions: ["contains"],
    maxNumConditions: 1,
  },
  filter: true,
  floatingFilter: true,
  sortable: true,
  comparator: () => {}, //disable default sorting
  minWidth: 300,
};

const account_invoice_net = {
  headerName: "Invoice net",
  field: "invoice.net",
  wrapText: true,
  autoHeight: true,
  flex: 5,
  filterParams: {
    filterOptions: ["contains"],
    maxNumConditions: 1,
  },
  filter: true,
  floatingFilter: true,
  sortable: true,
  comparator: () => {}, //disable default sorting
  minWidth: 140,
  maxWidth: 140,
};

const RenderAccountLink = (props) => {
  const dispatch = useAppDispatch();

  const handleDelete = async () => {
    await dispatch(removeAccount(props.data.id));
  };

  return (
    <TableButtons
      link={`/accounts/${props.data.id}`}
      handleDelete={handleDelete}
      deletePopupEntity="account"
      historyData={props.data.history || []}
    />
  );
};

const account_link = {
  field: "",
  sorting: false,
  sort: false,
  sortable: false,
  autoHeight: true,
  filter: false,
  cellRenderer: RenderAccountLink,
  flex: 2,
  minWidth: 120,
  maxWidth: 120,
};

function AccountNameRenderer(params) {
  return (
    <span className="my-renderer">
      <Link
        to={`/accounts/${params.data.id}`}
        style={{ textDecoration: "none" }}
      >
        {params.data.name}
      </Link>
    </span>
  );
}

function AccountCustomerRenderer(params) {
  return (
    <span className="my-renderer">
      <Link
        to={`/customers/dashboard/${params.data.customer.id}/personal`}
        style={{ textDecoration: "none" }}
      >
        {params.data.customer.name}
      </Link>
    </span>
  );
}
function AccountEmailsRenderer(params) {
  return (
    <span className="my-renderer">
      <Stack>
        {params.data.emails.map((item) => (
          <Typography>{item.email}</Typography>
        ))}
      </Stack>
    </span>
  );
}
function AccountPhonesRenderer(params) {
  return (
    <span className="my-renderer">
      <Stack>
        {params.data.phone_numbers.map((item) => (
          <Typography>{item.phone}</Typography>
        ))}
      </Stack>
    </span>
  );
}
const account_email = {
  headerName: "Emails",
  field: "email",
  wrapText: true,
  autoHeight: true,
  sortable: true,
  comparator: () => {}, //disable default sorting
  filterParams: {
    filterOptions: ["contains"],
    suppressAndOrCondition: true,
  },
  filter: true,
  floatingFilter: true,
  valueGetter: (params) =>
    params.data.emails.map((item) => item.email)?.join(",\n "),
  flex: 4,
  minWidth: 200,
  cellRenderer: AccountEmailsRenderer,
};

const phone_numbers = {
  headerName: "Phones",
  field: "phone_number",
  wrapText: true,
  autoHeight: true,
  sortable: false,
  comparator: () => {}, //disable default sorting
  filterParams: {
    filterOptions: ["contains"],
    suppressAndOrCondition: true,
  },
  filter: true,
  floatingFilter: true,
  valueGetter: (params) =>
    params.data.phone_numbers.map((item) => item.phone)?.join(",\n "),
  flex: 4,
  minWidth: 200,
  cellRenderer: AccountPhonesRenderer,
};
const customer_internal_id = {
  headerName: "Internal Id",
  field: "internal_id",
  wrapText: true,
  autoHeight: true,
  filterParams: {
    filterOptions: ["contains"],
    maxNumConditions: 1,
  },
  valueGetter: (params) =>
    params.data.internal_id || params.data.customer.internal_id,
  filter: true,
  floatingFilter: true,
  sortable: true,
  comparator: () => {}, //disable default sorting
  minWidth: 140,
  maxWidth: 120,
};

const account_routes = {
  headerName: "Routes",
  field: "route",
  wrapText: true,
  autoHeight: true,
  filterParams: {
    filterOptions: ["contains"],
    maxNumConditions: 1,
  },
  valueGetter: (params) =>
    params.data.routes.map(route=>route.name).join(', '),
  filter: true,
  floatingFilter: true,
  sortable: true,
  comparator: () => {}, //disable default sorting
  minWidth: 140,
  maxWidth: 120,
}

const accountTableColumns = [
  customer_internal_id,
  account_name,
  //account_customer,
  account_email,
  phone_numbers,
  //account_territory,
  account_address,
  // account_invoice_net,
  account_routes,
  account_link,

];

export default accountTableColumns;
