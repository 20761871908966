import React, { useState } from "react";
import { Button, Grid, Input, Stack, Typography } from "@mui/material";
import { EditRoad } from "@mui/icons-material";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import SelectRouteModal from "./SelectRouteModal";

const OrderRouteModalItem = ({ item, handleItemChange }) => {
  const [changeRoute, setChangeRoute] = useState(false);
  const [showSetNote, setShowSetNote] = useState(false);
  const toggleSetNote = () => {
    setShowSetNote(!showSetNote);
  };

  const getItemPrice = () => {
    if (
      item.item_details.break_into_pieces &&
      item.unit != item.item_details.sold_by
    ) {
      return item.item_details.price.price / item.item_details.sold_by_qty;
    }
    return item.item_details.price.price;
  };

  const handleSaveRoute = (newValue) => {
    handleItemChange(item.item, {
      route: { id: newValue.value, name: newValue.label },
      manual_route: true,
    });
  };

  return (
    <Stack
      sx={{
        padding: "10px 14px",
        borderRadius: "4px",
        backgroundColor: "#F5F5F5",
        marginTop: "8px",
      }}
    >
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={5}>
                <Typography
                  sx={{
                    fontSize: "13px",
                    fontWeight: "500",
                    maxWidth: "250px",
                    width: "100%",
                  }}
                >
                  {item.item_details.name} (
                  {item.unit || item.item_details.sold_by}
                  {item.unit && item.unit != item.item_details.sold_by && (
                    <small> broken to piece</small>
                  )}
                  <small>
                    {" "}
                    ${getItemPrice().toFixed(2)}/
                    {item.unit || item.item_details.sold_by}
                  </small>
                  )
                  {item.unit &&
                    item.unit != item.item_details.sold_by &&
                    item.qty < item.item_details.piece_min_qty && (
                      <small style={{ color: "red" }}>
                        {" "}
                        quantity should be gt than{" "}
                        {item.item_details.piece_min_qty}{" "}
                      </small>
                    )}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={7}>
                <Stack
                  direction={"row"}
                  gap={1}
                  alignItems="center"
                  justifyContent="flex-end"
                  sx={{
                    width: "100%",
                    "& .MuiFormControl-root": {
                      display: "flex",
                      justifyContent: "center",
                    },
                  }}
                >
                  <Stack direction="column" justifyContent="center" gap={1}>
                    {item.route && (
                      <Typography
                        sx={{
                          fontSize: "13px",
                          fontWeight: "500",
                        }}
                      >
                        {item.route.name}
                      </Typography>
                    )}
                    {!item.route && (
                      <Typography
                        sx={{
                          fontSize: "13px",
                          fontWeight: "500",
                          color: "red",
                        }}
                      >
                        No route
                      </Typography>
                    )}
                  </Stack>
                  <SelectRouteModal
                    item={item}
                    changeRoute={changeRoute}
                    setChangeRoute={setChangeRoute}
                    handleSaveRoute={handleSaveRoute}
                  />
                  <Button
                    sx={{
                      display: "flex",
                      flexShrink: 0,
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "4px",
                      fontSize: "12px",
                      minWidth: "30px",
                      width: "30px",
                      height: "30px !important",
                    }}
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => setChangeRoute(true)}
                  >
                    <EditRoad fontSize="small" />
                  </Button>
                  <Button
                    sx={{
                      display: "flex",
                      flexShrink: 0,
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "4px",
                      fontSize: "12px",
                      minWidth: "30px",
                      width: "30px",
                      height: "30px !important",
                    }}
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={toggleSetNote}
                  >
                    <NoteAddIcon fontSize="small" />
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row">
              <Typography fontSize={"11px"} marginRight={"4px"}>
                SKU: {item?.item_details?.sku}
              </Typography>
              {item?.vendor_details?.vendorName && (
                <Typography fontSize={"11px"}>
                  Vendor: {item?.vendor_details?.vendorName}
                </Typography>
              )}
              {item?.item_details?.vendor_details?.vendorName && (
                <Typography fontSize={"11px"}>
                  Vendor: {item?.item_details?.vendor_details?.vendorName}
                </Typography>
              )}
              {item.item_details.vendor_item_id && (
                <Typography fontSize={"11px"} marginLeft={"4px"}>
                  Vendor item #: {item?.item_details?.vendor_item_id}
                </Typography>
              )}
            </Stack>
          </Grid>
          <Grid item xs={12}>
            {item.notes && (
              <Typography fontSize={"12px"}>
                <b>Schedule Note:</b> {item.notes}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            {showSetNote && (
              <Input
                size="small"
                fullWidth
                placeholder="Add note"
                sx={{ fontSize: "14px" }}
                value={item?.note || ""}
                onChange={(e) =>
                  handleItemChange(item.item, {
                    note: e.target.value || (item?.note ?? ""),
                  })
                }
              />
            )}
            {!showSetNote && item.note && (
              <Typography sx={{ fontSize: "13px", fontWeight: "500" }}>
                {item.note}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default OrderRouteModalItem;
