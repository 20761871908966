import React, { useEffect, useState } from "react";
import DataTable from "../../components/DataTable";
import { Stack, Typography, Box, Button, TextField } from "@mui/material";
import { getNoPages } from "../../utils";
import {
  formatAgGridFilters,
  formatAgGridSorting,
} from "../../distflowAPI/common";
import DatePickerCustom2 from "../../components/DatePicker2";
import {
  getProductCSV,
  getVariantCSV,
  listCustomerVariantOrders,
  listCustomerVariantOrdersCSV,
  listCustomerVariantReturns,
  listCustomerVariantReturnsCSV,
} from "../../distflowAPI/products";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import { DATE_FORMAT_FRONT } from "../../utils/constants";

export default function CustomerOrdersHistory() {
  return <ProductOrdersHistory />;
}

const defaultColDef = {
  flex: 1,
  height: "30px",
  filter: false,
  floatingFilter: false,
};

const ProductOrdersHistory = ({ type, customerId }) => {
  const { id } = useParams();
  const [oType, setOType] = useState("ITEMS");
  const [rowData, setRowData] = useState([]);
  const [productOrdersHistoryLoading, setProductOrdersHistoryLoading] =
    useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [numberOfPages, setNumberOfPage] = useState(1);
  const [gridApi, setGridApi] = useState(null);
  const [selectedIds, setSelectedIds] = useState([]);
  const [idsString, setIdsString] = useState("");
  const [filterBy, setFilterBy] = useState({
    account: null,
    fulfillment_date__gte: null,
    fulfillment_date__lte: null,
    quantity__gte: null,
    quantity__lte: null,
    route__name: null,
    display_name: null,
    variant__sku: null,
  });
  const [orderBy, setOrderBy] = useState([
    {
      field: "order__fulfillment_date",
      sort: "desc",
    },
  ]);

  const onSelectionChanged = (event) => {
    setSelectedIds(event.api.getSelectedNodes());
  };
  useEffect(() => {
    if (selectedIds.length > 0) {
      setIdsString(
        selectedIds
          .map((row) => {
            return row.data.id;
          })
          .join(",")
      );
    }
  }, [selectedIds]);
  function handleDownload() {
    console.log("downloading...");
    const queryFilter = [];
    Object.keys(filterBy).map((fk) => {
      if (filterBy[fk]) {
        queryFilter.push({ column: fk, value: filterBy[fk] });
      }
    });
    console.log("type is; ", type);
    if (oType === "ITEMS") {
      console.log("ids string: ", idsString);
      listCustomerVariantOrdersCSV(
        id,
        pageSize,
        page,
        orderBy,
        queryFilter,
        idsString
      ).then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "variants-order-history.csv");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      });
    } else {
      listCustomerVariantReturnsCSV(
        id,
        pageSize,
        page,
        orderBy,
        queryFilter,
        idsString
      ).then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "variants-return-history.csv");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      });
    }
  }
  console.log("rows are", rowData);
  useEffect(() => {
    setProductOrdersHistoryLoading(true);
    const queryFilter = [];
    Object.keys(filterBy).map((fk) => {
      if (filterBy[fk]) {
        queryFilter.push({ column: fk, value: filterBy[fk] });
      }
    });

    if (oType == "ITEMS") {
      listCustomerVariantOrders(id, pageSize, page, orderBy, queryFilter)
        .then((res) => {
          setRowData(res.data.results);
          setProductOrdersHistoryLoading(false);
          setNumberOfPage(getNoPages(res.data.count, pageSize));
        })
        .finally(() => setProductOrdersHistoryLoading(false));
    } else {
      listCustomerVariantReturns(id, pageSize, page, orderBy, queryFilter)
        .then((res) => {
          setRowData(res.data.results);
          setProductOrdersHistoryLoading(false);
          setNumberOfPage(getNoPages(res.data.count, pageSize));
        })
        .finally(() => setProductOrdersHistoryLoading(false));
    }
  }, [type, id, page, pageSize, filterBy, orderBy, oType]);
  function handleGetSelectedRows() {
    if (!gridApi) return;
    const selectedRows = gridApi.getSelectedRows();
    setSelectedIds(
      selectedRows.map((row) => {
        return row.id;
      })
    );
  }

  const ProductHistoryTableCols = getCols({ type });
  return (
    <div
      className="variant-table orders-history table-pagination-padding-right"
      style={{
        paddingBottom: "12px",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Stack>
          <Stack direction="row" spacing={1} paddingLeft={2}>
            <Button
              variant="contained"
              color={oType == "ITEMS" ? "success" : "primary"}
              onClick={() => setOType("ITEMS")}
            >
              Items
            </Button>
            <Button
              variant="contained"
              color={oType == "RETURNS" ? "success" : "primary"}
              onClick={() => setOType("RETURNS")}
            >
              Returns
            </Button>
          </Stack>
          <Stack direction={"row"} spacing={1}>
            <DataFilter filterBy={filterBy} setFilterBy={setFilterBy} />
            <Stack paddingTop={2}>
              <Button
                //sx={{ mr: "10px" }}
                variant="contained"
                onClick={() => handleDownload()}
              >
                Download
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Box>
      {!productOrdersHistoryLoading && rowData.length == 0 ? (
        <Typography marginLeft={3}>This Product has no orders</Typography>
      ) : (
        <DataTable
          rowData={rowData}
          loading={productOrdersHistoryLoading}
          columnDefs={ProductHistoryTableCols}
          defaultColDef={defaultColDef}
          rowHeight={40}
          rowSelection="multiple"
          page={page}
          pageSize={pageSize}
          setPageSize={(pageSize) => setPageSize(pageSize)}
          noPages={numberOfPages}
          setPage={(page) => setPage(page)}
          onSortChanged={(event) =>
            setOrderBy(
              formatAgGridSorting(event.columnApi.columnModel.gridColumns)
            )
          }
          onFilterChanged={(event) =>
            setFilterBy(formatAgGridFilters(event.api.getFilterModel()) || [])
          }
          onGridReady={(params) => {
            setGridApi(params.api);
          }}
          onSelectionChanged={onSelectionChanged}
        />
      )}
    </div>
  );
};

function DataFilter({ filterBy, setFilterBy }) {
  return (
    <Stack direction={"row"} padding={2} spacing={2}>
      <DatePickerCustom2
        label={"From"}
        controlledValue={
          filterBy.fulfillment_date__gte
            ? dayjs(filterBy.fulfillment_date__gte)
            : null
        }
        setControlledValue={(v) =>
          setFilterBy({
            ...filterBy,
            fulfillment_date__gte: v ? v.format("YYYY-MM-DD") : null,
          })
        }
      />
      <DatePickerCustom2
        label={"To"}
        controlledValue={
          filterBy.fulfillment_date__lte
            ? dayjs(filterBy.fulfillment_date__lte)
            : null
        }
        setControlledValue={(v) =>
          setFilterBy({
            ...filterBy,
            fulfillment_date__lte: v ? v.format("YYYY-MM-DD") : null,
          })
        }
      />
      <TextField
        label={"Account"}
        value={filterBy.account}
        onChange={(e) => setFilterBy({ ...filterBy, account: e.target.value })}
      />
      <TextField
        label={"Route"}
        value={filterBy.route__name}
        onChange={(e) =>
          setFilterBy({ ...filterBy, route__name: e.target.value })
        }
      />
      <TextField
        label={"Item"}
        value={filterBy.display_name}
        onChange={(e) =>
          setFilterBy({ ...filterBy, display_name: e.target.value })
        }
      />
      <TextField
        label={"SKU"}
        value={filterBy.variant__sku}
        onChange={(e) =>
          setFilterBy({ ...filterBy, variant__sku: e.target.value })
        }
      />
    </Stack>
  );
}

function getCols(extraParams) {
  return [
    {
      headerName: "Order",
      valueGetter: (params) => {
        return `#${params.data?.invoice_id || params.data?.po_id}`;
      },
      maxWidth: 120,
      // cellRenderer: OrdersRenderer,
      filter: false,
      sortable: true,
      checkboxSelection: true,
      cellRenderer: (params) => (
        <Typography
          onClick={() =>
            (window.location.href = `/invoices/invoices/${params.data?.invoice_id}`)
          }
        >
          {params.data?.po_id
            ? `PO#${params.data?.po_id}`
            : params.data?.invoice_id}
        </Typography>
      ),
    },
    {
      headerName: "Fulfillmnet Date",
      field: "order__fulfillment_date",
      valueGetter: (params) => {
        return dayjs
          .utc(params.data?.order?.fulfillment_date)
          .format(DATE_FORMAT_FRONT);
      },
      maxWidth: 140,
      minWidth: 140,
      filter: false,
      sortable: true,
      comparator: () => {},
    },
    {
      headerName: "Account",
      field: "order__account__name",
      valueGetter: (params) => {
        return params.data.account_name;
      },
      minWidth: 220,
      filter: false,
      sortable: true,
      comparator: () => {},
    },
    {
      headerName: "SKU",
      field: "variant__sku",
      valueGetter: (params) => {
        return params.data.sku;
      },
      maxWidth: 100,
      minWidth: 90,
      filter: false,
      sortable: true,
      comparator: () => {},
    },
    {
      headerName: "Desc",
      field: "display_name",
      maxWidth: 280,
      minWidth: 140,
      filter: false,
      sortable: true,
      comparator: () => {},
    },
    {
      headerName: "U.P",
      field: "unit_price",
      maxWidth: 100,
      filter: false,
      sortable: true,
      valueGetter: (params) => {
        return `$${params.data.unit_price}`;
      },
      comparator: () => {},
    },
    {
      headerName: "Total P.",
      field: "line_price",
      maxWidth: 90,
      filter: false,
      sortable: true,
      valueGetter: (params) => {
        return `$${params.data.line_price}`;
      },
      comparator: () => {},
    },
    {
      headerName: "QTY",
      field: "quantity",
      maxWidth: 90,
      filter: false,
      sortable: true,
      comparator: () => {},
    },
    {
      headerName: "Billable",
      field: "delivered_qty",
      maxWidth: 90,
      filter: false,
      sortable: true,
      comparator: () => {},
    },

    {
      headerName: "Route",
      field: "route.name",
      maxWidth: 100,
      filter: false,
      sortable: true,
      comparator: () => {},
    },
  ];
}
