import React, { useEffect, useState } from "react";
import { Alert, Box, Button, Stack, Typography } from "@mui/material";
import "swiper/css";
import "swiper/css/navigation";
import CalendarSwitcher from "../../../components/CalendarSwitcher";
import { useSearchParams } from "react-router-dom";
import CalendarSwiper from "../../../components/CalendarSwiper";
import dayjs from "dayjs";
import {
  emailRouteReports,
  routesReports,
} from "../../../distflowAPI/routesTwo";
import { useModal } from "mui-modal-provider";
import Report from "./report";
import { DATE_FORMAT, DATE_FORMAT_FRONT } from "../../../utils/constants";
import Spinner from "../../../components/Spinner";
import { fireErrorNotification } from "../../../utils";
import { downloadFile } from "../../../distflowAPI/downloadFile";

function getTomorrowsDate() {
  let tom = dayjs.utc().add(1, "days");
  if (tom.format("dd").toLocaleLowerCase() == "sa") {
    return (tom = dayjs.utc().add(2, "days").format(DATE_FORMAT));
  }
  return tom.format(DATE_FORMAT);
}
const RoutesReportsTwo = () => {
  const { showModal } = useModal();
  const [searchParams, setSearchParams] = useSearchParams();
  const [date, setDate] = useState(
    searchParams.get("date") || getTomorrowsDate()
  );
  const [dateType, setDateType] = useState("day");
  const [reports, setReports] = useState([]);
  const [hasFetched, setHasFetched] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const changeDateType = (event, newAlignment) => {
    if (newAlignment !== null) {
      setDateType(newAlignment);
    }
  };

  useEffect(() => {
    setSearchParams({ ...searchParams, date: date });
    refreshTable();
  }, [date]);

  async function refreshTable() {
    setHasFetched(false);
    setIsLoading(true);
    await routesReports(date)
      .then(
        (res) => {
          setReports(res.data.results);
          setHasFetched(true);
        },
        (error) => {
          setReports([]);
          if (error.response.data.includes("DOCTYPE")) {
            fireErrorNotification(`Internal Server Error, reload the page`);
          } else {
            const errorFields = Object.keys(error.response.data);
            errorFields.forEach((field) => {
              fireErrorNotification(
                `${field}: ${error.response.data[field][0]}`
              );
            });
          }
        }
      )
      .finally(() => {
        setIsLoading(false);
      });
  }

  {
    /*<Report item={item} date={date} refreshTable={refreshTable} />*/
  }
  return (
    <Box maxWidth="1522px" margin="0 auto">
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        mb="25px"
      >
        <Typography
          fontSize="23px"
          fontWeight={800}
          component="h2"
          data-testid="page-title"
        >
          Routes report
        </Typography>
        <Stack direction={"row"} spacing={2}>
          <Button
            variant={"outlined"}
            onClick={() => {
              reports.map((report) => {
                downloadFile(
                  `/api/routes2/export_route_report/?date=${report.date}&route_id=${report.route.id}`,
                  `${report.route.name} ${dayjs
                    .utc(report.date)
                    .format(DATE_FORMAT_FRONT)}.pdf`
                );
              });
            }}
          >
            Print Routes Report
          </Button>
          <Button
            variant={"outlined"}
            onClick={() =>
              downloadFile(
                `/api/orders/orders_routes_export/?date=${date}`,
                "report.xlsx"
              )
            }
          >
            Download Report
          </Button>
          <Button
            variant={"outlined"}
            onClick={() =>
              emailRouteReports(date).then(
                (res) => alert("Emailing started"),
                (err) => alert("Error occurred")
              )
            }
          >
            Email Route reports
          </Button>
          <CalendarSwitcher dateType={dateType} onChange={changeDateType} />
        </Stack>{" "}
      </Stack>

      <CalendarSwiper
        date={date}
        setDate={setDate}
        dateType={dateType}
        searchParams={searchParams}
      />

      <Box
        sx={{
          display: "flex",
          position: "relative",
        }}
      >
        {isLoading && (
          <Box
            height="100%"
            display="flex"
            justifyContent="center"
            sx={{
              position: "absolute",
              backgroundColor: "#f6f6f79e",
              width: "calc(100% - 24px)",
              zIndex: 2,
            }}
          >
            <Box
              sx={{
                position: "fixed",
                top: "50%",
                right: "50%",
              }}
            >
              <Spinner size="lg" />
            </Box>
          </Box>
        )}

        {reports.length == 0 && hasFetched && (
          <Alert severity="warning">No confirmed orders</Alert>
        )}

        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr",
            gap: "15px",
            "@media (max-width: 1500px)": { gridTemplateColumns: "1fr 1fr" },
            "@media (max-width: 860px)": { gridTemplateColumns: "1fr" },
          }}
        >
          {reports.map((item) => (
            <Report report={item} date={date} refreshTable={refreshTable} />
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default RoutesReportsTwo;
