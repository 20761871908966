import { ListItemButton, ListItemText } from "@mui/material";
import { NavLink } from "react-router-dom";

const LinkButton = ({ title, to, onClick, testid }) => {
  return (
    <ListItemButton
      to={to}
      component={NavLink}
      onClick={onClick}
      data-testid={testid}
      sx={{
        position: "relative",
        color: "#8091A7",
        "&.active span": {
          color: "#3FC6A5",
        },
        "&.active span::before": {
          content: '""',
          height: "26px",
          width: 8,
          position: "absolute",
          top: "50%",
          left: 0,
          transform: "translateY(-50%)",
          backgroundColor: "#3FC6A5",
          borderRadius: "0px 5px 5px 0px",
        },
      }}
    >
      <ListItemText
        primary={title}
        sx={{
          "& span": {
            fontSize: "15px",
            fontWeight: 600,
            letterSpacing: "0.16px",
            paddingLeft: "36px",
          },
        }}
      />
    </ListItemButton>
  );
};

export default LinkButton;
