import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import getCols from "./managersTableColumns";
import DataTable from "../../components/DataTable";
import {
  formatAgGridFilters,
  formatAgGridSorting,
} from "../../distflowAPI/common";
import { Box, Button, Stack, Typography } from "@mui/material";
import { listManagers } from "../../distflowAPI/managersApi";
import AddCustomerManagerForm from "./add";

const defaultColDef = {
  flex: 1,
  filter: false,
  floatingFilter: false,
};

function Managers() {
  const { id } = useParams();
  const [managers, setManagers] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [orderBy, setOrderBy] = useState([]);
  const [filterBy, setFilterBy] = useState([]);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    updateTable();
  };

  function updateTable() {
    listManagers(page, pageSize, filterBy, orderBy).then((res) => {
      setManagers(res.data.results);
      //setPageSize(parseInt(res.data.count / pageSize) + 1);
    });
  }
  useEffect(() => {
    updateTable();
  }, [filterBy, orderBy]);
  useEffect(() => {
    updateTable();
  }, []);
  const handleChange = (field, value) => {};
  const managersTableColumns = getCols({ refreshTable: updateTable });
  return (
    <>
      <Typography
        fontSize="23px"
        fontWeight={800}
        component="h2"
        data-testid="page-title"
      >
        Account Managers
      </Typography>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        alignItems={{ xs: "space-between", sm: "center" }}
        justifyContent="space-between"
        spacing={1.5}
        my="25px"
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          spacing="15px"
        >
          <Button onClick={handleOpen} variant="contained">
            Add New
          </Button>
        </Stack>
      </Stack>
      <Box className="table-wrapper">
        <DataTable
          rowData={managers}
          columnDefs={managersTableColumns}
          defaultColDef={defaultColDef}
          suppressSorting={true} //disable default sorting
          rowHeight={53}
          headerHeight={60}
          loading={dataLoading}
          onSortChanged={(event) =>
            handleChange(
              "orderBy",
              setOrderBy(
                formatAgGridSorting(event.columnApi.columnModel.gridColumns),
              ),
            )
          }
          onFilterChanged={(event) =>
            handleChange(
              "filterBy",
              setFilterBy(
                formatAgGridFilters(event.api.getFilterModel()) || [],
              ),
            )
          }
          page={page}
          pageSize={pageSize}
          setPageSize={(pageSize) => handleChange("pageSize", pageSize)}
          noPages={numberOfPages}
          setPage={(page) => handleChange("page", page)}
        />
      </Box>
      <AddCustomerManagerForm open={open} handleClose={handleClose} />
    </>
  );
}
export default Managers;
