import api from "./api";

export async function listSettings() {
  const result = await api.get("/api/settings/");
  return result;
}
export async function getSettings(id) {
  const result = await api.get(`/api/settings/${id}/`);
  return result;
}

export async function createSettings(payload) {
  const result = await api.post("/api/settings/", payload);
  return result;
}

export async function updateSettings(id, payload) {
  const result = await api.patch(`/api/settings/${id}/`, payload);
  return result;
}

export async function listCpAlerts() {
  const result = await api.get("/api/public_alerts/");
  return result;
}
export async function patchCpAlert(id, payload) {
  const result = await api.patch(`/api/public_alerts/${id}/`, payload);
  return result;
}
export async function createCpAlert(payload) {
  const result = await api.post(`/api/public_alerts/`, payload);
  return result;
}
export async function deleteCpAlert(id) {
  const result = await api.delete(`/api/public_alerts/${id}/`);
  return result;
}
