import React from "react";
import AsyncAutocompleteSelect from "./AsyncAutocompleteSelect";
import categoriesApi from "../../distflowAPI/categoriesApi";
import accountsApi from "../../distflowAPI/accountsApi";
import { listDrivers2 } from "../../distflowAPI/driversApi";

/**
 * @param {Omit<
 *  import("./AsyncAutocompleteSelect").AsyncAutocompleteSelectProps<
 *    import("../../redux/module/category").Category
 *  >,
 *  'fetchOptions'
 * >} props
 */
const DriverSelect = (props) => (
  <AsyncAutocompleteSelect
    fetchOptions={(searchTerm) =>
      listDrivers2(100, 1, searchTerm)
        .then((res) =>
          res.data.results.map((item) => ({
            value: item.id,
            label: `${item.user.first_name} ${item.user.last_name}`,
          }))
        )
    }
    {...props}
  />
);

export default DriverSelect;
