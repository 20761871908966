import { Link } from "react-router-dom";
import useAppDispatch from "../../hooks/useAppDispatch";
import TableButtons from "../../components/TableComponents/TableButtons";
import { deleteGroup } from "../../redux/module/group";
import { useState } from "react";
import AddGroupFrom from "./AddGroupFrom";

function GroupCustomersCountRenderer(params) {
  return (
    <span className="my-renderer">
      <Link
        to={`/customers/?groups=${params.data.name}`}
        style={{ textDecoration: "none" }}
      >
        {params.data.products_count}
      </Link>
    </span>
  );
}

function GroupLinkRenderer(props) {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleDelete = async () => {
    await dispatch(deleteGroup(props.data.id));
  };
  const handleEdit = () => {
    handleOpen()
  }
  return (
    <>
      <AddGroupFrom open={open} handleClose={handleClose} group={props.data}/>
      <TableButtons
        handleEdit={handleEdit}
        handleDelete={handleDelete}
        deletePopupEntity="group"
        historyData={props.data.history || []}
      />
    </>
    
  );
}

const group_name = {
  headerName: "Type",
  field: "name",
  wrapText: true,
  autoHeight: true,
  sortable: true,
  comparator: () => {}, //disable default sorting
  filterParams: {
    filterOptions: ["contains"],
    suppressAndOrCondition: true,
  },
  filter: true,
  floatingFilter: true,
  valueGetter: (params) => `${params.data.name}`,
  flex: 4,
  minWidth: 300,
};

export const group_customers_count = {
  headerName: "Accounts",
  field: "accounts_count",
  filter: false,
  sortable: false,
  comparator: () => {}, //disable default sorting
  autoHeight: true,
  cellRenderer: GroupCustomersCountRenderer,
  flex: 2,
  minWidth: 200,
};

const empty = {
  field: "",
  filter: false,
  floatingFilter: false,
  flex: 5,
};

const group_link = {
  field: "",
  sorting: false,
  sort: false,
  sortable: false,
  autoHeight: true,
  filter: false,
  cellRenderer: GroupLinkRenderer,
  flex: 1,
  minWidth: 120,
  maxWidth: 150,
};

const groupTableColumns = [group_name, group_customers_count, empty, group_link];

export default groupTableColumns;
