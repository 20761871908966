import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getRouteReportById,
  getRouteReports,
  createRouteReport as createRouteReportApi,
  updateRouteReportStatus as updateRouteReportStatusApi,
  createRouteReportPickupVendor as createRouteReportPickupVendorApi,
  updateRouteReportPickupStatus as updateRouteReportPickupStatusApi,
  createRouteReportPickupItem as createRouteReportPickupItemApi,
  updateRouteReportPickupItem as updateRouteReportPickupItemApi,
} from "../../distflowAPI/routeReportsApi";

import { fireErrorNotification, fireInfoNotification } from "../../utils";

/**
 * @typedef {object} RouteReport
 * @property {object[]} data
 * @property {string} data.calculation_date
 * @property {object} data.route
 * @property {number} data.route.id
 * @property {string} data.route.name
 * @property {boolean} data.route.is_active
 * @property {boolean} data.route.is_sunday
 * @property {boolean} data.route.is_monday
 * @property {boolean} data.route.is_tuesday
 * @property {boolean} data.route.is_wednesday
 * @property {boolean} data.route.is_thursday
 * @property {boolean} data.route.is_friday
 * @property {boolean} data.route.is_saturday
 * @property {number} data.route.driver
 * @property {object[]} data.route.vendors_calc
 * @property {number} data.route.vendors_calc.id
 * @property {string} data.route.vendors_calc.name
 * @property {string} data.route.vendors_calc.address
 * @property {number} data.route.vendors_calc.products_count
 * @property {null|string} data.route.vendors_calc.internal_id
 * @property {object} data.route.vendors_calc.upcharge
 * @property {number} data.route.vendors_calc.upcharge.value
 * @property {null} data.route.vendors_calc.upcharge.id
 * @property {string|null} data.route.vendors_calc.latitude
 * @property {string|null} data.route.vendors_calc.longitude
 * @property {object[]} data.route.accounts_calc
 * @property {number} data.route.accounts_calc.id
 * @property {number} data.route.accounts_calc.display_order
 * @property {string} data.route.accounts_calc.type
 * @property {string} data.route.accounts_calc.note
 * @property {number} data.route.accounts_calc.route
 * @property {number} data.route.accounts_calc.account
 * @property {null} data.route.accounts_calc.schedule_change
 * @property {object} data.route.route_calc
 * @property {number} data.route.route_calc.id
 * @property {string} data.route.route_calc.name
 * @property {boolean} data.route.route_calc.is_active
 * @property {boolean} data.route.route_calc.is_sunday
 * @property {boolean} data.route.route_calc.is_monday
 * @property {boolean} data.route.route_calc.is_tuesday
 * @property {boolean} data.route.route_calc.is_wednesday
 * @property {boolean} data.route.route_calc.is_thursday
 * @property {boolean} data.route.route_calc.is_friday
 * @property {boolean} data.route.route_calc.is_saturday
 * @property {number} data.route.route_calc.driver
 * @property {object[]} data.route.route_calc.vendor_rules
 * @property {number} data.route.route_calc.vendor_rules.id
 * @property {object} data.route.route_calc.vendor_rules.vendor
 * @property {number} data.route.route_calc.vendor_rules.vendor.id
 * @property {string} data.route.route_calc.vendor_rules.vendor.name
 * @property {string} data.route.route_calc.vendor_rules.vendor.address
 * @property {number} data.route.route_calc.vendor_rules.vendor.products_count
 * @property {null} data.route.route_calc.vendor_rules.vendor.internal_id
 * @property {object} data.route.route_calc.vendor_rules.vendor.upcharge
 * @property {number} data.route.route_calc.vendor_rules.vendor.upcharge.value
 * @property {null} data.route.route_calc.vendor_rules.vendor.upcharge.id
 * @property {null|string} data.route.route_calc.vendor_rules.vendor.latitude
 * @property {null|string} data.route.route_calc.vendor_rules.vendor.longitude
 * @property {null|number} data.route.route_calc.vendor_rules.pickup_route
 * @property {string} data.route.route_calc.vendor_rules.type
 * @property {null|number} data.route.route_calc.vendor_rules.schedule_change
 * @property {object[]} data.route.route_calc.accounts
 * @property {number} data.route.route_calc.accounts.id
 * @property {object} data.route.route_calc.accounts.account
 * @property {number} data.route.route_calc.accounts.account.id
 * @property {string} data.route.route_calc.accounts.account.name
 * @property {number} data.route.route_calc.accounts.account.territory
 * @property {string} data.route.route_calc.accounts.account.created_at
 * @property {string} data.route.route_calc.accounts.account.updated_at
 * @property {number} data.route.route_calc.accounts.account.customer
 * @property {string} data.route.route_calc.accounts.account.address
 * @property {object} data.route.route_calc.accounts.account.upcharge
 * @property {number} data.route.route_calc.accounts.account.upcharge.value
 * @property {null} data.route.route_calc.accounts.account.upcharge.id
 * @property {number} data.route.route_calc.accounts.display_order
 * @property {string} data.route.route_calc.accounts.type
 * @property {null|number} data.route.route_calc.accounts.schedule_change
 * @property {string} data.route.route_calc.note
 * @property {object[]} data.route.vendor_rules
 * @property {number} data.route.vendor_rules.id
 * @property {string} data.route.vendor_rules.type
 * @property {number} data.route.vendor_rules.route
 * @property {number} data.route.vendor_rules.vendor
 * @property {null|number} data.route.vendor_rules.pickup_route
 * @property {null|number} data.route.vendor_rules.schedule_change
 * @property {string} data.route.note
 * @property {object[]} data.route.orders_calc
 * @property {number} data.route.orders_calc.id
 * @property {number} data.route.orders_calc.account_id
 * @property {number[]} data.route.orders_calc.vendors
 * @property {number} data.route_id
 * @property {number} data.total_deliveries
 * @property {number} data.total_delivered
 * @property {number} data.total_pickups
 * @property {number} data.total_picked_up
 * @property {null} data.route_report
 * @property {object[]} data.order_items
 * @property {number} data.order_items.id
 * @property {number} data.order_items.account_id
 * @property {number} data.order_items.items__variant__product__vendor
 * @property {number} data.order_items.items__variant
 * @property {number} data.order_items.items__quantity
 * @property {number} data.order_items.items__variant__product
 * @property {object[]} missing_orders
 * @property {number} missing_orders.id
 * @property {number} missing_orders.account_id
 * @property {number[]} missing_orders.vendors
 */

/**
 * @type {{
 *  reports: Array<RouteReport>;
 *  accountMap: {};
 *  loading: boolean;
 *  showMissingOrders: boolean;
 *  hasMissingOrders: boolean;
 *  showErrorMessage: boolean;
 *  errorMessage: string;
 *  selectedReport: RouteReport,
 *  selectedReportPickups: Array,
 *  selectedReportDeliveries: Array,
 *  pickupRoutesInfo: Array
 * }}
 */
const initialState = {
  reports: [],
  selectedReport: {},
  loading: false,
  showMissingOrders: true,
  hasMissingOrders: false,

  // only for error message purposes
  accountMap: {},
  errorMessage: "",
  showErrorMessage: false,

  selectedReportPickups: [],
  selectedReportDeliveries: [],

  pickupRoutesInfo: [],
};

export const fetchRouteReports = createAsyncThunk(
  "routeReport/fetchRouteReports",
  async (arg, { dispatch, getState, rejectWithValue }) => {
    const { date, showWarningOfMissingOrders } = arg;
    const account = getState().account;

    dispatch(setAccounts(account.accountMap));
    dispatch(setShowMissingOrderWarning(showWarningOfMissingOrders));

    try {
      const response = await getRouteReports(date);
      return { data: response.data, missing_orders: response.missing_orders };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const fetchRouteReportById = createAsyncThunk(
  "routeReports/fetchRouteReportById",
  async (arg) => {
    const { date, routeId } = arg;
    const { data } = await getRouteReportById(routeId, date);
    return { data };
  },
);

export const fetchCurrentSelectedRouteReport = createAsyncThunk(
  "routeReport/fetchCurrentSelectedRouteReport",
  (_arg, { dispatch, getState }) => {
    const reducerState = getState().routeReports;
    if (reducerState.selectedReport) {
      dispatch(
        fetchRouteReportById({
          routeId: reducerState.selectedReport.route.id,
          date: reducerState.selectedReport.calculation_date,
        }),
      );
      // dispatch(calculateSelectedReportPickupItems());
    }
  },
);

export const createRouteReport = createAsyncThunk(
  "routeReport/createRouteReport",
  async (arg) => {
    const { state, routeId, date } = arg;
    const { data } = await createRouteReportApi(state, routeId, date);
    return { data };
  },
);

export const updateRouteReportStatus = createAsyncThunk(
  "routeReport/updateRouteReportStatus",
  async (arg, { getState, rejectWithValue }) => {
    const { id, state, date } = arg;
    try {
      const { data } = await updateRouteReportStatusApi(id, state, date);
      return { data };
    } catch (err) {
      return rejectWithValue(err.data.data);
    }
  },
);

export const createRouteReportPickupVendor = createAsyncThunk(
  "routeReport/createRouteReportPickupVendor",
  async (arg, { getState, rejectWithValue }) => {
    const { vendor, state, pickedUpTimestamp } = arg;
    const reducerState = getState().routeReports;
    const route_report = reducerState.selectedReport.route_report;

    if (route_report) {
      const { data } = await createRouteReportPickupVendorApi(
        route_report.id,
        vendor,
        state,
        pickedUpTimestamp,
      );
      return { data };
    }

    return rejectWithValue({ error: "The current route is not started yet" });
  },
);

export const updateRouteReportPickupStatus = createAsyncThunk(
  "routeReport/updateRouteReportPickupStatus",
  async (arg) => {
    const { id, state, pickedUpTimestamp } = arg;
    const { data } = await updateRouteReportPickupStatusApi(
      id,
      state,
      pickedUpTimestamp,
    );
    return { data };
  },
);

export const createRouteReportPickupItem = createAsyncThunk(
  "routeReport/createRouteReportPickupItem",
  async (arg, { getState, rejectWithValue }) => {
    const reducerState = getState().routeReports;
    const route_report = reducerState.selectedReport.route_report;

    const { selectedReport } = reducerState;

    if (!route_report) {
      return rejectWithValue({ error: "The current route is not started yet" });
    }

    try {
      const { accountId, variantId, amount, vendorId, note, unit } = arg;
      const { data } = await createRouteReportPickupItemApi(
        variantId,
        amount,
        vendorId,
        route_report.id,
        note,
        unit,
        accountId,
        selectedReport.calculation_date,
      );
      return { data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const updateRouteReportPickupItemAmount = createAsyncThunk(
  "routeReport/updateRouteReportPickupItemAmount",
  async (arg, { getState, rejectWithValue }) => {
    const { id, amount, note } = arg;
    const { selectedReport } = getState().routeReports;

    try {
      const { data } = await updateRouteReportPickupItemApi(
        id,
        amount,
        note,
        selectedReport.calculation_date,
      );
      return { data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

const routeReportsSlice = createSlice({
  name: "routeReports",
  initialState,
  reducers: {
    setAccounts: (state, action) => {
      state.accountMap = action.payload;
    },
    setShowMissingOrderWarning: (state, action) => {
      state.showMissingOrders = action.payload;
    },
    setShowErrorMessage: (state, action) => {
      state.showErrorMessage = action.payload;
    },
    setSelectedReport: (state, action) => {
      const routeReport = action.payload;
      state.selectedReport = routeReport;
    },
    setSelectedReportPickupItems: (state, { payload }) => {
      state.selectedReportPickups = payload;
    },
    calculateSelectedReportPickupItems: (state) => {
      const routeReport = state.selectedReport;
      const orders = routeReport.route.orders_calc;
      const vendors_calc = routeReport.route.vendors_calc;

      const pickups = routeReport?.route_report?.pickups ?? [];

      const pickupsList = [...pickups];

      const orderVendors = orders.map((x) => x.vendors);
      const flattened = new Set(orderVendors.concat.apply([], orderVendors));

      for (let pickupVendor of flattened) {
        if (pickupsList.find((x) => x.vendor == pickupVendor)) {
          continue;
        }

        const item = vendors_calc.find((x) => x.id == pickupVendor);
        if (item) {
          if (item.pickup_route) {
            pickupsList.push({
              vendor: pickupVendor,
              state: "INVALID",
              pickup_route: item.pickup_route,
            });
          } else {
            pickupsList.push({ vendor: pickupVendor, state: "INVALID" });
          }
        }
      }

      state.selectedReportPickups = pickupsList;
    },
    calculateSelectedReportDeliveryItems: (state, { payload }) => {
      const report = state.selectedReport;

      const deliveries =
        report.route_report?.deliveries?.filter(
          (x) => x.state == "DELIVERED",
        ) ?? [];

      const orders = report.route.orders_calc;
      const deliveriesList = [...deliveries];
      const accounts = orders.map((x) => x.account_id);

      for (let account of accounts) {
        if (deliveriesList.find((x) => x.account == account)) {
          continue;
        }

        deliveriesList.push({ account, state: "-1" });
      }

      state.selectedReportDeliveries = deliveriesList;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRouteReports.pending, (state) => {
        state.reports = [];
        state.selectedReport = {};
        state.loading = true;
      })
      .addCase(fetchRouteReports.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.hasMissingOrders = false;

        const { data, missing_orders } = payload.data;

        if (!data) {
          fireInfoNotification("No available routes for the selected day.");
          return;
        }

        state.reports = data;
        if (missing_orders && missing_orders.length > 0) {
          state.hasMissingOrders = true;
        }

        if (missing_orders && state.showMissingOrders) {
          const missing_accounts = Array.from(
            new Set(missing_orders.map((x) => x.account_id)),
          );
          const accountNames = missing_accounts.map(
            (x) => state.accountMap[x]?.name,
          );
          state.errorMessage = `The following accounts ${accountNames.join(
            ", ",
          )} do not have a serving route.`;
          state.showErrorMessage = true;
        }
      })
      .addCase(fetchRouteReports.rejected, (state, { payload }) => {
        state.loading = false;
        if (payload && payload.error) {
          fireErrorNotification(payload.error);
        }
      })
      .addCase(fetchRouteReportById.fulfilled, (state, { payload }) => {
        const newRouteReports = [...state.reports];
        const { data } = payload.data;
        // make sure there's only one row
        const routeReport = data[0];
        const existingRouteReportIndex = newRouteReports.findIndex(
          (x) => x.route_id == routeReport.route_id,
        );

        if (existingRouteReportIndex != -1) {
          newRouteReports[existingRouteReportIndex] = routeReport;
          state.reports = newRouteReports;
          // set selected Route Report if fetched by id
          state.selectedReport = routeReport;
        }

        // const routeReport = state.selectedReport;
        const orders = routeReport.route.orders_calc;

        const pickups = routeReport.route_report?.pickups ?? [];

        const pickupsList = [...pickups];

        const orderVendors = orders.map((x) => x.vendors);
        const flattened = new Set(orderVendors.concat.apply([], orderVendors));

        for (let pickupVendor of flattened) {
          if (pickupsList.find((x) => x.vendor == pickupVendor)) {
            continue;
          }

          pickupsList.push({ vendor: pickupVendor, state: "INVALID" });
        }

        state.selectedReportPickups = pickupsList;
      })
      .addCase(createRouteReport.fulfilled, (state) => {})
      .addCase(updateRouteReportStatus.fulfilled, (state, { payload }) => {
        const routeReports = [...state.reports];
        const updatedReportStatus = payload.data;

        const index = routeReports.findIndex(
          (x) => x.route.id == updatedReportStatus.route,
        );

        if (index != -1) {
          const updatedRouteReportStatus = {
            ...routeReports[index].route_report,
            ...updatedReportStatus,
          };
          routeReports[index].route_report = updatedRouteReportStatus;

          state.reports = routeReports;
        }
      })
      .addCase(createRouteReportPickupVendor.pending, (state) => {})
      .addCase(createRouteReportPickupVendor.fulfilled, (state) => {})
      .addCase(createRouteReportPickupVendor.rejected, (state, { payload }) => {
        fireErrorNotification(payload.error);
      })
      .addCase(
        updateRouteReportPickupItemAmount.fulfilled,
        (state, { payload }) => {},
      )
      .addCase(
        updateRouteReportPickupItemAmount.rejected,
        (state, { payload }) => {},
      );
  },
});

export const {
  setSelectedReport,
  setSelectedReportPickupItems,
  calculateSelectedReportPickupItems,
  calculateSelectedReportDeliveryItems,
  setShowMissingOrderWarning,
  setAccounts,
  setShowErrorMessage,
} = routeReportsSlice.actions;

export default routeReportsSlice.reducer;
