import React from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import CustomTextField from "../CustomTextField";
import { useState } from "react";
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import HighlightOffTwoToneIcon from "@mui/icons-material/HighlightOffTwoTone";
import CheckCircleTwoToneIcon from "@mui/icons-material/CheckCircleTwoTone";
import AddCircleTwoToneIcon from "@mui/icons-material/AddCircleTwoTone";
import VerifiedUserTwoToneIcon from "@mui/icons-material/VerifiedUserTwoTone";
import AddBoxTwoToneIcon from "@mui/icons-material/AddBoxTwoTone";

const emailValidator = (email) => {
  const re = /\S+@\S+\.\S+/;
  return re.test(email);
};

const MultipleEmailsForm = ({
  emails = [],
  updateEmail,
  deleteEmail,
  addEmail,
}) => {
  const [defaultEmail, setDefaultEmail] = useState("");
  const [editingIndex, setEditingIndex] = useState(null);
  const [is_addEmail, setIsAddEmail] = useState(false);
  const [newEmail, setNewEmail] = useState("");

  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState("");

  const validateEmail = (email) => {
    if (email === "") {
      return { isValid: false, error: "Email is required" };
    }

    if (!emailValidator(email)) {
      return { isValid: false, error: "Invalid email" };
    }

    if (emails.some((e) => e.email === email)) {
      return { isValid: false, error: "Email already exists" };
    }

    return { isValid: true };
  };

  const addNewEmail = async () => {
    const validation = validateEmail(newEmail);

    if (!validation.isValid) {
      setError(true);
      setHelperText(validation.error);
      return;
    }

    await addEmail(newEmail, () => {
      setNewEmail("");
      setIsAddEmail(false);
      setError(false);
      setHelperText("");
    });
  };

  const updateEmail_ = async (index, email) => {
    const validation = validateEmail(email);

    if (!validation.isValid) {
      setError(true);
      setHelperText(validation.error);
      return;
    }

    await updateEmail(emails[index].id, email, () => {
      setEditingIndex(null);
      setError(false);
      setHelperText("");
    });
  };

  return (
    <>
      <Box sx={{ mt: 2, display: "flex", flexWrap: "wrap" }}>
        {emails.length > 0 ? (
          emails.map((email, index) => (
            <Box
              key={index}
              sx={{
                width: "fit-content",
                gap: 1,
                backgroundColor: "#f0f0f0",
                borderRadius: 1,
                p: 1,
                mt: 0.5,
                mr: 0.5,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {editingIndex === index ? (
                <Stack
                  direction="row"
                  spacing={0.4}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <input
                    type="email"
                    value={defaultEmail}
                    placeholder="Email"
                    style={{
                      width: "100%",
                      border: "none",
                      outline: "none",
                      backgroundColor: "transparent",
                      borderBottom: "1px solid #000",
                    }}
                    onChange={(e) => setDefaultEmail(e.target.value)}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        updateEmail_(index, defaultEmail);
                      }
                    }}
                  />
                  <CheckCircleTwoToneIcon
                    style={{
                      cursor: "pointer",
                    }}
                    fontSize="small"
                    onClick={() => updateEmail_(index, defaultEmail)}
                  />
                  <HighlightOffTwoToneIcon
                    style={{
                      cursor: "pointer",
                      color: "red",
                    }}
                    fontSize="small"
                    onClick={() => {
                      setDefaultEmail(email.email);
                      setEditingIndex(null);
                    }}
                  />
                </Stack>
              ) : (
                <Typography>{email.email}</Typography>
              )}

              {editingIndex === index ? null : (
                <Stack direction="row" spacing={0.4}>
                  <EditTwoToneIcon
                    fontSize="small"
                    style={{ cursor: "pointer", color: "#0E46A3" }}
                    onClick={() => {
                      setDefaultEmail(email.email);
                      setEditingIndex(index);
                    }}
                  />
                  <HighlightOffTwoToneIcon
                    style={{
                      cursor: "pointer",
                      color: "red",
                    }}
                    fontSize="small"
                    onClick={() => deleteEmail(email.id, email.email)}
                  />

                  {email.is_default && (
                    <VerifiedUserTwoToneIcon
                      style={{
                        cursor: "pointer",
                        color: "green",
                      }}
                      fontSize="small"
                    />
                  )}
                </Stack>
              )}
            </Box>
          ))
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100%",
            }}
          >
            <Typography>No email found</Typography>
          </Box>
        )}
      </Box>
      {
        <Box
          sx={{
            display: "flex",
            gap: 1,
            mt: 1,
            alignItems: "center",
            justifyContent: "end",
          }}
        >
          {is_addEmail ? (
            <Stack
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: 1,
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  mt: 1,
                }}
              >
                <CustomTextField
                  sx={{ mt: 0 }}
                  label="Email"
                  type="email"
                  value={newEmail}
                  onChange={(e) => setNewEmail(e.target.value)}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      addNewEmail();
                    }
                  }}
                />
                <Button variant="outlined" onClick={addNewEmail}>
                  Add
                </Button>
              </Box>

              {error && (
                <Typography
                  sx={{
                    color: "red",
                    fontSize: "0.7rem",
                    mt: 0.5,
                  }}
                >
                  {helperText}
                </Typography>
              )}
            </Stack>
          ) : (
            <Button
              variant="outlined"
              sx={{
                mt: 2,
              }}
              startIcon={<AddCircleTwoToneIcon />}
              onClick={() => setIsAddEmail(true)}
            >
              Add Email
            </Button>
          )}
        </Box>
      }
    </>
  );
};

export default MultipleEmailsForm;
