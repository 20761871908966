import { createAction, createSlice } from "@reduxjs/toolkit";

const initialState = {
  error: null,
};

export const setError = createAction("error/set");

export const errorSlice = createSlice({
  name: "error",
  initialState,
  reducers: {
    clearError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setError, (state, action) => {
      state.error = action.payload;
    });
  },
});

export default errorSlice.reducer;
