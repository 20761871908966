import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  TextField,
  Box,
  Stack,
  Button,
  Grid,
} from "@mui/material";
import { useState, useEffect } from "react";
import AccountSelect from "../../components/AsyncSelects/AccountSelect";
import CloseIcon from "@mui/icons-material/Close";
import AmountSetter from "../../components/AmountSetter";
import DatePickerCustom2 from "../../components/DatePicker2";
import dayjs from "dayjs";
export default function CreateCredit({ open, setOpen }) {
  const [account, setAccount] = useState();
  const [note, setNote] = useState("");
  const [amount, setAmount] = useState(0);
  const [date, setDate] = useState(dayjs());
  const handleClick = () => {};

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      maxWidth="xl"
      sx={{
        "& .MuiPaper-root": {
          width: "100%",
          maxWidth: "800px",
        },
      }}
    >
      <DialogTitle>
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
          }}
        >
          <CloseIcon
            sx={{ cursor: "pointer" }}
            onClick={() => {
              setOpen(false);
            }}
          />
        </div>
        <Typography
          variant="pageTitle"
          noWrap
          textAlign="center"
          component="p"
          mb="20px"
        >
          Create a Credit
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={"20px"} sx={{ paddingTop: "20px" }}>
          <Grid item md={6}>
            <AccountSelect
              name="account"
              value={account}
              fullWidth
              label="Account"
              onChange={(event, newValue) => {
                setAccount(null);
                if (newValue) {
                  if (newValue.value) {
                    setAccount(newValue);
                  }
                }
              }}
            />
          </Grid>
          <Grid item md={3}>
            <DatePickerCustom2
              controlledValue={date}
              setControlledValue={setDate}
              label={"date"}
            />
          </Grid>
          <Grid item md={3}>
            <AmountSetter value={amount} setValue={setAmount} />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Note"
              onChange={(e) => {
                setNote(e.target.value);
              }}
              value={note}
              required
              fullWidth
              multiline
            ></TextField>
          </Grid>
        </Grid>
      </DialogContent>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "10px",
          marginBottom: "20px",
        }}
      >
        <Button variant="contained" color="primary" onClick={() => handleClick}>
          Confirm
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={() => setOpen(false)}
        >
          Cancel
        </Button>
      </Box>
    </Dialog>
  );
}
