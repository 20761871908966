import { Button, IconButton, Typography } from "@mui/material";
import { ReactComponent as Edit } from "../../images/edit.svg";

const NoteRenderer = (params) => {
  return (
    <>
      {params.value ? (
        <Typography sx={{ display: "flex", alignItems: "flex-end" }}>
          <Typography
            component="span"
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: "2",
              WebkitBoxOrient: "vertical",
            }}
          >
            {params.value}
          </Typography>
          <IconButton sx={{ minWidth: "auto !important" }}>
            <Edit width={12} height={12} />
          </IconButton>
        </Typography>
      ) : (
        <Button
          size="small"
          variant="gray"
          endIcon={<Edit width={12} height={12} />}
          sx={{
            bgcolor: "#fff",
            fontSize: "14px !important",
            border: "1px solid #DBDFEA !important",
            padding: "0 9px !important",
          }}
        >
          Add note
        </Button>
      )}
    </>
  );
};

const routeChangesPageTableColumns = [
  {
    headerName: "Type",
    field: "type",
    maxWidth: 80,
    minWidth: 80,
    flex: 1,
  },
  {
    headerName: "Change",
    field: "change",
    minWidth: 150,
    wrapText: true,
    autoHeight: true,
    flex: 1,
  },
  {
    headerName: "Original",
    field: "original",
    minWidth: 120,
    flex: 1,
  },
  {
    headerName: "changed by",
    field: "changedBy",
    minWidth: 160,
    flex: 1,
  },
  {
    headerName: "Note",
    field: "note",
    flex: 1,
    minWidth: 180,
    wrapText: true,
    autoHeight: true,
    cellRenderer: NoteRenderer,
  },
];

export default routeChangesPageTableColumns;
