import React, { useEffect, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import "./styles.css";
import { DEBOUNCE_VALUE } from "../../utils/constants";
// TODO: think about storing state

/**
 * @template T
 * @typedef {{
 *  label: string;
 *  helperText?: string;
 *  error?: boolean;
 *  required?: boolean;
 *  extraOptions?: Array<T>;
 *  fetchOptions: (searchTerm: string) => Promise<{
 *    value: string | number;
 *    label: string;
 *  }>
 * } & import("@mui/material").AutocompleteProps<T>} AsyncAutocompleteSelectProps
 */
/**
 * @template T
 * @param {AsyncAutocompleteSelectProps<T>} props
 */
const AsyncAutocompleteSelect = ({
  label,
  required = false,
  helperText,
  error = false,
  extraOptions = [],
  extraStyles,
  fetchOptions,
  setTagSearch,
  ...props
}) => {
  const [options, setOptions] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      fetchOptions(searchTerm).then(setOptions);
    }, DEBOUNCE_VALUE);

    return () => {
      clearTimeout(debounceTimer);
    };
  }, [searchTerm]);

  const handleInputChange = (event, newInputValue) => {
    if (setTagSearch) {
      setTagSearch(newInputValue);
    }
    setSearchTerm(newInputValue);
  };

  const filterOptions = (options, { inputValue }) => {
    try {
      return options.filter((option) => {
        if (props?.value?.length) {
          return !props.value.some(
            (selectedOption) => selectedOption.value === option.value
          );
        } else {
          return option?.value !== props?.value?.value;
        }
      });
    } catch (e) {
      return true;
    }
  };

  return (
    <Autocomplete
      options={[...extraOptions, ...options].filter(Boolean)}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          required={required}
          helperText={helperText}
          error={error}
          placeholder={props.placeholder}
          data-testid={`test-${props.name || label}-select-id`}
        />
      )}
      fullWidth
      autoComplete
      filterOptions={filterOptions}
      classes={{ popper: "autocomplete-popper" }}
      onInputChange={handleInputChange}
      sx={{
        ...extraStyles,
        "& .MuiInputBase-root": {
          padding: "0 65px 0 9px",
        },
        "& .MuiInputBase-input": {
          padding: "8.5px 4px 8.5px 5px !important",
        },
        "& .MuiFormControl-root": {
          minHeight: "40px !important",
        },
      }}
      {...props}
    />
  );
};

export default AsyncAutocompleteSelect;
