import DatePicker from "../../components/DatePicker2";
import CustomerSelect from "../../components/AsyncSelects/CustomerSelect";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
import { generateStatement } from "../../distflowAPI/invoicesApi";
import AccountSelect from "../../components/AsyncSelects/AccountSelect";

export default function GenerateStatements({ updateStatementTable }) {
  const [open, setOpen] = useState(false);
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [customerSearch, setCustomerSearch] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [selectedAccounts, setSelectedAccounts] = useState([]);
  const [accountSearch, setAccountSearch] = useState();

  function generate() {
    const customersCall = selectedCustomers.map((customer) =>
      generateStatement(
        startDate?.isValid() ? startDate?.format("YYYY-MM-DD") : null,
        endDate?.isValid() ? endDate?.format("YYYY-MM-DD") : null,
        customer.value,
        null
      )
    );
    const accountsCall = selectedAccounts.map((account) =>
      generateStatement(
        startDate?.isValid() ? startDate?.format("YYYY-MM-DD") : null,
        endDate?.isValid() ? endDate?.format("YYYY-MM-DD") : null,
        null,
        account.value
      )
    );

    Promise.all([...accountsCall, ...customersCall])
      .then((res) => {
        setOpen(false);
        updateStatementTable();
      })
      .catch((error) => {
        console.error("Error generating statements:", error);
      });
  }

  return (
    <>
      <Button
        variant="contained"
        onClick={() => {
          setOpen(true);
        }}
        color="success"
      >
        Generate Statements
      </Button>

      <Dialog open={open} onClose={() => setOpen(false)}>
        <CloseIcon
          onClick={() => {
            setOpen(false);
          }}
          sx={{
            mb: 0,
            ml: "auto",
            mt: "5px",
            mr: "5px",
            caretColor: "transparent",
            cursor: "pointer",
          }}
        />
        <h2 style={{ textAlign: "center", marginTop: "0" }}>
          Generate Statements
        </h2>
        <DialogContent>
          <Typography sx={{ mt: "10px", mb: "10px", ml: "5px" }}>
            Specify Account(s)/Customer(s):
          </Typography>
          <AccountSelect
            sx={{ mt: "15px" }}
            multiple
            label="Accounts"
            id="multiple-limit-tags"
            value={selectedAccounts}
            onChange={(_event, newValue) => {
              setSelectedAccounts(newValue);
            }}
            setAccountSearch={setAccountSearch}
          />
          <CustomerSelect
            sx={{ mt: "15px" }}
            multiple
            label="Customer"
            id="multiple-limit-tags"
            value={selectedCustomers}
            onChange={(_event, newValue) => {
              setSelectedCustomers(newValue);
            }}
            setCustomerSearch={setCustomerSearch}
          />
          <Typography sx={{ mt: "40px", mb: "20px", ml: "5px" }}>
            Pick a period:
          </Typography>
          <div style={{ display: "flex", gap: "10px" }}>
            <DatePicker
              controlledValue={startDate}
              setControlledValue={setStartDate}
              label={"Starting Date"}
            />
            <DatePicker
              controlledValue={endDate}
              setControlledValue={setEndDate}
              label={"Ending Date"}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={generate}
            sx={{ m: "20px auto" }}
            variant="contained"
            color="success"
          >
            Generate Statements
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
