export const orderItemStatusMap = {
  APPROVED: {
    key: "APPROVED",
    name: "Approved",
    colorHex: "",
    colorText: "success",
  },
  CANCELED: {
    key: "CANCELED",
    name: "Cancelled",
    colorHex: "",
    colorText: "error",
  },
  PENDING: {
    key: "PENDING",
    name: "Pending",
    colorHex: "",
    colorText: "secondary",
  },
  PICKED_UP: {
    key: "PICKED_UP",
    name: "Picked up",
    colorHex: "",
    colorText: "success",
  },
};

export const paymentStatus = {
  PENDING: "Pending",
  PAID: "Paid",
  PARTIALLY_PAID: "Partially paid",
  UNPAID: "Unpaid",
};

export const fulfillmentStatus = {
  AUTOMATIC: "Automatic",
  PENDING: "Pending",
  PICKED_UP: "Picked up",
  DELIVERED: "Delivered",
  CANCELED: "Canceled",
  CUSTOMER_EDIT: "EDITED - CP",
  CUSTOMER_PENDING: "CREATED - CP",
  CUSTOMER_CANCEL: "CANCELLED - CP",
  APPROVED: "Approved",
};
