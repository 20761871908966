import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Block from "../../../components/Block";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useModal } from "mui-modal-provider";
import CloseIcon from "@mui/icons-material/Close";
import {
  compatibleRoutesToPickFor,
  createRoutePickUp,
  deleteRoutePickUp,
} from "../../../distflowAPI/routesTwo";
import { fireErrorNotification } from "../../../utils";
import ErrorPopupBoundary from "../../../components/ErrorPopupBoundary";
import { ErrorBoundary } from "react-error-boundary";

function itemPriority(items) {
  const result = items.map((item, index) => {
    return { ...item, priority: index };
  });
  return result;
}

const Item = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  margin: theme.spacing(1),
}));

function Rdnd({
  vendors,
  routeData,
  setRouteData,
  vendorSearch,
  setVendorSearch,
  getRouteFunction,
}) {
  const { showModal } = useModal();
  const [items, setItems] = useState({
    vendors: vendors.filter(
      (vendor) =>
        !routeData.route_vendors.map((rv) => rv.id).includes(vendor.id),
    ),
    route_vendors: routeData.route_vendors,
  });
  useEffect(() => {
    setItems({
      vendors: vendors.filter(
        (vendor) =>
          !routeData.route_vendors.map((rv) => rv.id).includes(vendor.id),
      ),
      route_vendors: routeData.route_vendors,
    });
  }, [vendors, routeData]);
  const onDragEnd = (result) => {
    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const start = items[source.droppableId];
    const finish = items[destination.droppableId];

    if (start === finish) {
      // gotta reorder
      const newItems = Array.from(start);
      const [removed] = newItems.splice(source.index, 1);
      newItems.splice(destination.index, 0, removed);
      if (source.droppableId == "route_vendors") {
        setRouteData({ ...routeData, route_vendors: itemPriority(newItems) });
      }

      //setItems(newState);
      return;
    }

    // Moving from one list to another
    const startItems = Array.from(start);
    const [removed] = startItems.splice(source.index, 1);
    const newStart = { ...items, [source.droppableId]: startItems };

    const finishItems = Array.from(finish);
    finishItems.splice(destination.index, 0, removed);
    const newFinish = { ...items, [destination.droppableId]: finishItems };

    const newState = {
      ...items,
      [source.droppableId]: newStart[source.droppableId],
      [destination.droppableId]: newFinish[destination.droppableId],
    };
    if (source.droppableId == "vendors") {
      setRouteData({
        ...routeData,
        route_vendors: newFinish[destination.droppableId],
      });
    }
    if (source.droppableId == "route_vendors") {
      setRouteData({
        ...routeData,
        route_vendors: newStart[source.droppableId],
      });
    }
    //setItems(newState);
  };

  const handleRemoveClick = (item) => {
    setRouteData((prev) => ({
      ...prev,
      route_vendors: prev.route_vendors.filter((el) => el.id !== item.id),
    }));
  };

  return (
    <Block>
      <Typography component="p" variant="label" mb="11px">
        Vendors
      </Typography>
      <DragDropContext onDragEnd={onDragEnd}>
        <Grid
          container
          columnSpacing={{ xs: 1, sm: 3, xl: "10px" }}
          margin="0px 0 0"
        >
          <Grid item md={8} lg={8} sx={{ maxWidth: "908px", width: "100%" }}>
            <Droppable droppableId={"route_vendors"} key={"route_vendors"}>
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  style={{
                    width: "100%",
                    textAlign: "left",
                    fontSize: "12px",
                    color: "#8E8E8E",
                    minHeight: 200,
                    height: "100%",
                    padding: "5px",
                    borderRadius: "5px",
                    backgroundColor: "#fafafa",
                  }}
                >
                  {items.route_vendors.map((item, index) => (
                    <Draggable
                      draggableId={`${item.id}`}
                      index={index}
                      key={item.id}
                    >
                      {(provided) => (
                        <Item
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <Grid container>
                            <Grid xs="5" md="6">
                              <Typography>{item.name}</Typography>
                              {item.route_to_pick_up_for?.map((r) => (
                                <Typography fontSize={11}>
                                  picks up for: {r.pick_up_for.route.name}
                                </Typography>
                              ))}
                            </Grid>
                            <Grid
                              xs="7"
                              md="6"
                              sx={{
                                justifyContent: "flex-end",
                                gap: "5px",
                                display: "flex",
                                flexWrap: "wrap",
                              }}
                            >
                              <Button
                                variant="outlined"
                                sx={{ padding: "3px 8px" }}
                                onClick={() =>
                                  showModal(ManagePickUp, {
                                    item,
                                    vendorId: item.id,
                                    vendorName: item.name,
                                    currentPickUps: item.route_to_pick_up_for,
                                    getRouteFunction: getRouteFunction,
                                  })
                                }
                              >
                                Manage Pick ups
                              </Button>
                              <Button
                                variant="outlined"
                                sx={{ marginLeft: "5px", padding: "3px 8px" }}
                                size="small"
                                onClick={() => handleRemoveClick(item)}
                              >
                                Remove
                              </Button>
                            </Grid>
                          </Grid>
                        </Item>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </Grid>
          <Grid item md={4} lg={4} sx={{ maxWidth: "908px", width: "100%" }}>
            <div style={{ padding: "8px" }}>
              <Typography variant="inputLabel" component="p">
                Search Vendors
              </Typography>
              <OutlinedInput
                fullWidth
                name="vendorSearch"
                value={vendorSearch}
                onChange={(e) => setVendorSearch(e.target.value)}
              />
            </div>
            <Droppable droppableId={"vendors"} key={"vendors"}>
              {(provided) => (
                <Box
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  style={{
                    width: "100%",
                    textAlign: "center",
                    fontSize: "12px",
                    color: "#8E8E8E",
                    minHeight: 200,
                    height: "calc(100% - 90px)",
                    padding: "5px",
                    borderRadius: "5px",
                    backgroundColor: "#fafafa",
                  }}
                >
                  {items.vendors.slice(0, 15).map((item, index) => (
                    <Draggable
                      draggableId={`${item.id}`}
                      index={index}
                      key={item.id}
                    >
                      {(provided) => (
                        <Item
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          {item.name}
                        </Item>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </Box>
              )}
            </Droppable>
          </Grid>
        </Grid>
      </DragDropContext>
    </Block>
  );
}

export default Rdnd;

function ManagePickUp(props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { ...restProps } = props;
  const [report, setReport] = useState(props.initRouteData);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [routesToPickFor, setRoutesToPickFor] = useState([]);
  const [pickedRouteId, setPickedRouteId] = useState(-1);
  const [pickUps, setPickUps] = useState(props.currentPickUps);

  function updateRoutesToPickFor() {
    compatibleRoutesToPickFor(props.item.routeVendorId).then((res) =>
      setRoutesToPickFor(res.data.results),
    );
  }

  useEffect(() => {
    updateRoutesToPickFor();
  }, []);

  function changePickupRoute(e) {
    setPickedRouteId(e.target.value);
  }

  function create() {
    createRoutePickUp(props.item.routeVendorId, pickedRouteId)
      .then((res) => {
        updateRoutesToPickFor();
        setPickUps([...pickUps, res.data]);
        props.getRouteFunction();
      })
      .catch((error) => {
        const err = error.response.data;
        const errorFields = Object.keys(err);
        errorFields.forEach((field) => {
          const errorType = typeof err[field];
          const errorText = errorType === "string" ? err[field] : err[field][0];
          fireErrorNotification(`${field}: ${errorText}`);
        });
      });
  }

  function deletePickUp(routePickUpId) {
    deleteRoutePickUp(routePickUpId).then((res) => {
      updateRoutesToPickFor();
      setPickUps(pickUps.filter((item) => item.routePickUpId != routePickUpId));
    });
  }

  return (
    <Dialog
      {...restProps}
      fullScreen={fullScreen}
      maxWidth="xs"
      sx={{
        "& .MuiPaper-root": { width: "100%", maxWidth: "900px" },
        zIndex: 1500,
      }}
    >
      <ErrorBoundary
        fallback={<ErrorPopupBoundary onClose={restProps.onClose} />}
      >
        <DialogTitle
          sx={{
            textAlign: "center",
            fontWeight: 600,
            color: "#252525",
            paddingBottom: 0,
          }}
        >
          <Typography>
            Manage pick ups {props.vendorName} ({props.item.routeVendorId}){" "}
          </Typography>
        </DialogTitle>
        {isRefreshing && (
          <Typography textAlign="center" sx={{ color: "orange" }}>
            Saving...
          </Typography>
        )}
        <IconButton
          aria-label="close"
          onClick={() => props.onClose()}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent
          sx={{ display: "flex", flexDirection: "column", gap: "12px" }}
        >
          <Stack direction={"row"} spacing={"10px"}>
            <Typography padding={"8px"}>Routes to pick up for</Typography>
            <Select
              value={pickedRouteId}
              onChange={changePickupRoute}
              disabled={routesToPickFor.length == 0}
              size="small"
              MenuProps={{
                style: {
                  maxHeight: 400,
                  zIndex: 1500,
                },
              }}
              fullWidth
              sx={{ maxWidth: "200px" }}
            >
              <MenuItem value={-1} disabled={true}>
                select route
              </MenuItem>

              {routesToPickFor.map((rtf) => (
                <MenuItem value={rtf.id}>{rtf.name}</MenuItem>
              ))}
            </Select>
            <Button variant="outlined" onClick={() => create()}>
              Add
            </Button>
          </Stack>

          {pickUps && pickUps.length > 0 && (
            <Stack>
              <Typography>
                <b>Currently picking for</b>
              </Typography>
              <Table>
                <TableBody>
                  {pickUps.map((item) => (
                    <TableRow>
                      <TableCell>
                        <Typography>
                          {item.pick_up_for.route.name} ({item.routePickUpId})
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Button
                          variant="outlined"
                          onClick={() => deletePickUp(item.routePickUpId)}
                        >
                          Delete
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Stack>
          )}
          {!pickUps ||
            (pickUps.length == 0 && (
              <Typography>Currently not picking for any route</Typography>
            ))}
        </DialogContent>
      </ErrorBoundary>
    </Dialog>
  );
}
