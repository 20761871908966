import React from "react";
import { FormControlLabel, FormGroup } from "@mui/material";
import RouteSelect from "../../components/AsyncSelects/RouteSelect";
import useAppDispatch from "../../hooks/useAppDispatch";
import useAppSelector from "../../hooks/useAppSelector";
import { setCurrentRoute } from "../../redux/module/addRouteChanges";

const RouteSelectControl = () => {
  const dispatch = useAppDispatch();
  const currentRoute = useAppSelector(
    (state) => state.addRouteChanges.currentRoute
  );

  return (
    <FormGroup>
      <FormControlLabel
        label="Select route"
        labelPlacement="start"
        sx={{
          alignItems: "flex-start",
          margin: 0,
          gap: "16px",
          "& span": { fontWeight: 500 },
        }}
        control={
          <RouteSelect
            label="Route"
            name="currentRoute"
            value={currentRoute}
            disableClearable
            onChange={(_e, newValue) => dispatch(setCurrentRoute(newValue))}
          />
        }
      />
    </FormGroup>
  );
};

export default RouteSelectControl;
