import api from "./api";
import { orderingToApi, filtersToApi } from "./common";

// export async function listRoutes(
//   pageSize,
//   page,
//   orderBy,
//   filterBy,
//   search_params
// ) {
//   const orderByString = orderingToApi(orderBy);
//   const filterByString = filtersToApi(filterBy, search_params);
//   const params = `page_size=${pageSize}&page=${page}&ordering=${orderByString}&${filterByString}`;
//   const result = await api.get(`/api/routes/routes/?${params}`);
//   return result.data;
// }

export async function getRouteReports(date) {
  const queryParams = new URLSearchParams({ date, apply_changes: true });
  return api.get(`/api/routes/rr/?${queryParams}`);
}

export async function getRouteReportById(routeId, date) {
  const queryParams = new URLSearchParams({
    route_id: routeId,
    date,
    apply_changes: true,
  });
  return api.get(`/api/routes/rr/?${queryParams}`);
}

export async function createRouteReportPickupVendor(
  routeReportId,
  vendorId,
  state,
  pickedUpTimestamp,
  issueDescription = null,
) {
  const requestBody = {
    route_report: routeReportId,
    vendor: vendorId,
    state,
    picked_up_timestamp: pickedUpTimestamp,
    issue_description: issueDescription,
  };

  return api.post("/api/routes/route-report-pickups/", requestBody);
}

export async function updateRouteReportPickupStatus(
  id,
  state,
  pickedUpTimestamp,
) {
  return api.patch(`/api/routes/route-report-pickups/${id}/`, {
    state,
    picked_up_timestamp: pickedUpTimestamp,
  });
}

export async function createRouteReport(state, routeId, date) {
  const requestBody = {
    route: routeId,
    state,
    date,
  };

  return api.post("/api/routes/route-reports/", requestBody);
}

export async function updateRouteReportStatus(id, state, date) {
  return api.patch(`/api/routes/route-reports/${id}/`, { state, date });
}

export async function createRouteReportPickupItem(
  variant,
  amount,
  vendor,
  route_report,
  note,
  unit,
  account,
  date,
) {
  const requestBody = {
    variant,
    amount,
    vendor,
    route_report,
    note,
    unit,
    account,
  };

  return api.post(
    `/api/routes/route-report-pickup-items/?date=${date}`,
    requestBody,
  );
}

export async function updateRouteReportPickupItem(id, amount, note, date) {
  const requestBody = {
    amount,
    note,
    // unit
  };

  return api.patch(
    `/api/routes/route-report-pickup-items/${id}/?date=${date}`,
    requestBody,
  );
}
