/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Stack,
  IconButton,
  TextField,
  InputAdornment,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSearchDebounce } from "../../utils";
import { useSearchParams } from "react-router-dom";
import Search from "../../images/search.svg";

function NotificationsFilter() {
  const [searchParams, setSearchParams] = useSearchParams({
    acknowledged: "true",
  });
  const [searchDB, setSearchDB] = useSearchDebounce();
  const [filter, setFilter] = useState({
    search: searchParams.get("search") || "",
    acknowledged: searchParams.get("acknowledged") || "",
  });

  const handleChange = (key, value) => {
    setSearchParams((params) => {
      const newParams = new URLSearchParams(params);
      if (!!value) {
        newParams.set(key, value);
      } else {
        newParams.delete(key);
      }
      return newParams;
    });
  };

  useEffect(() => {
    setSearchDB(filter.search);
  }, [filter]);

  useEffect(() => {
    handleChange("search", searchDB);
  }, [searchDB]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mt: 3,
          flexDirection: "column",
        }}
      >
        <TextField
          placeholder="Search for notifications"
          fullWidth
          value={filter.search}
          onChange={(e) => {
            setFilter({ ...filter, search: e.target.value });
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <IconButton>
                  <img src={Search} alt="" />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>

      <Box sx={{ mt: 1 }}>
        <Stack direction="row" spacing={2}>
          <RadioGroup
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: 2,
              border: "none !important",
            }}
            value={filter.acknowledged}
            onChange={(e) => {
              setFilter({ ...filter, acknowledged: e.target.value });
              if (e.target.value === "all") {
                handleChange("acknowledged", null);
              } else {
                handleChange("acknowledged", e.target.value);
              }
            }}
          >
            <FormControlLabel
              value={"all"}
              control={<Radio checked={!filter.acknowledged} />}
              label="All"
            />
            <FormControlLabel
              value={"false"}
              control={<Radio />}
              label="Acknowledged"
            />

            <FormControlLabel
              value={"true"}
              control={<Radio />}
              label="Unacknowledged"
            />
          </RadioGroup>
        </Stack>
      </Box>
    </>
  );
}

export default NotificationsFilter;
