import {
  Button,
  Dialog,
  Box,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  Input,
} from "@mui/material";
import { useState } from "react";
import { createSalesman } from "../../distflowAPI/salesmenApi";
import AccountSelect from "../../components/AsyncSelects/AccountSelect";
import CommissionInput from "./ComissionInput";
export default function AddSalesman({ open, setOpen, refreshTable }) {
  const [commission, setCommission] = useState(0.1);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [selectedAccounts, setSelectedAccounts] = useState([]);
  const [accountSearch, setAccountSearch] = useState("");
  const [userInfo, setUserInfo] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
  });

  function handleSubmit() {
    const payload = {
      ...userInfo,
      phone_number: phoneNumber,
      attributed_accounts: selectedAccounts.map(item=>item.value),
      percentage: commission,
    };
    createSalesman(payload).then((res) => {
      setOpen(false);
      refreshTable();
    });
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle sx={{ fontWeight: "bolder", mb: "30px" }}>
          Add a new Salesman
        </DialogTitle>
        <DialogContent>
          <TextField
            required={true}
            name="name"
            label="First Name"
            value={userInfo.first_name}
            onChange={(e) => {
              setUserInfo({ ...userInfo, first_name: e.target.value });
            }}
            fullWidth
            sx={{ margin: "8px" }}
          />
          <TextField
            required={true}
            name="name"
            label="Last Name"
            value={userInfo.last_name}
            onChange={(e) => {
              setUserInfo({ ...userInfo, last_name: e.target.value });
            }}
            fullWidth
            sx={{ margin: "8px" }}
          />
          <TextField
            required={true}
            name="name"
            label="Email"
            value={userInfo.email}
            onChange={(e) => {
              setUserInfo({ ...userInfo, email: e.target.value });
            }}
            fullWidth
            sx={{ margin: "8px" }}
            type="email"
          />
          <TextField
            required={true}
            name="name"
            label="Password"
            value={userInfo.password}
            onChange={(e) => {
              setUserInfo({ ...userInfo, password: e.target.value });
            }}
            fullWidth
            sx={{ margin: "8px" }}
            type="password"
          />
          <AccountSelect
            multiple
            style={{ margin: "8px" }}
            name="accounts"
            label="Salesman's Accounts"
            required
            value={selectedAccounts}
            onChange={(_event, newValue) => {
              setSelectedAccounts(newValue);
            }}
            setAccountSearch={setAccountSearch}
          />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "10px",
              mt: "20px",
            }}
          >
            <Typography variant="h7">Commision:</Typography>
            <CommissionInput
              commission={commission}
              setCommission={setCommission}
            />
            <Typography variant="h7" sx={{ fontWeight: "bolder" }}>
              %
            </Typography>
          </Box>
        </DialogContent>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            m: "20px",
            gap: "10px",
          }}
        >
          <Button
            variant="contained"
            color="success"
            onClick={() => handleSubmit()}
          >
            Create
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              setOpen(false);
            }}
          >
            Cancel
          </Button>
        </Box>
      </Dialog>
    </>
  );
}
