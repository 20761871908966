export const SOLD_BY_OPTIONS = {
  BX: {
    name: "Box",
    pluralName: "Boxes",
    multiple: true,
  },
  PC: {
    name: "Piece",
    pluralName: "Pieces",
  },
  BK: {
    name: "Bucket",
    pluralName: "Buckets",
    multiple: true,
  },
  BT: {
    name: "Batter",
    pluralName: "Batters",
  },
  CT: {
    name: "Container",
    pluralName: "",
  },
  DZ: {
    name: "Dozen",
    pluralName: "Dozens",
  },
  EA: {
    name: "Each",
    pluralName: "Each",
  },
  LB: {
    name: "Lb",
    pluralName: "Lbs",
  },
  PK: {
    name: "Package",
    pluralName: "Packages",
  },
  PL: {
    name: "Pale / Bucket",
    pluralName: "Pales / Buckets",
  },
  SH: {
    name: "Sheet",
    pluralName: "Sheets",
  },
};
