import React from "react";
import Masonry from "@mui/lab/Masonry";
import Product from "./Product";

const AutoOrdersProductsList = ({ variants = [], account, updateAccountRelated, accountOrders }) => {
    
  const alreadySelectedProductVariantIds = accountOrders.map(el => el?.item?.id)
  
  if( !variants.length) {
    return <></>
  }
  
  return (
    <>
      <Masonry
        columns={{ xs: 1, md: 2 }}
        spacing={1}
      >
        {variants.map((item) => (
          <Product
            key={item.id}
            parent
            item={item}
            account={account}
            updateAccountRelated={updateAccountRelated}
            hideAdd={alreadySelectedProductVariantIds.includes(item.id)}
          >
            {item.related.map((related_item) => (
              <Product
                key={related_item.id}
                child
                item={related_item}
              />
            ))}
          </Product>
        ))}
      </Masonry>
    </>
  );
};

export default AutoOrdersProductsList;