import React from "react";
import AsyncAutocompleteSelect from "./AsyncAutocompleteSelect";
import categoriesApi from "../../distflowAPI/categoriesApi";

/**
 * @param {Omit<
 *  import("./AsyncAutocompleteSelect").AsyncAutocompleteSelectProps<
 *    import("../../redux/module/category").Category
 *  >,
 *  'fetchOptions'
 * >} props
 */
const CategorySelect = (props) => (
  <AsyncAutocompleteSelect
    fetchOptions={(searchTerm) =>
      categoriesApi
        .list(10, 1, [], [{ column: "name", value: searchTerm }])
        .then((res) =>
          res.results.map((item) => ({
            value: item.id,
            label: item.tree_name,
          }))
        )
    }
    {...props}
  />
);

export default CategorySelect;
