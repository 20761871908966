import React, { useState } from "react";
import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  Button,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import Product from "./Product";
import Block from "../../components/Block";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import Spinner from "../../components/Spinner";
import { smallButton } from "../../utils/smallButton";
import DatePickerCustom2 from "../../components/DatePicker2";
import { bulkPushOffAutoOrders } from "../../distflowAPI/ordersApi";
import { DATE_FORMAT, DATE_FORMAT_FRONT } from "../../utils/constants";

const formControlLabels = [
  { id: 1, value: "all" },
  { id: 2, value: "mon" },
  { id: 3, value: "tue" },
  { id: 4, value: "wed" },
  { id: 5, value: "thu" },
  { id: 6, value: "fri" },
  // { id: 7, value: "sat" },
  { id: 8, value: "sun" },
];

function CancelOrderModal({ open, setOpen, account, refreshTable, day }) {
  const [date, setDate] = useState(null);
  const [dateTill, setDateTill] = useState(null);
  function handleSubmit() {
    const df = date ? date.format(DATE_FORMAT) : "";
    const dt = dateTill ? dateTill.format(DATE_FORMAT) : "";
    bulkPushOffAutoOrders([account.value], df, dt).then(
      (res) => {
        alert(`successfully disabled from ${df} to ${dt}`);
        refreshTable();
        setOpen(false);
      },
      (err) => {
        alert("Error occurred");
      },
    );
  }
  return (
    <Dialog open={open}>
      <DialogTitle>
        <Typography>Disable Steadies</Typography>
      </DialogTitle>
      <DialogContent>
        <Stack>
          <Typography>Disable from</Typography>
          <DatePickerCustom2
            controlledValue={date}
            setControlledValue={setDate}
          />
          <Typography>Disable Till</Typography>
          <DatePickerCustom2
            controlledValue={dateTill}
            setControlledValue={setDateTill}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack direction={"row"} spacing={1}>
          <Button
            variant="contained"
            color="error"
            onClick={() => setOpen(false)}
          >
            Close
          </Button>
          <Button variant="contained" color="success" onClick={handleSubmit}>
            Confirm
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
const AutoOrderConfigurations = ({
  day,
  title = "Account Scheduled Products",
  onChange,
  accountOrders,
  account,
  updateAccountRelated,
  isLoading = false,
}) => {
  const [cancelOpen, setCancelOpen] = useState(false);
  return (
    <>
      <CancelOrderModal
        open={cancelOpen}
        setOpen={setCancelOpen}
        account={account}
        day={day}
        refreshTable={updateAccountRelated}
      />
      <Stack direction={"row"} spacing={1}>
        <Typography fontSize="23px" fontWeight={600} mb="17px">
          {title}
        </Typography>
        <Button
          color="error"
          variant="contained"
          sx={{ ...smallButton, maxWidth: 130 }}
          onClick={() => setCancelOpen(true)}
        >
          Bulk Cancel
        </Button>
      </Stack>

      <RadioGroup
        row
        value={day}
        name="days-order"
        sx={{
          justifyContent: "space-around",
          flexWrap: "wrap",
          gap: "15px",
          padding: "10px !important",
          marginBottom: "9px",
          "& .MuiFormControlLabel-root": {
            position: "relative",
            margin: 0,
            "&:has(span.Mui-checked)": {
              "&::after": {
                content: "''",
                position: "absolute",
                left: "50%",
                bottom: "-11px",
                transform: "translateX(-50%)",
                width: "200%",
                height: "2px",
                borderRadius: "5px",
                bgcolor: "#6576FF",
              },
            },
          },
          "& .MuiFormControlLabel-root .Mui-checked + span": {
            color: "#6576FF",
            fontWeight: "bolder",
            textDecoration: "underline",
          },
          "& .MuiFormControlLabel-root .MuiRadio-root > span": {
            display: "none",
          },
          "& .MuiFormControlLabel-root .MuiRadio-root": {
            padding: 0,
          },
        }}
      >
        {formControlLabels.map((label) => (
          <FormControlLabel
            key={label.id}
            value={label.value}
            control={<Radio onChange={() => onChange(label.value)} />}
            label={label.value.charAt(0).toUpperCase() + label.value.slice(1)}
          />
        ))}
      </RadioGroup>
      {isLoading ? (
        <Box
          height="100px"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Spinner size="lg" />
        </Box>
      ) : !accountOrders.length && account.value ? (
        <Box
          height="100px"
          display="flex"
          gap={2}
          justifyContent="center"
          alignItems="center"
        >
          <SearchOutlinedIcon fontSize="medium" />
          <Typography component="p" variant="body1">
            There are no products on the selected day
          </Typography>
        </Box>
      ) : (
        <Box
          display="grid"
          gridTemplateColumns={{ xs: "1fr", md: "1fr 1fr" }}
          gap={1}
        >
          {accountOrders.map((item) => (
            <Product
              key={item.id}
              multiple
              item={item.item}
              orderData={item}
              day={day}
              account={account}
              updateAccountRelated={updateAccountRelated}
            />
          ))}
        </Box>
      )}
    </>
  );
};

export default AutoOrderConfigurations;
