import React, { useState } from "react";
import { IconButton } from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import ConfirmDialog from "../ConfirmDialog";
import { useNavigate } from "react-router-dom";
import ChangesHistory from "../History";

const TableButtons = ({
  link,
  handleEdit,
  handleDelete,
  deletePopupEntity,
  historyData,
  allowEdit = true,
}) => {
  const navigate = useNavigate();

  const [isOpenDelete, setIsOpenDelete] = useState(false);

  const handleEditClick = () => {
    if (link) {
      navigate(link);
    } else {
      handleEdit();
    }
  };

  return (
    <>
      <ConfirmDialog
        title={`Delete ${deletePopupEntity}?`}
        open={isOpenDelete}
        setOpen={setIsOpenDelete}
        onConfirm={handleDelete}
      >
        {`Are you sure you want to delete this ${deletePopupEntity}?`}
      </ConfirmDialog>

      {allowEdit && (
        <IconButton onClick={handleEditClick}>
          <EditOutlinedIcon />
        </IconButton>
      )}
      <ChangesHistory historyData={historyData} />
      {handleDelete && (
        <IconButton
          onClick={() => {
            setIsOpenDelete(true);
          }}
        >
          <DeleteForeverOutlinedIcon />
        </IconButton>
      )}
    </>
  );
};

export default TableButtons;
