import { useEffect, useState } from "react";
import { Box, Button, Grid, Stack, TextField, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate, useParams } from "react-router-dom";
import useAppSelector from "../../hooks/useAppSelector";
import useAppDispatch from "../../hooks/useAppDispatch";
import Block from "../../components/Block";
import { fetchTag, removeTag, updateTag } from "../../redux/module/tag";
import ConfirmDialog from "../../components/ConfirmDialog";
import UpdateItemTitle from "../../components/UpdateItemTitle";

const UpdateTag = () => {
  const { id } = useParams();
  const tag = useAppSelector((state) => state.tag.tag);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isOpenDiscard, setIsOpenDiscard] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [tagInfo, setTagInfo] = useState({
    name: "",
  });

  async function handleSubmit(event) {
    event.preventDefault();
    setIsSubmitting(true);
    await dispatch(updateTag({ tagId: id, tag: tagInfo.name }));
    setIsSubmitting(false);
  }

  useEffect(() => {
    if (id) {
      dispatch(fetchTag(id));
    }
  }, [id]);

  useEffect(() => {
    if (tag.id === +id) {
      setTagInfo({
        name: tag.name,
      });
    }
  }, [tag]);

  const handleDiscard = () => {
    dispatch(fetchTag(id));
  };

  const handleDelete = async () => {
    const resp = await dispatch(removeTag(id));
    if (resp.payload === id) {
      navigate(-1);
    }
  };
  return (
    <Box maxWidth="1394px" margin=" 30px auto 0">
      <ConfirmDialog
        title="Discard updates?"
        open={isOpenDiscard}
        setOpen={setIsOpenDiscard}
        onConfirm={handleDiscard}
      >
        {`Are you sure you want to discard all changes?`}
      </ConfirmDialog>
      <ConfirmDialog
        title="Delete tag?"
        open={isOpenDelete}
        setOpen={setIsOpenDelete}
        onConfirm={handleDelete}
      >
        {`Are you sure you want to delete this tag?`}
      </ConfirmDialog>
      <form id="tagUpdate" onSubmit={handleSubmit}>
        <UpdateItemTitle
          item={"Tag"}
          setIsOpenDelete={setIsOpenDelete}
          setIsOpenDiscard={setIsOpenDiscard}
          isSubmitting={isSubmitting}
        />
        <Grid
          container
          columnSpacing={{ xs: 1, sm: 3, xl: "50px" }}
          margin="20px 0 0"
        >
          <Grid item md={9} lg={9} sx={{ maxWidth: "908px", width: "100%" }}>
            <Block>
              <TextField
                required
                name="name"
                label="Tag"
                data-testid="test-tag-id"
                type="text"
                id="name"
                fullWidth
                autoComplete="off"
                value={tagInfo.name}
                onChange={(e) =>
                  setTagInfo({ ...tagInfo, name: e.target.value })
                }
                sx={{ pt: 1, pb: 1 }}
              />
            </Block>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default UpdateTag;
