import { Link, useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import ChangesHistory from "../../../components/History";
import TableButtons from "../../../components/TableComponents/TableButtons";
import { deleteRoute } from "../../../redux/module/route";
import useAppDispatch from "../../../hooks/useAppDispatch";

const DAYS_LIST = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'sunday']

const route_name = {
  headerName: "Name",
  colId: "name",
  wrapText: true,
  autoHeight: true,
  filter: false,
  floatingFilter: false,
  valueGetter: (params) => {
    return `${params.data.name}`;
  },
  minWidth: 200,
};

const is_active = {
  headerName: "Is Active",
  wrapText: true,
  colId: "is_active",  
  autoHeight: true,  
  filter: false,
  floatingFilter: false,
  valueGetter: (params) => {
    return params.data.active?'Yes':'No';
  },
  minWidth: 130,

};


const driver = {
  headerName: "Driver",
  colId: "driver",  
  wrapText: true,
  autoHeight: true,  
  filter: false,
  floatingFilter: false,
  valueGetter: (params) => {
    return `${params.data.driver.user.first_name} ${params.data.driver.user.last_name}`;
  },
  minWidth: 200,
}

const accounts = {
  headerName: "Accounts",
  colId: "accounts",  
  wrapText: true,
  autoHeight: true, 
  filter: false,
  floatingFilter: false,
  valueGetter: (params) => {
    return `${params.data.route_accounts.length}`;
  },
  minWidth: 130,

}

const vendors = {
  headerName: "Vendors",
  colId: "vendors",
  wrapText: true,
  autoHeight: true,
  filter: false,
  floatingFilter: false,
  valueGetter: (params) => {
    return `${params.data.route_vendors.length}`;
  },
  minWidth: 130,
}

const days = {
  headerName: "Days",
  colId: "days",  
  wrapText: true,
  autoHeight: true, 
  filter: false,
  floatingFilter: false,
  valueGetter: (params) => {
    const active_days = DAYS_LIST.filter(item=>params.data[item] == true)
    return active_days.join(', ');
  },
  minWidth: 200,
}

const RenderRouteLink = (props) => {
  const dispatch = useAppDispatch();

  const handleDelete = async () => {
    await dispatch(deleteRoute(props.data.id))
  }
  
  return (
    <>
      <TableButtons
        link={`/routes2/routes-changes/${props.data.id}`}
        handleDelete={handleDelete}
        deletePopupEntity="route changes"
        historyData={props.data.history || []}
      />
    </>
  );
};

const route_link = {
  field: "",
  sorting: false,
  sort: false,
  sortable: false,
  autoHeight: true,
  filter: false,
  cellRenderer: RenderRouteLink,
  minWidth: 120,
  maxWidth: 120,
};
const routeTableColumns = [
  route_name,
  is_active,
  driver,
  accounts, 
  vendors,
  days,
  route_link
];

export default routeTableColumns;
