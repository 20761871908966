import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useAppDispatch from "../../hooks/useAppDispatch";
import { Box, Button, Grid, Stack, Tab, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  fetchCustomer,
  fetchCustomerAccounts,
  fetchCustomerFinancials,
  fetchCustomerOverrides,
} from "../../redux/module/customer";
import useAppSelector from "../../hooks/useAppSelector";
import Block from "../../components/Block";
import CustomerGeneralInfo from "../../components/CustomerComponents/CustomerGeneralInfo";
import Spinner from "../../components/Spinner";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import RouteOutlinedIcon from "@mui/icons-material/RouteOutlined";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import CustomerPersonal from "../../components/CustomerComponents/CustomerPersonal";
import CustomerOverrides from "../../components/CustomerComponents/CustomerOverrides";
import CustomerAccounts from "../../components/CustomerComponents/CustomerAccounts";
import ApartmentOutlinedIcon from "@mui/icons-material/ApartmentOutlined";
import CustomerOrders from "../../components/CustomerComponents/CustomerOrders";
import CustomerInvoices from "../../components/CustomerComponents/CustomerInvoices";
import CustomerPayments from "../../components/CustomerComponents/CustomerPayments";
import OpenInFullOutlinedIcon from "@mui/icons-material/OpenInFullOutlined";
import CloseFullscreenOutlinedIcon from "@mui/icons-material/CloseFullscreenOutlined";
import CustomerNotes from "./CustomerNotes";
import CustomerRestrictions from "./CustomerRestrictions";
import RestrictCategoryCustomers from "./RestrictCategoryCustomers";
import CustomerTransactions from "../../components/CustomerComponents/CustomerTransactions";
import CustomerOrdersHistory from "../../components/CustomerComponents/CustomerOrdersHistory";
import RestrictVendorAccounts from "../accounts/RestrictVendorAccounts";

const CustomerDashboard = () => {
  const { id, tab } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const {
    customer,
    customerOverrides,
    customerAccounts,
    customerBalance,
    selectedTab,
  } = useAppSelector((state) => state.customer);
  const [showGeneralInfo, setShowGeneralInfo] = useState(true);

  const handleChange = (event, newValue) => {
    navigate(
      `/customers/dashboard/${id}/${
        newValue === "invoices"
          ? "invoices?ordering=issued_date&status=UNPAID%2CCREDIT%2CPARTIALLY_PAID"
          : newValue
      }`
    );
    // dispatch(setSelectedTab(newValue));
  };

  useEffect(() => {
    if (id) {
      const fetchCustomerData = async () => {
        await dispatch(fetchCustomer(id));
        await dispatch(fetchCustomerOverrides(id));
        await dispatch(fetchCustomerAccounts(id));
        await dispatch(fetchCustomerFinancials(id));
        // await dispatch(fetchCustomerPhones(id));
        // dispatch(fetchCustomerEmails(id));
      };
      fetchCustomerData();
    }
  }, [id]);

  if (!customer.id) {
    return (
      <Box
        height="100vh"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Spinner size="lg" />
      </Box>
    );
  }

  const tabsList = [
    { id: "personal", label: "Personal", icon: <AccountCircleOutlinedIcon /> },
    { id: "accounts", label: "Accounts", icon: <ApartmentOutlinedIcon /> },
    { id: "overrides", label: "Overrides", icon: <LocalOfferOutlinedIcon /> },
    { id: "orders", label: "Orders", icon: <ListAltOutlinedIcon /> },
    { id: "invoices", label: "Invoices", icon: <RouteOutlinedIcon /> },
    { id: "payments", label: "Payments", icon: <AccountBalanceOutlinedIcon /> },
    {
      id: "transactions",
      label: "Transactions",
      icon: <AccountBalanceOutlinedIcon />,
    },
    {
      id: "ordersHistory",
      label: "orders History",
      icon: <ListAltOutlinedIcon />,
    },
  ];

  return (
    <Box maxWidth="1394px" margin=" 30px auto 0" className="customer-dashboard">
      <Stack
        direction={{ xs: "column", md: "row" }}
        alignItems={{ xs: "flex-start", md: "center" }}
        justifyContent="space-between"
        spacing="20px"
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
          sx={{ width: { xs: "100%", sm: "initial" }, gap: "20px" }}
        >
          <Button
            onClick={() => navigate(-1)}
            variant="outlined"
            startIcon={<ArrowBackIcon />}
          >
            Back
          </Button>
          <Typography variant="pageTitle" noWrap component="p">
            Customer dashboard
          </Typography>
        </Stack>
      </Stack>
      <Grid
        container
        columnSpacing={{ xs: 1, sm: 3, xl: "20px" }}
        margin="20px 0 0"
        sx={{
          flexDirection: { xs: "column-reverse", md: "row" },
        }}
      >
        <Grid item md={12} sx={{ maxWidth: "1008px", width: "100%" }}>
          <Block
            sx={{
              padding: { xs: "16px 0", sm: "13px 0 24px" },
              marginBottom: "24px",
            }}
          >
            <TabContext value={tab}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                  variant="scrollable"
                >
                  {tabsList.map((el) => (
                    <Tab
                      key={el.id}
                      icon={el.icon}
                      value={el.id}
                      iconPosition="start"
                      label={el.label}
                    />
                  ))}
                </TabList>
              </Box>
              <TabPanel value="personal">
                <CustomerPersonal customer={customer} />
              </TabPanel>
              <TabPanel value="accounts">
                <CustomerAccounts accounts={customerAccounts} />
              </TabPanel>
              <TabPanel value="overrides">
                <CustomerOverrides overrides={customerOverrides} />
              </TabPanel>
              <TabPanel value="orders">
                <CustomerOrders />
              </TabPanel>
              <TabPanel value="invoices">
                <CustomerInvoices accounts={customerAccounts} />
              </TabPanel>
              <TabPanel value="payments">
                <CustomerPayments />
              </TabPanel>
              <TabPanel value="transactions">
                <CustomerTransactions />
              </TabPanel>
              <TabPanel value="ordersHistory">
                <CustomerOrdersHistory />
              </TabPanel>
            </TabContext>

            <Stack direction="row" justifyContent="flex-end" pr={3}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexShrink: 0,
                  cursor: "pointer",
                }}
                onClick={() => setShowGeneralInfo(!showGeneralInfo)}
              >
                {showGeneralInfo ? (
                  <OpenInFullOutlinedIcon />
                ) : (
                  <CloseFullscreenOutlinedIcon />
                )}
              </Box>
            </Stack>
          </Block>
        </Grid>
        <Grid md={12} sx={{ pl: 3 }}>
          <Grid container spacing={2}>
            <Grid item md={9} sx={{ width: "100%" }}>
              <RestrictCategoryCustomers />
              <CustomerNotes />
              <CustomerRestrictions />
              <RestrictVendorAccounts />
            </Grid>
            <Grid item md={3} sx={{ width: "100%" }}>
              <CustomerGeneralInfo />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CustomerDashboard;
