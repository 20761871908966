import React, { useEffect, useState } from "react";
import { listTransactions } from "../../distflowAPI/invoicesApi";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import dayjs from "dayjs";

function RedeemCredit({
  open,
  setOpen,
  accountId,
  addToCredits,
  existingCredits = {},
  customerId,
}) {
  const [credits, setCredits] = useState([]);
  const [search, setSearch] = useState("");
  useEffect(() => {
    if (!accountId && !customerId) {
      return;
    }
    const filters = [
      {
        column: "used",
        value: false,
      },
      {
        column: "method",
        value: "CUSTOMER_CREDIT",
      },
      {
        column: "search",
        value: search,
      },
    ];
    if (accountId) {
      filters.push({
        column: "account__id",
        value: accountId,
      });
    }
    if (customerId) {
      filters.push({
        column: "customer__id",
        value: customerId,
      });
    }
    listTransactions(
      1,
      50,
      filters,

      [],
    ).then(
      (res) => {
        setCredits(res.data.results);
      },
      (err) => {},
    );
  }, [accountId, open, search]);
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      maxWidth="xl"
      sx={{
        "& .MuiPaper-root": {
          width: "100%",
          maxWidth: "800px",
        },
      }}
    >
      <DialogTitle>
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
          }}
        >
          <CloseIcon
            sx={{ cursor: "pointer" }}
            onClick={() => {
              setOpen(false);
            }}
          />
        </div>
        <Typography
          variant="pageTitle"
          noWrap
          textAlign="center"
          component="p"
          mb="20px"
        >
          Pick A credit to apply
        </Typography>
      </DialogTitle>
      <DialogContent>
        <TextField value={search} onChange={(e) => setSearch(e.target.value)} />
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>id</TableCell>
              <TableCell>date</TableCell>
              <TableCell>amount</TableCell>
              <TableCell>memo</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {credits
              .filter(
                (credit) =>
                  !Object.keys(existingCredits)
                    .map((k) => existingCredits[k]?.id)
                    .includes(credit.id),
              )
              .map((credit) => (
                <TableRow>
                  <TableCell>{credit.id}</TableCell>
                  <TableCell>
                    {dayjs(credit.payment_date, "YYYY-MM-DD").format(
                      "MMM DD, YYYY",
                    )}
                  </TableCell>
                  <TableCell>{credit.amount_paid}</TableCell>
                  <TableCell>{credit.notes}</TableCell>
                  <TableCell>
                    <Button
                      variant="outlined"
                      onClick={() => addToCredits(credit)}
                    >
                      Use
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </DialogContent>
    </Dialog>
  );
}

export default RedeemCredit;
