import ApiBase from "./apiBase";
import api from "./api";
import { filtersToApi, orderingToApi } from "./common";

const paymentsApi = new ApiBase("/accounting/invoice_payments");

export default paymentsApi;

export async function createPayment(payload) {
  const res = await api.post("/api/accounting/payment_groups/", payload);
  return res;
}

export async function listPayments(
  page,
  pageSize,
  filterBy,
  orderBy,
  searchParams
) {
  const orderByString = orderingToApi(orderBy);
  const filterByString = filtersToApi(filterBy);
  let filterByS = filtersToApi([], searchParams);
  const params = `?page_size=${pageSize}&page=${page}&ordering=${orderByString}&${filterByString}&${filterByS}`;
  const res = await api.get(`/api/accounting/payment_groups/${params}`);
  return res;
}

export async function getPayment(id) {
  const res = await api.get(`/api/accounting/payment_groups/${id}/`);
  return res;
}

export async function deletePayment(paymentId) {
  const res = await api.delete(`/api/accounting/payment_groups/${paymentId}/`);
  return res;
}

export async function attachPaymentFile(id, paylaod) {
  const res = await api.post(
    `/api/accounting/payment_groups/${id}/attachments/`,
    paylaod
  );
  return res;
}

export async function deletePaymentAttachment(paymentId, attachmentId) {
  const res = await api.delete(
    `/api/accounting/payment_groups/${paymentId}/attachments/${attachmentId}/`
  );
  return res;
}

export async function updatePaymentGroup(paymentId, payload) {
  const res = await api.patch(
    `/api/accounting/payment_groups/${paymentId}/`,
    payload
  );
  return res;
}

export async function voidPayment(payload) {
  const res = await api.post(`/api/accounting/void_payment/`, payload);
  return res;
}

export async function issueRefund(payload) {
  const res = await api.post(`/api/accounting/partial_cc_refund/`, payload);
  return res;
}
