import {
  IconButton,
  Typography,
  useTheme,
  useMediaQuery,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import useAppSelector from "../../hooks/useAppSelector";
import PickupItem from "./PickupItem";

const PickupModal = (props) => {
  
  const currentRouteReport = useAppSelector((state) => state.routeReports.selectedReport);
  const pickups = useAppSelector((state) => state.routeReports.selectedReportPickups);

  const { total_picked_up, total_pickups } = currentRouteReport;

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { ...restProps } = props;

  return (
    <Dialog
      {...restProps}
      fullScreen={fullScreen}
      maxWidth="xs"
      sx={{ "& .MuiPaper-root": { width: "100%", maxWidth: "900px" }, zIndex: 1500 }}
    >
      <DialogTitle sx={{ textAlign: "center", fontSize: "24px", fontWeight: 600, color: "#252525", paddingBottom: 0 }}>
        Pickups
      </DialogTitle>
      <Typography textAlign="center" sx={{ color: "#535151" }}>
        {total_picked_up} of {total_pickups}
      </Typography>
      <IconButton
        aria-label="close"
        onClick={() => props.onClose()}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ display: "flex", flexDirection: "column", gap: "12px" }}>
        {pickups?.map((pickup, index) => (
          <PickupItem
            key={index}
            pickup={pickup} />))}
      </DialogContent>
    </Dialog>
  );
};

export default PickupModal;
