import api from "./api";
import { orderingToApi, filtersToApi } from "./common";
export async function listTags(
  pageSize,
  page,
  orderBy,
  filterBy,
  search_params
) {
  const orderByString = orderingToApi(orderBy);
  const filterByString = filtersToApi(filterBy, search_params);
  const params = `page_size=${pageSize}&page=${page}&ordering=${orderByString}&${filterByString}`;
  const result = await api.get(`/api/products/tags/?${params}`);
  return result.data;
}

export function getTag(tagId) {
  return api.get(`/api/products/tags/${tagId}/`);
}

export function postTag(tag) {
  return api.post(`/api/products/tags/`, { tag });
}

export function patchTag(tagId, tag) {
  return api.patch(`/api/products/tags/${tagId}/`, { tag });
}

export function deleteTag(tagId) {
  return api.delete(`/api/products/tags/${tagId}/`);
}
