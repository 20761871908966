import { CheckOutlined, CloseOutlined } from "@mui/icons-material";
import { Input, Typography } from "@mui/material";

export default function NumberInput({
  value,
  setValue,
  unit,
  setPriceEdit,
  handleClick,
}) {
  const buttonStyle = {
    fontWeight: "bolder",
    textAlign: "center",
    width: "20px",
    height: "20px",
    backgroundColor: "white",
    border: 0,
    color: "#333",
    borderRadius: "3px",
    cursor: "pointer",
  };
  const handleInputChange = (e) => {
    setValue(parseFloat(e.target.value));
  };
  return (
    <>
      <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Typography fontSize={"14px"}>{unit}</Typography>
        <Input
          sx={{ backgroundColor: "white", width: "80px", textAlign: "right" }}
          type="number"
          value={value}
          onChange={handleInputChange}
          disableUnderline={true}
        />
        <div style={{ display: "flex", flexDirection: "column", gap: 0 }}>
          <button
            style={buttonStyle}
            onClick={() => {
              setValue((oldVal) => {
                if (isNaN(oldVal)) {
                  return 0;
                } else {
                  return oldVal + 1;
                }
              });
            }}
          >
            +
          </button>
          <button
            size="large"
            style={buttonStyle}
            onClick={() => {
              setValue((oldVal) => {
                if (oldVal <= 0 || isNaN(oldVal)) {
                  return 0;
                } else if (oldVal > 0) {
                  return parseFloat(oldVal - 1);
                }
              });
            }}
          >
            -
          </button>
        </div>
        <CheckOutlined
          sx={{ cursor: "pointer", color: "green" }}
          onClick={() => handleClick()}
        />
        <CloseOutlined
          sx={{ cursor: "pointer", color: "red" }}
          onClick={() => {
            setPriceEdit("");
            setValue(0);
          }}
        />
      </div>
    </>
  );
}
