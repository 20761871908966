import {
  Button,
  Typography,
  Stack,
  TextField,
  Box,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { NavLink } from "react-router-dom";
import Block from "../../components/Block";
import DatePicker from "../../components/DatePicker";
import DataTable from "../../components/DataTable";
import Popconfirm from "../../components/Popconfirm";
import routeChangesTableColumns from "./routeChangesTableColumns";
import useAppSelector from "../../hooks/useAppSelector";
import { useCallback, useEffect } from "react";
import {
  deleteSeletedRouteChange,
  fetchRouteChanges,
  selectRouteChangeNumberOfPages,
  setRouteChangeToDelete,
} from "../../redux/module/routeChange";
import useAppDispatch from "../../hooks/useAppDispatch";
import { fetchDrivers } from "../../redux/module/driver";
import { fetchRoutes } from "../../redux/module/route";
import debounce from "lodash.debounce";
import { DEBOUNCE_VALUE } from "../../utils/constants";

const RoutesChangesOld = () => {
  const dispatch = useAppDispatch();
  const routeChanges = useAppSelector(
    (state) => state.routeChange.routeChanges
  );
  const page = useAppSelector((state) => state.routeChange.page);
  const pageSize = useAppSelector((state) => state.routeChange.pageSize);
  const filterBy = useAppSelector((state) => state.routeChange.filterBy);
  const routeChangesLoading = useAppSelector(
    (state) => state.routeChange.routeChangesLoading
  );
  const routeChangeToDelete = useAppSelector(
    (state) => state.routeChange.routeChangeToDelete
  );
  const routeChangeDeleteLoading = useAppSelector(
    (state) => state.routeChange.routeChangeDeleteLoading
  );
  const numberOfPages = useAppSelector(selectRouteChangeNumberOfPages);

  const fetchTableData = useCallback(
    (params) => {
      dispatch(fetchRouteChanges(params));
    },
    [dispatch]
  );

  const fetchTableDataDebounce = useCallback(
    debounce(
      (e) =>
        fetchTableData({
          filterBy: {
            ...filterBy,
            [e.target.name]: { filter: e.target.value },
          },
        }),
      DEBOUNCE_VALUE
    ),
    [fetchTableData, filterBy]
  );

  useEffect(() => {
    fetchTableData({ filterBy: {} });
  }, [fetchTableData]);

  useEffect(() => {
    dispatch(fetchRoutes());
    dispatch(fetchDrivers());
  }, [dispatch]);

  return (
    <Box maxWidth="1570px" margin={{ xs: "0 auto 15px", md: "30px auto" }}>
      <Popconfirm
        title="Are you sure?"
        onOk={() => dispatch(deleteSeletedRouteChange())}
        onCancel={() => dispatch(setRouteChangeToDelete(null))}
        loading={routeChangeDeleteLoading}
        show={!!routeChangeToDelete}
      />
      <Stack
        direction={{ xs: "column", sm: "row" }}
        alignItems={{ xs: "space-between", sm: "center" }}
        justifyContent="space-between"
        spacing={1.5}
        my="25px"
      >
        <Typography fontSize="23px" fontWeight={800} component="h2">
          Routes Changes
        </Typography>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          spacing="15px"
        >
          <Button
          className="btn-height"
            to="/routes/add-route-change"
            component={NavLink}
            variant="contained"
          >
            Add New
          </Button>
        </Stack>
      </Stack>

      <Block>
        <Stack
          direction="row"
          gap={{ xs: "15px", lg: "30px" }}
          flexWrap={{ xs: "wrap", xl: "nowrap" }}
          sx={{
            "& > div": { flex: "1 1 auto", minWidth: "auto" },
            "& > div:last-child": { minWidth: "fit-content" },
          }}
        >
          <Box>
            <Typography component="p" variant="label" mb="11px">
              Route
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              data-testid="test-route-id"
              name="route"
              onChange={fetchTableDataDebounce}
            />
          </Box>
          <Box>
            <Typography component="p" variant="label" mb="11px">
              Driver
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              data-testid="test-driver-id"
              name="driver"
              onChange={fetchTableDataDebounce}
            />
          </Box>
          <Box>
            <Typography component="p" variant="label" mb="11px">
              Vendor
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              data-testid="test-vendor-id"
              name="vendor"              
              onChange={fetchTableDataDebounce}
            />
          </Box>
          <Box>
            <Typography component="p" variant="label" mb="11px">
              Account
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              name="account"
              data-testid="test-account-id"
              onChange={fetchTableDataDebounce}
            />
          </Box>
          <Box>
            <Typography component="p" variant="label" mb="11px">
              Date
            </Typography>
            <Box sx={{ "& .MuiFormControl-root": { width: "100%" } }}>
              <DatePicker />
            </Box>
          </Box>
          <Box sx={{ maxWidth: "fit-content", flexShrink: 0 }}>
            <Typography component="p" variant="label" mb="11px">
              Delivery Type
            </Typography>
            <RadioGroup row name="delivery-type" defaultValue="all">
              <FormControlLabel value="all" control={<Radio />} label="All" />
              <FormControlLabel
                value="upcoming"
                control={<Radio />}
                label="Upcoming"
              />
              <FormControlLabel value="past" control={<Radio />} label="Past" />
            </RadioGroup>
          </Box>
        </Stack>
      </Block>

      <Box className="table-wrapper routes-table routes-changes">
        <DataTable
          rowData={routeChanges}
          loading={routeChangesLoading}
          columnDefs={routeChangesTableColumns}
          headerHeight={49}
          rowHeight={56}
          onSortChanged={(event) =>
            fetchTableData({
              orderBy: event.columnApi.columnModel.gridColumns,
            })
          }
          onFilterChanged={(event) =>
            fetchTableData({ filterBy: event.api.getFilterModel() })
          }
          page={page}
          pageSize={pageSize}
          setPageSize={(pageSize) => fetchTableData({ pageSize })}
          noPages={numberOfPages}
          setPage={(page) => fetchTableData({ page })}
        />
      </Box>
    </Box>
  );
};

export default RoutesChangesOld;
