import React, { useEffect, useRef, useState } from "react";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  ImageList,
  InputAdornment,
  InputLabel,
  Link,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import FileDropzone from "../../components/FileDropzone";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import Block from "../../components/Block";
import Separator from "../../components/Separator";
import DatePicker from "../../components/DatePicker2";
import {
  checkSkuAvailable,
  cloneVariant,
  createAccountTypeOverride,
  createCostChange,
  createVariantOverride,
  createVariantUpcharge,
  deleteAccountTypeVariantOverride,
  deleteCostChange,
  deleteProductVariant,
  deleteVariantOverride,
  deleteVariantUpcharge,
  getCostChanges,
  getProduct,
  getProductVariant,
  getVariantAccountsOverrides,
  getVariantAccountTypesOverrides,
  getVariantCustomerOverrides,
  getVariantTerritoriesOverrides,
  listVariantUpcharges,
  updateCostChange,
  updateProductVariant,
  updateVariantOverride,
  updateVariantUpcharge,
} from "../../distflowAPI/products";
import ProductOverrideAccordion from "./ProductOverrideAccordion";
import HtmlTooltip from "../../components/HtmlToolTip";
import dayjs from "dayjs";
import { createTag } from "../../redux/module/tag";
import useAppDispatch from "../../hooks/useAppDispatch";
import { SOLD_BY_OPTIONS } from "./consts";
import ProductImageListItem from "./ProductImageListItem";
import ConfirmDialog from "../../components/ConfirmDialog";
import debounce from "lodash.debounce";
import TagSelect from "../../components/AsyncSelects/TagSelect";
import AccountSelect from "../../components/AsyncSelects/AccountSelect";
import CustomerSelect from "../../components/AsyncSelects/CustomerSelect";
import TerritorySelect from "../../components/AsyncSelects/TerritorySelect";
import VariantOverrideItem from "./VariantOverrideItem";
import {
  fireErrorNotification,
  fireSuccessNotification,
  fixedNumber,
  validateNum,
} from "../../utils";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import { DATE_FORMAT, DATE_FORMAT_FRONT } from "../../utils/constants";
import ChangesHistory from "../../components/History";
import ProductOrdersHistory from "./ProductOrdersHistory";
import useAppSelector from "../../hooks/useAppSelector";
import AccountTypeSelect from "../../components/AsyncSelects/AccountTypeSelect";
import { smallButton } from "../../utils/smallButton";

const Variant = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id, vid } = useParams();
  const { productOrdersHistoryLoading } = useAppSelector(
    (state) => state.product
  );

  const [images, setImages] = useState([]);
  const [selectedTag, setSelectedTag] = useState([]);
  const [currImages, setCurrImages] = useState([]);
  const [productName, setProductName] = useState("");
  const [tagSearch, setTagSearch] = useState("");

  useEffect(() => {
    getProduct(id).then((res) => {
      setProductName(res.data.name);
    });
  }, []);
  const [name, setName] = useState("");
  const [additionalInfo, setAdditionalInfo] = useState("");
  const [sku, setSku] = useState(null);
  const [errors, setErrors] = useState({});
  const [cost, setCost] = useState("");
  const [isCostLocked, setIsCostLocked] = useState(true);
  const [price, setPrice] = useState("");
  const [soldByQty, setSoldByQty] = useState(1);
  const [costPc, setCostPc] = useState(0);
  const [breakIntoPieces, setBreakIntoPieces] = useState(false);
  const [pieceMinQty, setPieceMinQty] = useState(1);
  const [vendorItemId, setVendorItemId] = useState("");
  const [subClass, setSubClass] = useState("");
  const [customizable, setCustomizable] = useState(false);
  const [breakIntoPiecesSm, setBreakIntoPiecesSm] = useState(true);
  const [soldBy, setSoldBy] = useState({ value: "PC", label: "Select..." });
  const [perBox, setPerBox] = useState(0);
  const [barcode, setBarcode] = useState("");
  const [perRack, setPerRack] = useState(0);
  const [orderQTY, setOrderQTY] = useState(1);
  const [code, setCode] = useState("");
  const [weight, setWeight] = useState(0);
  const [weight2, setWeight2] = useState(0);
  const [sheet, setSheet] = useState(0);
  const [saleable, setSaleable] = useState(false);
  const [originalSKU, setOriginalSKU] = useState("");
  const [upcharges, setUpcharges] = useState({
    global_upcharge: 0,
    vendor_upcharge: 0,
    category_upcharge: 0,
    self_upcharge: 0,
  });
  const [sysMinQty, setSysMinQty] = useState(1);
  const [isVariantLoading, setIsVariantLoading] = useState(false);
  const [openCloneVariant, setOpenCloneVariant] = useState(false);
  const [isHidden, setIsHidden] = useState(false);
  const [isCustomerAvailable, setIsCustomerAvailable] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const soldByValMapper = {
    PC: "Piece",
    BX: "Box",
    BK: "Bucket",
    BT: "Batter",
    CT: "CT",
    DZ: "Dozen",
    EA: "Each",
    LB: "LB",
    PK: "Package",
    PL: "PL",
    SH: "Sheet",
  };

  const [weekDays, setWeekDays] = useState([
    { value: 0, key: "avail_mon", label: "Monday", short: "mon" },
    { value: 0, key: "avail_tue", label: "Tuesday", short: "tue" },
    { value: 0, key: "avail_wed", label: "Wednesday", short: "wed" },
    { value: 0, key: "avail_thu", label: "Thursday", short: "thu" },
    { value: 0, key: "avail_fri", label: "Friday", short: "fri" },
    { value: 0, key: "avail_sun", label: "Sunday", short: "sun" },
  ]);

  function fetchProductVariant() {
    setIsVariantLoading(true);
    getProductVariant(id, vid)
      .then((res) => {
        setName(res.data.name);
        setSelectedTag(
          res.data.tags.map((item) => ({ value: item.id, label: item.name }))
        );

        weekDays.forEach((day) => {
          const key = `avail_${day.short}`;
          console.log(key);
          if (res.data[key]) {
            setWeekDays((prev) =>
              prev.map((prevDay) =>
                prevDay.key === key
                  ? { ...prevDay, value: res.data[key] }
                  : prevDay
              )
            );
          }
        });

        setBarcode(res.data.barcode);
        setCost(res.data.cost);
        setPrice(res.data?.general_price);
        setIsHidden(res?.data?.hidden);
        setIsCustomerAvailable(res?.data?.customer_available);
        setBreakIntoPieces(res.data.break_into_pieces);
        setPieceMinQty(res.data.piece_min_qty);
        setBreakIntoPiecesSm(res.data.break_into_pieces_sp);
        setSoldByQty(res.data.sold_by_qty || 1);
        setCostPc(res.data.cost_pc || 0);
        setSku(res.data.sku);
        setOriginalSKU(res.data.sku);
        setSoldBy({
          value: res.data.sold_by,
          label: soldByValMapper[res.data.sold_by],
        });
        setCurrImages(res.data.images);
        setPerBox(res.data.per_box);
        setPerRack(res.data.per_pack);
        setOrderQTY(res.data.order_qty);
        setCode(res.data.code);
        setWeight(res.data.weight);
        setWeight2(res.data.weight_2);
        setSheet(res.data.sheet);
        setSaleable(res.data.saleable);
        setUpcharges({
          global_upcharge: res.data.global_upcharge,
          vendor_upcharge: res.data.vendor_upcharge.value,
          category_upcharge: res.data.category_upcharge.value,
          self_upcharge: res.data.self_upcharge.value,
        });
        setVendorItemId(res.data.vendor_item_id);
        setSubClass(res.data.sub_class);
        setCustomizable(res.data.customizable);
        setSysMinQty(res.data.sys_min_qty);
        setAdditionalInfo(res.data.additional_info);
      })
      .finally(() => {
        setIsVariantLoading(false);
      });
  }

  useEffect(() => fetchProductVariant(), []);

  const checkSku = useRef(
    debounce(async (sku) => {
      try {
        const available = await checkSkuAvailable(sku);
        const err = { ...errors };
        if (err.sku) {
          delete err.sku;
          setErrors(err);
        }
        if (!available && sku != originalSKU) {
          err[`sku`] = [`SKU "${sku}" Already exists`];
          setErrors(err);
        } else {
          setErrors(err);
        }
      } catch (e) {}
    }, 400)
  );

  async function handleSubmit(event) {
    setErrors({});
    setIsSubmitting(true);
    event.preventDefault();
    const formData = new FormData();
    formData.append("name", name);
    formData.append("additional_info", additionalInfo);
    if (selectedTag) {
      selectedTag.map((item) => formData.append("form_tags", item.value));
    }
    formData.append(`sku`, sku);
    formData.append(`cost`, cost);
    formData.append(`per_box`, Number(perBox));
    formData.append(`barcode`, barcode);
    formData.append(`sold_by`, soldBy.value);
    formData.append(`code`, code);
    formData.append(`saleable`, saleable);
    formData.append(`weight`, Number(weight));
    formData.append(`weight_2`, Number(weight2));
    formData.append(`per_pack`, Number(perRack));
    formData.append(`sheet`, Number(sheet));
    formData.append("order_qty", Number(orderQTY));
    formData.append("piece_min_qty", Number(pieceMinQty));
    formData.append("sold_by_qty", Number(soldByQty));
    formData.append("cost_pc", Number(costPc));
    formData.append("break_into_pieces", breakIntoPieces);
    formData.append("break_into_pieces_sp", breakIntoPiecesSm);
    formData.append("customizable", customizable);
    formData.append("vendor_item_id", vendorItemId);
    formData.append("sub_class", subClass);
    formData.append("sys_min_qty", sysMinQty);
    formData.append("hidden", isHidden);
    formData.append("customer_available", isCustomerAvailable);

    weekDays.map((day) => {
      formData.append(day.key, day.value);
    });

    if (images) {
      for (let im = 0; im < images.length; im++) {
        formData.append("form_images", images[im], images[im].name);
      }
    }

    try {
      const res = await updateProductVariant(id, vid, formData);
      await fetchProductVariant(); // fetch variant for update image value
      setImages([]); // clean image state because all images from backend in currImages
      fireSuccessNotification(`Product variant updated successfully`);
      setIsSubmitting(false);
    } catch (error) {
      setIsSubmitting(false);
      let messages = [];
      if (error.response && error.response.data) {
        setErrors(error.response.data);
      } else {
        messages = [error.message] || ["Something went wrong"];
        setErrors({ submit: messages });
      }
    }
  }

  const handleCreateTag = async () => {
    const res = await dispatch(createTag(tagSearch));
    const data = res.payload;
    setSelectedTag((prev) => [...prev, { value: data.id, label: data.name }]);
    setTagSearch("");
  };
  const [cleanOverrideFormFlag, setCleanOverrideFormFlag] = useState(false);
  const [cleanCostFormFlag, setCleanCostFormFlag] = useState(false);
  const [cleanVariantOverrideFlag, setCleanVariantOverrideFlag] =
    useState(false);

  const [isOpenDiscard, setIsOpenDiscard] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);

  const handleDiscard = () => {
    fetchProductVariant();
    setCleanOverrideFormFlag(true);
    setCleanCostFormFlag(true);
    setCleanVariantOverrideFlag(true);
  };

  const handleDelete = () => {
    deleteProductVariant(id, vid)
      .then(() => {
        fireSuccessNotification(`Product variant deleted successfully`);
        navigate(-1);
      })
      .catch(() => {
        fireErrorNotification(
          "Protected Error, you can't delete this product variant"
        );
      });
  };

  const handleChangeWeekDay = (e, key) => {
    const newWeekDays = weekDays.map((day) => {
      if (day.key === key) {
        return { ...day, value: e.target.checked ? 1 : 0 };
      }
      return day;
    });

    setWeekDays(newWeekDays);

    console.log(newWeekDays[0].value);
  };

  return (
    <Box margin=" 30px auto 0">
      <CloneVariant
        open={openCloneVariant}
        setOpen={setOpenCloneVariant}
        variantInfo={{
          sku: originalSKU,
          vid: vid,
          name: name,
          barcode: barcode,
          cost: cost,
          price: price,
          vendor_item_id: vendorItemId,
        }}
      />
      <Stack
        direction={{ xs: "column", md: "row" }}
        alignItems={{ xs: "flex-start", md: "center" }}
        justifyContent="space-between"
        spacing="20px"
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
          sx={{ width: { xs: "100%", sm: "initial" }, gap: "20px" }}
        >
          <Button
            onClick={() => navigate(-1)}
            variant="outlined"
            startIcon={<ArrowBackIcon />}
          >
            Back
          </Button>
          <Typography
            variant="pageTitle"
            component="p"
            data-testid="page-title"
          >
            <a href={`/products/${id}/`}>{productName}</a> &gt; {name}
          </Typography>
        </Stack>
        <Stack
          direction={{ sm: "row-reverse", md: "row" }}
          alignItems="center"
          justifyContent="flex-end"
          flexWrap="wrap"
          sx={{
            width: {
              xs: "100%",
              sm: "initial",
            },
            gap: "11px",
            "@media(max-width: 600px)": {
              flexDirection: "row-reverse",
              width: "100%",
            },
            "@media(max-width: 480px)": {
              flexDirection: "column-reverse",
              width: "100%",
              button: { width: "100%" },
            },
          }}
        >
          <ConfirmDialog
            title="Discard updates?"
            open={isOpenDiscard}
            setOpen={setIsOpenDiscard}
            onConfirm={handleDiscard}
          >
            {`Are you sure you want to discard all changes?`}
          </ConfirmDialog>
          <ConfirmDialog
            title="Delete user?"
            open={isOpenDelete}
            setOpen={setIsOpenDelete}
            onConfirm={handleDelete}
          >
            {`Are you sure you want to delete this product variant?`}
          </ConfirmDialog>
          <Button
            variant="contained"
            color="error"
            onClick={() => setIsOpenDelete(true)}
          >
            Delete
          </Button>
          <Button variant="outlined" onClick={() => setIsOpenDiscard(true)}>
            Discard
          </Button>
          <Button
            color="black"
            variant="contained"
            form="variantUpdate"
            onClick={(e) => handleSubmit(e)}
            disabled={isSubmitting}
          >
            Save & continue Editing
          </Button>
          <Button
            variant={"contained"}
            color={"primary"}
            onClick={() => setOpenCloneVariant(true)}
          >
            Clone Variant
          </Button>
        </Stack>
      </Stack>
      <Grid
        container
        columnSpacing={{ xs: 1, sm: 3, xl: "24px" }}
        margin="20px 0 0"
      >
        <Grid item md={7} lg={8} sx={{ maxWidth: "908px", width: "100%" }}>
          {Object.keys(errors).map((key) => (
            <Alert sx={{ mb: 1 }} severity="error">
              {key}: {errors[key][0]}
            </Alert>
          ))}
          <form id="variantUpdate">
            <Block isLoading={isVariantLoading}>
              <Grid>
                <Grid md={12}>
                  <Typography sx={{ textDecoration: "underline" }}>
                    General Info
                  </Typography>
                </Grid>
              </Grid>
              <Grid container mb={1} spacing={1}>
                <Grid
                  item
                  md={6}
                  lg={6}
                  sx={{ maxWidth: "908px", width: "100%" }}
                >
                  <Typography component="p" variant="label" mb={1}>
                    Name
                  </Typography>
                  <TextField
                    fullWidth
                    data-testid="test-name-id"
                    variant="outlined"
                    placeholder="Enter product's name"
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                    required
                  />
                </Grid>
                <Grid
                  item
                  md={3}
                  lg={3}
                  sx={{ maxWidth: "908px", width: "100%" }}
                >
                  <Typography component="p" variant="label" mb={1}>
                    SKU
                  </Typography>
                  <TextField
                    fullWidth
                    data-testid="test-product-sku-id"
                    variant="outlined"
                    placeholder="Enter product's sku"
                    value={sku}
                    onChange={(e) => {
                      checkSku.current(e.target.value);
                      setSku(e.target.value);
                    }}
                    required
                  />
                </Grid>
                <Grid
                  item
                  md={3}
                  lg={3}
                  sx={{ maxWidth: "908px", width: "100%" }}
                >
                  <Typography component="p" variant="label" mb={1}>
                    Barcode
                  </Typography>
                  <TextField
                    fullWidth
                    data-testid="test-barcode-id"
                    variant="outlined"
                    value={barcode}
                    onChange={(e) => {
                      setBarcode(e.target.value);
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container mb={1} spacing={1}>
                <Grid
                  item
                  md={3}
                  lg={3}
                  sx={{ maxWidth: "908px", width: "100%" }}
                >
                  <Typography component="p" variant="label" mb={1}>
                    Code
                  </Typography>
                  <TextField
                    fullWidth
                    data-testid="test-code-id"
                    variant="outlined"
                    value={code}
                    onChange={(e) => {
                      setCode(e.target.value);
                    }}
                  />
                </Grid>
                <Grid
                  item
                  md={3}
                  lg={3}
                  sx={{ maxWidth: "908px", width: "100%" }}
                >
                  <Typography component="p" variant="label" mb={1}>
                    Weight
                  </Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    data-testid="test-weigth1-id"
                    placeholder="Enter product's sku"
                    value={weight}
                    onChange={(e) => {
                      setWeight(e.target.value);
                    }}
                  />
                </Grid>
                <Grid
                  item
                  md={3}
                  lg={3}
                  sx={{ maxWidth: "908px", width: "100%" }}
                >
                  <Typography component="p" variant="label" mb={1}>
                    Weight 2
                  </Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    data-testid="test-weigth2-id"
                    placeholder="Enter product's sku"
                    value={weight2}
                    onChange={(e) => {
                      setWeight2(e.target.value);
                    }}
                  />
                </Grid>
                <Grid
                  item
                  md={3}
                  lg={3}
                  sx={{ maxWidth: "908px", width: "100%" }}
                >
                  <Typography component="p" variant="label" mb={1}>
                    Active?
                  </Typography>

                  <Select
                    fullWidth
                    required
                    name="variantSoldBy"
                    value={saleable}
                    onChange={(e) => {
                      setSaleable(e.target.value);
                    }}
                  >
                    <MenuItem value={true}>Active</MenuItem>
                    <MenuItem value={false}>InActive</MenuItem>
                  </Select>
                </Grid>
                <Grid
                  item
                  md={3}
                  lg={3}
                  sx={{ maxWidth: "908px", width: "100%" }}
                >
                  <Typography component="p" variant="label" mb={1}>
                    Sheet*
                  </Typography>
                  <TextField
                    fullWidth
                    data-testid="test-sheet-id"
                    variant="outlined"
                    type="number"
                    required
                    name="sheet"
                    value={sheet}
                    onChange={(e) => setSheet(e.target.value)}
                  />
                </Grid>
                <Grid item md="2">
                  <Typography component="p" variant="label" mb={1}>
                    System min qty
                  </Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    data-testid="test-min-qty-id"
                    name="minQty"
                    required
                    type="number"
                    value={sysMinQty}
                    onChange={(e) => setSysMinQty(e.target.value)}
                  />
                </Grid>
                <Grid item md="2">
                  <Typography component="p" variant="label" mb={1}>
                    Vendor item #
                  </Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    data-testid="test-min-qty-id"
                    name="minQty"
                    required
                    type="text"
                    value={vendorItemId}
                    onChange={(e) => setVendorItemId(e.target.value)}
                  />
                </Grid>
                <Grid item md="2">
                  <Typography component="p" variant="label" mb={1}>
                    Sub class
                  </Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    data-testid="test-min-qty-id"
                    name="minQty"
                    required
                    type="text"
                    value={subClass}
                    onChange={(e) => setSubClass(e.target.value)}
                  />
                </Grid>
                <Grid
                  item
                  md={3}
                  lg={3}
                  sx={{ maxWidth: "908px", width: "100%" }}
                >
                  <Typography component="p" variant="label" mb={1}>
                    Customizable?
                  </Typography>

                  <Select
                    fullWidth
                    required
                    name="variantSoldBy"
                    value={customizable}
                    onChange={(e) => {
                      setCustomizable(e.target.value);
                    }}
                  >
                    <MenuItem value={true}>Customizable</MenuItem>
                    <MenuItem value={false}>NonCustmizable</MenuItem>
                  </Select>
                </Grid>
              </Grid>
              <Separator />
              <Grid container mb={1} spacing={1}>
                <Grid
                  item
                  md={3}
                  lg={3}
                  sx={{
                    maxWidth: "908px",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography component="p" variant="label" mb={1}>
                    Sold by*
                  </Typography>
                  <Select
                    fullWidth
                    required
                    name="variantSoldBy"
                    value={soldBy.value}
                    onChange={(e) => {
                      setSoldBy(e.target);
                    }}
                  >
                    <MenuItem value="PC">Piece</MenuItem>
                    <MenuItem value="BX">Box</MenuItem>
                    <MenuItem value="BK">Bucket</MenuItem>
                    <MenuItem value="BT">Batter</MenuItem>
                    <MenuItem value="CT">Container</MenuItem>
                    <MenuItem value="DZ">Dozen</MenuItem>
                    <MenuItem value="EA">Each</MenuItem>
                    <MenuItem value="LB">Lb</MenuItem>
                    <MenuItem value="PK">Package</MenuItem>
                    <MenuItem value="PL">Pale / Bucket</MenuItem>
                    <MenuItem value="SH">Sheet</MenuItem>
                  </Select>
                </Grid>
                <Grid
                  item
                  md={SOLD_BY_OPTIONS[soldBy.value]?.multiple ? 3 : 6}
                  sx={{
                    maxWidth: "908px",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <Stack direction={"row"}>
                    <Typography component="p" variant="label" mb={1}>
                      {SOLD_BY_OPTIONS[soldBy.value]?.name} Cost*
                    </Typography>
                    <Button
                      onClick={() => setIsCostLocked(!isCostLocked)}
                      sx={smallButton}
                    >
                      {isCostLocked ? "Unlock" : "Lock"}
                    </Button>
                  </Stack>

                  <TextField
                    fullWidth
                    data-testid="test-variant-cost-id"
                    variant="outlined"
                    name="variantCost"
                    required
                    type="number"
                    inputProps={{ step: "0.1", lang: "en-US" }}
                    value={cost}
                    disabled={isCostLocked}
                    onChange={(e) => {
                      const parsed = parseFloat(e.target.value);
                      const newVal = parsed < 0 ? "" : parsed;
                      setCost(newVal);
                    }}
                  />
                </Grid>

                {SOLD_BY_OPTIONS[soldBy.value]?.multiple && (
                  <>
                    <Grid
                      item
                      md={3}
                      sx={{
                        maxWidth: "908px",
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography component="p" variant="label" mb={1}>
                        Items per {SOLD_BY_OPTIONS[soldBy.value].name}
                      </Typography>
                      <TextField
                        fullWidth
                        variant="outlined"
                        data-testid="test-variant-sold-by-qty-id"
                        name="variantSoldByQty"
                        required
                        type="number"
                        inputProps={{ step: "1", lang: "en-US" }}
                        value={soldByQty}
                        onChange={(e) => setSoldByQty(e.target.value)}
                      />
                    </Grid>
                    <Grid
                      item
                      md={3}
                      sx={{
                        maxWidth: "908px",
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography component="p" variant="label" mb={1}>
                        Cost PC
                      </Typography>
                      <TextField
                        fullWidth
                        variant="outlined"
                        data-testid="test-variant-sold-by-qty-id"
                        name="variantCostPc"
                        required
                        type="number"
                        inputProps={{ step: "1", lang: "en-US" }}
                        value={costPc}
                        onChange={(e) => setCostPc(e.target.value)}
                      />
                    </Grid>
                    <Grid
                      item
                      md={6}
                      sx={{
                        maxWidth: "908px",
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography component="p" variant="label" mb={1}>
                        Broken to pieces? (sales person)
                      </Typography>
                      <Switch
                        checked={breakIntoPiecesSm}
                        onChange={(event) =>
                          setBreakIntoPiecesSm(event.target.checked)
                        }
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    </Grid>
                    <Grid
                      item
                      md={6}
                      sx={{
                        maxWidth: "908px",
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography component="p" variant="label" mb={1}>
                        Broken to pieces? (client)
                      </Typography>
                      <Switch
                        checked={breakIntoPieces}
                        onChange={(event) =>
                          setBreakIntoPieces(event.target.checked)
                        }
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    </Grid>
                    {breakIntoPieces && (
                      <>
                        <Grid
                          item
                          md={3}
                          lg={3}
                          sx={{
                            maxWidth: "908px",
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography component="p" variant="label" mb={1}>
                            Cost per piece
                          </Typography>
                          <OutlinedInput
                            fullWidth
                            startAdornment={
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            }
                            value={
                              soldByQty > 0
                                ? fixedNumber(Number(cost / soldByQty))
                                : 0
                            }
                            disabled
                          />
                        </Grid>
                        <Grid
                          item
                          md={3}
                          lg={3}
                          sx={{
                            maxWidth: "908px",
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography component="p" variant="label" mb={1}>
                            Minimum qty
                          </Typography>
                          <TextField
                            fullWidth
                            variant="outlined"
                            data-testid="test-min-qty-id"
                            name="minQty"
                            required
                            type="number"
                            value={pieceMinQty}
                            onChange={(e) => setPieceMinQty(e.target.value)}
                          />
                        </Grid>
                      </>
                    )}
                  </>
                )}
              </Grid>
              <Grid container mb={1} spacing={1}>
                <Grid
                  item
                  md={3}
                  lg={3}
                  sx={{ maxWidth: "908px", width: "100%" }}
                >
                  <Typography component="p" variant="label" mb={1}>
                    Per Box*
                  </Typography>
                  <TextField
                    fullWidth
                    data-testid="test-per-box-id"
                    variant="outlined"
                    type="number"
                    name="perBox"
                    required
                    value={perBox}
                    onChange={(e) => setPerBox(e.target.value)}
                  />
                </Grid>
                <Grid
                  item
                  md={3}
                  lg={3}
                  sx={{ maxWidth: "908px", width: "100%" }}
                >
                  <Typography component="p" variant="label" mb={1}>
                    Per Rack*
                  </Typography>
                  <TextField
                    fullWidth
                    data-testid="test-per-pack-id"
                    variant="outlined"
                    type="number"
                    required
                    name="perRack"
                    value={perRack}
                    onChange={(e) => setPerRack(e.target.value)}
                  />
                </Grid>
                <Grid
                  item
                  md={3}
                  lg={3}
                  sx={{ maxWidth: "908px", width: "100%" }}
                >
                  <Typography variant="inputLabel" component="p" mb={1}>
                    Min Order QTY*
                  </Typography>
                  <TextField
                    fullWidth
                    data-testid="test-order-qty-id"
                    variant="outlined"
                    type="number"
                    required
                    name="orderQTY"
                    defaultValue={1}
                    value={orderQTY}
                    onChange={(e) => setOrderQTY(e.target.value)}
                  />
                </Grid>

                {/*
                <Grid
                  item
                  md={3}
                  lg={3}
                  sx={{ maxWidth: "908px", width: "100%" }}
                >
                  <Typography component="p" variant="label" mb={1}>
                    Order QTY*
                  </Typography>
                  <TextField
                    fullWidth
                    data-testid="test-order-qty-id"
                    variant="outlined"
                    type="number"
                    required
                    name="orderQTY"
                    value={orderQTY}
                    onChange={(e) => setOrderQTY(e.target.value)}
                  />
                </Grid>
                        */}

                <Grid item xs={12} sx={{ width: "100%" }}>
                  <Typography component="p" variant="label" mb={1}>
                    Additional info
                  </Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Enter additional info"
                    data-testid="test-additional-id"
                    value={additionalInfo}
                    onChange={(e) => {
                      setAdditionalInfo(e.target.value);
                    }}
                  />
                </Grid>
              </Grid>
            </Block>
            <Block isLoading={isVariantLoading}>
              <Typography component="p" variant="label" mb={1}>
                Media
              </Typography>
              <FileDropzone setImages={setImages} />
              <ImageList fullWidth cols={6} rowHeight={120} gap={15}>
                {[...currImages, ...images].map((item) => (
                  <ProductImageListItem
                    key={item}
                    productId={id}
                    variantId={vid}
                    item={item}
                    setImages={setImages}
                    fetchItem={fetchProductVariant}
                    type="variant"
                  />
                ))}
              </ImageList>
            </Block>
          </form>
          <Block isLoading={productOrdersHistoryLoading}>
            <Typography component="p" variant="label" mb="11px">
              Orders History
            </Typography>
            <Box sx={{ margin: { xs: "0 -16px -16px", sm: "0 -24px -24px" } }}>
              <ProductOrdersHistory type="variant" id={vid} />
            </Box>
          </Block>
        </Grid>

        <Grid item md={5} lg={4} sx={{ width: "100%" }}>
          <Block isLoading={isVariantLoading}>
            <Stack direction="row" justifyContent="space-between" mb="20px">
              <Typography component="p" variant="label">
                Availability
              </Typography>
            </Stack>
            <Stack direction="column" justifyContent="space-between" mb="20px">
              {weekDays &&
                weekDays.map((weekday) => (
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    key={weekday.key}
                    mb="10px"
                  >
                    <Typography component="p" variant="label">
                      {weekday.label}
                    </Typography>
                    <Switch
                      checked={weekday.value}
                      onChange={(e) => {
                        handleChangeWeekDay(e, weekday.key);
                      }}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </Stack>
                ))}
            </Stack>
          </Block>
          <Block isLoading={isVariantLoading}>
            <Stack direction="row" justifyContent="space-between" mb="20px">
              <Typography component="p" variant="label">
                Variant Tags
              </Typography>
              <Link to="/tags" component={NavLink}>
                Manage
              </Link>
            </Stack>
            <TagSelect
              multiple
              label="Variant Tags"
              id="multiple-limit-tags"
              value={selectedTag}
              onChange={(_event, newValue) => {
                setSelectedTag(newValue);
              }}
              setTagSearch={setTagSearch}
              noOptionsText={
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography>There is no such variant</Typography>
                  <Button onClick={handleCreateTag}>Create</Button>
                </Stack>
              }
            />
          </Block>
          <Block isLoading={isVariantLoading}>
            <Typography component="p" variant="label" mb="11px">
              Hidden?
            </Typography>
            <Select
              fullWidth
              value={isHidden}
              onChange={(e) => setIsHidden(e.target.value)}
            >
              <MenuItem value={true}>Yes</MenuItem>
              <MenuItem value={false}>No</MenuItem>
            </Select>
          </Block>
          <Block isLoading={isVariantLoading}>
            <Typography component="p" variant="label" mb="11px">
              Available to customers?
            </Typography>
            <Select
              fullWidth
              value={isCustomerAvailable}
              onChange={(e) => setIsCustomerAvailable(e.target.value)}
            >
              <MenuItem value={true}>Yes</MenuItem>
              <MenuItem value={false}>No</MenuItem>
            </Select>
          </Block>
          <Block isLoading={isVariantLoading}>
            {/*
            <Typography component="p" variant="label" mb={1}>
              Price Settings
            </Typography>
            <Typography component="p" fontWeight="bold" mb="5px">
              Upcharge Percentage
            </Typography>
            <Stack direction="row" alignItems="center" gap="10px">
              <Typography fontWeight="bold" sx={{ color: "#313131" }}>
                12.5%
              </Typography>
              <Link
                to=""
                component={NavLink}
                sx={{ fontSize: "13px", color: "#000" }}
              >
                View Breakdown
              </Link>
            </Stack>
            <Separator />
            <Typography component="p" fontWeight={600} mb="12px">
              Scheduled upcharge Changes
            </Typography>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>June 2 ‘23</TableCell>
                  <TableCell sx={{ flex: 1 }}>13%</TableCell>
                  <TableCell>
                    <IconButton>
                      <img src={EyeIcon} alt="" />
                    </IconButton>
                    <IconButton>
                      <EditOutlinedIcon fontSize={"small"} />
                    </IconButton>
                    <IconButton>
                      <DeleteForeverOutlinedIcon fontSize={"small"} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Separator />
              */}
            <Typography
              component="p"
              variant="label"
              mb="11px"
              //fontWeight="bold"
            >
              Price
            </Typography>
            <Typography component="p" variant="label" mb="11px">
              $
              {fixedNumber(
                Number(cost) +
                  (Number(cost) *
                    (Number(upcharges.category_upcharge) +
                      Number(upcharges.global_upcharge) +
                      Number(upcharges.vendor_upcharge) +
                      Number(upcharges.self_upcharge))) /
                    100
              )}
            </Typography>
            <Typography component="p" mb="5px" variant="label">
              Upcharge Percentage
            </Typography>
            <Stack direction="row" alignItems="center" gap="10px">
              <Typography sx={{ color: "#313131" }}>
                {fixedNumber(
                  Number(upcharges.category_upcharge) +
                    Number(upcharges.global_upcharge) +
                    Number(upcharges.vendor_upcharge) +
                    Number(upcharges.self_upcharge)
                )}
                %
              </Typography>

              <HtmlTooltip
                title={
                  <div>
                    <Typography color="inherit">
                      Category upcharge:{" "}
                      {fixedNumber(+upcharges.category_upcharge)}%
                    </Typography>
                    <Typography color="inherit">
                      Vendor upcharge: {fixedNumber(+upcharges.vendor_upcharge)}
                      %
                    </Typography>
                    <Typography color="inherit">
                      Global upcharge: {fixedNumber(+upcharges.global_upcharge)}
                      %
                    </Typography>
                    <Typography>
                      Variant upcharge: {fixedNumber(+upcharges.self_upcharge)}%
                    </Typography>
                  </div>
                }
              >
                <Link sx={{ fontSize: "13px", color: "#000" }}>
                  View Breakdown
                </Link>
              </HtmlTooltip>
            </Stack>
          </Block>
          <Block isLoading={isVariantLoading}>
            <VariantOverrideMenu
              upcharges={upcharges}
              setUpcharges={setUpcharges}
              cleanVariantOverrideFlag={cleanVariantOverrideFlag}
              setCleanVariantOverrideFlag={setCleanVariantOverrideFlag}
              fetchProductVariant={fetchProductVariant}
              cost={cost}
            />
          </Block>

          <CostChangesMenu
            cleanCostFormFlag={cleanCostFormFlag}
            setCleanCostFormFlag={setCleanCostFormFlag}
          />

          <OverridesMenu
            cleanOverrideFormFlag={cleanOverrideFormFlag}
            setCleanOverrideFormFlag={setCleanOverrideFormFlag}
            cost={cost}
            upcharges={upcharges}
          />

          {/*
          <Block>
            <Typography component="p" variant="label" mb="12px">
              Selling Restrictions
            </Typography>
            <Typography component="p" mb="12px">
              Restriction type
            </Typography>
            <RadioGroup
              row
              name="restriction-type"
              defaultValue="account"
              sx={{ my: "15px" }}
            >
              <FormControlLabel
                value="territory"
                control={<Radio />}
                label="Territory"
              />
              <FormControlLabel
                value="account"
                control={<Radio />}
                label="Account"
              />
              <FormControlLabel
                value="customer"
                control={<Radio />}
                label="Customer"
              />
              <FormControlLabel
                value="preorder"
                control={<Radio />}
                label="Preorder"
              />
              <FormControlLabel
                value="approval-required"
                control={<Radio />}
                label="Approval Required"
              />
            </RadioGroup>
            <Stack
              direction="row"
              alignItems="center"
              flexWrap="wrap"
              gap="13px"
            >
              <Typography
                noWrap
                fontSize="15px"
                sx={{ color: "#313131", flexShrink: 0 }}
              >
                Preorder Rule
              </Typography>
              <Stack
                direction="row"
                alignItems="center"
                sx={{
                  border: "1px solid #DBDFEA",
                  borderRadius: "5px",
                  backgroundColor: "#E5E9F2",
                  textAlign: "center",
                }}
              >
                <Typography
                  fontSize="13px"
                  sx={{ color: "#7E7E7E", width: "56px" }}
                >
                  Days
                </Typography>
                <TextField
                  inputProps={{
                    type: "number",
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                  }}
                  sx={{
                    width: "59px",
                    "& input": { textAlign: "center" },
                    "& .MuiOutlinedInput-root": { borderRadius: 0 },
                  }}
                />
                <Typography
                  fontSize="13px"
                  sx={{ color: "#7E7E7E", width: "56px" }}
                >
                  Time
                </Typography>
                <Box
                  sx={{
                    backgroundColor: "#fff",
                    height: "44px",
                    display: "flex",
                    borderRadius: "0 5px 5px 0",
                    overflow: "hidden",
                  }}
                >
                  <input type="time" className="time-input" />
                </Box>
              </Stack>
            </Stack>
            <Stack direction="row" flexWrap="wrap" gap="10px" mt="15px">
              <Button variant="gray" sx={{ padding: "6px 10px" }}>
                Add Additional Restrictions
              </Button>
              <Button variant="contained">Save Restrictions</Button>
            </Stack>

            <Separator />

            <Typography component="p" fontWeight={600} mb="12px">
              Restrictions
            </Typography>
            <Table>
              <TableBody>
                {[
                  "Evergreen Monsey",
                  "Flatbush",
                  "Requires 2 day prior notice by 4pm",
                  "Approval Required",
                ].map((row, i) => (
                  <TableRow key={i}>
                    <TableCell>{row}</TableCell>
                    <TableCell>
                      <IconButton>
                        <img src={EyeIcon} alt="" />
                      </IconButton>
                      <IconButton>
                        <EditOutlinedIcon fontSize={"small"} />
                      </IconButton>
                      <IconButton>
                        <DeleteForeverOutlinedIcon fontSize={"small"} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Block>
          */}
        </Grid>
      </Grid>
    </Box>
  );
};

export default Variant;

function OverridesMenu({
  cleanOverrideFormFlag,
  setCleanOverrideFormFlag,
  upcharges,
  cost,
}) {
  const [customerGroups, setCustomerGroups] = useState([]);
  const [customerGroupSearch, setCustomerGroupSearch] = useState("");
  const totalOveerides =
    Number(upcharges.category_upcharge) +
    Number(upcharges.global_upcharge) +
    Number(upcharges.vendor_upcharge) +
    Number(upcharges.self_upcharge);
  const currentPrice = Number(cost) + (Number(cost) * totalOveerides) / 100;
  const [targetPrice, setTargetPrice] = useState(
    Number(cost) +
      (Number(cost) *
        (Number(upcharges.category_upcharge) +
          Number(upcharges.global_upcharge) +
          Number(upcharges.vendor_upcharge) +
          Number(upcharges.self_upcharge))) /
        100
  );
  useEffect(() => {
    const priceDifference =
      parseFloat(parseFloat(targetPrice).toFixed(2)) -
      parseFloat(parseFloat(currentPrice).toFixed(2));
    const newUpcharge = priceDifference / cost;
    setOverride((newUpcharge * 100).toFixed(2));
  }, [targetPrice]);

  const { id, vid } = useParams();
  const [overrideType, setOverrideType] = useState("account");
  const [override, setOverride] = useState(0);
  const [selectedVariantAccountOverride, setSelectedVariantAccountOverride] =
    useState({ label: "", value: null });
  const [selectedVariantCustomerOverride, setSelectedVariantCustomerOverride] =
    useState({ label: "", value: null });
  const [
    selectedVariantTerritoryOverride,
    setSelectedVariantTerritoryOverride,
  ] = useState({ label: "", value: null });
  const [
    selectedVariantAccountTypeOverride,
    setSelectedVariantAccountTypeOverride,
  ] = useState({
    label: "",
    value: null,
  });
  const [overrideSubmitting, setOverrideSubmitting] = useState(false);
  const [overrideError, setOverrideError] = useState("");
  const [overrideSuccess, setOverrideSuccess] = useState("");
  const [overrideStartDate, setOverrideStartDate] = useState(dayjs());
  const [overrideEndDate, setOverrideEndDate] = useState();
  const [isVariantOverridesLoading, setIsVariantOverridesLoading] =
    useState(false);

  const cleanProductVariantOverrideForm = () => {
    setOverrideSubmitting(false);
    setOverride(0);
    setOverrideStartDate(null);
    setOverrideEndDate(null);
    setSelectedVariantAccountOverride([]);
    setSelectedVariantCustomerOverride([]);
    setSelectedVariantTerritoryOverride([]);
  };

  async function submitOverride(event) {
    setOverrideError("");
    setOverrideSuccess("");
    setOverrideSubmitting(true);
    event.preventDefault();
    if (!override) {
      setOverrideError({ error: ["Input a valid override, " + override] });
      setOverrideSubmitting(false);
      return;
    }
    const start_date = overrideStartDate?.isValid()
      ? overrideStartDate?.format(DATE_FORMAT)
      : null;
    const end_date = overrideEndDate?.isValid()
      ? overrideEndDate?.format(DATE_FORMAT)
      : null;
    const payload = {
      tp: overrideType,
      override,
      start_date,
      end_date,
      variant: vid,
    };
    if (overrideType == "customer") {
      payload["customer"] = selectedVariantCustomerOverride.value;
      createVariantOverride(id, vid, payload).then(
        () => updateCustomerOverrides(),
        (error) => setOverrideError(error.response.data)
      );
    }
    if (overrideType == "account") {
      payload["account"] = selectedVariantAccountOverride.value;
      createVariantOverride(id, vid, payload).then(
        () => updateAccountOverrides(),
        (error) => setOverrideError(error.response.data)
      );
    }
    if (overrideType == "territory") {
      payload["territory"] = selectedVariantTerritoryOverride.value;
      createVariantOverride(id, vid, payload).then(
        () => updateTerritoriesOverrides(),
        (error) => setOverrideError(error.response.data)
      );
    }
    if (overrideType == "account_type") {
      payload["account_type"] = selectedVariantAccountTypeOverride.value;
      createAccountTypeOverride(id, vid, payload).then(
        () => updateAccountTypeOverrides(),
        (error) => setOverrideError(error.response.data)
      );
    }
    cleanProductVariantOverrideForm();
    //append created data to list
  }

  useEffect(() => {
    if (cleanOverrideFormFlag) {
      cleanProductVariantOverrideForm();
      setCleanOverrideFormFlag(false);
    }
  }, [cleanOverrideFormFlag]);

  const [currentAccountsOverride, setCurrentAccountsOverrides] = useState({});
  const [currentCustomersOverrides, setCurrentCustomersOverrides] = useState(
    []
  );
  const [currentAccountTypesOverride, setCurrentAccountTypesOverride] =
    useState([]);

  function normalizeOverridesResponse(overrides, key) {
    const results = {};
    overrides.map((item) => {
      if (results[item[key]]) {
        results[item[key]]["items"].push(item);
      } else {
        results[item[key]] = {
          [`${key}_name`]: item[`${key}_name`],
          items: [item],
        };
      }
    });
    return results;
  }

  const [currentTerritoriesOverrides, setCurrentTerritoriesOverrides] =
    useState([]);

  function updateAccountOverrides() {
    getVariantAccountsOverrides(id, vid).then((res) =>
      setCurrentAccountsOverrides(
        normalizeOverridesResponse(res.results, "account")
      )
    );
  }

  function updateCustomerOverrides() {
    getVariantCustomerOverrides(id, vid).then((res) =>
      setCurrentCustomersOverrides(
        normalizeOverridesResponse(res.results, "customer")
      )
    );
  }

  function updateTerritoriesOverrides() {
    getVariantTerritoriesOverrides(id, vid).then((res) =>
      setCurrentTerritoriesOverrides(
        normalizeOverridesResponse(res.results, "territory")
      )
    );
  }

  function updateAccountTypeOverrides() {
    getVariantAccountTypesOverrides(id, vid).then((res) =>
      setCurrentAccountTypesOverride(
        normalizeOverridesResponse(res.results, "account_type")
      )
    );
  }

  useEffect(() => {
    async function fetchData() {
      try {
        setIsVariantOverridesLoading(true);

        const [accountsRes, customersRes, territoriesRes, accTypeRes] =
          await Promise.all([
            getVariantAccountsOverrides(id, vid),
            getVariantCustomerOverrides(id, vid),
            getVariantTerritoriesOverrides(id, vid),
            getVariantAccountTypesOverrides(id, vid),
          ]);

        setCurrentAccountsOverrides(
          normalizeOverridesResponse(accountsRes.results, "account")
        );
        setCurrentCustomersOverrides(
          normalizeOverridesResponse(customersRes.results, "customer")
        );
        setCurrentTerritoriesOverrides(
          normalizeOverridesResponse(territoriesRes.results, "territory")
        );
        setCurrentAccountTypesOverride(
          normalizeOverridesResponse(accTypeRes.results, "account_type")
        );
        setIsVariantOverridesLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsVariantOverridesLoading(false);
      }
    }

    fetchData();
  }, []);

  const [variantOverrideSubmitting, setVariantOverrideSubmitting] =
    useState(false);

  function submitVariantUpcharge() {}

  return (
    <Block isLoading={isVariantOverridesLoading}>
      {Object.keys(overrideError).map((key) => (
        <Alert sx={{ mb: 1 }} severity="error">
          {key}: {overrideError[key][0]}
        </Alert>
      ))}
      {overrideSuccess && (
        <Alert severity="success" sx={{ mt: 2, mb: 2 }}>
          {overrideSuccess}
        </Alert>
      )}
      <Typography component="p" fontWeight={600} mb="12px">
        Advanced Override
      </Typography>
      <form id="overrideCreation" onSubmit={submitOverride}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Override Type</InputLabel>
          <Select
            value={overrideType}
            onChange={(event) => {
              setOverrideType(event.target.value);
            }}
            label="Override Type"
            sx={{ mb: 2 }}
          >
            <MenuItem value="territory">Territory</MenuItem>
            <MenuItem value="account">Account</MenuItem>
            <MenuItem value="customer">Customer</MenuItem>
            <MenuItem value="account_type">Account Type</MenuItem>
          </Select>
        </FormControl>
        <Grid container spacing={2} sx={{ marginBottom: "5px" }}>
          <Grid item xs={12}>
            {overrideType == "account" && (
              <AccountSelect
                name="account"
                label="Account"
                required
                value={selectedVariantAccountOverride}
                onChange={(_event, newValue) => {
                  setSelectedVariantAccountOverride(null);
                  if (newValue) {
                    if (newValue.value) {
                      setSelectedVariantAccountOverride(newValue);
                    }
                  }
                }}
              />
            )}
            {overrideType == "customer" && (
              <CustomerSelect
                name="customer"
                label="Customer"
                required
                value={selectedVariantCustomerOverride}
                onChange={(_event, newValue) => {
                  setSelectedVariantCustomerOverride(null);
                  if (newValue) {
                    if (newValue.value) {
                      setSelectedVariantCustomerOverride(newValue);
                    }
                  }
                }}
              />
            )}
            {overrideType == "territory" && (
              <TerritorySelect
                name="customer"
                label="Territory"
                required
                value={selectedVariantTerritoryOverride}
                onChange={(_event, newValue) => {
                  setSelectedVariantTerritoryOverride(null);
                  if (newValue) {
                    if (newValue.value) {
                      setSelectedVariantTerritoryOverride(newValue);
                    }
                  }
                }}
              />
            )}
            {overrideType == "account_type" && (
              <AccountTypeSelect
                name="account_type"
                label="AccountType"
                required
                value={selectedVariantAccountTypeOverride}
                onChange={(_event, newValue) => {
                  setSelectedVariantAccountTypeOverride(null);
                  if (newValue) {
                    if (newValue.value) {
                      setSelectedVariantAccountTypeOverride(newValue);
                    }
                  }
                }}
              />
            )}
          </Grid>
          <Grid item xs={6}>
            <FormControl variant="outlined">
              <OutlinedInput
                endAdornment={
                  <InputAdornment position="start">target $</InputAdornment>
                }
                size="small"
                name="targetPrice"
                type="number"
                sx={{
                  "& .MuiInputBase-input.MuiOutlinedInput-input": {
                    padding: "9px 12px 8px",
                  },
                }}
                onChange={(e) =>
                  setTargetPrice(validateNum(targetPrice, e.target.value))
                }
                value={targetPrice}
                label={"target price"}
                required
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl variant="outlined">
              <OutlinedInput
                endAdornment={
                  <InputAdornment position="end">corresp. %</InputAdornment>
                }
                size="small"
                name="override"
                type="number"
                sx={{
                  "& .MuiInputBase-input.MuiOutlinedInput-input": {
                    padding: "9px 12px 8px",
                  },
                }}
                onChange={(e) => setOverride(e.target.value)}
                value={override}
                required
                disabled
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <DatePicker
              size="small"
              controlledValue={overrideStartDate}
              setControlledValue={setOverrideStartDate}
            />
          </Grid>
          <Grid item xs={6}>
            <DatePicker
              size="small"
              controlledValue={overrideEndDate}
              setControlledValue={setOverrideEndDate}
            />
          </Grid>
        </Grid>
        <Stack direction="row" flexWrap="wrap" gap="10px" mt="15px">
          <Button
            variant="contained"
            type="submit"
            disabled={overrideSubmitting}
          >
            Save Override
          </Button>
        </Stack>
      </form>
      {currentTerritoriesOverrides && (
        <div>
          <Separator />
          <Typography component="p" fontWeight={600} mb="12px">
            Territory Overrides
          </Typography>
          <Box sx={{ width: "100%", overflow: "auto" }}>
            {Object.keys(currentTerritoriesOverrides).map((key) => (
              <ProductOverrideAccordion
                overrides={currentTerritoriesOverrides}
                elementId={key}
                elementKey={"territory"}
                productId={id}
                variantId={vid}
                deleteOverride={deleteVariantOverride}
                updateOverride={updateVariantOverride}
                updateOverridesTable={updateTerritoriesOverrides}
                upcharges={upcharges}
                cost={cost}
              />
            ))}
          </Box>
        </div>
      )}
      {currentCustomersOverrides && (
        <div>
          <Separator />
          <Typography component="p" fontWeight={600} mb="12px">
            Customer Overrides
          </Typography>
          <Box sx={{ width: "100%", overflow: "auto" }}>
            {Object.keys(currentCustomersOverrides).map((key) => (
              <ProductOverrideAccordion
                overrides={currentCustomersOverrides}
                elementId={key}
                elementKey={"customer"}
                productId={id}
                variantId={vid}
                deleteOverride={deleteVariantOverride}
                updateOverride={updateVariantOverride}
                updateOverridesTable={updateCustomerOverrides}
                upcharges={upcharges}
                cost={cost}
              />
            ))}
          </Box>
        </div>
      )}
      {currentAccountTypesOverride && (
        <div>
          <Separator />
          <Typography component="p" fontWeight={600} mb="12px">
            Account Type Overrides {currentAccountTypesOverride.length}
          </Typography>
          <Box sx={{ width: "100%", overflow: "auto" }}>
            {Object.keys(currentAccountTypesOverride).map((key) => (
              <ProductOverrideAccordion
                overrides={currentAccountTypesOverride}
                upcharges={upcharges}
                cost={cost}
                elementId={key}
                elementKey={"account_type"}
                productId={id}
                variantId={vid}
                deleteOverride={deleteAccountTypeVariantOverride}
                updateOverride={updateVariantOverride}
                updateOverridesTable={updateAccountTypeOverrides}
              />
            ))}
          </Box>
        </div>
      )}
      {currentAccountsOverride && (
        <div>
          <Separator />
          <Typography component="p" fontWeight={600} mb="12px">
            Account Overrides
          </Typography>
          <Box sx={{ width: "100%", overflow: "auto" }}>
            {Object.keys(currentAccountsOverride).map((key) => (
              <ProductOverrideAccordion
                overrides={currentAccountsOverride}
                upcharges={upcharges}
                cost={cost}
                elementId={key}
                elementKey={"account"}
                productId={id}
                variantId={vid}
                deleteOverride={deleteVariantOverride}
                updateOverride={updateVariantOverride}
                updateOverridesTable={updateAccountOverrides}
              />
            ))}
          </Box>
        </div>
      )}
    </Block>
  );
}

function VariantOverrideMenu({
  upcharges,
  setUpcharges,
  cleanVariantOverrideFlag,
  setCleanVariantOverrideFlag,
  fetchProductVariant,
  cost,
}) {
  const { id, vid } = useParams();
  const [override, setOverride] = useState(0);
  const [overrideStartDate, setOverrideStartDate] = useState();
  const [overrideEndDate, setOverrideEndDate] = useState();
  const [variantOverrideSubmitting, setVariantOverrideSubmitting] =
    useState(false);
  const [variantOverrides, setVariantOverrides] = useState([]);
  const [variantOverrideError, setVariantOverrideError] = useState({});
  const [variantSuccess, setVariantSuccess] = useState("");
  const [targetPrice, setTargetPrice] = useState(0);
  const currentPrice = Number(cost) + (Number(cost) * 0) / 100;
  useEffect(() => {
    const priceDifference =
      parseFloat(parseFloat(targetPrice).toFixed(2)) -
      parseFloat(parseFloat(currentPrice).toFixed(2));
    const newUpcharge = priceDifference / cost;
    setOverride((newUpcharge * 100).toFixed(2));
  }, [targetPrice]);

  function updateOverrides(overrides) {
    const currentOverride = overrides.filter((o) => o.is_current)[0];
    if (currentOverride && currentOverride.override) {
      console.log("new upcharges: ", {
        ...upcharges,
        self_upcharge: currentOverride.override,
      });
      setUpcharges({
        ...upcharges,
        self_upcharge: Number(currentOverride.override) || 0,
      });
    }
  }

  const cleanFormFields = () => {
    setOverride(0);
    setOverrideStartDate(null);
    setOverrideEndDate(null);
  };

  function submitVariantUpcharge(e) {
    e.preventDefault();
    setVariantOverrideError({});
    createVariantUpcharge(vid, {
      variant: vid,
      override: override,
      start_date: overrideStartDate?.isValid()
        ? overrideStartDate?.format(DATE_FORMAT)
        : null,
      end_date: overrideEndDate?.isValid()
        ? overrideEndDate?.format(DATE_FORMAT)
        : null,
    }).then(
      (res) => {
        fireSuccessNotification(`Override created successfully`);
        updateVariantOverrides();
        cleanFormFields();
      },
      (error) => {
        const errorFields = Object.keys(error.response.data);
        errorFields.forEach((field) => {
          const errorType = typeof error.response.data[field];
          const errorText =
            errorType === "string"
              ? error.response.data[field]
              : error.response.data[field][0];
          fireErrorNotification(`${field}: ${errorText}`);
        });
      }
    );
  }

  function updateVariantOverrides() {
    listVariantUpcharges(vid).then((res) => {
      setVariantOverrides(res.data.results);
      updateOverrides(res.data.results);
    });
  }

  useEffect(() => {
    updateVariantOverrides();
  }, []);

  useEffect(() => {
    if (cleanVariantOverrideFlag) {
      cleanFormFields();
      setCleanVariantOverrideFlag(false);
    }
  }, [cleanVariantOverrideFlag]);

  function deleteVU(vid, oid) {
    deleteVariantUpcharge(vid, oid).then((success) => {
      updateVariantOverrides();
      fetchProductVariant();
    });
  }

  function updateVU(vid, oid, payload, onClose) {
    updateVariantUpcharge(vid, oid, payload)
      .then((success) => {
        updateVariantOverrides();
        fetchProductVariant();
        fireSuccessNotification(`Variant upcharge updated successfully`);
        onClose();
      })
      .catch((error) => {
        const errorFields = Object.keys(error.response.data);
        errorFields.forEach((field) => {
          fireErrorNotification(error.response.data[field][0]);
        });
      });
  }

  return (
    <>
      {Object.keys(variantOverrideError).map((key) => (
        <Alert sx={{ mb: 1 }} severity="error">
          {key}: {variantOverrideError[key][0]}
        </Alert>
      ))}
      {variantSuccess && (
        <Alert severity="success" sx={{ mt: 2, mb: 2 }}>
          {variantSuccess}
        </Alert>
      )}
      <Typography component="p" fontWeight={600} mb="12px">
        Variant Upcharge
      </Typography>
      <form id="variantUpchargeCreation" onSubmit={submitVariantUpcharge}>
        <Grid container spacing={2} sx={{ marginBottom: "5px" }}>
          <Grid item xs={6}>
            <FormControl variant="outlined">
              <OutlinedInput
                endAdornment={
                  <InputAdornment position="start">target $</InputAdornment>
                }
                size="small"
                name="targetPrice"
                type="number"
                sx={{
                  "& .MuiInputBase-input.MuiOutlinedInput-input": {
                    padding: "9px 12px 8px",
                  },
                }}
                onChange={(e) =>
                  setTargetPrice(validateNum(targetPrice, e.target.value))
                }
                value={targetPrice}
                label={"target price"}
                required
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl variant="outlined">
              <OutlinedInput
                endAdornment={
                  <InputAdornment position="end">corresp. %</InputAdornment>
                }
                size="small"
                name="override"
                type="number"
                sx={{
                  "& .MuiInputBase-input.MuiOutlinedInput-input": {
                    padding: "9px 12px 8px",
                  },
                }}
                onChange={(e) => setOverride(e.target.value)}
                value={override}
                required
                disabled
              />
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <DatePicker
              size="small"
              controlledValue={overrideStartDate}
              setControlledValue={setOverrideStartDate}
            />
          </Grid>
          <Grid item xs={6}>
            <DatePicker
              size="small"
              controlledValue={overrideEndDate}
              setControlledValue={setOverrideEndDate}
            />
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="contained"
              type="submit "
              disabled={variantOverrideSubmitting}
            >
              Save Override
            </Button>
          </Grid>
          <Grid item md={12}>
            <Box sx={{ overflow: "auto" }}>
              <Table>
                <TableBody>
                  {variantOverrides.map((override) => (
                    <VariantOverrideItem
                      override={override}
                      deleteVU={deleteVU}
                      updateVU={updateVU}
                      vid={vid}
                      cost={cost}
                    />
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Grid>
        </Grid>
      </form>
    </>
  );
}

function CostChangesMenu({ cleanCostFormFlag, setCleanCostFormFlag }) {
  const { id, vid } = useParams();
  const [costChanges, setCostChanges] = useState([]);
  const [costChangeForm, setCostChangeForm] = useState({
    variant: vid,
    cost: "",
    start_date: null,
  });
  const [costChangeSuccess, setCostChangeSuccess] = useState("");
  const [costChangeError, setCostChangeError] = useState({});
  const [costChangeSubmitting, setCostChangeSubmitting] = useState(false);
  const [isCostChangesLoading, setIsCostChangesLoading] = useState(false);

  async function submitCostChange(event) {
    setCostChangeSubmitting(true);
    event.preventDefault();
    const payload = { ...costChangeForm };
    payload["start_date"] = payload["start_date"]?.isValid()
      ? payload["start_date"]?.format(DATE_FORMAT)
      : null;
    await createCostChange(id, vid, payload).then(
      (result) => {
        setCostChangeSuccess("Cost change schedule added successfully");
        loadCostChanges();
        setCostChangeForm({
          variant: vid,
          cost: "",
          start_date: null,
        });
      },
      (error) => setCostChangeError(error.response.data)
    );
    setCostChangeSubmitting(false);
  }

  useEffect(() => {
    loadCostChanges();
  }, []);

  function loadCostChanges() {
    setIsCostChangesLoading(true);
    getCostChanges(id, vid)
      .then((result) => setCostChanges(result.results))
      .finally(() => {
        setIsCostChangesLoading(false);
      });
  }

  useEffect(() => {
    if (cleanCostFormFlag) {
      setCostChangeForm({
        variant: vid,
        cost: "",
        start_date: null,
      });
      setCleanCostFormFlag(false);
    }
  }, [cleanCostFormFlag]);

  return (
    <Block isLoading={isCostChangesLoading}>
      {Object.keys(costChangeError).map((key) => (
        <Alert sx={{ mb: 1 }} severity="error">
          {key}: {costChangeError[key][0]}
        </Alert>
      ))}
      {costChangeSuccess && (
        <Alert severity="success" sx={{ mt: 2, mb: 2 }}>
          {costChangeSuccess}
        </Alert>
      )}
      <Typography component="p" fontWeight={600} mb="12px">
        Cost change schedule
      </Typography>
      <form id="costChangeCreation" onSubmit={submitCostChange}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography>New cost</Typography>
            <OutlinedInput
              startAdornment={
                <InputAdornment position="start">$</InputAdornment>
              }
              size="small"
              type="number"
              name="override"
              sx={{
                "& .MuiInputBase-input.MuiOutlinedInput-input": {
                  padding: "6px 12px 6px 0",
                },
              }}
              onChange={(e) => {
                const parsed = parseFloat(e.target.value);
                const cost = parsed < 0 ? "" : parsed;
                setCostChangeForm({ ...costChangeForm, cost });
              }}
              value={costChangeForm.cost}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <Typography>Start date</Typography>
            <DatePicker
              size="small"
              controlledValue={costChangeForm.start_date}
              setControlledValue={(newValue) => {
                setCostChangeForm({ ...costChangeForm, start_date: newValue });
              }}
            />
          </Grid>
        </Grid>
        <Stack direction="row" flexWrap="wrap" gap="10px" mt="15px">
          <Button
            variant="contained"
            type="submit "
            disabled={costChangeSubmitting}
          >
            Save Cost change
          </Button>
        </Stack>
      </form>
      {
        <Table sx={{ marginTop: "10px" }}>
          <TableBody>
            {costChanges.map((costChange) => (
              <CostChangeRow
                costChange={costChange}
                loadCostChanges={loadCostChanges}
                setCostChangeError={setCostChangeError}
                setCostChangeSuccess={setCostChangeSuccess}
              />
            ))}
          </TableBody>
        </Table>
      }
    </Block>
  );
}

function CostChangeRow({
  costChange,
  loadCostChanges,
  setCostChangeError,
  setCostChangeSuccess,
}) {
  const { id, vid } = useParams();
  const [costChangeSubmitting, setCostChangeSubmitting] = useState(false);
  const [edit, setEdit] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [costChangeForm, setCostChangeForm] = useState(costChange);

  function submitCostChange(event) {
    setCostChangeSuccess("");
    setCostChangeError({});
    setCostChangeSubmitting(true);
    event.preventDefault();
    const payload = { ...costChangeForm };
    payload["start_date"] =
      typeof payload["start_date"] == "string"
        ? payload["start_date"]
        : payload["start_date"]?.isValid()
          ? payload["start_date"]?.format(DATE_FORMAT)
          : null;
    payload["cid"] = payload["id"];
    updateCostChange(id, vid, payload["id"], payload).then(
      (result) => {
        setCostChangeSuccess("Cost change schedule updated successfully");
        loadCostChanges();
        setEdit(false);
      },
      (error) => setCostChangeError(error.response.data)
    );
    setCostChangeSubmitting(false);
  }

  function handleDelete() {
    setCostChangeSuccess("");
    setCostChangeError({});
    deleteCostChange(id, vid, costChangeForm["id"]).then(
      (result) => {
        setCostChangeSuccess("Cost change schedule deleted successfully");
        loadCostChanges();
      },
      (error) => setCostChangeError(error.response.data)
    );
  }

  return (
    <TableRow>
      {!edit && (
        <>
          <ConfirmDialog
            title="Delete cost change?"
            open={isOpenDelete}
            setOpen={setIsOpenDelete}
            onConfirm={handleDelete}
          >
            {`Are you sure you want to delete this cost change?`}
          </ConfirmDialog>
          <TableCell>
            {costChange.start_date
              ? dayjs.utc(costChange.start_date).format(DATE_FORMAT_FRONT)
              : ""}
          </TableCell>
          <TableCell>${costChange.cost}</TableCell>
          <TableCell>
            <IconButton onClick={() => setEdit(true)}>
              <EditOutlinedIcon fontSize={"small"} />
            </IconButton>
            <IconButton onClick={() => setIsOpenDelete(true)}>
              <DeleteForeverOutlinedIcon fontSize={"small"} />
            </IconButton>
            <ChangesHistory
              historyData={costChange.history || []}
              fontSize={"small"}
            />
          </TableCell>
        </>
      )}
      {edit && (
        <>
          <TableCell width={"50%"}>
            <DatePicker
              size="small"
              controlledValue={
                typeof costChangeForm.start_date == "string"
                  ? dayjs.utc(costChangeForm.start_date)
                  : costChangeForm.start_date
              }
              setControlledValue={(newValue) => {
                setCostChangeForm({ ...costChangeForm, start_date: newValue });
              }}
            />
          </TableCell>
          <TableCell width={"40%"}>
            <OutlinedInput
              startAdornment={
                <InputAdornment position="start">$</InputAdornment>
              }
              size="small"
              name="override"
              onChange={(e) =>
                setCostChangeForm({ ...costChangeForm, cost: e.target.value })
              }
              value={costChangeForm.cost}
              required
            />
          </TableCell>
          <TableCell width={"10%"}>
            <Button
              variant="contained"
              type="button "
              onClick={(event) => submitCostChange(event)}
              disabled={costChangeSubmitting}
            >
              Save
            </Button>
          </TableCell>
        </>
      )}
    </TableRow>
  );
}

function CloneVariant({ open, setOpen, variantInfo }) {
  const [name, setName] = useState(variantInfo?.name);
  const [sku, setSku] = useState(variantInfo?.sku);
  const [vendor_item_id, setVendorItemId] = useState(
    variantInfo?.vendor_item_id
  );
  const [barcode, setBarcode] = useState(variantInfo?.barcode);
  const [cost, setCost] = useState(variantInfo?.cost);
  const [price, setPrice] = useState(variantInfo?.price);

  const [error, setError] = useState("");
  const [success, setSuccess] = useState({});
  const [isCreateNew, setIsCreateNew] = useState(false);
  function onConfirm() {
    const payload = {
      sku,
      name,
      vendor_item_id,
      barcode,
      cost,
      price,
      create_new_product: isCreateNew,
    };
    setError("");
    setSuccess({});
    cloneVariant(variantInfo?.vid, payload).then(
      (res) => {
        window.open(
          `/products/${res.data.product.id}/variants/${res.data.id}`,
          "_blank",
          "noreferrer"
        );
        setSuccess({
          pid: res.data.product.id,
          vid: res.data.id,
        });
      },
      (err) => {
        if (err?.response?.data?.error) {
          setError(err?.response?.data?.error);
        }
      }
    );
  }

  useEffect(() => {
    setName(variantInfo?.name);
    setSku(variantInfo?.sku);
    setVendorItemId(variantInfo?.vendor_item_id);
    setBarcode(variantInfo?.barcode);
    setCost(variantInfo?.cost);
    setPrice(variantInfo?.price);

    console.log(variantInfo);
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      maxWidth="md"
      sx={{
        "& .MuiPaper-root": {
          width: "100%",
          maxWidth: "500px",
        },
      }}
    >
      <DialogTitle id="confirm-dialog">Clone variant</DialogTitle>
      <DialogContent>
        <Stack spacing={1} padding={2}>
          {Object.keys(success).length > 0 && (
            <Alert>
              <a
                href={`/products/${success.pid}/variants/${success.vid}`}
                target="_blank"
                rel="noreferrer"
              >
                Clone created successfully, click to open.
              </a>
            </Alert>
          )}
          {error && <Alert severity={"error"}>{error}</Alert>}
          <TextField
            name={"name"}
            value={name}
            onChange={(e) => setName(e.target.value)}
            label={"Name"}
          />
          <TextField
            name={"sku"}
            value={sku}
            onChange={(e) => setSku(e.target.value)}
            label={"SKU"}
          />
          <TextField
            name={"vendor_item_id"}
            value={vendor_item_id}
            onChange={(e) => setVendorItemId(e.target.value)}
            label={"Vendor Item ID"}
          />
          <TextField
            name={"barcode"}
            value={barcode}
            onChange={(e) => setBarcode(e.target.value)}
            label={"Barcode"}
          />
          <Stack direction="row" spacing={1}>
            <TextField
              name={"cost"}
              value={cost}
              onChange={(e) => setCost(e.target.value)}
              label={"Cost"}
              type={"number"}
            />
            <TextField
              name={"price"}
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              label={"Price"}
              type={"number"}
            />
          </Stack>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography>Create as New Product</Typography>

            <Checkbox
              checked={isCreateNew}
              onChange={(e) => {
                setIsCreateNew(e.target.checked);
              }}
            />
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={() => setOpen(false)}>
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            onConfirm();
          }}
          color={"success"}
        >
          Clone
        </Button>
      </DialogActions>
    </Dialog>
  );
}
