import React from "react";
import AsyncAutocompleteSelect from "./AsyncAutocompleteSelect";
import { listTerritories } from "../../distflowAPI/territoriesApi";

/**
 * @param {Omit<
 *  import("./AsyncAutocompleteSelect").AsyncAutocompleteSelectProps<
 *    import("../../redux/module/territory").Territory
 *  >,
 *  'fetchOptions'
 * >} props
 */
const TerritorySelect = (props) => (
  <AsyncAutocompleteSelect
    fetchOptions={(searchTerm) =>
      listTerritories(
        10,
        1,
        [],
        [{ column: "search", value: searchTerm }]
      ).then((res) =>
        res.results.map((item) => ({
          value: item.id,
          label: item.tree_name,
        }))
      )
    }
    {...props}
  />
);

export default TerritorySelect;
