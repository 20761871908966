import { useState, useEffect } from "react";
import { Button, Typography, Stack, Box } from "@mui/material";
import DataTable from "../../components/DataTable";
import AddVendorForm from "./AddVendorForm";
import useAppDispatch from "../../hooks/useAppDispatch";
import useAppSelector from "../../hooks/useAppSelector";
import {
  fetchVendors,
  selectVendorNumberOfPages,
  setVendorsTableSettings,
  cleanTableSettings
} from "../../redux/module/vendor";
import vendorsTableColumns from "./vendorsTableColumns";
import { formatAgGridFilters, formatAgGridSorting } from "../../distflowAPI/common";
import { useSearchParams } from "react-router-dom";
import PageLoading from "../../components/PageLoading";

const defaultColDef = {
  flex: 1,
  filter: false,
  floatingFilter: false,
};

const Vendors = () => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const {vendors, pageSize, page, orderBy, filterBy, vendorsLoading} = useAppSelector((state) => state.vendor);

  const numberOfPages = useAppSelector(selectVendorNumberOfPages);

  const [open, setOpen] = useState(false);

  useEffect(() => {
    // get all vendors request
    dispatch(fetchVendors({ page, pageSize, filterBy, orderBy }));
  }, [pageSize, page, filterBy, orderBy]);

  useEffect(() => {
    const params = new URLSearchParams();
    filterBy.forEach((item) => {
      params.set(item.column, item.value);
    });
    setSearchParams(params);
  }, [filterBy]);
  
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleChange = (field, value) => {
    if (field === "filterBy" || field === "pageSize") {
      dispatch(setVendorsTableSettings({ field: "page", value: 1 }));
    }
    dispatch(setVendorsTableSettings({ field, value }));
  };

  useEffect(() => {
    return () => {
      dispatch(cleanTableSettings())
    }
  }, []);
  
  return (
    <>
      <PageLoading dataLoading={vendorsLoading} />

      <Typography fontSize="23px" fontWeight={800} component="h2" data-testid="page-title">
        Vendors
      </Typography>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        alignItems={{ xs: "space-between", sm: "center" }}
        justifyContent="space-between"
        spacing={1.5}
        my="25px"
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          spacing="15px"
        >
          <Button onClick={handleOpen} variant="contained">
            Add New
          </Button>
        </Stack>
      </Stack>

      <Box className="table-wrapper">
        <DataTable
          rowData={vendors}
          columnDefs={vendorsTableColumns}
          defaultColDef={defaultColDef}
          suppressSorting={true} //disable default sorting
          loading={vendorsLoading}
          rowHeight={53}
          headerHeight={60}
          onSortChanged={(event) =>
            handleChange("orderBy", formatAgGridSorting(event.columnApi.columnModel.gridColumns))
          }
          onFilterChanged={(event) =>
            handleChange("filterBy", formatAgGridFilters(event.api.getFilterModel()) || [])
          }
          page={page}
          pageSize={pageSize}
          setPageSize={(pageSize) => handleChange("pageSize", pageSize)}
          noPages={numberOfPages}
          setPage={(page) => handleChange("page", page)}
        />
      </Box>
      <AddVendorForm open={open} handleClose={handleClose} />
    </>
  );
};

export default Vendors;
