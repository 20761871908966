import React from "react";
import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";
import Spinner from "./Spinner";
import LoadingButton from "./LoadingButton";

/**
 * @param {Object} props
 * @param {string} props.title
 * @param {boolean} props.show
 * @param {() => void} props.onCancel
 * @param {string} [props.okText] defaults to `Ok`
 */
const Alert = ({
  title,
  show,
  onCancel,
  okText = "Ok",
}) => (
  <Dialog maxWidth="xs" open={show} onClose={onCancel}>
    <DialogTitle mx={3} my={2}>
      {title}
    </DialogTitle>
    <DialogActions sx={{ borderTop: "1px solid lightgray" }}>
      <Button variant="contained" onClick={onCancel}>
        {okText}
      </Button>
    </DialogActions>
  </Dialog>
);

export default Alert;
