import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import DataTable from "../DataTable";
import customerAccountsTable from "./customerAccountsTable";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";

const defaultColDef = { flex: 1, minWidth: 100 };

const CustomerAccounts = ({ accounts }) => {
  return (
    <Box>
      <Stack direction="row" justifyContent="space-between" alignItems="center" mb={3}>
        <Typography component="p" variant="h6">
          Customer accounts
        </Typography>
      </Stack>
      {accounts?.length ?
        <DataTable 
          rowData={accounts} 
          columnDefs={customerAccountsTable} 
          defaultColDef={defaultColDef}                   
          rowHeight={73} 
        /> :
        <Box
          height="200px"
          display="flex"
          gap={2}
          justifyContent="center"
          alignItems="center"
        >
          <SearchOutlinedIcon fontSize="large" />
          <Typography component="p" variant="h6">
            This customer has no accounts
          </Typography>
        </Box>
      }
    </Box>
  );
};

export default CustomerAccounts;