import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { NavLink } from "react-router-dom";

const SidebarLink = ({ children, title, to, onClick, testid }) => {
  return (
    <ListItemButton
      to={to}
      component={NavLink}
      onClick={onClick}
      data-testid={testid}
      sx={{
        color: "#8091A7",
        "&.active span": {
          color: "#000",
        },
        "&.active .MuiListItemIcon-root": {
          color: "#000",
        },
      }}
    >
      <ListItemIcon sx={{ minWidth: "auto", color: "#8091A7", mr: "12px" }}>
        {children}
      </ListItemIcon>
      <ListItemText
        primary={title}
        sx={{
          "& span": {
            fontSize: "15px",
            fontWeight: "bold",
            letterSpacing: "0.16px",
          },
        }}
      />
    </ListItemButton>
  );
};

export default SidebarLink;
