import React, { useEffect, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import { createTag, updateTag } from "../../redux/module/tag";
import useAppDispatch from "../../hooks/useAppDispatch";
import ErrorPopupBoundary from "../../components/ErrorPopupBoundary";
import { ErrorBoundary } from "react-error-boundary";

const AddTagFrom = ({ open, handleClose, tag }) => {
  const dispatch = useAppDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [tagInfo, setTagInfo] = useState({
    name: ""
  });


  async function handleSubmit(event) {
    event.preventDefault();
    setIsSubmitting(true);
    let data;

    if (tag) {
      data = await dispatch(updateTag({ tagId: tag.id, tag: tagInfo.name }));
    } else {
      data = await dispatch(createTag(tagInfo.name));
    }
    if (data.payload.id) {
      handleClose();
    }
    setIsSubmitting(false);
  }

  useEffect(() => {
    if (tag) {
      setTagInfo({
        name: tag.name
      });
    } else {
      setTagInfo({
        name: ""
      });
    }
  }, [open]);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <ErrorBoundary fallback={<ErrorPopupBoundary onClose={handleClose} />}>
        <DialogTitle sx={{ pb: 1 }}>{tag ? "Update" : "Create a new"} Tag</DialogTitle>
        <form id="tagCreation" onSubmit={handleSubmit}>
          <DialogContent sx={{ pt: 1, pb: 1 }}>
            <TextField
              margin="normal"
              required
              name="tag"
              data-testid="test-tag-id"
              label="Tag name"
              type="text"
              id="tag"
              value={tagInfo.name}
              onChange={(e) => setTagInfo({ ...tagInfo, name: e.target.value })}
              fullWidth
              autoComplete="off"
            />
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="primary" type="submit">
              {tag ? "Update" : "Create"} Tag
            </Button>
            <Button onClick={handleClose} variant="contained" color="error">
              Cancel
            </Button>
          </DialogActions>
        </form>
      </ErrorBoundary>
    </Dialog>
  );
};

export default AddTagFrom;
