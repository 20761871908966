import { useEffect, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import { createGroup, updateGroup } from "../../redux/module/group";
import useAppDispatch from "../../hooks/useAppDispatch";

const AddGroupFrom = ({ open, handleClose, group }) => {
  const dispatch = useAppDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [groupInfo, setGroupInfo] = useState({
    name: ""
  });


  async function handleSubmit(event) {
    event.preventDefault();
    setIsSubmitting(true);
    let data;
    if (group) {
      data = await dispatch(updateGroup({ id: group.id, payload: { name: groupInfo.name } }));
    } else {
      data = await dispatch(createGroup({ name: groupInfo.name }));
    }
    if (data.payload.id) {
      handleClose();
    }
    setIsSubmitting(false);
  }

  useEffect(() => {
    if (group) {
      setGroupInfo({
        name: group.name
      });
    } else {
      setGroupInfo({
        name: ""
      });
    }
  }, [open]);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle sx={{ pb: 1 }}>{group ? "Update" : "Create a new"} Account Type</DialogTitle>
      <form id="groupCreation" onSubmit={handleSubmit}>
        <DialogContent sx={{ pt: 1, pb: 1 }}>
          <TextField
            margin="normal"
            required
            name="group"
            label="Type name"
            type="text"
            id="group"
            value={groupInfo.name}
            onChange={(e) => setGroupInfo({ ...groupInfo, name: e.target.value })}
            fullWidth
            autoComplete="off"
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" type="submit" disabled={isSubmitting}>
            {group ? "Update" : "Create"} Group
          </Button>
          <Button onClick={handleClose} variant="contained" color="error">
            Cancel
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddGroupFrom;
