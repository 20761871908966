import { useState, useEffect } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import useAppDispatch from "../../hooks/useAppDispatch";
import { createVendor } from "../../redux/module/vendor";
import CustomTextField from "../../components/CustomTextField";

const initialValue = {
  name: "",
  address: "",
  internal_id: ""
}

const AddVendorForm = ({ open, handleClose }) => {
  const dispatch = useAppDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [vendorInfo, setVendorInfo] = useState(initialValue);
    
  async function handleSubmit(event) {
    event.preventDefault();
    setIsSubmitting(true);
    const payload = {
      name: vendorInfo.name,
      address: vendorInfo.address || undefined,
      internal_id: vendorInfo.internal_id.toUpperCase() || undefined
    };
    const data = await dispatch(createVendor(payload));
    if (data.payload.id) {
      handleClose();
    }
    setIsSubmitting(false);
  }

  useEffect(() => {
    setVendorInfo(initialValue)    
  }, [open]);
  
  const formFields = [
    { id: 1, name: "name", label: "Vendor name", type: "text", value: vendorInfo.name, required: true },
    { id: 2, name: "address", label: "Vendor address", type: "text", value: vendorInfo.address, required: false },
    { id: 3, name: "internal_id", label: "Internal Identifier", type: "text", value: vendorInfo.internal_id, required: false },
  ];
  
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle sx={{ pb: 1 }}>Create a new Vendor</DialogTitle>
      <form id="tagCreation" onSubmit={handleSubmit}>
        <DialogContent sx={{ pt: 2, pb: 1 }}>
          {formFields.map(el => (
            <CustomTextField
              key={el.id}
              required={el.required}
              name={el.name}
              label={el.label}
              type={el.type}
              value={el.value}
              onChange={(e) => setVendorInfo({ ...vendorInfo, [el.name]: e.target.value })}
            />
          ))}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={isSubmitting}
          >
            Create Vendor
          </Button>
          <Button onClick={handleClose} variant="contained" color="error">
            Cancel
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default AddVendorForm;
