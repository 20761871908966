import { useEffect, useState } from "react";
import { Box, Grid, TextField, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import Block from "../../components/Block";
import TerritorySelect from "../../components/AsyncSelects/TerritorySelect";
import useAppSelector from "../../hooks/useAppSelector";
import useAppDispatch from "../../hooks/useAppDispatch";
import {
  createTerritoryOverride,
  fetchTerritory,
  fetchTerritoryOverrides,
  removeTerritory,
  removeTerritoryOverride,
  updateTerritory,
  updateTerritoryOverride
} from "../../redux/module/territory";
import UpchargeAddRefactoredForm from "../../components/UpchargeAddRefactoredForm";
import OverridesRefactoredTable from "../../components/OverridesRefactoredTable";
import ConfirmDialog from "../../components/ConfirmDialog";
import UpdateItemTitle from "../../components/UpdateItemTitle";
import PageLoading from "../../components/PageLoading";

const UpdateTerritory = () => {
  const { id } = useParams();
  const { territory, territoryOverrides, territoriesLoading, territoryOverridesLoading } = useAppSelector((state) => state.territory);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isOpenDiscard, setIsOpenDiscard] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [cleanOverrideFormFlag, setCleanOverrideFormFlag] = useState(false);
  const [overrides, setOverrides] = useState([]);
  const [territoryInfo, setTerritoryInfo] = useState({
    name: "",
    parent: {
      value: "",
      label: ""
    }
  });

  async function handleSubmit(event) {
    event.preventDefault();
    setIsSubmitting(true);
    const payload = {
      name: territoryInfo.name,
      parent: territoryInfo.parent.value
    };
    await dispatch(updateTerritory({ id, payload }));
    setIsSubmitting(false);
  }

  useEffect(() => {
    if (id) {
      dispatch(fetchTerritory(id));
      dispatch(fetchTerritoryOverrides(id));
    }
  }, [id]);

  useEffect(() => {
    if (territory.id === +id) {
      setTerritoryInfo({
        name: territory.name,
        parent: {
          value: territory.parent,
          label: territory.tree_name
        }
      });
    }
  }, [territory]);

  useEffect(() => {
    if (territoryOverrides[0]?.territory === +id || !territoryOverrides.length) {
      setOverrides(territoryOverrides);
    }
  }, [territoryOverrides]);

  const getIsAllChargeInactive = () => {
    return !territoryOverrides.filter(el => el.is_current === true).length;
  };

  const handleDiscard = () => {
    dispatch(fetchTerritory(id));
    setCleanOverrideFormFlag(true);
  };

  const handleDelete = async () => {
    const resp = await dispatch(removeTerritory(id));
    if (resp.payload === id) {
      navigate(-1);
    }
  };

  return (
    <Box maxWidth="1394px" margin=" 30px auto 0">
      <PageLoading dataLoading={territoriesLoading || territoryOverridesLoading} />
      
      <ConfirmDialog
        title="Discard updates?"
        open={isOpenDiscard}
        setOpen={setIsOpenDiscard}
        onConfirm={handleDiscard}
      >
        {`Are you sure you want to discard all changes?`}
      </ConfirmDialog>
      <ConfirmDialog
        title="Delete territory?"
        open={isOpenDelete}
        setOpen={setIsOpenDelete}
        onConfirm={handleDelete}
      >
        {`Are you sure you want to delete this territory?`}
      </ConfirmDialog>
      <form id="TerritoryCreation" onSubmit={handleSubmit}>
        <UpdateItemTitle
          item={"Territory"}
          setIsOpenDelete={setIsOpenDelete}
          setIsOpenDiscard={setIsOpenDiscard}
          isSubmitting={isSubmitting}
        />
        <Grid
          container
          columnSpacing={{ xs: 1, sm: 3, xl: "50px" }}
          margin="20px 0 0"
        >
          <Grid item md={9} lg={9} sx={{ maxWidth: "908px", width: "100%" }}>
            <Block>
              <TextField
                required
                name="name"
                data-testid="test-name-id"
                label="Territory name"
                type="text"
                id="name"
                fullWidth
                value={territoryInfo.name}
                autoComplete="off"
                onChange={(e) => setTerritoryInfo({ ...territoryInfo, name: e.target.value })}
              />
              <TerritorySelect
                label="Parent territory"
                name="parent"
                required
                fullWidth
                value={territoryInfo.parent}
                onChange={(_e, newValue) => setTerritoryInfo({ ...territoryInfo, parent: newValue })}
                extraStyles={{
                  mt: 1
                }}
              />
            </Block>
          </Grid>
        </Grid>
      </form>
      <UpchargeAddRefactoredForm
        fetchOverrides={fetchTerritoryOverrides}
        createOverride={createTerritoryOverride}
        cleanOverrideFormFlag={cleanOverrideFormFlag}
        setCleanOverrideFormFlag={setCleanOverrideFormFlag}
        elementKey="territory"
      />
      <Grid
        container
        columnSpacing={{ xs: 1, sm: 3, xl: "50px" }}
        margin="20px 0 0"
      >
        <Grid item md={9} lg={9} sx={{ maxWidth: "908px", width: "100%" }}>
          <Block>
            <Typography component="p" variant="label" mb="11px">
              Territory Upcharges {getIsAllChargeInactive() &&
              <span style={{ color: "red" }}>(No active up charge)</span>}
            </Typography>
            <Box sx={{ margin: { xs: "0 -16px -16px", sm: "0 -24px -24px" } }}>
              <OverridesRefactoredTable
                overrides={overrides}
                fetchOverrides={fetchTerritoryOverrides}
                removeOverride={removeTerritoryOverride}
                updateOverride={updateTerritoryOverride}
                elementKey="territory"
              />
            </Box>
          </Block>
        </Grid>
      </Grid>
    </Box>
  );
};
export default UpdateTerritory;
