import React from "react";
import AsyncAutocompleteSelect from "./AsyncAutocompleteSelect";
import { listStatementGroups } from "../../distflowAPI/invoicesApi";

/**
 * @param {Omit<
 *  import("./AsyncAutocompleteSelect").AsyncAutocompleteSelectProps<
 *    import("../../redux/module/category").Category
 *  >,
 *  "fetchOptions"
 * >} props
 */
const StatementGroupSelect = (props) => {
  //temporarily the only field we need is customerId
  return (
    <AsyncAutocompleteSelect
      fetchOptions={(searchTerm) => {
        return listStatementGroups(
          50,
          1,
          [],
          [{ column: "search", value: searchTerm }],
        ).then((res) =>
          res.data.results.map((item) => {
            return {
              value: item.id,
              label: `${item.name}`,
            };
          }),
        );
      }}
      {...props}
    />
  );
};

export default StatementGroupSelect;
