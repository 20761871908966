import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Grid, TextField, Typography } from "@mui/material";
import Block from "../../components/Block";
import CategorySelect from "../../components/AsyncSelects/CategorySelect";
import useAppSelector from "../../hooks/useAppSelector";
import useAppDispatch from "../../hooks/useAppDispatch";
import {
  createCategoryOverride,
  fetchCategory,
  fetchCategoryOverrides,
  removeCategory,
  removeCategoryOverride,
  updateCategory,
  updateCategoryOverride
} from "../../redux/module/category";
import UpchargeAddRefactoredForm from "../../components/UpchargeAddRefactoredForm";
import OverridesRefactoredTable from "../../components/OverridesRefactoredTable";
import IconSelect from "./IconSelect";
import ConfirmDialog from "../../components/ConfirmDialog";
import UpdateItemTitle from "../../components/UpdateItemTitle";
import PageLoading from "../../components/PageLoading";


const UpdateCategory = () => {
  const { id } = useParams();
  const { category, categoryOverrides, categoriesLoading, categoryOverridesLoading } = useAppSelector((state) => state.category);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isOpenDiscard, setIsOpenDiscard] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [cleanOverrideFormFlag, setCleanOverrideFormFlag] = useState(false);
  const [overrides, setOverrides] = useState([]);
  const [categoryInfo, setCategoryInfo] = useState({
    name: "",
    parent: {
      value: "",
      label: ""
    },
    icon: null
  });

  async function handleSubmit(event) {
    event.preventDefault();
    setIsSubmitting(true);

    const formData = new FormData();
    if (categoryInfo.parent.value) {
      formData.append("parent", categoryInfo.parent.value);
    }
    formData.append("name", categoryInfo.name);
    if (categoryInfo.icon instanceof File) {
      formData.append("icon", categoryInfo.icon);
    }

    await dispatch(updateCategory({ id, data: formData }));
    setIsSubmitting(false);
  }

  useEffect(() => {
    if (id) {
      dispatch(fetchCategory(id));
      dispatch(fetchCategoryOverrides(id));
    }
  }, [id]);


  useEffect(() => {
    if (category.id === +id) {
      setCategoryInfo({
        name: category.name,
        parent: {
          value: category.parent,
          label: category.tree_name
        },
        icon: category.icon
      });
    }
  }, [category]);

  useEffect(() => {
    if (categoryOverrides[0]?.category === +id || !categoryOverrides.length) {
      setOverrides(categoryOverrides);
    }
  }, [categoryOverrides]);

  const getIsAllChargeInactive = () => {
    return !categoryOverrides.filter(el => el.is_current === true).length;
  };

  const handleDiscard = () => {
    dispatch(fetchCategory(id));
    setCleanOverrideFormFlag(true);
  };

  const handleDelete = async () => {
    const resp = await dispatch(removeCategory(id));
    if (resp.payload === id) {
      navigate(-1);
    }
  };

  return (
    <Box maxWidth="1394px" margin=" 30px auto 0">
      <PageLoading dataLoading={categoriesLoading || categoryOverridesLoading} />
      
      <ConfirmDialog
        title="Discard updates?"
        open={isOpenDiscard}
        setOpen={setIsOpenDiscard}
        onConfirm={handleDiscard}
      >
        {`Are you sure you want to discard all changes?`}
      </ConfirmDialog>
      <ConfirmDialog
        title="Delete category?"
        open={isOpenDelete}
        setOpen={setIsOpenDelete}
        onConfirm={handleDelete}
      >
        {`Are you sure you want to delete this category?`}
      </ConfirmDialog>
      <form id="CategoryUpdate" onSubmit={handleSubmit}>
        <UpdateItemTitle
          item={"Category"}
          setIsOpenDelete={setIsOpenDelete}
          setIsOpenDiscard={setIsOpenDiscard}
          isSubmitting={isSubmitting}
        />
        <Grid
          container
          columnSpacing={{ xs: 1, sm: 3, xl: "50px" }}
          margin="20px 0 0"
        >
          <Grid item md={9} lg={9} sx={{ maxWidth: "908px", width: "100%" }}>
            <Block>
              <TextField
                fullWidth
                variant="outlined"
                name="name"
                data-testid="test-name-id"
                label="Category name"
                required
                value={categoryInfo.name}
                onChange={(e) => setCategoryInfo({ ...categoryInfo, name: e.target.value })}
              />
              <CategorySelect
                label="Parent Category"
                name="parent"
                required
                fullWidth
                value={categoryInfo.parent}
                onChange={(_e, newValue) => setCategoryInfo({ ...categoryInfo, parent: newValue })}
                extraStyles={{
                  mt: 1,
                  mb: 2
                }}
              />
              <IconSelect categoryInfo={categoryInfo} setCategoryInfo={setCategoryInfo} />
            </Block>
          </Grid>

          <Grid item md={3} lg={3} sx={{ width: "100%" }}>
            <Block sx={{
              width: "200px",
              height: "200px"
            }}>
              {categoryInfo.icon && (
                <img
                  src={categoryInfo.icon}
                  alt="category"
                  width="180px"
                  height="180px"
                  style={{
                    objectFit: "contain",
                    marginLeft: "10px",
                    marginTop: "10px"
                  }}
                />
              )}
            </Block>
          </Grid>
        </Grid>
      </form>
      <UpchargeAddRefactoredForm
        fetchOverrides={fetchCategoryOverrides}
        createOverride={createCategoryOverride}
        cleanOverrideFormFlag={cleanOverrideFormFlag}
        setCleanOverrideFormFlag={setCleanOverrideFormFlag}
        elementKey="category"
      />
      
      <Grid
        container
        columnSpacing={{ xs: 1, sm: 3, xl: "50px" }}
        margin="20px 0 0"
      >
        <Grid item md={9} lg={9} sx={{ maxWidth: "908px", width: "100%" }}>
          <Block>
            <Typography component="p" variant="label" mb="11px">
              Category Upcharges {getIsAllChargeInactive() &&
              <span style={{ color: "red" }}>(No active up charge)</span>}
            </Typography>
            <Box sx={{ margin: { xs: "0 -16px -16px", sm: "0 -24px -24px" } }}>
              <OverridesRefactoredTable
                overrides={overrides}
                fetchOverrides={fetchCategoryOverrides}
                removeOverride={removeCategoryOverride}
                updateOverride={updateCategoryOverride}
                elementKey="category"
              />
            </Box>
          </Block>
        </Grid>
      </Grid>
    </Box>
  );
};

export default UpdateCategory;
