import { Link, useNavigate } from "react-router-dom";
import { Typography, IconButton } from "@mui/material";
import useAppDispatch from "../../hooks/useAppDispatch";
import TableButtons from "../../components/TableComponents/TableButtons";
import { removeTerritory } from "../../redux/module/territory";

function TerritoryNameRenderer(params) {
  return (
    <span className="my-renderer">
      <Typography style={{ textDecoration: "none" }}>
        {params.data.name}
      </Typography>
    </span>
  );
}

function TerritoryAccountsCountRenderer(params) {
  return (
    <span className="my-renderer">
      <Link
        to={`/accounts/?territory__name=${params.data.name}`}
        style={{ textDecoration: "none" }}
      >
        {params.data.accounts_count}
      </Link>
    </span>
  );
}

function TerritoryLinkRenderer(props) {
  const dispatch = useAppDispatch();

  const handleDelete = async () => {
    await dispatch(removeTerritory(props.data.id));
  };

  return (
    <TableButtons
      link={`/territories/${props.data.id}`}
      handleDelete={handleDelete}
      deletePopupEntity="territory"
      historyData={props.data.history || []}
    />
  );
}

const territoryTableColumns = [
  {
    headerName: "Territory",
    field: "name",
    wrapText: true,
    autoHeight: true,
    sortable: true,
    comparator: () => {}, //disable default sorting
    filterParams: {
      filterOptions: ["contains"],
      maxNumConditions: 1,
    },
    filter: true,
    floatingFilter: true,
    valueGetter: (params) => {
      return `${params.data.name}`;
    },
    cellRendererSelector: () => {
      return {
        component: TerritoryNameRenderer,
      };
    },
    flex: 6,
    minWidth: 200,
  },
  {
    headerName: "Territory Tree Name",
    field: "tree_name",
    wrapText: true,
    autoHeight: true,
    filter: false,
    comparator: () => {}, //disable default sorting
    flex: 6,
    minWidth: 200,
    sortable: true
  },
  {
    headerName: "Accounts",
    field: "accounts_count",
    filter: false,
    autoHeight: true,
    comparator: () => {}, //disable default sorting
    cellRendererSelector: () => {
      return {
        component: TerritoryAccountsCountRenderer,
      };
    },
    flex: 8,
    minWidth: 200,
    sortable: true
  },
  {
    field: "",
    sorting: false,
    sort: false,
    sortable: false,
    autoHeight: true,
    filter: false,
    cellRenderer: TerritoryLinkRenderer,
    flex: 2,
    minWidth: 120,
  },
];

export default territoryTableColumns;
