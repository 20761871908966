import {
  Button,
  Grid,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  IconButton,
  TextField,
} from "@mui/material";
import AccountProductsSearch from "./AccountProductsSearch";
import Block from "../../components/Block";
import DataTable from "../../components/DataTable";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import {
  getAccountRestrictions,
  deleteAccountRestrictions,
} from "../../distflowAPI/products";
import { postAccountNote } from "../../distflowAPI/accountsApi";
import { useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import ConfirmDialog from "../../components/ConfirmDialog";
export default function AccountRestrictions() {
  const { id } = useParams();
  const columns = [
    {
      headerName: "Products",
      field: "product.name",
      minWidth: 400,
      sortable: true,
    },
    {
      headerName: "Notes",
      field: "note",
      sortable: true,
    },
    {
      headerName: "Policy",
      field: "policy",
      sortable: true,
    },

    { headerName: "Actions", cellRenderer: buttonsRenderer, maxWidth: 100 },
  ];

  const defaultColDef = {
    flex: 1,
    filter: false,
    floatingFilter: false,
  };
  const [searchProductsOpen, setSearchProductsOpen] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [orderBy, setOrderBy] = useState([]);
  const [filterBy, setFilterBy] = useState([]);
  const [count, setCount] = useState();

  function fetchRestrictions() {
    getAccountRestrictions(id, pageSize, page, orderBy, filterBy).then(
      (res) => {
        setRowData(res.data.results);
        setCount(res.data.count);
      },
    );
  }

  useEffect(() => {
    fetchRestrictions();
  }, [page, pageSize]);

  function buttonsRenderer(params) {
    const rowData = params.data;
    const handleDeleteClick = () => {
      setConfirmModalOpen(true);
    };

    return (
      <div>
        <ConfirmDialog
          title="Note Delete"
          children="Do you want to delete this Note ?"
          open={confirmModalOpen}
          setOpen={setConfirmModalOpen}
          onConfirm={() => {
            deleteAccountRestrictions(rowData.id).then((res) => {
              fetchRestrictions();
            });
          }}
        />

        <IconButton
          onClick={() => {
            handleDeleteClick();
          }}
        >
          <DeleteForeverOutlinedIcon />
        </IconButton>
      </div>
    );
  }

  return (
    <>
      <Grid
        container
        columnSpacing={{ xs: 1, sm: 3, xl: "50px" }}
        margin="20px 0 0"
      >
        <Grid item md={9} lg={9} sx={{ maxWidth: "908px", width: "100%" }}>
          <Block>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <h3 style={{ fontWeight: 600 }}>Products Restrictions</h3>
              <Button
                type="submit"
                variant="contained"
                sx={{ pt: 1, pb: 1, mb: 1 }}
                onClick={() => setSearchProductsOpen(true)}
              >
                Create a Restriction
              </Button>
              <AccountProductsSearch
                openProductSearch={searchProductsOpen}
                setOpenProductSearch={setSearchProductsOpen}
                fetchRestrictions={fetchRestrictions}
              />
            </div>
            <DataTable
              columnDefs={columns}
              rowData={rowData}
              defaultColDef={defaultColDef}
              page={page}
              pageSize={pageSize}
              setPageSize={(pageSize) => {
                setPageSize(pageSize);
                setPage(1);
              }}
              setPage={(page) => {
                setPage(page);
              }}
              noPages={Math.ceil(count / pageSize)}
              // gridOptions={gridOptions}
            />
          </Block>
        </Grid>
      </Grid>
    </>
  );
}
