import React, { useEffect, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { Button, MenuItem, Select, Stack, Typography } from "@mui/material";
import Spinner from "./Spinner";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { useSearchParams } from "react-router-dom";

/**
 * When using `DataTable` do not define `columnDefs` directly in the component,
 * especially if you're defining custom `cellRenderer` components (it's a bad practice in react):
 * instead you can define separate file for table columns, and if you need to pass some data to the custom `cellRenderer`
 * you can use context or redux (depending on specific case).
 *
 * Examples can be found in this project
 * @param {import("ag-grid-react").AgGridReactProps & {
 *    page: number;
 *    pageSize: number;
 *    setPageSize: (value: number) => void;
 *    noPages: number;
 *    setPage: (value: number) => void;
 *    loading?: boolean;
 * }} props
 */

function NoRowsComponent(params) {
  return (
    <Stack alignItems={"center"}>
      <Typography sx={{ position: "fixed", marginTop: 2 }}>No rows</Typography>
    </Stack>
  );
}
const DataTable = ({
  page,
  pageSize,
  setPageSize,
  noPages,
  setPage,
  loading = false,
  selectedRowIds,
  ...props
}) => {
  /** @type {React.MutableRefObject<AgGridReact>} */
  const ref = useRef();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (!ref.current?.api) {
      return;
    }
    ref.current.api[loading ? "showLoadingOverlay" : "hideOverlay"]();
  }, [loading, ref]);

  const [filteredModel, setFilteredModel] = useState();

  useEffect(() => {
    ref?.current?.api?.setFilterModel(filteredModel);
  }, [filteredModel]);

  useEffect(() => {
    const filteredModel = {};
    for (let entry of searchParams?.entries()) {
      const [param, value] = entry;
      filteredModel[param] = {
        filter: value,
        filterType: "text",
        type: "contains",
      };
    }
    setFilteredModel(filteredModel);
  }, []);

  function nextPage() {
    if (page < noPages) {
      setPage(page + 1);
    }
  }

  function prevPage() {
    if (page > 1) {
      setPage(page - 1);
    }
  }

  const customLocaleText = {
    noRowsToShow: "",
  };

  useEffect(() => {
    if (selectedRowIds?.length && ref.current) {
      try {
        ref?.current?.api?.forEachNode((node) => {
          if (selectedRowIds.includes(node.data.id)) {
            node.setSelected(true);
          }
        });
      } catch (error) {}
    }
  }, [selectedRowIds, props.rowData]);

  return (
    <div style={{ width: "100%" }} className="ag-theme-alpine">
      <AgGridReact
        ref={ref}
        animateRows
        localeText={customLocaleText}
        domLayout="autoHeight"
        loadingOverlayComponent={Spinner}
        noRowsOverlayComponent={NoRowsComponent}
        {...props}
      />
      {!!noPages && (
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={2}
          mt={1}
        >
          <Typography component="p" variant="label">
            Rows per page
          </Typography>
          <Select
            className="btn-height"
            onChange={(e) => setPageSize(Number(e.target.value))}
            value={pageSize}
            sx={{
              "& .MuiSelect-select": {
                padding: "7px 32px 7px 12px",
              },
            }}
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={25}>25</MenuItem>
            <MenuItem value={50}>50</MenuItem>
          </Select>
          <Button
            className="btn-height"
            variant="outlined"
            onClick={() => prevPage()}
          >
            Previous
          </Button>
          <Typography component="p" variant="label">
            Page {page} of {noPages}
          </Typography>
          <Button
            className="btn-height"
            variant="outlined"
            onClick={() => nextPage()}
          >
            Next
          </Button>
        </Stack>
      )}
    </div>
  );
};

export default DataTable;
