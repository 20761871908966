import { useSelector } from "react-redux";

/**
 * @template Selected
 * @param {(state: import("../redux/store").RootState) => Selected} selector
 * @param {EqualityFn<Selected> | undefined} [equalityFn]
 * @returns {Selected}
 */
export default function useAppSelector(selector, equalityFn) {
  return useSelector(selector, equalityFn);
}
