import React, { useState, useEffect } from "react";
import { Button, Typography, Stack, Box } from "@mui/material";
import DataTable from "../../../components/DataTable";
import { useSearchParams } from "react-router-dom";
import { listDrivers2 } from "../../../distflowAPI/driversApi";
import driverTableColumns from "./driversTableColumns";
import AddDriverForm from "./addDriverForm";
import useAppSelector from "../../../hooks/useAppSelector";
import useAppDispatch from "../../../hooks/useAppDispatch";
import {
  cleanTableSettings,
  fetchUsers,
  selectUsersNumberOfPages,
  setUsersTableSettings
} from "../../../redux/module/user";
import { fetchDrivers, selectDriversNumberOfPages, setDriversTableSettings } from "../../../redux/module/driver";
import { formatAgGridFilters, formatAgGridSorting } from "../../../distflowAPI/common";
import PageLoading from "../../../components/PageLoading";
const defaultColDef = {
  flex: 1,
  filter: true,
  floatingFilter: true,
};

const Drivers = () => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const { drivers, pageSize, page, filterBy, orderBy, driversLoading } = useAppSelector((state) => state.driver);
  const numberOfPages = useAppSelector(selectDriversNumberOfPages);

  const [open, setOpen] = useState(false);

  useEffect(() => {
    // get all users request
    dispatch(fetchDrivers({ page, pageSize, filterBy, orderBy }));
  }, [pageSize, page, filterBy, orderBy]);

  useEffect(() => {
    const params = new URLSearchParams();
    filterBy.forEach((item) => {
      params.set(item.column, item.value);
    });
    setSearchParams(params);
  }, [filterBy]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleChange = (field, value) => {
    if (field === "filterBy" || field === "pageSize") {
      dispatch(setDriversTableSettings({ field: "page", value: 1 }));
    }
    dispatch(setDriversTableSettings({ field, value }));
  };

  useEffect(() => {
    return () => {
      dispatch(cleanTableSettings())
    }
  }, []);

  return (
    <>
      <PageLoading dataLoading={driversLoading} />
      
      <Typography fontSize="23px" fontWeight={800} component="h2" data-testid="page-title">
        Drivers
      </Typography>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        alignItems={{ xs: "space-between", sm: "center" }}
        justifyContent="space-between"
        spacing={1.5}
        my="25px"
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          spacing="15px"
        >
          <Button onClick={handleOpen} variant="contained">
            Add New
          </Button>
        </Stack>
      </Stack>

      <Box className="table-wrapper">
        <DataTable
          rowData={drivers}
          columnDefs={driverTableColumns}
          defaultColDef={defaultColDef}
          suppressSorting={true} //disable default sorting
          rowHeight={53}
          headerHeight={60}
          loading={driversLoading}
          onSortChanged={(event) =>
            handleChange("orderBy", formatAgGridSorting(event.columnApi.columnModel.gridColumns))
          }
          onFilterChanged={(event) =>
            handleChange("filterBy", formatAgGridFilters(event.api.getFilterModel()) || [])
          }
          page={page}
          pageSize={pageSize}
          setPageSize={(pageSize) => handleChange("pageSize", pageSize)}
          noPages={numberOfPages}
          setPage={(page) => handleChange("page", page)}
        />
      </Box>
      <AddDriverForm open={open} handleClose={handleClose}/>
    </>
  );
};

export default Drivers;
