import React, { useState } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import RouteSelect from "../AsyncSelects/RouteSelect";

const SelectRouteModal = ({item, changeRoute, setChangeRoute, handleSaveRoute}) => {

  const [newRoute, setNewRoute] = useState({
    value: item.route?.id || null,
    label: item.route?.name || ""
  });

  const handleChangeRoute = (newValue) => {
    setNewRoute(newValue);
  };
  
  return (
    <Dialog
      open={changeRoute}
      onClose={() => setChangeRoute(false)}
      aria-labelledby="confirm-dialog"

    >
      <DialogTitle id="confirm-dialog">Route settings</DialogTitle>
      <DialogContent sx={{
        minWidth: "400px"
      }}>
        <Box sx={{
          padding: "10px 0 0"
        }}>

          <RouteSelect
            label="Route"
            name="currentRoute"
            value={newRoute}
            onChange={(_e, newValue) => handleChangeRoute(newValue)}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={() => setChangeRoute(false)}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            setChangeRoute(false);
            handleSaveRoute(newRoute);
          }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SelectRouteModal;