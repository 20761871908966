import { Box } from "@mui/material";
import { useRef, useState } from "react";

const OTPInput = ({ length = 4, onComplete }) => {
  const [OTP, setOTP] = useState(Array(length).fill(""));
  const inputRef = useRef(Array(length).fill(null));

  const handleTextChange = (input, index) => {
    if (!/^\d?$/.test(input)) {
      return;
    }

    const newPin = [...OTP];
    newPin[index] = input;
    setOTP(newPin);

    if (input.length === 1 && index < length - 1) {
      inputRef.current[index + 1]?.focus();
    }

    if (input.length === 0 && index > 0) {
      inputRef.current[index - 1]?.focus();
    }

    if (newPin.every((digit) => digit !== "")) {
      onComplete(newPin.join(""));
    }
  };

  const handleKeyDown = (event, index) => {
    if (event.key === "Backspace" && OTP[index] === "" && index > 0) {
      inputRef.current[index - 1]?.focus();
      const newPin = [...OTP];
      newPin[index] = "";
      newPin[index - 1] = "";
      setOTP(newPin);
    }
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{ mt: 2 }}
    >
      {Array.from({ length }, (_, index) => (
        <input
          maxLength="1"
          className="otp-input"
          ref={(ref) => (inputRef.current[index] = ref)}
          key={index}
          type="text"
          value={OTP[index]}
          onChange={(e) => handleTextChange(e.target.value, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          style={{ marginRight: index === length - 1 ? "0" : "10px" }}
        />
      ))}
    </Box>
  );
};

export default OTPInput;
