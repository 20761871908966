import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  OutlinedInput,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import DataTable from "../../../components/DataTable";
import { useSearchParams } from "react-router-dom";

import {
  formatAgGridFilters,
  formatAgGridSorting,
} from "../../../distflowAPI/common";
import { getNoPages, useSearchDebounce } from "../../../utils";
import PageLoading from "../../../components/PageLoading";
import { customerItems, getCustomers } from "../../../distflowAPI/customersApi";
import CustomerSelect from "../../../components/AsyncSelects/CustomerSelect";
import { smallButton } from "../../../utils/smallButton";
import { listVariants } from "../../../distflowAPI/ordersApi";
import { getVariants } from "../../../distflowAPI/products";
import Product from "../../manageAutoOrders/Product";
const defaultColDef = {
  flex: 1,
  filter: false,
  floatingFilter: false,
};

const CustomersProductManagement = () => {
  const [searchParams, setSearchParams] = useSearchParams({
    ordering: "internal_id",
    search: "",
  });
  const [customer, setCustomer] = useState({
    value: null,
    label: "",
  });
  const [customers, setCustomers] = useState([]);
  const [customersLoading, setCustomersLoading] = useState(false);
  const [filterBy, setFilterBy] = useState([]);
  const [products, setProducts] = useState([]);
  const [search, setSearch] = useSearchDebounce(200);
  const [isDeleting, setIsDeleting] = useState(false);
  const [openAddItem, setOpenAddItem] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  function deleteItem(variantId) {
    setIsDeleting(true);
    customerItems(customer?.value, "DELETE", variantId)
      .then((res) => {
        refreshTable();
      })
      .finally(() => {
        setIsDeleting(false);
      });
  }
  function addItem(variantId) {
    setIsAdding(true);
    customerItems(customer?.value, "ADD", variantId)
      .then((res) => {
        refreshTable();
      })
      .finally(() => {
        setIsAdding(false);
      });
  }
  function refreshTable() {
    if (customer?.value) {
      console.log("customer is set to: ", customer);
      customerItems(customer?.value, "LIST", null, search).then((res) => {
        console.log("res is: ", res);
        setProducts(res.data);
      });
    }
  }
  useEffect(() => {
    refreshTable();
  }, [customer, search]);

  useEffect(() => {
    setSearch(searchParams.get("search"));
  }, [searchParams]);
  const handleChange = (field, value) => {
    if (field === "orderBy") {
      handleSortParams(value);
    }
  };
  function handleSortParams(value) {
    let newSortingValue = "";
    if (value?.length > 0) {
      newSortingValue = value[0]?.field;
      if (value[0]?.sort != "asc") {
        newSortingValue = "-" + newSortingValue;
      }
    }
    setSearchParams((params) => {
      const newParams = new URLSearchParams(params);
      newParams.set("ordering", newSortingValue);
      return newParams;
    });
  }

  const columns = [
    {
      headerName: "name",
      field: "name",
      wrapText: true,
      autoHeight: true,
      sortable: true,
      comparator: () => {}, //disable default sorting
      flex: 2,
      cellStyle: { fontSize: 14 },
    },
    {
      headerName: "sku",
      field: "sku",
      wrapText: true,
      autoHeight: true,
      sortable: true,
      comparator: () => {}, //disable default sorting
      flex: 1,

      cellStyle: { fontSize: 14 },
    },
    {
      headerName: "vendor",
      field: "vendor",
      wrapText: true,
      autoHeight: true,
      sortable: true,
      comparator: () => {}, //disable default sorting
      flex: 1,

      cellStyle: { fontSize: 14 },
    },
    {
      headerName: "category",
      field: "category",
      wrapText: true,
      autoHeight: true,
      sortable: true,
      comparator: () => {}, //disable default sorting
      flex: 1,
      cellStyle: { fontSize: 14 },
    },
    {
      headerName: "Actions",
      field: "",
      wrapText: true,
      autoHeight: true,
      sortable: false,
      comparator: () => {}, //disable default sorting
      flex: 1,
      cellStyle: { fontSize: 14 },
      cellRenderer: (params) => (
        <Button
          variant="contained"
          color="error"
          sx={{ ...smallButton }}
          onClick={() => deleteItem(params.data.id)}
          disabled={isDeleting}
        >
          delete
        </Button>
      ),
    },
  ];
  return (
    <>
      <PageLoading dataLoading={customersLoading} />

      <Typography
        fontSize="23px"
        fontWeight={800}
        component="h2"
        data-testid="page-title"
      >
        Customers Product Visibility Management{" "}
        {customer?.value && <>- {customer.label}</>}
      </Typography>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        alignItems={{ xs: "space-between", sm: "center" }}
        justifyContent="space-between"
        spacing={1.5}
        my="25px"
      >
        <CustomerSelect
          label="Customer"
          name="customer"
          required
          value={customer}
          onChange={(_e, newValue) => setCustomer(newValue)}
        />
        <OutlinedInput
          sx={{ height: "40px", minWidth: "70%" }}
          name="Search"
          placeholder="Search"
          fullWidth
          value={searchParams.get("search")}
          onChange={(e) => {
            setSearchParams((params) => {
              const newParams = new URLSearchParams(params);
              newParams.set("search", e.target.value);
              return newParams;
            });
          }}
        />
        <Button
          onClick={() => {
            setOpenAddItem(true);
          }}
          variant="contained"
          sx={{ minWidth: "10%" }}
        >
          Add Item
        </Button>
      </Stack>

      <Box className="table-wrapper">
        <DataTable
          rowData={products}
          columnDefs={columns}
          defaultColDef={defaultColDef}
          suppressSorting={true} //disable default sorting
          rowHeight={53}
          headerHeight={60}
          loading={customersLoading}
          onSortChanged={(e) => {
            handleChange(
              "orderBy",
              formatAgGridSorting(e.columnApi.columnModel.gridColumns)
            );
          }}
        />
      </Box>
      <AddVariantModal
        open={openAddItem}
        setOpen={(val) => setOpenAddItem(val)}
        onConfirm={(id) => {
          addItem(id);
        }}
        existing_items={products}
      />
    </>
  );
};

export default CustomersProductManagement;

function AddVariantModal({ open, setOpen, onConfirm, existing_items }) {
  const [errors, setErrors] = useState({});
  const [variants, setVariants] = useState([]);
  const [totalPages, setTotalPages] = useState(2);
  const [productsPage, setProductsPage] = useState(1);
  const [filterVariant, setFilterVariant] = useState("");
  const [toHide, setToHide] = useState([]);
  function updateVariantsList() {
    const existing_ids = existing_items.map((item) => item.id);
    getVariants(
      20,
      productsPage,
      [],
      [{ value: filterVariant, column: "search" }]
    ).then((res) => {
      console.log("VARIANTS RES IS: ", res);
      const newVariants = res.data.results
        .filter((v) => !existing_ids.includes(v.id))
        .map((v) => {
          const item = {
            ...v,
            label: v.name,
            ordered: false,
            ordered_pc: false,
            vendor_details: {
              vendorName: v.vendor,
            },
          };
          return item;
        });
      setVariants(newVariants);
      setTotalPages(Math.ceil(res.count / 10));
    });
  }

  useEffect(() => {
    updateVariantsList();
  }, [filterVariant]);

  return (
    <Dialog open={open} onClose={() => setOpen(false)} maxWidth={850}>
      <DialogTitle>Add item to visible products</DialogTitle>
      <DialogContent>
        <Stack padding={2} spacing={1}>
          <TextField
            label="Search"
            value={filterVariant}
            onChange={(e) => setFilterVariant(e.target.value)}
          />
          <Box overflow={"auto"} maxHeight={550} maxWidth={550}>
            <Stack spacing={1}>
              {variants
                .filter((item) => !toHide.includes(item?.id))
                .map((item, index) => (
                  <Product
                    parent
                    item={item}
                    //account={data.order.account}
                    // add item modal
                    customAdd={(data) => {
                      onConfirm(data?.item?.id);
                      setToHide([...toHide, data?.item?.id]);
                    }}
                    showView
                  />
                ))}
            </Stack>
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack>
          <Button
            variant="contained"
            color="error"
            onClick={() => setOpen(false)}
          >
            Close
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
