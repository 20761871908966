import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import "swiper/css";
import "swiper/css/navigation";
import {
  markPickUp,
  routesReports,
  updateHandover,
  getReport,
  bulkPatchPickSheets,
} from "../../../distflowAPI/routesTwo";
import CloseIcon from "@mui/icons-material/Close";
import Delivery from "../../../images/delivery.svg";
import { styled } from "@mui/system";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { updateOrderItem } from "../../../distflowAPI/ordersApi";
import ErrorPopupBoundary from "../../../components/ErrorPopupBoundary";
import { ErrorBoundary } from "react-error-boundary";
import DoneAllOutlinedIcon from "@mui/icons-material/DoneAllOutlined";
import { fireErrorNotification, fireSuccessNotification } from "../../../utils";

const PickupVendor = styled(Box)((props) => {
  return {
    display: "flex",
    alignItems: "center",
    color: "#252525",
    gap: "9px",
    fontWeight: 600,
    fontSize: 12,
    "&::before": {
      content: "''",
      display: "inline-flex",
      width: 1,
      height: 12,
      flexShrink: 0,
      borderRadius: "50%",
      backgroundColor: props.status === "PICKED_UP" ? "#3FC6A5" : "#E2E2E2",
    },
  };
});

function PickUpsModal(props) {
  // this modal shows all pick sheets
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { ...restProps } = props;
  const [report, setReport] = useState(props.initRouteData);
  const [isRefreshing, setIsRefreshing] = useState(false);

  function refreshTable() {
    setIsRefreshing(true);
    getReport(report.id).then(
      (res) => {
        console.log("res is: ", res.data);
        console.log("new report is: ", res.data.results[0]);
        setReport(res.data.results[0]);
        setIsRefreshing(false);
        props.refreshTable();
      },
      (error) => {}
    );
  }
  function pickUpFromVendorsAndDeliverToAccounts(pickSheetItem) {
    const result = [];
    pickSheetItem.orders_items.map((orderItem) => {
      if (!orderItem.is_handover) {
        result.push({
          is_handover: orderItem.is_handover,
          is_handover_to_pick_up: orderItem.is_handover_to_pick_up,
          orderItemId: orderItem.id,
          quantity: orderItem.quantity,
          unit: orderItem.unit,
          status: orderItem.status,
          picked_up_qty: orderItem.picked_up_qty,
          delivered_qty: orderItem.delivered_qty,
          account: orderItem.account,
        });
      }
    });

    return result;
  }

  function pickUpFromVendorAndDeliverToRoutes(pickSheetItem) {
    const result = [];
    pickSheetItem.orders_items.map((orderItem) => {
      if (orderItem.is_handover && !orderItem.is_handover_to_pick_up) {
        result.push({
          is_handover: orderItem.is_handover,
          is_handover_to_pick_up: orderItem.is_handover_to_pick_up,
          handoverId: orderItem.handoverId,
          orderItemId: orderItem.id,
          quantity: orderItem.quantity,
          unit: orderItem.unit,
          status: orderItem.status,
          picked_up_qty: orderItem.picked_up_qty,
          delivered_qty: orderItem.delivered_qty,
          account: orderItem.account,
          handover_status: orderItem.handover_status,
          route: orderItem.route,
        });
      }
    });
    return result;
  }

  function pickSheetsFromRoutes() {
    const result = {};
    report.pick_sheet_items.map((pickSheetItem) => {
      if (pickSheetItem.orders_items.length > 0) {
        if (
          pickSheetItem.orders_items[0].is_handover &&
          pickSheetItem.orders_items[0].is_handover_to_pick_up
        ) {
          if (!result[pickSheetItem.orders_items[0].route.id]) {
            result[pickSheetItem.orders_items[0].route.id] = {
              routeId: pickSheetItem.orders_items[0].route.id,
              routeName: pickSheetItem.orders_items[0].route.name,
              pickSheets: [],
            };
          }
          result[pickSheetItem.orders_items[0].route.id].pickSheets.push(
            pickSheetItem
          );
        }
      }
    });
    return result;
  }

  function pickSheetsFromVendor() {
    const result = {};
    report.pick_sheet_items.map((pickSheetItem) => {
      if (!result[pickSheetItem.variant.vendor.id]) {
        result[pickSheetItem.variant.vendor.id] = {
          vendorName: pickSheetItem.variant.vendor.name,
          vendorId: pickSheetItem.variant.vendor.id,
          pickSheets: [],
        };
      }
      const vToA = pickUpFromVendorsAndDeliverToAccounts(pickSheetItem);
      if (
        vToA.length > 0 &&
        !result[pickSheetItem.variant.vendor.id].pickSheets
          .map((pc) => pc.id)
          .includes(pickSheetItem.id)
      ) {
        result[pickSheetItem.variant.vendor.id].pickSheets.push(pickSheetItem);
      }
      const vTor = pickUpFromVendorAndDeliverToRoutes(pickSheetItem);
      if (
        vTor.length > 0 &&
        !result[pickSheetItem.variant.vendor.id].pickSheets
          .map((pc) => pc.id)
          .includes(pickSheetItem.id)
      ) {
        result[pickSheetItem.variant.vendor.id].pickSheets.push(pickSheetItem);
      }
    });
    return result;
  }
  return (
    <Dialog
      {...restProps}
      fullScreen={fullScreen}
      maxWidth="xs"
      sx={{
        "& .MuiPaper-root": { width: "100%", maxWidth: "900px" },
        zIndex: 1500,
      }}
    >
      <ErrorBoundary
        fallback={<ErrorPopupBoundary onClose={restProps.onClose} />}
      >
        <DialogTitle
          sx={{
            textAlign: "center",
            fontWeight: 600,
            color: "#252525",
            paddingBottom: 0,
          }}
        >
          <Typography>
            Pick sheets:{" "}
            <b>
              {report.route.name} {report.route.date}
            </b>
          </Typography>
        </DialogTitle>
        <Typography textAlign="center" sx={{ color: "#535151" }}>
          Picked up{" "}
          <b>
            {
              report.pick_sheet_items.filter((ps) => ps.picked_up >= ps.qty)
                .length
            }
            /{report.pick_sheet_items.length}
          </b>
        </Typography>
        {isRefreshing && (
          <Typography textAlign="center" sx={{ color: "orange" }}>
            Saving...
          </Typography>
        )}
        <IconButton
          aria-label="close"
          onClick={() => props.onClose()}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent
          sx={{ display: "flex", flexDirection: "column", gap: "12px" }}
        >
          {Object.keys(pickSheetsFromVendor()).map(
            (vendorId) =>
              pickSheetsFromVendor()[vendorId].pickSheets.length > 0 && (
                <PickUpFromVendor
                  fromVendor={pickSheetsFromVendor()[vendorId]}
                  refreshTable={refreshTable}
                  setIsRefreshing={setIsRefreshing}
                />
              )
          )}
          {Object.keys(pickSheetsFromRoutes()).map((routeId) => (
            <PickUpFromRoute
              fromRoute={pickSheetsFromRoutes()[routeId]}
              refreshTable={refreshTable}
              setIsRefreshing={setIsRefreshing}
            />
          ))}
        </DialogContent>
      </ErrorBoundary>
    </Dialog>
  );
}

function PickUpFromRoute({ fromRoute, refreshTable, setIsRefreshing }) {
  const handlePickAll = async (e) => {
    e.stopPropagation();
    setIsRefreshing(true);

    for (let i = 0; i < fromRoute.pickSheets.length; i++) {
      await markPickUp(fromRoute.pickSheets[i].id, fromRoute.pickSheets[i].qty)
        .then(() => {
          fireSuccessNotification(
            `All available products picked up successfully`
          );
        })
        .catch((error) => {
          const errorFields = Object.keys(error.response.data);
          errorFields.forEach((field) => {
            fireErrorNotification(`${field}: ${error.response.data[field][0]}`);
          });
        });
    }
    refreshTable();
  };

  return (
    <Accordion
      key={fromRoute.routeId}
      sx={{
        boxShadow: "none",
        border: "1px solid #DBDFEA",
        borderRadius: "5px",
        "&.Mui-expanded": {
          margin: 0,
        },
        "&::before": {
          content: "none",
        },
        "& .MuiAccordionSummary-root.Mui-expanded": {
          minHeight: "49px",
        },
        "& .MuiAccordionSummary-content.Mui-expanded": {
          margin: 0,
        },
      }}
    >
      <AccordionSummary
        id={fromRoute.routeName}
        sx={{
          "& .MuiAccordionSummary-content": {
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
          },
        }}
      >
        <PickupVendor status={fromRoute.state}>
          {fromRoute.routeName}
          {/*
                {isPickingUpFromDifferentRoute(pickup.vendor) && <Typography fontSize={12} color="#A0A0A0">
                    Pickup From {getPickupRouteName(pickup.vendor)}
                </Typography>}
                */}
        </PickupVendor>
        <IconButton
          onClick={handlePickAll}
          sx={{
            marginLeft: "auto",
          }}
        >
          <DoneAllOutlinedIcon />
        </IconButton>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: "0 14px 14px" }}>
        <Box>
          <Grid container columnSpacing={{ xs: 1 }}>
            {/*<PickupDetails data={pickup.items} refreshTable={refreshTable} />*/}
            {fromRoute.pickSheets
              .sort((a, b) => {
                if (a.variant.sku < b.variant.sku) return -1;
                if (a.variant.sku > b.variant.sku) return 1;
                return 0;
              })
              .map((pickSheet) => (
                <Grid item md="6">
                  <PickSheet
                    pickSheet={pickSheet}
                    refreshTable={refreshTable}
                  />
                </Grid>
              ))}
          </Grid>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}

function PickUpFromVendor({ fromVendor, refreshTable, setIsRefreshing }) {
  const [fromVendorData, setFromVendorData] = useState(fromVendor);

  function updatePickFromVendor() {
    //somFunc()
  }
  useEffect(() => setFromVendorData(fromVendor), [fromVendor]);
  function splitCnPd() {
    const result = {
      nonCn: {
        pickSheets: [],
      },
      cn: {},
    };
    fromVendorData.pickSheets.map((psi) => {
      const noCnItems = [];
      const cnItems = [];
      psi.orders_items.map((oi) => {
        if (oi.cn_pd) {
          cnItems.push(oi);
          if (!result["cn"][oi.cn_pd]) {
            result["cn"][oi.cn_pd] = [];
          }
          result["cn"][oi.cn_pd].push({
            ...oi,
            variant: psi.variant,
          });
        } else {
          noCnItems.push(oi);
        }
      });
      if (noCnItems.length > 0) {
        result["nonCn"]["pickSheets"].push({
          ...psi,
          orders_items: noCnItems,
          items: noCnItems.map((oi) => oi.id),
        });
      }
    });
    return result;
  }
  function getPickUpStatus() {
    let picked_up = 0;
    let to_pick_up = 0;
    let isIssue = false;
    fromVendorData?.pickSheets?.map((pickSheet) => {
      pickSheet.orders_items.map((oi) => {
        picked_up += Number(oi.picked_up_qty);
        to_pick_up += Number(oi.quantity);
      });
    });
    if (picked_up != to_pick_up) {
      return "red";
    }
    if (picked_up == to_pick_up) {
      return "green";
    }
    return "grey";
  }
  const handlePickAll = async (e) => {
    e.stopPropagation();

    //setIsRefreshing(true);
    const newFromVendorData = JSON.parse(JSON.stringify(fromVendorData));
    const payload = {};
    for (let i = 0; i < fromVendorData.pickSheets.length; i++) {
      payload[fromVendorData.pickSheets[i].id] = {
        picked_up: fromVendorData.pickSheets[i].qty,
      };
      newFromVendorData.pickSheets[i].picked_up =
        fromVendorData.pickSheets[i].qty;
      /*
      markPickUp(
        fromVendorData.pickSheets[i].id,
        fromVendorData.pickSheets[i].qty
      )
        .then((res) => {
          fireSuccessNotification(
            `All available products picked up successfully`
          );
          newFromVendorData.pickSheets[i] = res.data;
          setFromVendorData(JSON.parse(JSON.stringify(newFromVendorData)));
          if (i == fromVendorData.pickSheets.length - 1) {
            //setIsRefreshing(false);
            refreshTable();
          }
        })
        .catch((error) => {
          const errorFields = Object.keys(error.response.data);
          errorFields.forEach((field) => {
            fireErrorNotification(`${field}: ${error.response.data[field][0]}`);
          });
        });*/
    }
    bulkPatchPickSheets(payload).then((res) => {
      setFromVendorData(newFromVendorData);
      refreshTable();
    });
    //refreshTable();
  };

  return (
    <Accordion
      key={fromVendorData.vendorId}
      sx={{
        boxShadow: "none",
        border: "1px solid #DBDFEA",
        borderRadius: "5px",
        "&.Mui-expanded": {
          margin: 0,
        },
        "&::before": {
          content: "none",
        },
        "& .MuiAccordionSummary-root.Mui-expanded": {
          minHeight: "49px",
        },
        "& .MuiAccordionSummary-content.Mui-expanded": {
          margin: 0,
        },
      }}
    >
      <AccordionSummary
        id={fromVendorData.vendorId}
        sx={{
          "& .MuiAccordionSummary-content": {
            display: "grid",
            gridTemplateColumns: "repeat(3, 1fr)",
          },
        }}
      >
        <PickupVendor status={fromVendorData.state}>
          {fromVendorData.vendorName}
          {/*
                {isPickingUpFromDifferentRoute(pickup.vendor) && <Typography fontSize={12} color="#A0A0A0">
                    Pickup From {getPickupRouteName(pickup.vendor)}
                </Typography>}
                */}
        </PickupVendor>

        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          alignContent="center"
        >
          <img src={Delivery} alt="" style={{ marginRight: "5px" }} />
          <Typography>V</Typography>
        </Stack>
        <IconButton
          onClick={handlePickAll}
          sx={{
            marginLeft: "auto",
            color: getPickUpStatus(),
          }}
        >
          <DoneAllOutlinedIcon />
        </IconButton>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: "0 14px 14px" }}>
        <Box>
          <Grid
            container
            columnSpacing={{ xs: 1 }}
            sx={{ marginBottom: "5px" }}
          >
            {/*<PickupDetails data={pickup.items} refreshTable={refreshTable} />*/}
            {splitCnPd()
              ["nonCn"].pickSheets.sort((a, b) => {
                if (a.variant.sku < b.variant.sku) return -1;
                if (a.variant.sku > b.variant.sku) return 1;
                return 0;
              })
              .map((pickSheet) => (
                <Grid item md="6" sx={{ marginBottom: "5px" }}>
                  <PickSheet
                    pickSheet={pickSheet}
                    refreshTable={refreshTable}
                  />
                </Grid>
              ))}
            {Object.keys(splitCnPd()["cn"]).map((cnO) => (
              <Grid item md="6" sx={{ marginBottom: "5px" }}>
                <PickSheetCn
                  cnOrder={splitCnPd()["cn"][cnO]}
                  keyy={cnO}
                  refreshTable={refreshTable}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}

function PickSheetCn({ cnOrder, refreshTable, keyy }) {
  const [expanded, setExpanded] = useState(false);
  function toggle() {
    setExpanded(!expanded);
  }
  function getPickedUp() {
    let total = 0;
    cnOrder.map((orderItem) => {
      if (orderItem.quantity >= orderItem.picked_up_qty) {
        total += 1;
      }
    });
    return total;
  }
  function handlePickUp() {
    cnOrder.map((orderItem, index) => {
      updateOrderItem(orderItem.id, { picked_up_qty: orderItem.quantity }).then(
        (success) => {
          if (index == cnOrder.length - 1) {
            refreshTable();
          }
        }
      );
    });
  }
  return (
    <Stack sx={{ marginBottom: "5px" }}>
      <Accordion
        expanded={expanded}
        key={`pickSheet-${keyy}`}
        sx={{
          boxShadow: "none",
          border: "1px solid #DBDFEA",
          borderRadius: "5px",
          "&.Mui-expanded": {
            margin: 0,
          },
          "&::before": {
            content: "none",
          },
          "& .MuiAccordionSummary-root.Mui-expanded": {
            minHeight: "49px",
          },
          "& .MuiAccordionSummary-content.Mui-expanded": {
            margin: 0,
          },
        }}
      >
        <AccordionSummary
          expandIcon={
            <Button
              onClick={() => toggle()}
              sx={{ padding: 0, minWidth: "25px" }}
            >
              <ExpandMoreIcon />
            </Button>
          }
        >
          {/*<PickUpItemForm item={item} refreshTable={refreshTable} />*/}
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item md="8">
              <Typography>
                {keyy} (
                <span
                  style={{
                    color: getPickedUp() == cnOrder.length ? "green" : "",
                  }}
                >
                  {getPickedUp()}/{cnOrder.length}
                </span>{" "}
                items)
              </Typography>
            </Grid>
            <Grid item md="3" marginRight={1}>
              <Button
                variant="outlined"
                sx={{
                  paddingBottom: "3px",
                  paddingTop: "3px",
                  marginLeft: "3px",
                  height: "30px !important",
                  paddingLeft: "5px",
                  paddingRight: "5px",
                }}
                onClick={handlePickUp}
              >
                Picked Up
              </Button>
            </Grid>
          </Grid>
        </AccordionSummary>
        <Stack>
          {cnOrder.map((orderItem) => (
            <PickSheetOrderItem
              orderItem={orderItem}
              refreshTable={refreshTable}
              isCn={true}
            />
          ))}
        </Stack>
      </Accordion>
    </Stack>
  );
}
function PickSheet({ pickSheet, refreshTable }) {
  const [expanded, setExpanded] = useState(false);
  const [pickSheetData, setPickSheetData] = useState(pickSheet);
  function toggle() {
    setExpanded(!expanded);
  }
  useEffect(() => {
    console.log("pick sheet changed to: ", pickSheet);
    setPickSheetData(pickSheet);
  }, [pickSheet]);
  return (
    <Stack sx={{ marginBottom: "5px" }}>
      <Accordion
        expanded={expanded}
        key={`pickSheet-${pickSheetData.id}`}
        sx={{
          boxShadow: "none",
          border: "1px solid #DBDFEA",
          borderRadius: "5px",
          "&.Mui-expanded": {
            margin: 0,
          },
          "&::before": {
            content: "none",
          },
          "& .MuiAccordionSummary-root.Mui-expanded": {
            minHeight: "49px",
          },
          "& .MuiAccordionSummary-content.Mui-expanded": {
            margin: 0,
          },
        }}
      >
        <AccordionSummary
          expandIcon={
            <Button
              onClick={() => toggle()}
              sx={{ padding: 0, minWidth: "25px" }}
            >
              <ExpandMoreIcon />
            </Button>
          }
        >
          {/*<PickUpItemForm item={item} refreshTable={refreshTable} />*/}
          <PickSheetForm
            pickSheet={pickSheetData}
            refreshTable={refreshTable}
            setPickSheetData={setPickSheetData}
          ></PickSheetForm>
        </AccordionSummary>
        <Stack>
          {pickSheetData?.orders_items?.map((orderItem) => (
            <PickSheetOrderItem
              orderItem={orderItem}
              refreshTable={refreshTable}
            />
          ))}

          {pickSheetData.issue && (
            <Typography
              sx={{
                color: "red",
                padding: "8px",
                borderTop: "1px solid #DBDFEA",
              }}
            >
              {pickSheetData.issue}
            </Typography>
          )}
        </Stack>
      </Accordion>
    </Stack>
  );
}

function PickSheetForm({ pickSheet, refreshTable, setPickSheetData }) {
  const [isSetting, setIsSetting] = useState(false);
  const [pickedUpQty, setPickedUpQty] = useState(pickSheet.qty);
  function updatePickSheetItem() {
    markPickUp(pickSheet.id, pickedUpQty).then(
      (success) => {
        setPickSheetData(success.data);
        setIsSetting(false);
        refreshTable();
      },
      (error) => {
        const errorFields = Object.keys(error.response.data);
        errorFields.forEach((field) => {
          fireErrorNotification(`${field}: ${error.response.data[field][0]}`);
        });
      }
    );
  }

  function isQtyWrong() {
    return pickSheet.picked_up > 0 && pickSheet.picked_up != pickSheet.qty;
  }
  function boxesCalculator(perBox, totalPcs, perRack = 0) {
    const calc = {
      unit: "boxes",
      count: perBox,
    };
    if ((!perBox || perBox == 0) && perRack > 0) {
      calc.unit = "racks";
      calc.count = perRack;
    }
    if (calc.count == totalPcs) {
      return `(1 ${calc.unit})`;
    } else if (calc.count > totalPcs) {
      return ``;
    } else {
      const boxesCount = Math.floor(totalPcs / calc.count);
      const piecesCount = totalPcs - boxesCount * calc.count;
      let msg = `${boxesCount} ${calc.unit}`;
      if (piecesCount > 0) {
        msg += `, and ${piecesCount} pieces`;
      }
      return `(${msg})`;
    }
  }

  return (
    <Grid container>
      <Grid md={"12"} sx={{ marginTop: "5px" }} alignContent={"end"}>
        {!isSetting && (
          <Typography fontSize={14}>
            {pickSheet?.oDisplayName}{" "}
            <b
              style={{
                color:
                  pickSheet.picked_up > 0
                    ? isQtyWrong()
                      ? "red"
                      : "green"
                    : "black",
                marginLeft: "5px",
              }}
              onClick={() => setIsSetting(true)}
            >
              {pickSheet.picked_up}/{pickSheet.qty}{" "}
              {pickSheet?.variant?.sold_by}{" "}
            </b>
            {pickSheet?.variant?.sold_by === "PC" && (
              <div style={{ fontSize: "11px" }}>
                {boxesCalculator(
                  pickSheet.variant?.per_box,
                  pickSheet.qty,
                  pickSheet.variant?.per_pack
                )}
              </div>
            )}
            <div style={{ fontSize: "12px", minWidth: "115px" }}>
              SKU: {pickSheet.variant?.sku}
              {pickSheet.variant?.vendor_item_id &&
                ` | Vendor Item ID: ${pickSheet.variant?.vendor_item_id}`}
            </div>
          </Typography>
        )}
        {isSetting && (
          <Stack direction="row" height={30}>
            <OutlinedInput
              sx={{
                paddingTop: 0,
                paddingBottom: 0,
                input: { height: 10 },
                maxWidth: 140,
              }}
              endAdornment={
                <InputAdornment position="end">
                  / {pickSheet.qty}
                </InputAdornment>
              }
              value={pickedUpQty}
              type="number"
              onChange={(e) => setPickedUpQty(e.target.value)}
            />
            <Button
              variant="outlined"
              size="small"
              onClick={updatePickSheetItem}
              sx={{
                paddingBottom: "3px",
                paddingTop: "3px",
                marginLeft: "5px",
                height: "30px !important",
              }}
            >
              Save
            </Button>
            <Button
              variant="outlined"
              size="small"
              onClick={() => setIsSetting(false)}
              sx={{
                paddingBottom: "3px",
                paddingTop: "3px",
                marginLeft: "5px",
                height: "30px !important",
              }}
            >
              Cancel
            </Button>
          </Stack>
        )}
      </Grid>
    </Grid>
  );
}

function PickSheetOrderItem({ orderItem, refreshTable, isCn }) {
  const [isSetting, setIsSetting] = useState(false);
  const [pickedUpQty, setPickedUpQty] = useState(getQty());

  function getOrderType() {
    if (!orderItem.is_handover) {
      return "vendor_to_account";
    }
    if (orderItem.is_handover && !orderItem.is_handover_to_pick_up) {
      return "vendor_to_route";
    }
    if (orderItem.is_handover && orderItem.is_handover_to_pick_up) {
      return "route_to_account";
    }
  }

  function isQtyWrong() {
    if (getOrderType() == "route_to_account") {
      return (
        orderItem.order_item.picked_up_qty > 0 &&
        orderItem.order_item.picked_up_qty != orderItem.order_item.quantity
      );
    }
    return (
      orderItem.picked_up_qty > 0 &&
      orderItem.picked_up_qty != orderItem.quantity
    );
  }

  function getProperPickedUpQty() {
    if (getOrderType() == "route_to_account") {
      return orderItem.order_item.picked_up_qty;
    }
    return orderItem.picked_up_qty;
  }

  function getQty() {
    if (getOrderType() == "route_to_account") {
      return orderItem.order_item.quantity;
    }
    return orderItem.quantity;
  }

  function updateOrderItemQty() {
    const orderItemType = getOrderType();
    if (orderItemType == "vendor_to_account") {
      setIsSetting(false);
      updateOrderItem(orderItem.id, { picked_up_qty: pickedUpQty }).then(
        (success) => {
          refreshTable();
        },
        (error) => {
          const errorFields = Object.keys(error.response.data);
          errorFields.forEach((field) => {
            fireErrorNotification(`${field}: ${error.response.data[field][0]}`);
          });
        }
      );
    }
    if (orderItemType == "vendor_to_route") {
      setIsSetting(false);
      updateHandover(orderItem.handoverId, { picked_up: pickedUpQty }).then(
        (res) => {
          refreshTable();
        },
        (error) => {
          const errorFields = Object.keys(error.response.data);
          errorFields.forEach((field) => {
            fireErrorNotification(`${field}: ${error.response.data[field][0]}`);
          });
        }
      );
    }
    if (orderItemType == "route_to_account") {
      setIsSetting(false);
      updateOrderItem(orderItem.order_item.orderItemId, {
        picked_up_qty: pickedUpQty,
      }).then(
        (success) => {
          refreshTable();
        },
        (error) => {
          const errorFields = Object.keys(error.response.data);
          errorFields.forEach((field) => {
            fireErrorNotification(`${field}: ${error.response.data[field][0]}`);
          });
        }
      );
    }
  }
  function getMainText() {
    if (isCn) {
      return orderItem.variant.name;
    }
    const orderType = getOrderType();
    if (orderType == "vendor_to_account" || orderType == "route_to_account") {
      return orderItem.account.accountName;
    }
    return orderItem.route.name;
  }
  return (
    <Grid
      container
      style={{
        marginBottom: "3px",
        padding: "5px 16px",
        borderTop: "1px solid #DBDFEA",
      }}
    >
      <>
        <Grid md={7}>
          {getMainText()}{" "}
          {orderItem?.is_central && (
            <small style={{ color: "red", textColor: "red" }}>
              Route Central
            </small>
          )}
        </Grid>
        <Grid md={5} textAlign={"right"} justifyContent={"center"}>
          {!isSetting && (
            <Typography
              marginRight={"20px"}
              fontSize={14}
              paddingTop="5px"
              fontWeight={800}
              color={
                getProperPickedUpQty() > 0
                  ? isQtyWrong()
                    ? "red"
                    : "green"
                  : "black"
              }
            >
              <span onClick={() => setIsSetting(true)}>
                {getProperPickedUpQty()}/{getQty()} {orderItem.unit}
              </span>
            </Typography>
          )}
          {isSetting && (
            <Grid container justifyContent={"end"}>
              <Grid md="12">
                <OutlinedInput
                  sx={{
                    paddingTop: 0,
                    paddingBottom: 0,
                    input: { height: 10 },
                  }}
                  value={pickedUpQty}
                  type="number"
                  onChange={(e) => setPickedUpQty(e.target.value)}
                />
              </Grid>
              <Grid md="12">
                <Button
                  variant="outlined"
                  size="small"
                  onClick={updateOrderItemQty}
                  sx={{
                    padding: "0px",
                    marginTop: "5px",
                    height: "30px !important",
                  }}
                >
                  Save
                </Button>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => setIsSetting(false)}
                  sx={{
                    padding: "0px",
                    marginTop: "5px",
                    marginLeft: "5px",
                    height: "30px !important",
                  }}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
        {orderItem.note && (
          <Grid md="12">
            <small>
              <b>Note: </b>
              {orderItem.note}
            </small>
          </Grid>
        )}
      </>
    </Grid>
  );
}

export default PickUpsModal;
