import {
  createAccountPhone,
  deleteAccountPhone,
  listAccountPhones,
  updateAccountPhone,
} from "../../distflowAPI/accountsApi";
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import HighlightOffTwoToneIcon from "@mui/icons-material/HighlightOffTwoTone";
import CheckCircleTwoToneIcon from "@mui/icons-material/CheckCircleTwoTone";
import AddCircleTwoToneIcon from "@mui/icons-material/AddCircleTwoTone";
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Block from "../../components/Block";
import ConfirmDialog from "../../components/ConfirmDialog";
import VerifiedUserTwoToneIcon from "@mui/icons-material/VerifiedUserTwoTone";

function phoneFormat(input) {
  if (!input || isNaN(input)) return `input must be a number was sent ${input}`;
  if (typeof input !== "string") input = input.toString();
  if (input.length === 10) {
    return input.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
  }
  return input;
}

export default function AccountPhones({ accountId }) {
  const [phones, setPhones] = useState([]);
  const [newPhone, setNewPhone] = useState("");
  const [note, setNote] = useState("");
  const [showForm, setShowForm] = useState(false);

  function updateTable() {
    listAccountPhones(accountId).then((res) => setPhones(res.data.results));
  }

  useEffect(() => {
    updateTable();
  }, [accountId]);

  function handleAddPhone() {
    createAccountPhone(Number(accountId), newPhone, note).then((res) => {
      updateTable();
      setNewPhone("");
    });
  }

  return (
    <Block sx={{ mb: 2, py: 2, px: 3, height: "100%" }}>
      <Typography variant={"h6"} mb={2}>
        Account Phone numbers
      </Typography>
      <Stack spacing={3}>
        {phones.map((entry, i) => (
          <PhoneEntry
            key={i}
            phone={entry.phone}
            accountId={accountId}
            phoneId={entry.id}
            updateTable={updateTable}
            isPrimary={entry.is_default}
            note={entry.note}
          />
        ))}
        {showForm ? (
          <Stack spacing={1} direction={"column"} alignItems={"flex-end"}>
            <Box
              maxWidth={500}
              gap={1}
              display={"flex"}
              flexDirection={"column"}
              width={"100%"}
            >
              <TextField
                label={"New Phone"}
                value={newPhone}
                required
                onChange={(e) => setNewPhone(e.target.value)}
                fullWidth
                type="tel"
              />
              <TextField
                label={"Note"}
                value={note}
                onChange={(e) => setNote(e.target.value)}
                fullWidth
              />
            </Box>
            <Stack spacing={1} direction={"row"}>
              <Button
                onClick={() => setShowForm(false)}
                variant={"contained"}
                color={"error"}
                sx={{ maxWidth: 120 }}
              >
                Cancel
              </Button>
              <Button
                onClick={() => handleAddPhone()}
                variant={"outlined"}
                disabled={newPhone.length < 10 || newPhone.length > 14}
                sx={{ maxWidth: 120 }}
              >
                Add
              </Button>
            </Stack>
          </Stack>
        ) : (
          <Stack spacing={1} direction={"column"} alignItems={"flex-end"}>
            <Button
              onClick={() => setShowForm(true)}
              variant="outlined"
              sx={{ mt: 2 }}
              startIcon={<AddCircleTwoToneIcon />}
            >
              Add Phone
            </Button>
          </Stack>
        )}
      </Stack>
    </Block>
  );
}

function PhoneEntry({
  phone,
  phoneId,
  accountId,
  updateTable,
  isPrimary,
  note,
}) {
  const [isEditing, setIsEditing] = useState(false);
  const [updatedEntry, setUpdatedEntry] = useState(phone);
  const [updatedNote, setUpdatedNote] = useState(note);

  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  function handleDelete() {
    setIsSaving(true);
    deleteAccountPhone(accountId, phoneId).then((res) => {
      updateTable();
      setIsSaving(false);
    });
  }

  function handleSave() {
    setIsSaving(true);
    updateAccountPhone(
      accountId,
      phoneId,
      updatedEntry,
      isPrimary,
      updatedNote
    ).then((res) => {
      setIsSaving(false);
      setIsEditing(false);
      updateTable();
    });
  }

  function makePrimary() {
    setIsSaving(true);
    updateAccountPhone(accountId, phoneId, phone, true).then((res) => {
      updateTable();
      setIsSaving(false);
    });
  }

  return (
    <Stack
      direction={"row"}
      spacing={1}
      alignItems={"center"}
      justifyContent={"space-between"}
    >
      {!isEditing && (
        <Box>
          <Typography fontWeight={isPrimary ? "medium" : "normal"}>
            {phoneFormat(phone)}{" "}
            {isPrimary && (
              <Typography variant={"caption"} fontWeight={"bold"} fontSize={10}>
                (Primary)
              </Typography>
            )}
          </Typography>
          {note && <Typography variant={"caption"}>{note}</Typography>}
        </Box>
      )}

      <Box
        display={"flex"}
        alignItems={"flex-end"}
        justifyContent={"space-between"}
        gap={0.1}
      >
        {!isEditing && (
          <>
            {!isPrimary && (
              <IconButton
                sx={{ ml: 0 }}
                onClick={() => makePrimary()}
                disabled={isSaving}
              >
                <VerifiedUserTwoToneIcon
                  style={{
                    cursor: "pointer",
                    color: isSaving ? "gray" : "green",
                  }}
                  fontSize="small"
                />
              </IconButton>
            )}
            <IconButton
              sx={{ ml: 0 }}
              onClick={() => setIsEditing(true)}
              disabled={isSaving}
            >
              <EditTwoToneIcon
                fontSize="small"
                style={{ cursor: "pointer", color: "#0E46A3" }}
              />
            </IconButton>
            <IconButton
              sx={{ ml: 0 }}
              onClick={() => setIsOpenDelete(true)}
              disabled={isSaving}
            >
              <HighlightOffTwoToneIcon
                style={{
                  cursor: "pointer",
                  color: "red",
                }}
                fontSize="small"
              />
            </IconButton>
          </>
        )}
      </Box>

      {isEditing && (
        <>
          <Stack
            spacing={1}
            direction={"column"}
            alignItems={"flex-end"}
            justifyContent={"space-between"}
            width={"100%"}
          >
            <input
              value={updatedEntry}
              style={{
                width: "100%",
                border: "none",
                outline: "none",
                backgroundColor: "transparent",
                borderBottom: "1px solid #000",
              }}
              onChange={(e) => setUpdatedEntry(e.target.value)}
            />
            <input
              value={updatedNote}
              style={{
                width: "100%",
                border: "none",
                outline: "none",
                backgroundColor: "transparent",
                borderBottom: "1px solid #000",
              }}
              onChange={(e) => setUpdatedNote(e.target.value)}
            />
          </Stack>
          <IconButton
            sx={{ ml: 0 }}
            onClick={() => handleSave()}
            disabled={isSaving}
          >
            <CheckCircleTwoToneIcon
              style={{
                cursor: "pointer",
              }}
              fontSize="small"
            />
          </IconButton>

          <IconButton
            sx={{ ml: 0 }}
            onClick={() => {
              setUpdatedEntry(phone);
              setIsEditing(false);
            }}
          >
            <HighlightOffTwoToneIcon
              style={{
                cursor: "pointer",
                color: "red",
              }}
              fontSize="small"
              onClick={() => {}}
            />
          </IconButton>
          {/* <Button
            variant={"outlined"}
            onClick={() => handleSave()}
            sx={{ ...smallButton, maxWidth: 120 }}
          >
            Save
          </Button>
          <Button
            variant={"outlined"}
            onClick={() => {
              setUpdatedEntry(phone);
              setIsEditing(false);
            }}
            sx={smallButton}
          >
            Cancel
          </Button> */}
        </>
      )}
      <ConfirmDialog
        title="Delete Phone number?"
        open={isOpenDelete}
        setOpen={setIsOpenDelete}
        onConfirm={handleDelete}
      >
        {`Are you sure you want to delete "${phone}"?`}
      </ConfirmDialog>
    </Stack>
  );
}
