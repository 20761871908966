import api from "./api";
import ApiBase from "./apiBase";
import { filtersToApi, orderingToApi } from "./common";

class ProductsApi extends ApiBase {
  constructor() {
    super("/products/products");
  }
}

const productsApi = new ProductsApi();

export default productsApi;

export async function checkSkuAvailable(sku) {
  const res = await api.get(`/api/products/check_sku/?sku=${sku}`);
  return res.data.results.length == 0;
}

export async function getProduct(id) {
  const res = await api.get(`/api/products/products/${id}/`);
  return res;
}

export async function getProductVariant(id, vid) {
  const res = await api.get(`/api/products/variants/${vid}/`);
  return res;
}

export async function deleteProductVariant(id, vid) {
  const res = await api.delete(`/api/products/products/${id}/variants/${vid}/`);
  return res;
}

export async function getStoreProductVariants(id, accountId) {
  const res = await api.get(
    `/api/products/store/products/${id}/variants/?account_id=${accountId}`
  );
  return res.data;
}

export async function exportProducts(ids) {
  const res = await api.get("/api/products/export_products/", {
    responseType: "blob",
  });
  return res;
}

export async function createProductOverride(id, data) {
  if (data.tp === "customer") {
    return api.post(`/api/products/products/${id}/customers_overrides/`, data);
  }
  if (data.tp === "account") {
    return api.post(`/api/products/products/${id}/accounts_overrides/`, data);
  }
  if (data.tp === "territory") {
    return api.post(
      `/api/products/products/${id}/territories_overrides/`,
      data
    );
  }
}

export async function updateProductOverride(id, overrideId, data, type) {
  if (type === "customer") {
    return api.patch(
      `/api/products/products/${id}/customers_overrides/${overrideId}/`,
      data
    );
  }
  if (type === "account") {
    return api.patch(
      `/api/products/products/${id}/accounts_overrides/${overrideId}/`,
      data
    );
  }
  if (type === "territory") {
    return api.patch(
      `/api/products/products/${id}/territories_overrides/${overrideId}/`,
      data
    );
  }
}

export async function updateVariantOverride(id, overrideId, data, type, vid) {
  if (type === "customer") {
    return api.patch(
      `/api/products/products/${id}/variants/${vid}/customers_overrides/${overrideId}/`,
      data
    );
  }
  if (type === "account") {
    return api.patch(
      `/api/products/products/${id}/variants/${vid}/accounts_overrides/${overrideId}/`,
      data
    );
  }
  if (type === "territory") {
    return api.patch(
      `/api/products/products/${id}/variants/${vid}/territories_overrides/${overrideId}/`,
      data
    );
  }
}

export async function deleteProductOverride(productId, overrideId, type) {
  if (type === "customer") {
    return api.delete(
      `/api/products/products/${productId}/customers_overrides/${overrideId}/`
    );
  }
  if (type === "account") {
    return api.delete(
      `/api/products/products/${productId}/accounts_overrides/${overrideId}/`
    );
  }
  if (type === "territory") {
    return api.delete(
      `/api/products/products/${productId}/territories_overrides/${overrideId}/`
    );
  }
}

export async function deleteAccountTypeVariantOverride(
  productId,
  overrideId,
  type,
  vid
) {
  return api.delete(
    `/api/products/products/${productId}/variants/${vid}/accounts_types_overrides/${overrideId}/`
  );
}

export async function deleteVariantOverride(productId, overrideId, type, vid) {
  if (type === "customer") {
    return api.delete(
      `/api/products/products/${productId}/variants/${vid}/customers_overrides/${overrideId}/`
    );
  }
  if (type === "account") {
    return api.delete(
      `/api/products/products/${productId}/variants/${vid}/accounts_overrides/${overrideId}/`
    );
  }
  if (type === "territory") {
    return api.delete(
      `/api/products/products/${productId}/variants/${vid}/territories_overrides/${overrideId}/`
    );
  }
}

export async function getProductAccountsOverrides(id) {
  const res = await api.get(`/api/products/products/${id}/accounts_overrides/`);
  return res.data;
}

export async function getProductCustomerOverrides(id) {
  const res = await api.get(
    `/api/products/products/${id}/customers_overrides/`
  );
  return res.data;
}

export async function getProductTerritoriesOverrides(id) {
  const res = await api.get(
    `/api/products/products/${id}/territories_overrides/`
  );
  return res.data;
}

export async function createAccountTypeOverride(pid, id, data) {
  return api.post(
    `/api/products/products/${pid}/variants/${id}/accounts_types_overrides/`,
    data
  );
}
export async function createVariantOverride(pid, id, data) {
  if (data.tp === "customer") {
    return api.post(
      `/api/products/products/${pid}/variants/${id}/customers_overrides/`,
      data
    );
  }
  if (data.tp === "account") {
    return api.post(
      `/api/products/products/${pid}/variants/${id}/accounts_overrides/`,
      data
    );
  }
  if (data.tp === "territory") {
    return api.post(
      `/api/products/products/${pid}/variants/${id}/territories_overrides/`,
      data
    );
  }
}

export async function getVariantAccountsOverrides(pid, id) {
  const res = await api.get(
    `/api/products/products/${pid}/variants/${id}/accounts_overrides/`
  );
  return res.data;
}

export async function getVariantCustomerOverrides(pid, id) {
  const res = await api.get(
    `/api/products/products/${pid}/variants/${id}/customers_overrides/`
  );
  return res.data;
}

export async function getVariantTerritoriesOverrides(pid, id) {
  const res = await api.get(
    `/api/products/products/${pid}/variants/${id}/territories_overrides/`
  );
  return res.data;
}

export async function getVariantAccountTypesOverrides(pid, id) {
  const res = await api.get(
    `/api/products/products/${pid}/variants/${id}/accounts_types_overrides/`
  );
  return res.data;
}

export async function updateProductVariant(id, vid, formData) {
  return api.patch(`/api/products/variants/${vid}/`, formData);
}

export async function getCostChanges(id, vid) {
  const res = await api.get(
    `/api/products/products/${id}/variants/${vid}/cost_changes/`
  );
  return res.data;
}

export async function createCostChange(id, vid, payload) {
  return api.post(
    `/api/products/products/${id}/variants/${vid}/cost_changes/`,
    payload
  );
}

export async function updateCostChange(id, vid, cid, payload) {
  return api.patch(
    `/api/products/products/${id}/variants/${vid}/cost_changes/${cid}/`,
    payload
  );
}

export async function deleteCostChange(id, vid, cid) {
  return api.delete(
    `/api/products/products/${id}/variants/${vid}/cost_changes/${cid}/`
  );
}

export async function deleteProductImage(id, imageId) {
  return api.delete(`/api/products/products/${id}/images/${imageId}/`);
}

export async function deleteProductVariantImage(id, vid, imageId) {
  return api.delete(
    `/api/products/products/${id}/variants/${vid}/images/${imageId}/`
  );
}

export async function createVariantUpcharge(vid, payload) {
  const res = await api.post(
    `/api/products/variants/${vid}/create_variant_override/`,
    payload
  );
  return res;
}

export async function listVariantUpcharges(vid) {
  const res = await api.get(
    `/api/products/variants/${vid}/create_variant_override/`
  );
  return res;
}

export async function deleteVariantUpcharge(vid, id) {
  const res = await api.delete(
    `/api/products/variants/${vid}/create_variant_override/${id}/`
  );
  return res;
}

export async function updateVariantUpcharge(vid, id, payload) {
  const res = await api.patch(
    `/api/products/variants/${vid}/create_variant_override/${id}/`,
    payload
  );
  return res;
}

export async function listProductOrders(
  id,
  pageSize,
  page,
  orderBy,
  filterBy,
  search_params
) {
  const orderByString = orderingToApi(orderBy);
  const filterByString = filtersToApi(filterBy, search_params);
  const params = `page_size=${pageSize}&page=${page}&ordering=${orderByString}&${filterByString}`;
  const result = await api.get(
    `/api/products/products/${id}/orders/?${params}`
  );
  return result;
}

export async function listProductReturns(
  id,
  pageSize,
  page,
  orderBy,
  filterBy,
  search_params
) {
  const orderByString = orderingToApi(orderBy);
  const filterByString = filtersToApi(filterBy, search_params);
  const params = `page_size=${pageSize}&page=${page}&ordering=${orderByString}&${filterByString}`;
  const result = await api.get(
    `/api/products/products/${id}/returns/?${params}`
  );
  return result;
}

export async function listVariantOrders(
  id,
  pageSize,
  page,
  orderBy,
  filterBy,
  search_params
) {
  const orderByString = orderingToApi(orderBy);
  const filterByString = filtersToApi(filterBy, search_params);
  const params = `page_size=${pageSize}&page=${page}&ordering=${orderByString}&${filterByString}`;
  const result = await api.get(
    `/api/products/variants/${id}/orders/?${params}`
  );
  return result;
}
export async function listVariantReturns(
  id,
  pageSize,
  page,
  orderBy,
  filterBy,
  search_params
) {
  const orderByString = orderingToApi(orderBy);
  const filterByString = filtersToApi(filterBy, search_params);
  const params = `page_size=${pageSize}&page=${page}&ordering=${orderByString}&${filterByString}`;
  const result = await api.get(
    `/api/products/variants/${id}/returns/?${params}`
  );
  return result;
}

// Account Restrictions
export async function getAccountRestrictions(
  accountId,
  pageSize,
  page,
  orderBy,
  filterBy
) {
  const orderByString = orderingToApi(orderBy);
  const filterByString = filtersToApi(filterBy);
  const params = `accountId=${accountId}&page_size=${pageSize}&page=${page}&ordering=${orderByString}&${filterByString}`;
  const result = await api.get(
    `/api/products/product_account_restrictions/?${params}`
  );
  return result;
}

export async function postAccountRestrictions(
  accountId,
  product,
  note,
  policy
) {
  const result = await api.post(`/api/products/product_account_restrictions/`, {
    account: accountId,
    product: product,
    note: note,
    policy: policy,
  });
  return result;
}

export async function deleteAccountRestrictions(restrictionId) {
  const result = await api.delete(
    `/api/products/product_account_restrictions/${restrictionId}/`
  );
  return result;
}

export async function getVariants(
  pageSize,
  page,
  orderBy,
  filterBy,
  search_params
) {
  const orderByString = orderingToApi(orderBy);
  const filterByString = filtersToApi(filterBy, search_params);
  const params = `page_size=${pageSize}&page=${page}&ordering=${orderByString}&${filterByString}`;
  const result = await api.get(`/api/products/variants/?${params}`);
  return result;
}

export async function cloneVariant(vid, payload) {
  const res = await api.post(`/api/products/clone_variant/${vid}/`, payload);
  return res;
}
export async function getProductCSV(productId, filterBy, selectedIds) {
  const filterByString = filtersToApi(filterBy);
  const res = await api.get(
    `/api/products/products/${productId}/orders_csv/?${filterByString}&ids=${selectedIds}`
  );
  return res;
}
export async function getVariantCSV(variantId, filterBy, selectedIds) {
  const filterByString = filtersToApi(filterBy);
  const res = await api.get(
    `/api/products/variants/${variantId}/orders_csv/?${filterByString}&ids=${selectedIds}`
  );
  return res;
}

export async function emailVariantPrices() {
  const res = await api.get(`/api/products/export_variant_overrides_csv/`);
  return res;
}

export async function listCustomerVariantOrders(
  id,
  pageSize,
  page,
  orderBy,
  filterBy,
  search_params
) {
  const orderByString = orderingToApi(orderBy);
  const filterByString = filtersToApi(filterBy, search_params);
  const params = `page_size=${pageSize}&page=${page}&ordering=${orderByString}&${filterByString}`;
  const result = await api.get(`/api/orders/items_history/${id}/?${params}`);
  return result;
}
export async function listCustomerVariantOrdersCSV(
  id,
  pageSize,
  page,
  orderBy,
  filterBy,
  idsString
) {
  const search_params = [];
  const orderByString = orderingToApi(orderBy);
  const filterByString = filtersToApi(filterBy, search_params);
  const params = `page_size=${pageSize}&page=${page}&ordering=${orderByString}&${filterByString}&ids=${idsString}`;
  const result = await api.get(
    `/api/orders/items_history_csv/${id}/?${params}`
  );
  return result;
}
export async function listCustomerVariantReturns(
  id,
  pageSize,
  page,
  orderBy,
  filterBy,
  search_params
) {
  const orderByString = orderingToApi(orderBy);
  const filterByString = filtersToApi(filterBy, search_params);
  const params = `page_size=${pageSize}&page=${page}&ordering=${orderByString}&${filterByString}`;
  const result = await api.get(`/api/orders/returns_history/${id}/?${params}`);
  return result;
}

export async function listCustomerVariantReturnsCSV(
  id,
  pageSize,
  page,
  orderBy,
  filterBy,
  idsString
) {
  const search_params = [];
  const orderByString = orderingToApi(orderBy);
  const filterByString = filtersToApi(filterBy, search_params);
  const params = `page_size=${pageSize}&page=${page}&ordering=${orderByString}&${filterByString}&ids=${idsString}`;
  const result = await api.get(
    `/api/orders/returns_history_csv/${id}/?${params}`
  );
  return result;
}
