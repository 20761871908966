import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from "@mui/material";

const DialogComponent = ({ open, handleClose, title, children, actions }) => (
  <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog">
    <DialogTitle id="form-dialog">{title}</DialogTitle>
    <DialogContent>
      <Stack
        direction="column"
        alignItems="flex-start"
        justifyContent="flex-start"
        spacing="15px"
      >
        {children}
      </Stack>
    </DialogContent>
    <DialogActions>{actions}</DialogActions>
  </Dialog>
);

export default DialogComponent;
