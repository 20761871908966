import React, { useMemo, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import "swiper/css";
import "swiper/css/navigation";
import {
  routesReports,
  updateHandover,
  getReport,
  bulkPatch,
} from "../../../distflowAPI/routesTwo";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/system";
import { updateOrderItem } from "../../../distflowAPI/ordersApi";
import ErrorPopupBoundary from "../../../components/ErrorPopupBoundary";
import { ErrorBoundary } from "react-error-boundary";
import DoneAllOutlinedIcon from "@mui/icons-material/DoneAllOutlined";
import { fireErrorNotification, fireSuccessNotification } from "../../../utils";

const PickupVendor = styled(Box)((props) => {
  return {
    display: "flex",
    alignItems: "center",
    color: "#252525",
    gap: "9px",
    fontWeight: 600,
    fontSize: 14,
    "&::before": {
      content: "''",
      display: "inline-flex",
      width: 1,
      height: 12,
      flexShrink: 0,
      borderRadius: "50%",
      backgroundColor: props.status === "PICKED_UP" ? "#3FC6A5" : "#E2E2E2",
    },
  };
});

function DeliveryModal(props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { ...restProps } = props;

  const [report, setReport] = useState(props.initRouteData);
  const [isRefreshing, setIsRefreshing] = useState(false);

  function refreshTable() {
    setIsRefreshing(true);
    getReport(report.id).then(
      (res) => {
        setReport(res.data.results[0]);
        setIsRefreshing(false);
        props.refreshTable();
      },
      (error) => {}
    );
  }

  function getDeliveries() {
    const resultTwo = {
      accounts: {},
      routes: {},
    };

    report.pick_sheet_items.map((pickSheetItem) => {
      pickSheetItem.orders_items.map((orderItem) => {
        if (!orderItem.is_handover && !orderItem.is_handover_to_pick_up) {
          //  ;
          if (!resultTwo.accounts[orderItem.account.accountId]) {
            resultTwo.accounts[orderItem.account.accountId] = {
              accountName: orderItem.account.accountName,
              // variant: pickSheetItem.variant,
              order_items: [],
            };
          }
          resultTwo.accounts[orderItem.account.accountId].order_items.push({
            ...orderItem,
            variant: pickSheetItem.variant,
          });
        }
        if (orderItem.is_handover && orderItem.is_handover_to_pick_up) {
          // ignore this case
          if (!resultTwo.accounts[orderItem.account.accountId]) {
            resultTwo.accounts[orderItem.account.accountId] = {
              accountName: orderItem.account.accountName,
              // variant: pickSheetItem.variant,
              order_items: [],
            };
          }
          resultTwo.accounts[orderItem.account.accountId].order_items.push({
            ...orderItem,
            id: orderItem.order_item.orderItemId,
            variant: pickSheetItem.variant,
          });
        }
        if (orderItem.is_handover && !orderItem.is_handover_to_pick_up) {
          // gotta deliver to a route
          if (!resultTwo.routes[orderItem.route.id]) {
            resultTwo.routes[orderItem.route.id] = {
              routeName: orderItem.route.name,
              variant: pickSheetItem.variant,
              to_handover: [],
            };
          }
          resultTwo.routes[orderItem.route.id].to_handover.push(orderItem);
        }
      });
    });
    return resultTwo;
  }

  return (
    <Dialog
      {...restProps}
      fullScreen={fullScreen}
      maxWidth="xs"
      sx={{
        "& .MuiPaper-root": { width: "100%", maxWidth: "900px" },
        zIndex: 1500,
      }}
    >
      <ErrorBoundary
        fallback={<ErrorPopupBoundary onClose={restProps.onClose} />}
      >
        <DialogTitle
          sx={{
            textAlign: "center",
            fontWeight: 600,
            color: "#252525",
            paddingBottom: 0,
          }}
        >
          <Typography>
            <b>
              {report.route.name} {report.route.date}
            </b>
          </Typography>
        </DialogTitle>

        {isRefreshing && (
          <Typography textAlign="center" sx={{ color: "orange" }}>
            Saving...
          </Typography>
        )}
        <IconButton
          aria-label="close"
          onClick={() => props.onClose()}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent
          sx={{ display: "flex", flexDirection: "column", gap: "12px" }}
        >
          {/*JSON.stringify(getDeliveries())*/}
          {Object.keys(getDeliveries().accounts).map((accountId, i) => (
            <AccountDelivery
              key={i}
              deliveries={getDeliveries().accounts[accountId]}
              refreshTable={refreshTable}
              accountId={accountId}
              setIsRefreshing={setIsRefreshing}
            />
          ))}
          {Object.keys(getDeliveries().routes).map((routeId, i) => (
            <RouteDelivery
              key={i}
              deliveries={getDeliveries().routes[routeId]}
              refreshTable={refreshTable}
              routeId={routeId}
              setIsRefreshing={setIsRefreshing}
            />
          ))}
        </DialogContent>
      </ErrorBoundary>
    </Dialog>
  );
}

const canHandoverReasons = {
  PENDING_PICKUP: "Not Picked Up",
  DELIVERED: "Delivered",
};

function RouteDelivery({ deliveries, refreshTable, routeId, setIsRefreshing }) {
  const [isSaving, setIsSaving] = useState(false);

  function markHandedOver(item) {
    //  ;
    setIsSaving(true);
    updateHandover(item.handoverId, { is_handed_over: true }).then(
      (success) => {
        refreshTable();
        setIsSaving(false);
      },
      (error) => {
        const errorFields = Object.keys(error.response.data);
        errorFields.forEach((field) => {
          fireErrorNotification(`${field}: ${error.response.data[field][0]}`);
        });
        setIsSaving(false);
      }
    );
  }

  function canHandover(item) {
    const result = { canHandover: true };
    if (item.handover_status != "PICKED_UP") {
      result.canHandover = false;
      result.reason =
        canHandoverReasons[item.handover_status] || item.handover_status;
    }
    return result;
  }

  const isCanHandoverAnyItem = !!deliveries.to_handover.filter(
    (el) => canHandover(el).canHandover
  ).length;

  const handleDeliveryAll = async (e) => {
    e.stopPropagation();
    setIsRefreshing(true);

    const items = deliveries.to_handover;

    for (let i = 0; i < items.length; i++) {
      if (canHandover(items[i]).canHandover) {
        await updateHandover(items[i].handoverId, { is_handed_over: true })
          .then(() => {
            fireSuccessNotification(
              `All available products handovered successfully`
            );
          })
          .catch((error) => {
            const errorFields = Object.keys(error.response.data);
            errorFields.forEach((field) => {
              fireErrorNotification(
                `${field}: ${error.response.data[field][0]}`
              );
            });
          });
      }
    }
    refreshTable();
  };

  return (
    <Accordion
      key={routeId}
      sx={{
        boxShadow: "none",
        border: "1px solid #DBDFEA",
        borderRadius: "5px",
        "&.Mui-expanded": {
          margin: 0,
        },
        "&::before": {
          content: "none",
        },
        "& .MuiAccordionSummary-root.Mui-expanded": {
          minHeight: "49px",
        },
        "& .MuiAccordionSummary-content.Mui-expanded": {
          margin: 0,
        },
      }}
    >
      <AccordionSummary
        id={routeId}
        sx={{
          "& .MuiAccordionSummary-content": {
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
          },
        }}
      >
        <PickupVendor>
          {deliveries.routeName}
          {/*
                {isPickingUpFromDifferentRoute(pickup.vendor) && <Typography fontSize={12} color="#A0A0A0">
                    Pickup From {getPickupRouteName(pickup.vendor)}
                </Typography>}
                */}
        </PickupVendor>
        {isCanHandoverAnyItem && (
          <IconButton
            onClick={handleDeliveryAll}
            sx={{
              marginLeft: "auto",
            }}
          >
            <DoneAllOutlinedIcon />
          </IconButton>
        )}
      </AccordionSummary>
      <AccordionDetails sx={{ padding: "0 14px 14px" }}>
        <Grid container spacing={1}>
          {deliveries.to_handover.map((item, i) => (
            <Grid item sm={6} key={i}>
              <Grid container border={"0.9px solid #DBDFEA"} p={1} spacing={1}>
                <Grid item sm={8}>
                  <Stack>
                    <Typography>{deliveries.variant.name}</Typography>
                    {item.note ? (
                      <small>
                        {" "}
                        <b>Note: </b>
                        {item.note}
                      </small>
                    ) : (
                      ""
                    )}
                  </Stack>
                </Grid>
                <Grid item sm={4}>
                  <Stack
                    direction="column"
                    justifyContent="flex-end"
                    alignItems="flex-end"
                    gap={1}
                  >
                    <b>
                      {item.picked_up_qty}/{item.quantity} {item.unit}
                    </b>

                    <Button
                      variant="outlined"
                      disabled={!canHandover(item).canHandover}
                      onClick={() => markHandedOver(item)}
                    >
                      {canHandover(item).canHandover
                        ? "Mark Handover"
                        : canHandover(item).reason}
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

const reasonsMapper = {
  APPROVED: "Not Picked Up",
  DELIVERED: "Delivered",
};

function AccountDelivery({
  deliveries,
  refreshTable,
  accountId,
  setIsRefreshing,
}) {
  const [saving, setIsSaving] = useState(false);
  function getSplitted() {
    const result = {
      nonCn: {
        order_items: [],
      },
      cn: {},
    };
    deliveries.order_items.map((oi) => {
      if (oi.cn_pd) {
        if (!result.cn[oi.cn_pd]) {
          result.cn[oi.cn_pd] = [];
        }
        result.cn[oi.cn_pd].push(oi);
      } else {
        result.nonCn.order_items.push(oi);
      }
    });
    return result;
  }
  const splitted = useMemo(() => {
    return getSplitted();
  }, [deliveries]);
  function canDeliver(item) {
    const result = {
      canDeliver: true,
    };
    if (item.status != "PICKED_UP") {
      result.canDeliver = false;
      result.reason = reasonsMapper[item.status] || item.status;
    }
    return result;
  }

  function markAsDelivered(id) {
    setIsSaving(true);
    updateOrderItem(id, { is_delivered: true }).then(
      (success) => {
        refreshTable();
        setIsSaving(false);
      },
      (error) => {
        const errorFields = Object.keys(error.response.data);
        errorFields.forEach((field) => {
          fireErrorNotification(`${field}: ${error.response.data[field][0]}`);
        });
        setIsSaving(false);
      }
    );
  }

  const isCanDeliverAnyItem =
    deliveries.order_items.filter((el) => !canDeliver(el).canDeliver).length >
    0;
  const isAllDelivered =
    deliveries.order_items.filter((item) => item.status == "DELIVERED")
      .length == deliveries.order_items.length;
  const isNotPickedUp =
    deliveries.order_items.filter((el) => el.status == "APPROVED").length > 0;
  deliveries.order_items.filter((el) => {});
  const handleDeliveryAll = async (e) => {
    e.stopPropagation();
    //setIsRefreshing(true);

    const items = deliveries.order_items;
    const payload = {};
    for (let i = 0; i < items.length; i++) {
      if (canDeliver(items[i]).canDeliver) {
        if (items[i].order_item) {
          payload[items[i].order_item.orderItemId] = {
            is_delivered: true,
          };
          /*
          await updateOrderItem(items[i].order_item.orderItemId, {
            is_delivered: true,
          })
            .then(() => {
              fireSuccessNotification(
                `All available products delivered successfully`
              );
            })
            .catch((error) => {
              const errorFields = Object.keys(error.response.data);
              errorFields.forEach((field) => {
                fireErrorNotification(
                  `${field}: ${error.response.data[field][0]}`
                );
              });
            });*/
        } else {
          payload[items[i].id] = {
            is_delivered: true,
          };
          /*
          await updateOrderItem(items[i].id, { is_delivered: true })
            .then(() => {
              fireSuccessNotification(
                `All available products delivered successfully`
              );
            })
            .catch((error) => {
              const errorFields = Object.keys(error.response.data);
              errorFields.forEach((field) => {
                fireErrorNotification(
                  `${field}: ${error.response.data[field][0]}`
                );
              });
            });*/
        }
      }
    }
    bulkPatch(payload).then((res) => refreshTable());
  };
  function markCnDelivery(cn) {
    const payload = {};
    splitted.cn[cn].map((item) => {
      if (item.order_item) {
        payload[item.order_item.orderItemId] = {
          is_delivered: true,
        };
      } else {
        payload[item.id] = {
          is_delivered: true,
        };
      }
    });
    bulkPatch(payload).then((res) => refreshTable());
  }
  function canDeliverCn(cn) {
    let canDeliverCount = 0;
    let cantDeliverCount = 0;
    let reason = "";
    splitted.cn[cn].map((item) => {
      const cd = canDeliver(item);
      if (cd.canDeliver) {
        canDeliverCount += 1;
      } else {
        cantDeliverCount += 1;
        reason = cd.reason;
      }
    });
    return {
      canDeliver: cantDeliverCount == 0,
      reason: reason,
    };
  }
  return (
    <Accordion
      key={accountId}
      sx={{
        boxShadow: "none",
        border: "1px solid #DBDFEA",
        borderRadius: "5px",
        "&.Mui-expanded": {
          margin: 0,
        },
        "&::before": {
          content: "none",
        },
        "& .MuiAccordionSummary-root.Mui-expanded": {
          minHeight: "49px",
        },
        "& .MuiAccordionSummary-content.Mui-expanded": {
          margin: 0,
        },
      }}
    >
      <AccordionSummary
        id={accountId}
        sx={{
          "& .MuiAccordionSummary-content": {
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
          },
        }}
      >
        <PickupVendor>
          {deliveries.accountName}
          {/*
                {isPickingUpFromDifferentRoute(pickup.vendor) && <Typography fontSize={12} color="#A0A0A0">
                    Pickup From {getPickupRouteName(pickup.vendor)}
                </Typography>}
                */}
        </PickupVendor>
        {!isAllDelivered && (
          <IconButton
            onClick={(e) => {
              if (!isCanDeliverAnyItem) {
                handleDeliveryAll(e);
              } else {
              }
            }}
            sx={{
              marginLeft: "auto",
              color: isNotPickedUp ? "red" : "grey",
            }}
          >
            <DoneAllOutlinedIcon />
          </IconButton>
        )}
        {isAllDelivered && (
          <IconButton
            sx={{
              marginLeft: "auto",
              color: "green",
            }}
          >
            <DoneAllOutlinedIcon />
          </IconButton>
        )}
      </AccordionSummary>
      <AccordionDetails sx={{ padding: "0 14px 14px" }}>
        <Grid container spacing={1}>
          {splitted.nonCn.order_items.map((item, i) => (
            <Grid item sm={6} key={i}>
              <Grid container border={"0.9px solid #DBDFEA"} p={1} spacing={1}>
                <Grid item sm={8}>
                  <Stack>
                    <Typography>
                      {item.variant.oDisplayName} ({item.variant.sku})
                    </Typography>
                    {item.note ? (
                      <small>
                        <b>Note: </b>
                        {item.note}
                      </small>
                    ) : (
                      ""
                    )}
                  </Stack>
                </Grid>
                <Grid item sm={4}>
                  <Stack
                    direction="column"
                    justifyContent="flex-end"
                    alignItems="flex-end"
                    gap={1}
                  >
                    <b>
                      {item.picked_up_qty}/{item.quantity} {item.unit}
                    </b>
                    <Button
                      variant="outlined"
                      sx={{ textDecoration: "capitalize" }}
                      disabled={!canDeliver(item).canDeliver || saving}
                      onClick={() => {
                        if (item.order_item) {
                          markAsDelivered(item.order_item.orderItemId);
                        } else {
                          markAsDelivered(item.id);
                        }
                      }}
                    >
                      {canDeliver(item).canDeliver
                        ? "Mark Delivery"
                        : canDeliver(item).reason}
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
          ))}
          {Object.keys(splitted.cn).map((cn, i) => (
            <Grid item sm={6} key={i}>
              <Grid container border={"0.9px solid #DBDFEA"} p={1} spacing={1}>
                <Grid item sm={8}>
                  <Stack>
                    <Typography>
                      <b>{cn}</b>
                    </Typography>
                    {splitted.cn[cn].map((item) => (
                      <Stack>
                        <Typography>{item.variant.oDisplayName}</Typography>
                        {item.note ? (
                          <small>
                            {" "}
                            <b>Note: </b>
                            {item.note}
                          </small>
                        ) : (
                          ""
                        )}
                      </Stack>
                    ))}
                  </Stack>
                </Grid>
                <Grid item sm={4}>
                  <Stack
                    direction="column"
                    justifyContent="flex-end"
                    alignItems="flex-end"
                    gap={1}
                  >
                    <Button
                      variant="outlined"
                      sx={{ textDecoration: "capitalize" }}
                      disabled={!canDeliverCn(cn).canDeliver || saving}
                      onClick={() => markCnDelivery(cn)}
                    >
                      {canDeliverCn(cn).canDeliver
                        ? "Mark Delivery"
                        : canDeliverCn(cn).reason}
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

export default DeliveryModal;
