import React from "react";
import { Button } from "@mui/material";
import Spinner from "./Spinner";

/**
 * @param {import("@mui/material").ButtonProps & {
 *  loading?: boolean;
 *  spinnerColor?: string;
 * }} props
 */
const LoadingButton = ({
  loading = false,
  spinnerColor,
  children,
  ...rest
}) => (
  <Button {...rest} disabled={rest.disabled || loading}>
    {loading && <Spinner size="sm" marginRight={8} color={spinnerColor} />}
    {children}
  </Button>
);

export default LoadingButton;
