import { useState } from "react";

import { IconButton, Tooltip, Typography } from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import { useModal } from "mui-modal-provider";
import { CheckCircleOutlineRounded, HighlightOff, VisibilityRounded } from "@mui/icons-material";
import dayjs from "dayjs";
import DataTable from "./DataTable";
import useAppDispatch from "../hooks/useAppDispatch";
import UpdateOverrideRefactoredModal from "./UpdateOverrideRefactoredModal";
import ConfirmDialog from "./ConfirmDialog";
import { ChangesModal } from "./History";
import { DATE_FORMAT_FRONT } from "../utils/constants";

const infoFontSize = "14px";
const defaultCellStyle = { "padding": " 5px 0 5px 18px" };
const defaultColDef = { flex: 1, minWidth: 100 };

const OverridesRefactoredTable = ({ overrides, fetchOverrides, updateOverride, removeOverride, elementKey }) => {
  const { showModal } = useModal();
  const dispatch = useAppDispatch();


  const handleRemove = async (id, overrideId) => {
    await dispatch(removeOverride({ id, overrideId }));
    await dispatch(fetchOverrides( id ));
  };

  const DateRenderer = (params) => {
    return (
      <Typography sx={{ color: "#1D1C1C", fontSize: infoFontSize }}>
        {params.value ? dayjs.utc(params.value).format(DATE_FORMAT_FRONT) : ""}
      </Typography>);
  };
  const OverrideRenderer = (params) => {
    return <Typography sx={{ color: "#1D1C1C", fontSize: infoFontSize }}>{params.value} %</Typography>;
  };
  const OverrideActiveRenderer = (params) => {
    return <Typography sx={{ color: "#1D1C1C", fontSize: infoFontSize, margin: "0 auto 0 10px" }}>
      {params.data.is_current ? <CheckCircleOutlineRounded color="success" /> : <HighlightOff color="error" />}
    </Typography>;
  };
  const ActionRenderer = (params) => {
    const [confirmOpen, setConfirmOpen] = useState(false)
    const historyData = params?.data?.history||[]

    const handleDelete = () => {
      handleRemove(params.data[elementKey], params.data.id);
    }
    return (
      <>
        <IconButton onClick={() => showModal(UpdateOverrideRefactoredModal, {
          data: params.data,
          fetchOverrides: fetchOverrides,
          updateOverride: updateOverride,
          elementKey
        })}>
          <EditOutlinedIcon />
        </IconButton>
        <Tooltip title={`${historyData.length} changes`} onClick={()=>showModal(
          ChangesModal,
          {
            historyData: historyData
          }
        )}>
          <IconButton>
            <VisibilityRounded/>
          </IconButton>
        </Tooltip>
        <IconButton onClick={() => { setConfirmOpen(true) }}>
          <DeleteForeverOutlinedIcon />
        </IconButton>
        <ConfirmDialog
          title="Delete upcharge?"
          open={confirmOpen}
          setOpen={setConfirmOpen}
          onConfirm={handleDelete}
        >
          {`Are you sure you want to remove this ${params.data.override}% override 
          from ${params.data.start_date}${params.data.end_date ? ` to ${params.data.end_date}`: ""}?`}
        </ConfirmDialog>      
      </>
    );
  };

  const columnDefs = [
    {
      headerName: "Status",
      field: "is_current",
      cellRenderer: OverrideActiveRenderer,
      cellStyle: defaultCellStyle,
      flex: 1,
      minWidth: 80
    },
    {
      headerName: "Override",
      field: "override",
      cellRenderer: OverrideRenderer,
      cellStyle: defaultCellStyle,
      flex: 2,
      minWidth: 100
    },
    {
      headerName: "Start date",
      field: "start_date",
      cellRenderer: DateRenderer,
      cellStyle: defaultCellStyle,
      flex: 2,
      minWidth: 140
    },
    { headerName: "End date", field: "end_date", cellRenderer: DateRenderer, cellStyle: defaultCellStyle, flex: 2,  minWidth: 140 },
    { headerName: "Note", field: "note", cellStyle: defaultCellStyle, flex: 6,  autoHeight: true, },
    { headerName: "", field: "actions", cellRenderer: ActionRenderer, cellStyle: defaultCellStyle, flex: 2,  minWidth: 120 }
  ];
  const rowData = [
    ...overrides
  ];
  return (
    <div className="variant-table">
      <DataTable rowData={rowData} columnDefs={columnDefs} defaultColDef={defaultColDef} rowHeight={73} />
    </div>
  );
};

export default OverridesRefactoredTable;
