import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import {
  createInvoice,
  getInvoice,
  updateInvoice,
  updateInvoiceStatus,
} from "../../distflowAPI/invoicesApi";
import { useEffect, useReducer, useState } from "react";
import Block from "../../components/Block";
import Separator from "../../components/Separator";
import dayjs from "dayjs";
import NotesModal from "../../components/NotesModal";
import {
  DATE_FORMAT,
  DATE_FORMAT_FRONT,
  INVOICE_STATUS,
} from "../../utils/constants";
import PageLoading from "../../components/PageLoading";

import { dynamicSort, errorToJson, renderErrors } from "../../utils";
import { downloadFile } from "../../distflowAPI/downloadFile";
import { INVOICE_INIT, reducer, REDUCER_ACTIONS } from "./config";
import InvoiceHeader from "./components/invoiceHeader";
import InvoiceFees from "./components/invoiceFees";
import InvoiceItems from "./components/invoiceItems";
import InvoiceFooter from "./components/invoiceFooter";
import InvoiceReturns from "./components/InvoiceReturns";
import DatePickerCustom2 from "../../components/DatePicker2";
import AccountSelect from "../../components/AsyncSelects/AccountSelect";
import Reconciliations from "../payments/paymentDetailComponents/reconciliations";
import SalesmanSelect from "../../components/AsyncSelects/SalesmanSelect";
function InvoiceCreateUpdate() {
  const { id } = useParams();
  const [data, dispatcher] = useReducer(reducer, { ...INVOICE_INIT, id });
  const [openNotes, setOpenNotes] = useState(false);
  const [isInvoiceLoading, setIsInvoiceLoading] = useState(true);
  const [itemToReturn, setItemToReturn] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [success, setSuccess] = useState("");
  const [errors, setErrors] = useState({});
  const [selectedSalesman, setSelectedSalesman] = useState({
    label: "",
    value: "",
  });
  const [isSalesmanSelect, setIsSalesmanSelect] = useState(true);
  console.log("selec", selectedSalesman);
  useEffect(() => {
    console.log("dispatched data", data);
  }, [data]);
  async function fetchData() {
    setIsInvoiceLoading(true);
    if (!isNaN(parseInt(id))) {
      await getInvoice(id).then((res) => {
        dispatcher({
          type: REDUCER_ACTIONS.SET_VALUES,
          payload: {
            po_id: res.data.po_id,
            items: res.data?.order?.items,
            returns: res.data?.order?.returns,
            fees: res.data?.order?.fees,
            total_paid: res.data?.total_paid,
            notes: res.data?.notes,
            attributed_sales_man: {
              id: res.data?.attributed_sales_man?.id,
              name: res.data?.attributed_sales_man?.name,
            },
            address: res.data.address,
            order: {
              address: res.data.order.address,
              id: res.data.order.id,
              fulfillment_date: res.data.order.fulfillment_date,
              account: res.data?.account,
              po_id: res.data.order.po_id,
            },
            status: res.data.status,
            reconciliations: res.data.credits,
            invoice_payments: res.data.invoice_payments,
            routes: res.data.routes,
          },
        });
      });
    }
    setIsInvoiceLoading(false);
    setIsSubmitting(false);
  }

  useEffect(() => {
    fetchData();
  }, [id]);

  function updateInvoiceData() {
    setIsSubmitting(true);
    setErrors({});
    setSuccess("");

    const payload = {
      date: data.date,
      account_id: data.order.account.id,
      fulfillment_date:
        data.fulfillment_date || data.date || data.order.fulfillment_date,
      items: data.items,
      returns: data.returns,
      fees: data.fees,
      notes: data.notes,
      address: data.address,
      sales_man_id: data.attributed_sales_man?.id,
    };
    if (isNaN(parseInt(data.id))) {
      payload["order"] = data.order;
      console.log("paylaod is", payload);
      createInvoice(payload).then(
        (res) => {
          setIsSubmitting(false);
          setSuccess("Invoice updated successfully");
          window.location.href = `/invoices/invoices/${res.data.id}`;
        },
        (err) => {
          setIsSubmitting(false);
          setErrors(
            errorToJson(err?.response?.data || { error: "Unexpected error" })
          );
        }
      );
      return;
    }
    updateInvoice(data.id, payload).then(
      (res) => {
        setIsSubmitting(false);
        setSuccess("Invoice updated successfully");
        fetchData();
      },
      (err) => {
        setIsSubmitting(false);
        setErrors(
          errorToJson(err?.response?.data || { error: "Unexpected error" })
        );
      }
    );
  }
  function handleSave() {
    updateInvoiceData();
  }
  function handleReset() {
    fetchData();
  }
  function getSavedStatus() {
    let color = "success";
    let saveStatus = "";
    let state = false;
    if (data.config.changed && !data.config.saved) {
      saveStatus = "(Unsaved)";
      color = "error";
    }
    return {
      status: saveStatus,
      color,
      state,
    };
  }
  //test
  return (
    <Box maxWidth="1300px" margin="30px auto 0" sx={{ position: "relative" }}>
      <PageLoading dataLoading={isInvoiceLoading} />

      <InvoiceHeader
        data={data}
        dispatch={dispatcher}
        handleSave={handleSave}
        handleReset={handleReset}
      />
      <Grid container columnSpacing={{ xs: 1, sm: "20px" }} margin="36px 0 0">
        <Grid item width="100%" marginBottom={2}>
          {Object.keys(errors).length > 0 && (
            <Alert severity="error">{renderErrors(errors)}</Alert>
          )}
          <Block>
            <Grid container>
              <Grid item width="100%" xl={4}>
                <Typography fontSize="23px" fontWeight={600} mb="0px">
                  ROYAL KOSHER FOODS
                </Typography>
              </Grid>
              <Grid item width="100%" xl={4} alignItems={"end"}>
                <Stack direction={"row"} justifyContent={"center"}>
                  <Typography
                    fontSize="23px"
                    fontWeight={600}
                    align="center"
                    color={
                      data.status == "UNPAID" || data.status == "VOIDED"
                        ? "red"
                        : data.status == "PAID"
                          ? "green"
                          : "orange"
                    }
                  >
                    {INVOICE_STATUS[data.status]}
                  </Typography>
                  <Typography color={getSavedStatus().color}>
                    {getSavedStatus().status}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item width="100%" xl={4} alignItems={"end"}>
                <Typography fontSize="23px" fontWeight={600} align="right">
                  INVOICE
                </Typography>
              </Grid>
            </Grid>
            <Separator />
            <Grid container marginBottom={0}>
              <Grid item width="100%" xl={4}>
                <DatePickerCustom2
                  controlledValue={dayjs(data.order.fulfillment_date)}
                  setControlledValue={(e) =>
                    dispatcher({
                      type: REDUCER_ACTIONS.UPDATE_ORDER,
                      name: "fulfillment_date",
                      value: e.format(DATE_FORMAT),
                    })
                  }
                />
              </Grid>
              <Grid item width="100%" xl={4}>
                <Typography
                  fontSize="14px"
                  fontWeight={450}
                  mb="0px"
                  align="center"
                >
                  Via: {data.routes?.map((r) => r.name).join(", ")}{" "}
                </Typography>
              </Grid>
              <Grid item width="100%" xl={4} alignItems={"end"}>
                <Typography fontSize="14px" fontWeight={450} align="right">
                  Invoice No:{" "}
                  <b>{data.po_id ? `PO# ${data.po_id}` : `# ${data.id}`}</b>
                </Typography>
              </Grid>
            </Grid>
            <Separator />
            <Grid container>
              <Grid item width="100%" xl={6}>
                <Stack spacing={1}>
                  <Box
                    sx={{ display: "flex", alignItems: "center", gap: "10px" }}
                  >
                    <AccountSelect
                      disabled={
                        !isNaN(parseInt(data.id)) && data.status == "PAID"
                      }
                      sx={{
                        maxWidth: 420,
                        ".MuiInputBase-input": {
                          fontSize: "12px",
                          padding: 0.1,
                        },
                        ".MuiOutlinedInput-root": { padding: 0.1 },
                      }}
                      value={{
                        value: data.order.account.id,
                        label: data.order.account.name,
                      }}
                      onChange={(_e, newValue) => {
                        setIsSalesmanSelect(true);
                        dispatcher({
                          type: REDUCER_ACTIONS.SET_VALUE,
                          name: "attributed_sales_man",
                          value: {
                            id: null,
                            name: "",
                          },
                        });
                        if (newValue && newValue.value) {
                          console.log("new is acc", newValue);
                          if (newValue.attributed_sales_man) {
                            setIsSalesmanSelect(false);

                            dispatcher({
                              type: REDUCER_ACTIONS.SET_VALUE,
                              name: "attributed_sales_man",
                              value: {
                                id: newValue.attributed_sales_man.id,
                                name: newValue.attributed_sales_man.name,
                              },
                            });
                          }
                          dispatcher({
                            type: REDUCER_ACTIONS.UPDATE_ORDER,
                            name: "account",
                            value: {
                              id: newValue.value,
                              name: newValue.label,
                            },
                          });
                          dispatcher({
                            type: REDUCER_ACTIONS.SET_VALUE,
                            name: "address",
                            value: `${newValue.address} \n${newValue.city}, ${newValue.state} ${newValue.zipcode}. \n ${newValue.address_2}`,
                          });
                        } else {
                          dispatcher({
                            type: REDUCER_ACTIONS.UPDATE_ORDER,
                            name: "account",
                            value: {
                              id: null,
                              name: "",
                            },
                          });
                        }
                      }}
                    />
                    <Button
                      sx={{
                        fontWeight: 500,
                        fontSize: "12px",
                        padding: "5",
                        mb: "12px",
                      }}
                      disabled={!data?.order?.account.id}
                      color="secondary"
                      onClick={() => setOpenNotes(true)}
                    >
                      Account Notes
                    </Button>
                  </Box>

                  <TextField
                    rows={3}
                    fullWidth
                    data-testid="test-address-id"
                    multiline
                    value={data.address}
                    onChange={(e) =>
                      dispatcher({
                        type: REDUCER_ACTIONS.SET_VALUE,
                        name: "address",
                        value: e.target.value,
                      })
                    }
                    sx={{
                      maxWidth: 420,
                      ".MuiInputBase-input": { fontSize: "12px" },
                      ".MuiInputBase-multiline": { padding: 0.5 },
                    }}
                  />
                </Stack>
              </Grid>
              <Grid
                item
                width="100%"
                xl={6}
                alignItems={"end"}
                justifyContent={"end"}
              >
                <Typography
                  fontSize="14px"
                  fontWeight={400}
                  mb="0px"
                  align="right"
                >
                  ROYAL KOSHER FOODS
                </Typography>
                <Typography
                  fontSize="14px"
                  fontWeight={400}
                  mb="0px"
                  align="right"
                >
                  4309-14TH AVE
                </Typography>
                <Typography
                  fontSize="14px"
                  fontWeight={400}
                  mb="0px"
                  align="right"
                >
                  Brooklyn, NY 11219
                </Typography>
              </Grid>
            </Grid>
            <Separator />
            {(!data.order.fulfillment_date || !data.order.account.id) &&
              !data.id && (
                <Typography>Select a date and an account to proceed</Typography>
              )}
            {((data.order.fulfillment_date && data.order.account.id) ||
              data.id) && (
              <>
                <InvoiceItems data={data} dispatch={dispatcher} />
                <Separator />
                <InvoiceReturns data={data} dispatch={dispatcher} />
                <Separator />
                <InvoiceFees data={data} dispatch={dispatcher} />
                <Reconciliations payment={data} />
                {data?.invoice_payments?.length > 0 && (
                  <>
                    <Separator />
                    <Stack>
                      <Typography variant="h6">Payments</Typography>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Payment</TableCell>
                            <TableCell>Amount</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {data?.invoice_payments.map((p) => (
                            <TableRow>
                              <TableCell>
                                <Typography
                                  sx={{ cursor: "pointer" }}
                                  onClick={() =>
                                    (window.location.href = `/invoices/payments/${p.group}/details`)
                                  }
                                >
                                  Payment #{p.group}
                                </Typography>
                              </TableCell>
                              <TableCell>${p.amount}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </Stack>
                  </>
                )}
                <Separator />
                <InvoiceFooter data={data} dispatch={dispatcher}>
                  {" "}
                  <SalesmanSelect
                    label="Select a Salesman"
                    name="salesman"
                    required
                    disabled={!isSalesmanSelect}
                    value={{
                      value: data?.attributed_sales_man?.id || "",
                      label: data?.attributed_sales_man?.name || "",
                    }}
                    onChange={(_e, newValue) => {
                      dispatcher({
                        type: REDUCER_ACTIONS.SET_VALUE,
                        name: "attributed_sales_man",
                        value: {
                          id: newValue?.value,
                          name: newValue?.label,
                        },
                      });
                    }}
                    extraStyles={{
                      mt: 1,
                      width: "420px",
                      "& .MuiInputLabel-root": {
                        fontWeight: "lighter",
                        fontSize: "12px",
                      },
                    }}
                  />{" "}
                </InvoiceFooter>
              </>
            )}
          </Block>
        </Grid>
      </Grid>
    </Box>
  );
}

export default InvoiceCreateUpdate;
