import { Box, Typography, Grid, Select, MenuItem } from "@mui/material";
import DatePickerCustom2 from "../../../components/DatePicker2";
import { REDUCER_ACTIONS } from "./config";
import AccountSelect from "../../../components/AsyncSelects/AccountSelect";
import CustomerSelect from "../../../components/AsyncSelects/CustomerSelect";

function PaymentGeneralInfo({data, dispatch}){
  return <Box>
    <Typography component="p" variant="h6" mb={2}>
                Payment info
              </Typography>
  <Grid
    container
    columnSpacing={{ xs: 1, sm: "5px" }}
    margin="3px 0 0"
  >
    <Grid item width="100%" xl={3} md={3} xs={12}>
      <Box>
        <Typography
          fontSize="14px"
          component="p"
          variant="label"
          mb="9px"
        >
          Payment level
        </Typography>
        <Select
          fullWidth
          value={data.payment_level}
          onChange={(e) => dispatch({type: REDUCER_ACTIONS.SET_VAL, value:e.target.value, key: 'payment_level'})}
          sx={{ maxHeight: 40 }}
        >
          <MenuItem value={"account"}>Account</MenuItem>
          <MenuItem value={"customer"}>Customer</MenuItem>
        </Select>
      </Box>
    </Grid>
    {data.payment_level == "account" ? (
      <Grid item width="100%" xl={6} md={6} xs={12}>
        <Box>
          <Typography
            fontSize="14px"
            component="p"
            variant="label"
            mb="9px"
          >
            Account
          </Typography>
          <AccountSelect
            name="account"
            required
            value={data.account}
            onChange={(_e, newValue) => {
              if (newValue && newValue.value) {
                dispatch({type:REDUCER_ACTIONS.SET_VAL, value:newValue, key:'account'});
              }
            }}
          />
        </Box>
      </Grid>
    ) : (
      <Grid item width="100%" xl={6} md={6} xs={12}>
        <Box>
          <Typography
            fontSize="14px"
            component="p"
            variant="label"
            mb="9px"
          >
            Customer
          </Typography>
          <CustomerSelect
            name="customer"
            required
            value={data.customer}
            onChange={(_e, newValue) => {
              if (newValue && newValue.value) {
                dispatch({type:REDUCER_ACTIONS.SET_VAL, value:newValue, key:'customer'});
              }
            }}
          />
        </Box>
      </Grid>
    )}

    <Grid item width="100%" xs={12} md={3} xl={3}>
      <Typography variant="inputLabel" component="p">
        Payment Date
      </Typography>
      <DatePickerCustom2
        controlledValue={data.payment_date}
        setControlledValue={(newVal) =>dispatch({type:REDUCER_ACTIONS.SET_VAL, value:newVal, key:'payment_date'})}
        
      />
    </Grid>
  </Grid>
</Box>
}

export default PaymentGeneralInfo