import { useEffect, useState } from "react";
import Block from "../../components/Block";
import {
  FormControlLabel,
  Grid,
  OutlinedInput,
  Typography,
  Switch,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useSearchDebounce } from "../../utils";
import DatePicker from "../../components/DatePicker2";
import dayjs from "dayjs";
const CreditsFilters = ({ showFilters }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [date, setDate] = useState();
  const [filterDB, setFilterDB] = useSearchDebounce();

  const [filters, setFilters] = useState({
    account_name: searchParams.get("account__name") || "",
    amount: searchParams.get("amount") || "",
    is_used: searchParams.get("is_used") || "",
    date: searchParams.get("date") || "",
  });

  const handleChange = (value) => {
    setSearchParams((params) => {
      const newParams = new URLSearchParams(params);
      Object.keys(value).forEach((key) => {
        if (!!value[key]) {
          newParams.set(key, value[key]);
        } else {
          newParams.delete(key);
        }
      });
      return newParams;
    });
  };

  useEffect(() => {
    setFilterDB(filters);
  }, [filters]);

  useEffect(() => {
    handleChange(filterDB);
  }, [filterDB]);

  useEffect(() => {}, [date]);

  return (
    <>
      {showFilters && (
        <Block
          sx={{
            padding: { xs: "16px", sm: "13px 24px" },
            marginBottom: "24px",
          }}
        >
          <Grid container spacing={1} rowGap="10px">
            {/*<Grid item xs={12} sm={3}>*/}
            {/*  <Typography mb="5px">Search</Typography>*/}
            {/*  <OutlinedInput*/}
            {/*    size="small"*/}
            {/*    sx={{ height: "40px" }}*/}
            {/*    fullWidth*/}
            {/*    value={filters.search}*/}
            {/*    onChange={(e) => setFilters({ ...filters, search: e.target.value })}*/}
            {/*  />*/}
            {/*</Grid>*/}
            <Grid item xs={12} sm={3}>
              <Typography mb="5px">Date</Typography>
              <DatePicker
                controlledValue={date}
                setControlledValue={(newValue) => {
                  setFilters({
                    ...filters,
                    date: dayjs(newValue).format("YYYY-MM-DD"),
                  });
                }}
              />
            </Grid>
            <Grid item xs={12} sm={5}>
              <Typography mb="5px">Account Name</Typography>
              <OutlinedInput
                size="small"
                sx={{ height: "40px" }}
                fullWidth
                value={filters.account__name}
                onChange={(e) =>
                  setFilters({ ...filters, account__name: e.target.value })
                }
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography mb="5px">Amount</Typography>
              <OutlinedInput
                size="small"
                sx={{ height: "40px" }}
                fullWidth
                value={filters.amount}
                onChange={(e) =>
                  setFilters({ ...filters, amount: e.target.value })
                }
              />
            </Grid>

            <Grid item xs={12} sm={1}>
              <Typography mb="5px">Used</Typography>
              <FormControlLabel
                control={
                  <Switch
                    checked={filters.is_used === "True"}
                    onChange={(e) =>
                      setFilters({
                        ...filters,
                        is_used: e.target.checked ? "True" : "False",
                      })
                    }
                  />
                }
              />
            </Grid>
          </Grid>
        </Block>
      )}
    </>
  );
};

export default CreditsFilters;
