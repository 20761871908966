import { Typography } from "@mui/material";
import { Link, NavLink } from "react-router-dom";
import dayjs from "dayjs";
import { DATE_FORMAT_FRONT } from "../../utils/constants";

function InvoiceNameRenderer(params) {
  return (
    <span className="my-renderer">
      <Link
        to={`/invoices/invoices/${params.data.invoice}`}
        style={{ textDecoration: "none" }}
      >
        {params.data?.po_id
          ? `PO# ${params.data.po_id}`
          : `# ${params.data.invoice}`}
      </Link>
    </span>
  );
}
const commissionsCols = [
  {
    headerName: "ID",
    field: "id",
    maxWidth: 80,
  },
  {
    headerName: "Invoice",
    field: "invoice",
    cellRendererSelector: () => {
      return {
        component: InvoiceNameRenderer,
      };
    },
    maxWidth: 120,
  },
  {
    headerName: "Account",
    field: "account.name",
    minWidth: 300,
  },
  {
    headerName: "date",
    field: "creation_date",
    cellRenderer: (params) => (
      <Typography>
        {dayjs.utc(params.data.creation_date).format(DATE_FORMAT_FRONT)}
      </Typography>
    ),
  },
  {
    headerName: "Invoice Amount",
    field: "invoice_amount",
    cellRenderer: (params) => (
      <Typography>${params?.data?.invoice_amount}</Typography>
    ),
    minWidth: 180,
  },
  {
    headerName: "Percentage",
    field: "percentage",
    cellRenderer: (params) => (
      <Typography>{params?.data?.percentage}%</Typography>
    ),
    maxWidth: 120,
  },
  {
    headerName: "Comission",
    field: "value",
    cellRenderer: (params) => <Typography>${params?.data?.value}</Typography>,
    maxWidth: 120,
  },
  {
    headerName: "Payout",
    field: "payout",
    cellRenderer: (params) => (
      <Typography>{params?.data?.payout ? "PAID" : "UNPAID"}</Typography>
    ),
  },
];

export default commissionsCols;
