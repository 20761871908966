import api from "./api";
import { filtersToApi, orderingToApi } from "./common";
import ApiBase from "./apiBase";

const actualOrdersApi = new ApiBase("/orders/actual_orders");

export default actualOrdersApi;

export async function listAutomaticOrders(date, accountId, search_params) {
  let filterBy = filtersToApi([], search_params);
  if (!filterBy.includes("myCustomers")) {
    filterBy = filterBy + "&myCustomers=true";
  }
  const dateParam = `&date=${date}`;
  const accountIdParam = `&accountId=${accountId}`;
  const params = `${filterBy}${date && dateParam}${
    accountId && accountIdParam
  }`;
  const res = await api.get(`/api/orders/auto_orders/?${params}`);
  return res.data;
}

export async function listVariants(pageSize, page, filters, search_params) {
  const filterByString = filtersToApi(filters, search_params);
  const params = `page_size=${pageSize}&page=${page}&${filterByString}`;
  const result = await api.get(`/api/orders/variants/?${params}`);
  return result.data;
}

export async function createAutomaticOrder(payload) {
  const result = await api.post("/api/orders/auto_orders/", payload);
  return result;
}

export async function updateAutomaticOrder(id, payload) {
  const result = await api.patch(`/api/orders/auto_orders/${id}/`, payload);
  return result;
}

export async function listAccountAutomaticOrders(accountId, day = "all") {
  const result = await api.get(
    `/api/orders/account/${accountId}/auto_orders?day=${day}`
  );
  return result;
}

export async function listActualOrders(
  customerId,
  accountId,
  startDate = "",
  endDate = ""
) {
  const params = `customer_id=${customerId}&account_id=${
    accountId || ""
  }&start_date=${startDate || ""}&end_date=${endDate || ""}`;

  const result = await api.get(`/api/orders/actual_orders/?${params}`);
  return result;
}

export async function deleteAutomaticOrder(orderData, day) {
  if (day == "all") {
    const res = await api.delete(`/api/orders/auto_orders/${orderData.id}/`);
    return res;
  } else {
    const result = await updateAutomaticOrder(orderData.id, {
      account: orderData.account.id,
      [`${day}_qty`]: 0,
    });
    return result;
  }
}

export async function cloneOrder(payload) {
  const res = await api.post(`/api/orders/clone_order/`, payload);
  return res;
}

export async function createOrder(payload) {
  const res = await api.post(`/api/orders/actual_orders/`, payload);
  return res;
}

export async function emailOrderCopy(orderId) {
  const res = await api.get(`/api/orders/email_order/?order_id=${orderId}`);
  return res;
}

export async function updateOrderDate(payload) {
  const res = await api.post(`/api/orders/change_order_date/`, payload);
  return res;
}

/**òrderid, date */

export async function updateOrder(orderId, payload) {
  const res = await api.patch(`/api/orders/orders/${orderId}/`, payload);
  return res;
}

export async function updateOrderItem(id, payload) {
  const res = await api.patch(`/api/orders/order_items/${id}/`, payload);
  return res;
}

export async function generateInvoice(orderId) {
  const res = await api.get(`/api/orders/orders/${orderId}/generate_invoice`);
  return res;
}

export async function deletetOrder(orderId) {
  const res = await api.delete(`/api/orders/orders/${orderId}/`);
  return res;
}

export async function createTomorrowsOrders(date) {
  const res = await api.get(
    `/api/orders/process_automatic_orders/?date=${date}`
  );
  return res;
}

export async function downloadInvoice(params) {
  const res = await api.get(`/api/orders/invoices_download/?${params}`);
  return res;
}

export async function getOrderItemHistory(orderItemId) {
  const res = await api.get(`/api/orders/history/orders_items/${orderItemId}/`);
  return res;
}

export async function fetchItemRoute(variantId, accountId, fulfillmentDate) {
  const res = await api.get(
    `/api/orders/variant_route/?variantId=${variantId}&accountId=${accountId}&fulfillment_date=${fulfillmentDate}`
  );
  return res;
}

export async function vdc(payload) {
  const res = await api.post("/api/orders/vendor_report_comparator/", payload, {
    headers: { "content-type": "multipart/form-data" },
  });
  return res;
}

export async function listBankTransactions(
  page,
  pageSize,
  order = [],
  filters = ""
) {
  const res = await api.get(
    `/api/accounting/bank_deposits/?page_size=${pageSize}&page=${page}&${filters}&ordering=${orderingToApi(
      order
    )}`
  );
  return res;
}

export async function updateBankDeposit(id, payload) {
  const res = await api.patch(`/api/accounting/bank_deposits/${id}/`, payload);
  return res;
}

export async function listBankTransactionsBatches(
  page,
  pageSize,
  order = [],
  filters = ""
) {
  const res = await api.get(
    `/api/accounting/bank_deposit_batch/?page_size=${pageSize}&page=${page}&${filters}&ordering=${orderingToApi(
      order
    )}`
  );
  return res;
}
export async function updateCheckBatch(id, payload) {
  const res = await api.patch(
    `/api/accounting/bank_deposit_batch/${id}/`,
    payload
  );
  return res;
}
export async function importBankTransactions(payload) {
  const res = await api.post("/api/accounting/bank_deposits/", payload, {
    headers: { "content-type": "multipart/form-data" },
  });
  return res;
}

export async function listAccountOrderRecommendations(accountId, months) {
  const res = await api.get(
    `/api/orders/account_orders_suggestions/?account_id=${accountId}&months=${months}`
  );
  return res;
}

export async function SkuToVariant(skus) {
  const res = await api.post(`/api/orders/sku_variant/`, { skus });
  return res;
}
export async function generatePriceMismatches(start_date, end_date) {
  const res = await api.get(
    `/api/orders/mismatches/?start_date=${start_date}&end_date=${end_date}`
  );
  return res;
}
export async function generateSteadiesExport(start_date, end_date) {
  const res = await api.get(
    `/api/orders/export_future_orders/?start_date=${start_date}&end_date=${end_date}`
  );
  return res;
}
export async function exportVendorStatement(
  start_date,
  end_date,
  vendor_id,
  include_pd = false,
  include_extra = false
) {
  const res = await api.get(`/api/orders/vendor_statement_export/?
  start_date=${start_date}
  &end_date=${end_date}
  &vendor_id=${vendor_id}
  &include_extra=${include_extra}
  &include_pd=${include_pd}`);
  return res;
}

export async function importSteadies(steadies) {
  const res = await api.post(`/api/orders/import_steadies/`, { steadies });
  return res;
}

export async function markAllDelivered(date) {
  const res = await api.get(`/api/orders/mark_all_delivered/?date=${date}`);
  return res;
}

export async function deleteReturn(id) {
  const res = await api.delete(`/api/orders/returns/${id}/`);
  return res;
}

export async function createReturn(payload) {
  const res = await api.post(`/api/orders/returns/`, payload);
  return res;
}

export async function deleteFee(id) {
  const res = await api.delete(`/api/orders/fees/${id}/`);
  return res;
}

export async function getOrdersStatistics(startDate, endDate, repType) {
  const res = await api.get(
    `/api/orders/statistics/?start_date=${startDate}&end_date=${endDate}&type=${repType}`
  );
  return res;
}

export async function checkSaleable(sku) {
  const res = await api.get(`/api/products/check_saleable/?sku=${sku}`);
  return res;
}

export async function markDelivered(orderId) {
  const res = await api.get(
    `/api/orders/mark_all_delivered/?orderId=${orderId}`
  );
  return res;
}

export async function transferOrder(orderId, newAccountId) {
  const res = await api.patch(`/api/orders/orders/${orderId}/transfer/`, {
    target_account_id: newAccountId,
  });
  return res;
}

export async function getOrder(orderId) {
  const res = await api.get(`/api/orders/get_order/?orderId=${orderId}`);
  return res;
}

export async function getAutomaticOrder(orderId) {
  const res = await api.get(`/api/orders/get_au/?orderId=${orderId}`);
  return res;
}

export async function bulkPushOffAutoOrders(
  accountId,
  disable_from,
  disable_till
) {
  const res = await api.get(
    `/api/orders/bulk_set_next_date/?account_ids=${accountId}&disable_till=${disable_till}&disable_from=${disable_from}`
  );
  return res;
}

export async function cCimportBankTransactions(payload) {
  const res = await api.post("/api/accounting/cc_bank_deposits/", payload, {
    headers: { "content-type": "multipart/form-data" },
  });
  return res;
}

export async function cClistBankTransactions(
  page,
  pageSize,
  order = [],
  filters = ""
) {
  const res = await api.get(
    `/api/accounting/cc_bank_deposits/?page_size=${pageSize}&page=${page}&${filters}&ordering=${orderingToApi(
      order
    )}`
  );
  return res;
}
export async function getBatchSummary(id) {
  const res = await api.get(`/api/accounting/bank_deposits_summary/?id=${id}`);
  return res;
}
export async function getCcBatchSummary(id) {
  const res = await api.get(
    `/api/accounting/cc_bank_deposits_summary/?id=${id}`
  );
  return res;
}
export async function cClistBankTransactionsBatches(
  page,
  pageSize,
  order = [],
  filters = ""
) {
  const res = await api.get(
    `/api/accounting/cc_bank_deposit_batch/?page_size=${pageSize}&page=${page}&${filters}&ordering=${orderingToApi(
      order
    )}`
  );
  return res;
}

export async function cCupdateBankDeposit(id, payload) {
  const res = await api.patch(
    `/api/accounting/cc_bank_deposits/${id}/`,
    payload
  );
  return res;
}
export async function cCupdateCheckBatch(id, payload) {
  const res = await api.patch(
    `/api/accounting/cc_bank_deposit_batch/${id}/`,
    payload
  );
  return res;
}
