import { useEffect, useState } from "react";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Collapse,
  Dialog,
  FormControlLabel,
  IconButton,
  Input,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
  Chip,
} from "@mui/material";
import { styled } from "@mui/system";
import Product from "./Product";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { ReactComponent as Plus } from "../../images/plus.svg";
import { ReactComponent as Trash } from "../../images/trash-alt.svg";
import CheckIcon from "@mui/icons-material/Check";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  createAutomaticOrder,
  updateAutomaticOrder,
} from "../../distflowAPI/ordersApi";
import SeasonPicker from "../../components/SeasonPicker";
import { fireSuccessNotification } from "../../utils";
import DatePickerCustom2 from "../../components/DatePicker2";
import dayjs from "dayjs";
import { setDate } from "date-fns";

const soldByValMapper = {
  PC: "Piece",
  BX: "Box",
  BK: "Bucket",
  BT: "Batter",
  CT: "Container",
  DZ: "Dozen",
  EA: "Each",
  LB: "Lb",
  PK: "Package",
  PL: "pale / bucket",
  SH: "Sheet",
};
const dayAbbrev = {
  mon: "Monday",
  tue: "Tuesday",
  wed: "Wednesday",
  thu: "Thursday",
  fri: "Friday",
  sat: "Saturday",
  sun: "Sunday",
};

const QuantitiesRow = styled(Stack)(() => {
  return {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "6px 10px",
  };
});

const Quantity = styled(Box)(() => {
  return {
    minWidth: "50px",
    fontSize: "14px",
    color: "#313131",
    backgroundColor: "#F5F5F5",
    border: "1px solid #DBDFEA",
    borderRadius: "5px",
    textAlign: "center",
    padding: "1px 5px",
  };
});

const LabelStyled = styled(FormControlLabel)(() => {
  return {
    margin: 0,
    "& .MuiTypography-root": { fontSize: "12px" },
    "& .MuiButtonBase-root": {
      padding: "0 5px 0 0",
      "& svg": { fontSize: "20px" },
    },
  };
});

const AddOrdersModal = (props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { ...restProps } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [note, setNote] = useState(props?.orderData?.notes || "");
  const [unitOption, setUnitOption] = useState(
    props?.orderData?.unit || props.item.sold_by,
  );
  const [holdFrom, setHoldFrom] = useState(
    props?.orderData?.disabled_from
      ? dayjs.utc(props?.orderData?.disabled_from)
      : null,
  );
  const [holdTill, setHoldTill] = useState(
    props?.orderData?.disabled_till
      ? dayjs.utc(props?.orderData?.disabled_till)
      : null,
  );

  function isDisabled() {
    if (holdTill && !holdFrom && holdTill.isAfter(dayjs())) {
      return true;
    }
    if (holdFrom && !holdTill && holdFrom.isBefore(dayjs())) {
      return true;
    }
    if (holdFrom && holdTill) {
      if (dayjs().isAfter(holdFrom) && dayjs().isBefore(holdTill)) {
        return true;
      }
    }
    return false;
  }
  const [checked, setChecked] = useState({
    sat: false,
    fri: false,
    mon: false,
    tue: false,
    wed: false,
    thu: false,
    sun: false,
  });
  const [qty, setQty] = useState({
    sun: props?.orderData?.sun_qty || 0,
    mon: props?.orderData?.mon_qty || 0,
    tue: props?.orderData?.tue_qty || 0,
    wed: props?.orderData?.wed_qty || 0,
    thu: props?.orderData?.thu_qty || 0,
    fri: props?.orderData?.fri_qty || 0,
    sat: props?.orderData?.sat_qty || 0,
  });

  const [autoConfirm, setAutoConfirm] = useState({
    sat: props?.orderData?.sat_auto_confirm || false,
    fri: props?.orderData?.fri_auto_confirm || false,
    mon: props?.orderData?.mon_auto_confirm || false,
    tue: props?.orderData?.tue_auto_confirm || false,
    wed: props?.orderData?.wed_auto_confirm || false,
    thu: props?.orderData?.thu_auto_confirm || false,
    sun: props?.orderData?.sun_auto_confirm || false,
  });

  const [freqs, setFreqs] = useState({
    sat: props?.orderData?.sat_frequency || 1,
    fri: props?.orderData?.fri_frequency || 1,
    mon: props?.orderData?.mon_frequency || 1,
    tue: props?.orderData?.tue_frequency || 1,
    wed: props?.orderData?.wed_frequency || 1,
    thu: props?.orderData?.thu_frequency || 1,
    sun: props?.orderData?.sun_frequency || 1,
  });

  const [lastBills, setLastBills] = useState({
    sat: props?.orderData?.sat_last_order
      ? dayjs(props?.orderData?.sat_last_order, "YYYY-MM-DD")
      : null,
    fri: props?.orderData?.fri_last_order
      ? dayjs(props?.orderData?.fri_last_order, "YYYY-MM-DD")
      : null,
    mon: props?.orderData?.mon_last_order
      ? dayjs(props?.orderData?.mon_last_order, "YYYY-MM-DD")
      : null,
    tue: props?.orderData?.tue_last_order
      ? dayjs(props?.orderData?.tue_last_order, "YYYY-MM-DD")
      : null,
    wed: props?.orderData?.wed_last_order
      ? dayjs(props?.orderData?.wed_last_order, "YYYY-MM-DD")
      : null,
    thu: props?.orderData?.thu_last_order
      ? dayjs(props?.orderData?.thu_last_order, "YYYY-MM-DD")
      : null,
    sun: props?.orderData?.sun_last_order
      ? dayjs(props?.orderData?.sun_last_order, "YYYY-MM-DD")
      : null,
  });

  const [nextBills, setNextBills] = useState({
    sat: props?.orderData?.sat_next_order
      ? dayjs(props?.orderData?.sat_next_order, "YYYY-MM-DD")
      : null,
    fri: props?.orderData?.fri_next_order
      ? dayjs(props?.orderData?.fri_next_order, "YYYY-MM-DD")
      : null,
    mon: props?.orderData?.mon_next_order
      ? dayjs(props?.orderData?.mon_next_order, "YYYY-MM-DD")
      : null,
    tue: props?.orderData?.tue_next_order
      ? dayjs(props?.orderData?.tue_next_order, "YYYY-MM-DD")
      : null,
    wed: props?.orderData?.wed_next_order
      ? dayjs(props?.orderData?.wed_next_order, "YYYY-MM-DD")
      : null,
    thu: props?.orderData?.thu_next_order
      ? dayjs(props?.orderData?.thu_next_order, "YYYY-MM-DD")
      : null,
    sun: props?.orderData?.sun_next_order
      ? dayjs(props?.orderData?.sun_next_order, "YYYY-MM-DD")
      : null,
  });

  const initialConfig = {
    mon: [],
    tue: [],
    wed: [],
    thu: [],
    fri: [],
    sat: [],
    sun: [],
  };

  const [additionalConfig, setAdditionalConfig] = useState(initialConfig);

  useEffect(() => {
    props?.orderData?.advanced_configs?.map((item) => {
      const ssm = String(item.start_month).padStart(2, "0");
      const ssd = String(item.start_day).padStart(2, "0");
      const esm = String(item.end_month).padStart(2, "0");
      const esd = String(item.end_day).padStart(2, "0");
      const newItem = {
        ss: `${ssm}-${ssd}`,
        es: `${esm}-${esd}`,
        qty: item.quantity,
      };
      setAdditionalConfig((prev) => ({
        ...prev,
        [item.day.toLowerCase()]: [...prev[item.day.toLowerCase()], newItem],
      }));
      setChecked((prev) => ({ ...prev, [item.day.toLowerCase()]: true }));
    });
  }, []);

  const qtyChange = (event) => {
    const parsed = parseFloat(event.target.value);
    const newVal = parsed < 0 ? "" : parsed;
    if (`${newVal}`.length <= 6) {
      setQty((prev) => ({ ...prev, [event.target.name]: newVal }));
    }
  };
  const freqChange = (event) => {
    const parsed = parseFloat(event.target.value);
    const newVal = parsed < 0 ? "" : parsed;
    if (`${newVal}`.length <= 6) {
      setFreqs((prev) => ({ ...prev, [event.target.name]: newVal }));
    }
  };
  function setNextBill(day, val) {
    setNextBills({ ...nextBills, [day]: val });
  }
  function setLastBill(day, val) {
    setLastBills({ ...lastBills, [day]: val });
  }
  const handleBlur = (event) => {
    setQty((prev) => ({
      ...prev,
      [event.target.name]: prev[event.target.name] || 0,
    }));
  };

  const configChange = (event) => {
    // open-close new config
    setChecked((prev) => ({
      ...prev,
      [event.target.name]: event.target.checked,
    }));

    // if we don't have any configurations after opening - add one date picker row
    if (event.target.checked && !additionalConfig[event.target.name].length) {
      setAdditionalConfig((prev) => ({
        ...prev,
        [event.target.name]: [{ ss: null, es: null, qty: 0 }],
      }));
    }

    // if we close - del all configs
    if (!event.target.checked) {
      setAdditionalConfig((prev) => ({ ...prev, [event.target.name]: [] }));
    }
  };

  const configAutoConfirm = (event) => {
    setAutoConfirm({
      ...autoConfirm,
      [event.target.name]: event.target.checked,
    });
  };

  function createAutoOrder(event) {
    setIsLoading(true);
    setError("");
    const payload = {
      note,
      item: props.item.id,
      account: props.account.value,
      unit: unitOption,
    };
    let allZeros = true;
    Object.keys(qty).map((key) => {
      payload[`${key}_qty`] = qty[key];
      if (qty[key] > 0) {
        allZeros = false;
      }
    });
    for (let key of Object.keys(qty)) {
      if (
        qty[key] > 0 &&
        qty[key] < props.item.piece_min_qty &&
        unitOption == "PC"
      ) {
        setError(`select a qty>${props.item.piece_min_qty}`);
        setIsLoading(false);
        return;
      }
    }
    if (allZeros) {
      setError("select a qty>0 for at least one day a week");
      setIsLoading(false);
      return;
    }
    let overlappingSchedule = false;
    let overlappingDay = "";
    let badStartEnd = false;
    let badStartEndDay = "";
    payload["additional_configs"] = [];
    Object.keys(additionalConfig).map((day) => {
      const dayConfigs = additionalConfig[day];
      for (let i = 0; i < dayConfigs.length; i++) {
        const dateiStart = new Date("2023-" + dayConfigs[i].ss);
        const dateiEnd = new Date("2023-" + dayConfigs[i].es);
        if (dateiEnd < dateiStart) {
          badStartEnd = true;
          badStartEndDay = day;
        }
        payload["additional_configs"].push({
          start_month: dayConfigs[i].ss.split("-")[0],
          start_day: dayConfigs[i].ss.split("-")[1],
          end_month: dayConfigs[i].es.split("-")[0],
          end_day: dayConfigs[i].es.split("-")[1],
          quantity: dayConfigs[i].qty,
          day: day,
        });
        for (let j = 0; j < dayConfigs.length; j++) {
          if (i == j) {
            continue;
          }
          const datejStart = new Date("2023-" + dayConfigs[j].ss);
          const datejEnd = new Date("2023-" + dayConfigs[j].es);
          if (dateiStart <= datejEnd && datejStart <= dateiEnd) {
            overlappingSchedule = true;
            overlappingDay = day;
            return;
          } else {
          }
        }
      }
    });
    if (overlappingSchedule) {
      setError("Overlapping dates on " + dayAbbrev[overlappingDay]);
      setIsLoading(false);
      return;
    }
    if (badStartEnd) {
      setError(
        "End date cant come before start date (on: " +
          dayAbbrev[badStartEndDay] +
          " )",
      );
      setIsLoading(false);
      return;
    }
    Object.keys(autoConfirm).map(
      (key) => (payload[`${key}_auto_confirm`] = autoConfirm[key]),
    );
    Object.keys(freqs).map((key) => (payload[`${key}_frequency`] = freqs[key]));
    Object.keys(nextBills).map(
      (key) =>
        (payload[`${key}_next_order`] = nextBills[key]
          ? nextBills[key].format("YYYY-MM-DD")
          : null),
    );
    Object.keys(lastBills).map(
      (key) =>
        (payload[`${key}_last_order`] = lastBills[key]
          ? lastBills[key].format("YYYY-MM-DD")
          : null),
    );
    payload["notes"] = note;
    payload["disabled_from"] = holdFrom ? holdFrom.format("YYYY-MM-DD") : null;
    payload["disabled_till"] = holdTill ? holdTill.format("YYYY-MM-DD") : null;
    if (props.orderData) {
      updateAutomaticOrder(props.orderData.id, payload)
        .then(
          (res) => {
            props.onClose();
            props.updateAccountRelated();
            fireSuccessNotification(`Order updated successfully`);
          },
          (error) => {
            if (error.response.data) {
              Object.keys(error.response.data).map((key) =>
                setError(key + ": " + error.response.data[key][0]),
              );
            }
          },
        )
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      createAutomaticOrder(payload)
        .then(
          (res) => {
            props.onClose();
            props.updateAccountRelated();
            fireSuccessNotification(`Order created successfully`);
          },
          (error) => {
            if (error.data) {
              error.data.map((key) => setError(error.data[key][0]));
            }
          },
        )
        .finally(() => {
          setIsLoading(false);
        });
    }
  }

  const quantityConfigRows = [
    { id: 1, name: "sun", day: "Sun", quantity: "120" },
    { id: 2, name: "mon", day: "Mon", quantity: "10" },
    { id: 3, name: "tue", day: "Tue", quantity: "60" },
    { id: 4, name: "wed", day: "Wed", quantity: "40" },
    { id: 5, name: "thu", day: "Thu", quantity: "150" },
    { id: 6, name: "fri", day: "Fri/Erev", quantity: "150" },
  ];

  return (
    <Dialog
      {...restProps}
      fullScreen={fullScreen}
      minWidth="xl"
      sx={{
        "& .MuiPaper-root": {
          width: "100%",
          maxWidth: "800px",
          p: "18px 50px 22px 50px",
        },
        zIndex: 1500,
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb="22px"
      >
        <Typography fontFamily="Roboto" color="#000">
          Add to Orders ordered data
        </Typography>
        <IconButton
          onClick={() => props.onClose()}
          sx={{
            color: "#515151",
            marginRight: "-5px",
          }}
        >
          <CloseIcon />
        </IconButton>
      </Stack>
      {isDisabled() && (
        <Alert severity="error" sx={{ marginBottom: 1 }}>
          This order is on hold
        </Alert>
      )}
      <Box mb="10px">
        <Product item={props.item} hideAdd orderData={props.orderData} />
      </Box>
      <Box mb="10px">
        {props.item.break_into_pieces && !props.orderData && (
          <RadioGroup
            row
            name="unitOption"
            value={unitOption}
            onChange={(e) => {
              setUnitOption(e.target.value);
            }}
          >
            {!props.item.ordered_pc && (
              <FormControlLabel value="PC" control={<Radio />} label="Piece" />
            )}
            {!props.item.ordered && (
              <FormControlLabel
                value={props.item.sold_by}
                control={<Radio />}
                label={soldByValMapper[props.item.sold_by]}
              />
            )}
          </RadioGroup>
        )}
      </Box>
      {error && (
        <Box mb="20px">
          <Alert severity="error">{error}</Alert>
        </Box>
      )}

      <Typography fontSize="12px" fontWeight={500} color="#000" mb="9px">
        Default Quantities{" "}
        {props.orderData && (
          <span>({props.orderData.unit || props.orderData.item.sold_by})</span>
        )}
      </Typography>
      <Box
        border="1px solid #DBDFEA"
        borderRadius="5px"
        mb="9px"
        sx={{ "& > .MuiBox-root:last-child": { borderBottom: "none" } }}
      >
        {quantityConfigRows.map((config) => (
          <QuantityConfigRow
            day={config.day}
            minQty={props.item.piece_min_qty}
            quantity={config.quantity}
            unitOption={unitOption}
            name={config.name}
            additionalConfig={additionalConfig}
            setAdditionalConfig={setAdditionalConfig}
            onChange={configChange}
            handleBlur={handleBlur}
            checked={checked}
            setChecked={setChecked}
            config
            qtyChange={qtyChange}
            qty={qty}
            configAutoConfirm={configAutoConfirm}
            autoConfirm={autoConfirm}
            freqs={freqs}
            freqChange={freqChange}
            lastBills={lastBills}
            nextBills={nextBills}
            setLastBill={setLastBill}
            setNextBill={setNextBill}
          />
        ))}
      </Box>
      {/*  <Typography fontSize="12px" fontWeight={500} color="#000" mb="9px">
        Hold order
      </Typography>
      <Box
        border="1px solid #DBDFEA"
        borderRadius="5px"
        mb="9px"
        sx={{ "& > .MuiBox-root:last-child": { borderBottom: "none" } }}
        padding={1}
      >
        <Stack direction={'row'} spacing={2}>
          <Box >
            <Typography>Hold From</Typography>
            <DatePickerCustom2 controlledValue={holdFrom} setControlledValue={setHoldFrom}/>
          </Box>
          <Box >
            <Typography>Till</Typography>
            <DatePickerCustom2 controlledValue={holdTill} setControlledValue={setHoldTill}/>
          </Box>
        </Stack>
        </Box> */}
      <Typography fontSize="12px" fontWeight={500} color="#000" mb="9px">
        Note
      </Typography>
      <TextField
        multiline
        data-testid="test-note-id"
        rows={3}
        sx={{
          "& .MuiInputBase-root": { fontSize: "14px", padding: "8px 10px" },
        }}
        value={note}
        onChange={(e) => setNote(e.target.value)}
      />
      <Box mt="16px" textAlign="center">
        <Button
          size="small"
          variant="contained"
          disabled={isLoading}
          // startIcon={<Plus width={20} height={20} />}
          sx={{ fontWeight: 500, fontSize: "16px", "& svg": { fill: "#fff" } }}
          onClick={(e) => createAutoOrder()}
        >
          {props.orderData ? "Update Order" : "Add to Orders"}
        </Button>
      </Box>
    </Dialog>
  );
};

function QuantityConfigRow(props) {
  const [editing, setEditing] = useState(false);
  const [recurrence, setRecurrence] = useState(2);
  const [dateEditing, setDateEditing] = useState("");

  const handleDeleteConfig = (index) => {
    // delete current config
    const newConfig = { ...props.additionalConfig };
    newConfig[props.name].splice(index, 1);
    props.setAdditionalConfig(newConfig);

    // if we don't have any configs on this day - close checkbox
    if (!newConfig[props.name].length) {
      props.setChecked((prev) => ({
        ...prev,
        [props.name]: false,
      }));
    }
  };

  const handleAddConfig = () => {
    const newConfig = { ...props.additionalConfig };
    newConfig[props.name].push({ ss: null, es: null, qty: 0 });
    props.setAdditionalConfig(newConfig);
  };

  const handleChipClick = () => {
    setEditing(true);
  };

  const handleIncrease = () => {
    props.freqChange({
      target: {
        name: props?.name,
        value: props.freqs[props?.name] + 1,
      },
    });
  };

  const handleDecrease = () => {
    if (recurrence > 1) {
      props.freqChange({
        target: {
          name: props?.name,
          value: props.freqs[props?.name] - 1,
        },
      });
    }
  };

  const handleSaveRecurrence = () => {
    setEditing(false);
  };
  return (
    <Box borderBottom="1px solid #DBDFEA">
      <QuantitiesRow>
        <Stack direction="row" alignItems="center">
          <Typography minWidth="54px" fontSize="14px" marginRight="15px">
            {props.day}{" "}
            {props.unitOption == "PC" && <small>min qty: {props.minQty}</small>}
          </Typography>
          <div>
            {editing ? (
              <Stack direction="row" alignItems="center" gap="8px">
                <RemoveIcon
                  sx={{
                    fontSize: "15px",
                    fontWeight: "bolder",
                    cursor: "pointer",
                  }}
                  onClick={handleDecrease}
                />

                <Typography>{props.freqs[props?.name]}</Typography>
                <AddIcon
                  sx={{ fontSize: "15px", cursor: "pointer" }}
                  onClick={handleIncrease}
                />
                <Button
                  sx={{
                    fontSize: "13px",
                    minWidth: "0",
                    width: "36px",
                    height: "36px !important",
                  }}
                  variant="contained"
                  color="success"
                  size="small"
                  onClick={handleSaveRecurrence}
                >
                  <CheckIcon fontSize="small" />
                </Button>
              </Stack>
            ) : (
              <Chip
                size="small"
                label={`Every ${props.freqs[props?.name]} week${
                  props.freqs[props?.name] > 1 ? "s" : ""
                }`}
                onClick={handleChipClick}
              />
            )}
          </div>
        </Stack>
        <Stack direction="column">
          {!dateEditing && (
            <>
              <Typography
                sx={{
                  fontSize: "12px",
                  cursor: "pointer",
                  "&:hover": {
                    fontWeight: "bold",
                    textDecoration: "underline",
                  },
                }}
                onClick={() => setDateEditing("lastOrder")}
              >
                Last order:{" "}
                {props.lastBills[props?.name]?.format("MM/DD/YY") || "Not Set"}
              </Typography>
              <Typography
                sx={{
                  fontSize: "12px",
                  cursor: "pointer",
                  "&:hover": {
                    fontWeight: "bold",
                    textDecoration: "underline",
                  },
                }}
                onClick={() => setDateEditing("nextOrder")}
              >
                Next order:{" "}
                {props.nextBills[props?.name]?.format("MM/DD/YY") || "Not Set"}
              </Typography>
            </>
          )}
          {dateEditing === "lastOrder" && (
            <Stack direction="row" alignItems="center" gap="5px">
              <DatePickerCustom2
                maxWidth="170px"
                height="15px"
                label="Last Order"
                controlledValue={props.lastBills[props?.name]}
                setControlledValue={(newVal) =>
                  props.setLastBill(props.name, newVal)
                }
              />

              <Button
                sx={{
                  fontSize: "13px",
                  minWidth: "0",
                  width: "10px",
                  height: "30px !important",
                }}
                variant="contained"
                color="success"
                size="small"
                onClick={() => setDateEditing("")}
              >
                <CheckIcon fontSize="small" />
              </Button>
              <Button
                sx={{
                  padding: "4px 5px",
                  fontSize: "12px",
                  minWidth: "0",
                  width: "30px",
                  height: "30px !important",
                }}
                variant="contained"
                onClick={() => setDateEditing("")}
                color="error"
                size="small"
              >
                <DeleteIcon fontSize="small" />
              </Button>
            </Stack>
          )}
          {dateEditing === "nextOrder" && (
            <Stack direction="row" alignItems="center" gap="5px">
              <DatePickerCustom2
                maxWidth="170px"
                height="15px"
                label="Next Order"
                controlledValue={props.nextBills[props?.name]}
                setControlledValue={(newVal) =>
                  props.setNextBill(props.name, newVal)
                }
              />

              <Button
                sx={{
                  fontSize: "13px",
                  minWidth: "0",
                  width: "10px",
                  height: "30px !important",
                }}
                variant="contained"
                color="success"
                size="small"
                onClick={() => setDateEditing("")}
              >
                <CheckIcon fontSize="small" />
              </Button>
              <Button
                sx={{
                  padding: "4px 5px",
                  fontSize: "12px",
                  minWidth: "0",
                  width: "30px",
                  height: "30px !important",
                }}
                variant="contained"
                onClick={() => setDateEditing("")}
                color="error"
                size="small"
              >
                <DeleteIcon fontSize="small" />
              </Button>
            </Stack>
          )}
        </Stack>
        <Stack direction="row" alignItems="center" gap="17px">
          <Quantity>
            <Input
              label="Qty."
              name={props.name}
              value={props.qty[props?.name]}
              fullWidth
              onChange={props.qtyChange}
              onBlur={props.handleBlur}
              sx={{
                maxWidth: "65px",
                fontSize: "14px",
                color: "black",
                backgroundColor: "transparent",
                border: "none",
                textAlign: "center",
                padding: "1px 5px",
                //padding:"0px",
              }}
              type="number"
              InputProps={{ inputProps: { min: props.minQty } }}
            ></Input>
          </Quantity>
          <Stack direction={{ sx: "column" }}>
            <LabelStyled
              label="Auto Confirm"
              control={
                <Checkbox
                  name={props.name}
                  checked={props.autoConfirm[props?.name]}
                  onChange={props.configAutoConfirm}
                />
              }
            />
            <LabelStyled
              label="Advance"
              control={
                <Checkbox
                  name={props.name}
                  checked={props.checked[props?.name]}
                  onChange={props.onChange}
                />
              }
            />
          </Stack>
        </Stack>
      </QuantitiesRow>
      {props.config ? (
        <Collapse in={props.checked[props?.name]} timeout="auto" unmountOnExit>
          <Stack p="3px 10px 9px" gap="7px">
            {props.additionalConfig[props.name].map((item, index) => {
              return (
                <Stack
                  height={25}
                  direction="row"
                  justifyContent="space-between"
                  gap="6px"
                >
                  <Stack direction="row" gap="6px" flex={1}>
                    <Stack
                      maxWidth="300px"
                      flex={1}
                      direction="row"
                      gap="5px"
                      sx={{
                        "& .MuiInputBase-root input": { fontSize: "12px" },
                        "& .MuiSvgIcon-root": { width: "18px", height: "18px" },
                      }}
                    >
                      <SeasonPicker
                        controlledValue={item.ss}
                        setControlledValue={(newValue) => {
                          const newConfig = { ...props.additionalConfig };
                          newConfig[props.name][index].ss = newValue;
                          props.setAdditionalConfig(newConfig);
                        }}
                      />
                      <SeasonPicker
                        controlledValue={item.es}
                        setControlledValue={(newValue) => {
                          const newConfig = { ...props.additionalConfig };
                          newConfig[props.name][index].es = newValue;
                          props.setAdditionalConfig(newConfig);
                        }}
                      />
                    </Stack>
                    <Quantity>
                      <input
                        name={props.name + "_config_" + index}
                        value={item.qty}
                        type="number"
                        onChange={(e) => {
                          const newConfig = { ...props.additionalConfig };
                          const parsed = parseFloat(e.target.value);
                          const newVal = parsed < 0 ? "" : parsed;
                          if (`${newVal}`.length <= 6) {
                            newConfig[props.name][index].qty = newVal;
                          }
                          props.setAdditionalConfig(newConfig);
                        }}
                        onBlur={() => {
                          const newConfig = { ...props.additionalConfig };
                          if (!newConfig[props.name][index].qty) {
                            newConfig[props.name][index].qty = 0;
                            props.setAdditionalConfig(newConfig);
                          }
                        }}
                        style={{
                          maxWidth: "50px",
                          fontSize: "14px",
                          color: "black",
                          backgroundColor: "transparent",
                          border: "none",
                          textAlign: "center",
                          padding: "1px 0px",
                          //marginBottom:'5px'
                          //padding:"0px",
                        }}
                      />
                    </Quantity>
                  </Stack>
                  <ConfigBtn onClick={handleAddConfig}>
                    <Plus width={12} height={12} />
                  </ConfigBtn>
                  <ConfigBtn onClick={() => handleDeleteConfig(index)}>
                    <Trash width={16} height={16} />
                  </ConfigBtn>
                </Stack>
              );
            })}
          </Stack>
        </Collapse>
      ) : null}
    </Box>
  );
}

function ConfigBtn({ children, onClick }) {
  return (
    <Button
      variant="outlined"
      onClick={onClick}
      sx={{
        width: "25px",
        height: "25px !important",
        minWidth: "auto",
        p: "0 !important",
        ":hover svg": {
          fill: "#fff",
        },
      }}
    >
      {children}
    </Button>
  );
}

export default AddOrdersModal;
