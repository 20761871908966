import { Box, Paper } from "@mui/material";
import Spinner from "./Spinner";

const Block = ({ isLoading, children, ...props }) => {
  return (
    <Paper variant="outlined" sx={{ padding: { xs: "16px", sm: "13px 24px 24px" }, marginBottom: "24px", position: "relative" }} {...props}>
      {isLoading && 
        <Box height="100%"
             display="flex"
             justifyContent="center"
             alignItems="center"
             sx={{
               position: "absolute",
               left: "0",
               top: "0",
               backgroundColor: "#f6f6f79e",
               width: "calc(100%)",
               zIndex: 5
             }}>
          <Spinner />          
        </Box>}
      {children}
    </Paper>
  );
};

export default Block;
