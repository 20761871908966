import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  DialogContent,
  DialogTitle,
  IconButton,
  OutlinedInput,
  Stack,
  Typography,
  Dialog,
  DialogActions,
  TextField,
} from "@mui/material";
import { NavLink, useSearchParams } from "react-router-dom";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import DataTable from "../../components/DataTable";
import useAppDispatch from "../../hooks/useAppDispatch";
import useAppSelector from "../../hooks/useAppSelector";
import {
  fetchProducts,
  selectProductNumberOfPages,
} from "../../redux/module/product";
import productTableColumns from "./productsSearchTableColumns";
import { formatAgGridFilters } from "../../distflowAPI/common";
import ConfirmDialog from "../../components/ConfirmDialog";
import productsApi from "../../distflowAPI/products";
import {
  fireErrorNotification,
  fireSuccessNotification,
  useSearchDebounce,
} from "../../utils";
import ProductFilters from "./ProductFilters";
import * as PropTypes from "prop-types";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import CloseIcon from "@mui/icons-material/Close";
import PageLoading from "../../components/PageLoading";
import { useParams } from "react-router-dom";
import { postCustomerRestrictions } from "../../distflowAPI/customersApi";

const defaultColDef = {
  flex: 1,
  filter: false,
  floatingFilter: false,
};

function InputButton(props) {
  return null;
}

InputButton.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.node,
};
const CustomerProductsSearch = ({
  openProductSearch,
  setOpenProductSearch,
  fetchRestrictions,
}) => {
  const dispatch = useAppDispatch();
  const products = useAppSelector((state) => state.product.products);
  const productsLoading = useAppSelector(
    (state) => state.product.productsLoading,
  );
  const page = useAppSelector((state) => state.product.page);
  const pageSize = useAppSelector((state) => state.product.pageSize);
  const numberOfPages = useAppSelector(selectProductNumberOfPages);
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchProductDB, setSearchProductDB] = useSearchDebounce();

  const [filterBy, setFilterBy] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [searchProduct, setSearchProduct] = useState(
    searchParams.get("search") || "",
  );
  const [showFilters, setShowFilters] = useState(false);

  const fetchTableData = useCallback(
    (params) => {
      dispatch(fetchProducts(params));
    },
    [dispatch],
  );
  useEffect(() => {
    fetchTableData({ filterBy: {}, searchParams });
  }, [fetchTableData, searchParams]);

  const onSelectionChanged = (e) => {
    const selected = e.api.getSelectedRows();
    setSelectedProducts(selected);
  };

  const handleChange = (value) => {
    setSearchParams((params) => {
      const newParams = new URLSearchParams(params);
      if (!!value) {
        newParams.set("search", value);
      } else {
        newParams.delete("search");
      }
      return newParams;
    });
  };

  useEffect(() => {
    setSearchProductDB(searchProduct);
  }, [searchProduct]);

  useEffect(() => {
    handleChange(searchProductDB);
  }, [searchProductDB]);

  function AddRestrictionNoteModal({ fetchRestrictions }) {
    const [open, setOpen] = useState(false);
    const [note, setNote] = useState("");
    const { id } = useParams();

    function handleClick() {
      const requests = selectedProducts.map((restriction) => {
        return postCustomerRestrictions(id, restriction.id, note);
      });
      Promise.all(requests)
        .then((responses) => {
          setOpenProductSearch(false);
          fetchRestrictions();
        })
        .catch((error) => {
          console.error(error);
        });
    }

    return (
      <>
        <Button
          color="error"
          variant="contained"
          sx={{ p: "0px 40px" }}
          onClick={() => setOpen(true)}
          disabled={!selectedProducts.length}
        >
          Restrict Selected Products (
          {selectedProducts.length && <div>{selectedProducts.length}</div>})
        </Button>

        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          style={{
            zIndex: "2000!impotant",
          }}
        >
          <DialogTitle>Add a New Note</DialogTitle>
          <DialogContent>
            <TextField
              fullWidth
              multiline
              placeholder="Type the note..."
              variant="outlined"
              sx={{ width: "500px" }}
              value={note}
              onChange={(e) => setNote(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              onClick={() => {
                handleClick();
              }}
              color="error"
            >
              Confirm Restriction
            </Button>
            <Button variant="contained" onClick={() => setOpen(false)}>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
  return (
    <>
      <Dialog
        open={openProductSearch}
        onClose={() => setOpenProductSearch(false)}
        fullWidth
        maxWidth="xl"
      >
        <DialogContent>
          <PageLoading dataLoading={productsLoading} />

          <div
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <CloseIcon
              sx={{ cursor: "pointer" }}
              onClick={() => {
                setOpenProductSearch(false);
                setSelectedProducts(0);
              }}
            />
          </div>
          <Typography variant="pageTitle" noWrap component="p" mb="20px">
            Product Search
          </Typography>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            spacing="15px"
            mb="20px"
          >
            <OutlinedInput
              placeholder="Search for a Product..."
              sx={{ height: "40px" }}
              fullWidth
              maxWidth="xs"
              value={searchProduct}
              onChange={(e) => {
                setSearchProduct(e.target.value);
                setSelectedProducts([]);
              }}
            />
            <AddRestrictionNoteModal
              restrictedProducts={selectedProducts}
              fetchRestrictions={fetchRestrictions}
            />
            <Button
              className="btn-height"
              variant="outlined"
              endIcon={showFilters ? <FilterAltOffIcon /> : <FilterAltIcon />}
              onClick={() => setShowFilters(!showFilters)}
            >
              Filters
            </Button>
          </Stack>

          <ProductFilters showFilters={showFilters} />

          <Box className="table-wrapper">
            <DataTable
              rowData={products}
              columnDefs={productTableColumns}
              defaultColDef={defaultColDef}
              loading={productsLoading}
              rowHeight={53}
              headerHeight={60}
              suppressSorting={true} //disable default sorting
              rowSelection={"multiple"}
              onSelectionChanged={onSelectionChanged}
              onSortChanged={(event) =>
                fetchTableData({
                  orderBy: event.columnApi.columnModel.gridColumns,
                })
              }
              onFilterChanged={(event) => {
                fetchTableData({ filterBy: event.api.getFilterModel() });
                setFilterBy(
                  formatAgGridFilters(event.api.getFilterModel()) || [],
                );
                console.log("fetching data with: ", {
                  filterBy: event.api.getFilterModel(),
                });
                console.log(
                  "setting filter by to: ",
                  formatAgGridFilters(event.api.getFilterModel()),
                );
              }}
              page={page}
              noPages={numberOfPages}
              setPage={(page) => {
                fetchTableData({ page });
                setSelectedProducts([]);
              }}
            />
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CustomerProductsSearch;
