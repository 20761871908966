import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import {
  Box,
  Button,
  IconButton,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import { memo } from "react";
import { NavLink } from "react-router-dom";
import Dots from "../../images/dots.svg";
import { ReactComponent as Trash } from "../../images/trash-alt.svg";

export const SortableAccount = memo((props) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: props.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <Box
      ref={setNodeRef}
      sx={{
        position: "relative",
        display: "grid",
        gridTemplateColumns: "repeat(3, 1fr)",
        alignItems: "center",
        gap: "20px",
        backgroundColor: "#fff",
        borderTop: "1px solid #DBDFEA",
        padding: "10px 22px 10px 16px",
        touchAction: "none",
      }}
      style={style}
    >
      <Stack direction="row" alignItems="center" gap="11px">
        <Button
          sx={{
            minWidth: "8px",
            padding: "4px 3px 4px 4px",
            display: "flex",
            cursor: "grab",
            flexShrink: 0,
            "&:hover": {
              backgroundColor: "#dcdcdc",
            },
          }}
          {...listeners}
          {...attributes}
        >
          <img src={Dots} alt="" />
        </Button>
        <Box>
          <Typography sx={{ color: props.fromRoute ? "#3FC6A5" : "#252525" }}>
            {props.title}
          </Typography>
          {props.fromRoute && (
            <Typography fontSize={11} fontWeight={700} color="#535151">
              Changed from route {props.fromRoute}
            </Typography>
          )}
          <Typography fontSize={11} sx={{ color: "#535151" }}>
            {props.subtitle}
          </Typography>
        </Box>
      </Stack>
      <Typography fontSize={11} sx={{ color: "#535151" }}>
        {props.note}
      </Typography>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        gap="20px"
      >
        {!!props.openAddNoteDialog && (
          <Link
            onClick={props.openAddNoteDialog}
            to=""
            underline="always"
            variant="link"
            component={NavLink}
          >
            {props.note ? "Edit note" : "Add note"}
          </Link>
        )}
        {/* <Typography sx={{ color: "#252525" }}>Grocery</Typography> */}
        {!!props.onClick && (
          <IconButton onClick={props.onClick}>
            <Trash />
          </IconButton>
        )}
      </Stack>
    </Box>
  );
});
