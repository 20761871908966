import {
  useTheme,
  useMediaQuery,
  Stack,
  Box,
  Typography,
  Dialog,
  DialogActions
} from '@mui/material';

const SubstituteItemDialog = (props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { ...restProps } = props;

  return (
    <Dialog
      {...restProps}
      fullScreen={fullScreen}
      maxWidth="md"
      sx={{
        "& .MuiPaper-root": {
          width: "100%",
          maxWidth: "550px",
          p: "18px 18px 22px 18px",
        },
        zIndex: 1500,
      }}>

      <Box>
        <Typography>Substitute item</Typography>
      </Box>

      <Stack direction='column'>

      </Stack>

      <DialogActions>

      </DialogActions>
    </Dialog>
  )
}

export default SubstituteItemDialog;