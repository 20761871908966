import React, { useEffect, useState } from "react";
import useAppDispatch from "../../hooks/useAppDispatch";
import useAppSelector from "../../hooks/useAppSelector";
import {
  fetchCustomerActualOrders,
  selectCustomerNumberOfPages,
  setCustomerTableSettings,
} from "../../redux/module/customer";
import { useParams, useSearchParams } from "react-router-dom";
import OrdersTable from "../../pages/orders/OrdersTable";
import dayjs from "dayjs";
import { Box, Button, Stack } from "@mui/material";
import DatePicker2 from "../DatePicker2";
import { DATE_FORMAT } from "../../utils/constants";
import OrdersFilter from "../../pages/orders/OrdersFilter";
import Spinner from "../Spinner";
import NoOptionsBlock from "./NoOptionsBlock";
import OrderItemsModal from "../OrderComponents/OrderItemsModal";
import { useModal } from "mui-modal-provider";

const CustomerActualOrders = ({ account }) => {
  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const { showModal } = useModal();

  const dispatch = useAppDispatch();
  const {
    page,
    pageSize,
    filterBy,
    orderBy,
    customerActualOrders,
    customersLoading,
    customerAccounts,
  } = useAppSelector((state) => state.customer);
  const numberOfPages = useAppSelector(selectCustomerNumberOfPages);

  const [startDate, setStartDate] = useState(dayjs());
  const [endDate, setEndDate] = useState(null);

  const fetchOrders = () => {
    let filters = [{ column: "customer_id", value: id }];
    if (account?.value)
      filters.push({ column: "account_id", value: account?.value });
    if (startDate)
      filters.push({
        column: "start_date",
        value: startDate.format(DATE_FORMAT),
      });
    if (endDate) filters.push({ column: "end_date", value: endDate });

    dispatch(
      fetchCustomerActualOrders({
        page,
        pageSize,
        filterBy: filters,
        orderBy: [{ sort: "desc", field: "date" }],
        searchParams,
      }),
    );
  };

  const handleChangeStartDate = (newValue) => {
    if (newValue) {
      setStartDate(newValue);
    } else {
      setStartDate(null);
    }
  };
  const handleChangeEndDate = (newValue) => {
    if (newValue) {
      setEndDate(dayjs(newValue).format(DATE_FORMAT));
    } else {
      setEndDate(null);
    }
  };

  useEffect(() => {
    fetchOrders();
  }, [account, startDate, endDate, pageSize, page, orderBy, searchParams]);

  const handleChange = (field, value) => {
    if (field === "filterBy" || field === "pageSize") {
      dispatch(setCustomerTableSettings({ field: "page", value: 1 }));
    }
    dispatch(setCustomerTableSettings({ field, value }));
  };
  const refreshTable = () => {
    fetchOrders();
  };

  const isShowFilters =
    !!customerActualOrders.length ||
    searchParams.toString() !== "" ||
    !!startDate ||
    !!endDate;

  function pPerPriceRbih() {
    // DEDICACE RBIH L'INSTABLE
    const results = JSON.parse(JSON.stringify(customerActualOrders));
    for (let i = 0; i < results.length; i++) {
      results[i].items = results[i].items.map((item) => {
        const newItem = { ...item };
        newItem.item_details.price = {
          price: newItem.price,
        };
        return newItem;
      });
    }
    return results;
  }
  return (
    <>
      <Stack direction="row" justifyContent="spaceBetween">
        <div>
          {isShowFilters && (
            <Stack direction="row" justifyContent="flex-end" gap={1} mb={3}>
              <DatePicker2
                controlledValue={startDate}
                setControlledValue={handleChangeStartDate}
                label="Start date"
                maxDate={endDate ? dayjs(endDate) : undefined}
              />
              <DatePicker2
                controlledValue={endDate}
                setControlledValue={handleChangeEndDate}
                minDate={dayjs(startDate)}
                label="End date"
              />
            </Stack>
          )}
        </div>
        {!!customerAccounts.length && (
          <Button
            className="btn-height"
            color="primary"
            variant="contained"
            sx={{
              display: "flex",
              marginLeft: "auto",
              marginBottom: "24px",
            }}
            onClick={() =>
              showModal(OrderItemsModal, {
                date: dayjs.utc(),
                withCustomerAccounts: true,
                customerAccounts: customerAccounts,
                account: account.value
                  ? account
                  : {
                      value: customerAccounts[0].id,
                      label: customerAccounts[0].name,
                    },
                isCreate: true,
                refreshTable: refreshTable,
              })
            }
          >
            Create Order
          </Button>
        )}
      </Stack>

      {/* {isShowFilters && <OrdersFilter hiddenFilters={["automatic"]} />} */}
      {customersLoading ? (
        <Box
          height="200px"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Spinner size="lg" />
        </Box>
      ) : customerActualOrders.length ? (
        <OrdersTable
          items={pPerPriceRbih()}
          refreshTable={refreshTable}
          isLoading={customersLoading}
          page={page}
          pageSize={pageSize}
          setPage={(page) => handleChange("page", page)}
          setPageSize={(pageSize) => handleChange("pageSize", pageSize)}
          noPages={customerActualOrders.length && numberOfPages}
        />
      ) : (
        <NoOptionsBlock
          option="actual orders"
          isFiltered={searchParams.keys().next().done === false}
        />
      )}
    </>
  );
};

export default CustomerActualOrders;
