import ScheduleSendTwoToneIcon from "@mui/icons-material/ScheduleSendTwoTone";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import { Box, Typography, Button, Stack, OutlinedInput } from "@mui/material";
import React from "react";
import DataTable from "../../components/DataTable";
import {
  listEmailQueue,
  processEmailQueue,
} from "../../distflowAPI/emailQueueApi";
import EmailQueueColumnsDef from "./EmailQueueTableColumns";
import EmailQueueFilter from "./EmailQueueFilter";
import { useSearchParams } from "react-router-dom";
import { useSearchDebounce } from "../../utils";

function EmailQueue() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchDB, setSearchDB] = useSearchDebounce();

  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(10);
  const [emailQueue, setEmailQueue] = React.useState([]);
  const [emailQueueLoading, setEmailQueueLoading] = React.useState(true);
  const [numberOfPages, setNumberOfPages] = React.useState(0);
  const [showFilters, setShowFilters] = React.useState(false);

  const [filters, setFilters] = React.useState({
    search: "",
    recipients: "",
    subject: "",
    emailed: "",
    start_date: "",
    end_date: "",
  });
  const handleChange = (key, value) => {
    switch (key) {
      case "orderBy":
        console.log("orderBy", value);
        break;
      case "filterBy":
        console.log("filterBy", value);
        break;
      case "page":
        setPage(value);
        break;
      case "pageSize":
        setPageSize(value);
        setPage(1);
        break;
      default:
        break;
    }
  };

  const fetchEmailQueue = async (
    pageSize = 10,
    page = 1,
    filter = [],
    order = []
  ) => {
    setEmailQueueLoading(true);
    const result = await listEmailQueue(pageSize, page, filter, order);
    setEmailQueue(result.data.results);
    setNumberOfPages(Math.ceil(result.data.count / (pageSize ? pageSize : 10)));
    setEmailQueueLoading(false);
  };

  React.useEffect(() => {
    fetchEmailQueue(pageSize, page, filters, []);
  }, [page, pageSize, filters]);

  React.useEffect(() => {
    const search = searchParams.get("search") || "";
    const recipients = searchParams.get("recipients") || "";
    const subject = searchParams.get("subject") || "";
    const emailed = searchParams.get("emailed") || "";
    const start_date = searchParams.get("start_date") || "";
    const end_date = searchParams.get("end_date") || "";
    setFilters({
      search,
      recipients,
      subject,
      emailed,
      start_date,
      end_date,
    });
  }, [searchParams]);

  function handleProcessQueue() {
    processEmailQueue().then(
      (res) => alert("Queue process started"),
      (err) => alert("Error occurred during queue process")
    );
  }

  const handleSearch = (value) => {
    setSearchParams((params) => {
      const newParams = new URLSearchParams(params);
      newParams.set("search", value);
      return newParams;
    });
  };

  React.useEffect(() => {
    setSearchDB(filters.search);
  }, [filters.search]);

  React.useEffect(() => {
    handleSearch(searchDB);
  }, [searchDB]);

  return (
    <>
      <Stack
        direction="row"
        spacing={1}
        alignContent={"center"}
        alignItems={"center"}
      >
        <Typography
          fontSize="23px"
          fontWeight={800}
          component="h2"
          data-testid="page-title"
        >
          Email Queue
        </Typography>
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        spacing="15px"
        my="20px"
      >
        <OutlinedInput
          placeholder="Search for an Email..."
          sx={{ height: "40px" }}
          fullWidth
          maxWidth="xs"
          value={filters.search}
          onChange={(e) => {
            setFilters({ ...filters, search: e.target.value });
          }}
        />
        <Button
          className="btn-height"
          variant="outlined"
          endIcon={showFilters ? <FilterAltOffIcon /> : <FilterAltIcon />}
          onClick={() => setShowFilters(!showFilters)}
        >
          Filters
        </Button>
        <Button
          className="contained "
          variant="contained"
          onClick={handleProcessQueue}
          endIcon={
            <ScheduleSendTwoToneIcon
              sx={{ fontSize: "20px", marginRight: "5px" }}
            />
          }
        >
          Process Queue
        </Button>
      </Stack>
      <EmailQueueFilter showFilters={showFilters} />
      <Box
        className="table-wrapper"
        sx={{
          my: "25px",
          width: "100%",
        }}
      >
        <DataTable
          columnDefs={EmailQueueColumnsDef}
          rowData={emailQueue}
          defaultColDef={{ resizable: true }}
          loading={emailQueueLoading}
          page={page}
          pageSize={pageSize}
          setPage={(page) => {
            handleChange("page", page);
          }}
          setPageSize={(pageSize) => {
            handleChange("pageSize", pageSize);
          }}
          rowHeight={53}
          noPages={numberOfPages}
        />
      </Box>
    </>
  );
}

export default EmailQueue;
