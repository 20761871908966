import api from "./api";
import ApiBase from "./apiBase";
import { filtersToApi, orderingToApi } from "./common";

const customersApi = new ApiBase("/accounts/customers");

export default customersApi;

export function customerItems(customerId, action, variantId, search) {
  return api.get(
    `/api/accounts/customers_can_orders/?customer_id=${customerId}&action=${action}&variant_id=${variantId}&search=${search}`
  );
}

export function getCustomers(page, pageSize, filterBy, orderBy, searchParams) {
  //const orderingParam = order.length ? `&ordering=${orderingToApi(order)}` : "";
  //const filterParam = filter.length ? `&${filtersToApi(filter)}` : "";
  let filterByS = filtersToApi([], searchParams);
  const orderByString = orderingToApi(orderBy);
  const filterByString = filtersToApi(filterBy);
  const params = `?page_size=${pageSize}&page=${page}&ordering=${orderByString}&${filterByString}&${filterByS}`;
  //const params = `page_size=${pageSize}&page=${page}&ordering=${order}${filterParam}`;

  return api.get(`/api/accounts/customers/${params}`);
}

export async function getCustomerById(id) {
  const res = await api.get(`/api/accounts/customers/${id}/`);
  return res;
}

export function getCustomerFinancials(customerId) {
  return api.get(
    `/api/accounting/customer_financials/?customer_id=${customerId}`
  );
}
export function getCustomerPhones(customerId) {
  return api.get(`/api/accounts/customers/${customerId}/phones/`);
}

export function getCustomerEmails(customerId) {
  return api.get(`/api/accounts/customers/${customerId}/emails/`);
}

export function getCustomerOverrides(customerId) {
  return api.get(`/api/accounts/customers/${customerId}/overrides/`);
}

export async function postCustomerOverride(customerId, data) {
  return api.post(`/api/accounts/customers/${customerId}/overrides/`, data);
}

export async function postCustomerPhone(customerId, data) {
  return api.post(`/api/accounts/customers/${customerId}/phones/`, data);
}

export async function postCustomerEmail(customerId, email) {
  return api.post(`/api/accounts/customers/${customerId}/emails/`, {
    customer: customerId,
    email,
  });
}

export async function patchCustomerPhone(customerId, id, data) {
  return api.patch(`/api/accounts/customers/${customerId}/phones/${id}/`, data);
}

export async function patchCustomerEmail(customerId, id, email) {
  return api.patch(`/api/accounts/customers/${customerId}/emails/${id}/`, {
    email,
    //is_default: isPrimary,
  });
}

export async function deleteCustomerPhone(customerId, id) {
  return api.delete(`/api/accounts/customers/${customerId}/phones/${id}/`);
}

export async function deleteCustomerEmail(customerId, id) {
  return api.delete(`/api/accounts/customers/${customerId}/emails/${id}/`);
}

export function patchCustomerOverride(customerId, id, payload) {
  return api.patch(
    `/api/accounts/customers/${customerId}/overrides/${id}/`,
    payload
  );
}

export function deleteCustomerOverride(customerId, overrideId) {
  return api.delete(
    `/api/accounts/customers/${customerId}/overrides/${overrideId}/`
  );
}

export async function getNoteCustomer(customerId) {
  const result = await api.get(
    `/api/accounts/customer_notes/?customer_id=${customerId}`
  );
  return result;
}
export async function postCustomerNote(id, data) {
  const result = await api.post(`/api/accounts/customer_notes/`, {
    customer: id,
    note: data,
  });
  return result;
}
export async function updateNoteCustomer(noteId, note, customer) {
  const result = await api.patch(`/api/accounts/customer_notes/${noteId}/`, {
    customer: customer,
    note: note,
  });
  return result;
}
export async function deleteNoteCustomer(noteId) {
  const result = await api.delete(`/api/accounts/customer_notes/${noteId}/`);
  return result;
}
// Customers Restrictions

export async function getCustomerRestrictions(
  customerId,
  pageSize,
  page,
  orderBy,
  filterBy
) {
  const orderByString = orderingToApi(orderBy);
  const filterByString = filtersToApi(filterBy);
  const params = `customerId=${customerId}&page_size=${pageSize}&page=${page}&ordering=${orderByString}&${filterByString}`;
  const result = await api.get(
    `/api/products/product_customer_restrictions/?${params}`
  );
  return result;
}

export async function postCustomerRestrictions(customerId, product, note) {
  const result = await api.post(
    `/api/products/product_customer_restrictions/`,
    {
      customer: customerId,
      product: product,
      note: note,
    }
  );
  return result;
}

export async function deleteCustomerRestrictions(restrictionId) {
  const result = await api.delete(
    `/api/products/product_customer_restrictions/${restrictionId}/`
  );
  return result;
}

// Customers Category Restrictions:

export async function getCategoryCustomerRestrictions(
  customerId,
  pageSize,
  page
) {
  const params = `customerId=${customerId}&page_size=${pageSize}&page=${page}`;
  const result = await api.get(
    `/api/products/category_customer_restrictions/?${params}`
  );
  return result;
}

export async function postCategoryCustomerRestrictions(
  customerId,
  categoryId,
  note
) {
  const result = await api.post(
    `/api/products/category_customer_restrictions/`,
    {
      category: categoryId,
      customer: customerId,
      note: note,
    }
  );
  return result;
}

export async function deleteCategoryAccountRestrictions(restrictionId) {
  const result = await api.delete(
    `/api/products/category_customer_restrictions/${restrictionId}/`
  );
  return result;
}

export async function sendEmail(payload) {
  const res = await api.post(`/api/send_batch_emails/`, payload, {
    headers: { "content-type": "multipart/form-data" },
  });
  return res;
}
