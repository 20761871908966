import { Grid, IconButton, Button } from "@mui/material";
import Block from "../../components/Block";
import DataTable from "../../components/DataTable";
import { useState, useEffect } from "react";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import ConfirmDialog from "../../components/ConfirmDialog";

import { useParams } from "react-router-dom";
import {
  getCategoryCustomerRestrictions,
  deleteCategoryAccountRestrictions,
} from "../../distflowAPI/customersApi";

import CreateCategoryRestriction from "./CreateCategoryRestriction";
export default function RestrictCategoryCustomers() {
  const { id } = useParams();
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [rowData, setRowData] = useState();
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [customerSelectOpen, setCustomerSelectOpen] = useState(false);
  function fetchRestrictions() {
    getCategoryCustomerRestrictions(id, pageSize, page).then((res) => {
      setRowData(res.data.results);
      setCount(res.data.count);
    });
  }
  useEffect(() => {
    fetchRestrictions();
  }, []);

  function buttonsRenderer(params) {
    const rowData = params.data;
    const handleDeleteClick = () => {
      setConfirmModalOpen(true);
    };

    return (
      <div>
        <ConfirmDialog
          title="Restriction Delete"
          children="Do you want to delete this Restriction ?"
          open={confirmModalOpen}
          setOpen={setConfirmModalOpen}
          onConfirm={() => {
            deleteCategoryAccountRestrictions(rowData.id).then((res) => {
              fetchRestrictions();
            });
          }}
        />

        <IconButton onClick={() => handleDeleteClick()}>
          <DeleteForeverOutlinedIcon />
        </IconButton>
      </div>
    );
  }
  const columns = [
    {
      headerName: "Category",
      field: "category.name",
      minWidth: 400,
      sortable: true,
    },
    {
      headerName: "Notes",
      field: "note",
      sortable: true,
    },

    { headerName: "Actions", cellRenderer: buttonsRenderer, maxWidth: 100 },
  ];
  const defaultColDef = {
    flex: 1,
    filter: false,
    floatingFilter: false,
  };
  return (
    <Block>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h3 style={{ fontWeight: 600 }}>Category Restrictions</h3>
        <Button
          type="submit"
          variant="contained"
          sx={{ pt: 1, pb: 1, mb: 1 }}
          onClick={() => {
            setCustomerSelectOpen(true);
          }}
        >
          Create a Restriction
        </Button>
        <CreateCategoryRestriction
          customerSelectOpen={customerSelectOpen}
          setCustomerSelectOpen={setCustomerSelectOpen}
          fetchRestrictions={fetchRestrictions}
        />
      </div>
      <DataTable
        rowData={rowData}
        columnDefs={columns}
        defaultColDef={defaultColDef}
        loading={false}
      />
    </Block>
  );
}
