import React from "react";
import { fireErrorNotification } from "../utils";

const useFile = (acceptedMimes = ["png", "jpeg", "jpg"]) => {
  const isFileValid = (file) => {
    //get file type
    const fileType = file.name.split(".").pop();
    //check if file valid by type
    const isValidFileType = acceptedMimes.includes(fileType.toLowerCase());
    //check if file valid by size
    const isValidFileSize = file.size < 1 * 1024 * 1024 * 25;

    // if file valid - return true
    if (isValidFileType && isValidFileSize) {
      return true;
    }
    // if file type not valid - show to user popup with error
    if (!isValidFileType) {
      fireErrorNotification(
        `No valid file type ${fileType}, choose ${acceptedMimes.join(", ")}`
      );
      return false;
    }
    // if file size not valid - show to user popup with error
    if (!isValidFileSize) {
      fireErrorNotification(
        `No valid ${file.name} size, should be less than 1 mb`
      );
      return false;
    }
  };
  return { isFileValid };
};

export default useFile;
