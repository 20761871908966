import { useMemo } from "react";
import { Box } from "@mui/material";
import DataTable from "../../components/DataTable";
import routeChangesPageTableColumns from "./routeChangesPageTableColumns";
import useAppSelector from "../../hooks/useAppSelector";
import useKeyBy from "../../hooks/useKeyBy";

const ChangesTable = ({ routeDetails }) => {
  const driversMap = useAppSelector((state) => state.driver.driversMap);
  const routesMap = useAppSelector((state) => state.route.routesMap);
  const systemAccounts = useAppSelector((state) => state.account.accounts);
  const systemAccountsMap = useKeyBy(systemAccounts, "id");

  const data = useMemo(() => {
    const changes = [];

    for (const scheduleChange of routeDetails.schedule_changes || []) {
      const {
        schedule_change_vendor_rules: scheduleChangeVendorRules = [],
        driver,
      } = scheduleChange;
      if (driver !== routeDetails.driver) {
        changes.push({
          type: "Driver",
          change: driversMap[scheduleChange.driver]?.user.first_name,
          original: driversMap[routeDetails.driver]?.user.first_name,
        });
      }
      for (const vendorChange of scheduleChangeVendorRules) {
        const originalVendor =
          routeDetails.vendors_calc.find(
            (v) => v.id === vendorChange.vendor.id
          ) ||
          routeDetails.vendor_rules.find(
            (vr) => vr.vendor === vendorChange.vendor.id
          );
        changes.push({
          type: "Vendor",
          change:
            vendorChange.type === "DISABLED"
              ? "DISABLED"
              : routesMap[vendorChange.pickup_route]?.name,
          original:
            vendorChange.type === "DISABLED"
              ? routesMap[originalVendor?.pickup_route]?.name
              : "DISABLED",
        });
      }

      const disabledAccounts = [];
      const allScheduleChangeAccounts = (routeDetails.schedule_changes || [])
        .map((item) => item.schedule_change_accounts)
        .flat()
        .reverse();
      for (const account of routeDetails.accounts_calc) {
        const accountChange = allScheduleChangeAccounts.find(
          (item) =>
            item.account.id === account.account &&
            item.route !== routeDetails.id
        );
        if (accountChange) {
          if (accountChange.type === "DISABLED") {
            changes.push({
              type: "Account",
              change: systemAccountsMap[account.account]?.name,
              original: `Changed from route ${
                routesMap[accountChange.route]?.name
              }`,
            });
          } else {
            disabledAccounts.push(account);
            changes.push({
              type: "Account",
              change: `Changed to route ${
                routesMap[accountChange.route]?.name
              }`,
              original: systemAccountsMap[account.account]?.name,
            });
            continue;
          }
        }
      }
      const disabledForThisRoute = allScheduleChangeAccounts.filter(
        (item) => item.type === "DISABLED" && item.route === routeDetails.id
      );
      for (const item of disabledForThisRoute) {
        const to = allScheduleChangeAccounts.find(
          (i) =>
            i.account.id === item.account.id &&
            item.type !== "DISABLED" &&
            item.route !== routeDetails.id
        );

        if (to && !disabledAccounts.some((i) => i.account === to.account.id)) {
          changes.push({
            type: "Account",
            change: `Changed to route ${routesMap[to.route]?.name}`,
            original: systemAccountsMap[to.account.id]?.name,
          });
        }
      }
    }

    return changes;
  }, [routeDetails, driversMap, routesMap]);

  return (
    <Box
      className="table-wrapper routes-table"
      sx={{
        margin: { xs: "0 -17px -17px", sm: "0 -25px -25px" },
        "& .ag-root-wrapper": { borderRadius: "0 0 4px 4px" },
        "& .ag-cell, & .ag-cell span, & .ag-cell p": {
          fontSize: "12px",
          color: "#000 !important",
          lineHeight: "18px",
        },
        "& .ag-header-cell-text": {
          textTransform: "uppercase",
          fontSize: "13px !important",
          fontWeight: "700 !important",
          letterSpacing: 1.6,
        },
      }}
    >
      <DataTable
        rowData={data}
        columnDefs={routeChangesPageTableColumns}
        headerHeight={43}
      />
    </Box>
  );
};

export default ChangesTable;
