import { FormControlLabel, FormGroup, TextField } from "@mui/material";
import React from "react";
import useAppDispatch from "../../hooks/useAppDispatch";
import useAppSelector from "../../hooks/useAppSelector";
import { setName } from "../../redux/module/addEditRoute";

const RouteNameFormControl = () => {
  const dispatch = useAppDispatch();
  const name = useAppSelector((state) => state.addEditRoute.name);
  const errors = useAppSelector((state) => state.addEditRoute.formErrors);

  return (
    <FormGroup>
      <FormControlLabel
        label="Name"
        labelPlacement="start"
        sx={{
          alignItems: "flex-start",
          margin: 0,
          gap: "16px",
          marginTop: "5px",
          "& span": { fontWeight: 500 },
          "& span.MuiTypography-root": {
            alignSelf: "center",
          }, 
          "& span.MuiFormControlLabel-asterisk": {
            display: "none"
          },
        }}
        control={
          <TextField
            required
            error={!!errors.name}
            helperText={errors.name}
            data-testid={`test-route-name-id`}
            margin="none"
            value={name}
            onChange={(e) => dispatch(setName(e.target.value))}
            fullWidth
            placeholder="Route name"
            variant="outlined"
          />
        }
      />
    </FormGroup>
  );
};

export default RouteNameFormControl;
