// TODO:
import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { signOut } from "./user";
import { fireErrorNotification, fireSuccessNotification } from "../../utils";
import paymentsApi from "../../distflowAPI/paymentsApi";
import { fetchInvoices, invoiceSlice } from "./invoice";
import invoicesApi from "../../distflowAPI/invoicesApi";
import { removeAccount } from "./account";
import api from "../../distflowAPI/api";
const initialState = {
  payments: [],
  payment: {},
  pageSize: 50,
  page: 1,
  orderBy: [],
  filterBy: [],
  invoiceForNewPayment: null,
  totalCount: 0,
  paymentsLoading: false,
};

export const fetchPayments = createAsyncThunk(
  "payment/fetchPayments",
  async (data, thunkAPI) => {
    let { page, pageSize, filterBy, orderBy, searchParams } = data;
    return await paymentsApi.list(
      pageSize,
      page,
      orderBy,
      filterBy,
      searchParams,
    );
  },
);

export const updatePayment = createAsyncThunk(
  "payment/updatePayment",
  async (data, { rejectWithValue }) => {
    const { id, payload } = data;
    try {
      const res = await paymentsApi.update(id, payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const deletePayment = createAsyncThunk(
  "payment/deletePayment",
  async (id, { rejectWithValue }) => {
    try {
      const res = await paymentsApi.delete(id);
      return id;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    setPaymentsTableSettings(state, { payload }) {
      state[payload.field] = payload.value;
    },
    setInvoiceForNewPayment(state, { payload }) {
      state.invoiceForNewPayment = payload;
    },
    cleanTableSettings(state) {
      state.pageSize = 50;
      state.page = 1;
      state.orderBy = [];
      state.filterBy = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPayments.pending, (state) => {
        state.paymentsLoading = true;
      })
      .addCase(fetchPayments.fulfilled, (state, action) => {
        state.paymentsLoading = false;
        state.payments = action.payload.results;
        state.totalCount = action.payload.count;
      })
      .addCase(fetchPayments.rejected, (state) => {
        state.paymentsLoading = false;
      })
      .addCase(updatePayment.pending, (state, action) => {
        state.paymentsLoading = true;
      })
      .addCase(updatePayment.fulfilled, (state, action) => {
        state.payment = action.payload;
        fireSuccessNotification(`Payment updated successfully`);
        state.paymentsLoading = false;
      })
      .addCase(updatePayment.rejected, (state, action) => {
        state.paymentsLoading = false;
        const errorFields = Object.keys(action.payload);
        errorFields.forEach((field) => {
          fireErrorNotification(`${field}: ${action.payload[field][0]}`);
        });
      })

      .addCase(deletePayment.fulfilled, (state, action) => {
        fireSuccessNotification(`Payment deleted successfully`);
        state.payment = {};
        state.payments = state.payments.filter(
          (el) => el.id !== action.payload,
        );
      })
      .addCase(deletePayment.rejected, (state, action) => {
        const protected_elements = action.payload.protected_elements;
        fireErrorNotification(`Protected by: 
          ${protected_elements.map(
            (el) => ` id: ${el.id} label: ${el.label}`,
          )}`);
      })
      .addCase(signOut, () => initialState);
  },
});

export const {
  setPaymentsTableSettings,
  setInvoiceForNewPayment,
  cleanTableSettings,
} = paymentSlice.actions;

export const selectPaymentNumberOfPages = createSelector(
  (state) => state.payment.totalCount,
  (state) => state.payment.pageSize,
  (totalCount, pageSize) => Math.ceil(totalCount / pageSize),
);

export default paymentSlice.reducer;
