import React, { useRef } from "react";
import { Button, Stack, Typography } from "@mui/material";
import useFile from "../../hooks/useFile";

const IconSelect = ({categoryInfo, setCategoryInfo }) => {  
  const fileRef = useRef(null)
  const { isFileValid } = useFile()

  const handleIconClick = () => fileRef.current.click()

  const onChangeImages = () => {
    const file = fileRef.current.files[0]
    if (isFileValid(file)) {
      setCategoryInfo({ ...categoryInfo, icon: file })
    } else {
      fileRef.current.value = ""
    }
  }
  return (
    <>
      <Stack direction="row" alignItems="center" style={{gap: "16px"}}>
        <Button  variant="contained" onClick={handleIconClick}>
          Choose icon
        </Button>
        <Typography>
          {categoryInfo.icon?.name}
        </Typography>
      </Stack>
      <input
        style={{display: "none"}}
        type="file"
        name="icon"
        accept=".jpg, .jpeg, .png"
        ref={fileRef}
        onChange={onChangeImages}
      />
    </>
  );
};

export default IconSelect;