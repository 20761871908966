import {
  AppBar,
  Badge,
  Box,
  IconButton,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import logo from "../../images/logo.svg";
import ProfileMenu from "./ProfileMenu";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import { useNavigate } from "react-router-dom";
import { notificationsCount } from "../../distflowAPI/notifications";
import { useState } from "react";
const Header = ({ handleDrawerToggle, user, signOut }) => {
  const navigate = useNavigate();
  const [count, setCount] = useState(0);
  const fetchNotificationsCount = async () => {
    await notificationsCount()
      .then((response) => {
        setCount(response.data.count);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useState(() => {
    fetchNotificationsCount();
  }, []);

  return (
    <AppBar
      position="fixed"
      sx={{
        width: "100%",
        backgroundColor: "#fff",
        borderBottom: "1px solid #DBDFEA",
        boxShadow: "none",
        zIndex: 1500,
      }}
    >
      <Toolbar sx={{ justifyContent: "space-between" }}>
        <Box sx={{ display: "flex" }}>
          <IconButton
            color="#000"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { lg: "none" } }}
          >
            <MenuIcon />
          </IconButton>

          <Typography component="div">
            <img
              src={logo}
              alt=""
              onClick={() => (window.location.href = "/orders")}
            />
          </Typography>
        </Box>
        <Stack
          direction="row"
          alignItems="center"
          gap={1}
          justifyContent="center"
        >
          <IconButton
            color="#000"
            edge="start"
            onClick={() => navigate("/notifications")}
          >
            <Badge badgeContent={count} color="error">
              <NotificationsNoneOutlinedIcon />
            </Badge>
          </IconButton>

          <ProfileMenu user={user} signOut={signOut} />
        </Stack>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
