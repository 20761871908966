import { Link, NavLink } from "react-router-dom";
import { Button, Stack } from "@mui/material";
import dayjs from "dayjs";
import { DATE_FORMAT_FRONT } from "../../utils/constants";

const orderDemo = {
  payments: [],
  issued_date: "2023-05-31T21:28:33.488816Z",
  notes: null,
};

function InvoiceNameRenderer(params) {
  return (
    <span className="my-renderer">
      <Link
        to={`/invoices/invoices/${params.data.id}`}
        style={{ textDecoration: "none" }}
      >
        {params.data?.po_id
          ? `PO# ${params.data.po_id}`
          : `# ${params.data.id}`}
      </Link>
    </span>
  );
}

function AccountNameRenderer(params) {
  return (
    <Stack direction={"row"} sx={{ width: "100%", flexWrap: "wrap" }} p={1}>
      <Link
        to={`/accounts/${params.data.account.id}`}
        style={{ textDecoration: "none", display: "flex", width: "100%" }}
      >
        {params.data.account.name}
      </Link>
    </Stack>
  );
}

function CustomerNameRenderer(params) {
  return (
    <Stack direction={"row"} sx={{ width: "100%", flexWrap: "wrap" }} p={1}>
      <Link
        to={`/customers/dashboard/${params.data.customer.id}/personal`}
        style={{ textDecoration: "none", display: "flex", width: "100%" }}
      >
        {params.data.customer.name}
      </Link>
    </Stack>
  );
}

function RouteRenderer(params) {
  // const navigate = useNavigate();
  // ;
  return (
    <Stack direction={"row"} sx={{ width: "100%", flexWrap: "wrap" }} p={1}>
      {params.data.routes.map((item) => {
        return (
          <Button
            size="small"
            variant="gray"
            component={NavLink}
            //to={`/routes2/routes/${item?.id}`}
            // to={`/routes2/?id=${item?.id}`}
            sx={{
              bgcolor: "#F5F5F5",
              border: "1px solid #DBDFEA !important",
              padding: "0 9px !important",
              fontSize: "14px !important",
              width: "100%",
              display: "flex",
              overflowWrap: "break-word",
              textWrap: "wrap",
              height: "auto !important",
              minHeight: "40px",
            }}
          >
            {item?.name}
          </Button>
        );
      })}
    </Stack>
  );
}

const order_id = {
  minWidth: 100,
  maxWidth: 100,
  headerName: "Order",
  valueGetter: (params) => {
    return `#${params.data.order}`;
  },
};
const internal_id = {
  headerName: "Account",
  valueGetter: (params) => params.data.account?.internal_id,
  field: "account__internal_id",
  flex: 1,
};
const account_name = {
  headerName: "Account",
  wrapText: true,
  autoHeight: true,
  sortable: true,
  comparator: () => {}, //disable default sorting
  filterParams: {
    filterOptions: ["contains"],
    suppressAndOrCondition: true,
  },
  field: "order__account__name",
  //filter: true,
  //floatingFilter: true,
  valueGetter: (params) => {
    return `${params.data.account.name}`;
  },
  cellRendererSelector: () => {
    return {
      component: AccountNameRenderer,
    };
  },
  minWidth: 150,
};
const invoice_id = {
  headerName: "Invoice",
  sortable: true,
  field: "id",
  comparator: () => {},
  cellRendererSelector: () => {
    return {
      component: InvoiceNameRenderer,
    };
  },
  flex: 1,
};
const customer_email = {
  headerName: "Email",
  field: "email",
  wrapText: true,
  autoHeight: true,
  sortable: true,
  comparator: () => {}, //disable default sorting
  filterParams: {
    filterOptions: ["contains"],
    suppressAndOrCondition: true,
  },
  //filter: true,
  //floatingFilter: true,
  valueGetter: (params) => `${params.data.owner.email}`,
  flex: 4,
};
const routes = {
  headerName: "Routes",
  wrapText: true,
  autoHeight: true,
  cellRendererSelector: () => {
    return {
      component: RouteRenderer,
    };
  },
  minWidth: 200,
  sortable: true,
  field: "order__items__route__name",
};
const INVOICES_STATUS = {
  PAID: "Paid",
  UNPAID: "Unpaid",
  PARTIALLY_PAID: "Partially Paid",
  VOIDED: "Voided",
  CREDIT: "Credit",
  OVERPAID: "Overpaid",
};
const invoice_status = {
  sortable: true,
  comparator: () => {},
  wrapText: true,
  flex: 1,
  headerName: "Status",
  field: "status",
  valueGetter: (params) => `${INVOICES_STATUS[params.data.status]}`,
};
const invoice_created_date = {
  sortable: true,
  comparator: () => {},
  //filter: true,
  //floatingFilter: true,
  flex: 1,
  headerName: "Created",
  field: "issued_date",
  valueGetter: (params) =>
    dayjs.utc(params.data.issued_date).format(DATE_FORMAT_FRONT),
};
const invoice_due_date = {
  sortable: true,
  comparator: () => {},
  //filter: true,
  //floatingFilter: true,
  minWidth: 130,
  maxWidth: 130,
  headerName: "Due date",
  field: "due_date",
  valueGetter: (params) =>
    dayjs.utc(params.data.due_date).format(DATE_FORMAT_FRONT),
};
const invoice_total_amount = {
  sortable: true,
  comparator: () => {},
  //filter: true,
  //floatingFilter: true,
  flex: 1,
  headerName: "Total",
  field: "total_amount",
  valueGetter: (params) => `$${params.data.total_amount}`,
};
const customer_name = {
  sortable: true,
  wrapText: true,
  autoHeight: true,
  comparator: () => {},
  minWidth: 150,
  headerName: "Customer",
  field: "order__account__customer__name",
  cellRendererSelector: () => {
    return {
      component: CustomerNameRenderer,
    };
  },
};
const amount_paid = {
  sortable: true,
  comparator: () => {},
  flex: 1,
  headerName: "Paid",
  field: "total_paid",
  valueGetter: (params) => `$${params.data.total_paid}`,
};
const amount_remaining = {
  sortable: true,
  comparator: () => {},
  flex: 1,
  headerName: "Balance",
  field: "balance",
  valueGetter: (params) => `$${params.data.balance}`,
};

const address = {
  field: "address",
  headerName: "Address",
  flex: 1,
  sortable: true,
};

const open_days = {
  field: "open_days",
  headerName: "Open",
  minWidth: 200,
  sortable: true,
  valueGetter: (params) => {
    if (params.data.open_days == "-") {
      return "Closed";
    } else {
      let nbrDays = params.data.open_days;
      return nbrDays + ` day${nbrDays > 1 ? "s" : ""} ago`;
    }
  },
};

const dtfp = {
  field: "days_to_first_payment",
  headerName: "DTFP",
  minWidth: 80,
  sortable: true,
};

const invoiceTableColumns = [
  internal_id,
  invoice_id,
  address,
  invoice_total_amount,
  amount_paid,
  amount_remaining,
  invoice_status,
  invoice_created_date,
  open_days,
  dtfp,
];

export default invoiceTableColumns;
