import React, { useRef, useState } from "react";
import {
  Alert,
  Box,
  Button,
  Grid,
  ImageList,
  Link,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import FileDropzone from "../../components/FileDropzone";
import TinymceEditor from "../../components/TinymceEditor";
import { NavLink, useNavigate } from "react-router-dom";
import Block from "../../components/Block";
import Separator from "../../components/Separator";
import VariantConfigTable from "./VariantConfigTable";
import productsApi, { checkSkuAvailable } from "../../distflowAPI/products";
import ProductVariantForm from "./ProductVariantForm";
import ProductImageListItem from "../products/ProductImageListItem";
import CategorySelect from "../../components/AsyncSelects/CategorySelect";
import VendorSelect from "../../components/AsyncSelects/VendorSelect";
import TagSelect from "../../components/AsyncSelects/TagSelect";
import { createTag } from "../../redux/module/tag";
import useAppDispatch from "../../hooks/useAppDispatch";

const AddProduct = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [personName, setPersonName] = useState([]);

  const multipleSelectChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(typeof value === "string" ? value.split(",") : value);
  };

  const [images, setImages] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState({ label: "" });
  const [selectedTag, setSelectedTag] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState({ label: "" });
  const [tagSearch, setTagSearch] = useState("");

  const [name, setName] = useState("");
  const [additionalInfo, setAdditionalInfo] = useState("");
  const [errors, setErrors] = useState({});
  const [description, setDescription] = useState("");
  const [deliveryType, setDeliveryType] = useState("");
  const [variants, setVariants] = useState({});
  const [variantsNo, setVariantsNo] = useState(0);
  const [isActive, setIsActive] = useState(true);
  const [internalId, setInternalId] = useState("");

  function addVariant(variant) {
    const newNo = variantsNo + 1;
    setVariantsNo(newNo);
    setVariants({ ...variants, [newNo]: variant });
  }

  async function handleSubmit() {
    setErrors({});
    const newErrors = {};
    const required_fields = {
      name: name,
      category: selectedCategory,
      vendor: selectedVendor,
      description: description,
      delivery_type: deliveryType,
      variants: variants,
    };
    for (const key in required_fields) {
      if (!required_fields[key]) {
        newErrors[key] = `${key} can not be empty`;
        setErrors(newErrors);
        return;
      }
    }
    if (!selectedCategory.value) {
      newErrors["category"] = "category can not be empty";
      setErrors(newErrors);
      return;
    }
    if (!selectedVendor.value) {
      newErrors["vendor"] = "vendor can not be empty";
      setErrors(newErrors);
      return;
    }
    const variants_required_fields = ["name", "sku", "cost"];
    if (!Object.keys(variants).length) {
      newErrors["variants"] = "add minimum one variant";
      setErrors(newErrors);
      return;
    }
    Object.keys(variants).map((key) => {
      variants_required_fields.map((field) => {
        if (!variants[key][field]) {
          newErrors[`${key}${field}`] = `${field} can not be empty`;
          setErrors(newErrors);
          return;
        }
      });
    });
    if (Object.keys(newErrors).length > 0) {
      return;
    }
    const formData = new FormData();
    formData.append("name", name);
    formData.append("additional_info", additionalInfo);
    formData.append("form_category", selectedCategory.value);
    formData.append("internal_id", internalId);
    if (selectedTag) {
      selectedTag.map((item) => formData.append("form_tags", item.value));
    }
    formData.append("form_vendor", selectedVendor.value);
    formData.append("description", description);
    formData.append("delivery_type", deliveryType);
    formData.append("is_active", isActive);
    if (images) {
      for (let im = 0; im < images.length; im++) {
        formData.append("form_images", images[im], images[im].name);
      }
    }
    let cc = 0;
    Object.keys(variants).map((key) => {
      const variant = variants[key];
      formData.append(`variants[${cc}]name`, variant.name);
      formData.append(`variants[${cc}]sku`, variant.sku);
      formData.append(`variants[${cc}]cost`, variant.cost);
      formData.append(`variants[${cc}]barcode`, variant.barcode);
      formData.append(
        `variants[${cc}]sold_by`,
        variant.sold_by.value.toUpperCase(),
      );
      formData.append(`variants[${cc}]per_box`, variant.per_box);
      formData.append(`variants[${cc}]per_pack`, variant.per_pack);
      formData.append(`variants[${cc}]order_qty`, variant.order_qty);
      formData.append(`variants[${cc}]code`, variant.code);
      formData.append(`variants[${cc}]sheet`, variant.sheet);

      if (variant.image) {
        if (variant.image[0] && variant.image[0].name) {
          formData.append(
            `variants[${cc}]form_images`,
            variant.image[0],
            variant.image[0].name,
          );
        }
      }
      cc += 1;
    });
    try {
      const res = await productsApi.create(formData);
      navigate(`/products/${res.data.id}`);
    } catch (error) {}
  }

  const [variantsErrors, setVariantsErrors] = useState({});

  const checkSku = async (sku) => {
    try {
      const available = await checkSkuAvailable(sku);
      const err = { ...variantsErrors };
      if (err.sku) {
        delete err.sku;
        setVariantsErrors(err);
      }
      if (!available) {
        err[`sku`] = `SKU "${sku}" Already exists`;
        setVariantsErrors(err);
      } else {
        setVariantsErrors(err);
      }
      for (let _sku of Object.keys(variants).map(
        (item) => variants[item].sku,
      )) {
        if (_sku == sku) {
          err[`sku`] = `SKU "${sku}" Already exists`;
          setVariantsErrors(err);
          return false;
        }
      }
      return available;
    } catch (e) {}
  };

  const productVariantFormRef = useRef();

  function updateVariant(key) {
    const newVariants = { ...variants };
    if (newVariants[key]) {
      productVariantFormRef.current?.setUpdateData(newVariants[key]);
      delete newVariants[key];
    }
    setVariants(newVariants);
  }

  function deleteVariant(key) {
    const newVariants = { ...variants };
    if (newVariants[key]) {
      delete newVariants[key];
    }
    setVariants(newVariants);
  }

  const handleCreateTag = async () => {
    const res = await dispatch(createTag(tagSearch));
    const data = res.payload;
    setSelectedTag((prev) => [...prev, { value: data.id, label: data.name }]);
    setTagSearch("");
  };

  return (
    <Box maxWidth="1394px" margin=" 30px auto 0">
      <Stack
        direction={{ xs: "column", sm: "row" }}
        alignItems="center"
        justifyContent="space-between"
        spacing="20px"
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent={{ xs: "space-between", sm: "flex-srart" }}
          sx={{ width: { xs: "100%", sm: "initial" }, gap: "20px" }}
        >
          <Button
            onClick={() => navigate(-1)}
            variant="outlined"
            startIcon={<ArrowBackIcon />}
          >
            Back
          </Button>
          <Typography
            variant="pageTitle"
            noWrap
            component="p"
            data-testid="page-title"
          >
            Add Product
          </Typography>
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          flexWrap="wrap"
          sx={{
            gap: "11px",
            "@media(max-width: 480px)": {
              width: "100%",
              button: { width: "100%" },
            },
          }}
        >
          {/*<Button variant="outlined">Discard</Button>*/}
          <Button color="black" variant="contained" onClick={handleSubmit}>
            Save & continue Editing
          </Button>
        </Stack>
      </Stack>
      <Grid
        container
        columnSpacing={{ xs: 1, sm: 3, xl: "50px" }}
        margin="20px 0 0"
      >
        <Grid item md={7} lg={8} sx={{ maxWidth: "908px", width: "100%" }}>
          {Object.keys(errors).map((key) => (
            <Alert sx={{ mb: 1 }} severity="error">
              {key}: {errors[key]}
            </Alert>
          ))}
          <Block>
            <Typography component="p" variant="label" mb="11px">
              Name
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              data-testid="test-name-id"
              placeholder="Enter product's name"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
              required
            />
            <Typography component="p" variant="label" mb="11px" mt="19px">
              Description
            </Typography>
            <TinymceEditor
              value={description}
              onEditorChange={(e) => {
                setDescription(e);
              }}
            />
            <Typography component="p" variant="label" mb="11px" mt="19px">
              Additional info
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Enter additional info"
              data-testid="test-additional-id"
              value={additionalInfo}
              onChange={(e) => {
                setAdditionalInfo(e.target.value);
              }}
            />
          </Block>
          <Block>
            <Typography component="p" variant="label" mb="11px">
              Media
            </Typography>
            <FileDropzone setImages={setImages} />
            <ImageList fullWidth cols={6} rowHeight={120} gap={15}>
              {images.map((item) => (
                <ProductImageListItem item={item} setImages={setImages} />
              ))}
            </ImageList>
          </Block>
          <Block>
            {Object.keys(variantsErrors).map((key) => (
              <Alert sx={{ mb: 1 }} severity="error">
                {variantsErrors[key]}
              </Alert>
            ))}
            <ProductVariantForm
              ref={productVariantFormRef}
              checkSku={checkSku}
              onSubmit={addVariant}
              setVariantsErrors={setVariantsErrors}
            />
          </Block>
          <Block>
            <Typography component="p" variant="label" mb="11px">
              Variant Config
            </Typography>
            <Box sx={{ margin: { xs: "0 -16px -16px", sm: "0 -24px -24px" } }}>
              <VariantConfigTable
                vars={variants}
                updateVariant={updateVariant}
                deleteVariant={deleteVariant}
              />
            </Box>
          </Block>
        </Grid>

        <Grid item md={5} lg={4} sx={{ width: "100%" }}>
          <Block>
            <Typography component="p" variant="label" mb="11px">
              Internal Identifier
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              data-testid="test-internal-id"
              value={internalId}
              onChange={(e) => setInternalId(e.target.value)}
            />
            <Separator />
            <Typography component="p" variant="label" mb="11px">
              Category
            </Typography>
            <CategorySelect
              name="category"
              label="Product Category"
              value={selectedCategory}
              fullWidth
              onChange={(_event, newValue) => {
                setSelectedCategory(null);
                if (newValue) {
                  if (newValue.value) {
                    setSelectedCategory(newValue);
                  }
                }
              }}
            />
            <Separator />
            <Typography component="p" variant="label" mb="11px">
              Vendor
            </Typography>
            <VendorSelect
              name="vendor"
              label="Product Vendor"
              value={selectedVendor}
              fullWidth
              onChange={(_event, newValue) => {
                setSelectedVendor(null);
                if (newValue) {
                  if (newValue.value) {
                    setSelectedVendor(newValue);
                    //setVendor(newValue.value)
                  }
                }
              }}
            />
          </Block>
          <Block>
            <Stack direction="row" justifyContent="space-between" mb="20px">
              <Typography component="p" variant="label">
                Product Tags
              </Typography>
              <Link to="/tags" component={NavLink}>
                Manage
              </Link>
            </Stack>
            <TagSelect
              multiple
              label="Product Tags"
              id="multiple-limit-tags"
              value={selectedTag}
              onChange={(_event, newValue) => {
                setSelectedTag(newValue);
              }}
              setTagSearch={setTagSearch}
              noOptionsText={
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography>There is no such variant</Typography>
                  <Button onClick={handleCreateTag}>Create</Button>
                </Stack>
              }
            />
          </Block>

          <Block>
            <Typography component="p" variant="label" mb="11px">
              Product Status
            </Typography>
            <Select
              fullWidth
              value={isActive}
              onChange={(e) => setIsActive(e.target.value)}
            >
              <MenuItem value={true}>Active</MenuItem>
              <MenuItem value={false}>Inactive</MenuItem>
            </Select>
            <Separator />
            <Typography component="p" variant="label" mb="11px">
              Delivery Type
            </Typography>
            <Select
              fullWidth
              value={deliveryType}
              onChange={(e) => setDeliveryType(e.target.value)}
            >
              <MenuItem value="MORNING">Morning</MenuItem>
              <MenuItem value="SALES_MAN">Salesman</MenuItem>
            </Select>
            {/*<Separator />*/}
            {/*<Typography component="p" variant="label" mb="11px">*/}
            {/*  Availability*/}
            {/*</Typography>*/}
            {/*<Select fullWidth sx={{ marginBottom: "12px" }}>*/}
            {/*  <MenuItem value="always">Always</MenuItem>*/}
            {/*  <MenuItem value="seasonal">Seasonal</MenuItem>*/}
            {/*</Select>*/}
            {/*<Select fullWidth sx={{ marginBottom: "12px" }}>*/}
            {/*  <MenuItem value="all">All days</MenuItem>*/}
            {/*  <MenuItem value="specific">Specific Weekdays</MenuItem>*/}
            {/*</Select>*/}
            {/*<Select*/}
            {/*  fullWidth*/}
            {/*  multiple*/}
            {/*  value={personName}*/}
            {/*  onChange={multipleSelectChange}*/}
            {/*  sx={{ marginBottom: "15px" }}*/}
            {/*>*/}
            {/*  <MenuItem value="purim">Purim</MenuItem>*/}
            {/*  <MenuItem value="purim2">Purim2</MenuItem>*/}
            {/*</Select>*/}
            {/*<Button color="primary" variant="contained">*/}
            {/*  Add Season*/}
            {/*</Button>*/}
          </Block>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AddProduct;
