import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DataTable from "../DataTable";
import useAppSelector from "../../hooks/useAppSelector";
import useAppDispatch from "../../hooks/useAppDispatch";
import invoiceTableColumns from "../../pages/invoices/invoiceTableColumns";
import {
  cleanTableSettings,
  fetchCustomerInvoices,
  selectCustomerNumberOfPages,
  setCustomerTableSettings,
} from "../../redux/module/customer";
import { useParams, useSearchParams } from "react-router-dom";
import InvoiceFilters from "../../pages/invoices/InvoiceFilters";
import { downloadFile } from "../../distflowAPI/downloadFile";
import DatePicker from "../DatePicker2";
import invoicesApi, {
  generateStatement,
  getSeneratedStatement,
} from "../../distflowAPI/invoicesApi";
import SpecificAccountsSelect from "./SpecificAccountsSelect";
import { formatAgGridSorting } from "../../distflowAPI/common";
import { CheckBox } from "@mui/icons-material";
import { set } from "date-fns";
import { getNoPages } from "../../utils";

const defaultColDef = {
  flex: 1,
  filter: false,
  floatingFilter: false,
};

const CustomerInvoices = ({ accounts }) => {
  const [generated, setGenerated] = useState(false);
  const [generateReport, setGenerateReport] = useState(false);
  const [reportStartDate, setReportStartDate] = useState();
  const [reportEndDate, setReportEndDate] = useState();
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams({
    ordering: "issued_date",
    page_size: 50,
    page: 1,
  });
  const [selectedAccounts, setSelectedAccounts] = useState([]);
  const [generatedStatement, setGeneratedStatement] = useState(null);
  const [settings, setSettings] = useState({
    includePaid: false,
    includePartiallyPaid: true,
    merged: "MERGE",
    includeCredits: true,
  });
  const [invoices, setInvoices] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  function getPageSize() {
    return searchParams.get("page_size") || 50;
  }
  function getPage() {
    return searchParams.get("page") || 1;
  }
  useEffect(() => {
    if (id) {
      invoicesApi
        .list(
          getPageSize(),
          getPage(),
          [],
          [
            {
              column: "customer_id",
              value: id,
            },
            { column: "round", value: "true" },
          ],
          searchParams
        )
        .then((res) => {
          setInvoices(res.results);
          setRowCount(res.count);
        });
    }
  }, [id, searchParams]);

  function handleSortParams(value) {
    let newSortingValue = "";
    if (value?.length > 0) {
      newSortingValue = value[0]?.field;
      if (value[0]?.sort != "asc") {
        newSortingValue = "-" + newSortingValue;
      }
    }
    setSearchParams((params) => {
      const newParams = new URLSearchParams(params);
      newParams.set("ordering", newSortingValue);
      return newParams;
    });
  }
  const handleChange = (field, value) => {
    if (field === "orderBy") {
      handleSortParams(value);
    }
  };
  function handleSearchParams(k, v) {
    console.log("setting", k, "to ", v);
    setSearchParams((params) => {
      const newParams = new URLSearchParams(params);
      if (!v.toString()) {
        console.log("not setting");
        newParams.delete(k);
      } else {
        console.log("yes setting", k, "to ", v);
        newParams.set(k, v);
        if (k != "page") {
          newParams.set("page", 1);
        }
      }
      return newParams;
    });
  }
  return (
    <Box>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        mb={3}
      >
        <Typography component="p" variant="h6">
          Customer invoices
        </Typography>
        <Button variant="contained" onClick={() => setGenerateReport(true)}>
          Download Invoices
        </Button>
      </Stack>
      {(!!invoices.length || searchParams.toString() !== "") && (
        <InvoiceFilters omitCustomer accountSelect customerId={id} />
      )}

      <DataTable
        rowData={invoices}
        columnDefs={invoiceTableColumns}
        defaultColDef={defaultColDef}
        rowHeight={73}
        page={parseInt(getPage())}
        pageSize={getPageSize()}
        loading={false}
        setPage={(page) => handleSearchParams("page", page)}
        setPageSize={(pageSize) => handleSearchParams("page_size", pageSize)}
        noPages={getNoPages(rowCount, getPageSize())}
        onSortChanged={(e) => {
          handleChange(
            "orderBy",
            formatAgGridSorting(e.columnApi.columnModel.gridColumns)
          );
        }}
      />

      <Dialog
        open={generateReport}
        onClose={() => setGenerateReport(false)}
        aria-labelledby="confirm-dialog"
        fullWidth
        maxWidth="sm"
        style={{
          zIndex: "2000!impotant",
        }}
      >
        <DialogTitle mb="40px">
          <div
            style={{
              display: "flex",
              justifyContent: "end",
            }}
          >
            <Typography
              variant="pageTitle"
              noWrap
              component="p"
              mb="20px"
              textAlign="center"
              margin="auto"
            >
              Generate Invoices and Statements Reports
            </Typography>
            <CloseIcon
              sx={{ cursor: "pointer", caretColor: "transparent" }}
              onClick={() => {
                setGenerateReport(false);
              }}
            />
          </div>
        </DialogTitle>

        <DialogContent sx={{ pr: "50px", pl: "50px" }}>
          <Stack spacing={2}>
            <Stack
              direction={"row"}
              alignItems={"center"}
              spacing={2}
              marginBottom={2}
            >
              <Typography sx={{ fontWeight: 600 }}>From:</Typography>
              <Box maxWidth={200}>
                <DatePicker
                  label="Start Date"
                  controlledValue={reportStartDate}
                  setControlledValue={setReportStartDate}
                  required
                />
              </Box>

              <Typography sx={{ fontWeight: 600 }}>To:</Typography>
              <Box maxWidth={200}>
                <DatePicker
                  label="End Date"
                  controlledValue={reportEndDate}
                  setControlledValue={setReportEndDate}
                  required
                />
              </Box>
            </Stack>
            <Stack>
              <Typography>
                <b>Parameters</b>
              </Typography>
              <Stack
                direction={"row"}
                spacing={2}
                justifyContent={"space-between"}
              >
                <Stack>
                  <Stack direction={"row"} alignItems={"center"}>
                    <Checkbox
                      onChange={(e) =>
                        setSettings({
                          ...settings,
                          includePaid: e.target.checked,
                        })
                      }
                      checked={settings.includePaid}
                    />
                    <Typography>Include Paid</Typography>
                  </Stack>
                  <Stack direction={"row"} alignItems={"center"}>
                    <Checkbox
                      onChange={(e) =>
                        setSettings({
                          ...settings,
                          includePartiallyPaid: e.target.checked,
                        })
                      }
                      checked={settings.includePartiallyPaid}
                    />
                    <Typography>Include Partially Paid</Typography>
                  </Stack>
                  <Stack direction={"row"} alignItems={"center"}>
                    <Checkbox
                      onChange={(e) =>
                        setSettings({
                          ...settings,
                          includeCredits: e.target.checked,
                        })
                      }
                      checked={settings.includeCredits}
                    />
                    <Typography>Include Credits</Typography>
                  </Stack>
                </Stack>
                <Stack>
                  <RadioGroup
                    value={settings.merged}
                    onChange={(e) => {
                      setSettings({
                        ...settings,
                        merged: e.target.value,
                      });
                      console.log("set merged settings to: ", e.target.value);
                    }}
                  >
                    <FormControlLabel
                      value={"MERGE"}
                      control={<Radio />}
                      label="One merged file"
                    />
                    <FormControlLabel
                      value={"SPLIT"}
                      control={<Radio />}
                      label="Split by account"
                    />
                  </RadioGroup>
                </Stack>
              </Stack>
            </Stack>
            {settings.merged == "MERGE" ? (
              <Typography>-</Typography>
            ) : (
              <SpecificAccountsSelect
                selectedAccounts={selectedAccounts}
                setSelectedAccounts={setSelectedAccounts}
                data={accounts}
              />
            )}

            <StatementReportResult reportId={generatedStatement} />
          </Stack>
        </DialogContent>
        <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
          <Button
            variant="contained"
            color={"error"}
            onClick={() => {
              setGenerateReport(false);
              setGeneratedStatement(null);
            }}
            sx={{ mb: "30px" }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{ mb: "30px" }}
            onClick={() => {
              generateStatement(
                reportStartDate?.isValid()
                  ? reportStartDate?.format("YYYY-MM-DD")
                  : null,
                reportEndDate?.isValid()
                  ? reportEndDate?.format("YYYY-MM-DD")
                  : null,
                id,
                selectedAccounts.map((account) => account.id).join(","),
                settings
              ).then((res) => {
                setGenerated(true);
                setGeneratedStatement(res.data.id);
                //setGenerateReport(false);
              });
            }}
            color={"primary"}
          >
            Generate
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default CustomerInvoices;

function StatementReportResult({ reportId }) {
  const [generatedStatementData, setGeneratedStatementData] = useState({});
  const [refC, setRefC] = useState(0);

  function loadData() {
    if (reportId) {
      getSeneratedStatement(reportId).then(
        (res) => {
          setGeneratedStatementData(res.data);
        },
        (err) => {}
      );
    }
  }

  useEffect(() => {
    setGeneratedStatementData({});
    loadData();
    setRefC(0);
  }, [reportId]);
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    console.log(
      "file is: ",
      generatedStatementData?.file,
      !generatedStatementData?.file
    );
    if (!generatedStatementData?.file) {
      setTimeout(() => setRefC(refC + 1), 1000);
      loadData();
    }
  }, [refC, reportId]);
  return (
    <>
      {Object.keys(generatedStatementData).length > 0 && (
        <Table sx={{ marginTop: 2 }}>
          <TableHead>
            <TableRow>
              <TableCell width="10px">Id</TableCell>
              <TableCell width="20px">From</TableCell>
              <TableCell width="20px">Till</TableCell>
              <TableCell width="85px">Status</TableCell>
              <TableCell width="10px">No Invoices</TableCell>
              <TableCell width="80px">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow height="45px">
              <TableCell>{generatedStatementData.id}</TableCell>
              <TableCell>{generatedStatementData.start_date}</TableCell>
              <TableCell>{generatedStatementData.end_date}</TableCell>
              <TableCell>{generatedStatementData.status}</TableCell>
              <TableCell>{generatedStatementData.invoices}</TableCell>
              <TableCell>
                {generatedStatementData.file && (
                  <Button
                    onClick={() =>
                      downloadFile(
                        generatedStatementData.file,
                        "statements.zip"
                      )
                    }
                    variant="outlined"
                  >
                    Download
                  </Button>
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      )}
    </>
  );
}
