import { useEffect, useMemo, useState } from "react";
import useAppDispatch from "../../hooks/useAppDispatch";
import useAppSelector from '../../hooks/useAppSelector';
import { fetchProductById, fetchVariantById } from "../../redux/module/product";

import { Box, Tabs, Tab, Badge, Typography } from "@mui/material";
import TabPanel from "../../components/TabPanel";

import SimpleMapWrapper from "../../components/SimpleMap";
import PickList from "./PickList";
import { SOLD_BY_OPTIONS } from "../products/consts";

// import { ReactComponent as Trash } from "../../images/trash-alt.svg";
import IssueList from "./IssueList";
import { orderItemStatusMap } from "../orders/consts";

const PickupDetails = ({ vendor, pickup }) => {

    const dispatch = useAppDispatch();
    const [value, setValue] = useState(0);
    const [info, setInfo] = useState([]);

    const availableRoutes = useAppSelector(
        (state) => state.routeReports.reports
    );

    const selectedReport = useAppSelector(
        (state) => state.routeReports.selectedReport
    );

    const keyedVendors = useAppSelector(
        (state) => state.vendor.vendorMap
    );

    const pickupItems = useMemo(() => {
        return selectedReport?.route_report?.pickup_items ?? [];
    }, [selectedReport]);

    const orderItems = useMemo(() => {
        return selectedReport.route?.items_calc;
    }, [selectedReport]);

    useEffect(() => {
        let prePickupItems = [];

        const pickupRoute = selectedReport.route.vendors_calc.find(x => x.id == vendor);
        if (pickupRoute && pickupRoute.pickup_route) {
            // means that this route has to pickup for another route
            if (selectedReport.route.id == pickupRoute.pickup_route) {
                const t = availableRoutes.find(x => x.route_id != selectedReport.route_id && x.route.vendors_calc.find(z => z.id == vendor && z.pickup_route == selectedReport.route_id) != undefined);
                const itemsForVendor = t.route.items_calc.filter(x => x.items__variant__product__vendor == vendor);
                prePickupItems = itemsForVendor.map(x => ({ ...x, pickupRoute: true, route: t.route_id }));
            }
        }

        let itemsToBePickedUp = orderItems?.filter(x => x.items__variant__product__vendor == vendor) ?? [];
        itemsToBePickedUp = [...prePickupItems, ...itemsToBePickedUp];
        
        // filter pending items
        //itemsToBePickedUp = itemsToBePickedUp.filter(x => x.status == orderItemStatusMap.APPROVED.key);

        const alreadyPickedItems = pickupItems.filter(x => x.vendor == vendor) ?? [];
        const newInfo = [];

        for (let pickupItem of itemsToBePickedUp) {
            const existing = alreadyPickedItems.find(x => x.variant == pickupItem.items__variant && x.unit == pickupItem.unit && x.account == pickupItem.account_id);

            newInfo.push({
                id: existing?.id ?? 0,
                productId: pickupItem.items__variant__product,
                variantId: pickupItem.items__variant,
                accountId: pickupItem.account_id,
                quantity: pickupItem.items__quantity,
                vendorId: pickupItem.items__variant__product__vendor,
                pickedAmount: existing?.amount ?? 0,
                note: existing?.note,
                user: existing?.user,
                updated_at: existing?.updated_at,
                // refactor?
                pickupRoute: pickupItem.pickupRoute,
                pickupRouteId: pickupItem.route,
                unit: pickupItem.items__quantity > 1 ? SOLD_BY_OPTIONS[pickupItem.unit]?.pluralName : SOLD_BY_OPTIONS[pickupItem.unit]?.name,
                unitShort: pickupItem.unit,
                status: pickupItem.status
            });
        }

        setInfo(newInfo);
    }, [orderItems, pickupItems, vendor]);

    useEffect(() => {
        for (let productInfo of info) {
            dispatch(fetchProductById({ id: productInfo.productId }))
            dispatch(fetchVariantById({ productId: productInfo.productId, variantId: productInfo.variantId }));
        }
    }, [info]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const generateTabProps = (index) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const generateIssuesTabProps = (index) => {
        const defaultProps = generateTabProps(index);
        return { ...defaultProps, backgroundColor: 'red' };
    }

    const hasIssues = useMemo(() => {
        if (!info) {
            return false;
        }

        return !info.filter(x => x.status == orderItemStatusMap.APPROVED.key).every(x => x.quantity == x.pickedAmount)
    }, [info]);

    const issues = useMemo(() => {
        if (!hasIssues) {
            return [];
        }

        return info.filter(x => x.status == orderItemStatusMap.APPROVED.key && x.quantity != x.pickedAmount);
    }, [info]);

    const hasNotes = useMemo(() => {
        if (!info) {
            return false;
        }

        return !info.some(x => x.note && x.note.length > 0);
    }, [info])

    const notes = useMemo(() => {
        if (!info) {
            return false;
        }

        return info.filter(x => x.note && x.note.length > 0);
    })

    return (
        <>
            <Box sx={{ border: 1, borderRadius: 0.90, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="Vendor Pickups" centered sx={{ paddingTop: '2px' }}>
                    <Tab label="Picklist" {...generateTabProps(0)} style={{ minWidth: "25%" }} />
                    <Tab label="Notes" {...generateTabProps(1)} style={{ minWidth: "25%" }} />

                    <Tab
                        indicatorColor="red"
                        label={
                            <Badge size="sm" color='error' badgeContent={issues.length}>
                                <Typography sx={{ marginRight: '12px' }}>Issues</Typography>
                            </Badge>}
                        {...generateIssuesTabProps(2)}
                        style={{ minWidth: "25%", color: hasIssues ? 'red' : 'unset' }} />
                    <Tab label="Location" {...generateTabProps(3)} style={{ minWidth: "25%" }} />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                <PickList pickup={pickup} items={info} />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <PickList renderAsNotes pickup={pickup} items={notes} />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <IssueList issues={issues} />
            </TabPanel>
            <TabPanel value={value} index={3}>
                <SimpleMapWrapper height={300} location={{
                    address: keyedVendors[vendor]?.address ?? "test",
                    lat: keyedVendors[vendor]?.latitude ?? 38.8951,
                    lng: keyedVendors[vendor]?.longitude ?? -77.0364,
                }}>
                </SimpleMapWrapper>
            </TabPanel>
        </>
    )
}

export default PickupDetails;