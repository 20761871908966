import React, { useState } from "react";
import { IconButton, TableCell, TableRow } from "@mui/material";
import { CheckCircleOutlineRounded, HighlightOff } from "@mui/icons-material";
import dayjs from "dayjs";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import ChangesHistory from "./History";
import ConfirmDialog from "./ConfirmDialog";
import { DATE_FORMAT_FRONT } from "../utils/constants";

const OverrideAccordionItem = ({
  item,
  handleUpdate,
  handleDelete,
  elementKey,
  upcharges,
  cost,
}) => {
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const totalOveerides =
    Number(upcharges.category_upcharge) +
    Number(upcharges.global_upcharge) +
    Number(upcharges.vendor_upcharge) +
    Number(upcharges.self_upcharge) +
    Number(item.override);
  const currentPrice = Number(cost) + (Number(cost) * totalOveerides) / 100;
  return (
    <>
      <ConfirmDialog
        title="Delete override?"
        open={isOpenDelete}
        setOpen={setIsOpenDelete}
        onConfirm={() => handleDelete(item)}
      >
        {`Are you sure you want to delete this ${elementKey} override?`}
      </ConfirmDialog>
      <TableRow>
        <TableCell>
          {item.is_current ? (
            <CheckCircleOutlineRounded color="success" fontSize="small" />
          ) : (
            <HighlightOff color="error" fontSize="small" />
          )}
        </TableCell>
        <TableCell>
          {item.start_date
            ? dayjs.utc(item.start_date).format(DATE_FORMAT_FRONT)
            : ""}
        </TableCell>
        <TableCell>
          Exp:{" "}
          {item.end_date
            ? dayjs.utc(item.end_date).format(DATE_FORMAT_FRONT)
            : "N/A"}
        </TableCell>
        <TableCell>{Number(item.override).toFixed(2)}% </TableCell>
        <TableCell>${currentPrice.toFixed(2)}</TableCell>
        <TableCell>
          <IconButton onClick={() => handleUpdate(item)}>
            <EditOutlinedIcon fontSize={"small"} />
          </IconButton>
          <IconButton onClick={() => setIsOpenDelete(true)}>
            <DeleteForeverOutlinedIcon fontSize={"small"} />
          </IconButton>
          <ChangesHistory historyData={item.history || []} fontSize={"small"} />
        </TableCell>
      </TableRow>
    </>
  );
};

export default OverrideAccordionItem;
