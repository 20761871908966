/* eslint-disable default-case */
import dayjs from "dayjs";
import { DATE_FORMAT } from "../../utils/constants";

export const INVOICE_INIT = {
  id: null,
  po_id: null,
  status: "UNPAID",
  order: {
    id: null,
    fulfillment_date: dayjs().format(DATE_FORMAT),
    account: {
      id: null,
      name: "",
    },
  },
  invoice_payments: [],
  items: [],
  returns: [],
  fees: [],
  routes: [],
  notes: "",
  is_updating: false,
  total_paid: 0,
  config: {
    addItem: false,
    addReturn: false,
    item_to_add: false,
    item_to_return: false,
    saved: true,
    changed: false,
    showItemsDetailed: false,
  },
  reconciliations: [],
};

export const REDUCER_ACTIONS = {
  SET_DATA: "SET_DATA",
  SET_VALUE: "SET_VALUE",
  ADD_FEE: "ADD_FEE",
  UPDATE_FEE: "UPDATE_FEE",
  DELETE_FEE: "DELETE_FEE",
  UPDATE_ITEM: "UPDATE_ITEM",
  SET_CONFIG_VAL: "SET_CONFIG_VAL",
  ADD_ITEM: "ADD_ITEM",
  ADD_RETURN: "ADD_RETURN",
  UPDATE_RETURN: "UPDATE_RETURN",
  UPDATE_ORDER: "UPDATE_ORDER",
  DELETE_RETURN: "DELETE_RETURN",
  DELETE_ITEM: "DELETE_ITEM",
};

export function reducer(state, action) {
  switch (action.type) {
    case (action.type = REDUCER_ACTIONS.SET_VALUE): {
      return {
        ...state,
        [action.name]: action.value,
        config: {
          ...state.config,
          saved: false,
          changed: true,
        },
      };
    }
    case (action.type = REDUCER_ACTIONS.SET_VALUES): {
      return {
        ...state,
        ...action.payload,
        config: {
          ...state.config,
          saved: true,
          changed: false,
        },
      };
    }
    case (action.type = REDUCER_ACTIONS.ADD_FEE): {
      const newFees = [...state.fees, { name: "Fee name", value: 0 }];
      return {
        ...state,
        fees: newFees,
        config: {
          ...state.config,
          saved: false,
          changed: true,
        },
      };
    }
    case (action.type = REDUCER_ACTIONS.UPDATE_FEE): {
      const newFees = [...state.fees];
      newFees[action.index][action.name] = action.value;
      return {
        ...state,
        fees: newFees,
        config: {
          ...state.config,
          saved: false,
          changed: true,
        },
      };
    }
    case (action.type = REDUCER_ACTIONS.DELETE_FEE): {
      const newFees = [...state.fees];
      newFees.splice(action.index, 1);
      return {
        ...state,
        fees: newFees,
        config: {
          ...state.config,
          saved: false,
          changed: true,
        },
      };
    }
    case (action.type = REDUCER_ACTIONS.UPDATE_ITEM): {
      const newItems = [...state.items];
      console.log("new items: ", newItems);
      console.log(
        "item at index: ",
        action.index,
        " is ",
        newItems[action.index],
      );
      newItems[action.index][action.name] = action.value;

      console.log("action is ", action);
      console.log("selected item is", newItems[action.index]);

      return {
        ...state,
        items: newItems,
        config: {
          ...state.config,
          saved: false,
          changed: true,
        },
      };
    }
    case (action.type = REDUCER_ACTIONS.SET_CONFIG_VAL): {
      const newConfig = { ...state.config };
      newConfig[action.name] = action.value;
      return {
        ...state,
        config: newConfig,
      };
    }
    case (action.type = REDUCER_ACTIONS.ADD_ITEM): {
      const newItems = [...state.items];
      newItems.push(action.payload);
      return {
        ...state,
        items: newItems,
        config: {
          ...state.config,
          saved: false,
          changed: true,
        },
      };
    }
    case (action.type = REDUCER_ACTIONS.ADD_RETURN): {
      const newItems = [...state.returns];
      newItems.push(action.payload);
      return {
        ...state,
        returns: newItems,
        config: {
          ...state.config,
          saved: false,
          changed: true,
        },
      };
    }
    case (action.type = REDUCER_ACTIONS.UPDATE_RETURN): {
      const newItems = [...state.returns];
      newItems[action.index][action.name] = action.value;
      return {
        ...state,
        returns: newItems,
        config: {
          ...state.config,
          saved: false,
          changed: true,
        },
      };
    }
    case (action.type = REDUCER_ACTIONS.UPDATE_ORDER): {
      const newOrder = { ...state.order };
      newOrder[action.name] = action.value;
      return {
        ...state,
        order: newOrder,
        config: {
          ...state.config,
          saved: false,
          changed: true,
        },
      };
    }
    case (action.type = REDUCER_ACTIONS.DELETE_RETURN): {
      const newReturns = [...state.returns];
      newReturns.splice(action.index, 1);
      return {
        ...state,
        returns: newReturns,
        config: {
          ...state.config,
          saved: false,
          changed: true,
        },
      };
    }
    case (action.type = REDUCER_ACTIONS.DELETE_ITEM): {
      const newItems = [...state.items];
      newItems[action.index]["status"] = "CANCELED";
      return {
        ...state,
        items: newItems,
        config: {
          ...state.config,
          saved: false,
          changed: true,
        },
      };
    }
  }
}

export function getItemUnitPrice(orderItem) {
  const disc = getItemDicountItem(orderItem);
  if (disc < 0) {
    return -disc + orderItem.unit_price;
  }
  return orderItem.unit_price;
}

export function getItemDicountItem(item) {
  const dv = parseFloat(item.discount_value);
  if (dv && !isNaN(item.discount_value)) {
    if (item.discount_type == "PERCENT") {
      return item.unit_price * (dv / 100);
    } else {
      return dv;
    }
  } else {
  }
  return 0;
}

export function getItemDicount(item) {
  if (
    item.discount_value &&
    !isNaN(item.discount_value) &&
    item.discount_value > 0
  ) {
    if (item.discount_type === "PERCENT") {
      return parseFloat(item.unit_price * (item.discount_value / 100))
    } else {
      return parseFloat(item.discount_value)
    }
  }
  return 0;
}
