import { Box, Button, Link, Stack, Typography, Menu, MenuItem } from "@mui/material";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useModal } from "mui-modal-provider";
import PickupModal from "./PickupModal";
import useAppSelector from "../../hooks/useAppSelector";
import { ReactComponent as Driver } from "../../images/driving.svg";
import { ReactComponent as RouteIc } from "../../images/route.svg";
import { ReactComponent as PickSheet } from "../../images/picksheet.svg";
import { ReactComponent as Navigation } from "../../images/navigation.svg";
import { ReactComponent as OpenIc } from "../../images/open-ic.svg";
import { NavLink } from "react-router-dom";
import { memo, useState } from "react";
import dayjs from "dayjs";
import DeliveryModal from "./DeliveryModal";
import PickSheetModal from "./PickSheetModal";
import { createSearchParams } from "react-router-dom";
import { statusColorMap, statusTextMap } from './constants'
import useAppDispatch from "../../hooks/useAppDispatch";
import {
  createRouteReport,
  fetchRouteReportById,
  setSelectedReport,
  updateRouteReportStatus,
  setSelectedReportPickupItems,
  calculateSelectedReportPickupItems,
  calculateSelectedReportDeliveryItems
} from "../../redux/module/routeReports";

const Route = memo(
  ({
    calculation_date,
    // base object
    routeReport,
    // old props
    newMessage,
    // deliveriesStart,
    // deliveriesEnd,

    //orders,
    // order_items,
    // pickups,
    deliveries,
  }) => {

    const orders = routeReport.route.orders_calc
    const order_items = routeReport.order_items;

    // extract the info here
    const dispatch = useAppDispatch();

    // Menu section
    const [anchorElement, setAnchorElement] = useState();
    const open = Boolean(anchorElement);
    // End Menu section
    const { showModal } = useModal();

    const drivers = useAppSelector(
      (state) => state.driver.drivers
    );

    const userMap = useAppSelector(
      (state) => state.user.userMap
    );

    const handleStatusClick = (event) => {
      setAnchorElement(event.target);
    }

    const handleStatusClose = (event) => {
      setAnchorElement(null);
    }

    const getDriverName = () => {
      if (drivers && drivers.length > 0 && routeReport) {
        return drivers.find(x => x.id == routeReport.route.route_calc?.driver)?.user.first_name;
      }
    }

    const updateStatusWrapper = async (incomingStatus) => {
      if (!routeReport.route_report?.state) {
        dispatch(createRouteReport({
          state: incomingStatus,
          date: calculation_date,
          routeId: routeReport.route.id
        }));

        dispatch(fetchRouteReportById({ routeId: routeReport.route.id, date: calculation_date }));
      }
      else {
        dispatch(updateRouteReportStatus({
          id: routeReport.route_report.id,
          date: calculation_date,
          state: incomingStatus
        }));
      }

      handleStatusClose();
    }

    const buildPickupModalProps = () => {

      dispatch(setSelectedReport(routeReport));
      dispatch(calculateSelectedReportPickupItems({}));
      // const pickups = routeReport.route_report.pickups;
      // const pickupsList = [...pickups];

      // const orderVendors = orders.map((x) => x.vendors);
      // const flattened = new Set(orderVendors.concat.apply([], orderVendors));

      // for (let pickupVendor of flattened) {
      //   if (pickupsList.find((x) => x.vendor == pickupVendor)) {
      //     continue;
      //   }

      //   pickupsList.push({ vendor: pickupVendor, state: "INVALID" });
      // }

      //dispatch(setSelectedReportPickupItems(pickupsList));
      return {};
    };

    const buildDeliveryModalProps = () => {

      dispatch(setSelectedReport(routeReport));
      dispatch(calculateSelectedReportDeliveryItems({}));
      return {};
      // const deliveriesList = [...deliveries];
      // const accounts = orders.map((x) => x.account_id);

      // for (let account of accounts) {
      //   if (deliveriesList.find((x) => x.account == account)) {
      //     continue;
      //   }

      //   deliveriesList.push({ account, state: "-1" });
      // }

      // return {
      //   deliveriesData: {
      //     deliveries: deliveriesList,
      //     deliveriesStart: routeReport.total_delivered,
      //     deliveriesEnd: routeReport.total_deliveries,
      //     orderItems: order_items,
      //   },
      // };
    };

    return (
      <Box border="1px solid #DBDFEA" borderRadius="5px" bgcolor="#fff">
        <Stack
          direction="row"
          minHeight="82px"
          borderBottom="1px solid rgba(101,118,255,0.12)"
        >
          <Box
            width="166px"
            borderRight="1px solid rgba(101,118,255,0.12)"
            p="15px 13px"
            flexShrink={0}
          >
            <Stack direction="row" alignItems="center" gap={1} mb="3px">
              <Box
                width="20px"
                height="20px"
                borderRadius="50%"
                bgcolor={routeReport.route_report?.state ? statusColorMap[routeReport.route_report.state] : statusColorMap['INVALID']}
              ></Box>
              <>
                <Typography
                  id="status-text"
                  aria-controls={open ? 'basic-menu' : null}
                  aria-haspopup={true}
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleStatusClick}
                  fontSize="18px"
                  fontWeight={700}
                  noWrap
                  color={routeReport.route_report?.state ? statusColorMap[routeReport.route_report.state] : statusColorMap['INVALID']}
                >
                  {routeReport.route_report?.state ? statusTextMap[routeReport.route_report.state] : 'Not started'}
                </Typography>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorElement}
                  open={open}
                  onClose={handleStatusClose}
                  MenuListProps={{
                    'aria-labelledby': 'status-text',
                  }}
                >
                  <MenuItem onClick={() => updateStatusWrapper('EN_ROUTE')}>En Route</MenuItem>
                  <MenuItem onClick={() => updateStatusWrapper('AT_PICKUP')}>At Pickup</MenuItem>
                  <MenuItem onClick={() => updateStatusWrapper('AT_DELIVERY')}>At Delivery</MenuItem>
                  <MenuItem onClick={() => updateStatusWrapper('FINISHED')}>Completed</MenuItem>
                </Menu>
              </>
            </Stack>
            {!routeReport.route_report?.state ? null : (
              <>
                <Typography fontSize="14px" color="#535151">
                  Started {dayjs(routeReport.route_report?.created_at).format("h:mm a")}
                </Typography>
                <Typography fontSize="13px" color="#535151">
                  Updated at {dayjs(routeReport.route_report?.updated_at).format("h:mm a")} by <b>{userMap[routeReport.route_report.updated_by]?.first_name}</b>
                </Typography>
              </>
            )}
          </Box>

          <Stack
            width="100%"
            direction="row"
            alignItems="flex-start"
            justifyContent="space-between"
            flexWrap="wrap"
            gap="15px"
            p="15px 20px"
          >
            <Box>
              <Typography
                color="#535151"
                fontWeight={700}
                sx={{ wordBreak: "break-all" }}
                mb="3px"
              >
                {routeReport.route.name}
              </Typography>
              <Stack direction="row" alignItems="center" gap="6px">
                <Driver />
                <Typography color="#535151" fontWeight={500}>
                  {getDriverName()}
                </Typography>
              </Stack>
            </Box>
            {/* <Button
            size="small"
            variant="outlinedBlue"
            position="relative"
            startIcon={<Chat />}
            sx={{
              ":before": {
                content: newMessage ? "''" : "none",
                position: "absolute",
                top: "-6px",
                right: "-4px",
                width: "10px",
                height: "10px",
                borderRadius: "50%",
                bgcolor: "#FF0000",
              },
            }}
          >
            23
          </Button> */}
          </Stack>
        </Stack>

        <Stack direction="row">
          <Stack
            width="166px"
            borderRight="1px solid rgba(101,118,255,0.12)"
            gap="10px"
            p="15px 10px"
            flexShrink={0}
          >
            <Button
              to={`/routes/route?${createSearchParams({
                id: routeReport.route_id,
                date: calculation_date,
              })}`}
              fullWidth
              variant="outlinedBlue"
              position="relative"
              startIcon={<RouteIc />}
              component={NavLink}
              sx={{ "&:hover svg": { stroke: "#fff", fill: "#3F75C6" } }}
            >
              View Route
            </Button>
            <Button
              fullWidth
              variant="outlinedBlue"
              position="relative"
              onClick={e => { showModal(PickSheetModal, buildPickupModalProps()); }}
              startIcon={<PickSheet />}
            >
              Pick Sheet
            </Button>
            {routeReport.route_report?.state && (
              <Button
                fullWidth
                variant="outlinedBlue"
                position="relative"
                startIcon={<Navigation />}
              >
                View Location
              </Button>
            )}
          </Stack>

          <Stack
            direction="row"
            gap="28px"
            p="11px 17px"
            sx={{ "@media (max-width: 440px)": { flexWrap: "wrap" } }}
          >
            <Box textAlign="center">
              <Button
                size="small"
                variant="gray"
                endIcon={<OpenIc />}
                onClick={() => showModal(PickupModal, buildPickupModalProps())}
                sx={{ marginBottom: "10px" }}
              >
                Pickups
              </Button>
              <Link
                onClick={() => showModal(PickupModal, buildPickupModalProps())}
                sx={{ cursor: "pointer" }}
              >
                <CircularProgressbarWithChildren
                  value={routeReport.total_picked_up}
                  maxValue={routeReport.total_pickups}
                  strokeWidth={17}
                  styles={buildStyles({
                    pathColor: "#00D1A5",
                    trailColor: "#E7E7E7",
                    rotation: 0.95,
                  })}
                >
                  <Typography fontSize="28px" fontWeight={700} color="#535151">
                    {routeReport.total_picked_up}/{routeReport.total_pickups}
                  </Typography>
                </CircularProgressbarWithChildren>
              </Link>
            </Box>
            <Box textAlign="center">
              <Button
                size="small"
                variant="gray"
                endIcon={<OpenIc />}
                onClick={() =>
                  showModal(DeliveryModal, buildDeliveryModalProps())
                }
                sx={{ marginBottom: "10px" }}
              >
                Deliveries
              </Button>
              <Link
                onClick={() =>
                  showModal(DeliveryModal, buildDeliveryModalProps())
                }
                sx={{ cursor: "pointer" }}
              >
                <CircularProgressbarWithChildren
                  value={routeReport.total_delivered}
                  maxValue={routeReport.total_deliveries}
                  strokeWidth={20}
                  styles={buildStyles({
                    pathColor: "#BDBDBD",
                    trailColor: "#E7E7E7",
                    rotation: 0.95,
                  })}
                >
                  <Typography fontSize="28px" fontWeight={700} color="#535151">
                    {routeReport.total_delivered}/{routeReport.total_deliveries}
                  </Typography>
                </CircularProgressbarWithChildren>
              </Link>
            </Box>
          </Stack>
        </Stack>
      </Box>
    );
  }
);

export default Route;
