import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useEffect, useState } from "react";
import DatePicker from "../DatePicker2";
import { listActualOrders } from "../../distflowAPI/ordersApi";
import dayjs from "dayjs";
import PageLoading from "../PageLoading";
import Spinner from "../Spinner";

export default function ImportOrderModal({
  importOpen,
  setImportOpen,
  account,
  setItemsCopy,
  setAddressCopy,
}) {
  const [items, setItems] = useState([]);
  const [startDate, setStartDate] = useState(dayjs().subtract(7, "days"));
  const [endDate, setEndDate] = useState(dayjs());
  const [isLoading, setIsLoading] = useState(false);
  const getItemPrice = (item) => {
    if (
      item.item_details.break_into_pieces &&
      item.unit != item.item_details.sold_by
    ) {
      return (
        parseFloat(item.item_details.price?.price) /
        parseInt(item.item_details.sold_by_qty)
      );
    }
    return parseFloat(item.item_details.price?.price);
  };
  function cleanOrdersToCopy(orders) {
    return orders.map((order) => {
      const newOrder = { ...order };
      newOrder.items = newOrder.items.filter(
        (item) => item?.status != "CANCELED"
      );
      return newOrder;
    });
  }
  useEffect(() => {
    setIsLoading(true);
    if (!account?.value) {
      setItems([]);
      return;
    }
    listActualOrders(
      "",
      account?.value,
      startDate.format("YYYY-MM-DD"),
      endDate.format("YYYY-MM-DD")
    )
      .then((res) => {
        setItems(
          cleanOrdersToCopy(
            res.data.results
              .filter((it) => it.accountId == account?.value)
              .filter((it) => it.items?.length > 0)
          )
        );
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("An error occurred:", error);
      });
  }, [startDate, endDate, importOpen, account]);

  function addPrice(array) {
    const newArray = array.map((item) => {
      return {
        ...item,
        item_details: {
          price: { price: item.unit_price },
          ...item.item_details,
        },
      };
    });
    return newArray;
  }

  const handleCopyClick = (item) => {
    const arr = addPrice(item.items);
    const load = {};
    arr.map((item) => {
      load[`${item.item}-${item.unit}-${item.display_name || ""}`] = {
        ...item,
        invoice_note: "",
        state: "APPROVED",
        status: "APPROVED",
      };
    });
    setItemsCopy(load);
    setAddressCopy(item.address);
    setImportOpen(false);
    setStartDate(dayjs().subtract(7, "days"));
    setEndDate(dayjs());
  };
  return (
    <>
      <Dialog
        open={importOpen}
        onClose={() => {
          setImportOpen(false);
          setItems([]);
        }}
        maxWidth="xl"
        sx={{
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "800px",
          },
        }}
      >
        <DialogTitle mb="15px">
          <Typography sx={{ fontWeight: "bolder", textAlign: "center" }}>
            Copy Order
          </Typography>
          <Typography textAlign="center">{account?.label}</Typography>
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "30px",
              mt: "20px",
            }}
          >
            <Typography sx={{ mb: "15px", fontWeight: 600 }}>From:</Typography>
            <Box maxWidth={200}>
              <PageLoading dataLoading={isLoading} />
              <DatePicker
                label="Start Date"
                controlledValue={startDate}
                setControlledValue={setStartDate}
                required
              />
            </Box>
            <Typography sx={{ mb: "5px", fontWeight: 600 }}>To:</Typography>
            <Box maxWidth={200}>
              <DatePicker
                label="End Date"
                controlledValue={endDate}
                setControlledValue={setEndDate}
                required
              />
            </Box>
          </Box>

          <Box>
            {items.length === 0 && (
              <Typography
                sx={{
                  mb: "30px",
                  fontWeight: 600,
                  color: "#880808",
                  textAlign: "center",
                }}
              >
                No orders found for the specified period
              </Typography>
            )}
            <Box>
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                {isLoading && <Spinner sx={{ zIndex: 9000 }} size="lg" />}
              </Stack>

              {items &&
                items
                  .filter((item) => item?.items?.length > 0)
                  .map((item) => {
                    return (
                      <Accordion
                        key={item.id}
                        sx={{ backgroundColor: "#fbfbfb" }}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography fontWeight="bolder">
                              {dayjs(item.date).format("MMM DD, YY")} (
                              {item?.items?.length} items)
                            </Typography>
                          </Box>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              gap: "20px",
                            }}
                          >
                            <Table
                              sx={{ minWidth: 650 }}
                              aria-label="simple table"
                            >
                              <TableHead>
                                <TableRow>
                                  <TableCell>Product name</TableCell>
                                  <TableCell align="right">Sold By</TableCell>
                                  <TableCell align="right">
                                    Unit Price
                                  </TableCell>
                                  <TableCell align="right">Quantity</TableCell>
                                  <TableCell align="right">Total</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {item.items.map((item) => (
                                  <TableRow
                                    key={item.id}
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 0,
                                      },
                                    }}
                                  >
                                    <TableCell component="th" scope="row">
                                      {item.item_details.name}
                                    </TableCell>
                                    <TableCell align="right">
                                      {item.unit || item.item_details.sold_by}
                                    </TableCell>
                                    <TableCell align="right">
                                      $
                                      {
                                        item.unit_price
                                        //zakaria
                                      }
                                    </TableCell>
                                    <TableCell align="right">
                                      {item.qty}
                                    </TableCell>
                                    <TableCell align="right">
                                      $
                                      {
                                        (item.unit_price * item.qty).toFixed(2)
                                        //zakaria
                                      }
                                    </TableCell>
                                  </TableRow>
                                ))}
                                <TableRow>
                                  <TableCell>-</TableCell>
                                  <TableCell>-</TableCell>
                                  <TableCell>-</TableCell>
                                  <TableCell align="right">total</TableCell>
                                  <TableCell align="right">
                                    $
                                    {item.items
                                      .reduce(
                                        (acc, val) =>
                                          acc +
                                          Number(val.unit_price * val.qty),
                                        0
                                      )
                                      ?.toFixed(2)}
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              gap: "15px",
                              mt: "30px",
                            }}
                          >
                            <Button
                              variant="contained"
                              color="info"
                              onClick={() => handleCopyClick(item)}
                              justifySelf="end"
                            >
                              COPY
                            </Button>
                          </Box>
                        </AccordionDetails>
                      </Accordion>
                    );
                  })}
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
