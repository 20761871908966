import {
  Button,
  Typography,
  Box,
  Grid,
  Checkbox,
  Snackbar,
  Alert,
  TextField,
} from "@mui/material";
import { Editor } from "@tinymce/tinymce-react";
import { useState, useEffect } from "react";
import Block from "../../../components/Block";
import CustomerSelect from "../../../components/AsyncSelects/CustomerSelect";
import AddIcon from "@mui/icons-material/Add";
// import AccountSelect from "../../../components/AsyncSelects/AccountSelect";
import CustomerCard from "./CustomerCard";
import { emailsReducer, EMAILS_INIT, REDUCER_ACTIONS } from "./config";
import AttachmentComponent from "./AttachmentComponent";
import { useReducer } from "react";

import { sendEmail } from "../../../distflowAPI/customersApi";
export default function EmailSend() {
  const [data, dispatch] = useReducer(emailsReducer, EMAILS_INIT);
  const [content, setContent] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [editorKey, setEditorKey] = useState(0);
  const [subject, setSubject] = useState("");
  const [result, setResult] = useState({
    valid_customers: [],
    invalid_customers: [],
  });

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleCheckBoxChange = (event) => {
    setIsChecked(event.target.checked);
  };
  const [selectedCustomer, setSelectedCustomer] = useState({
    label: "",
    value: "",
  });
  const handleFileChange = (event) => {
    dispatch({
      type: REDUCER_ACTIONS.ADD_FILE,
      payload: event.target.files,
    });
  };

  const [customersList, setCustomersList] = useState([]);
  console.log(selectedCustomer);
  const handleEditorChange = (content, editor) => {
    setContent(content);
  };

  function handleEmailSend() {
    setIsSending(true);
    const customersArray = [];
    data.customers_list.map((customer) => {
      customersArray.push(customer.value);
    });
    const payload = new FormData();

    if (isChecked) {
      payload.append("email_all", true);
      payload.append("customers_ids", []);
    } else {
      payload.append("customers_ids", customersArray);
    }
    if (subject) {
      payload.append("subject", subject);
    }
    payload.append("content", content);
    if (data.email_files) {
      for (let i = 0; i < data.email_files.length; i++) {
        let file = data.email_files[i];
        payload.append(`attachments.${i}`, file, file.name);
        console.log("attaching ", file, file.name);
      }
    }
    for (let [key, value] of payload.entries()) {
      console.log(`${key}: ${value}`);
    }

    sendEmail(payload)
      .then((res) => {
        setResult({
          valid_customers: res.data.valid_customers,
          invalid_customers: res.data.invalid_customers,
        });
        setIsSending(false);
        setContent("");
        setSnackbarMessage("Email sent successfully!");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
        setSubject("");
        setEditorKey((prevKey) => prevKey + 1);
      })
      .catch((err) => {
        setIsSending(false);
        setContent("");
        setSnackbarMessage("Failed to send email.");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
        setSubject("");
        setEditorKey((prevKey) => prevKey + 1);
      });
  }

  return (
    <>
      {result.invalid_customers.length > 0 && (
        <>
          <Alert color="error" sx={{ mb: 1 }}>
            Couldn't email following customers (no emails on file):{" "}
            {result.invalid_customers.join(", ")}
          </Alert>
          <Alert color="error" sx={{ mb: 1 }}>
            Couldn't email {result.invalid_customers.length} customers (no
            emails on file)
          </Alert>
        </>
      )}
      {result.valid_customers.length > 0 && (
        <Alert color="success" sx={{ mb: 1 }}>
          Emailing: {result.valid_customers.length} customers
        </Alert>
      )}
      <Typography
        fontSize="23px"
        fontWeight={800}
        component="h2"
        data-testid="page-title"
        mb="10px"
      >
        Email Correspondence
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mb: "5px",
        }}
      >
        <TextField
          fullWidth
          sx={{ width: "75%" }}
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
          placeholder="Email Subject..."
        />
        <Button
          variant="contained"
          color="success"
          onClick={handleEmailSend}
          disabled={isSending}
        >
          Send Email
        </Button>
      </Box>
      <Grid container spacing={1}>
        <Grid item xs={9}>
          <Editor
            key={editorKey}
            apiKey="gpr4nec4hr3fdzfph9jv42wv8eh6qdn41p2gneq5h7kq8cpy"
            initialValue=""
            init={{
              height: 500,
              menubar: false,
              plugins: [
                "advlist autolink lists link image charmap print preview anchor",
                "searchreplace visualblocks code fullscreen",
                "insertdatetime media table paste code help wordcount",
              ],
              toolbar:
                "undo redo | formatselect | " +
                "bold italic backcolor | alignleft aligncenter " +
                "alignright alignjustify | bullist numlist outdent indent | " +
                "removeformat | help",
            }}
            onEditorChange={handleEditorChange}
          />
          <Block sx={{ mt: "20px" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "20px",
              }}
            >
              <Typography sx={{ fontSize: "20px", fontWeight: "bolder" }}>
                Attachment Files
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <Button variant="contained" color="warning" component="label">
                  Upload Files
                  <input type="file" hidden onChange={handleFileChange} />
                </Button>
              </Box>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              {data?.email_files.map((file) => {
                return <AttachmentComponent file={file} dispatch={dispatch} />;
              })}
            </Box>
          </Block>
        </Grid>
        <Grid item xs={3}>
          <Block>
            <Typography
              fontSize="18px"
              fontWeight={600}
              component="h2"
              data-testid="page-title"
            >
              Customers List:
              {data?.customers_list.length > 0 && (
                <span
                  style={{
                    fontWeight: 400,
                    fontSize: "10px",
                    marginLeft: "5px",
                  }}
                >
                  ({data.customers_list.length} customer(s) selected)
                </span>
              )}
            </Typography>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "6px",
                mb: "20px",
              }}
            >
              <CustomerSelect
                label="Add a Customer"
                name="customer"
                required
                customersList={data?.customers_list}
                disabled={isChecked}
                value={selectedCustomer}
                onChange={(_e, newValue) => setSelectedCustomer(newValue)}
                extraStyles={{
                  mt: 1,
                }}
              />

              <Button
                sx={{
                  padding: "4px 5px",
                  fontSize: "12px",
                  minWidth: "0",
                  width: "30px",
                  height: "30px !important",
                  mt: "7px",
                }}
                variant="contained"
                disabled={isChecked}
                onClick={() => {
                  dispatch({
                    type: REDUCER_ACTIONS.ADD_CUSTOMER,
                    payload: { newCustomer: selectedCustomer },
                  });
                  setSelectedCustomer({ label: "", value: "" });
                }}
                color="primary"
              >
                <AddIcon />
              </Button>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                mb: "20px",
              }}
            >
              <Typography>Select all Customers:</Typography>
              <Checkbox
                checked={isChecked}
                onChange={handleCheckBoxChange}
                inputProps={{ "aria-label": "controlled" }}
              />
            </Box>
            {!isChecked && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                }}
              >
                {data.customers_list.map((customer) => {
                  return (
                    <CustomerCard
                      customer={customer}
                      setCustomersList={setCustomersList}
                      dispatch={dispatch}
                    />
                  );
                })}
              </Box>
            )}
          </Block>
        </Grid>
      </Grid>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
}
