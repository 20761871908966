import { Stack, Typography } from "@mui/material";

const name = {
  headerName: "name",
  field: "name",
  flex: 2,
  cellRenderer: (params) => {
    const link = ["EMAIL", "FAX", "MAIL"].includes(params.data?.gt)
      ? `/invoices/group-statements/${params.data?.id}/batches`
      : "#";
    return (
      <a href={link}>
        <Typography>{params.data?.name}</Typography>
      </a>
    );
  },
};
const gt = {
  headerName: "Report Type",
  field: "gt",
  flex: 3,
};
const accounts = {
  headerName: "Accounts",
  cellRenderer: (params) => (
    <Stack direction={"row"} spacing={1}>
      {params?.data?.accounts.slice(0, 2)?.map((acc) => (
        <Typography>{acc.name}, </Typography>
      ))}
      {params?.data?.accounts.length > 2 && (
        <Typography>And {params?.data?.accounts.length - 2} others</Typography>
      )}
    </Stack>
  ),
  flex: 10,
};

export const statementGroupCols = [name, gt, accounts];
