import React from "react";
import InputAdornment from "@mui/material/InputAdornment";
import { IconButton, TextField } from "@mui/material";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";

const CustomTextField = ({
  required,
  name,
  label,
  type,
  value,
  onChange,
  ...rest
}) => {
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const getAutoComplete = () => {
    switch (type) {
      case "password":
        return "new-password";
      case "email":
        return "email";
      default:
        return "off";
    }
  };

  return (
    <TextField
      required={required}
      name={name}
      label={label}
      data-testid={`test-${name}-id`}
      type={showPassword ? "text" : type}
      id={name}
      fullWidth
      inputProps={{
        autoComplete: getAutoComplete(),
      }}
      value={value}
      onChange={onChange}
      sx={{ mt: 1 }}
      InputProps={{
        endAdornment:
          type === "password" ? (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
                sx={{
                  marginRight: "0",
                }}
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ) : (
            ""
          ),
      }}
      {...rest}
    />
  );
};

export default CustomTextField;
