import React from "react";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import DataTable from "../DataTable";
import { customerOverridesTable } from "./customerOverridesTable";
import "./style.css";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { useNavigate, useParams } from "react-router-dom";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import OverridesRefactoredTable from "../OverridesRefactoredTable";
import { fetchCustomerOverrides, removeCustomerOverride, updateCustomerOverride } from "../../redux/module/customer";

const defaultColDef = { flex: 1, minWidth: 100 };
const CustomerOverrides = ({ overrides }) => {

  const navigate = useNavigate();
  const { id } = useParams();

  return (
    <Box>
      <Stack direction="row" justifyContent="space-between" alignItems="center" mb={3}>
        <Typography component="p" variant="h6">
          Customer overrides
        </Typography>
        <IconButton onClick={() => navigate(`/customers/${id}`)} width="25px" height="25px" sx={{
          "&:hover": {
            backgroundColor: "transparent" // Set the background color to transparent on hover
          }
        }}>
          <EditOutlinedIcon />
        </IconButton>
      </Stack>
      {overrides.length ?
        <OverridesRefactoredTable
          overrides={overrides}
          fetchOverrides={fetchCustomerOverrides}
          removeOverride={removeCustomerOverride}
          updateOverride={updateCustomerOverride}
          elementKey="customer"
        />
        :
        <Box
          height="200px"
          display="flex"
          gap={2}
          justifyContent="center"
          alignItems="center"
        >
          <SearchOutlinedIcon fontSize="large" />
          <Typography component="p" variant="h6">
            This customer has no overrides
          </Typography>
        </Box>
      }
    </Box>
  );
};

export default CustomerOverrides;