import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { listDrivers } from "../../distflowAPI/driversApi";
import { listDrivers2 } from "../../distflowAPI/driversApi";
import accountsApi from "../../distflowAPI/accountsApi";
import routesApi from "../../distflowAPI/routesApi";
import { enqueueSnackbar } from "notistack";
import { arrayMove } from "@dnd-kit/sortable";
import { signOut } from "./user";
import { fireErrorNotification, fireSuccessNotification } from "../../utils";

function validateRouteData(route) {
  const errors = {};
  if (!route.name) {
    errors.name = "Name is required!";
  }

  if (!route.selectedDriverId) {
    errors.driver = "Driver is required!";
  }

  if (!Object.values(route.selectedDays).some(Boolean)) {
    errors.selectedDays = "At least one day must be selected!";
  }

  if (!route.accounts.length) {
    errors.accounts = "At least one account must be selected!";
  }

  return {
    errors,
    data: {
      driver: Number(route.selectedDriverId),
      is_active: route.isActive,
      ...route.selectedDays,
      ...route,
    },
  };
}

const initialState = {
  name: "",
  note: "",
  isActive: true,
  selectedDays: {
    is_sunday: false,
    is_monday: false,
    is_tuesday: false,
    is_wednesday: false,
    is_thursday: false,
    is_friday: false,
    is_saturday: false,
  },
  vendors: [],
  vendor_rules: [],
  // Temp storage for notes only
  accounts_calc: [],
  accounts: [],
  allAccounts: [],
  selectedDriverId: null,
  allDrivers: [],
  driverSearchTerm: "",
  accountSearchTerm: "",
  loading: false,
  createLoading: false,
  updateLoading: false,
  formErrors: {},
};

export const fetchDrivers = createAsyncThunk("addEditRoute/fetchDrivers", () =>
  listDrivers2()
);

export const fetchRouteAccounts = createAsyncThunk(
  "addEditRoute/fetchRouteAccounts",
  () => accountsApi.list(100)
);

export const fetchRouteToEdit = createAsyncThunk(
  "addEditRoute/fetchRouteToEdit",
  async (id, { dispatch }) => {
    const response = await routesApi.findById(id);
    dispatch(setRouteToEdit(response.data));
    return response.data;
  }
);

export const createRoute = createAsyncThunk(
  "addEditRoute/createRoute",
  async (route, { dispatch, getState, rejectWithValue }) => {
    const { name, note, selectedDriverId, isActive, selectedDays } =
      getState().addEditRoute;

    const { errors, data } = validateRouteData({
      name,
      note,
      selectedDriverId,
      isActive,
      selectedDays,
      ...route,
    });

    if (Object.keys(errors).length) {
      dispatch(setRouteFormErrors(errors));
      return;
    }

    try {
      const response = await routesApi.create(data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateRoute = createAsyncThunk(
  "addEditRoute/updateRoute",
  async (route, { dispatch, getState, rejectWithValue }) => {
    const { name, note, selectedDriverId, isActive, selectedDays } =
      getState().addEditRoute;

    const { errors, data } = validateRouteData({
      name,
      note,
      selectedDriverId,
      isActive,
      selectedDays,
      ...route,
    });

    if (Object.keys(errors).length) {
      dispatch(setRouteFormErrors(errors));
      return;
    }

    try {
      const response = await routesApi.update(route.id, data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const addEditRouteSlice = createSlice({
  name: "addEditRoute",
  initialState,
  reducers: {
    setName(state, action) {
      state.name = action.payload;
      delete state.formErrors.name;
    },
    setNote(state, action) {
      state.note = action.payload;
      delete state.formErrors.note;
    },
    /**
     * NOTE: this function currently is not used
     * TODO: cleanup
     */
    setIsActive(state, action) {
      state.isActive = action.payload;
    },
    setSelectedDays(state, action) {
      for (const dayKey of Object.keys(action.payload)) {
        state.selectedDays[dayKey] = action.payload[dayKey];
      }
      delete state.formErrors.selectedDays;
    },
    setSelectedDriver(state, action) {
      state.selectedDriverId = action.payload.split("-")[1];
      delete state.formErrors.driver;
    },
    removeSelectedDriver(state) {
      state.selectedDriverId = null;
    },
    setDriverSearchTerm(state, action) {
      state.driverSearchTerm = action.payload;
      state.allDrivers = state.allDrivers.map((x) => ({
        ...x,
        show: x.user.first_name
          .toLowerCase()
          .includes(action.payload.toLowerCase()),
      }));
    },
    setAccountSearchTerm(state, action) {
      state.accountSearchTerm = action.payload;
      state.allAccounts = state.allAccounts.map((x) => ({
        ...x,
        show: x.name.toLowerCase().includes(action.payload.toLowerCase()),
      }));
    },
    addNoteToAccount(state, action) {
      const { accountId, note } = action.payload;
      state.allAccounts = state.allAccounts.map((account) => {
        if (account.id === accountId) {
          return { ...account, note };
        }
        return account;
      });
    },
    addAccount(state, action) {
      state.accounts = [...state.accounts, action.payload];
      delete state.formErrors.accounts;
    },
    removeAccount(state, action) {
      state.accounts = state.accounts.filter((item) => item !== action.payload);
    },
    handleAccountsSort(state, action) {
      const { activeId, overId } = action.payload;

      if (activeId === overId) {
        return;
      }
      const oldIndex = state.accounts.indexOf(activeId);
      const newIndex = state.accounts.indexOf(overId);

      state.accounts = arrayMove(state.accounts, oldIndex, newIndex);
    },
    setRouteToEdit(state, action) {
      state.name = action.payload.name;
      state.note = action.payload.note || action.payload.route_calc?.note;
      state.isActive = action.payload.is_active;
      state.selectedDriverId = String(action.payload.driver);
      state.selectedDays = {
        is_sunday: action.payload.is_sunday,
        is_monday: action.payload.is_monday,
        is_tuesday: action.payload.is_tuesday,
        is_wednesday: action.payload.is_wednesday,
        is_thursday: action.payload.is_thursday,
        is_friday: action.payload.is_friday,
        is_saturday: action.payload.is_saturday,
      };

      state.vendor_rules = action.payload.vendor_rules;
      state.accounts_calc = action.payload.accounts_calc;

      state.accounts = (
        action.payload.accounts ||
        action.payload.accounts_calc ||
        []
      ).filter(x => x.schedule_change == null).map((item) => `account-${item.account.id || item.account}`);
    },
    setRouteFormErrors(state, action) {
      state.formErrors = action.payload;
      state.createLoading = false;
      state.updateLoading = false;
    },
    resetState: () => ({ ...initialState }),
  },
  extraReducers(builder) {
    builder
      .addCase(fetchDrivers.fulfilled, (state, action) => {
        state.allDrivers = action.payload.data.results.map((x) => ({
          ...x,
          show: true,
        }));
      })
      .addCase(fetchRouteAccounts.fulfilled, (state, action) => {
        state.allAccounts = action.payload.results.map((x) => ({
          ...x,
          show: true,
        }));
      })
      .addCase(fetchRouteToEdit.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(createRoute.pending, (state) => {
        state.createLoading = true;
      })
      .addCase(createRoute.fulfilled, (state, action) => {
        state.createLoading = false;
        fireSuccessNotification(`Route "${action.payload.name}" created successfully`)
        // enqueueSnackbar(`Route "${action.payload.name}" created successfully`, {
        //   variant: "success",
        // });
      })
      .addCase(createRoute.rejected, (state, { payload }) => {
        state.createLoading = false;
        enqueueSnackbar(`Error creating Route - ${payload['error']}`, { variant: "error" });
      })
      .addCase(fetchRouteToEdit.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchRouteToEdit.rejected, (state, err) => {
        state.loading = false;
        // add error handling here
        enqueueSnackbar("Error getting Route", { variant: "error" });
      })
      .addCase(updateRoute.pending, (state) => {
        state.updateLoading = true;
      })
      .addCase(updateRoute.fulfilled, (state, action) => {
        state.updateLoading = false;
        fireSuccessNotification(`Route "${action.payload.name}" updated successfully`);
        // enqueueSnackbar(`Route "${action.payload.name}" updated successfully`, {
        //   variant: "success",
        // });
      })
      .addCase(updateRoute.rejected, (state, { payload }) => {
        state.updateLoading = false;
        fireErrorNotification(`Error updating route - ${payload["error"]}`)
        //enqueueSnackbar(message, { variant: "error" });
      })
      .addCase(signOut, () => initialState);
  },
});

export const {
  setName,
  setNote,
  setIsActive,
  setSelectedDays,
  setSelectedDriver,
  removeSelectedDriver,
  setDriverSearchTerm,
  setAccountSearchTerm,
  addNoteToAccount,
  addAccount,
  removeAccount,
  handleAccountsSort,
  setRouteToEdit,
  setRouteFormErrors,
  resetState,
} = addEditRouteSlice.actions;

export default addEditRouteSlice.reducer;
