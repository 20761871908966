import React from "react";
import { Grid, Typography } from "@mui/material";
import CustomTextField from "../../components/CustomTextField";

const CustomerForm = ({
  customerInfo,
  setCustomerInfo,
  isPasswordRequired,
}) => {
  const formFields = [
    {
      id: 3,
      name: "address",
      label: "Address",
      type: "text",
      value: customerInfo.address,
      required: true,
      md: 6,
    },
    {
      id: 4,
      name: "city",
      label: "City",
      type: "text",
      value: customerInfo.city,
      required: true,
      md: 2,
    },
    {
      id: 5,
      name: "state",
      label: "State",
      type: "text",
      value: customerInfo.state,
      required: true,
      md: 2,
    },
    {
      id: 6,
      name: "zipcode",
      label: "Zipcode",
      type: "text",
      value: customerInfo.zipcode,
      required: true,
      md: 2,
    },
  ];

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography mt={2} mb={1}>
              Owner information
            </Typography>
          </Grid>

          <Grid item xs={2}>
            <CustomTextField
              required
              name="first_name"
              label="First name"
              type="text"
              value={customerInfo.owner.first_name}
              onChange={(e) =>
                setCustomerInfo({
                  ...customerInfo,
                  owner: {
                    ...customerInfo.owner,
                    first_name: e.target.value,
                  },
                })
              }
            />
          </Grid>
          <Grid item xs={2}>
            <CustomTextField
              required
              name="last_name"
              label="Last name"
              type="text"
              value={customerInfo.owner.last_name}
              onChange={(e) =>
                setCustomerInfo({
                  ...customerInfo,
                  owner: {
                    ...customerInfo.owner,
                    last_name: e.target.value,
                  },
                })
              }
            />
          </Grid>
          <Grid item xs={4}>
            <CustomTextField
              required
              name="email"
              label="Email"
              type="email"
              value={customerInfo.owner.email}
              onChange={(e) =>
                setCustomerInfo({
                  ...customerInfo,
                  owner: {
                    ...customerInfo.owner,
                    email: e.target.value,
                  },
                })
              }
            />
          </Grid>
          <Grid item xs={4}>
            <CustomTextField
              required={isPasswordRequired}
              name="password"
              label="Password"
              type="password"
              value={customerInfo.owner.password}
              onChange={(e) =>
                setCustomerInfo({
                  ...customerInfo,
                  owner: {
                    ...customerInfo.owner,
                    password: e.target.value,
                  },
                })
              }
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item md={12}>
            <Typography mt={2} mb={1}>
              General information
            </Typography>
          </Grid>

          <Grid item md={6}>
            <CustomTextField
              required
              name="name"
              label="Customer name"
              type="text"
              value={customerInfo.name}
              onChange={(e) =>
                setCustomerInfo({ ...customerInfo, name: e.target.value })
              }
            />
          </Grid>
          <Grid item md={3}>
            <CustomTextField
              name="internal_id"
              label="System id"
              type="text"
              value={customerInfo.internal_id}
              onChange={(e) =>
                setCustomerInfo({
                  ...customerInfo,
                  internal_id: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item md={3}>
            <CustomTextField
              name="invoices_net"
              label="Invoices net"
              type="number"
              value={customerInfo.invoice_net}
              onChange={(e) =>
                setCustomerInfo({
                  ...customerInfo,
                  invoice_net: e.target.value,
                })
              }
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item md={12}>
            <Typography mt={2} mb={1}>
              Address
            </Typography>
          </Grid>

          {formFields.map((el) => (
            <Grid item md={el.md} key={el.id}>
              <CustomTextField
                required={el.required}
                name={el.name}
                label={el.label}
                type={el.type}
                value={el.value}
                onChange={(e) =>
                  setCustomerInfo({
                    ...customerInfo,
                    [el.name]: e.target.value,
                  })
                }
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CustomerForm;
