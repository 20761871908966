export function renameKeys(obj, newKeys) {
  const keyValues = Object.keys(obj).map((key) => {
    const newKey = newKeys[key] || key;
    return { [newKey]: obj[key] };
  });
  return Object.assign({}, ...keyValues);
}

export function to_rename(agg) {
  const result = {};
  for (const key in agg) {
    result[`${key}_${agg[key]}`] = key;
  }
  return result;
}

export function get_cols_agg(cols) {
  const agg = {};
  const agg_client = [];
  let _cols = cols.map((item) => ("children" in item ? item.children : [item]));
  cols = _cols.flat(1);
  for (let i = 0; i < cols.length; i++) {
    if (!("field" in cols[i])) {
      continue;
    }
    if (cols[i].field.includes("_1")) {
      continue;
    }
    if (typeof cols[i].aggFunc === "string") {
      agg[cols[i].field] = cols[i].aggFunc;
      if (cols[i].aggFunc === "first") {
        agg_client.push(cols[i].field);
      }
      if (cols[i].aggFunc === "avg") {
        agg[cols[i].field] = "mean";
      }
    }
  }
  return { agg, agg_client };
}

class CustomLoadingOverlay {
  init(params) {
    this.eGui = document.createElement("div");
    this.eGui.innerHTML = `<div class="ag-overlay-loading-center" style="background-color: lightsteelblue;"><i class="bx bxs-hourglass"> ' +
            params.loadingMessage</i></div>`;
  }

  getGui() {
    return this.eGui;
  }

  refresh(params) {
    return false;
  }
}

export const gridOptions = {
  pagination: true,
  paginationPageSize: 20,
  defaultColDef: {
    sortable: true,
  },
};

export function saveToLocal(key, value) {
  try {
    const now = new Date();
    const item = {
      value: value,
      expiry: now.getTime() + 21600000,
    };
    localStorage.setItem(key, JSON.stringify(item));
  } catch (e) {}
}

export function loadFromLocal(key) {
  const itemStr = localStorage.getItem(key);
  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);
  const now = new Date();
  if (now.getTime() > item.expiry) {
    localStorage.removeItem(key);
    return null;
  }
  return item.value;
}

export function saveToLocalSession(key, value) {
  try {
    const now = new Date();
    const item = {
      value: value,
      expiry: now.getTime() + 21600000,
    };
    sessionStorage.setItem(key, JSON.stringify(item));
  } catch (e) {}
}

export function loadFromLocalSession(key) {
  const itemStr = sessionStorage.getItem(key);
  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);
  const now = new Date();
  if (now.getTime() > item.expiry) {
    sessionStorage.removeItem(key);
    return null;
  }
  return item.value;
}

export function orderingToApi(orderBy) {
  const params = orderBy.map((item) =>
    item.sort == "asc"
      ? "" + item.field.replace(".", "__")
      : "-" + item.field.replace(".", "__"),
  );
  const paramsString = params.join(",");
  return paramsString;
}
export function filtersToApi(filterBy, search_params) {
  const params = filterBy.map((item) => {
    //  ;
    return "" + item.column.replace(".", "__") + "=" + item.value;
  });
  if (search_params) {
    for (let entry of search_params.entries()) {
      params.push(`${entry[0]}=${entry[1]}`);
    }
  }
  // ;
  const paramsString = params.join("&");
  return paramsString;
}

export function formatAgGridFilters(filters) {
  return Object.keys(filters).map((col) => {
    switch (filters[col].filterType) {
      case "set": {
        return {
          column: col,
          filterType: "IS_IN",
          values: filters[col].values,
        };
      }
      case "inRange": {
        return {
          column: col,
          filterType: filters[col].type,
          start: filters[col].filter,
          end: filters[col].filterTo,
        };
      }
      default: {
        return {
          column: col,
          filterType: filters[col].type,
          value: filters[col].filter,
        };
      }
    }
  });
}

export function formatAgGridSorting(columns) {
  return columns
    .filter((column) => column.sort != null)
    .map((column) => {
      return { field: column.colId, sort: column.sort };
    });
}
