import { useEffect, useRef, useState } from "react";
import { Alert, Box, Button, Menu, Stack, Typography } from "@mui/material";
import "swiper/css";
import "swiper/css/navigation";
import CalendarSwitcher from "../../components/CalendarSwitcher";
import OrdersTable from "./OrdersTable";
import { NavLink, useSearchParams } from "react-router-dom";
import {
  listAutomaticOrders,
  generatePriceMismatches,
} from "../../distflowAPI/ordersApi";
import OrderItemsModal from "../../components/OrderComponents/OrderItemsModal";
import { useModal } from "mui-modal-provider";
import dayjs from "dayjs";
import CalendarSwiper from "../../components/CalendarSwiper";
import { downloadFile } from "../../distflowAPI/downloadFile";
import OrdersFilter from "./OrdersFilter";
import { DATE_FORMAT } from "../../utils/constants";
import ImportSteadies from "./components/importSteadies";
import ImportExportAccountOrders from "./components/importExportAccountOrders";
import GenerateMismatches from "./components/generateMismatches";
import GenerateSteadiesEmail from "./components/generateSteadiesEmail";
import EmailVendorModal from "./components/EmailVendorModal";

function getTomorrow() {
  const today = new Date();
  let tomorrow = new Date();
  tomorrow.setDate(today.getDate() + 3);
  return tomorrow;
}

const Orders = () => {
  function getDate() {
    const toAdd = dayjs().add(1, "day").day() === 6 ? 2 : 1;
    return (
      searchParams.get("date") || dayjs().add(toAdd, "day").format(DATE_FORMAT)
    );
  }

  const [loaded, setLoaded] = useState(false);
  const [success, setSuccess] = useState("");
  const { showModal } = useModal();
  const [searchParams, setSearchParams] = useSearchParams();
  const [date, setDate] = useState(getDate());
  const [dateType, setDateType] = useState("day");
  const [orders, setOrders] = useState([]);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [openImportExport, setOpenImportExport] = useState(false);
  const [erevEvent, setSerevEvent] = useState({});
  const [isDownloadingVendorExport, setIsDownloadingVendorExport] =
    useState(false);
  const [openEmailVendor, setOpenEmailVendor] = useState(false);
  const changeDateType = (event, newAlignment) => {
    if (newAlignment !== null) {
      setDateType(newAlignment);
    }
  };

  async function refreshTable(isLazy = false) {
    console.log("REFRESH TABLE CALLED");
    setIsDataLoading(true);
    setIsError(false);
    if (!isLazy) {
      setOrders([]);
    } else {
    }
    setLoaded(false);
    listAutomaticOrders(date, "", searchParams).then((res) => {
      const results = res.results.map((item) => {
        return {
          ...item,
          date,
        };
      });
      setOrders(results);
      setIsDataLoading(false);
      setLoaded(true);
      setSerevEvent(res.hebrew_event);
    });
  }

  useEffect(() => {
    setSearchParams((params) => {
      // ;
      const newParams = new URLSearchParams(params);
      newParams.set("date", date);
      return newParams;
    });
  }, [date]);

  useEffect(() => {
    if (searchParams.get("date") && searchParams.get("myCustomers")) {
      refreshTable();
    }
  }, [searchParams]);

  const getCreateOrderDate = () => {
    const calendarDate = dayjs.utc(date);
    const today = dayjs.utc();
    if (calendarDate < today) return today;
    return calendarDate;
  };

  function getHolidaysAlert() {
    if (erevEvent.todays_holiday && erevEvent.todays_holiday?.name) {
      return {
        closed: true,
        message: `Today is ${erevEvent.todays_holiday?.name}`,
      };
    }
    if (erevEvent.tomorrows_holiday) {
      if (erevEvent.is_erev) {
        return {
          message: `Today is Erev of ${erevEvent.tomorrows_holiday?.name} orders ARE subject to erev rules`,
        };
      }
      return {
        message: `Tomorrow is ${erevEvent.tomorrows_holiday?.name} orders ARE NOT subject to erev rules`,
      };
    }
    return {};
  }

  const [openImportSteadies, setOpenImportSteadies] = useState(false);
  const [openMismatches, setOpenMismatches] = useState(false);
  const [openEmailSteadies, setOpenEmailSteadies] = useState(false);
  return (
    <Box maxWidth="1525px" margin=" 30px auto 0">
      <Stack
        direction={{ md: "row" }}
        alignItems="start"
        justifyContent="space-between"
        spacing="20px"
        gap="10px"
        mb="36px"
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          flexWrap="wrap"
          sx={{
            gap: "11px",
            "@media(max-width: 480px)": {
              width: "100%",
              button: { width: "100%" },
            },
          }}
        >
          <Button
            className="btn-height"
            variant="outlined"
            onClick={() => setOpenEmailSteadies(true)}
          >
            Email Steadies
          </Button>
          <Button
            className="btn-height"
            variant="outlined"
            onClick={() => setOpenMismatches(true)}
          >
            Email mismatch report
          </Button>
          <Button
            className="btn-height"
            variant="outlined"
            onClick={() => setOpenImportSteadies(true)}
          >
            Import Steadies
          </Button>

          <ImportExportAccountOrders
            open={openImportExport}
            setOpen={setOpenImportExport}
            refreshTable={refreshTable}
          />
          <ImportSteadies
            openImportSteadies={openImportSteadies}
            setOpenImportSteadies={setOpenImportSteadies}
            refreshTable={refreshTable}
          />
          <GenerateMismatches
            open={openMismatches}
            setOpen={setOpenMismatches}
          />
          <GenerateSteadiesEmail
            open={openEmailSteadies}
            setOpen={setOpenEmailSteadies}
          />
          <EmailVendorModal
            open={openEmailVendor}
            setOpen={setOpenEmailVendor}
            date={date}
          />
          <Button
            className="btn-height"
            variant="outlined"
            onClick={() => setOpenImportExport(true)}
          >
            Import/Export Account Orders
          </Button>
          <Button
            className="btn-height"
            variant="outlined"
            onClick={() => setOpenEmailVendor(true)}
          >
            Vendor Report
          </Button>
          <Button
            className="btn-height sx-full-width"
            to={`/orders/manage-auto-orders?date=${date}`}
            color="success"
            variant="contained"
            component={NavLink}
          >
            Manage recurrent orders
          </Button>
          <Button
            className="btn-height"
            color="primary"
            variant="contained"
            onClick={() =>
              showModal(OrderItemsModal, {
                date: getCreateOrderDate(),
                isCreate: true,
                refreshTable: refreshTable,
              })
            }
          >
            Create Order
          </Button>
          {/*
          <Button
            variant="contained"
            color="info"
            disabled={generatingSteadis}
            onClick={() => {
              setGeneratingSteadies(true);
              createTomorrowsOrders(date).then((res) => {
                setGeneratingSteadies(false);
                setSuccess(
                  `${res.data.total_items} order items generated, ${res.data.requiring_approval} items require approval`,
                );

                setTimeout(refreshTable(), 500);
              });
            }}
          >
            Process Steadies
          </Button>

          <Button
            color="error"
            variant="contained"
            disabled={markingDelivered}
            onClick={() => {
              setMarkingDelivered(true);
              markAllDelivered(date).then((res) => {
                setMarkingDelivered(false);
                setSuccess(`${res.data.delivered} orders marked as delivered`);
                setTimeout(refreshTable(), 500);
              });
            }}
          >
            mark all DELIVERED
          </Button>
          */}
          <CalendarSwitcher dateType={dateType} onChange={changeDateType} />
        </Stack>
      </Stack>

      <CalendarSwiper
        date={date}
        setDate={setDate}
        dateType={dateType}
        searchParams={searchParams}
      />
      {success && <Alert severity="success">{success}</Alert>}
      <OrdersFilter />
      {getHolidaysAlert().message && (
        <Alert color={getHolidaysAlert().closed ? "error" : "warning"}>
          {getHolidaysAlert().message}
        </Alert>
      )}
      {loaded && orders.length > 0 && (
        <OrdersTable
          items={orders}
          day={dayjs(date).format("ddd")}
          date={date}
          refreshTable={() => {
            console.log("orders table asked for refresh");
            refreshTable();
          }}
          isLoading={isDataLoading}
        />
      )}
      {loaded && orders.length == 0 && (
        <Alert severity="warning">No orders satisfy the search criterea</Alert>
      )}
    </Box>
  );
};

export default Orders;
