import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import TerritorySelect from "../../components/AsyncSelects/TerritorySelect";
import CustomerSelect from "../../components/AsyncSelects/CustomerSelect";
import { createAccount } from "../../redux/module/account";
import useAppDispatch from "../../hooks/useAppDispatch";
import ErrorPopupBoundary from "../../components/ErrorPopupBoundary";
import { ErrorBoundary } from "react-error-boundary";

const initialValue = {
  name: "",
  territory: null,
  customer: null,
  address: "",
  invoice_net: "",
  primary_phone: "",
};

const AddAccountForm = ({ open, handleClose }) => {
  const dispatch = useAppDispatch();
  const [accountInfo, setAccountInfo] = useState(initialValue);
  const [isSubmitting, setIsSubmitting] = useState(false);

  async function handleSubmit(event) {
    event.preventDefault();
    setIsSubmitting(true);
    const data = await dispatch(
      createAccount({
        name: accountInfo.name,
        territory: accountInfo.territory.value,
        customer: accountInfo.customer.value,
        address: accountInfo.address,
        invoice_net: accountInfo.invoice_net,
        primary_phone: accountInfo.primary_phone,
      }),
    );
    if (data.payload.id) {
      handleClose();
    }
    setIsSubmitting(false);
  }

  useEffect(() => {
    setAccountInfo(initialValue);
  }, [open]);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <ErrorBoundary fallback={<ErrorPopupBoundary onClose={handleClose} />}>
        <DialogTitle sx={{ pb: 1 }}>Create a new Account</DialogTitle>
        <form id="AccountCreation" onSubmit={handleSubmit}>
          <DialogContent sx={{ pt: 1, pb: 1 }}>
            <TextField
              required
              name="name"
              data-testid={`test-name-id`}
              label="Account name"
              type="text"
              id="name"
              fullWidth
              autoComplete="off"
              value={accountInfo.name}
              onChange={(e) =>
                setAccountInfo({ ...accountInfo, name: e.target.value })
              }
              sx={{ mt: "9px" }}
            />
            <CustomerSelect
              label="Customer"
              name="customer"
              required
              value={accountInfo.customer}
              onChange={(_e, newValue) =>
                setAccountInfo({ ...accountInfo, customer: newValue })
              }
              extraStyles={{
                mt: 1,
              }}
            />
            <TerritorySelect
              label="Territories"
              name="territory"
              required
              value={accountInfo.territory}
              onChange={(_e, newValue) =>
                setAccountInfo({ ...accountInfo, territory: newValue })
              }
              extraStyles={{
                pt: 1,
              }}
            />
            <TextField
              name="address"
              label="Account address"
              required
              data-testid={`test-address-id`}
              type="text"
              id="address"
              fullWidth
              autoComplete="off"
              value={accountInfo.address}
              onChange={(e) =>
                setAccountInfo({ ...accountInfo, address: e.target.value })
              }
              sx={{ mt: 1 }}
            />
            <TextField
              name="invoice_net "
              label="Invoice net"
              data-testid={`test-invoice-net-id`}
              type="number"
              id="invoice_net"
              fullWidth
              autoComplete="off"
              value={accountInfo.invoice_net}
              onChange={(e) =>
                setAccountInfo({ ...accountInfo, invoice_net: e.target.value })
              }
              sx={{ mt: 1 }}
            />
            <TextField
              name="primary_phone"
              label="Primary Phone Number"
              type="text"
              id="primary_phone"
              fullWidth
              autoComplete="off"
              value={accountInfo.primary_phone}
              onChange={(e) =>
                setAccountInfo({
                  ...accountInfo,
                  primary_phone: e.target.value,
                })
              }
              sx={{ mt: 1 }}
              required
            />
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={isSubmitting}
            >
              Create Account
            </Button>
            <Button onClick={handleClose} variant="contained" color="error">
              Cancel
            </Button>
          </DialogActions>
        </form>
      </ErrorBoundary>
    </Dialog>
  );
};

export default AddAccountForm;
