import { useState, useRef, useEffect } from "react";
import {
  Button,
  Typography,
  Stack,
  TextField,
  Box,
  RadioGroup,
  FormControlLabel,
  Radio,
  Alert,
} from "@mui/material";
import { NavLink } from "react-router-dom";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import Block from "../../components/Block";
import SearchIcon from "@mui/icons-material/Search";
import DataTable from "../../components/DataTable";
import { Link } from "react-router-dom";
import { listSettings } from "../../distflowAPI/settingsApi";

const defaultColDef = {
  flex: 1,
  filter: false,
  floatingFilter: false,
};

const Settings = () => {
  const columnDefs = [
    //product_image,
    {
      headerName: "Code",
      field: "code",
      autoHeight: true,
      cellRendererSelector: (params) => {
        return {
          component: SettingCodeRenderer,
        };
      },
      filter: false,
      flex: 2,
      width: 450,
    },
    {
      headerName: "Notes",
      field: "notes",
      autoHeight: true,
      cellRendererSelector: (params) => {
        return {
          component: SettingsNotesRenderer,
        };
      },
      filter: false,
      flex: 7,
    },
  ];
  const [rawTableData, setrawTableData] = useState([]);
  const gridRef = useRef();

  function updateTable() {
    listSettings().then((res) => {
      setrawTableData(res.data.results);
    });
  }
  useEffect(() => {
    updateTable();
  }, []);
  return (
    <>
      <Typography
        fontSize="23px"
        fontWeight={800}
        component="h2"
        data-testid="page-title"
      >
        Settings
      </Typography>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        alignItems={{ xs: "space-between", sm: "center" }}
        justifyContent="space-between"
        spacing={1.5}
        my="25px"
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          spacing="15px"
        >
          <Button
            className="btn-height"
            to="/settings/create"
            component={NavLink}
            variant="contained"
          >
            Add New
          </Button>
          <Button className="btn-height" variant="outlined">
            Delete Selected
          </Button>
        </Stack>
      </Stack>
      <Alert severity="error">
        DANGER ZONE: Do not modify unless you know exactly what you're doing
      </Alert>
      <Box className="table-wrapper">
        <DataTable
          rowData={rawTableData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          rowHeight={53}
          headerHeight={60}
          ref={gridRef}
        />
      </Box>
    </>
  );
};

export default Settings;

function SettingCodeRenderer(params) {
  return (
    <span className="my-renderer">
      <Link
        to={`/settings/${params.data.id}`}
        style={{ textDecoration: "none" }}
      >
        {params.data.code}
      </Link>
    </span>
  );
}

function SettingsNotesRenderer(params) {
  return <span>{params.data.notes}</span>;
}
