import React, { useEffect, useState } from "react";
import { Box, Button, IconButton, Stack, Typography } from "@mui/material";
import dayjs from "dayjs";
import { DATE_FORMAT_FRONT } from "../../../utils/constants";
import useAppSelector from "../../../hooks/useAppSelector";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import CheckIcon from "@mui/icons-material/Check";
import DatePicker from "../../DatePicker2";
import { Cancel } from "@mui/icons-material";
import RoomIcon from "@mui/icons-material/Room";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

const OrderTitleModalCopy = ({
  state,
  orderId,
  invoiceId,
  date,
  setDate,
  shippingAddress,
  orderPoId,
}) => {
  const { account } = useAppSelector((state) => state.account);
  const [selectedDate, setSelectedDate] = useState();
  const [newAddress, setNewAddress] = useState("");
  const [datePickerOpen, setDatePickerOpen] = useState(true);
  const handleChangeDate = () => {
    setDate(selectedDate);
    setDatePickerOpen(false);
  };

  useEffect(() => {
    setNewAddress(shippingAddress || account?.address || "");
  }, [shippingAddress, account]);

  const textColor = !state || state === "RECEIVED" ? "orange" : "red";

  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <Stack direction={"row"} alignItems={"start"} spacing={3}>
        <Typography color="#000">
          <b>
            {invoiceId
              ? `INV#${invoiceId}`
              : orderPoId
                ? `PO# ${orderPoId}`
                : orderId
                  ? `Order #${orderId}`
                  : ""}
          </b>
        </Typography>
        {/* STATUS */}
        <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
          <div
            style={{
              width: "10px",
              height: "10px",
              background: textColor,
              borderRadius: "100%",
            }}
          />
          <Typography
            sx={{
              color: textColor,
              fontSize: "14px",
              fontWeight: "500",
            }}
          >
            {state ? (state === "RECEIVED" ? "Pending" : state) : "Pending"}
          </Typography>
        </Box>
      </Stack>
      {/* ACCOUNT */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "3rem",
          position: "relative",
          width: "100%",
        }}
      >
        {!!account.id && (
          <Stack direction="row" alignItems="center" gap={1} fullWidth>
            <DisplayAccount account={account} />
          </Stack>
        )}

        <Stack direction="row" gap={2} alingItems="center">
          <Stack sx={{ overflow: "hidden" }}>
            <Stack
              sx={{ overflow: "hidden" }}
              direction="row"
              alignItems="center"
              gap={1}
            >
              <RoomIcon fontSize="medium" />
              <Typography fontSize="16px">{newAddress}</Typography>
            </Stack>
          </Stack>
        </Stack>

        <Stack direction="row" alignItems="center" gap={2}>
          {datePickerOpen ? (
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <DatePicker
                controlledValue={selectedDate}
                setControlledValue={setSelectedDate}
                maxWidth="220px"
                size="small"
                defaultValue={dayjs(date)}
                open={true}
                minDate={dayjs()}
              />
              <Button
                sx={{
                  padding: "3px 4px",
                  fontSize: "13px",
                  minWidth: "0",
                  width: "36px",
                  height: "36px !important",
                }}
                variant="contained"
                color="success"
                size="small"
                onClick={() => {
                  handleChangeDate();
                }}
              >
                <CheckIcon fontSize="small" />
              </Button>
              <Button
                sx={{
                  padding: "3px 4px",
                  fontSize: "13px",
                  minWidth: "0",
                  width: "36px",
                  height: "36px !important",
                }}
                variant="contained"
                color="error"
                size="small"
                onClick={() => setDatePickerOpen(false)}
              >
                <Cancel fontSize="small" />
              </Button>
            </div>
          ) : (
            <Typography
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
              }}
            >
              <CalendarMonthIcon fontSize="small" />
              {dayjs(date).format(DATE_FORMAT_FRONT) || "No date"}
            </Typography>
          )}

          {!datePickerOpen && (orderPoId || orderId) && (
            <IconButton>
              <EditOutlinedIcon
                onClick={() => {
                  setDatePickerOpen(!datePickerOpen);
                }}
                fontSize="small"
              />
            </IconButton>
          )}
        </Stack>
      </Box>
    </Box>
  );
};

export default OrderTitleModalCopy;

function DisplayAccount({ account }) {
  return (
    <Stack spacing={1} maxWidth={"100%"} minWidth={"100%"}>
      <Stack direction={"row"} alignItems={"center"} spacing={1}>
        <Stack direction={"row"} alignItems={"center"} spacing={1}>
          <Typography fontSize="18px" fontWeight="500">
            {account.name}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}
