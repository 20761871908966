import { useNavigate, useRouteError } from "react-router-dom";
import { fireErrorNotification } from "../utils";
import { useEffect } from "react";

function ErrorBoundary() {
  const navigate = useNavigate();

  fireErrorNotification(
    `Something went wrong! We will solve this problem as soon as possible.`
  );

  /*
  useEffect(() => {
    navigate("/products");
  }, []);*/

  return <></>;
}

export default ErrorBoundary;
