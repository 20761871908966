import { useState, useEffect } from "react";
import {
  Button,
  Stack,
  TextField,
  FormControl,
  FormLabel,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";

const AccountNoteDialog = ({ open, name, close, existingNote }) => {
  const [note, setNote] = useState(existingNote);

  useEffect(() => {
    setNote(existingNote);
  }, [existingNote]);

  const closeWrapper = () => {
    close(true, note);
    setNote(null);
  };

  return (
    <Dialog
      open={open}
      disableEscapeKeyDown={false}
      closeAfterTransition={true}
      onClose={close}
      maxWidth="xs"
      sx={{
        "& .MuiPaper-root": { width: "100%", maxWidth: "500px" },
        zIndex: 1500,
      }}
    >
      <DialogTitle>Add note for {name} account</DialogTitle>
      <DialogContent>
        <Stack direction="column" spacing="15px">
          <FormControl fullWidth sx={{ marginBottom: 3 }}>
            <FormLabel>Note</FormLabel>
            <TextField
              fullWidth
              data-testid={`test-note-id`}
              multiline
              rows={4}
              name="note"
              value={note}
              onChange={(e) => setNote(e.target.value)}
            />
          </FormControl>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            flexWrap="wrap"
            sx={{
              gap: "11px",
              "@media(max-width: 480px)": {
                width: "100%",
                button: { width: "100%" },
              },
            }}
          >
            <Button variant="outlined" onClick={() => close()}>
              Discard
            </Button>
            <Button color="primary" variant="contained" onClick={closeWrapper}>
              Save
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default AccountNoteDialog;
