import { Box, Typography, Button } from "@mui/material";
import DataTable from "../../components/DataTable";
import { useEffect, useState } from "react";
import { columns } from "./creditsColumns";
import CreateCredit from "./CreateCredit";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import { listCredits } from "../../distflowAPI/creditsApi";
import CreditsFilters from "./CreditsFilters";
export default function Credits() {
  const [openCreate, setOpenCreate] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    setLoading(true);
    listCredits().then((res) => {
      setData(res.data.results);
      setLoading(false);
    });
  }, [page, pageSize]);
  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography
          fontSize="23px"
          fontWeight={800}
          component="h2"
          data-testid="page-title"
          marginBottom="20px"
        >
          Credits
        </Typography>
        <Box sx={{ display: "flex", gap: "10px" }}>
          <Button
            sx={{ padding: "21px 18px" }}
            variant="outlined"
            endIcon={showFilters ? <FilterAltOffIcon /> : <FilterAltIcon />}
            onClick={() => setShowFilters(!showFilters)}
          >
            Filters
          </Button>
          <Button
            color="info"
            variant="contained"
            onClick={() => {
              setOpenCreate(true);
            }}
          >
            Create a Credit
          </Button>
        </Box>
      </Box>
      <Box>
        <CreditsFilters showFilters={showFilters} />
        <DataTable columnDefs={columns} />
      </Box>
      <CreateCredit open={openCreate} setOpen={setOpenCreate} />
    </Box>
  );
}
