import { Grid, IconButton, Button } from "@mui/material";
import Block from "../../components/Block";
import DataTable from "../../components/DataTable";
import { useState, useEffect } from "react";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import ConfirmDialog from "../../components/ConfirmDialog";
import {
  getCategoryAccountRestrictions,
  deleteCategoryAccountRestrictions,
} from "../../distflowAPI/accountsApi";
import { useParams } from "react-router-dom";

import CreateCategoryRestriction from "./CreateCategoryRestriction";
export default function RestrictCategoryAccounts() {
  const { id } = useParams();
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [rowData, setRowData] = useState();
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [customerSelectOpen, setCustomerSelectOpen] = useState(false);
  function fetchRestrictions() {
    getCategoryAccountRestrictions(id, pageSize, page).then((res) => {
      setRowData(res.data.results);
      setCount(res.data.count);
    });
  }
  useEffect(() => {
    fetchRestrictions();
  }, []);

  function buttonsRenderer(params) {
    const rowData = params.data;
    const handleDeleteClick = () => {
      setConfirmModalOpen(true);
    };

    return (
      <div>
        <ConfirmDialog
          title="Restrictions"
          children="Do you want to delete this Restriction ?"
          open={confirmModalOpen}
          setOpen={setConfirmModalOpen}
          onConfirm={() => {
            deleteCategoryAccountRestrictions(rowData.id).then((res) => {
              fetchRestrictions();
            });
          }}
        />

        <IconButton
          onClick={() => {
            handleDeleteClick();
          }}
        >
          <DeleteForeverOutlinedIcon />
        </IconButton>
      </div>
    );
  }
  const columns = [
    {
      headerName: "Categories",
      field: "category.name",
      minWidth: 400,
      sortable: true,
    },
    {
      headerName: "Notes",
      field: "note",
      sortable: true,
    },
    {
      headerName: "Policy",
      field: "policy",
      sortable: true,
    },
    { headerName: "Actions", cellRenderer: buttonsRenderer, maxWidth: 100 },
  ];
  const defaultColDef = {
    flex: 1,
    filter: false,
    floatingFilter: false,
  };
  return (
    <Grid
      container
      columnSpacing={{ xs: 1, sm: 3, xl: "50px" }}
      margin="20px 0 0"
    >
      <Grid item md={9} lg={9} sx={{ maxWidth: "908px", width: "100%" }}>
        <Block>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <h3 style={{ fontWeight: 600 }}>Category Restrictions</h3>
            <Button
              type="submit"
              variant="contained"
              sx={{ pt: 1, pb: 1, mb: 1 }}
              onClick={() => {
                setCustomerSelectOpen(true);
              }}
            >
              Create a Restriction
            </Button>
            <CreateCategoryRestriction
              customerSelectOpen={customerSelectOpen}
              setCustomerSelectOpen={setCustomerSelectOpen}
              fetchRestrictions={fetchRestrictions}
            />
          </div>
          <DataTable
            rowData={rowData}
            columnDefs={columns}
            defaultColDef={defaultColDef}
            loading={false}
          />
        </Block>
      </Grid>
    </Grid>
  );
}
