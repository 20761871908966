import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import {
  deleteTerritory,
  deleteTerritoryOverride,
  getTerritory,
  getTerritoryOverrides,
  listTerritories,
  patchTerritoryOverride,
  postTerritory,
  postTerritoryOverride,
  updateTerritoryApi
} from "../../distflowAPI/territoriesApi";
import { signOut } from "./user";
import { fireErrorNotification, fireSuccessNotification } from "../../utils";

/**
 * @typedef {Object} Territory
 * @property {number} Territory.id
 * @property {string} Territory.name
 * @property {number} Territory.parent
 * @property {[]} Territory.overrides
 * @property {string} Territory.tree_name combination of parent names and name (parent name > name)
 * @property {number} Territory.accounts_count
 */

/**
 * @type {{
 *  territories: Array<Territory>;
 *  territory: Territory;
 *  territoriesLoading: boolean;
 *  pageSize: number;
 *  page: number;
 *  orderBy: [];
 *  filterBy: {};
 *  totalCount: number;
 *  createLoading: boolean;
 *  updateLoading: boolean;
 *  formModalOpen: boolean;
 *  formInitialData: Partial<Territory>|null;
 * }}
 */
const initialState = {
  territories: [],
  territoriesLoading: false,
  pageSize: 50,
  page: 1,
  orderBy: [],
  filterBy: [],
  totalCount: 0,
  territory: {},
  territoryOverrides: [],
  territoryOverridesLoading: false,
  createLoading: false,
  updateLoading: false,
  formModalOpen: false,
  formInitialData: null
};

export const fetchTerritories = createAsyncThunk(
  "territory/fetchTerritories",
  async (data) => {
    const { page, pageSize, filterBy, orderBy } = data;
    return await listTerritories(pageSize, page, orderBy, filterBy);
  }
);

export const fetchTerritory = createAsyncThunk(
  "territory/fetchTerritory",
  async (territoryId) => {
    const res = await getTerritory(territoryId);
    return res.data;
  }
);

export const fetchTerritoryOverrides = createAsyncThunk(
  "territory/fetchTerritoryOverrides",
  async (territoryId, thunkAPI) => {
    const res = await getTerritoryOverrides(territoryId);
    return res.data.results;
  }
);

export const createTerritory = createAsyncThunk(
  "territory/createTerritory",
  async (territory, { rejectWithValue }) => {
    try {
      const res = await postTerritory(territory);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateTerritory = createAsyncThunk(
  "territory/updateTerritory",
  async ({ id, payload }, { rejectWithValue }) => {
    try {
      const res = await updateTerritoryApi(id, payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const removeTerritory = createAsyncThunk("territory/removeTerritory", async (territoryId, { rejectWithValue }) => {
  try {
    const res = await deleteTerritory(territoryId);
    return territoryId;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const createTerritoryOverride = createAsyncThunk(
  "territory/createTerritoryOverride",
  async (data, { rejectWithValue }) => {
    const { id, ...payload } = data;

    try {
      const res = await postTerritoryOverride(id, payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const updateTerritoryOverride = createAsyncThunk(
  "territory/updateTerritoryOverride",
  async (data, { rejectWithValue }) => {
    const { id, overrideId, payload } = data;

    try {
      const res = await patchTerritoryOverride(id, overrideId, payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const removeTerritoryOverride = createAsyncThunk(
  "territory/removeTerritoryOverride",
  async (data) => {
    const { id, overrideId } = data;
    const res = await deleteTerritoryOverride(id, overrideId);
    return { overrideId };
  }
);

export const territorySlice = createSlice({
  name: "territory",
  initialState,
  reducers: {
    setTerritoryTableSettings(state, { payload }) {
      state[payload.field] = payload.value;
    },
    cleanTableSettings(state) {
      state.pageSize = 50;
      state.page = 1;
      state.orderBy = [];
      state.filterBy = [];
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTerritories.pending, (state) => {
        state.territoriesLoading = true;
      })
      .addCase(fetchTerritories.fulfilled, (state, action) => {
        state.territoriesLoading = false;
        state.territories = action.payload.results;
        state.totalCount = action.payload.count;
      })
      .addCase(fetchTerritories.rejected, (state) => {
        state.territoriesLoading = false;
      })
      .addCase(fetchTerritory.pending, (state, action) => {
        state.territoriesLoading = true;
      })
      .addCase(fetchTerritory.fulfilled, (state, action) => {
        state.territory = action.payload;
        state.territoriesLoading = false;
      })
      .addCase(fetchTerritoryOverrides.pending, (state, action) => {
        state.territoryOverridesLoading = true;
      })
      .addCase(fetchTerritoryOverrides.fulfilled, (state, action) => {
        state.territoryOverrides = action.payload;
        state.territoryOverridesLoading = false;        
      })
      .addCase(createTerritory.pending, (state) => {
        state.createLoading = true;
      })
      .addCase(createTerritory.fulfilled, (state, action) => {
        state.createLoading = false;
        fireSuccessNotification(`Territory "${action.meta.arg.name}" created successfully`);
        state.totalCount = state.totalCount + 1;
        if (state.territories.length < state.pageSize) {
          state.territories = [...state.territories, action.payload];
        }
      })
      .addCase(createTerritory.rejected, (state, action) => {
        state.createLoading = false;
        const errorFields = Object.keys(action.payload);
        errorFields.forEach(field => {
          fireErrorNotification(`${field}: ${action.payload[field][0]}`);
        });
      })
      .addCase(updateTerritory.pending, (state) => {
        state.updateLoading = true;
      })
      .addCase(updateTerritory.fulfilled, (state, action) => {
        state.updateLoading = false;
        fireSuccessNotification(`Territory "${action.meta.arg.payload.name}" updated successfully`);
        state.territories = state.territories.map((item) =>
          item.id === action.meta.arg.id
            ? {
              ...item,
              ...action.payload
            }
            : item
        );
      })
      .addCase(updateTerritory.rejected, (state, action) => {
        const errorFields = Object.keys(action.payload);
        errorFields.forEach(field => {
          fireErrorNotification(`${field}: ${action.payload[field][0]}`);
        });
      })
      .addCase(removeTerritory.fulfilled, (state, action) => {
        fireSuccessNotification(`Territory deleted successfully`);
        state.territory = {};
        state.territories = state.territories.filter(el => el.id !== action.payload);
      })
      .addCase(removeTerritory.rejected, (state, action) => {
        const protected_elements = action.payload.protected_elements;
        fireErrorNotification(`Protected by: 
          ${protected_elements.map(el => ` id: ${el.id} label: ${el.label}`)}`);
      })
      .addCase(createTerritoryOverride.fulfilled, (state, action) => {
        fireSuccessNotification(`Territory override created successfully`);
        state.territoryOverrides.push(action.payload);
      })
      .addCase(createTerritoryOverride.rejected, (state, action) => {
        const errorFields = Object.keys(action.payload);
        errorFields.forEach(field => {
          fireErrorNotification(`${field}: ${action.payload[field][0]}`);
        });
      })
      .addCase(updateTerritoryOverride.fulfilled, (state, action) => {
        fireSuccessNotification(`Override updated successfully`);
        state.territoryOverrides = state.territoryOverrides.map(el => {
          if (el.id === action.payload.id) {
            return action.payload;
          } else {
            return el;
          }
        });
      })
      .addCase(updateTerritoryOverride.rejected, (state, action) => {
        const errorFields = Object.keys(action.payload);
        errorFields.forEach(field => {
          fireErrorNotification(`${field}: ${action.payload[field][0]}`);
        });
      })
      .addCase(removeTerritoryOverride.fulfilled, (state, action) => {
        fireSuccessNotification(`Override deleted successfully`);
        state.territoryOverrides = state.territoryOverrides.filter(el => el.id !== action.payload.overrideId);
      })
      .addCase(signOut, () => initialState);
  }
});

export const {
  setTerritoryTableSettings,
  cleanTableSettings
} = territorySlice.actions;

export const selectTerritoryNumberOfPages = createSelector(
  (state) => state.territory.totalCount,
  (state) => state.territory.pageSize,
  (totalCount, pageSize) => Math.ceil(totalCount / pageSize)
);

export default territorySlice.reducer;
