import React, { useEffect, useState } from "react";
import invoicesApi from "../../distflowAPI/invoicesApi";
import { getNoPages } from "../../utils";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DataTable from "../../components/DataTable";
import updatePaymentTableColumns from "./updatePaymentTableColumns";
import { formatAgGridSorting } from "../../distflowAPI/common";
import DatePickerCustom2 from "../../components/DatePicker2";
import { smallButton } from "../../utils/smallButton";
import dayjs from "dayjs";
import { DATE_FORMAT } from "../../utils/constants";

const filtersDefaults = {
  accountId: { type: "number", default: null },
};
const defaultColDef = {
  flex: 1,
  filter: false,
  floatingFilter: false,
};

function InvoicesSelect({
  open,
  setOpen,
  accountId = null,
  customerId = null,
  includedInvoices = [],
  payments = [],
  includeInvoice,
}) {
  const [invoices, setInvoices] = useState([]);
  const [orderBy, setOrderBy] = useState([]);
  const [pageSize, setPageSize] = useState(50);
  const [page, setPage] = useState(1);
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [invoicesLoading, setInvoicesLoading] = useState(false);
  const [filterBy, setFilterBy] = useState({
    created__gte: null,
    created__lt: null,
    account__name: "",
    status: "UNPAID,CREDIT,PARTIALLY_PAID",
    invoice__balance__gt: null,
    invoice__balance__lt: null,
  });
  useEffect(() => {
    console.log("new filter by", filterBy);
    setInvoicesLoading(true);

    const local_filter = Object.keys(filterBy)
      .filter((k) => filterBy[k])
      .map((k) => {
        return {
          column: k,
          value: filterBy[k],
        };
      });
    if (accountId) {
      local_filter.push({ column: "account_id", value: accountId });
    }
    if (customerId) {
      local_filter.push({ column: "customer_id", value: customerId });
    }
    if (accountId || customerId) {
      invoicesApi
        .list(pageSize, page, orderBy, local_filter)
        .then((res) => {
          setInvoices(res.results);
          setNumberOfPages(getNoPages(res.count, pageSize));
        })
        .finally(() => {
          setInvoicesLoading(false);
        });
    }
  }, [pageSize, page, orderBy, accountId, customerId, filterBy]);

  const InvoiceActionButton = (params) => {
    const invoicesId = payments.map((el) => el.invoice);
    const isIncluded = invoicesId.includes(params.data.id);

    return (
      <>
        {isIncluded ? (
          <Typography fontSize="13px">Included</Typography>
        ) : (
          <Button
            variant="outlined"
            sx={smallButton}
            onClick={() => {
              includeInvoice(params.data);
            }}
          >
            Include
          </Button>
        )}
      </>
    );
  };

  const actionTableColumns = {
    headerName: "Action",
    cellRendererSelector: () => {
      return {
        component: InvoiceActionButton,
      };
    },
    minWidth: 100,
  };
  const handleStatusChange = (event) => {
    const { value, checked } = event.target;

    setFilterBy((prevFilters) => {
      let updatedStatus;

      if (value === "") {
        updatedStatus = checked ? "" : "";
      } else {
        const statusArray = prevFilters.status.split(",");
        if (checked) {
          if (value == "UNPAID") {
            updatedStatus = [...statusArray, "UNPAID", "CREDIT"];
          } else {
            updatedStatus = [...statusArray, value];
          }
        } else {
          if (value == "UNPAID") {
            updatedStatus = statusArray
              .filter((status) => status !== value)
              .filter((s) => s !== "CREDIT");
          } else {
            updatedStatus = statusArray.filter((status) => status !== value);
          }

          if (updatedStatus.length === 0) {
            updatedStatus = "";
          }
        }
      }
      updatedStatus = updatedStatus.length
        ? updatedStatus.filter((el) => el).join(",")
        : "";
      return { ...prevFilters, status: updatedStatus, past_due: "" };
    });
  };
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      maxWidth="xl"
      sx={{
        "& .MuiPaper-root": {
          width: "100%",
          maxWidth: "1200px",
        },
      }}
    >
      <DialogTitle>
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
          }}
        >
          <CloseIcon
            sx={{ cursor: "pointer" }}
            onClick={() => {
              setOpen(false);
            }}
          />
        </div>
        <Typography
          variant="pageTitle"
          noWrap
          textAlign="center"
          component="p"
          mb="20px"
        >
          Select Invoices
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Stack spacing={1}>
          <Stack>
            <FormGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              sx={{ border: "none !important", padding: "0 !important" }}
            >
              <FormControlLabel
                value=""
                control={
                  <Checkbox
                    checked={filterBy.status === ""}
                    onChange={handleStatusChange}
                  />
                }
                label="All"
              />
              <FormControlLabel
                value="PAID"
                control={
                  <Checkbox
                    checked={filterBy.status.split(",").includes("PAID")}
                    onChange={handleStatusChange}
                  />
                }
                label="Paid"
              />
              <FormControlLabel
                value="UNPAID"
                control={
                  <Checkbox
                    checked={filterBy.status.split(",").includes("UNPAID")}
                    onChange={handleStatusChange}
                  />
                }
                label="Unpaid"
              />
              <FormControlLabel
                value="PARTIALLY_PAID"
                control={
                  <Checkbox
                    checked={filterBy.status
                      .split(",")
                      .includes("PARTIALLY_PAID")}
                    onChange={handleStatusChange}
                  />
                }
                label="Partially paid"
              />
              <FormControlLabel
                value="VOIDED"
                control={
                  <Checkbox
                    checked={filterBy.status.split(",").includes("VOIDED")}
                    onChange={handleStatusChange}
                  />
                }
                label="Voided"
              />
              <FormControlLabel
                value="CREDIT"
                control={
                  <Checkbox
                    checked={filterBy.status.split(",").includes("CREDIT")}
                    onChange={handleStatusChange}
                  />
                }
                label="Credit"
              />
            </FormGroup>
          </Stack>
          <Stack direction={"row"} spacing={1} paddingTop={1}>
            <DatePickerCustom2
              label={"created After"}
              maxWidth={180}
              controlledValue={
                filterBy.created__gte ? dayjs.utc(filterBy.created__gte) : null
              }
              setControlledValue={(newValue) =>
                setFilterBy({
                  ...filterBy,
                  created__gte: newValue
                    ? dayjs(newValue).format(DATE_FORMAT)
                    : "",
                })
              }
            />
            <DatePickerCustom2
              label={"Created Before"}
              maxWidth={180}
              controlledValue={
                filterBy.created__lt ? dayjs.utc(filterBy.created__lt) : null
              }
              setControlledValue={(newValue) =>
                setFilterBy({
                  ...filterBy,
                  created__lt: newValue
                    ? dayjs(newValue).format(DATE_FORMAT)
                    : "",
                })
              }
            />
            <TextField
              label={"Account name"}
              fullWidth
              sx={{ maxWidth: 400 }}
              value={filterBy.account__name}
              onChange={(e) =>
                setFilterBy({ ...filterBy, account__name: e.target.value })
              }
            />
            <TextField
              label={"Balance GTE"}
              sx={{ maxWidth: 180 }}
              value={filterBy.invoice__balance__gt}
              onChange={(e) =>
                setFilterBy({
                  ...filterBy,
                  invoice__balance__gt: e.target.value,
                })
              }
            />
            <TextField
              label={"Balance LTE"}
              sx={{ maxWidth: 180 }}
              value={filterBy.invoice__balance__lt}
              onChange={(e) =>
                setFilterBy({
                  ...filterBy,
                  invoice__balance__lt: e.target.value,
                })
              }
            />
          </Stack>
          <Box height={500}>
            <DataTable
              rowData={accountId || customerId ? invoices : []}
              columnDefs={[...updatePaymentTableColumns, actionTableColumns]}
              defaultColDef={defaultColDef}
              loading={invoicesLoading}
              suppressSorting={true} //disable default sorting
              rowHeight={33}
              headerHeight={42}
              onSortChanged={(event) => {
                setOrderBy(
                  formatAgGridSorting(event.columnApi.columnModel.gridColumns)
                );
              }}
              page={page}
              pageSize={pageSize}
              setPageSize={(pageSize) => setPageSize(pageSize)}
              noPages={numberOfPages}
              setPage={(page) => setPage(page)}
            />
          </Box>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}

export default InvoicesSelect;
