import { configureStore } from "@reduxjs/toolkit";
import {errorHandlingMiddleware} from "./middlewares";

import user from "./module/user";
import category from "./module/category";
import tag from "./module/tag";
import vendor from "./module/vendor";
import override from "./module/override";
import territory from "./module/territory";
import customer from "./module/customer";
import group from "./module/group";
import route from "./module/route";
import account from "./module/account";
import addEditRoute from "./module/addEditRoute";
import addRouteChanges from "./module/addRouteChanges";
import product from "./module/product";
import driver from "./module/driver";
import utils from "./module/utils";
import payment from "./module/payment";
import invoice from "./module/invoice";
import routeReports from "./module/routeReports";
import routeChange from "./module/routeChange";
import error from "./module/errors"

export const store = configureStore({
  reducer: {
    error,
    user,
    category,
    tag,
    vendor,
    override,
    territory,
    customer,
    group,
    route,
    account,
    addEditRoute,
    addRouteChanges,
    product,
    driver,
    routeReports,
    utils,
    payment,
    invoice,
    routeChange,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: { ignoreActions: true },
    }).concat(errorHandlingMiddleware)
});

/**
 * @typedef {ReturnType<typeof store.getState>} RootState
 */
