import React from "react";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { Box, Button, Stack, Typography } from "@mui/material";
import { useSearchParams } from "react-router-dom";

const NoOptionsBlock = ({ option, isFiltered }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  
  const clearSearchParams = () => {
    const paramKeys = Array.from(searchParams.keys());

    paramKeys.forEach((key) => {
      searchParams.delete(key);
    });

    setSearchParams(searchParams);
  };

  return (
    <Box
      height="200px"
      display="flex"
      gap={2}
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      <Stack direction="column" alignItems="center" gap={1} sx={{
        maxWidth: {
          md: "90%",
          xs: "100%"
        }
      }}>
        <SearchOutlinedIcon fontSize="large" />
        <Typography component="p" variant="h6" textAlign="center">
          This customer does't have any {option}{isFiltered ? " based on the selected filters" : ""}
        </Typography>
      </Stack>
      {isFiltered && <Button onClick={clearSearchParams}>
        Clean filters
      </Button>}
    </Box>
  );
};

export default NoOptionsBlock;