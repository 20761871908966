import React, { useState, useEffect } from "react";
import { Button, Typography, Stack, Box } from "@mui/material";
import DataTable from "../../components/DataTable";
import { useSearchParams } from "react-router-dom";
import useAppSelector from "../../hooks/useAppSelector";
import useAppDispatch from "../../hooks/useAppDispatch";
import { formatAgGridFilters, formatAgGridSorting } from "../../distflowAPI/common";
import { 
  fetchVendorCategoryOverrides, 
  setOverrideTableSettings, 
  cleanTableSettings, 
  selectOverrideNumberOfPages 
} from "../../redux/module/override";
import vendorCategoryOverrideTableColumns from "./vendorCategoryOverrideTableColumns";
import VendorCategoryFilters from "./VendorCategoryFilters";
import VendorCategoryOverrideForm from "./VendorCategoryOverrideForm";
import PageLoading from "../../components/PageLoading";

const defaultColDef = {
  flex: 1,
  filter: false,
  floatingFilter: false,
};

const VendorCategoryOverrides = () => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const { vendorCategoryOverrides, pageSize, page, orderBy, filterBy, overridesLoading }= useAppSelector((state) => state.override);
  const numberOfPages = useAppSelector(selectOverrideNumberOfPages);

  const [open, setOpen] = useState(false);


  useEffect(() => {
    dispatch(fetchVendorCategoryOverrides({ page, pageSize, filterBy, orderBy, searchParams }));
  }, [pageSize, page, filterBy, orderBy, searchParams]);


  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleChange = (field, value) => {
    if (field === "filterBy" || field === "pageSize") {
      dispatch(setOverrideTableSettings({ field: "page", value: 1 }));
    }
    dispatch(setOverrideTableSettings({ field, value }));
  };

  useEffect(() => {
    return () => {
      dispatch(cleanTableSettings())
    }
  }, []);

  return (
    <>
      <PageLoading dataLoading={overridesLoading} />
      
      <Typography fontSize="23px" fontWeight={800} component="h2" data-testid="page-title">
        Vendor Category Overrides
      </Typography>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        alignItems={{ xs: "space-between", sm: "center" }}
        justifyContent="space-between"
        spacing={1.5}
        my="25px"
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          spacing="15px"
        >
          <Button onClick={handleOpen} variant="contained">
            Add New
          </Button>
        </Stack>
      </Stack>

      <VendorCategoryFilters />
      
      <Box className="table-wrapper">
        <DataTable
          rowData={vendorCategoryOverrides}
          columnDefs={vendorCategoryOverrideTableColumns}
          defaultColDef={defaultColDef}
          suppressSorting={true} //disable default sorting
          loading={overridesLoading}
          rowHeight={53}
          headerHeight={60}
          page={page}
          pageSize={pageSize}
          setPageSize={(pageSize) => handleChange("pageSize", pageSize)}
          noPages={numberOfPages}
          setPage={(page) => handleChange("page", page)}
        />
      </Box>
      <VendorCategoryOverrideForm open={open} handleClose={handleClose} />
    </>
  );
};

export default VendorCategoryOverrides;
