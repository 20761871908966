import CustomerSelect from "../../components/AsyncSelects/CustomerSelect";
import CategorySelect from "../../components/AsyncSelects/CategorySelect";
import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Grid,
  Typography,
} from "@mui/material";

import { useState, useEffect } from "react";
import { postCategoryCustomerRestrictions } from "../../distflowAPI/customersApi";

import { useParams } from "react-router-dom";
import ConfirmDialog from "../../components/ConfirmDialog";
export default function CreateCategoryRestriction({
  customerSelectOpen,
  setCustomerSelectOpen,
  fetchRestrictions,
}) {
  const [note, setNote] = useState();
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [customerSearch, setCustomerSearch] = useState("");
  const { id } = useParams();
  const [confirmRestriction, setConfirmRestriction] = useState(false);
  function handleClick() {
    const requests = selectedCategories.map((restriction) => {
      return postCategoryCustomerRestrictions(id, restriction.value, note);
    });
    Promise.all(requests)
      .then((responses) => {
        setCustomerSelectOpen(false);
        fetchRestrictions();
      })
      .catch((error) => {
        console.error(error);
      });
  }
  return (
    <>
      <Dialog
        maxWidth="xl"
        sx={{
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "750px",
          },
          zIndex: 1500,
        }}
        open={customerSelectOpen}
        onClose={() => setCustomerSelectOpen(false)}
      >
        <DialogContent>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <CloseIcon
              sx={{ cursor: "pointer" }}
              onClick={() => {
                setCustomerSelectOpen(false);
              }}
            />
          </div>
          <Typography variant="pageTitle" noWrap component="p" mb="20px">
            Category Search:
          </Typography>
          <Grid container>
            <CategorySelect
              multiple
              label="Category to Restrict:"
              id="multiple-limit-tags"
              value={selectedCategories}
              onChange={(_event, newValue) => {
                setSelectedCategories(newValue);
              }}
              setCustomerSearch={setCustomerSearch}
            />

            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                placeholder="Type a note..."
                variant="outlined"
                sx={{ width: "100%", mt: "20px" }}
                value={note}
                onChange={(e) => setNote(e.target.value)}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="error"
            onClick={() => setConfirmRestriction(true)}
          >
            Restrict
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setCustomerSelectOpen(false);
            }}
            color="success"
          >
            Cancel
          </Button>
          <ConfirmDialog
            title="Restrict Customers"
            open={confirmRestriction}
            setOpen={setConfirmRestriction}
            onConfirm={() => handleClick()}
          >
            <Typography>
              Are you sure you want to restrict these customers ?
            </Typography>
          </ConfirmDialog>
        </DialogActions>
      </Dialog>
    </>
  );
}
