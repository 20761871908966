import {
  Autocomplete,
  Box,
  Input,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import NumberInput from "../NumberInput";
import { listSalesmen } from "../../distflowAPI/salesmenApi";

function getTotal(obj) {
  let count = 0;
  let discount = 0;
  Object.values(obj).map((item) => {
    if (item.status == "CANCELED") {
      return;
    }
    if (item.qty) {
      const item_price = item?.item_details?.price?.price || item?.price || 0;
      count += item_price * item.qty;
      if (
        item.discount_value &&
        item.discount_value > 0 &&
        item.discount_type
      ) {
        if (item.discount_type == "PERCENT") {
          discount += item_price * (item.discount_value / 100) * item.qty;
          console.log(
            "discount added percent: ",
            item_price * (item.discount_value / 100)
          );
        } else {
          discount += item.discount_value * item.qty;
          console.log("discount added val: ", {
            v: item.discount_value,
            q: item.qty,
          });
        }
      }
    } else {
      count += 0;
    }
  });
  return {
    grandToatl: count,
    discount: discount,
  };
}

function percentage(percent, total) {
  return (total - (percent / 100) * total).toFixed(2);
}

export default function OrderItemTotal({
  items,
  attributedSalesMan,
  setAttributedSalesMan,
  canAttributeSalesMan,
  children,
}) {
  const [total, setTotal] = useState({
    grandToatl: 0,
    discount: 0,
  });
  const [discountValue, setDiscountValue] = useState(0);
  const [priceEdit, setPriceEdit] = useState("");
  const [note, setNote] = useState("");
  const [showDiscount, setShowDiscount] = useState(false);
  const [salesMen, setSalesMen] = useState([]);
  /* 
  useEffect(() => {
    listSalesmen(1, 50, [{ column: "search", value: "" }]).then((res) => {
      const newSalesMen = [];
      res.data.results.map((item) =>
        newSalesMen.push({
          label: `${item.user.first_name} ${item.user.last_name}`,
          value: item.id,
        }),
      );
      setSalesMen(newSalesMen);
    });
  }, []); */
  useEffect(() => {
    if (priceEdit === "percentage") {
      setTotal(percentage(discountValue, total));
    }
    if (priceEdit === "dollarUnit") {
      setTotal((total - discountValue).toFixed(2));
    }
  }, [discountValue]);

  const handleSubmitDiscount = () => {};
  useEffect(() => {
    setTotal(getTotal(items));
    setDiscountValue(0);
  }, [items]);
  const discountedPriceStyle = {
    color: "#344a97",
    fontWeight: 600,
  };
  const selectedButton = {
    fontWeight: 600,
    padding: "6px",
    marginRight: "0",
  };
  const unselectedButton = {
    fontWeight: 200,
    padding: "6px",
    marginRight: "0",
  };

  return (
    <Box
      sx={{
        padding: "10px 14px",
        borderRadius: "4px",
        backgroundColor: "#F5F5F5",
        marginTop: "8px",
      }}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        {/*<div style={{ display: "flex", alignItems: "center" }}>
          <Button
            sx={priceEdit === "percentage" ? selectedButton : unselectedButton}
            onClick={() => {
              setPriceEdit("percentage");
              setDiscountValue(0);
              setTotal(getTotal(items).toFixed(2));
            }}
          >
            Discount by Percentage
          </Button>
          <Button
            sx={priceEdit === "dollarUnit" ? selectedButton : unselectedButton}
            onClick={() => {
              setPriceEdit("dollarUnit");
              setDiscountValue(0);
              setTotal(getTotal(items).toFixed(2));
            }}
          >
            Discount by Dollar
          </Button>
          </div>*/}

        <Stack
          direction={"row"}
          justifyContent={"center"}
          justifyItems={"center"}
          alignItems={"center"}
          alignContent={"center"}
        >
          <Typography marginRight={2}>Attributed to</Typography>
          <Box maxWidth={250} minWidth={250}>
            {children}
          </Box>
        </Stack>

        {total.discount > 0 ? (
          <Stack alignItems={"end"}>
            <Typography>Grand Total: ${total.grandToatl.toFixed(2)}</Typography>
            <Typography color={"red"}>
              Discount: -$
              {total.discount.toFixed(2)}
            </Typography>
            <Typography>
              Total: {(total.grandToatl - total.discount).toFixed(2)}
            </Typography>
          </Stack>
        ) : (
          <Typography>Total: ${total.grandToatl.toFixed(2)}</Typography>
        )}
      </Stack>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        gap="25px"
      >
        {priceEdit && (
          <Input
            size="small"
            fullWidth
            placeholder="Add a note"
            sx={{ fontSize: "14px" }}
            value={note}
            onChange={(e) => setNote(e.target.value)}
          />
        )}
        {priceEdit === "percentage" && (
          <>
            <NumberInput
              setValue={setDiscountValue}
              value={discountValue}
              unit={"%"}
              setPriceEdit={setPriceEdit}
              handleClick={handleSubmitDiscount}
            />
          </>
        )}
        {priceEdit === "dollarUnit" && (
          <>
            <NumberInput
              setValue={setDiscountValue}
              value={discountValue}
              unit={"$"}
              setPriceEdit={setPriceEdit}
              handleClick={handleSubmitDiscount}
            />
          </>
        )}
      </Stack>
    </Box>
  );
}
