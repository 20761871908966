import { Box, Button, Grid, Typography } from "@mui/material";
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from "react-circular-progressbar";
import { ReactComponent as OpenIc } from "../../../images/open-ic.svg";
import { useModal } from "mui-modal-provider";
import PickUpsModal from "./pickUpsModal";
import DeliveryModal from "./deliveriesModal";
import { useNavigate } from "react-router-dom";
import { downloadFile } from "../../../distflowAPI/downloadFile";
import dayjs from "dayjs";
import { DATE_FORMAT_FRONT } from "../../../utils/constants";
import { useState } from "react";

function Report({ report, date, refreshTable }) {
  const navigate = useNavigate();
  const { showModal } = useModal();
  const [isExporting, setIsExporting] = useState(false);
  function getDeliveries() {
    const result = { delivered: 0, nonDelivered: 0, total: 0 };
    const resultTwo = {
      accounts: {},
      routes: {},
    };
    report.pick_sheet_items.map((pickSheetItem) => {
      pickSheetItem.orders_items.map((orderItem) => {
        if (!orderItem.is_handover && !orderItem.is_handover_to_pick_up) {
          //  ;
          if (!resultTwo.accounts[orderItem.account.accountId]) {
            resultTwo.accounts[orderItem.account.accountId] = {
              delivered: 0,
              total: 0,
            };
          }
          resultTwo.accounts[orderItem.account.accountId].total += 1;
          if (orderItem.status == "DELIVERED") {
            resultTwo.accounts[orderItem.account.accountId].delivered += 1;
          }
        }
        if (orderItem.is_handover && orderItem.is_handover_to_pick_up) {
          // ignore this case
          if (!resultTwo.accounts[orderItem.account.accountId]) {
            resultTwo.accounts[orderItem.account.accountId] = {
              accountName: orderItem.account.accountName,
              delivered: 0,
              total: 0,
            };
          }
          resultTwo.accounts[orderItem.account.accountId].total += 1;
          if (orderItem.status == "DELIVERED") {
            resultTwo.accounts[orderItem.account.accountId].delivered += 1;
          }
        }
        if (orderItem.is_handover && !orderItem.is_handover_to_pick_up) {
          // gotta deliver to a route
          if (!resultTwo.routes[orderItem.route.id]) {
            resultTwo.routes[orderItem.route.id] = {
              delivered: 0,
              total: 0,
            };
          }
          resultTwo.routes[orderItem.route.id].total += 1;
          if (
            orderItem.handover_status == "HANDED_OVER" ||
            orderItem.handover_status == "RECEIVED"
          ) {
            resultTwo.routes[orderItem.route.id].delivered += 1;
          }
        }
      });
    });
    resultTwo.delivered = Object.keys(resultTwo.accounts).filter(
      (key) =>
        resultTwo.accounts[key].delivered == resultTwo.accounts[key].total
    ).length;
    resultTwo.delivered += Object.keys(resultTwo.routes).filter(
      (key) => resultTwo.routes[key].delivered == resultTwo.routes[key].total
    ).length;
    //  ;
    //  ;
    //  ;
    //  ;
    resultTwo.total =
      Object.keys(resultTwo.accounts).length +
      Object.keys(resultTwo.routes).length;

    return resultTwo;
  }

  function getPickedUp() {
    let total = 0;
    report.pick_sheet_items.map((ps) => {
      let picked_up = 0;
      ps.orders_items.map((oi) => {
        if (oi.picked_up_qty >= oi.quantity) {
          picked_up += 1;
        }
      });
      if (picked_up == ps.orders_items.length) {
        total += 1;
      }
    });
    return total;
  }

  return (
    <Box
      border="1px solid #DBDFEA"
      borderRadius="5px"
      bgcolor="#fff"
      padding={"20px"}
    >
      <Grid container>
        <Grid md="4">
          <Typography>
            {report.route.driver.user.first_name}{" "}
            {report.route.driver.user.last_name}
          </Typography>
        </Grid>
        <Grid md="8">
          <Typography fontWeight={800}>{report.route.name}</Typography>
        </Grid>
      </Grid>
      <Grid container marginTop={"10px"}>
        <Grid md="4" paddingRight={"10px"}>
          <Button
            variant="outlined"
            fullWidth
            sx={{ marginBottom: "5px" }}
            onClick={() => {
              window.open(
                `/routes2/routes/${report.route.id}`,
                "_blank",
                "noopener,noreferrer"
              );
            }}
          >
            View Route
          </Button>
          {/*<Button variant="outlined" fullWidth sx={{ marginBottom: "5px" }}>*/}
          {/*  Pick Sheet*/}
          {/*</Button>*/}
          {/*<Button variant="outlined" fullWidth sx={{ marginBottom: "5px" }}>*/}
          {/*  View Location*/}
          {/*</Button>*/}
          <Button
            variant="outlined"
            fullWidth
            sx={{ marginBottom: "5px" }}
            disabled={isExporting}
            onClick={() => {
              setIsExporting(true);
              downloadFile(
                `/api/routes2/export_route_report/?date=${report.date}&route_id=${report.route.id}`,
                `${report.route.name} ${dayjs
                  .utc(report.date)
                  .format(DATE_FORMAT_FRONT)}.pdf`
              ).finally(() => setIsExporting(false));
            }}
          >
            {isExporting ? "Downloading..." : "Export PDF"}
          </Button>
        </Grid>
        <Grid md="8">
          <Grid container>
            <Grid md="6" padding={"10px"}>
              <Box textAlign="center">
                <Button
                  size="small"
                  variant="gray"
                  endIcon={<OpenIc />}
                  onClick={() =>
                    showModal(PickUpsModal, {
                      itemId: report.id,
                      date: date,
                      initRouteData: report,
                      refreshTable: refreshTable,
                    })
                  }
                  sx={{ marginBottom: "10px" }}
                >
                  Pickups
                </Button>

                <Box>
                  <CircularProgressbarWithChildren
                    value={getPickedUp()}
                    maxValue={report.pick_sheet_items.length}
                    strokeWidth={17}
                    styles={buildStyles({
                      pathColor: "#00D1A5",
                      trailColor: "#E7E7E7",
                      rotation: 0.95,
                    })}
                  >
                    <Typography
                      fontSize="28px"
                      fontWeight={700}
                      color="#535151"
                    >
                      {getPickedUp()}/{report.pick_sheet_items.length}
                    </Typography>
                  </CircularProgressbarWithChildren>
                </Box>
              </Box>
            </Grid>
            <Grid md="6" padding={"10px"}>
              <Box textAlign="center">
                <Button
                  size="small"
                  variant="gray"
                  endIcon={<OpenIc />}
                  onClick={() =>
                    showModal(DeliveryModal, {
                      reportId: report.id,
                      date: date,
                      initRouteData: report,
                      refreshTable: refreshTable,
                    })
                  }
                  sx={{ marginBottom: "10px" }}
                >
                  Deliveries
                </Button>

                <Box>
                  <CircularProgressbarWithChildren
                    value={getDeliveries().delivered}
                    maxValue={getDeliveries().total}
                    strokeWidth={17}
                    styles={buildStyles({
                      pathColor: "#00D1A5",
                      trailColor: "#E7E7E7",
                      rotation: 0.95,
                    })}
                  >
                    <Typography
                      fontSize="28px"
                      fontWeight={700}
                      color="#535151"
                    >
                      {getDeliveries().delivered}/{getDeliveries().total}
                    </Typography>
                  </CircularProgressbarWithChildren>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Report;
