import {
  IconButton,
  useTheme,
  useMediaQuery,
  Dialog,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import dayjs from "dayjs";
import { getInvoiceHistory } from "../../../distflowAPI/invoicesApi";
import { useEffect, useState } from "react";

const HistoryModal = (props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { ...restProps } = props;
  const [history, setHistory] = useState([]);

  const fetchHistory = async () => {
    const res = await getInvoiceHistory(props.invoiceId);
    setHistory(res.data.result.merged_history);
  };

  useEffect(() => {
    fetchHistory();
  }, []);

  return (
    <Dialog
      {...restProps}
      fullScreen={fullScreen}
      maxWidth="md"
      sx={{
        "& .MuiPaper-root": {
          width: "100%",
          maxWidth: "900px",
          height: "500px",
        },
        zIndex: 1500,
      }}
    >
      <DialogTitle
        sx={{
          textAlign: "center",
          fontSize: "24px",
          fontWeight: 600,
          color: "#252525",
          paddingBottom: 0,
        }}
      >
        Invoice History #{props.invoiceId}
      </DialogTitle>

      <IconButton
        aria-label="close"
        onClick={() => props.onClose()}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent
        sx={{ display: "flex", flexDirection: "column", gap: "12px" }}
      >
        <TableContainer>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>User</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Changes</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {history.map((row) => (
                <TableRow key={row.internal_id}>
                  <TableCell component="th" scope="row">
                    {row.internal_id}
                  </TableCell>
                  <TableCell>{row?.user?.name}</TableCell>
                  <TableCell>
                    {dayjs(row.date).format("MM/DD/YY HH:mm")}
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{ textAlign: "left", whiteSpace: "pre-wrap" }}
                  >
                    {row?.changes
                      ? row.changes.map((change) => (
                          <>
                            <div key={change?.field}>
                              {change?.field}: {change?.prev} → {change?.curr}
                            </div>
                            <br />
                          </>
                        ))
                      : row?.message}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
    </Dialog>
  );
};

export default HistoryModal;
