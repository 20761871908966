import dayjs from "dayjs";
import { DATE_FORMAT_FRONT } from "../../utils/constants";
import { Stack, TextField, Typography, Box, Button } from "@mui/material";

export default function getCols(extraParams) {
  return [
    {
      headerName: "Order",
      valueGetter: (params) => {
        return `#${params.data?.invoice_id || params.data?.po_id}`;
      },
      maxWidth: 150,
      // cellRenderer: OrdersRenderer,
      filter: false,
      sortable: true,
      checkboxSelection: true,
      cellRenderer: (params) => (
        <Typography
          onClick={() =>
            (window.location.href = `/invoices/invoices/${params.data?.invoice_id}`)
          }
        >
          {params.data?.po_id
            ? `PO#${params.data?.po_id}`
            : params.data?.invoice_id}
        </Typography>
      ),
    },
    {
      headerName: "Fulfillmnet Date",
      field: "order__fulfillment_date",
      valueGetter: (params) => {
        return dayjs
          .utc(params.data?.order?.fulfillment_date)
          .format(DATE_FORMAT_FRONT);
      },
      maxWidth: 280,
      minWidth: 140,
      filter: false,
      sortable: true,
      comparator: () => {},
    },
    {
      headerName: "Account",
      field: "order__account__name",
      valueGetter: (params) => {
        return params.data.account_name;
      },
      minWidth: 220,
      filter: false,
      sortable: true,
      comparator: () => {},
    },
    {
      headerName: "Unit Price",
      field: "unit_price",
      maxWidth: 120,
      filter: false,
      sortable: true,
      valueGetter: (params) => {
        return `$${params.data.unit_price}`;
      },
      comparator: () => {},
    },
    {
      headerName: "Total Price",
      field: "line_price",
      maxWidth: 120,
      filter: false,
      sortable: true,
      valueGetter: (params) => {
        return `$${params.data.line_price}`;
      },
      comparator: () => {},
    },
    {
      headerName: "Quantity",
      field: "quantity",
      maxWidth: 120,
      filter: false,
      sortable: true,
      comparator: () => {},
    },
    {
      headerName: "Billable",
      field: "delivered_qty",
      maxWidth: 130,
      filter: false,
      sortable: true,
      comparator: () => {},
    },

    {
      headerName: "Route",
      field: "route.name",
      maxWidth: 100,
      filter: false,
      sortable: true,
      comparator: () => {},
    },
  ];
}
