import React from "react";
import {
  Box,
  Button,
  Grid,
  Stack,
  Table,
  TableBody,
  Typography,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import DeleteIcon from "@mui/icons-material/Delete";

const OrderItemWrapper = ({
  status,
  count,
  approveAllItems,
  cancelAllItems,
  children,
}) => {
  const titleColor = {
    approved: "green",
    delivered: "green",
    picked: "green",
    cancelled: "red",
    auto: "orange",
    pending: "orange",
  }[status];

  const title = {
    approved: "Approved items",
    delivered: "Delivered items",
    picked: "Picked Up items",
    cancelled: "Cancelled items",
    auto: "Auto approved items",
    pending: "Pending items",
  }[status];

  const confirmAll = {
    auto: "AUTO_APPROVE",
    pending: "PENDING",
  }[status];

  return (
    <Box mt={1}>
      <Grid container>
        <Grid item sx={{ width: "calc(100% - 180px)" }}>
          <Typography
            sx={{
              marginTop: "10px",
              color: titleColor,
              marginBottom: "5px",
              fontSize: "14px",
              fontWeight: "500",
            }}
          >
            <b>{title}</b> ({count})
          </Typography>
        </Grid>
        {approveAllItems && (
          <Grid
            item
            justifyContent={"end"}
            display="flex"
            sx={{ maxWidth: "90px", width: "100%" }}
          >
            <Stack direction="row" spacing={1} alignItems="center">
              <Button
                sx={{
                  padding: "4px 5px",
                  fontSize: "12px",
                  minWidth: "0",
                  // width: "25px",
                  height: "25px !important",
                }}
                variant="contained"
                onClick={() => approveAllItems(confirmAll)}
                color="success"
                size="small"
              >
                Approve all
              </Button>
            </Stack>
          </Grid>
        )}
        {cancelAllItems && (
          <Grid
            item
            justifyContent={"end"}
            display="flex"
            sx={{ maxWidth: "90px", width: "100%" }}
          >
            <Stack direction="row" spacing={1} alignItems="center">
              <Button
                sx={{
                  padding: "4px 5px",
                  fontSize: "12px",
                  minWidth: "0",
                  // width: "25px",
                  height: "25px !important",
                }}
                variant="contained"
                onClick={() => cancelAllItems(confirmAll)}
                color="error"
                size="small"
              >
                Cancel all
              </Button>
            </Stack>
          </Grid>
        )}
      </Grid>
      <Stack direction="column">{children}</Stack>
    </Box>
  );
};

export default OrderItemWrapper;
