import React, { useEffect, useState } from "react";
import Block from "../../components/Block";
import {
  Box,
  FormControlLabel,
  Grid,
  OutlinedInput,
  Radio,
  RadioGroup,
  Stack,
  Typography,
  Switch,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useSearchDebounce } from "../../utils";
import ArrowUpwardOutlinedIcon from "@mui/icons-material/ArrowUpwardOutlined";
import ArrowDownwardOutlinedIcon from "@mui/icons-material/ArrowDownwardOutlined";

const OrdersFilter = ({ hiddenFilters }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [filterDB, setFilterDB] = useSearchDebounce();
  const [itemsCountWay, setItemsCountWay] = useState(
    searchParams.get("items_count_lte") ? "items_count_lte" : "items_count_gte",
  );
  const [itemsTotalWay, setItemsTotalWay] = useState(
    searchParams.get("items_total_lte") ? "items_total_lte" : "items_total_gte",
  );
  const [filters, setFilters] = useState({
    search: searchParams.get("search") || "",
    route_name: searchParams.get("route_name") || "",
    [itemsCountWay]:
      searchParams.get("items_count_gte") ||
      searchParams.get("items_count_lte") ||
      "",
    [itemsTotalWay]:
      searchParams.get("items_total_gte") ||
      searchParams.get("items_total_lte") ||
      "",
    fulfillment_status: searchParams.get("fulfillment_status") || "",
    payment_status: searchParams.get("payment_status") || "",
    myCustomers: searchParams.get("myCustomers") || "false",
  });

  const handleChange = (value) => {
    setSearchParams((params) => {
      const newParams = new URLSearchParams(params);
      Object.keys(value).forEach((key) => {
        if (!!value[key]) {
          newParams.set(key, value[key]);
        } else {
          newParams.delete(key);
        }
      });
      return newParams;
    });
  };

  useEffect(() => {
    setFilterDB(filters);
  }, [filters, itemsCountWay]);

  useEffect(() => {
    handleChange(filterDB);
  }, [filterDB]);

  const handleWayChange = (way, field) => {
    const reverse = {
      count: way === "items_count_gte" ? "items_count_lte" : "items_count_gte",
      total: way === "items_total_gte" ? "items_total_lte" : "items_total_gte",
    }[field];

    if (field === "count") {
      setItemsCountWay(way);
    } else {
      setItemsTotalWay(way);
    }
    setFilters((prev) => ({
      ...prev,
      [way]: prev[reverse],
      [reverse]: prev[way],
    }));
  };

  const isHideAutoOrders = hiddenFilters?.includes("automatic");

  return (
    <Block>
      <Grid container spacing={1} rowGap="10px">
        <Grid item xs={12} sm={4}>
          <Typography mb="5px">Search</Typography>
          <OutlinedInput
            size="small"
            sx={{ height: "40px" }}
            fullWidth
            value={filters.search}
            onChange={(e) => setFilters({ ...filters, search: e.target.value })}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography mb="5px">Route</Typography>
          <OutlinedInput
            size="small"
            sx={{ height: "40px" }}
            fullWidth
            value={filters.route_name}
            onChange={(e) =>
              setFilters({ ...filters, route_name: e.target.value })
            }
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Typography mb="5px">Items</Typography>
          <Stack direction={"row"} gap={1}>
            <OutlinedInput
              size="small"
              sx={{ height: "40px" }}
              fullWidth
              value={filters[itemsCountWay]}
              type="number"
              onChange={(e) =>
                setFilters({ ...filters, [itemsCountWay]: e.target.value })
              }
              endAdornment={
                <Box
                  sx={{
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                  }}
                  onClick={() =>
                    handleWayChange(
                      itemsCountWay === "items_count_gte"
                        ? "items_count_lte"
                        : "items_count_gte",
                      "count",
                    )
                  }
                >
                  {itemsCountWay === "items_count_gte" ? (
                    <ArrowUpwardOutlinedIcon fontSize="small" />
                  ) : (
                    <ArrowDownwardOutlinedIcon fontSize="small" />
                  )}
                </Box>
              }
            />
          </Stack>
        </Grid>
        <Grid item xs={12} sm={2}>
          <Typography mb="5px">Total</Typography>
          <Stack direction={"row"} gap={1}>
            <OutlinedInput
              size="small"
              fullWidth
              sx={{ height: "40px" }}
              value={filters[itemsTotalWay]}
              type="number"
              onChange={(e) =>
                setFilters({ ...filters, [itemsTotalWay]: e.target.value })
              }
              endAdornment={
                <Box
                  sx={{
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                  }}
                  onClick={() =>
                    handleWayChange(
                      itemsTotalWay === "items_total_gte"
                        ? "items_total_lte"
                        : "items_total_gte",
                      "total",
                    )
                  }
                >
                  {itemsTotalWay === "items_total_gte" ? (
                    <ArrowUpwardOutlinedIcon fontSize="small" />
                  ) : (
                    <ArrowDownwardOutlinedIcon fontSize="small" />
                  )}
                </Box>
              }
            />
          </Stack>
        </Grid>
        <Grid xs={12} lg={5} item>
          <Typography>Fulfillment status</Typography>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            sx={{ border: "none !important", padding: "0 !important" }}
            value={filters.fulfillment_status}
            onChange={(e) =>
              setFilters({ ...filters, fulfillment_status: e.target.value })
            }
          >
            <FormControlLabel value="" control={<Radio />} label="All" />
            {!isHideAutoOrders && (
              <FormControlLabel
                value="AUTOMATIC"
                control={<Radio />}
                label="Automatic"
              />
            )}
            <FormControlLabel
              value="PENDING"
              control={<Radio />}
              label="Pending"
            />
            <FormControlLabel
              value="PICKED_UP"
              control={<Radio />}
              label="Picked up"
            />
            <FormControlLabel
              value="DELIVERED"
              control={<Radio />}
              label="Delivered"
            />
          </RadioGroup>
        </Grid>
        <Grid xs={12} lg={5} item>
          <Typography>Payment status</Typography>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            sx={{ border: "none !important", padding: "0 !important" }}
            value={filters.payment_status}
            onChange={(e) =>
              setFilters({ ...filters, payment_status: e.target.value })
            }
          >
            <FormControlLabel value="" control={<Radio />} label="All" />
            <FormControlLabel
              value="PENDING"
              control={<Radio />}
              label="Pending"
            />
            <FormControlLabel value="PAID" control={<Radio />} label="Paid" />
            <FormControlLabel
              value="UNPAID"
              control={<Radio />}
              label="Unpaid"
            />
            <FormControlLabel
              value="PARTIALLY_PAID"
              control={<Radio />}
              label="Partially paid"
            />
          </RadioGroup>
        </Grid>
        <Grid xs={12} lg={2} paddingTop={4}>
          <Stack direction="row" alignItems="center">
            <Typography>My customers only</Typography>
            <Switch
              checked={filters.myCustomers == "true"}
              onChange={(_, checked) => {
                setFilters({ ...filters, myCustomers: `${checked}` });
              }}
              inputProps={{ "aria-label": "controlled" }}
            />
          </Stack>
        </Grid>
      </Grid>
    </Block>
  );
};

export default OrdersFilter;
