import { createSlice } from "@reduxjs/toolkit";



const initialState = {
  currentRoute: null,
  previousRoute: null,
};

export const utilsSlice = createSlice({
  name: "utils",
  initialState,
  reducers: {
    setRouteHistory(state, { payload }) {
      state.previousRoute = state.currentRoute;
      state.currentRoute = payload;
    },   
  },
  extraReducers: (builder) => {}
   
});

export const { setRouteHistory } =
  utilsSlice.actions;


export default utilsSlice.reducer;
