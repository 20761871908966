import React, { useState, useEffect } from 'react';

import useAppSelector from '../../hooks/useAppSelector';
import useAppDispatch from '../../hooks/useAppDispatch';

import { fetchProductById, fetchVariantById } from "../../redux/module/product";

import {
  Box,
  Grid,
  IconButton,
  Typography,
  useTheme,
  useMediaQuery,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";

const PickSheetModal = (props) => {

  const dispatch = useAppDispatch();
  const [info, setInfo] = useState([]);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { ...restProps } = props;

  const selectedReport = useAppSelector(
    (state) => state.routeReports.selectedReport
  );

  const pickups = useAppSelector((state) => state.routeReports.selectedReportPickups);

  const keyedVendors = useAppSelector(
    (state) => state.vendor.vendorMap
  );

  const keyedProducts = useAppSelector(
    (state) => state.product.keyedProducts
  );

  const keyedVariants = useAppSelector(
    (state) => state.product.keyedVariants
  );

  const pickup_items = selectedReport?.route_report?.pickup_items ?? [];
  const { order_items } = selectedReport;

  useEffect(() => {
    const newInfo = [];
    for (let pickup of pickups) {

      const pickupItems = order_items?.filter(x => x.items__variant__product__vendor == pickup.vendor) ?? [];
      const pickedItems = pickup_items?.filter(x => x.vendor == pickup.vendor) ?? [];

      for (let pickupItem of pickupItems) {
        const existingPickedItem = pickedItems.find(x => x.variant == pickupItem.items__variant);

        const existing = newInfo.find(x => x.variantId == pickupItem.items__variant);
        if (existing) {
          existing.quantity += pickupItem.items__quantity;
          existing.pickedAmount += existingPickedItem?.pickedAmount ?? 0;
        }
        else {
          newInfo.push({
            id: existingPickedItem?.id ?? 0,
            productId: pickupItem.items__variant__product,
            variantId: pickupItem.items__variant,
            accountId: pickupItem.account_id,
            quantity: pickupItem.items__quantity,
            vendorId: pickupItem.items__variant__product__vendor,
            pickedAmount: existingPickedItem?.amount ?? 0,
            note: existingPickedItem?.note,
            user: existingPickedItem?.user,
            updated_at: existingPickedItem?.updated_at
          });
        }
      }

      setInfo(newInfo);
    }

    return () => setInfo(null)
  }, [selectedReport, pickups]);

  useEffect(() => {
    for (let productInfo of info) {
      dispatch(fetchProductById({ id: productInfo.productId }))
      dispatch(fetchVariantById({ productId: productInfo.productId, variantId: productInfo.variantId }));
    }
  }, [info]);

  return (
    <Dialog
      {...restProps}
      fullScreen={fullScreen}
      maxWidth="xs"
      sx={{ "& .MuiPaper-root": { width: "100%", maxWidth: "900px" }, zIndex: 1500 }}
    >
      <DialogTitle sx={{ textAlign: "center", fontSize: "24px", fontWeight: 600, color: "#252525", paddingBottom: 0 }}>
        Pick Sheet
      </DialogTitle>
      <DialogContent sx={{ display: "flex", flexDirection: "column", gap: "12px" }}>
        <Grid container display="flex" width="100%">
          <Grid container item width="90%">
            <Grid item width="33%">
              Vendor name
            </Grid>
            <Grid item width="33%">
              Product name
            </Grid>
            <Grid item width="33%">
              Variant name
            </Grid>
          </Grid>
          <Grid item width="10%">Quantity</Grid>
        </Grid>
        {info.map((x, index) => (
          <Grid container key={index} display="flex" width="100%">
            <Grid container item width="90%">
              <Grid item width="33%">
                {keyedVendors[x.vendorId]?.name}
              </Grid>
              <Grid item width="33%">
                {keyedProducts[x.productId]?.name}
              </Grid>
              <Grid item width="33%">
                {keyedVariants[x.variantId]?.name}
              </Grid>
            </Grid>
            <Grid item width="10%">{x.quantity}</Grid>
          </Grid>
        ))}

      </DialogContent>
    </Dialog>
  );
}

export default PickSheetModal;