import React, { useEffect, useState } from "react";
import Block from "../../components/Block";
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  OutlinedInput,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useSearchDebounce } from "../../utils";
import CleaningServicesTwoToneIcon from "@mui/icons-material/CleaningServicesTwoTone";
const EmailQueueFilter = ({ showFilters }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [filterDB, setFilterDB] = useSearchDebounce();

  const [filters, setFilters] = useState({
    recipients: searchParams.get("recipients") || "",
    subject: searchParams.get("subject") || "",
    emailed: searchParams.get("emailed") || "",
    created_att__gte: searchParams.get("start_date") || "",
    created_att__lte: searchParams.get("end_date") || "",
  });

  const handleChange = (value) => {
    setSearchParams((params) => {
      const newParams = new URLSearchParams(params);
      Object.keys(value).forEach((key) => {
        if (!!value[key]) {
          newParams.set(key, value[key]);
          newParams.set("search", "");
        } else {
          newParams.delete(key);
        }
      });
      return newParams;
    });
  };

  const clearFilters = () => {
    setFilters({
      recipients: "",
      subject: "",
      emailed: "",
      start_date: "",
      end_date: "",
    });
  };

  useEffect(() => {
    setFilterDB(filters);
  }, [filters]);

  useEffect(() => {
    handleChange(filterDB);
  }, [filterDB]);

  return (
    <>
      {showFilters && (
        <Block
          sx={{
            padding: { xs: "16px", sm: "13px 24px" },
            marginBottom: "24px",
            gap: "24px",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={3}>
              <Typography mb="5px">
                Recipients{" "}
                <small style={{ color: "gray", fontSize: "10px" }}>
                  (comma separated)
                </small>
              </Typography>
              <OutlinedInput
                size="small"
                sx={{ height: "40px" }}
                fullWidth
                value={filters.recipients}
                onChange={(e) =>
                  setFilters({ ...filters, recipients: e.target.value })
                }
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography mb="5px">Subject </Typography>
              <OutlinedInput
                size="small"
                sx={{ height: "40px" }}
                fullWidth
                value={filters.subject}
                onChange={(e) =>
                  setFilters({ ...filters, subject: e.target.value })
                }
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography mb="5px">
                Start Date{" "}
                <small style={{ color: "gray", fontSize: "10px" }}>
                  (YYYY-MM-DD)
                </small>
              </Typography>
              <OutlinedInput
                type="date"
                size="small"
                sx={{ height: "40px" }}
                fullWidth
                value={filters.created_att__gte}
                onChange={(e) =>
                  setFilters({ ...filters, created_att__gte: e.target.value })
                }
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography mb="5px">
                End Date{" "}
                <small style={{ color: "gray", fontSize: "10px" }}>
                  (YYYY-MM-DD)
                </small>
              </Typography>
              <OutlinedInput
                type="date"
                size="small"
                sx={{ height: "40px" }}
                fullWidth
                value={filters.created_att__lte}
                onChange={(e) =>
                  setFilters({ ...filters, created_att__lte: e.target.value })
                }
              />
            </Grid>

            <Grid sm={12} item>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Box>
                  <Typography mb="5px">Has been emailed</Typography>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    sx={{ border: "none !important", padding: "0 !important" }}
                    value={filters.emailed}
                    onChange={(e) => {
                      setFilters({ ...filters, emailed: e.target.value });
                    }}
                  >
                    <FormControlLabel
                      value=""
                      control={<Radio />}
                      label="All"
                    />
                    <FormControlLabel
                      value="false"
                      control={<Radio />}
                      label="No"
                    />
                    <FormControlLabel
                      value="true"
                      control={<Radio />}
                      label="Yes"
                    />
                  </RadioGroup>
                </Box>

                <Box
                  sx={{
                    marginTop: "1.2rem",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    className="contained "
                    variant="contained"
                    onClick={() => {
                      clearFilters();
                    }}
                    endIcon={
                      <CleaningServicesTwoToneIcon
                        sx={{
                          fontSize: "20px",
                        }}
                      />
                    }
                  >
                    Clear Filters
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Block>
      )}
    </>
  );
};

export default EmailQueueFilter;
