import { Chip, Stack, Typography, Button } from "@mui/material";
import { useState, useEffect } from "react";

const SpecificAccountsSelect = ({
  data,
  selectedAccounts,
  setSelectedAccounts,
}) => {
  function selectAll() {
    setSelectedAccounts(data);
  }
  function deselectAll() {
    setSelectedAccounts([]);
  }
  function handleClick(account) {
    const isSelected = selectedAccounts.includes(account);

    if (isSelected) {
      setSelectedAccounts((prevSelectedAccounts) =>
        prevSelectedAccounts.filter((chip) => chip !== account),
      );
    } else {
      setSelectedAccounts((prevSelectedAccounts) => [
        ...prevSelectedAccounts,
        account,
      ]);
    }
  }

  return (
    <>
      <Stack spacing={2}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems={"center"}
        >
          <Typography>
            Select Account(s):{" "}
            {selectedAccounts.length > 0 && (
              <div style={{ fontSize: "10px", fontWeight: "bolder" }}>
                ({selectedAccounts.length} account
                {selectedAccounts.length === 1 ? "" : "s"} selected)
              </div>
            )}
          </Typography>
          <Button
            size="small"
            variant="contained"
            color={
              selectedAccounts.length == data.length ? "warning" : "success"
            }
            onClick={() =>
              selectedAccounts.length == data.length
                ? deselectAll()
                : selectAll()
            }
          >
            {selectedAccounts.length == data.length
              ? "Deselect All"
              : "Select All"}
          </Button>
        </Stack>

        <Stack direction="column" spacing={1}>
          {data.map((account, index) => {
            const isSelected = selectedAccounts.includes(account);

            return (
              <Chip
                sx={{
                  backgroundColor: isSelected ? "#4753b3" : "#A9A9A9",
                  color: isSelected ? "black" : "white",
                }}
                color="primary"
                disabled={false}
                label={account.name}
                onClick={() => handleClick(account)}
                key={`accs-${index}`}
              />
            );
          })}
        </Stack>
      </Stack>
    </>
  );
};

export default SpecificAccountsSelect;
