import React from "react";
import "./styles.css";

const sizeMap = {
  sm: 16,
  md: 24,
  lg: 42,
};

const borderWidthBySize = {
  sm: 2,
  md: 3,
  lg: 4,
};

/**
 * @param {{ size: keyof typeof sizeMap } & React.CSSProperties} props
 * @returns
 */
const Spinner = ({ size = "md", color = "#6576ff", ...styles }) => (
  <div
    style={{
      width: sizeMap[size],
      height: sizeMap[size],
      borderWidth: borderWidthBySize[size],
      borderColor: `${color} transparent ${color} transparent`,
      ...styles,
    }}
    className="custom-spinner"
  />
);

export default Spinner;
