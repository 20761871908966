import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import routesApi from "../../distflowAPI/routesApi";
import { enqueueSnackbar } from "notistack";
import { signOut } from "./user";
import { arrayToDictionary } from "../../utils";

const dayNames = {
  sunday: "Sun",
  monday: "Mon",
  tuesday: "Tue",
  wednesday: "Wed",
  thursday: "Thu",
  friday: "Fri",
  saturday: "Sat"
};

function getWeekDays(item) {
  const daysGroups = [[]];

  for (const dayKey in dayNames) {
    if (item[`is_${dayKey}`]) {
      daysGroups[daysGroups.length - 1].push(dayNames[dayKey]);
    } else if (daysGroups[daysGroups.length - 1].length) {
      daysGroups.push([]);
    }
  }

  return daysGroups
    .map((daysGroup) => {
      if (daysGroup.length > 2) {
        return [`${daysGroup[0]} - ${daysGroup[daysGroup.length - 1]}`];
      }
      return daysGroup;
    })
    .flat();
}

/**
 * @typedef {{
 *  id: number;
 *  account: import("./account").Account & { territory: number; customer: number; };
 *  display_order: number | null;
 *  type: "REGULAR" | "DISABLED" | "ADDED_TO_ROUTE"
 * }} Account
 * @typedef {{
 *  id: number;
 *  vendor: import("./vendor").Vendor;
 *  pickup_route: any;
 *  type: "DISABLED" | "PICKUP_ROUTE";
 * }} Vendor
 * @typedef {Object} Route
 * @property {number} Route.id
 * @property {string} Route.name
 * @property {boolean} Route.is_active
 * @property {boolean} Route.is_sunday
 * @property {boolean} Route.is_monday
 * @property {boolean} Route.is_tuesday
 * @property {boolean} Route.is_wednesday
 * @property {boolean} Route.is_thursday
 * @property {boolean} Route.is_friday
 * @property {boolean} Route.is_saturday
 * @property {Array<Account>} Route.accounts
 * @property {Array<Vendor>} Route.vendor_rules
 * @property {string | null} Route.note
 */

/**
 * @type {{
 *  routes: Array<Route>;
 *  routesMap: Record<string, Route>;
 *  routesLoading: boolean;
 *  pageSize: number;
 *  page: number;
 *  orderBy: [];
 *  filterBy: {};
 *  totalCount: number;
 *  routeToDelete: Route | null;
 *  routeDeleteLoading: boolean;
 *  routeModalAccounts: Array<Account> | null;
 *  routeModalVendors: Array<Vendor> | null;
 * }}
 */
const initialState = {
  routes: [],
  routesMap: [],
  routesLoading: false,
  pageSize: 50,
  page: 1,
  orderBy: [],
  filterBy: [],
  totalCount: 0,
  routeToDelete: null,
  routeDeleteLoading: false,
  routeModalAccounts: null,
  routeModalVendors: null
};

export const fetchRoutes = createAsyncThunk(
  "route/fetchRoutes",
  async (data, { dispatch, getState }) => {
    const { page, pageSize, filterBy, orderBy, searchParams } = data;
    return await routesApi.list(pageSize, page, orderBy, filterBy, searchParams);
  }
);

export const deleteRoute = createAsyncThunk("route/deleteRoute", (id) => {
    routesApi.delete(id);
    return id;
  }
);

export const routeSlice = createSlice({
  name: "route",
  initialState,
  reducers: {
    setRoutesTableSettings(state, { payload }) {
      state[payload.field] = payload.value;
    },
    cleanTableSettings(state) {
      state.pageSize = 50;
      state.page = 1;
      state.orderBy = [];
      state.filterBy = [];
    },
    setRoutesPagination(state, action) {
      state.pageSize = action.payload.pageSize;
      state.page = action.payload.page;
    },
    setRoutesFilters(state, action) {
      state.orderBy = action.payload.orderBy;
      state.filterBy = action.payload.filterBy;
    },
    setRouteToDelete(state, action) {
      state.routeToDelete = action.payload;
    },
    setRouteModalAccounts(state, action) {
      state.routeModalAccounts = action.payload;
    },
    setRouteModalVendors(state, action) {
      state.routeModalVendors = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRoutes.pending, (state) => {
        state.routesLoading = true;
      })
      .addCase(fetchRoutes.fulfilled, (state, action) => {
        state.routesLoading = false;
        state.routes = action.payload.results.map((item) => ({
          ...item,
          days: getWeekDays(item)
        }));
        state.routesMap = arrayToDictionary(state.routes, "id");
        state.totalCount = action.payload.count;
      })
      .addCase(fetchRoutes.rejected, (state) => {
        state.routesLoading = false;
      })
      .addCase(deleteRoute.pending, (state) => {
        state.routeDeleteLoading = true;
      })
      .addCase(deleteRoute.fulfilled, (state, action) => {
        state.routeDeleteLoading = false;
        state.routeToDelete = null;
        state.routes = state.routes.filter(
          (item) => item.id !== action.meta.arg
        );
        enqueueSnackbar("Successfully deleted route", { variant: "success" });
      })
      .addCase(deleteRoute.rejected, (state) => {
        state.routeDeleteLoading = false;
        state.routeToDelete = null;
        enqueueSnackbar("Failed to delete route", { variant: "error" });
      })
      .addCase(signOut, () => initialState);
  }
});

export const {
  setRoutesTableSettings,
  cleanTableSettings,
  setRoutesPagination,
  setRoutesFilters,
  setRouteToDelete,
  setRouteModalAccounts,
  setRouteModalVendors
} = routeSlice.actions;

export const selectRouteNumberOfPages = createSelector(
  (state) => state.route.totalCount,
  (state) => state.route.pageSize,
  (totalCount, pageSize) => Math.ceil(totalCount / pageSize)
);

export default routeSlice.reducer;
