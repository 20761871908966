import { useState } from "react";
import { ListItemButton, ListItemIcon, Collapse, List, ListItemText } from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

const SidebarNestedLink = ({ title, icon, children, testid}) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <ListItemButton
        onClick={handleClick}
        data-testid={testid}
        className={open ? "open" : ""}
        sx={{
          color: "#8091A7",
          "&.open": {
            marginBottom: "10px",
          },
          "&.open span": {
            color: "#000",
          },
          "&.open .MuiListItemIcon-root": {
            color: "#000",
          },
        }}
      >
        <ListItemIcon sx={{ minWidth: "auto", color: "#8091A7", mr: "12px" }}>{icon}</ListItemIcon>
        <ListItemText
          primary={title}
          sx={{
            "& span": {
              fontSize: "15px",
              fontWeight: "bold",
              letterSpacing: "0.16px",
            },
          }}
        />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding sx={{ marginTop: "-10px" }}>
          {children}
        </List>
      </Collapse>
    </>
  );
};

export default SidebarNestedLink;
