import api from "./api";
import { orderingToApi, filtersToApi } from "./common";
export async function listTerritories(
  pageSize,
  page,
  orderBy,
  filterBy,
  search_params
) {
  const orderByString = orderingToApi(orderBy);
  const filterByString = filtersToApi(filterBy, search_params);
  const params = `page_size=${pageSize}&page=${page}&ordering=${orderByString}&${filterByString}`;
  const result = await api.get(`/api/accounts/territories/?${params}`);
  return result.data;
}

export function postTerritory(payload) {
  return api.post(`/api/accounts/territories/`, payload);
}

export function deleteTerritory(id) {
  return api.delete(`/api/accounts/territories/${id}/`);
}

export async function updateTerritoryApi(id, data) {
  return api.patch(`/api/accounts/territories/${id}/`, data);
}

export function getTerritory(id) {
  return api.get(`/api/accounts/territories/${id}/`);
}

export function getTerritoryOverrides(id) {
  return api.get(`/api/accounts/territories/${id}/overrides/`);
}

export function postTerritoryOverride(territoryId, payload) {
  return api.post(
    `/api/accounts/territories/${territoryId}/overrides/`,
    payload
  );
}

export function patchTerritoryOverride(territoryId, overrideId, payload) {
  return api.patch(
    `/api/accounts/territories/${territoryId}/overrides/${overrideId}/`,
    payload
  );
}

export function deleteTerritoryOverride(territoryId, overrideId) {
  return api.delete(
    `/api/accounts/territories/${territoryId}/overrides/${overrideId}/`
  );
}

export async function listCustomerGroups(search = "") {
  const res = await api.get(`/api/accounts/customer_groups/?search=${search}`);
  return res;
}
