import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  OutlinedInput,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import DataTable from "../DataTable";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Spinner from "../Spinner";
import NoOptionsBlock from "./NoOptionsBlock";
import { formatAgGridFilters } from "../../distflowAPI/common";
import {
  deleteTransaction,
  listTransactions,
} from "../../distflowAPI/invoicesApi";
import dayjs from "dayjs";
import { useSearchDebounce } from "../../utils";
import ArrowUpwardOutlinedIcon from "@mui/icons-material/ArrowUpwardOutlined";
import ArrowDownwardOutlinedIcon from "@mui/icons-material/ArrowDownwardOutlined";
import DatePicker2 from "../DatePicker2";
import { DATE_FORMAT } from "../../utils/constants";
import { CreateCredit } from "../../pages/transactions";
import ConfirmDialog from "../ConfirmDialog";
import { downloadFile } from "../../distflowAPI/downloadFile";
import DatePickerCustom2 from "../DatePicker2";
import { getNoPages } from "../../utils";
const defaultColDef = { flex: 1, minWidth: 100 };

const CustomerTransactions = ({ customerId }) => {
  const { id } = useParams();
  console.log(
    "customer id is: ",
    id,
    formatAgGridFilters({ account__customer__id: id })
  );
  console.log(
    "customer formatted filter is: ",
    formatAgGridFilters({
      account__customer__id: {
        value: id,
        filterType: "equals",
        filter: id,
      },
    })
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const [payments, setPayments] = useState([]);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [filterBy, setFilterBy] = useState({});
  const [orderBy, setOrderBy] = useState([]);
  const [paymentsLoading, setPaymentsLoading] = useState(false);
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [transactionToRemove, setTransactionToRemove] = useState(false);
  const transactionsColumns = [
    {
      headerName: "id",
      field: "id",
      flex: 1,
    },
    {
      headerName: "Account",
      valueGetter: (params) =>
        `${params.data.account?.name || params.data.customer?.name}`,
      flex: 4,
    },
    {
      headerName: "Method",
      field: "method",
      //valueGetter: (params)=>
      valueGetter: (params) =>
        params.data?.method == "CHEQUE"
          ? "check"
          : params.data?.method
              .replace("_", " ")
              .toLowerCase()
              .replace("customer ", ""),
      flex: 2,
    },
    {
      headerName: "date",
      valueGetter: (params) =>
        dayjs(params.data.payment_date, "YYYY-MM-DD").format("MMM DD, YYYY"),
      flex: 2,
    },

    {
      headerName: "Amount",
      field: "amount_paid",
      valueGetter: (params) => `$${params.data.amount_paid}`,
      flex: 2,
    },
    {
      headerName: "Reference",
      field: "reference",
      valueGetter: (params) => `${params.data.reference || "-"}`,
      flex: 2,
    },
    {
      headerName: "Payment",
      valueGetter: (params) =>
        params.data.group ? `#${params.data.group}` : "Not applied",
      flex: 2,
    },
    {
      headerName: "Memo",
      field: "notes",
      valueGetter: (params) => params.data.notes || "-",
      flex: 4,
    },
    {
      headerName: "Actions",
      flex: 1,
      cellRenderer: (params) => {
        if (params && params.data && !params.data.group) {
          return (
            <Button onClick={() => setTransactionToRemove(params.data)}>
              Delete
            </Button>
          );
        }
        return <></>;
      },
    },
  ];

  async function fetchPayments() {
    const res = await listTransactions(
      page,
      pageSize,
      formatAgGridFilters({
        ...filterBy,
        customer__id: {
          value: id,
          filterType: "equals",
          filter: id,
        },
      }),
      orderBy,
      searchParams
    );
    setPayments(res.data.results);
    setNumberOfPages(getNoPages(res.data.count, pageSize));
    return res;
  }

  useEffect(() => {
    fetchPayments();
  }, [pageSize, page, filterBy, orderBy, searchParams]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const navigate = useNavigate();
  const [createCreditOpen, setCreateCreditOpen] = useState(false);
  function handleDeleteTransaction() {
    if (transactionToRemove && transactionToRemove.id) {
      deleteTransaction(transactionToRemove.id).then((res) => {
        fetchPayments()
          .then((res) => {})
          .finally(() => setTransactionToRemove(false));
      });
    } else {
      setTransactionToRemove(false);
    }
  }
  const [exportCreditOpen, setExportCreditOpen] = useState(false);
  return (
    <Box>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        mb={3}
      >
        <ConfirmDialog
          title="Confirm Transaction Removal"
          open={transactionToRemove}
          setOpen={setTransactionToRemove}
          onConfirm={handleDeleteTransaction}
          autoClose={false}
        >
          {transactionToRemove && (
            <Stack>
              <Typography>
                You're about to remove the following transaction, this can not
                be undone
              </Typography>
              <Typography>
                <b>Account</b>: {transactionToRemove?.account?.name}
              </Typography>
              <Typography>
                <b>Payment Method</b>: {transactionToRemove?.method}{" "}
                <b>Reference</b>: #{transactionToRemove.reference}
              </Typography>
              <Typography>
                <b>Payment Date</b>:{" "}
                {dayjs(transactionToRemove?.payment_date, "YYYY-MM-DD").format(
                  "MMM DD, YYYY"
                )}
              </Typography>
              <Typography>
                <b>Amount</b>: ${transactionToRemove?.amount_paid}
              </Typography>
              <Typography>
                <b>Memo</b>: {transactionToRemove?.notes}
              </Typography>
            </Stack>
          )}
        </ConfirmDialog>
        <CreateCredit
          open={createCreditOpen}
          setOpen={setCreateCreditOpen}
          customerId={id}
          refreshTable={fetchPayments}
        />
        <ExportCreditsModal
          open={exportCreditOpen}
          setOpen={setExportCreditOpen}
          customerId={id}
        />
        <Typography component="p" variant="h6">
          Customer Transactions
        </Typography>
        <Stack direction="row" spacing={1}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setCreateCreditOpen(true)}
          >
            Create Credit
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setExportCreditOpen(true)}
          >
            Export credits
          </Button>
        </Stack>
      </Stack>
      {(!!payments.length || searchParams.toString() !== "") && (
        <TransactionsFilters />
      )}
      {paymentsLoading ? (
        <Box
          height="200px"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Spinner size="lg" />
        </Box>
      ) : payments.length ? (
        <DataTable
          rowData={payments}
          columnDefs={transactionsColumns}
          defaultColDef={defaultColDef}
          rowHeight={40}
          page={page}
          pageSize={pageSize}
          loading={paymentsLoading}
          onFilterChanged={(event) => setFilterBy(event.api.getFilterModel())}
          setPageSize={(pageSize) => setPageSize(pageSize)}
          noPages={numberOfPages}
          setPage={(page) => setPage(page)}
        />
      ) : (
        <NoOptionsBlock
          option="payments"
          isFiltered={searchParams.keys().next().done === false}
        />
      )}
    </Box>
  );
};

export default CustomerTransactions;

const TransactionsFilters = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [filterDB, setFilterDB] = useSearchDebounce();
  const [amountWay, setAmountWay] = useState("amount_paid_gte");
  const [paymentDateWay, setPaymentDateWay] = useState("payment_date__gte");
  const [balanceWay, setBalanceWay] = useState("balance__gt");

  const [filters, setFilters] = useState({
    account__name: searchParams.get("account__name") || "",
    [amountWay]:
      searchParams.get("amount_paid_gte") ||
      searchParams.get("amount_paid_lte") ||
      "",
    [paymentDateWay]:
      searchParams.get("payment_date__gte") ||
      searchParams.get("payment_date__lte") ||
      "",
    [balanceWay]:
      searchParams.get("balance__gt") || searchParams.get("balance__lt") || "",
    method: searchParams.get("method") || "",
  });

  const handleChange = (value) => {
    setSearchParams((params) => {
      const newParams = new URLSearchParams(params);
      Object.keys(value).forEach((key) => {
        if (!!value[key]) {
          newParams.set(key, value[key]);
        } else {
          newParams.delete(key);
        }
      });
      return newParams;
    });
  };

  useEffect(() => {
    const isSearchParamsEmpty = Array.from(searchParams.values()).every(
      (value) => value === null || value === ""
    );
    if (isSearchParamsEmpty) {
      setFilters({
        account__name: "",
        [amountWay]: "",
        [paymentDateWay]: "",
        [balanceWay]: "",
        method: "",
      });
    }
  }, [searchParams]);

  useEffect(() => {
    setFilterDB(filters);
  }, [filters]);

  useEffect(() => {
    handleChange(filterDB);
  }, [filterDB]);

  const handleWayChange = (way, field) => {
    const reverse = {
      balance: way === "balance__gt" ? "balance__lt" : "balance__gt",
      amount: way === "amount_paid_gte" ? "amount_paid_lte" : "amount_paid_gte",
      paymentDate:
        way === "payment_date__gte" ? "payment_date__lte" : "payment_date__gte",
    }[field];

    if (field === "balance") {
      setBalanceWay(way);
    } else if (field === "amount") {
      setAmountWay(way);
    } else if (field === "paymentDate") {
      setPaymentDateWay(way);
    }

    setFilters((prev) => ({
      ...prev,
      [way]: prev[reverse],
      [reverse]: prev[way],
    }));
  };

  return (
    <Grid container spacing={1} rowGap="10px">
      <Grid item xs={12} sm={4}>
        <Typography mb="5px">Account</Typography>
        <OutlinedInput
          size="small"
          sx={{ height: "40px" }}
          fullWidth
          value={filters.account__name}
          onChange={(e) =>
            setFilters({ ...filters, account__name: e.target.value })
          }
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Typography mb="5px">Total</Typography>
        <Stack direction={"row"} gap={1}>
          <OutlinedInput
            size="small"
            sx={{ height: "40px" }}
            fullWidth
            value={filters[amountWay]}
            type="number"
            onChange={(e) =>
              setFilters({ ...filters, [amountWay]: e.target.value })
            }
            endAdornment={
              <Box
                sx={{
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                }}
                onClick={() =>
                  handleWayChange(
                    amountWay === "amount_paid_gte"
                      ? "amount_paid_lte"
                      : "amount_paid_gte",
                    "amount"
                  )
                }
              >
                {amountWay === "amount_paid_gte" ? (
                  <ArrowUpwardOutlinedIcon fontSize="small" />
                ) : (
                  <ArrowDownwardOutlinedIcon fontSize="small" />
                )}
              </Box>
            }
          />
        </Stack>
      </Grid>
      <Grid
        item
        className="date-picker"
        sx={{ width: { sm: "220px", xs: "100%" } }}
      >
        <Stack direction="row" gap={1} alignItems="center" mb="5px">
          <Typography>Payment date</Typography>
          <Box
            sx={{
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
            }}
            onClick={() =>
              handleWayChange(
                paymentDateWay === "payment_date__gte"
                  ? "payment_date__lte"
                  : "payment_date__gte",
                "paymentDate"
              )
            }
          >
            {paymentDateWay === "payment_date__gte" ? (
              <ArrowUpwardOutlinedIcon fontSize="small" />
            ) : (
              <ArrowDownwardOutlinedIcon fontSize="small" />
            )}
          </Box>
        </Stack>
        <DatePicker2
          controlledValue={
            paymentDateWay ? dayjs.utc(filters[paymentDateWay]) : null
          }
          setControlledValue={(newValue) => {
            setFilters({
              ...filters,
              [paymentDateWay]: dayjs(newValue).format(DATE_FORMAT),
            });
          }}
          additionlStyles={{
            height: "40px",
            width: "100%",
            "& .MuiInputBase-root.Mui-error .MuiOutlinedInput-notchedOutline": {
              borderColor: "#DBDFEA",
            },
          }}
        />
      </Grid>
      <Grid xs={12} item>
        <Typography mb="5px">Payment method</Typography>
        <Stack direction={"row"} spacing={2} alignItems={"center"}>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            sx={{ border: "none !important", padding: "0 !important" }}
            value={filters.method}
            onChange={(e) => {
              setFilters({ ...filters, method: e.target.value });
            }}
          >
            <FormControlLabel value="" control={<Radio />} label="All" />
            <FormControlLabel
              value="CREDIT_CARD"
              control={<Radio />}
              label="Credit card"
            />
            <FormControlLabel
              value="QUICK_PAY"
              control={<Radio />}
              label="Quick Pay"
            />
            <FormControlLabel value="CASH" control={<Radio />} label="Cash" />
            <FormControlLabel value="ACH" control={<Radio />} label="ACH" />
            <FormControlLabel
              value="CUSTOMER_CREDIT"
              control={<Radio />}
              label="Customer credit"
            />
            <FormControlLabel
              value="CHEQUE"
              control={<Radio />}
              label="Check"
            />
            <FormControlLabel
              value="WIRE_TRANSFER"
              control={<Radio />}
              label="Wire transfer"
            />
          </RadioGroup>

          {filters.method == "CUSTOMER_CREDIT" && (
            <>
              <Typography mt={2}>|</Typography>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                sx={{ border: "none !important", padding: "0 !important" }}
                value={filters.used}
                onChange={(e) => {
                  setFilters({ ...filters, used: e.target.value });
                }}
              >
                <FormControlLabel value="" control={<Radio />} label="All" />
                <FormControlLabel
                  value="false"
                  control={<Radio />}
                  label="Not applied"
                />
                <FormControlLabel
                  value="true"
                  control={<Radio />}
                  label="Applied"
                />
              </RadioGroup>
            </>
          )}
        </Stack>
      </Grid>
    </Grid>
  );
};

function ExportCreditsModal({ open, setOpen, customerId }) {
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  return (
    <Dialog open={open}>
      <DialogTitle>Export credits</DialogTitle>
      <DialogContent>
        <Stack direction="row" spacing={1}>
          <DatePickerCustom2
            controlledValue={startDate}
            setControlledValue={setStartDate}
            label="Start Date"
          />
          <DatePickerCustom2
            controlledValue={endDate}
            setControlledValue={setEndDate}
            label="End Date"
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack direction={"row"} spacing={1}>
          <Button
            variant="contained"
            color="success"
            onClick={() => {
              let url = `/api/accounting/export_credits/?customer_id=${customerId}`;
              if (startDate) {
                url += `&start_date=${startDate.format("YYYY-MM-DD")}`;
              }
              if (endDate) {
                url += `&end_date=${endDate.format("YYYY-MM-DD")}`;
              }
              downloadFile(url);
            }}
          >
            Generate
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => setOpen(false)}
          >
            Cancel
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
