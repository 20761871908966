import { NavLink, useNavigate } from "react-router-dom";
import { Box, IconButton, Link, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { deleteProductVariant } from "../../distflowAPI/products";
import { fireErrorNotification, fireSuccessNotification, fixedNumber } from "../../utils";
import ConfirmDialog from "../../components/ConfirmDialog";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import ChangesHistory from "../../components/History";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import HtmlTooltip from "../../components/HtmlToolTip";

const infoFontSize = "14px";
const defaultCellStyle = { "padding-left": "10px", "padding-right": "10px" };

const VariantRenderer = (props) => {

  return (
    <Stack direction="row" alignItems="center" gap="14px">
      <Box sx={{ maxWidth: "50px", "& img": { width: "100%" } }}>
        <img src={props.value.img} alt="" />
      </Box>
      <Link
        to={`/products/${props.data.pid}/variants/${props.data.id}`}
        component={NavLink}
        sx={{ color: "#535151", fontWeight: 600, fontSize: infoFontSize }}
      >
        {" "}
        {props.value.name}
      </Link>
    </Stack>
  );
};

const SkuRenderer = (params) => {
  return (
    <Typography sx={{ color: "#1D1C1C", fontSize: infoFontSize }}>
      {params.value}
    </Typography>
  );
};

const CostRenderer = (params) => {
  return (
    <Stack>
      <Typography sx={{ color: "#1D1C1C", fontSize: infoFontSize }}>
        {params.value.value}
      </Typography>
      <Link
        to={`/products/${params.data.pid}/variants/${params.data.id}`}
        component={NavLink}
        sx={{
          color: "#535151",
          fontSize: "9px",
          textDecorationColor: "#535151"
        }}
      >
        {params.value.link}
      </Link>
    </Stack>
  );
};

const UpchargeRenderer = (params) => {

  const categoryOverride = Number(params.data?.overrides?.category).toFixed(2);
  const vendorOverride = Number(params.data?.overrides?.vendor).toFixed(2);
  const globalOverride = Number(params.data?.overrides?.global).toFixed(2);
  const variantOverride = Number(params.data?.overrides?.variant).toFixed(2);
  const sum = +categoryOverride + +vendorOverride + +globalOverride + +variantOverride;
  return (
    <Stack direction="row" alignItems="center" gap="10px" pl={2}>
      {params.data.variantId ?
        <HtmlTooltip
          title={
            <div>
              <Typography color="inherit">
                Category upcharge: {categoryOverride}%
              </Typography>
              <Typography color="inherit">
                Vendor upcharge: {vendorOverride}%
              </Typography>
              <Typography color="inherit">
                Global upcharge: {globalOverride}%
              </Typography>
              <Typography>
                Variant upcharge: {variantOverride}%
              </Typography>
            </div>
          }
        >
          <Typography sx={{ color: "#313131", fontSize: infoFontSize }}>
            {sum.toFixed(2)}%
          </Typography>
        </HtmlTooltip>
        : "---"
      }
    </Stack>
  );
};

const PriceRenderer = (params) => {
  const categoryOverride = fixedNumber(params.data?.overrides?.category);
  const vendorOverride = fixedNumber(params.data?.overrides?.vendor);
  const globalOverride = fixedNumber(params.data?.overrides?.global);
  const variantOverride = fixedNumber(params.data?.overrides?.variant);
  const sum = +categoryOverride + +vendorOverride + +globalOverride + +variantOverride;
  const cost = +params.data.cost.value.substring(1)
  const price = fixedNumber(cost + (cost * sum / 100))

  return (
    <Stack>  
      <Typography sx={{ color: "#313131", fontSize: infoFontSize }}>
        {params.data.variantId ? `$${price}` : "---"}
    </Typography>
    </Stack>
  );
};

const ActionRenderer = (params) => {
  const navigate = useNavigate();
  const [isOpenDelete, setIsOpenDelete] = useState(false);

  const handleDelete = () => {
    if (params.data.variantId) {
      deleteProductVariant(params.data.pid, params.data.id).then(() => {
        fireSuccessNotification(`Product variant deleted successfully`);
        navigate(-1);
      }).catch(() => {
        fireErrorNotification("Protected Error, you can't delete this product variant");
      });
    } else {
      params.data.setVariants(prev => {
        const newVars = { ...prev };
        delete newVars[params.data.id];
        return newVars;
      });
    }
  };

  const handleUpdate = () => {
    if (params.data.variantId) {
      navigate(`/products/${params.data.pid}/variants/${params.data.id}`);
    } else {
      params.data.editFunction();
    }
  };

  return (
    <>
      <ConfirmDialog
        title={`Delete product variant?`}
        open={isOpenDelete}
        setOpen={setIsOpenDelete}
        onConfirm={handleDelete}
      >
        {`Are you sure you want to delete this product variant?`}
      </ConfirmDialog>
      <IconButton onClick={handleUpdate}>
        <EditOutlinedIcon color="basic" />
      </IconButton>
      <ChangesHistory historyData={params.data.history || []} />
      <IconButton onClick={() => setIsOpenDelete(true)}>
        <DeleteForeverOutlinedIcon />
      </IconButton>
    </>
  );
};

const RestrictionRenderer = (params) => {
  return (
    <Link
      to=""
      component={NavLink}
      sx={{
        color: "#252525",
        textDecorationColor: "#252525",
        fontSize: infoFontSize
      }}
    >
      {params.value}
    </Link>
  );
};

const variantConfigTableColumns = [
  {
    headerName: "Variant",
    field: "variant",
    minWidth: 240,
    cellRenderer: VariantRenderer,
    cellStyle: defaultCellStyle
  },
  {
    headerName: "SKU",
    field: "sku",
    maxWidth: 100,
    cellRenderer: SkuRenderer,
    cellStyle: defaultCellStyle
  },
  {
    headerName: "Cost",
    field: "cost",
    maxWidth: 80,
    cellRenderer: CostRenderer,
    cellStyle: defaultCellStyle
  },
  {
    headerName: "Upcharge",
    field: "upcharge",
    cellRenderer: UpchargeRenderer,
    minWidth: 110,
    cellStyle: defaultCellStyle
  },
  {
    headerName: "Price",
    field: "price",
    minWidth: 110,
    cellRenderer: PriceRenderer,
    cellStyle: defaultCellStyle
  },
  {
    headerName: "Restrictions",
    field: "restrictions",
    minWidth: 120,
    cellRenderer: RestrictionRenderer,
    cellStyle: defaultCellStyle
  },
  {
    headerName: "",
    field: "actions",
    minWidth: 120,
    cellRenderer: ActionRenderer,
    cellStyle: defaultCellStyle
  }
];

export default variantConfigTableColumns;
