import dayjs from "dayjs";
export const SUPPORTED_METHODS = [
  {
    key: "CREDIT_CARD",
    value: "CREDIT_CARD",
    label: "Credit Card",
  },
  {
    key: "CHEQUE",
    value: "CHEQUE",
    label: "Check",
  },
  {
    key: "WIRE_TRANSFER",
    value: "WIRE_TRANSFER",
    label: "Wire Transfer",
  },
  {
    key: "QUICK_PAY",
    value: "QUICK_PAY",
    label: "Quick Pay",
  },
  {
    key: "CASH",
    value: "CASH",
    label: "Cash",
  },
  {
    key: "ACH",
    value: "ACH",
    label: "ACH",
  },
  {
    key: "BARTER",
    value: "BARTER",
    label: "Barter",
  },
  {
    key: "NONE",
    value: "NONE",
    label: "No Payment",
  },
];

export const INIT_PAYMENT = {
  id: null,
  account: {
    value: null,
    label: "",
  },
  customer: {
    value: null,
    label: "",
  },
  notes: "",
  payment_date: dayjs(),
  payment_level: "account",
  applied_credits: {},
  payment_data: {
    method: "NONE",
    amount_paid: 0,
    reference: null,
    cc_digits: null,
    fee_percent: 0,
    notes: "",
  },
  credits_to_include: [],
  credits_to_create: [],
  included_invoices: [],
  open_redeem_credit: false,
  open_select_invoices: false,
};

export const REDUCER_ACTIONS = {
  SET_VAL: "SET_VAL",
  INCLUDE_INVOICE: "INCLUDE_INVOICE",
  SET_PAID_AMOUNT: "SET_PAID_AMOUNT",
  EXCLUDE_INVOICE: "EXCLUDE_INVOICE",
  ALTER_PAYMENT_VAL: "ALTER_PAYMENT_VAL",
  INCLUDE_CREDIT: "INCLUDE_CREDIT",
  EXLUDE_CREDIT: "EXLUDE_CREDIT",
  CREATE_CREDIT: "CREATE_CREDIT",
  REMOVE_CREDIT: "REMOVE_CREDIT",
  UPDATE_CREDIT: "UPDATE_CREDIT",
};

export function reducer(state, action) {
  switch (action.type) {
    case REDUCER_ACTIONS.SET_VAL: {
      return { ...state, [action.key]: action.value };
    }
    case REDUCER_ACTIONS.INCLUDE_INVOICE: {
      const newInvoices = [...state.included_invoices, action.invoice];
      return { ...state, included_invoices: newInvoices };
    }
    case REDUCER_ACTIONS.SET_PAID_AMOUNT: {
      const newInvoices = [...state.included_invoices];
      newInvoices[action.index]["amount"] = action.amount;
      return { ...state, included_invoices: newInvoices };
    }
    case REDUCER_ACTIONS.EXCLUDE_INVOICE: {
      return {
        ...state,
        included_invoices: state.included_invoices.filter(
          (invoice) => invoice.invoice !== action.id,
        ),
      };
    }
    case REDUCER_ACTIONS.ALTER_PAYMENT_VAL: {
      const newPaymentData = {
        ...state.payment_data,
        [action.key]: action.value,
      };
      return { ...state, payment_data: newPaymentData };
    }
    case REDUCER_ACTIONS.INCLUDE_CREDIT: {
      const newAppliedCredits = { ...state.applied_credits };
      newAppliedCredits[action.credit.id] = action.credit;

      return { ...state, applied_credits: newAppliedCredits };
    }
    case REDUCER_ACTIONS.EXLUDE_CREDIT: {
      const newAppliedCredits = {};
      Object.keys(state.applied_credits).map((k) => {
        if (k != action.id) {
          newAppliedCredits[k] = state.applied_credits[k];
        }
      });
      return { ...state, applied_credits: newAppliedCredits };
    }
    case REDUCER_ACTIONS.CREATE_CREDIT: {
      return {
        ...state,
        credits_to_create: [...state.credits_to_create, action.data],
      };
    }
    case REDUCER_ACTIONS.REMOVE_CREDIT: {
      const newCredits = [];
      state.credits_to_create.map((credit, index) => {
        if (index != action.index) {
          newCredits.push(credit);
        }
      });
      return { ...state, credits_to_create: newCredits };
    }
    case REDUCER_ACTIONS.UPDATE_CREDIT: {
      const newCredits = [...state.credits_to_create];
      newCredits[action.index][action.key] = action.value;
      return { ...state, credits_to_create: newCredits };
    }
  }
}

export function getTotalInvoices(data) {
  return data.included_invoices.reduce(
    (total, invoice) => total + Number(invoice.invoice_amount),
    0,
  );
}

export function getTotalAllocated(data) {
  return data.included_invoices.reduce(
    (total, invoice) => total + Number(invoice.amount),
    0,
  );
}

export function getTotalCredit(data) {
  return (
    Object.keys(data.applied_credits)
      .map((ac) => data.applied_credits[ac])
      .reduce((total, credit) => total + Number(credit.amount_paid), 0) +
    data.credits_to_create.reduce(
      (total, credit) => total + Number(credit.amount_paid),
      0,
    )
  );
}

export function getCCFees(data) {
  return Object.keys([data.payment_data])
    .filter((ac) => {
      return [data.payment_data][ac].method == "CREDIT_CARD";
    })
    .map((ac) => [data.payment_data][ac])
    .reduce(
      (total, transaction) =>
        total +
        (Number(transaction.amount_paid) / 100) *
          (transaction.fee_percent || 0),
      0,
    );
}

export function getGrandTotal(data) {
  return getTotalAllocated(data) - getTotalCredit(data);
}

export function getGrandTotalWF(data) {
  return getTotalAllocated(data) + getCCFees(data) - getTotalCredit(data);
}

export function getTotalPayments(data) {
  return Object.keys([data.payment_data])
    .map((ac) => [data.payment_data][ac])
    .reduce((total, transaction) => total + Number(transaction.amount_paid), 0);
}

export function getBalance(data) {
  return getGrandTotalWF(data) - getTotalPayments(data);
}
