import api from "./api";
import axios from "axios";

export function downloadFile(url, fileName = "file.pdf", no_auth = false) {
  if (url.includes("amazonaws.com")) {
    no_auth = true;
  }
  let res;
  if (no_auth) {
    res = axios.get(url, {
      responseType: "blob",
    });
  } else {
    res = api.get(url, {
      responseType: "blob",
    });
  }

  return res.then((blob) => {
    // Create blob link to download
    const url = window.URL.createObjectURL(new Blob([blob.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
  });
}
