import {
    Box,
    IconButton,
    Typography,
    useTheme,
    useMediaQuery,
    Dialog,
    DialogContent,
    DialogTitle,
    Stack,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Clock from "../../images/clock.svg";
import Eye from "../../images/eye.svg";
import useAppSelector from "../../hooks/useAppSelector";

import dayjs from 'dayjs';
import SimpleMap from "../../components/SimpleMap";
import DeliveryAccount from "./DeliveryAccount";

const DeliveryModal = (props) => {
    const currentRouteReport = useAppSelector((state) => state.routeReports.selectedReport);

    const deliveries = useAppSelector((state) => state.routeReports.selectedReportDeliveries);
    const { total_delivered: deliveriesStart, total_deliveries: deliveriesEnd } = currentRouteReport;

    const keyedAccounts = useAppSelector((state) => state.account.accountMap);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const { ...restProps } = props;

    return (
        <Dialog
            {...restProps}
            fullScreen={fullScreen}
            maxWidth="xs"
            sx={{ "& .MuiPaper-root": { width: "100%", maxWidth: "557px" }, zIndex: 1500 }}
        >
            <DialogTitle sx={{ textAlign: "center", fontSize: "24px", fontWeight: 600, color: "#252525", paddingBottom: 0 }}>
                Deliveries
            </DialogTitle>
            <Typography textAlign="center" sx={{ color: "#535151" }}>
                {deliveriesStart} of {deliveriesEnd}
            </Typography>
            <IconButton
                aria-label="close"
                onClick={() => props.onClose()}
                sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent sx={{ display: "flex", flexDirection: "column", gap: "12px" }}>
                {deliveries?.map((delivery) => (
                    <Accordion
                        key={delivery.account}
                        sx={{
                            boxShadow: "none",
                            border: "1px solid #DBDFEA",
                            borderRadius: "5px",
                            "&.Mui-expanded": {
                                margin: 0,
                            },
                            "&::before": {
                                content: "none",
                            },
                            "& .MuiAccordionSummary-root.Mui-expanded": {
                                minHeight: "49px",
                            },
                            "& .MuiAccordionSummary-content.Mui-expanded": {
                                margin: 0,
                            },
                        }}
                    >
                        <AccordionSummary
                            id={delivery.account}
                            sx={{
                                "& .MuiAccordionSummary-content": { display: "grid", gridTemplateColumns: "repeat(3, 1fr)" },
                            }}
                        >
                            <DeliveryAccount status={delivery.state}>{keyedAccounts[delivery.account]?.name}</DeliveryAccount>
                            {delivery.state === "DELIVERED" ? (
                                <>
                                    <Stack direction="row" justifyContent="center" alignpmnItems="center">
                                        <img src={Clock} alt="" />
                                        <Typography sx={{ color: "#252525" }}>{dayjs(delivery.picked_up_timestamp).format('h:mm a')}</Typography>
                                    </Stack>
                                    <Stack direction="row" alignItems="center" justifyContent="flex-end" gap="5px">
                                        <Typography sx={{ color: "#252525" }}>Location</Typography>
                                        <img src={Eye} alt="" />
                                    </Stack>
                                </>
                            ) : null}
                        </AccordionSummary>
                        <AccordionDetails sx={{ padding: "0 14px 14px" }}>
                            <Box sx={{height: "250px"}}>
                                <SimpleMap location={{
                                    address: '1600 Amphitheatre Parkway, Mountain View, california.',
                                    lat: 37.42216,
                                    lng: -122.08427,
                                }}
                                    zoomLevel={2}
                                    height={250}
                                >

                                </SimpleMap>
                            </Box>
                        </AccordionDetails>
                    </Accordion>
                ))}
            </DialogContent>
        </Dialog>
    );
};

export default DeliveryModal;
