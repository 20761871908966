import {
    Typography,
    Stack,
} from "@mui/material";

import Delivery from "../../images/delivery.svg";

const PickupSummary = ({ pickup, started = null }) => {
    const uniqueItems = new Set(pickup.map(x => x.items__variant));
    const uniqueAccounts = new Set(pickup.map(x => x.account_id));

    return (
        <Stack direction="row" justifyContent="center" alignItems="center" alignContent="center">
            <img src={Delivery} alt="" style={{ marginRight: '5px' }} />
            <Typography sx={{ color: "#252525", marginRight: '5px' }}>{uniqueItems.size} item{uniqueItems.size > 1 ? 's' : ''} - </Typography>
            <Typography sx={{ color: "#252525" }}>{uniqueAccounts.size} account{uniqueAccounts.size > 1 ? 's' : ''}</Typography>
        </Stack>
    )
}

export default PickupSummary;