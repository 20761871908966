import { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import CustomTextField from "../../../components/CustomTextField";
import useAppDispatch from "../../../hooks/useAppDispatch";
import {
  createDriver,
  updateDriver,
  fetchDrivers,
} from "../../../redux/module/driver";

const initialValue = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
};

const AddDriverForm = ({ open, handleClose, driver }) => {
  const dispatch = useAppDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [driverInfo, setDriverInfo] = useState(initialValue);

  async function handleSubmit(event) {
    event.preventDefault();
    setIsSubmitting(true);
    const payload = {
      user: {
        first_name: driverInfo.firstName,
        last_name: driverInfo.lastName,
        email: driverInfo.email,
        password: driverInfo.password || undefined,
      },
    };

    let data;
    if (driver) {
      data = await dispatch(
        updateDriver({ driverId: driver.id, driverInfo: payload })
      );
    } else {
      data = await dispatch(createDriver(payload));
    }
    if (data.payload.id) {
      handleClose();
    }
    setIsSubmitting(false);
    dispatch(fetchDrivers(1, 50, [], []));
  }

  useEffect(() => {
    if (driver) {
      setDriverInfo({
        firstName: driver.user.first_name,
        lastName: driver.user.last_name,
        email: driver.user.email,
        password: "",
      });
    } else {
      setDriverInfo(initialValue);
    }
  }, [open]);

  const formFields = [
    // { id: 1, name: "userName", label: "User name", type: "text", value: userInfo.userName },
    {
      id: 2,
      name: "firstName",
      label: "First name",
      type: "text",
      value: driverInfo.firstName,
      required: true,
    },
    {
      id: 3,
      name: "lastName",
      label: "Last name",
      type: "text",
      value: driverInfo.lastName,
      required: true,
    },
    {
      id: 4,
      name: "email",
      label: "Email",
      type: "email",
      value: driverInfo.email,
      required: true,
    },
    {
      id: 5,
      name: "password",
      label: "Password",
      type: "password",
      value: driverInfo.password,
      required: !driver,
    },
  ];

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle sx={{ pb: 1 }}>
        {driver ? "Update" : "Create a new"} driver
      </DialogTitle>
      <form id="driverCreation" onSubmit={handleSubmit}>
        <DialogContent sx={{ pt: 1, pb: 1 }}>
          {formFields.map((el) => (
            <CustomTextField
              key={el.id}
              required={el.required}
              name={el.name}
              label={el.label}
              type={el.type}
              value={el.value}
              onChange={(e) =>
                setDriverInfo({ ...driverInfo, [el.name]: e.target.value })
              }
            />
          ))}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={isSubmitting}
          >
            {driver ? "Update" : "Create"} Driver
          </Button>
          <Button onClick={handleClose} variant="contained" color="error">
            Cancel
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddDriverForm;
