import React, { useEffect, useState } from "react";
import {
  Alert,
  Box,
  Button,
  Divider,
  Grid,
  Input,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import Block from "../../components/Block";
import useAppDispatch from "../../hooks/useAppDispatch";
import { deletePayment, updatePayment } from "../../redux/module/payment";
import AccountSelect from "../../components/AsyncSelects/AccountSelect";
import ConfirmDialog from "../../components/ConfirmDialog";
import UpdateItemTitle from "../../components/UpdateItemTitle";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { fixedNumber } from "../../utils";
import DatePickerCustom2 from "../../components/DatePicker2";
import dayjs from "dayjs";
import { createPayment, getPayment } from "../../distflowAPI/paymentsApi";
import Separator from "../../components/Separator";
import CustomerSelect from "../../components/AsyncSelects/CustomerSelect";
import InvoicesSelect from "./invoiceSelect";
import RedeemCredit from "./redeemCredit";
import PaymentSummary from "./paymentSummary";
import SelectedInvoices from "./selectedInvoices";

const UpdatePayment = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const [paymentsLoading, setPaymentsLoading] = useState(false);
  const [payment, setPayment] = useState({});
  const [isCreatePayment] = useState(id === "new");
  const [backButtonLink, setBackButtonLink] = useState("");
  const [isOpenDiscard, setIsOpenDiscard] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [transactions, setTransactions] = useState({ 0: { amount_paid: 0 } });
  const [appliedCredits, setAppliedCredits] = useState({});
  const [isOpenSelectInvoices, setIsOpenSelectInvoices] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [data, setData] = useState({
    account: {
      value: null,
      label: "",
    },
    customer: {
      value: null,
      label: "",
    },
    notes: "",
    payment_date: dayjs(),
  });
  const [errors, setErrors] = useState({});
  const [payments, setPayments] = useState([]);

  useEffect(() => {
    if (id && !isCreatePayment) {
      getPayment(id).then((res) => setPayment(res.data));
    }
  }, [id]);

  useEffect(() => {
    if (payment.id === +id) {
      setData({
        account: {
          value: payment.account.id,
          label: payment.account.name,
        },
        customer: {
          value: payment.customer.id,
          label: payment.customer.name,
        },
        amount_paid: payment.amount_paid,
        method: payment.method,
        notes: payment.notes || "",
        reference: payment.reference || "",
        payment_date: dayjs(payment.payment_date),
        match: payment.match,
      });
      setPayments(
        payment.payments.map((el) => ({
          invoice: el.invoice,
          amount: el.amount,
          remaining: el.remaining,
          invoice_amount: el.invoice_amount,
        }))
      );
    }
  }, [payment]);

  function excludeInvoice(id) {
    const filteredPayments = payments.filter(
      (invoice) => invoice.invoice !== id
    );
    setPayments(filteredPayments);
  }

  function includeInvoice(inv) {
    const newPayments = [...payments];
    newPayments.push({
      invoice: inv.id,
      amount: fixedNumber(inv.total_amount - inv.total_paid),
      remaining: fixedNumber(inv.total_amount - inv.total_paid),
      invoice_amount: inv.total_amount,
      total_paid: inv.total_paid,
      account: inv.account.name,
    });
    setPayments(newPayments);

    // add current invoice amount to general value
    // setData({...data, amount_paid: `${fixedNumber(+data.amount_paid + +fixedNumber(inv.total_amount - inv.total_paid))}` })
  }

  function setPaymentAmount(index, amount) {
    const newPayments = [...payments];
    newPayments[index]["amount"] = amount;
    setPayments(newPayments);

    // change general amount value after changes in current invoice
    const newData = { ...data };
    setData(newData);
  }

  function setValue(name, value) {
    setData({ ...data, [name]: value });
  }

  const handleDiscard = async () => {
    getPayment(id).then((res) => setPayment(res.data));
  };

  const handleDelete = async () => {
    await dispatch(deletePayment(id));
    navigate(-1);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrors({});
    if (isSaving) {
      return;
    }
    setIsSaving(true);
    const payload = { ...data, payments: [], transactions: [] };
    payload["payment_date"] = payload["payment_date"].format("YYYY-MM-DD");
    payload.account = data.account?.value;
    payload.customer = data.customer?.value;
    payments.map((invoice) =>
      payload.payments.push({
        invoice: invoice.invoice,
        amount: invoice.amount,
      })
    );
    Object.keys(transactions).map((tk) =>
      payload.transactions.push(transactions[tk])
    );
    Object.keys(appliedCredits).map((ac) => {
      payload.transactions.push({
        ...appliedCredits[ac],
        tid: appliedCredits[ac].id,
      });
    });
    if (isCreatePayment) {
      createPayment(payload)
        .then(
          (res) => {
            if (res.data.id) {
              navigate(`/invoices/payments/${res.data.id}/details`);
            }
          },
          (err) => {
            if (err.response.data && `${err.response.data}`?.length < 200) {
              setErrors(err.response.data);
            } else {
              setErrors({ error: "unexpected error" });
            }
          }
        )
        .finally(() => setIsSaving(false));
    } else {
      await updatePayment({ id: payment.id, payload });
      setIsSaving(false);
    }
  };

  function removeItem(obj, key) {
    const newObj = {};
    Object.keys(obj).map((k) => {
      if (k != key) {
        newObj[k] = obj[k];
      }
    });
    return newObj;
  }

  const [openRedeemCredit, setOpenRedeemCredit] = useState(false);

  const [paymentLevel, setPaymentLevel] = useState("account");
  useEffect(() => {
    if (paymentLevel == "account") {
      setData({ ...data, customer: { label: "", value: null } });
    } else {
      setData({ ...data, account: { label: "", value: null } });
    }
  }, [paymentLevel]);

  function getTotalAllocated() {
    return payments.reduce(
      (total, invoice) => total + Number(invoice.amount),
      0
    );
  }

  function getTotalCredit() {
    return Object.keys(appliedCredits)
      .map((ac) => appliedCredits[ac])
      .reduce((total, credit) => total + Number(credit.amount_paid), 0);
  }

  function getGrandTotal() {
    return getTotalAllocated() - getTotalCredit();
  }

  return (
    <Box maxWidth="1600px" margin=" 30px auto 0">
      <ConfirmDialog
        title="Discard updates?"
        open={isOpenDiscard}
        setOpen={setIsOpenDiscard}
        onConfirm={handleDiscard}
      >
        {`Are you sure you want to discard all changes?`}
      </ConfirmDialog>
      <ConfirmDialog
        title="Delete payment?"
        open={isOpenDelete}
        setOpen={setIsOpenDelete}
        onConfirm={handleDelete}
      >
        {`Are you sure you want to delete this payment?`}
      </ConfirmDialog>
      <form id="PaymentUpdate" onSubmit={handleSubmit}>
        <PaymentPageTitle
          isCreatePayment={isCreatePayment}
          setIsOpenDelete={setIsOpenDelete}
          setIsOpenDiscard={setIsOpenDiscard}
          backButtonLink={backButtonLink}
          paymentsLoading={paymentsLoading}
          navigate={navigate}
        />
        <Grid container columnSpacing={{ xs: 1, sm: "20px" }} margin="36px 0 0">
          <Grid item width="100%" xl={12} marginBottom={2}>
            <Block isLoading={paymentsLoading}>
              {Object.keys(errors).map((error) => (
                <Alert severity="error">
                  {error}:{errors[error]}
                </Alert>
              ))}
              <Typography component="p" variant="h6" mb={2}>
                Payment info
              </Typography>
              {payment &&
                payment.match &&
                payment.match.length > 0 &&
                id == payment?.id && <Alert>Matches Bank Deposit </Alert>}
              {payment &&
                payment.match &&
                payment.match.length == 0 &&
                id == payment?.id && (
                  <Alert severity="error">No Bank deposit match</Alert>
                )}
              <Box>
                <Grid
                  container
                  columnSpacing={{ xs: 1, sm: "5px" }}
                  margin="3px 0 0"
                >
                  <Grid item width="100%" xl={3} md={3} xs={12}>
                    <Box>
                      <Typography
                        fontSize="14px"
                        component="p"
                        variant="label"
                        mb="9px"
                      >
                        Payment level
                      </Typography>
                      <Select
                        fullWidth
                        value={paymentLevel}
                        onChange={(e) => setPaymentLevel(e.target.value)}
                        sx={{ maxHeight: 40 }}
                      >
                        <MenuItem value={"account"}>Account</MenuItem>
                        <MenuItem value={"customer"}>Customer</MenuItem>
                      </Select>
                    </Box>
                  </Grid>
                  {paymentLevel == "account" ? (
                    <Grid item width="100%" xl={6} md={6} xs={12}>
                      <Box>
                        <Typography
                          fontSize="14px"
                          component="p"
                          variant="label"
                          mb="9px"
                        >
                          Account
                        </Typography>
                        <AccountSelect
                          name="account"
                          disabled={!isCreatePayment}
                          required
                          value={data.account}
                          onChange={(_e, newValue) => {
                            if (newValue && newValue.value) {
                              setData({ ...data, account: newValue });
                            }
                          }}
                        />
                      </Box>
                    </Grid>
                  ) : (
                    <Grid item width="100%" xl={6} md={6} xs={12}>
                      <Box>
                        <Typography
                          fontSize="14px"
                          component="p"
                          variant="label"
                          mb="9px"
                        >
                          Customer
                        </Typography>
                        <CustomerSelect
                          name="customer"
                          disabled={!isCreatePayment}
                          required
                          value={data.customer}
                          onChange={(_e, newValue) => {
                            if (newValue && newValue.value) {
                              setData({ ...data, customer: newValue });
                            }
                          }}
                        />
                      </Box>
                    </Grid>
                  )}

                  <Grid item width="100%" xs={12} md={3} xl={3}>
                    <Typography variant="inputLabel" component="p">
                      Payment Date
                    </Typography>
                    <DatePickerCustom2
                      controlledValue={data.payment_date}
                      setControlledValue={(newVal) =>
                        setValue("payment_date", newVal)
                      }
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box>
                <Separator />
              </Box>
              <Stack
                direction={"row"}
                spacing={2}
                divider={<Divider orientation="vertical" flexItem />}
              >
                {/* Payment block*/}
                <Grid container spacing={2}>
                  <Grid item md={12}>
                    <Stack
                      direction="row"
                      spacing={2}
                      alignItems={"center"}
                      justifyItems={"center"}
                    >
                      <Typography
                        fontSize={20}
                        fontWeight={600}
                        marginBottom="10px"
                        marginTop="10px"
                      >
                        Payment details
                      </Typography>
                    </Stack>
                  </Grid>
                  {Object.keys(transactions).map((tk) => (
                    <Grid item md={12}>
                      <TransactionInput
                        val={transactions[tk]}
                        setVal={(val) => {
                          setTransactions({
                            ...transactions,
                            [tk]: val,
                          });
                        }}
                        delItem={() => {
                          setTransactions(removeItem(transactions, tk));
                        }}
                        getBalance={getGrandTotal}
                        canAdd={false}
                      />
                    </Grid>
                  ))}
                </Grid>
                {/* Credits block*/}
                <Grid container spacing={2}>
                  <Grid item md={12}>
                    <Stack
                      direction={"row"}
                      spacing={1}
                      alignItems={"center"}
                      justifyItems={"center"}
                    >
                      <Typography
                        fontSize={20}
                        fontWeight={600}
                        marginBottom="10px"
                      >
                        Credits
                      </Typography>
                      <Button
                        variant="outlined"
                        onClick={() => setOpenRedeemCredit(true)}
                        sx={{
                          display: "flex",
                          flexShrink: 0,
                          justifyContent: "center",
                          alignItems: "center",
                          padding: "4px",
                          fontSize: "12px",
                          minWidth: "30px",
                          height: "25px !important",
                        }}
                      >
                        Redeem Credit
                      </Button>
                      <Typography>OR</Typography>
                      <Button
                        onClick={() => {
                          setAppliedCredits({
                            ...appliedCredits,
                            [Object.keys(appliedCredits).length + 1]: {},
                          });
                        }}
                        variant={"outlined"}
                        sx={{
                          display: "flex",
                          flexShrink: 0,
                          justifyContent: "center",
                          alignItems: "center",
                          padding: "4px",
                          fontSize: "12px",
                          minWidth: "30px",
                          height: "25px !important",
                        }}
                      >
                        Create credit
                      </Button>
                    </Stack>
                  </Grid>
                  {Object.keys(appliedCredits).length == 0 && (
                    <Grid item md={12}>
                      <Stack
                        direction={"row"}
                        spacing={2}
                        justifyItems={"center"}
                        alignItems={"center"}
                      >
                        <Typography>No credits applied yet</Typography>
                      </Stack>
                    </Grid>
                  )}
                  {Object.keys(appliedCredits).map((tk) => (
                    <Grid item md={12}>
                      <TransactionInput
                        val={appliedCredits[tk]}
                        setVal={(val) => {
                          setAppliedCredits({
                            ...appliedCredits,
                            [tk]: val,
                          });
                        }}
                        delItem={() => {
                          setAppliedCredits(removeItem(appliedCredits, tk));
                        }}
                        isCredit={true}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Stack>
              <Box>
                <Separator />
              </Box>
              <Grid container spacing={2}>
                <Grid item md={12}>
                  <SelectedInvoices
                    data={data}
                    payments={payments}
                    excludeInvoice={excludeInvoice}
                    setIsOpenSelectInvoices={setIsOpenSelectInvoices}
                    setPaymentAmount={setPaymentAmount}
                  />
                </Grid>

                <PaymentSummary
                  payments={payments}
                  appliedCredits={appliedCredits}
                  transactions={transactions}
                />

                <Grid item md={12}>
                  <Separator />
                  <Typography
                    fontSize={20}
                    fontWeight={600}
                    marginBottom="10px"
                  >
                    Memo
                  </Typography>
                  <TextField
                    rows={3}
                    fullWidth
                    multiline
                    value={data.notes}
                    onChange={(e) => setValue("notes", e.target.value)}
                  />
                </Grid>
                <Grid item md={12}>
                  <Separator />
                </Grid>
                {Object.keys(errors).map((err) => (
                  <Grid item md={12}>
                    <Alert severity="error">
                      {err}: {errors[err]}
                    </Alert>
                  </Grid>
                ))}
                <Grid item md={12}>
                  <Button
                    size="small"
                    color="black"
                    variant="contained"
                    type="submit"
                    disabled={paymentsLoading || isSaving}
                  >
                    Save payment
                  </Button>
                </Grid>
              </Grid>
            </Block>
            <RedeemCredit
              open={openRedeemCredit}
              setOpen={setOpenRedeemCredit}
              accountId={data.account.value}
              addToCredits={(credit) => {
                setAppliedCredits({
                  ...appliedCredits,
                  [Object.keys(appliedCredits).length + 1]: credit,
                });
              }}
              existingCredits={appliedCredits}
            />
            <InvoicesSelect
              open={isOpenSelectInvoices}
              setOpen={setIsOpenSelectInvoices}
              accountId={data.account?.value}
              customerId={data.customer?.value}
              includeInvoice={includeInvoice}
              payments={payments}
            />
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default UpdatePayment;

function TransactionInput({
  val,
  setVal,
  delItem,
  isCredit,
  getBalance,
  canAdd = true,
}) {
  const [amount, setAmount] = useState(0 || val.amount_paid);
  const [method, setMethod] = useState(val.method || null);
  const [reference, setReference] = useState("");
  const [memo, setMemo] = useState(val.notes || "");
  const [ccFee, setCCfee] = useState(3);
  const [ccDigits, setCcDigits] = useState("");
  useEffect(() => {
    const transaction = {
      amount_paid: amount,
      method: isCredit ? "CUSTOMER_CREDIT" : method,
      reference,
      notes: memo,
      id: val.id,
    };
    if (method == "CREDIT_CARD") {
      transaction["fee_percent"] = ccFee;
      transaction["cc_digits"] = ccDigits;
    }

    setVal(transaction);
  }, [amount, method, reference, memo, ccFee]);
  const SUPPORTED_METHODS = [
    {
      key: "CREDIT_CARD",
      value: "CREDIT_CARD",
      label: "Credit Card",
    },
    {
      key: "CHEQUE",
      value: "CHEQUE",
      label: "Check",
    },
    {
      key: "WIRE_TRANSFER",
      value: "WIRE_TRANSFER",
      label: "Wire Transfer",
    },
    {
      key: "QUICK_PAY",
      value: "QUICK_PAY",
      label: "Quick Pay",
    },
    {
      key: "CASH",
      value: "CASH",
      label: "Cash",
    },
    {
      key: "ACH",
      value: "ACH",
      label: "ACH",
    },
    {
      key: "BARTER",
      value: "BARTER",
      label: "Barter",
    },
    /*{
      key: "CUSTOMER_CREDIT",
      value: "CUSTOMER_CREDIT",
      label: "Account Credit",
    },*/
  ];
  return (
    <Grid container spacing={1}>
      {!isCredit ? (
        <Grid item md={canAdd ? 10 : 12}>
          <Grid container spacing={1}>
            <Grid item md={3}>
              <Typography variant="inputLabel" component="p">
                Payment Method
              </Typography>
              <Select
                value={method}
                sx={{
                  height: "40px",
                }}
                onChange={(e) => setMethod(e.target.value)}
                fullWidth
                required
              >
                {SUPPORTED_METHODS.map((m) => (
                  <MenuItem value={m.value} key={m.key}>
                    {m.label}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item width="100%" md={3}>
              <Stack>
                <Typography variant="inputLabel" component="p">
                  Amount
                </Typography>
              </Stack>
              <OutlinedInput
                fullWidth
                sx={{
                  height: "40px",
                  "& .MuiInputBase-input": {
                    height: "40px",
                    boxSizing: "border-box",
                  },
                }}
                startAdornment={
                  <InputAdornment position="start">$</InputAdornment>
                }
                name="amountPaid"
                type="number"
                value={amount}
                onChange={(e) => {
                  const r = /^\$?[0-9]+\.?[0-9]?[0-9]?$/;
                  if (e.target.value == "") {
                    setAmount("");
                  }
                  if (r.test(e.target.value)) {
                    setAmount(e.target.value);
                  }
                }}
                required
              />
            </Grid>
            {method == "CREDIT_CARD" && (
              <>
                <Grid item md={2}>
                  <Stack
                    direction="row"
                    alignItems={"center"}
                    justifyItems={"center"}
                    spacing={1}
                  >
                    <Typography>CC fee</Typography>
                  </Stack>
                  <Input
                    fullWidth
                    sx={{
                      height: "40px",
                      "& .MuiInputBase-input": {
                        height: "40px",
                        boxSizing: "border-box",
                      },
                    }}
                    startAdornment={
                      <InputAdornment position="end">%</InputAdornment>
                    }
                    name="ccFee"
                    type="number"
                    value={ccFee}
                    onChange={(e) => setCCfee(e.target.value)}
                  />
                </Grid>
                <Grid item md={2}>
                  <Stack
                    direction="row"
                    alignItems={"center"}
                    justifyItems={"center"}
                    spacing={1}
                  >
                    <Button
                      sx={{
                        display: "flex",
                        flexShrink: 0,
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "4px",
                        fontSize: "12px",
                        minWidth: "30px",
                        height: "25px !important",
                      }}
                      onClick={() =>
                        setAmount((getBalance() / (1 - ccFee / 100)).toFixed(2))
                      }
                    >
                      match T.
                    </Button>
                  </Stack>

                  <Input
                    fullWidth
                    sx={{
                      height: "40px",
                      "& .MuiInputBase-input": {
                        height: "40px",
                        boxSizing: "border-box",
                      },
                    }}
                    startAdornment={
                      <InputAdornment position="start">$</InputAdornment>
                    }
                    name="amountPaid"
                    type="number"
                    value={(amount - (amount * ccFee) / 100).toFixed(2)}
                    readOnly
                  />
                </Grid>
                <Grid item md={2}>
                  <Stack
                    direction="row"
                    alignItems={"center"}
                    justifyItems={"center"}
                    spacing={1}
                  >
                    <Typography>Last 4 Digits</Typography>
                  </Stack>

                  <Input
                    fullWidth
                    sx={{
                      height: "40px",
                      "& .MuiInputBase-input": {
                        height: "40px",
                        boxSizing: "border-box",
                      },
                    }}
                    name="ccDigits"
                    type="test"
                    value={ccDigits}
                    onChange={(e) => setCcDigits(e.target.value)}
                  />
                </Grid>
              </>
            )}
            {method != "CREDIT_CARD" && (
              <Grid item md={5}>
                <Typography variant="inputLabel" component="p">
                  Reference #
                </Typography>
                <OutlinedInput
                  fullWidth
                  sx={{
                    height: "40px",
                    "& .MuiInputBase-input": {
                      height: "40px",
                      boxSizing: "border-box",
                    },
                  }}
                  name="reference"
                  type="string"
                  value={reference}
                  onChange={(e) => setReference(e.target.value)}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      ) : (
        <Grid item md={10}>
          <Grid container spacing={1}>
            <Grid item md={4}>
              <Typography variant="inputLabel" component="p">
                Amount
              </Typography>
              <OutlinedInput
                fullWidth
                sx={{
                  height: "40px",
                  "& .MuiInputBase-input": {
                    height: "40px",
                    boxSizing: "border-box",
                  },
                }}
                startAdornment={
                  <InputAdornment position="start">$</InputAdornment>
                }
                name="amountPaid"
                type="number"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                required
              />
            </Grid>
            <Grid item md={8}>
              <Typography variant="inputLabel" component="p">
                Memo
              </Typography>
              <OutlinedInput
                fullWidth
                sx={{
                  height: "40px",
                  "& .MuiInputBase-input": {
                    height: "40px",
                    boxSizing: "border-box",
                  },
                }}
                name="memo"
                type="string"
                value={memo}
                onChange={(e) => setMemo(e.target.value)}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
      {canAdd && (
        <Grid item md={2}>
          <Grid md={12} item>
            <Stack alignItems="center" justifyItems="center">
              <Typography>Actions</Typography>
              <Button
                variant="outlined"
                onClick={() => delItem()}
                sx={{ marginTop: 0.5 }}
              >
                X
              </Button>
            </Stack>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}

function PaymentPageTitle({
  isCreatePayment,
  setIsOpenDelete,
  setIsOpenDiscard,
  backButtonLink,
  paymentsLoading,
  navigate,
}) {
  return (
    <>
      {isCreatePayment ? (
        <Stack
          direction={{ xs: "column", md: "row" }}
          alignItems={{ xs: "flex-start", md: "center" }}
          justifyContent="space-between"
          spacing="20px"
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
            sx={{ width: { xs: "100%", sm: "initial" }, gap: "20px" }}
          >
            <Button
              onClick={() => navigate(backButtonLink || -1)}
              variant="outlined"
              startIcon={<ArrowBackIcon />}
            >
              Back
            </Button>
            <Typography
              variant="pageTitle"
              noWrap
              component="p"
              data-testid="page-title"
            >
              Create Payment
            </Typography>
          </Stack>
          <Stack
            direction={{ sm: "row-reverse", md: "row" }}
            alignItems="center"
            justifyContent="flex-end"
            flexWrap="wrap"
            sx={{
              width: {
                xs: "100%",
                sm: "initial",
              },
              gap: "11px",
              "@media(max-width: 600px)": {
                flexDirection: "row-reverse",
                width: "100%",
              },
              "@media(max-width: 480px)": {
                flexDirection: "column-reverse",
                width: "100%",
                button: { width: "100%" },
              },
            }}
          ></Stack>
        </Stack>
      ) : (
        <UpdateItemTitle
          item={"Payment"}
          setIsOpenDelete={setIsOpenDelete}
          setIsOpenDiscard={setIsOpenDiscard}
          isSubmitting={paymentsLoading}
          backLink={backButtonLink}
        />
      )}
    </>
  );
}
