import { Link } from "react-router-dom";
import useAppDispatch from "../../hooks/useAppDispatch";
import TableButtons from "../../components/TableComponents/TableButtons";
import { removeTag } from "../../redux/module/tag";
import React, { useState } from "react";
import AddTagFrom from "./AddTagFrom";

function TagProductsCountRenderer(params) {
  return (
    <span className="my-renderer">
      <Link
        to={`/products/?tags=${params.data.name}`}
        style={{ textDecoration: "none" }}
      >
        {params.data.products_count}
      </Link>
    </span>
  );
}

function TagLinkRenderer(props) {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleDelete = async () => {
    await dispatch(removeTag(props.data.id));
  };
  const handleEdit = () => {
    handleOpen();
  };
  return (
    <>
      <AddTagFrom open={open} handleClose={handleClose} tag={props.data} />
      <TableButtons
        handleEdit={handleEdit}
        handleDelete={handleDelete}
        deletePopupEntity="tag"
        historyData={props.data.history || []}
      />
    </>
  );
}

const tag_name = {
  headerName: "Tag",
  field: "name",
  wrapText: true,
  autoHeight: true,
  sortable: true,
  comparator: () => {}, //disable default sorting
  filterParams: {
    filterOptions: ["contains"],
    suppressAndOrCondition: true,
  },
  filter: true,
  floatingFilter: true,
  valueGetter: (params) => `${params.data.name}`,
  flex: 4,
  minWidth: 300,
};

export const tag_products_count = {
  headerName: "Products tagged",
  field: "products_count",
  filter: false,
  sortable: false,
  comparator: () => {}, //disable default sorting
  autoHeight: true,
  cellRenderer: TagProductsCountRenderer,
  flex: 2,
  minWidth: 200,
};

const empty = {
  field: "",
  filter: false,
  floatingFilter: false,
  flex: 5,
};

const tag_link = {
  field: "",
  sorting: false,
  sort: false,
  sortable: false,
  autoHeight: true,
  filter: false,
  cellRenderer: TagLinkRenderer,
  flex: 1,
  minWidth: 120,
  maxWidth: 150,
};

const tagTableColumns = [tag_name, tag_products_count, empty, tag_link];

export default tagTableColumns;
