import React, { useEffect, useState } from "react";
import {
  Alert,
  Box,
  Button,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { DATE_FORMAT_FRONT } from "../../utils/constants";
import useAppSelector from "../../hooks/useAppSelector";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import DatePicker from "../DatePicker2";
import { transferOrder, updateOrderDate } from "../../distflowAPI/ordersApi";
import { Cancel } from "@mui/icons-material";
import ConfirmDialog from "../ConfirmDialog";
import AccountSelect from "../AsyncSelects/AccountSelect";
import RoomIcon from "@mui/icons-material/Room";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
const OrderTitleModal = ({
  state,
  orderId,
  invoiceId,
  date,
  shippingAddress,
  setShippingAddress,
  orderPoId,
  refreshTable,
}) => {
  const { account } = useAppSelector((state) => state.account);
  const [selectedDate, setSelectedDate] = useState();
  const [isEditAddress, setIsEditAddress] = useState(false);
  const [newAddress, setNewAddress] = useState("");
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const [confirmDateOpen, setConfirmDateOpen] = useState(false);

  const handleEditIconClick = () => {
    if (isEditAddress && shippingAddress && shippingAddress !== newAddress) {
      setShippingAddress(newAddress);
    } else if (isEditAddress && account.full_address !== newAddress) {
      setShippingAddress(newAddress);
    }
    setIsEditAddress(!isEditAddress);
  };

  const handleDiscardIconClick = () => {
    setIsEditAddress(false);
    setNewAddress(account.full_address);
  };

  const handleChangeDate = () => {
    updateOrderDate({
      orderId: orderId,
      date: selectedDate.format("YYYY-MM-DD"),
    })
      .then((res) => {
        setDatePickerOpen(false);
        refreshTable(true);
      })
      .catch((error) => {
        console.error("change date failed ", error);
      });
  };

  useEffect(() => {
    setNewAddress(shippingAddress || account?.full_address || "");
    setIsEditAddress(!shippingAddress && !account?.full_address);
  }, [shippingAddress, account]);

  const textColor = !state || state === "RECEIVED" ? "orange" : "red";

  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <Stack direction={"row"} alignItems={"start"} spacing={3}>
        <Typography color="#000">
          <b>
            {invoiceId
              ? `INV#${invoiceId}`
              : orderPoId
                ? `PO# ${orderPoId}`
                : orderId
                  ? `Order #${orderId}`
                  : "New order"}
          </b>
        </Typography>
        {/* STATUS */}
        <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
          <div
            style={{
              width: "10px",
              height: "10px",
              background: textColor,
              borderRadius: "100%",
            }}
          />
          <Typography
            sx={{
              color: textColor,
              fontSize: "14px",
              fontWeight: "500",
            }}
          >
            {state ? (state === "RECEIVED" ? "Pending" : state) : "Pending"}
          </Typography>
        </Box>
      </Stack>
      {/* ACCOUNT */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "3rem",
          position: "relative",
          width: "100%",
        }}
      >
        <Stack>
          {!!account.id && (
            <Stack direction="row" alignItems="center" gap={1} fullWidth>
              <DisplayAccount
                account={account}
                state={state}
                orderId={orderId}
                setNewAddress={setNewAddress}
                setShippingAddress={setShippingAddress}
              />
            </Stack>
          )}
          <Stack direction="row" gap={2} alingItems="center" my={1}>
            <Stack
              sx={{
                overflow: "hidden",
                maxHeight: "52px",
              }}
            >
              <Stack
                sx={{
                  maxHeight: isEditAddress ? 0 : "52px",
                  overflow: "hidden",
                }}
                direction="row"
                alignItems="center"
                gap={1}
              >
                <RoomIcon fontSize="medium" />
                <Typography fontSize="16px">{newAddress}</Typography>
              </Stack>
              {isEditAddress && (
                <TextField
                  variant="standard"
                  placeholder="Add shipping address"
                  value={newAddress}
                  autoFocus
                  onChange={(e) => setNewAddress(e.target.value)}
                  sx={{
                    width: "100%",
                  }}
                />
              )}
            </Stack>
            <Stack direction="row" alignItems="center" gap="3px">
              <IconButton
                onClick={handleEditIconClick}
                sx={{
                  display: "flex",
                  flexShrink: "0",
                }}
              >
                {isEditAddress ? (
                  <CheckIcon fontSize="small" />
                ) : (
                  <EditOutlinedIcon fontSize="small" />
                )}
              </IconButton>
              {isEditAddress && (
                <IconButton
                  onClick={handleDiscardIconClick}
                  sx={{
                    display: "flex",
                    flexShrink: "0",
                  }}
                >
                  <ClearIcon fontSize="small" />
                </IconButton>
              )}
            </Stack>
          </Stack>
        </Stack>
        <Stack direction="row" alignItems="center" gap={2}>
          {datePickerOpen ? (
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <DatePicker
                controlledValue={selectedDate}
                setControlledValue={setSelectedDate}
                maxWidth="220px"
                size="small"
              />
              <Button
                sx={{
                  padding: "3px 4px",
                  fontSize: "13px",
                  minWidth: "0",
                  width: "36px",
                  height: "36px !important",
                }}
                variant="contained"
                color="success"
                size="small"
                onClick={() => {
                  setConfirmDateOpen(true);
                }}
              >
                <CheckIcon fontSize="small" />
              </Button>
              <Button
                sx={{
                  padding: "3px 4px",
                  fontSize: "13px",
                  minWidth: "0",
                  width: "36px",
                  height: "36px !important",
                }}
                variant="contained"
                color="error"
                size="small"
                onClick={() => setDatePickerOpen(false)}
              >
                <Cancel fontSize="small" />
              </Button>
              <ConfirmDialog
                title="Confirm order date change"
                open={confirmDateOpen}
                setOpen={setConfirmDateOpen}
                onConfirm={handleChangeDate}
              >
                <Typography>
                  {" "}
                  Do you really want to change the date of this order ?
                </Typography>
              </ConfirmDialog>
            </div>
          ) : (
            <Typography
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
              }}
            >
              <CalendarMonthIcon fontSize="small" />
              {dayjs.utc(date).format(DATE_FORMAT_FRONT)}
            </Typography>
          )}

          {!datePickerOpen && (orderPoId || orderId) && (
            <IconButton>
              <EditOutlinedIcon
                onClick={() => {
                  setDatePickerOpen(!datePickerOpen);
                }}
                fontSize="small"
              />
            </IconButton>
          )}
          <Stack direction="row" alignItems="center" gap={1}></Stack>
        </Stack>
      </Box>
    </Box>
  );
};

export default OrderTitleModal;

function DisplayAccount({
  account,
  state,
  orderId,
  setNewAddress,
  setShippingAddress,
}) {
  const [isChangingAccount, setIsChangingAccount] = useState(false);
  const [newAccount, setNewAccount] = useState(null);
  const [changedAccount, setChangedAccount] = useState(false);
  const [success, setSuccess] = useState("");

  function handleChangeAccount() {
    transferOrder(orderId, newAccount.value).then(
      (res) => {
        setChangedAccount(true);
        setIsChangingAccount(false);
        setShippingAddress(res.data.address);
        setNewAddress(res.data.address);
        setSuccess(`order transferred to ${newAccount.label}`);
      },
      (err) => {}
    );
  }

  return (
    <Stack spacing={1} maxWidth={"100%"} minWidth={"100%"}>
      {success && (
        <Alert fullWidth maxWidth={"100%"} minWidth={"100%"}>
          {success}
        </Alert>
      )}
      <Stack direction={"row"} alignItems={"center"} spacing={1}>
        {!isChangingAccount && (
          <Stack direction={"row"} alignItems={"center"} spacing={1}>
            <Typography fontSize="18px" fontWeight="500">
              {changedAccount ? newAccount.label : account.name}
            </Typography>
            {orderId && (
              <IconButton
                onClick={() => setIsChangingAccount(true)}
                sx={{
                  display: "flex",
                  flexShrink: "0",
                }}
              >
                <EditOutlinedIcon fontSize="small" />
              </IconButton>
            )}
          </Stack>
        )}
        {isChangingAccount && (
          <Stack
            direction={"row"}
            alignItems={"center"}
            spacing={1}
            minWidth={350}
          >
            <AccountSelect
              label={"Transfer to"}
              value={newAccount}
              onChange={(_ev, newVal) => setNewAccount(newVal)}
            />
            <IconButton
              onClick={() => handleChangeAccount()}
              sx={{
                display: "flex",
                flexShrink: "0",
              }}
            >
              <CheckIcon fontSize="small" />
            </IconButton>
            <IconButton
              onClick={() => setIsChangingAccount(false)}
              sx={{
                display: "flex",
                flexShrink: "0",
              }}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
}
