import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import {
  Box,
  Button,
  IconButton,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import ImgPlaceholder from "../../images/img-placeholder.svg";
import useFile from "../../hooks/useFile";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";

// TODO: move to constants
const soldByValMapper = {
  PC: "Piece",
  BX: "Box",
  BK: "Bucket",
  BT: "Batter",
  CT: "Container",
  DZ: "Dozen",
  EA: "Each",
  LB: "Lb",
  PK: "Package",
  PL: "pale / bucket",
  SH: "Sheet",
};

/**
 * @type {React.ForwardRefExoticComponent<React.PropsWithoutRef<{
 *  onSubmit: (variant: any) => void;
 *  checkSku: (sku: string) => Promise<boolean>;
 *  setVariantsErrors: ({}) => void;
 * }> & React.RefAttributes<{
 *  setUpdateData: (variant: any) => void
 * }>>}
 */
const ProductVariantForm = forwardRef((props, ref) => {
  const [variantSoldBy, setVariantSoldBy] = useState(null);
  const [variantImageUrl, setVariantImageUrl] = useState();
  /** @type {React.MutableRefObject<HTMLFormElement>} */
  const formRef = useRef();
  /** @type {React.MutableRefObject<HTMLInputElement>} */
  const variantImageRef = useRef(null);
  const { isFileValid } = useFile();

  const setFormFieldValue = useCallback(
    (field, value) => {
      if (!formRef.current.elements.namedItem(field)) {
        return;
      }
      formRef.current.elements.namedItem(field).value = value;
    },
    [formRef],
  );

  useImperativeHandle(
    ref,
    () => ({
      setUpdateData(variant) {
        setFormFieldValue("variantName", variant.name);
        setFormFieldValue("variantSKU", variant.sku);
        setFormFieldValue("variantBarCode", variant.barcode);
        setFormFieldValue("variantCode", variant.code);
        setFormFieldValue("variantCost", variant.cost);
        setFormFieldValue("perBox", variant.per_box);
        setFormFieldValue("perRack", variant.per_pack);
        setFormFieldValue("orderQTY", variant.order_qty);
        setFormFieldValue("sheet", variant.sheet);
        setVariantSoldBy(variant.sold_by.value);
        if (variant.images && variant.images.length > 0) {
          if (variant.images[0].image) {
            setVariantImageUrl(variant.images[0].image);
          }
        }
      },
    }),
    [setFormFieldValue],
  );

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const skuAvailable = await props.checkSku(data.get("variantSKU"));
    if (!skuAvailable) {
      // TODO: show a message that sku is not available
      return;
    }
    const variant = {
      name: data.get("variantName"),
      sku: data.get("variantSKU"),
      cost: data.get("variantCost"),
      images: [{ image: variantImageUrl }],
      barcode: data.get("variantBarCode"),
      sold_by: {
        value: data.get("variantSoldBy"),
        label: soldByValMapper[data.get("variantSoldBy")],
      },
      //count: data.get('variantSKU'),
      //variantId: data.get('variantSKU'),
      per_box: data.get("perBox"),
      per_pack: data.get("perRack"),
      order_qty: data.get("orderQTY"),
      code: data.get("variantCode"),
      //weight: data.get('variantSKU'),
      //weight_2: data.get('variantSKU'),
      sheet: data.get("sheet"),
      image: [data.get("variantImage")],
      overrides_count: 0,
    };
    props.onSubmit(variant);
    setVariantImageUrl(null);
    variantImageRef.current.value = null;
    setVariantSoldBy(null);
    setFormFieldValue("variantImage", null);
    setFormFieldValue("variantName", "");
    setFormFieldValue("variantSKU", "");
    setFormFieldValue("variantBarCode", "");
    setFormFieldValue("variantCode", "");
    setFormFieldValue("variantCost", "");
    setFormFieldValue("perBox", 0);
    setFormFieldValue("perRack", 0);
    setFormFieldValue("orderQTY", 0);
    setFormFieldValue("sheet", 0);
    props.setVariantsErrors({});
  };

  const handleVariantImageChange = (e) => {
    if (isFileValid(e.target.files[0])) {
      setVariantImageUrl(URL.createObjectURL(e.target.files[0]));
    }
  };

  return (
    <form ref={formRef} id="tagCreation" onSubmit={handleSubmit}>
      <Typography component="p" variant="label" mb="11px">
        Product Variants
      </Typography>
      <Stack
        direction="row"
        alignItems="flex-end"
        flexWrap="wrap"
        gap="17px"
        mb="20px"
      >
        <Box maxWidth="250px">
          <Typography variant="inputLabel" component="p">
            Option Name*
          </Typography>
          <TextField
            fullWidth
            data-testid={`test-variant-name-id`}
            variant="outlined"
            name="variantName"
            defaultValue=""
            required
            id="variantName"
          />
        </Box>
        <Box maxWidth="140px">
          <Typography variant="inputLabel" component="p">
            SKU*
          </Typography>
          <TextField
            fullWidth
            data-testid={`test-variant-sku-id`}
            variant="outlined"
            name="variantSKU"
            defaultValue=""
            required
          />
        </Box>
        <Box maxWidth="170px">
          <Typography variant="inputLabel" component="p">
            Barcode
          </Typography>
          <TextField
            fullWidth
            data-testid={`test-variant-bar-code-id`}
            variant="outlined"
            name="variantBarCode"
            defaultValue=""
          />
        </Box>
        <Box maxWidth="110px">
          <Typography variant="inputLabel" component="p">
            Code
          </Typography>
          <TextField
            data-testid={`test-variat-code-id`}
            fullWidth
            variant="outlined"
            name="variantCode"
            defaultValue=""
          />
        </Box>
        <Box maxWidth="120px">
          <Typography variant="inputLabel" component="p">
            Cost*
          </Typography>
          <TextField
            fullWidth
            data-testid={`test-variant-cost-id`}
            variant="outlined"
            name="variantCost"
            required
            type="number"
            defaultValue={0}
            inputProps={{ min: 0, step: "0.1", lang: "en-US" }}
          />
        </Box>
      </Stack>
      <Stack
        direction="row"
        alignItems="flex-end"
        flexWrap="wrap"
        gap="17px"
        mb="20px"
      >
        <Box maxWidth="250px" minWidth="240px">
          <Typography variant="inputLabel" component="p">
            Sold by*
          </Typography>
          <Select
            fullWidth
            required
            name="variantSoldBy"
            value={variantSoldBy}
            onChange={(e) => setVariantSoldBy(e.target.value)}
          >
            <MenuItem value="PC">Piece</MenuItem>
            <MenuItem value="BX">Box</MenuItem>
            <MenuItem value="BK">Bucket</MenuItem>
            <MenuItem value="BT">Batter</MenuItem>
            <MenuItem value="CT">Container</MenuItem>
            <MenuItem value="DZ">Dozen</MenuItem>
            <MenuItem value="EA">Each</MenuItem>
            <MenuItem value="LB">Lb</MenuItem>
            <MenuItem value="PK">Package</MenuItem>
            <MenuItem value="PL">pale / bucket</MenuItem>
            <MenuItem value="SH">Sheet</MenuItem>
          </Select>
        </Box>
        <Box maxWidth="95px">
          <Typography variant="inputLabel" component="p">
            Per Box*
          </Typography>
          <TextField
            fullWidth
            data-testid="test-per-box-id"
            variant="outlined"
            type="number"
            name="perBox"
            defaultValue={0}
            required
          />
        </Box>
        <Box maxWidth="95px">
          <Typography variant="inputLabel" component="p">
            Per Rack*
          </Typography>
          <TextField
            fullWidth
            data-testid="test-per-pack-id"
            variant="outlined"
            type="number"
            required
            name="perRack"
            defaultValue={0}
          />
        </Box>
        <Box maxWidth="95px">
          <Typography variant="inputLabel" component="p">
            Order QTY*
          </Typography>
          <TextField
            fullWidth
            data-testid="test-order-qty-id"
            variant="outlined"
            type="number"
            required
            name="orderQTY"
            defaultValue={0}
          />
        </Box>
        <Box maxWidth="95px">
          <Typography variant="inputLabel" component="p">
            Sheet*
          </Typography>
          <TextField
            fullWidth
            data-testid="test-sheet-id"
            variant="outlined"
            type="number"
            required
            defaultValue={0}
            name="sheet"
          />
        </Box>
        <Box maxWidth="140px">
          <Stack direction="row">
            <Box>
              <input
                style={{ display: "none" }}
                accept="image/*"
                id="icon"
                type="file"
                name="variantImage"
                ref={variantImageRef}
                onChange={handleVariantImageChange}
              />
              <label htmlFor="icon">
                <Button
                  fullWidth
                  component="span"
                  //variant="contained"
                  sx={{ pt: 0 }}
                  //startIcon={<FileOpenIcon />}
                >
                  {!variantImageUrl && <img src={ImgPlaceholder} alt="" />}
                  {variantImageUrl && (
                    <img src={variantImageUrl} loading="lazy" />
                  )}
                </Button>
              </label>
            </Box>
            {variantImageUrl && (
              <Box>
                <IconButton
                  sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                  onClick={() => {
                    setVariantImageUrl(null);
                    variantImageRef.current.value = null;
                  }}
                >
                  <DeleteForeverOutlinedIcon fontSize={"small"} />
                </IconButton>
              </Box>
            )}
          </Stack>
        </Box>
      </Stack>
      <Stack direction="row" flexWrap="wrap" gap={2}>
        <Button color="primary" variant="contained" type="submit">
          Create Option
        </Button>
      </Stack>
    </form>
  );
});

export default ProductVariantForm;
