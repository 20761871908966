import { Grid, Stack, Typography } from "@mui/material";
import { getTotalInvoices,getTotalAllocated,getTotalCredit,getCCFees,getGrandTotal,getGrandTotalWF,getTotalPayments,getBalance } from "./createPaymentComponents/config";

function PaymentSummary({data }) {
  return (
    <Grid item md={12}>
      <Stack spacing={0.5}>
        <Typography
          fontSize={20}
          fontWeight={600}
          marginBottom="10px"
          marginTop="10px"
        >
          Summary
        </Typography>
        <Typography>
          Total invoices:
          <b>{` $${getTotalInvoices(data).toFixed(2)}`}</b>
        </Typography>
        <Typography>
          Total allocated: <b>${getTotalAllocated(data).toFixed(2)}</b>
        </Typography>
        <Typography>
          Credit: <b>${getTotalCredit(data).toFixed(2)}</b>
        </Typography>
        {getCCFees(data) > 0 && (
          <Typography>
            CC Fees: <b>${getCCFees(data).toFixed(2)}</b>
          </Typography>
        )}
        <Typography>
          Total To Pay: <b>${getGrandTotal(data).toFixed(2)}</b>
        </Typography>
        <Typography>
          Total Due (with fees): <b>${getGrandTotalWF(data).toFixed(2)}</b>
        </Typography>
        <Typography>
          Total Payments: <b>${getTotalPayments(data).toFixed(2)}</b>
        </Typography>
        <Typography>
          Balance:{" "}
          <b
            style={{
              color:
                getBalance(data) == 0
                  ? "green"
                  : getBalance(data) > 0
                  ? "red"
                  : "black",
            }}
          >
            ${getBalance(data) == 0 ? 0 : getBalance(data).toFixed(2)}
          </b>
        </Typography>
        {parseInt(getBalance(data)) < 0 && (
          <Typography>
            <span style={{ color: "red" }}>Important note: </span>A credit of{" "}
            <b>${(getBalance(data) * -1).toFixed(2)}</b> will be added to the
            account
          </Typography>
        )}
      </Stack>
    </Grid>
  );
}

export default PaymentSummary;
