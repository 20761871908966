import React from "react";
import AsyncAutocompleteSelect from "./AsyncAutocompleteSelect";
import customersApi from "../../distflowAPI/customersApi";
import { listSalesmen } from "../../distflowAPI/salesmenApi";

/**
 * @param {Omit<
 *  import("./AsyncAutocompleteSelect").AsyncAutocompleteSelectProps<
 *    import("../../redux/module/customer").Customer
 *  >,
 *  'fetchOptions'
 * >} props
 */
const SalesmanSelect = (props) => (
  <AsyncAutocompleteSelect
    fetchOptions={(searchTerm) => {
      return listSalesmen(1, 10, searchTerm).then((res) =>
        res.data.results.map((item) => {
          return {
            value: item.id,
            label: `${item.user.first_name} ${item.user.last_name}`,
            
          };
        }),
      );
    }}
    {...props}
  />
);

export default SalesmanSelect;
