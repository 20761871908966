import { redirect } from "react-router-dom";
import {
  createAction,
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { getToken } from "../../distflowAPI/api";
import {
  deleteUser,
  getAllUsers,
  getAuthUser,
  getUser,
  getUsers,
  otpVerification,
  patchUser,
  postUser,
  signIn as signInApi,
} from "../../distflowAPI/user";
import {
  arrayToDictionary,
  fireErrorNotification,
  fireSuccessNotification,
} from "../../utils";
import Smartlook from "smartlook-client";
import { setSession } from "../../utils/setSession";
/**
 * @typedef {Object} User
 * @property {string} User.first_name
 * @property {string} User.last_name
 * @property {string} User.email
 * @property {number} User.id
 * @property {string} User.username
 */

/**
 * @type {{
 *  authUser: User | null;
 *  userLoading: boolean;
 *  user: User | null;
 *  users: Array<User>;
 *  usersLoading: boolean;
 *  pageSize: number;
 *  page: number;
 *  orderBy: [];
 *  filterBy: {};
 *  totalCount: number;
 *  userMap: {};
 *  isInitialized: boolean;
 *  allUsersLoading: boolean;
 * }}
 */

const initialState = {
  authUser: null,
  userLoading: false,
  user: {
    first_name: "",
    last_name: "",
    username: "",
    email: "",
  },
  users: [],
  usersLoading: false,
  pageSize: 25,
  page: 1,
  orderBy: [],
  filterBy: [],
  totalCount: 0,
  userMap: {},
  isInitialized: false,
  allUsersLoading: false,
};

export const fetchAuthUser = createAsyncThunk(
  "user/fetchAuthUser",
  async () => {
    const resp = await getAuthUser();
    Smartlook.init("6ca537ea798f90f9df3f3940c68132a6b6eb459f");
    Smartlook.identify(resp.data?.id, resp.data);
    Smartlook.record({
      emails: true,
      forms: true,
      numbers: false,
    });
    return resp.data;
  }
);

export const fetchAllUsers = createAsyncThunk(
  "user/fetchAllUsers",
  async (arg, { dispatch, getState }) => {
    const res = await getAllUsers();
    return res.data;
  }
);

export const fetchUsers = createAsyncThunk(
  "user/fetchUsers",
  async (data, thunkAPI) => {
    const { page, pageSize, filterBy, orderBy } = data;
    const res = await getUsers(page, pageSize, filterBy, orderBy);
    return res.data;
  }
);

export const fetchUser = createAsyncThunk(
  "user/fetchUser",
  async (userId, thunkAPI) => {
    const res = await getUser(userId);
    return res.data;
  }
);

export const createUser = createAsyncThunk(
  "user/createUser",
  async (userInfo, { rejectWithValue }) => {
    try {
      const res = await postUser(userInfo);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateUser = createAsyncThunk(
  "user/updateUser",
  async (data, { rejectWithValue }) => {
    const { userId, userInfo } = data;

    try {
      const res = await patchUser(userId, userInfo);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const removeUser = createAsyncThunk(
  "user/removeUser",
  async (userId, { rejectWithValue }) => {
    try {
      const res = await deleteUser(userId);
      return userId;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const signIn = createAsyncThunk(
  "user/signIn",
  async ({ email, password }, { dispatch, rejectWithValue }) => {
    try {
      const res = await signInApi(email, password);
      if (res.data === "ok") {
        dispatch(fetchAuthUser());
      }
      if (res.status === 207) {
        console.log("child", res.data);
        return dispatch(redirectToOtp(res.data));
      }
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const verifyOtp = createAsyncThunk(
  "user/verifyOtp",
  async ({ otp, username }, { dispatch, rejectWithValue }) => {
    otpVerification({ otp, username })
      .then(async (response) => {
        await setSession(response);
        if (localStorage.getItem("accessToken")) {
          dispatch(fetchAuthUser());
        }
      })
      .catch((error) => {
        console.log("error", error.response.data);
        return rejectWithValue(error.response.data);
      });
  }
);

export const redirectToOtp = createAsyncThunk(
  "user/redirectToOtp",
  async ({ username, email }, { dispatch }) => {
    window.location.href = "/auth/otp?username=" + username + "&email=" + email;
  }
);

export const signOut = createAction("user/signOut");

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUsersTableSettings(state, { payload }) {
      state[payload.field] = payload.value;
    },
    cleanTableSettings(state) {
      state.pageSize = 25;
      state.page = 1;
      state.orderBy = [];
      state.filterBy = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAuthUser.pending, (state) => {
        state.userLoading = true;
      })
      .addCase(fetchAuthUser.fulfilled, (state, action) => {
        state.userLoading = false;
        state.isInitialized = true;
        state.authUser = action.payload;
      })
      .addCase(fetchAuthUser.rejected, (state) => {
        state.userLoading = false;
        state.isInitialized = true;
      })
      .addCase(signIn.rejected, (state, action) => {
        const errorFields = Object.keys(action.payload);
        errorFields.forEach((field) => {
          const errorType = typeof action.payload[field];
          const errorText =
            errorType === "string"
              ? action.payload[field]
              : action.payload[field][0];
          fireErrorNotification(`${field}: ${errorText}`);
        });
      })
      .addCase(signOut, (state) => {
        localStorage.setItem("accessToken", null);
        state.authUser = null;
      })
      .addCase(fetchAllUsers.pending, (state) => {
        state.allUsersLoading = true;
      })
      .addCase(fetchAllUsers.fulfilled, (state, { payload }) => {
        state.allUsersLoading = false;
        state.users = payload.results;
        state.userMap = arrayToDictionary(payload.results, "id");
      })
      .addCase(fetchAllUsers.rejected, (state) => {
        state.allUsersLoading = false;
      })
      .addCase(fetchUsers.pending, (state, { payload }) => {
        state.usersLoading = true;
      })
      .addCase(fetchUsers.fulfilled, (state, { payload }) => {
        state.users = payload.results;
        state.totalCount = payload.count;
        state.usersLoading = false;
      })
      .addCase(fetchUser.pending, (state, { payload }) => {
        state.usersLoading = true;
      })
      .addCase(fetchUser.fulfilled, (state, { payload }) => {
        state.user = payload;
        state.usersLoading = false;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        fireSuccessNotification(`User updated successfully`);
        state.user = action.payload;
      })
      .addCase(updateUser.rejected, (state, action) => {
        const errorFields = Object.keys(action.payload);
        errorFields.forEach((field) => {
          fireErrorNotification(`${field}: ${action.payload[field][0]}`);
        });
      })
      .addCase(createUser.fulfilled, (state, action) => {
        fireSuccessNotification(`User created successfully`);
        state.totalCount = state.totalCount + 1;
        if (state.users.length < state.pageSize) {
          state.users = [...state.users, action.payload];
        }
      })
      .addCase(createUser.rejected, (state, action) => {
        const errorFields = Object.keys(action.payload);
        errorFields.forEach((field) => {
          fireErrorNotification(`${field}: ${action.payload[field][0]}`);
        });
      })
      .addCase(removeUser.fulfilled, (state, action) => {
        fireSuccessNotification(`User deleted successfully`);
        state.user = {};
        state.users = state.users.filter((el) => el.id !== action.payload);
      })
      .addCase(removeUser.rejected, (state, action) => {
        const protected_elements = action.payload.protected_elements;
        fireErrorNotification(`Protected by: 
          ${protected_elements.map(
            (el) => ` id: ${el.id} label: ${el.label}`
          )}`);
      });
  },
});

export const selectUsersNumberOfPages = createSelector(
  (state) => state.user.totalCount,
  (state) => state.user.pageSize,
  (totalCount, pageSize) => Math.ceil(totalCount / pageSize)
);

export const { setUsersTableSettings, cleanTableSettings } = userSlice.actions;

export default userSlice.reducer;
