import { Link } from "react-router-dom";
import useAppDispatch from "../../hooks/useAppDispatch";
import { removeCustomer } from "../../redux/module/customer";
import TableButtons from "../../components/TableComponents/TableButtons";

function CustomerAccountsCountRenderer(params) {
  return (
    <span className="my-renderer">
      <Link
        to={`/accounts/?customer__name=${params.data.name}`}
        style={{ textDecoration: "none" }}
      >
        {params.data.accounts_count}
      </Link>
    </span>
  );
}

function CustomerNameRenderer(params) {
  return (
    <span className="my-renderer">
      <Link
        to={`/customers/dashboard/${params.data.id}/personal`}
        style={{ textDecoration: "none" }}
      >
        {params.data.name}
      </Link>
    </span>
  );
}

function CustomerLinkRenderer(props) {
  const dispatch = useAppDispatch();

  const handleDelete = async () => {
    await dispatch(removeCustomer(props.data.id));
  };
  return (
    <TableButtons
      link={`/customers/${props.data.id}`}
      handleDelete={handleDelete}
      deletePopupEntity="customer"
      historyData={props.data.history || []}
    />
  );
}

const customer_name = {
  headerName: "Customer",
  field: "name",
  wrapText: true,
  autoHeight: true,
  sortable: true,
  comparator: () => {}, //disable default sorting
  filterParams: {
    filterOptions: ["contains"],
    suppressAndOrCondition: true,
  },
  filter: true,
  floatingFilter: true,
  valueGetter: (params) => {
    return `${params.data.name}`;
  },
  cellRendererSelector: () => {
    return {
      component: CustomerNameRenderer,
    };
  },
  flex: 4,
  minWidth: 250,
};

const customer_email = {
  headerName: "Emails",
  field: "email",
  wrapText: true,
  autoHeight: true,
  sortable: true,
  comparator: () => {}, //disable default sorting
  filterParams: {
    filterOptions: ["contains"],
    suppressAndOrCondition: true,
  },
  filter: true,
  floatingFilter: true,
  valueGetter: (params) =>
    params.data.emails.map((item) => item.email)?.join(", "),
  flex: 4,
  minWidth: 250,
};

const customer_group = {
  headerName: "Price Group",
  field: "group.name",
  wrapText: true,
  autoHeight: true,
  sortable: true,
  comparator: () => {}, //disable default sorting
  filterParams: {
    filterOptions: ["contains"],
    suppressAndOrCondition: true,
  },
  filter: true,
  floatingFilter: true,
  valueGetter: (params) => params.data.group?.name || "N/A",
  flex: 2,
  minWidth: 150,
};

const customer_accounts_count = {
  headerName: "Accounts",
  field: "accounts_count",
  sortable: true,
  comparator: () => {}, //disable default sorting
  filterParams: {
    filterOptions: ["contains"],
    suppressAndOrCondition: true,
  },
  filter: true,
  floatingFilter: true,
  autoHeight: true,
  cellRendererSelector: () => {
    return {
      component: CustomerAccountsCountRenderer,
    };
  },
  flex: 2,
  minWidth: 100,
};

const customer_address = {
  headerName: "Address",
  field: "address",
  wrapText: true,
  autoHeight: true,
  sortable: true,
  comparator: () => {}, //disable default sorting
  filterParams: {
    filterOptions: ["contains"],
    suppressAndOrCondition: true,
  },
  filter: true,
  floatingFilter: true,
  flex: 6,
  minWidth: 250,
};
const customer_city = {
  headerName: "City",
  field: "city",
  wrapText: true,
  autoHeight: true,
  sortable: true,
  comparator: () => {}, //disable default sorting
  filterParams: {
    filterOptions: ["contains"],
    suppressAndOrCondition: true,
  },
  filter: true,
  floatingFilter: true,
  flex: 3,
  minWidth: 150,
};
const customer_state = {
  headerName: "State",
  field: "state",
  wrapText: true,
  autoHeight: true,
  sortable: true,
  comparator: () => {}, //disable default sorting
  filterParams: {
    filterOptions: ["contains"],
    suppressAndOrCondition: true,
  },
  filter: true,
  floatingFilter: true,
  flex: 2,
  minWidth: 100,
};

const customer_link = {
  field: "",
  sorting: false,
  sort: false,
  sortable: false,
  autoHeight: true,
  cellRenderer: CustomerLinkRenderer,
  flex: 1,
  minWidth: 120,
};

const customer_invoice_net = {
  headerName: "Invoice net",
  field: "invoice.net",
  wrapText: true,
  autoHeight: true,
  flex: 5,
  filterParams: {
    filterOptions: ["contains"],
    maxNumConditions: 1,
  },
  filter: true,
  floatingFilter: true,
  sortable: true,
  comparator: () => {}, //disable default sorting
  minWidth: 140,
  maxWidth: 140,
};

const customer_internal_id = {
  headerCheckboxSelection: true,
  checkboxSelection: true,
  headerName: "Internal Id",
  field: "internal_id",
  wrapText: true,
  autoHeight: true,
  filterParams: {
    filterOptions: ["contains"],
    maxNumConditions: 1,
  },
  filter: true,
  floatingFilter: true,
  sortable: true,
  comparator: () => {}, //disable default sorting
  minWidth: 140,
  maxWidth: 140,
};

const manageUserAccountsTableColumns = [
  customer_internal_id,
  customer_name,
  customer_address,
  customer_city,
  customer_state,
  customer_email,
  customer_accounts_count,
  customer_group,
  // customer_invoice_net,
  // customer_link,
];

export default manageUserAccountsTableColumns;
