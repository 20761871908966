import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  Stack,
  Typography,
  FormGroup,
  FormControlLabel,
  TextField,
  Select,
  MenuItem,
  Chip,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import {
  restrictToFirstScrollableAncestor,
  restrictToVerticalAxis,
  restrictToWindowEdges,
} from "@dnd-kit/modifiers";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Block from "../../components/Block";
import Separator from "../../components/Separator";
import { Droppable } from "../../components/Droppable";
import { DraggableAccount } from "../../components/draggable/DraggableAccount";
import ChangesTable from "./ChangesTable";
import { useSearchParams } from "react-router-dom";
import useAppDispatch from "../../hooks/useAppDispatch";
import useAppSelector from "../../hooks/useAppSelector";
import { fetchRoutes } from "../../redux/module/route";
import { fetchRouteById } from "../../distflowAPI/routesApi";
import { fetchAccounts } from "../../redux/module/account";
import { fetchVendors } from "../../redux/module/vendor";
import dayjs from "dayjs";
import { fetchDrivers } from "../../redux/module/driver";
import { SortableVendor } from "../addEditRoute/SortableVendor";
import { SortableAccount } from "../addEditRoute/SortableAccount";
import ManagePickupsDialog from "../addEditRoute/ManagePickupsDialog";
import useKeyBy from "../../hooks/useKeyBy";
import { DATE_FORMAT_FRONT } from "../../utils/constants";

const Route = () => {
  const [searchParams] = useSearchParams();
  const routeId = searchParams.get("id");
  const routeDate = searchParams.get("date");
  const [routeDetails, setRouteDetails] = useState({});

  const [selectedRoute, setSelectedRoute] = useState(0);
  const [managePickupsVendorId, setManagePickupsVendorId] = useState();
  const [showManagePickups, setShowManagePickups] = useState(false);

  const dispatch = useAppDispatch();

  const driversMap = useAppSelector((state) => state.driver.driversMap);

  const routes = useAppSelector((state) => state.route.routes);
  const routesMap = useAppSelector((state) => state.route.routesMap);

  const systemAccounts = useAppSelector((state) => state.account.accounts);
  const systemAccountsMap = useKeyBy(systemAccounts, "id");

  useEffect(() => {
    dispatch(fetchAccounts({ pageSize: 200 }));
    dispatch(fetchVendors({ pageSize: 200 }));
    dispatch(fetchRoutes({ pageSize: 200 }));
    dispatch(fetchDrivers());
  }, [dispatch]);

  useEffect(() => {
    fetchRouteById(routeId, routeDate)
      .then((response) => {
        setRouteDetails(response.data);
        const vendorChanges = [];
        for (const scheduleChange of response.data.schedule_changes || []) {
          const {
            schedule_change_vendor_rules: scheduleChangeVendorRules = [],
          } = scheduleChange;
          for (const vendorChange of scheduleChangeVendorRules) {
            vendorChanges.push({
              ...vendorChange.vendor,
              disabled: vendorChange.type === "DISABLED",
              pickup_route: vendorChange.pickup_route,
            });
          }
        }
        setVendors([...(response.data.vendors_calc || []), ...vendorChanges]);
        const accounts = [];
        const disabledAccounts = [];
        const allScheduleChangeAccounts = (response.data.schedule_changes || [])
          .map((item) => item.schedule_change_accounts)
          .flat()
          .reverse();
        for (const account of response.data.accounts_calc) {
          const formattedAccount = { ...account };
          const accountChange = allScheduleChangeAccounts.find(
            (item) =>
              item.account.id === account.account &&
              item.route !== response.data.id,
          );
          if (accountChange) {
            if (accountChange.type === "DISABLED") {
              formattedAccount.fromRoute = accountChange.route;
            } else {
              formattedAccount.toRoute = accountChange.route;
              disabledAccounts.push(formattedAccount);
              continue;
            }
          }
          accounts.push(formattedAccount);
        }
        const disabledForThisRoute = allScheduleChangeAccounts.filter(
          (item) => item.type === "DISABLED" && item.route === response.data.id,
        );
        for (const item of disabledForThisRoute) {
          const to = allScheduleChangeAccounts.find(
            (i) =>
              i.account.id === item.account.id &&
              item.type !== "DISABLED" &&
              item.route !== response.data.id,
          );

          if (
            to &&
            !disabledAccounts.some((i) => i.account === to.account.id)
          ) {
            disabledAccounts.push({
              toRoute: to.route,
              account: to.account.id,
              ...to.account,
            });
          }
        }
        setAccounts(accounts);
        setDisabledAccounts(disabledAccounts);
      })
      .catch((err) => {})
      .finally(() => {
        // hide loader
      });
  }, [routeId, routeDate]);

  const [vendors, setVendors] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [disabledAccounts, setDisabledAccounts] = useState([]);
  const navigate = useNavigate();

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  function handleDragEnd(event) {
    const { over, active } = event;

    if (over && active.data.current.supports.includes(over.data.current.type)) {
      if (over.id === "account") {
        setAccounts((prev) => [...prev, active.id]);
      }
    }
  }

  function sortVendor(event) {
    const { over, active } = event;
    if (active.id !== over.id) {
      setVendors((items) => {
        const oldIndex = items.indexOf(active.id);
        const newIndex = items.indexOf(over.id);

        return arrayMove(items, oldIndex, newIndex);
      });
    }
  }

  function sortAccount(event) {
    const { over, active } = event;
    if (active.id !== over.id) {
      setAccounts((items) => {
        const oldIndex = items.indexOf(active.id);
        const newIndex = items.indexOf(over.id);

        return arrayMove(items, oldIndex, newIndex);
      });
    }
  }

  const openManagePickupsDialog = (vendorId) => {
    setManagePickupsVendorId(vendorId);
    setShowManagePickups(true);
  };

  const closeManagePickupsDialog = (pickupRouteId) => {
    const vendor = vendors.find((x) => x.id == managePickupsVendorId);
    if (vendor) {
      setVendors((prev) => {
        const oldIndex = prev.indexOf(vendor);
        const newState = [...prev];
        const updatedVendor = {
          ...vendor,
          type: "PICKUP_ROUTE",
          pickup_route: pickupRouteId,
        };
        newState[oldIndex] = updatedVendor;
        return newState;
      });
    }

    setShowManagePickups(false);
  };

  return (
    <Box maxWidth="1525px" margin=" 30px auto 0">
      <ManagePickupsDialog
        open={showManagePickups}
        close={closeManagePickupsDialog}
        name={
          managePickupsVendorId &&
          vendors?.find((x) => x.id === managePickupsVendorId)?.name
        }
        currentRouteId={routeId}
      />
      <Stack
        direction={{ xs: "column", sm: "row" }}
        alignItems="center"
        justifyContent="space-between"
        spacing="20px"
      >
        <Stack
          direction="row"
          alignItems="center"
          flexWrap="wrap"
          justifyContent={{ xs: "space-between", sm: "flex-srart" }}
          sx={{ width: { xs: "100%", sm: "initial" }, gap: "20px" }}
        >
          <Button
            onClick={() => navigate(-1)}
            variant="outlined"
            startIcon={<ArrowBackIcon />}
          >
            Back
          </Button>
          <Typography
            variant="pageTitle"
            noWrap
            fontWeight={700}
            color="#3FC6A5"
            component="p"
          >
            {routeDetails.name}
          </Typography>
          <Typography variant="pageTitle" fontWeight={500} noWrap component="p">
            {!!routeDate && dayjs(routeDate).format(DATE_FORMAT_FRONT)}
          </Typography>
        </Stack>
      </Stack>

      <DndContext
        onDragEnd={handleDragEnd}
        modifiers={[restrictToFirstScrollableAncestor]}
      >
        <Grid
          container
          columnSpacing={{ xs: 1, sm: 3, xl: "50px" }}
          margin="36px 0 0"
        >
          <Grid item md={6} lg={7} sx={{ maxWidth: "858px", width: "100%" }}>
            <Block>
              <Typography fontSize="16px" fontWeight={500} mt="5px" mb="17px">
                Changes
              </Typography>
              <ChangesTable routeDetails={routeDetails} />
            </Block>

            <Block>
              <Typography fontSize="16px" fontWeight={500} mt="5px">
                Driver
              </Typography>
              <Separator
                sx={{ margin: { xs: "15px -16px", sm: "15px -24px" } }}
              />
              <Box mt={3}>
                <Chip
                  label={
                    driversMap[routeDetails?.route_calc?.driver]?.user
                      ?.first_name || "Not"
                  }
                  sx={{ borderRadius: "5px" }}
                />
              </Box>
            </Block>

            <Block
              sx={{
                border: "1px dashed #DBDFEA",
                borderRadius: "5px",
                marginBottom: "24px",
                overflow: "hidden",
              }}
            >
              <FormGroup sx={{ padding: "16px 24px" }}>
                <FormControlLabel
                  label="Vendors"
                  labelPlacement="start"
                  sx={{
                    justifyContent: "space-between",
                    margin: 0,
                    gap: "16px",
                    "& span": { fontWeight: 500 },
                  }}
                  control={
                    <TextField
                      margin="none"
                      data-testid="test-vendors-search-id"
                      placeholder="Search"
                      variant="outlined"
                    />
                  }
                />
              </FormGroup>
              <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragEnd={sortVendor}
                modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}
              >
                <SortableContext
                  items={vendors}
                  strategy={verticalListSortingStrategy}
                >
                  {vendors.map((vendor) => (
                    <SortableVendor
                      key={vendor.id}
                      id={vendor.id}
                      title={vendor.name}
                      subtitle={vendor.address}
                      disabled={vendor.disabled}
                      pickup_route={vendor.pickup_route}
                      openManagePickupsDialog={openManagePickupsDialog}
                    />
                  ))}
                </SortableContext>
              </DndContext>
            </Block>

            <Droppable id="account" type="account">
              <Block
                sx={{
                  border: "1px dashed #DBDFEA",
                  borderRadius: "5px",
                  marginBottom: "24px",
                  overflow: "hidden",
                }}
              >
                <FormGroup sx={{ padding: "16px 24px" }}>
                  <FormControlLabel
                    label="Accounts"
                    labelPlacement="start"
                    sx={{
                      justifyContent: "space-between",
                      margin: 0,
                      gap: "16px",
                      "& span": { fontWeight: 500 },
                    }}
                    control={
                      <TextField
                        margin="none"
                        placeholder="Search"
                        data-testid="test-accounts-search-id"
                        variant="outlined"
                      />
                    }
                  />
                </FormGroup>
                {accounts.length > 0 && (
                  <DndContext
                    sensors={sensors}
                    collisionDetection={closestCenter}
                    onDragEnd={sortAccount}
                    modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}
                  >
                    <SortableContext
                      items={accounts}
                      strategy={verticalListSortingStrategy}
                    >
                      <Box>
                        {accounts.length > 0 &&
                          accounts?.map((account) => (
                            <SortableAccount
                              id={account.id}
                              key={account.id}
                              title={systemAccountsMap[account.account]?.name}
                              subtitle={
                                systemAccountsMap[account.account]?.address
                              }
                              note={systemAccountsMap[account.account]?.note}
                              fromRoute={
                                account.fromRoute &&
                                routesMap[account.fromRoute]?.name
                              }
                            />
                          ))}
                      </Box>
                    </SortableContext>
                  </DndContext>
                )}
                {disabledAccounts.map((item) => (
                  <Box
                    key={item.id}
                    sx={{
                      backgroundColor: "#FFF0F0",
                      borderTop: "1px solid #DBDFEA",
                      padding: "10px 22px 10px 16px",
                      touchAction: "none",
                    }}
                  >
                    <Box pl="24px">
                      <Typography sx={{ color: "#FF0000" }}>
                        {item.account}
                      </Typography>
                      <Typography
                        fontSize="11px"
                        fontWeight={700}
                        sx={{ color: "#535151" }}
                      >
                        Changed to route {routesMap[item.toRoute]?.name}
                      </Typography>
                    </Box>
                  </Box>
                ))}
              </Block>
            </Droppable>
          </Grid>

          <Grid item md={6} lg={5} sx={{ width: "100%" }}>
            <Block>
              <Typography fontSize="18px" fontWeight={600} mt="5px">
                Add an account to today’s route
              </Typography>
              <Separator />
              <Stack
                direction="row"
                flexWrap={{ xs: "wrap", xl: "nowrap" }}
                gap="14px"
                margin="10px 0 20px"
              >
                <TextField
                  margin="none"
                  placeholder="Search Accounts"
                  data-testid="test-accounts-search-id"
                  fullWidth
                  variant="outlined"
                />
                <Select value="territory" fullWidth sx={{ minWidth: "163px" }}>
                  <MenuItem value="territory">Territory</MenuItem>
                </Select>
                <Select
                  value={selectedRoute}
                  onChange={(e) => setSelectedRoute(e.target.value)}
                  fullWidth
                  sx={{ minWidth: 205 }}
                >
                  <MenuItem value={0}>None</MenuItem>
                  {routes.map((x) => (
                    <MenuItem value={x.id} key={x.id}>
                      {x.name}
                    </MenuItem>
                  ))}
                </Select>
              </Stack>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: { xs: "1fr", sm: "1fr 1fr" },
                  gap: "8px 22px",
                }}
              >
                {systemAccounts
                  .filter((item) => !accounts.includes(item.id))
                  .map((acc) => (
                    <DraggableAccount
                      key={acc.id}
                      id={acc.id}
                      title={acc.name}
                      subtitle={acc.address}
                    />
                  ))}
              </Box>
            </Block>

            <Block>
              <Typography fontSize="18px" fontWeight={600} mt="5px">
                Add order to RT
              </Typography>
              <Separator />
              <Stack
                direction="row"
                flexWrap={{ xs: "wrap", xl: "nowrap" }}
                gap="14px"
                margin="10px 0 20px"
              >
                <TextField
                  margin="none"
                  placeholder="Search Accounts"
                  data-testid="test-accaunt-searche-id"
                  fullWidth
                  variant="outlined"
                />
                <Select value="territory" fullWidth sx={{ minWidth: "163px" }}>
                  <MenuItem value="territory">Territory</MenuItem>
                </Select>
                <Select value="route" fullWidth sx={{ minWidth: "205px" }}>
                  <MenuItem value="route">Route</MenuItem>
                </Select>
              </Stack>
            </Block>
          </Grid>
        </Grid>
      </DndContext>
    </Box>
  );
};

export default Route;
