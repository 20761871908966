import DataTable from "../../components/DataTable";
import {
  Box,
  Stack,
  Typography,
  Button,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  formatAgGridFilters,
  formatAgGridSorting,
} from "../../distflowAPI/common";
import { useSearchParams } from "react-router-dom";
import PageLoading from "../../components/PageLoading";
import { listStatements } from "../../distflowAPI/invoicesApi";
import { downloadFile } from "../../distflowAPI/downloadFile";

import GenerateStatements from "./GenerateStatements";
function StatemrntLinkRenderer(params) {
  function getCustomerName() {
    return params?.data?.customer?.name
      ? ` ${params?.data?.customer?.name}`
      : " All Customers";
  }
  function getAccountName() {
    return params?.data?.account?.name
      ? ` ${params?.data?.account?.name}`
      : " All Accounts";
  }
  function getFileName() {
    return `statement from ${params?.data?.start_date} till ${
      params?.data?.end_date
    }${getCustomerName()}${getAccountName()}.zip`;
  }
  return (
    <Stack direction={"row"} sx={{ width: "100%", flexWrap: "wrap" }} p={1}>
      {params?.data?.file && (
        <Button
          variant="contained"
          onClick={() => downloadFile(params?.data?.file, getFileName())}
        >
          Download
        </Button>
      )}
    </Stack>
  );
}
const defaultColDef = {
  flex: 1,
  filter: false,
  floatingFilter: false,
};

const columns = [
  {
    headerName: "ID",
    field: "id",
    maxWidth: 80,
  },
  {
    headerName: "From",
    field: "start_date",
    maxWidth: 120,
  },
  {
    headerName: "Till",
    field: "end_date",
    maxWidth: 120,
  },
  {
    headerName: "Created At",
    field: "created_at",
  },
  {
    headerName: "Status",
    field: "status",
    maxWidth: 150,
  },
  {
    headerName: "Customer",
    field: "customer",
    valueGetter: (params) => params?.data?.customer?.name || "-",
    maxWidth: 150,
  },
  {
    headerName: "Account",
    field: "account",
    valueGetter: (params) =>
      params?.data?.accounts.map((account) => account.name).join(",") || "-",
    maxWidth: 150,
  },
  {
    headerName: "Download Link",
    field: "file",
    cellRendererSelector: () => {
      return {
        component: StatemrntLinkRenderer,
      };
    },
  },
];
export default function StatementReports() {
  const [isLoading, setIsLoading] = useState(false);
  const [statements, setStatements] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [orderBy, setOrderBy] = useState([]);
  const [filterBy, setFilterBy] = useState([]);
  const [numberOfPages, setNumberOfPages] = useState(1);

  function updateStatementTable() {
    setIsLoading(true);
    listStatements(page, pageSize, orderBy, filterBy).then((res) => {
      setStatements(res.data.results);
      setIsLoading(false);
    });
  }
  useEffect(() => {
    updateStatementTable();
  }, [pageSize, page, filterBy, orderBy]);

  const handleChange = (field, value) => {
    if (field === "filterBy" || field === "pageSize") {
      setPage(1);
    }
    setFilterBy({ field, value });
  };

  const getRowStyle = (params) => {
    if (params.data.status === "VOIDED") {
      return { background: "#fff0f0" };
    }
  };

  return (
    <>
      <PageLoading dataLoading={isLoading} />
      <Stack direction={"row"} spacing={4} justifyContent={"space-between"}>
        <Typography
          fontSize="23px"
          fontWeight={800}
          component="h2"
          data-testid="page-title"
        >
          Statement Reports
        </Typography>
        <GenerateStatements updateStatementTable={updateStatementTable} />
      </Stack>

      <Stack mt={2}>
        <Box className="table-wrapper">
          <DataTable
            rowData={statements}
            columnDefs={columns}
            getRowStyle={getRowStyle}
            defaultColDef={defaultColDef}
            loading={isLoading}
            suppressSorting={true} //disable default sorting
            rowHeight={53}
            headerHeight={72}
            onSortChanged={(event) =>
              handleChange(
                "orderBy",
                formatAgGridSorting(event.columnApi.columnModel.gridColumns)
              )
            }
            onFilterChanged={(event) =>
              handleChange(
                "filterBy",
                formatAgGridFilters(event.api.getFilterModel())
              )
            }
            page={page}
            pageSize={pageSize}
            setPageSize={(pageSize) => handleChange("pageSize", pageSize)}
            noPages={numberOfPages}
            setPage={(page) => handleChange("page", page)}
          />
        </Box>
      </Stack>
    </>
  );
}
