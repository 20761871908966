import React, { useState } from "react";
import { Grid, Table, TableBody } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useModal } from "mui-modal-provider";
import UpdateOverrideProductModal from "../../components/UpdateOverrideProductModal";
import OverrideAccordionItem from "../../components/OverrideAccordionItem";
import { fireErrorNotification, fireSuccessNotification } from "../../utils";

const ProductOverrideAccordion = ({
  overrides,
  productId,
  variantId,
  elementId,
  updateOverridesTable,
  elementKey,
  deleteOverride,
  updateOverride,
  upcharges,
  cost,
}) => {
  const { showModal } = useModal();
  const [expanded, setExpanded] = useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleDelete = async (override) => {
    await deleteOverride(productId, override.id, elementKey, variantId).then(
      (res) => {
        fireSuccessNotification(
          `Product ${elementKey} override deleted successfully`,
        );
        updateOverridesTable();
      },
      (error) => {
        const errorFields = Object.keys(error.response.data);
        errorFields.forEach((field) => {
          fireErrorNotification(error.response.data[field][0]);
        });
      },
    );
  };

  const handleUpdate = (override) => {
    showModal(UpdateOverrideProductModal, {
      override,
      updateOverride,
      updateOverridesTable,
      productId,
      variantId,
      elementKey,
    });
  };

  return (
    <Accordion
      expanded={expanded === elementId}
      onChange={handleChange(elementId)}
      sx={{ backgroundColor: "#f6f6f7" }}
    >
      <AccordionSummary
        style={{ backgroundColor: "#E6E6E6" }}
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
        sx={{ margin: 0 }}
      >
        <Grid container margin="2px 0 0">
          <Grid item sm={8} md={12} xl={8}>
            {overrides[elementId][`${elementKey}_name`]}{" "}
            {overrides[elementId].items.filter((o) => o.is_current == true)
              .length == 0 ? (
              <span style={{ color: "red" }}> Inactive</span>
            ) : (
              ""
            )}
          </Grid>
          <Grid item sm={4} md={12} xl={4}>
            <b>{overrides[elementId].items.length}</b> overrides
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: 0, overflow: "auto" }}>
        <Table>
          <TableBody>
            {overrides[elementId] &&
              overrides[elementId].items.map((item) => (
                <OverrideAccordionItem
                  key={item.id}
                  item={item}
                  handleDelete={handleDelete}
                  handleUpdate={handleUpdate}
                  elementKey={elementKey}
                  cost={cost}
                  upcharges={upcharges}
                />
              ))}
          </TableBody>
        </Table>
      </AccordionDetails>
    </Accordion>
  );
};

export default ProductOverrideAccordion;
