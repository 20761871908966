import ApiBase from "./apiBase";
import api from "./api";

const routesApi = new ApiBase("/routes2/routes");

/**
 * @param {Number} routeId
 * @param {String} date
 * @returns A Route with respective changes according to passed date.
 */
export async function fetchRouteById(routeId, date) {
  const params = new URLSearchParams({ date });
  return api.get(`/api/routes2/routes/${routeId}/?${params}`);
}

export async function createRouteEmail(route, email) {
  const res = await api.post(`/api/routes2/route_emails/`, { route, email });
  return res;
}

export async function listRouteEmails(route) {
  const res = await api.get(`/api/routes2/routes/${route}/`);
  return res.data.emails;
}

export async function deleteRouteEmail(id) {
  const res = await api.delete(`/api/routes2/route_emails/${id}/`);
  return res;
}

export async function updateRouteEmail(email, newEmail) {
  const res = await api.put(`/api/routes2/route_emails/${email}/`, {
    email: newEmail,
  });
  return res;
}

export default routesApi;
