import DataTable from "../../components/DataTable";
import {
  Box,
  Stack,
  Typography,
  Button,
  RadioGroup,
  Radio,
  FormControlLabel,
  Alert,
  Grid,
  Input,
  IconButton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  formatAgGridFilters,
  formatAgGridSorting,
} from "../../distflowAPI/common";
import { Link, useParams, useSearchParams } from "react-router-dom";
import PageLoading from "../../components/PageLoading";
import {
  cCimportBankTransactions,
  cClistBankTransactions,
  cClistBankTransactionsBatches,
  cCupdateBankDeposit,
  getCcBatchSummary,
} from "../../distflowAPI/ordersApi";
import Block from "../../components/Block";
import DatePicker from "../../components/DatePicker2";
import MatchingTransactionsModal from "./MatchingTransactionsModal";
import { Cancel, Check, Edit, Save } from "@mui/icons-material";
import ConfirmDialog from "../../components/ConfirmDialog";
const defaultColDef = {
  flex: 1,
  filter: false,
  floatingFilter: false,
};
const columnsBatches = [
  {
    headerName: "Batch",
    field: "id",
    maxWidth: 150,
    cellRenderer: (params) => (
      <Typography>
        <Link to={`/invoices/bank_transactions_batch/${params.data.id}`}>
          Batch #{params.data.id}
        </Link>
      </Typography>
    ),
    sortable: true,
    comparator: () => {},
  },
  {
    headerName: "Uploaded At",
    field: "created",
    maxWidth: 250,
    sortable: true,
    comparator: () => {},
  },
  {
    headerName: "Total transactions",
    field: "transactions",
    maxWidth: 250,
    sortable: true,
    comparator: () => {},
  },
  {
    headerName: "Matched transactions",
    field: "matched_transactions",
    maxWidth: 250,
    sortable: true,
    comparator: () => {},
  },
  {
    headerName: "Non Matched transactions",
    field: "unmatched_transactions",
    maxWidth: 250,
    sortable: true,
    comparator: () => {},
  },
  {
    headerName: "Uploaded By",
    valueGetter: (params) =>
      `${params.data.uploaded_by.first_name} ${params.data.uploaded_by.last_name}`,
    field: "uploaded_by",
    sortable: true,
    comparator: () => {},
  },
];
function NoteRenderer(params) {
  const [isEditing, setIsEditing] = useState(false);
  const [val, setVal] = useState(params.data.note);
  const [note, setNote] = useState(val);
  function updateNote() {
    cCupdateBankDeposit(params.data.id, { note }).then((res) => {
      setVal(note);
      setIsEditing(false);
    });
  }
  return (
    <Stack
      direction={"row"}
      justifyContent="space-between"
      alignItems={"center"}
    >
      {isEditing ? (
        <>
          <Input value={note} onChange={(e) => setNote(e.target.value)} />
          <IconButton onClick={() => updateNote()}>
            <Check />
          </IconButton>
          <IconButton
            onClick={() => {
              setNote(val);
              setIsEditing(false);
            }}
          >
            <Cancel />
          </IconButton>
        </>
      ) : (
        <>
          <Typography>{note || "-"}</Typography>
          <IconButton onClick={() => setIsEditing(true)}>
            <Edit />
          </IconButton>
        </>
      )}
    </Stack>
  );
}
export default function CCBankTransactionsBatch() {
  const { id } = useParams();
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [statements, setStatements] = useState([]);
  const [summary, setSummary] = useState({
    matchedCount: 0,
    unmatchedCount: 0,
    matchedTotal: 0,
    unmatchedTotal: 0,
    batchTotal: 0,
    batchCount: 0,
  });
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [orderBy, setOrderBy] = useState([]);
  const [filterBy, setFilterBy] = useState("group=" + id);
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [view, setView] = useState("TRANSACTIONS");
  const [batches, setBatches] = useState([]);
  const [filters, setFilters] = useState({
    matched: null,
    date__gte: null,
    date__lte: null,
    amount__gte: null,
    amount__lte: null,
    group: id,
  });
  const [open, setOpen] = useState(false);
  useEffect(() => {
    let newFilterBy = "";
    let cc = 0;
    Object.keys(filters).map((fk, fi) => {
      if (filters[fk]) {
        let filterVal = filters[fk];
        if (fk.includes("date")) {
          filterVal = filterVal.format("YYYY-MM-DD");
        }
        newFilterBy += `${cc > 0 ? "&" : ""}${fk}=${filterVal}`;
        cc++;
      }
    });
    setFilterBy(newFilterBy);
  }, [filters]);
  function updateStatementTable() {
    setIsLoading(true);
    cClistBankTransactions(page, pageSize, orderBy, filterBy).then((res) => {
      const npages = parseInt(res.data.count / pageSize);
      if (npages == res.data.count) {
        setNumberOfPages(npages);
      } else {
        setNumberOfPages(npages + 1);
      }
      setStatements(res.data.results);
      setIsLoading(false);
    });
    getCcBatchSummary(id).then((res) => setSummary(res.data));
  }
  useEffect(() => {
    updateStatementTable();
  }, [page, filterBy, orderBy]);
  useEffect(() => {
    if (page == 1) {
      updateStatementTable();
    }
    setPage(1);
  }, [pageSize, view]);

  const handleChange = (field, value) => {
    if (field === "filterBy" || field === "pageSize") {
      setPage(1);
    }
    setFilterBy({ field, value });
  };

  const getRowStyle = (params) => {
    if (params.data.status === "VOIDED") {
      return { background: "#fff0f0" };
    }
  };
  const [csvFiles, setCsvFiles] = useState();
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  function handleSubmit() {
    setError("");
    setSuccess("");
    if (!csvFiles) {
      setError("Include a valid file");
    }
    const payload = new FormData();
    for (let i = 0; i < csvFiles.length; i++) {
      payload.append(`csv_files.${i}`, csvFiles[i], csvFiles[i].name);
    }
    cCimportBankTransactions(payload).then(
      (res) => {
        setSuccess(
          `Upload complete, items imported: ${res.data.total_created}`
        );
        if (res.data.errors.length > 0) {
          setError(`Total errors: ${res.data.errors.length}`);
        }
      },
      (err) => setError("Error importing data")
    );
  }
  const [isOpenUnmatch, setIsOpenUnmatch] = useState(false);
  const columns = [
    {
      headerName: "Date",
      field: "date",
      maxWidth: 150,
      sortable: true,
      comparator: () => {},
    },
    {
      headerName: "Amount",
      field: "amount",
      maxWidth: 150,
      sortable: true,
      comparator: () => {},
    },
    {
      headerName: "Ref",
      field: "ref",
      maxWidth: 150,
      sortable: true,
      comparator: () => {},
    },
    {
      headerName: "4 digits",
      field: "cc_digit",
      maxWidth: 150,
      sortable: true,
      comparator: () => {},
    },
    {
      headerName: "Card Type",
      field: "card_type",
      maxWidth: 150,
      sortable: true,
      comparator: () => {},
    },
    {
      headerName: "Note",
      field: "note",
      cellRenderer: NoteRenderer,
    },
    {
      headerName: "Matched Payment",
      field: "payment",
      maxWidth: 230,
      cellRenderer: (params) => {
        return (
          <>
            {params.data.payment && (
              <Stack direction={"row"} alignItems={"center"}>
                <Typography>
                  <Link
                    to={`/invoices/payments/${params.data.payment_group}/details`}
                  >
                    #{params.data.payment}
                  </Link>
                </Typography>
                <Button
                  onClick={() => {
                    setIsOpenUnmatch(params.data.id);
                  }}
                >
                  Unmatch
                </Button>
              </Stack>
            )}
            {!params.data.payment && (
              <Stack direction={"row"} spacing={2} alignItems={"center"}>
                <Button
                  variant="outlined"
                  onClick={() => handleOpen(params.rowIndex)}
                  className="MuiButton-sizeSmall"
                >
                  Match ({params.data.cc_possible_matches.length} P.M)
                </Button>
              </Stack>
            )}
          </>
        );
      },
      sortable: true,
    },
    {
      headerName: "Auto?",
      maxWidth: 90,
      valueGetter: (params) =>
        params.node.data.payment
          ? params.node.data.manual_match
            ? "No"
            : "Yes"
          : "-",
    },
  ];
  function handleOpen(rowIndex) {
    setSelectedTransaction(rowIndex);
    setOpen(true);
  }

  return (
    <>
      <ConfirmDialog
        title="Unmatch payment?"
        open={isOpenUnmatch}
        setOpen={setIsOpenUnmatch}
        onConfirm={() => {
          cCupdateBankDeposit(isOpenUnmatch, { payment: null }).then((res) => {
            setIsOpenUnmatch(false);
            updateStatementTable();
          });
        }}
      >
        {`Are you sure you want to unmatch this transaction (#${isOpenUnmatch})?`}
      </ConfirmDialog>
      <PageLoading dataLoading={isLoading} />
      <Stack direction={"row"} spacing={4} justifyContent={"space-between"}>
        <Stack>
          <MatchingTransactionsModal
            statements={statements}
            selectedTrans={selectedTransaction}
            open={open}
            setOpen={setOpen}
            updateStatementTable={updateStatementTable}
          />
          <Typography
            fontSize="23px"
            fontWeight={800}
            component="h2"
            data-testid="page-title"
          >
            CC Bank Transactions Batch #{id}
          </Typography>
        </Stack>

        {success && <Alert>{success}</Alert>}
        {error && <Alert severity="error">{error}</Alert>}
      </Stack>
      <Block>
        <Grid container spacing={3}>
          <Grid item md="12">
            <Stack direction="row" justifyContent={"space-between"}>
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                spacing={3}
              >
                <RadioGroup
                  row
                  value={filters.matched}
                  onChange={(e) =>
                    setFilters({ ...filters, matched: e.target.value })
                  }
                >
                  <FormControlLabel
                    value={null}
                    control={<Radio />}
                    label="All"
                  />
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="Matched"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="Non Matched"
                  />
                </RadioGroup>
                <DatePicker
                  controlledValue={filters.date__gte}
                  setControlledValue={(e) =>
                    setFilters({ ...filters, date__gte: e })
                  }
                  label={"Created After"}
                />
                <DatePicker
                  controlledValue={filters.date__lte}
                  setControlledValue={(e) =>
                    setFilters({ ...filters, date__lte: e })
                  }
                  label={"Created Before"}
                />
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Block>

      <Stack mt={2} spacing={2}>
        <Box>
          <Block>
            <Stack spacing={1}>
              <Typography>
                Summary{" "}
                {summary.unmatchedTotal > 1 ? (
                  <span style={{ color: "red" }}>INCOMPLETE</span>
                ) : (
                  <span>COMPLETE</span>
                )}
              </Typography>
              <Stack spacing={1} direction={"row"}>
                <Typography>Batch count {summary.batchCount}</Typography>
                <Typography>Matched: {summary.matchedCount}</Typography>
                <Typography>Remaining: {summary.unmatchedCount}</Typography>
                <Typography>Batch Total: ${summary.batchTotal}</Typography>
                <Typography>Matched Total: ${summary.matchedTotal}</Typography>
                <Typography>
                  <b>Remaining: ${summary.unmatchedTotal}</b>
                </Typography>
                <Typography></Typography>
              </Stack>
            </Stack>
          </Block>
        </Box>
        <Box className="table-wrapper">
          <DataTable
            rowData={statements}
            columnDefs={columns}
            getRowStyle={getRowStyle}
            defaultColDef={defaultColDef}
            loading={isLoading}
            suppressSorting={true} //disable default sorting
            rowHeight={53}
            headerHeight={72}
            onSortChanged={(event) =>
              setOrderBy(
                formatAgGridSorting(event.columnApi.columnModel.gridColumns)
              )
            }
            onFilterChanged={(event) =>
              handleChange(
                "filterBy",
                formatAgGridFilters(event.api.getFilterModel())
              )
            }
            page={page}
            pageSize={pageSize}
            setPageSize={(pageSize) => setPageSize(pageSize)}
            noPages={numberOfPages}
            setPage={(page) => setPage(page)}
          />
        </Box>
      </Stack>
    </>
  );
}
