import { getManager, updateManager } from "../../distflowAPI/managersApi";
import { getCustomers } from "../../distflowAPI/customersApi";
import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  Stack,
  Alert,
  Grid,
} from "@mui/material";
import CustomerSelect from "../../components/AsyncSelects/CustomerSelect";
import Block from "../../components/Block";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ConfirmDialog from "../../components/ConfirmDialog";
import { errorToJson } from "../../utils";
import AccountSelect from "../../components/AsyncSelects/AccountSelect";

function UpdateManager() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [userInfo, setUserInfo] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
  });
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [customerSearch, setCustomerSearch] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [selectedCustomersAccounting, setSelectedCustomersAccounting] =
    useState([]);
  const [customerSearchAccounting, setCustomerSearchAccounting] = useState("");
  const [success, setSuccess] = useState("");
  const [errors, setErrors] = useState({});
  const formFields = [
    // { id: 1, name: "userName", label: "User name", type: "text", value: userInfo.userName },
    {
      id: 2,
      name: "first_name",
      label: "First name",
      type: "text",
      value: userInfo.first_name,
      required: true,
    },
    {
      id: 3,
      name: "last_name",
      label: "Last name",
      type: "text",
      value: userInfo.last_name,
      required: true,
    },
    {
      id: 4,
      name: "email",
      label: "Email",
      type: "email",
      value: userInfo.email,
      required: true,
    },
    {
      id: 5,
      name: "password",
      label: "Password",
      type: "password",
      value: userInfo.password,
      required: false,
    },
  ];
  function updateData() {
    getManager(id).then((res) => {
      setUserInfo({
        first_name: res.data.user.first_name,
        last_name: res.data.user.last_name,
        email: res.data.user.email,
      });

      res.data.accounts.map((item) =>
        setSelectedCustomers({
          value: item.id,
          label: item.name,
        }),
      );
      setSelectedCustomersAccounting(
        res.data.accounting_managers.map((item) => {
          return {
            value: item.id,
            label: item.name,
          };
        }),
      );
    });
  }
  useEffect(() => {
    updateData();
  }, [id]);
  function handleSubmit(e) {
    e.preventDefault();
    setIsSubmitting(true);
    const payload = {
      user: {
        first_name: userInfo.first_name,
        last_name: userInfo.last_name,
        email: userInfo.email,
      },
      accounts: selectedCustomers?.value ? [selectedCustomers?.value] : [],
      accounting_managers: selectedCustomersAccounting.map(
        (item) => item.value,
      ),
    };
    if (userInfo.password) {
      payload["user"]["password"] = userInfo.password;
    }
    updateManager(id, payload).then(
      (res) => {
        setSuccess("Manager updated successfully");
        setIsSubmitting(false);
      },
      (err) => {
        setErrors(
          errorToJson(err?.response?.data || { error: "Unexpected error" }),
        );
        setIsSubmitting(false);
      },
    );
  }
  function handleDelete() {}
  return (
    <>
      <Stack
        direction={{ xs: "column", md: "row" }}
        alignItems={{ xs: "flex-start", md: "center" }}
        justifyContent="space-between"
        spacing="20px"
        marginBottom={2}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
          sx={{ width: { xs: "100%", sm: "initial" }, gap: "20px" }}
        >
          <Button
            className="btn-height"
            onClick={() => navigate("/managers")}
            variant="outlined"
            startIcon={<ArrowBackIcon />}
          >
            Back
          </Button>
          <Typography variant="pageTitle" component="p">
            {userInfo.first_name} {userInfo.last_name}
          </Typography>
        </Stack>
        <Stack
          direction={{ sm: "row-reverse", md: "row" }}
          alignItems="center"
          justifyContent="flex-end"
          flexWrap="wrap"
          sx={{
            width: {
              xs: "100%",
              sm: "initial",
            },
            gap: "11px",
            "@media(max-width: 600px)": {
              flexDirection: "row-reverse",
              width: "100%",
            },
            "@media(max-width: 480px)": {
              flexDirection: "column-reverse",
              width: "100%",
              button: { width: "100%" },
            },
          }}
        >
          <ConfirmDialog
            title="Delete user?"
            open={isOpenDelete}
            setOpen={setIsOpenDelete}
            onConfirm={handleDelete}
          >
            {`Are you sure you want to delete this product?`}
          </ConfirmDialog>
          <Button
            className="btn-height"
            variant="contained"
            color="error"
            onClick={() => setIsOpenDelete(true)}
          >
            Delete
          </Button>
          <Button
            className="btn-height"
            color="black"
            variant="contained"
            form="managerUpdate"
            type="submit"
          >
            Save & continue Editing
          </Button>
        </Stack>
      </Stack>
      {success && <Alert>{success}</Alert>}
      {Object.keys(errors).map((key) => (
        <Alert severity="error">
          {key}: {errors[key]}
        </Alert>
      ))}
      <Block>
        <form id="managerUpdate" onSubmit={handleSubmit}>
          <Grid container>
            <Grid md="8" paddingRight={1}>
              {formFields.map((el) => (
                <TextField
                  key={el.id}
                  required={el.required}
                  name={el.name}
                  label={el.label}
                  type={el.type}
                  value={el.value}
                  onChange={(e) =>
                    setUserInfo({ ...userInfo, [el.name]: e.target.value })
                  }
                  fullWidth
                />
              ))}
            </Grid>
            <Grid md="8" marginTop={2}>
              <AccountSelect
                label="Manage orders for"
                id="multiple-limit-tags"
                value={selectedCustomers}
                onChange={(_event, newValue) => {
                  setSelectedCustomers(newValue);
                }}
                setCustomerSearch={setCustomerSearch}
              />
            </Grid>
            <Grid md="4" marginTop={2}></Grid>
            <Grid md="8" marginTop={2}>
              <AccountSelect
                label="Manage Accounting for"
                id="multiple-limit-tags-xx"
                value={selectedCustomersAccounting}
                onChange={(_event, newValue) => {
                  setSelectedCustomersAccounting(newValue);
                }}
                multiple={true}
                //setCustomerSearch={setCustomerSearchAccounting}
              />
            </Grid>
          </Grid>
        </form>
      </Block>
    </>
  );
}

export default UpdateManager;
