import React, { useEffect, useMemo, useState } from "react";
import Block from "../../components/Block";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  OutlinedInput,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { useLocation, useSearchParams } from "react-router-dom";
import { useSearchDebounce } from "../../utils";
import ArrowUpwardOutlinedIcon from "@mui/icons-material/ArrowUpwardOutlined";
import ArrowDownwardOutlinedIcon from "@mui/icons-material/ArrowDownwardOutlined";
import { DATE_FORMAT } from "../../utils/constants";
import dayjs from "dayjs";
import DatePicker2 from "../../components/DatePicker2";
import AccountSelect from "../../components/AsyncSelects/AccountSelect";
var weekday = require("dayjs/plugin/weekday");
dayjs.extend(weekday);

const InvoiceFilters = (params) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { pathname } = useLocation();
  const [myCustomers, setMyCustomers] = useState(false);
  const INIT_FILTERS = {
    id: searchParams.get("id") || "",
    status: searchParams.get("status") || "",
    invoice__balance__gt: searchParams.get("invoice__balance__gt") || "",
    invoice__balance__lt: searchParams.get("invoice__balance__lt") || "",
    created__lt: searchParams.get("created__lt") || "",
    created__gte: searchParams.get("created__gte") || "",
    customer__name: searchParams.get("customer__name") || "",
    account__name: searchParams.get("account__name") || "",
    past_due: searchParams.get("past_due") || "",
    route: searchParams.get("route") || "",
    myCustomers: searchParams.get("myCustomers") || "",
  };
  const [filterDB, setFilterDB] = useSearchDebounce(500, INIT_FILTERS);

  const [filters, setFilters] = useState(INIT_FILTERS);

  const hideShowAccountFilter = pathname.includes("dashboard");

  const handleChange = (value) => {
    console.log("handling change..", value);
    setSearchParams((params) => {
      const newParams = new URLSearchParams(params);
      Object.keys(value).forEach((key) => {
        if (!!value[key]) {
          newParams.set(key, value[key]);
        } else {
          newParams.delete(key);
        }
      });
      console.log("setting page to 1");
      newParams.set("page", 1);
      return newParams;
    });
  };

  useEffect(() => {
    if (myCustomers && !hideShowAccountFilter) {
      setFilters({ ...filters, myCustomers: myCustomers });
    }
  }, [myCustomers]);

  useEffect(() => {
    const isSearchParamsEmpty = Array.from(searchParams.values()).every(
      (value) => value === null || value === ""
    );
    if (isSearchParamsEmpty) {
      console.log("set search params empty..");
      setFilters({
        id: "",
        status: "",
        invoice__balance__gt: "",
        invoice__balance__lt: "",
        created__lt: "",
        created__gte: "",
        customer__name: "",
        account__name: "",
        past_due: "",
        route: "",
        myCustomers: "",
      });
    }
  }, [searchParams]);

  useEffect(() => {
    setFilterDB(filters);
  }, [filters]);

  useEffect(() => {
    handleChange(filterDB);
  }, [filterDB]);

  const handleStatusChange = (event) => {
    const { value, checked } = event.target;

    setFilters((prevFilters) => {
      let updatedStatus;

      if (value === "") {
        updatedStatus = checked ? "" : "";
      } else {
        const statusArray = prevFilters.status.split(",");

        if (checked) {
          if (value == "UNPAID") {
            updatedStatus = [...statusArray, "UNPAID", "CREDIT"];
          } else {
            updatedStatus = [...statusArray, value];
          }
        } else {
          if (value == "UNPAID") {
            updatedStatus = statusArray
              .filter((status) => status !== value)
              .filter((s) => s !== "CREDIT");
          } else {
            updatedStatus = statusArray.filter((status) => status !== value);
          }

          if (updatedStatus.length === 0) {
            updatedStatus = "";
          }
        }
      }
      updatedStatus = updatedStatus.length
        ? updatedStatus.filter((el) => el).join(",")
        : "";
      return { ...prevFilters, status: updatedStatus, past_due: "" };
    });
  };

  const handleShowMyChange = (_, checked) => {
    if (checked) {
      setFilters({ ...filters, myCustomers: true });
      setMyCustomers(true);
    } else {
      setFilters({ ...filters, myCustomers: false });
      setMyCustomers(false);
    }
  };

  const handlePastDueChange = () => {
    if (filters.past_due) {
      setFilters({ ...filters, past_due: "" });
    } else {
      setFilters({ ...filters, past_due: "true" });
    }
  };

  const isShowPastDue = useMemo(() => {
    if (!filters.status) return false;
    if (filters.status.split(",").includes("VOIDED")) return false;
    if (filters.status.split(",").includes("PAID")) return false;
    return true;
  }, [filters]);

  function handleToday() {
    setFilters({
      ...filters,
      created__lt: dayjs().utc().format(DATE_FORMAT),
      created__gte: dayjs().utc().format(DATE_FORMAT),
    });
  }
  function handleThisWeek() {
    setFilters({
      ...filters,
      created__lt: dayjs().utc().format(DATE_FORMAT),
      created__gte: dayjs().weekday(0).format(DATE_FORMAT),
    });
  }
  function handleMTD() {
    setFilters({
      ...filters,
      created__lt: dayjs().utc().format(DATE_FORMAT),
      created__gte: dayjs().startOf("month").format(DATE_FORMAT),
    });
  }
  function handleAll() {
    setFilters({
      ...filters,
      created__lt: null,
      created__gte: null,
    });
  }
  return (
    <Block>
      <Grid container spacing={1} rowGap="10px">
        <Grid md={12}>
          <Stack direction="row" spacing={1} paddingLeft={1} paddingTop={1}>
            <Stack>
              <Stack direction="row" gap={1} alignItems="center">
                <Typography mb="5px">Created After</Typography>
              </Stack>
              <DatePicker2
                controlledValue={
                  filters.created__gte ? dayjs.utc(filters.created__gte) : null
                }
                setControlledValue={(newValue) => {
                  setFilters({
                    ...filters,
                    created__gte: newValue
                      ? dayjs(newValue).format(DATE_FORMAT)
                      : "",
                  });
                }}
                additionlStyles={{
                  height: "40px",
                  width: "100%",
                  "& .MuiInputBase-root.Mui-error .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "#DBDFEA",
                    },
                }}
              />
            </Stack>
            <Stack>
              <Stack direction="row" gap={1} alignItems="center">
                <Typography mb="5px">Created Before</Typography>
              </Stack>
              <DatePicker2
                controlledValue={
                  filters.created__lt ? dayjs.utc(filters.created__lt) : null
                }
                setControlledValue={(newValue) => {
                  setFilters({
                    ...filters,
                    created__lt: newValue
                      ? dayjs(newValue).format(DATE_FORMAT)
                      : "",
                  });
                }}
                additionlStyles={{
                  height: "40px",
                  width: "100%",
                  "& .MuiInputBase-root.Mui-error .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "#DBDFEA",
                    },
                }}
              />
            </Stack>
            <Stack direction={"row"} spacing={1} paddingTop={3.5}>
              <Button variant="contained" onClick={handleToday}>
                Today
              </Button>
              <Button variant="contained" onClick={handleThisWeek}>
                This week
              </Button>
              <Button variant="contained" onClick={handleMTD}>
                Month to date
              </Button>
              <Button variant="contained" onClick={handleAll}>
                All
              </Button>
            </Stack>
            {!hideShowAccountFilter && (
              <Stack paddingLeft={2}>
                <Typography mb="5px">My customers only</Typography>
                <Switch
                  checked={myCustomers}
                  onChange={handleShowMyChange}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </Stack>
            )}
          </Stack>
        </Grid>
        <Grid item xs={12} sm={2}>
          <Typography mb="5px">Invoice No</Typography>
          <OutlinedInput
            size="small"
            sx={{ height: "40px" }}
            fullWidth
            value={filters.id}
            onChange={(e) => setFilters({ ...filters, id: e.target.value })}
          />
        </Grid>
        {!params.omitCustomer && (
          <Grid item xs={12} sm={3}>
            <Typography mb="5px">Customer</Typography>
            <OutlinedInput
              size="small"
              sx={{ height: "40px" }}
              fullWidth
              value={filters.customer__name}
              onChange={(e) =>
                setFilters({ ...filters, customer__name: e.target.value })
              }
            />
          </Grid>
        )}

        {!params.accountSelect && (
          <Grid item xs={12} sm={3}>
            <Typography mb="5px">Account</Typography>
            <OutlinedInput
              size="small"
              sx={{ height: "40px" }}
              fullWidth
              value={filters.account__name}
              onChange={(e) =>
                setFilters({ ...filters, account__name: e.target.value })
              }
            />
          </Grid>
        )}
        {params.accountSelect && (
          <Grid item xs={12} sm={3}>
            <Stack>
              <Typography mb="5px">Account</Typography>
              <AccountSelect
                customerId={params.customerId}
                label={"Account"}
                onChange={(event, newValue) => {
                  if (newValue) {
                    if (newValue.value) {
                      setFilters({ ...filters, account_id: newValue?.value });
                    } else {
                      setFilters({ ...filters, account_id: null });
                    }
                  } else {
                    setFilters({ ...filters, account_id: null });
                  }
                }}
              />
            </Stack>
          </Grid>
        )}
        <Grid item xs={12} sm={2}>
          <Typography mb="5px">Route</Typography>
          <OutlinedInput
            size="small"
            sx={{ height: "40px" }}
            fullWidth
            value={filters.route}
            onChange={(e) => setFilters({ ...filters, route: e.target.value })}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Typography mb="5px">Balance GTE</Typography>
          <Stack direction={"row"} gap={1}>
            <OutlinedInput
              label={"Balance GTE"}
              size="small"
              sx={{ height: "40px" }}
              fullWidth
              value={filters.invoice__balance__gt}
              type="number"
              onChange={(e) =>
                setFilters({ ...filters, invoice__balance__gt: e.target.value })
              }
            />
          </Stack>
        </Grid>
        <Grid item xs={12} sm={2}>
          <Typography mb="5px">Balance LTE</Typography>
          <Stack direction={"row"} gap={1}>
            <OutlinedInput
              label={"Balance LTE"}
              size="small"
              sx={{ height: "40px" }}
              fullWidth
              value={filters.invoice__balance__lt}
              type="number"
              onChange={(e) =>
                setFilters({ ...filters, invoice__balance__lt: e.target.value })
              }
            />
          </Stack>
        </Grid>
        <Grid
          item
          sx={{ width: "100%", maxWidth: { sm: "620px", xs: "100%" } }}
        >
          <Typography mb="5px">Payment status</Typography>
          <FormGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            sx={{ border: "none !important", padding: "0 !important" }}
          >
            <FormControlLabel
              value=""
              control={
                <Checkbox
                  checked={filters.status === ""}
                  onChange={handleStatusChange}
                />
              }
              label="All"
            />
            <FormControlLabel
              value="PAID"
              control={
                <Checkbox
                  checked={filters.status.split(",").includes("PAID")}
                  onChange={handleStatusChange}
                />
              }
              label="Paid"
            />
            <FormControlLabel
              value="UNPAID"
              control={
                <Checkbox
                  checked={filters.status.split(",").includes("UNPAID")}
                  onChange={handleStatusChange}
                />
              }
              label="Unpaid"
            />
            <FormControlLabel
              value="PARTIALLY_PAID"
              control={
                <Checkbox
                  checked={filters.status.split(",").includes("PARTIALLY_PAID")}
                  onChange={handleStatusChange}
                />
              }
              label="Partially paid"
            />
            <FormControlLabel
              value="VOIDED"
              control={
                <Checkbox
                  checked={filters.status.split(",").includes("VOIDED")}
                  onChange={handleStatusChange}
                />
              }
              label="Voided"
            />
            <FormControlLabel
              value="CREDIT"
              control={
                <Checkbox
                  checked={filters.status.split(",").includes("CREDIT")}
                  onChange={handleStatusChange}
                />
              }
              label="Credit"
            />
          </FormGroup>
        </Grid>
        {isShowPastDue && (
          <Grid
            item
            sx={{ width: "100%", maxWidth: { sm: "90px", xs: "100%" } }}
          >
            <Typography mb="5px">Past due</Typography>
            <Switch
              checked={!!filters.past_due}
              onChange={handlePastDueChange}
              inputProps={{ "aria-label": "controlled" }}
            />
          </Grid>
        )}
        <Grid
          item
          sx={{ width: "100%", maxWidth: { sm: "290px", xs: "100%" } }}
        >
          <Typography>Address</Typography>
          <TextField
            label={"search by address"}
            value={filters?.address}
            onChange={(e) =>
              setFilters({ ...filters, address: e.target.value })
            }
          />
        </Grid>
      </Grid>
    </Block>
  );
};

export default InvoiceFilters;
