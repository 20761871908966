import { setSession } from "../utils/setSession";
import api from "./api";
import { filtersToApi, orderingToApi } from "./common";
import axios from "axios";
export function getAuthUser() {
  return api.get("/api/accounts/me/");
}

export async function getAllUsers() {
  return await api.get("/api/users/");
}

export function getUser(userId) {
  return api.get(`/api/users/${userId}/`);
}

export function getUsers(page, pageSize, filter, order) {
  const orderingParam = order.length ? `&ordering=${orderingToApi(order)}` : "";
  const filterParam = filter.length ? `&${filtersToApi(filter)}` : "";

  const params = `page_size=${pageSize}&page=${page}${orderingParam}${filterParam}`;

  return api.get(`/api/users/?${params}`);
}

export function postUser(userInfo) {
  return api.post(`/api/users/`, userInfo);
}

export function patchUser(userId, userInfo) {
  return api.patch(`/api/users/${userId}/`, userInfo);
}

export function deleteUser(userId) {
  return api.delete(`/api/users/${userId}/`);
}

export const signIn = async (email, password) => {
  const response = await axios.post(
    `${process.env.REACT_APP_RIAD_API_URL}/api/auth/login/`,
    {
      username: email,
      password,
    }
  );
  console.log(response.status);
  if (response.status === 200) {
    setSession(response);
    return { data: "ok" };
  }
  return response;
};

export const confirmPassword = async (email, code, password) => {
  const response = await api.post("/api/auth/confirm_reset_password/", {
    email,
    code,
    new_password: password,
    confirm_new_password: password,
  });
  return response;
};

export const otpVerification = async (data) => {
  const response = await api.post("/api/auth/validate_otp/", data);
  return response;
};

export const resendOtp = async (data) => {
  const response = await api.post("/api/auth/resend_otp/", data);
  return response;
};
