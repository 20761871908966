import DataTable from "../../components/DataTable";
import variantConfigTableColumns from "./variantConfigTableColumns";
import { useMemo } from "react";
import "./tableStyle.css";

const VariantConfigTable = ({ vars = {}, deleteVariant, updateVariant }) => {
  const variants = Object.keys(vars).map((key) => {
    let vimage = null;
    if (vars[key].images && vars[key].images.length > 0) {
      vimage = vars[key].images[0].image;
    }
    return {
      ...vars[key],
      id: key,
      variantId: vars[key].variantId,
      editFunction: () => updateVariant(key),
      deleteFunction: () => deleteVariant(key),
      variant: {
        name: vars[key].name,
        img: vimage,
      },
      price: {
        value: "$" + vars[key].cost,
        info: `per ${vars[key].sold_by.label} (per box: ${vars[key].per_box}, per rack: ${vars[key].per_pack}, order qty: ${vars[key].order_qty}, sheet:${vars[key].sheet})`,
      },
      cost: {
        value: "$" + vars[key].cost,
        link: "",
      },
      restrictions: "0 Restrictions",
      upcharge: {
        value: `${vars[key].overrides_count} overrides`,
        link: "",
      },
    };
  });
  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 100,
    };
  }, []);

  const rowData = [...variants];
  return (
    <div className="variant-table">
      <DataTable
        rowData={rowData}
        columnDefs={variantConfigTableColumns}
        defaultColDef={defaultColDef}
        rowHeight={73}
      />
    </div>
  );
};

export default VariantConfigTable;
