import React, { useEffect, useState } from "react";
import Block from "../../../components/Block";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { DATE_FORMAT, DATE_FORMAT_FRONT } from "../../../utils/constants";
import dayjs from "dayjs";
import RouteSelect from "../../../components/AsyncSelects/RouteSelect";
import ArrowUpwardOutlinedIcon from "@mui/icons-material/ArrowUpwardOutlined";
import ArrowDownwardOutlinedIcon from "@mui/icons-material/ArrowDownwardOutlined";
import DatePicker2 from "../../../components/DatePicker2";

const RouteChangesForm = ({ routeData, setValue, disabled }) => {
  return (
    <Block>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={4}>
          <Typography component="p" variant="label" mb="11px">
            Route Change
          </Typography>
          <RouteSelect
            name="currentRoute"
            disabled={disabled}
            value={{
              value: routeData.change_for?.id || null,
              label: routeData.change_for?.name || "",
            }}
            onChange={(_e, newValue) =>
              setValue("change_for", {
                id: newValue.value,
                name: newValue.label,
              })
            }
            size="small"
            placeholder="Route"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography component="p" variant="label" mb="11px">
            Change Start
          </Typography>
          <DatePicker2
            controlledValue={
              routeData.change_start ? dayjs.utc(routeData.change_start) : null
            }
            setControlledValue={(newValue) => {
              setValue("change_start", dayjs(newValue).format(DATE_FORMAT));
            }}
            additionlStyles={{
              height: "40px",
              width: "100%",
              "& .MuiInputBase-root.Mui-error .MuiOutlinedInput-notchedOutline":
                {
                  borderColor: "#DBDFEA",
                },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography component="p" variant="label" mb="11px">
            Change Finish
          </Typography>
          <DatePicker2
            controlledValue={
              routeData.change_end ? dayjs.utc(routeData.change_end) : null
            }
            setControlledValue={(newValue) => {
              setValue("change_end", dayjs(newValue).format(DATE_FORMAT));
            }}
            additionlStyles={{
              height: "40px",
              width: "100%",
              "& .MuiInputBase-root.Mui-error .MuiOutlinedInput-notchedOutline":
                {
                  borderColor: "#DBDFEA",
                },
            }}
          />
        </Grid>
      </Grid>
    </Block>
  );
};

export default RouteChangesForm;
