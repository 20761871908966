import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { deleteTag, getTag, listTags, patchTag, postTag } from "../../distflowAPI/tagsApi";
import { signOut } from "./user";
import { fireErrorNotification, fireSuccessNotification } from "../../utils";

/**
 * @typedef {Object} Tag
 * @property {number} Tag.id
 * @property {string} Tag.name
 * @property {number} Tag.products_count
 */

/**
 * @type {{
 *  tag: Tag;
 *  tags: Array<Tag>;
 *  tagsLoading: boolean;
 *  pageSize: number;
 *  page: number;
 *  orderBy: [];
 *  filterBy: {};
 *  totalCount: number;
 *  createLoading: boolean;
 * }}
 */
const initialState = {
  tag: {},
  tags: [],
  tagsLoading: false,
  pageSize: 50,
  page: 1,
  orderBy: [],
  filterBy: [],
  totalCount: 0,
  createLoading: false
};

export const fetchTags = createAsyncThunk(
  "tag/fetchTags",
  async (data, { dispatch, getState }) => {
    const { page, pageSize, filterBy, orderBy } = data;
    return await listTags(pageSize, page, orderBy, filterBy);
  }
);

export const fetchTag = createAsyncThunk("tag/fetchTag", async (tagId, thunkAPI) => {
  const res = await getTag(tagId);
  return res.data;
});

export const createTag = createAsyncThunk("tag/createTag", async (tag, { rejectWithValue }) => {
  try {
    const res = await postTag(tag);
    return res.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const updateTag = createAsyncThunk("tag/updateTag", async (data, { rejectWithValue }) => {
  const { tagId, tag } = data;

  try {
    const res = await patchTag(tagId, tag);
    return res.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const removeTag = createAsyncThunk("tag/removeTag", async (tagId, { rejectWithValue }) => {
  try {
    const res = await deleteTag(tagId);
    return tagId;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const tagSlice = createSlice({
  name: "tag",
  initialState,
  reducers: {
    setTagsTableSettings(state, { payload }) {
      state[payload.field] = payload.value;
    },
    cleanTableSettings(state) {
      state.pageSize = 50;
      state.page = 1;
      state.orderBy = [];
      state.filterBy = [];
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTags.pending, (state) => {
        state.tagsLoading = true;
      })
      .addCase(fetchTags.fulfilled, (state, action) => {
        state.tagsLoading = false;
        state.tags = action.payload.results;
        state.totalCount = action.payload.count;
      })
      .addCase(fetchTags.rejected, (state) => {
        state.tagsLoading = false;
      })
      .addCase(fetchTag.fulfilled, (state, { payload }) => {
        state.tag = payload;
      })
      .addCase(createTag.pending, (state) => {
        state.createLoading = true;
      })
      .addCase(createTag.fulfilled, (state, action) => {
        state.createLoading = false;
        fireSuccessNotification(`Tag "${action.meta.arg}" created successfully`);
        state.totalCount = state.totalCount + 1;
        if (state.tags.length < state.pageSize) {
          state.tags = [...state.tags, action.payload];
        }
      })
      .addCase(createTag.rejected, (state, action) => {
        state.createLoading = false;
        const errorFields = Object.keys(action.payload);
        errorFields.forEach(field => {
          fireErrorNotification(`${field}: ${action.payload[field][0]}`);
        });
      })
      .addCase(updateTag.fulfilled, (state, action) => {
        fireSuccessNotification(`Tag "${action.payload.name}" updated successfully`);
        state.tag = action.payload;
        state.tags = state.tags.map(el => {
          if (el.id === action.payload.id) {
            return action.payload;
          } else {
            return el;
          }
        });
      })
      .addCase(updateTag.rejected, (state, action) => {
        const errorFields = Object.keys(action.payload);
        errorFields.forEach(field => {
          fireErrorNotification(`${field}: ${action.payload[field][0]}`);
        });
      })
      .addCase(removeTag.fulfilled, (state, action) => {
        fireSuccessNotification(`Tag deleted successfully`);
        state.tag = {};
        state.tags = state.tags.filter(el => el.id !== action.payload);
      })
      .addCase(removeTag.rejected, (state, action) => {
        const protected_elements = action.payload.protected_elements;
        fireErrorNotification(`Protected by: 
          ${protected_elements.map(el => ` id: ${el.id} label: ${el.label}`)}`);
      })
      .addCase(signOut, () => initialState);
  }
});

export const { setTagsTableSettings, cleanTableSettings } = tagSlice.actions;

export const selectTagNumberOfPages = createSelector(
  (state) => state.tag.totalCount,
  (state) => state.tag.pageSize,
  (totalCount, pageSize) => Math.ceil(totalCount / pageSize)
);

export default tagSlice.reducer;
