const statusColorMap = {
    EN_ROUTE: '#3F75C6',
    FINISHED: '#3FC6A5',
    AT_PICKUP: '#969696',
    AT_DELIVERY: '#969696',
    INVALID: '#969696'
}

const statusTextMap = {
    EN_ROUTE: 'En Route',
    FINISHED: 'Completed',
    AT_PICKUP: 'At Pickup',
    AT_DELIVERY: 'At Delivery'
}

const vendorStatusTextMap = {
    ARRIVED_AT_PICKUP_LOCATION: "Started",
    NOT_PICKED_UP: "Not picked up",
    PICKED_UP: "Completed",
    REPORTED_ISSUE: "Reported issue",
    INVALID: "Pending"
}

export { statusColorMap, statusTextMap, vendorStatusTextMap }