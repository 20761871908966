import DataTable from "../../components/DataTable";
import variantsColumnsDef from "./variantsColumns";
import { useState, useEffect } from "react";
import { emailVariantPrices, getVariants } from "../../distflowAPI/products";
import { set } from "date-fns";
import { useSearchParams } from "react-router-dom";
import { useSearchDebounce } from "../../utils";
import {
  OutlinedInput,
  Box,
  Stack,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  MenuItem,
} from "@mui/material";
import VariantFilters from "./VariantFilters";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOffIcon from "@mui/icons-material/FilterAlt";
import { formatAgGridSorting } from "../../distflowAPI/common";
import PageLoading from "../../components/PageLoading";
import { FileDownload, FileDownloadOutlined } from "@mui/icons-material";
import { downloadFile } from "../../distflowAPI/downloadFile";
import { Checkbox, FormControlLabel } from "@mui/material";
import AccountSelect from "../../components/AsyncSelects/AccountSelect";
import AccountTypeSelect from "../../components/AsyncSelects/AccountTypeSelect";
import TerritorySelect from "../../components/AsyncSelects/TerritorySelect";
export default function Variants() {
  const defaultColDef = {
    flex: 1,
    filter: false,
    floatingFilter: false,
  };
  const [isLoading, setIsLoading] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [variants, setVariants] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [orderBy, setOrderBy] = useState([]);
  const [filterBy, setFilterBy] = useState([]);
  const [count, setCount] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const [openExportItemList, setOpenExportItemList] = useState(false);
  const [openItemsPrices2, setOpenItemsPrices2] = useState(false);
  const [searchVariant, setSearchVariant] = useState(
    searchParams.get("search") || ""
  );
  const [searchProductDB, setSearchProductDB] = useSearchDebounce(
    500,
    searchParams.get("search") || ""
  );
  function fetchVariants() {
    setIsLoading(true);
    getVariants(pageSize, page, orderBy, filterBy, searchParams)
      .then((res) => {
        setVariants(res.data.results);
        setCount(res.data.count);
      })
      .finally(() => setIsLoading(false));
  }
  useEffect(() => {
    fetchVariants();
  }, [page, pageSize, orderBy, searchParams]);

  const handleChange = (k, value) => {
    console.log("setting", k, "to", value);
    setSearchParams((params) => {
      const newParams = new URLSearchParams(params);
      if (!!value) {
        newParams.set(k, value);
      } else {
        newParams.delete(k);
      }
      return newParams;
    });
  };

  useEffect(() => {
    setSearchProductDB(searchVariant);
  }, [searchVariant]);

  useEffect(() => {
    handleChange("search", searchProductDB);
  }, [searchProductDB]);

  function handlePricesReport() {
    emailVariantPrices().then((res) => alert("Prices email successfully."));
  }
  return (
    <>
      <ExportItemList2 open={openItemsPrices2} setOpen={setOpenItemsPrices2} />
      <ExportItemList
        open={openExportItemList}
        setOpen={setOpenExportItemList}
      />
      {isLoading && <PageLoading dataLoading={true} />}
      <Typography
        fontSize="23px"
        fontWeight={800}
        component="h2"
        data-testid="page-title"
        mb="20px"
      >
        Variants
      </Typography>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        spacing="5px"
        mb="20px"
      >
        <OutlinedInput
          placeholder="Search for a Product..."
          sx={{ height: "40px", maxWidth: "60%" }}
          fullWidth
          value={searchVariant}
          onChange={(e) => {
            setSearchVariant(e.target.value);
          }}
        />
        <Button
          className="btn-height"
          variant="outlined"
          endIcon={showFilters ? <FilterAltOffIcon /> : <FilterAltIcon />}
          onClick={() => setShowFilters(!showFilters)}
        >
          Filters
        </Button>
        <Button
          className="btn-height"
          variant="outlined"
          onClick={() => handlePricesReport()}
        >
          Prices Report
        </Button>
        <Button
          className="btn-height"
          variant="outlined"
          endIcon={<FileDownload />}
          onClick={() => setOpenExportItemList(true)}
        >
          Export Item List
        </Button>
        <Button
          className="btn-height"
          variant="outlined"
          onClick={() => setOpenItemsPrices2(true)}
        >
          Prices Report 2
        </Button>
      </Stack>
      <VariantFilters showFilters={showFilters} />
      <Box className="table-wrapper">
        <DataTable
          rowData={variants}
          suppressSorting={true}
          enableSorting={false}
          columnDefs={variantsColumnsDef}
          page={page}
          pageSize={pageSize}
          animateRows={false}
          onSortChanged={(event) =>
            setOrderBy(
              formatAgGridSorting(event.columnApi.columnModel.gridColumns)
            )
          }
          setPageSize={(pageSize) => {
            setPageSize(pageSize);
            setPage(1);
          }}
          setPage={(page) => {
            setPage(page);
            window.scrollTo(0, 0);
          }}
          noPages={Math.ceil(count / pageSize)}
          rowHeight={73}
          defaultColDef={defaultColDef}
        />
      </Box>
    </>
  );
}

function ExportItemList2({ open, setOpen }) {
  const [territory, setTerritory] = useState();
  const [accountType, setAccountType] = useState();
  const [generating, setGenerating] = useState(false);
  return (
    <Dialog open={open}>
      <DialogTitle>Export Customer Prices</DialogTitle>
      <DialogContent>
        <Stack>
          <TerritorySelect
            value={territory}
            label="Territory"
            name="territory"
            required
            onChange={(_e, newValue) => setTerritory(newValue)}
            extraStyles={{
              pt: 1,
            }}
          />
          <AccountTypeSelect
            value={accountType}
            label="Account Type"
            name="accountType"
            required
            onChange={(_e, newValue) => setAccountType(newValue)}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack direction={"row"} spacing={1}>
          <Button
            variant="contained"
            color="error"
            onClick={() => setOpen(false)}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              setGenerating(true);
              downloadFile(
                `/api/products/export_items_list_2/?account_type=${accountType?.value}&territory=${territory?.value}`,
                "items_list.csv"
              ).finally(() => setGenerating(false));
            }}
            variant="contained"
            color="success"
            disabled={generating}
          >
            Generate
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
function ExportItemList({ open, setOpen }) {
  const [settings, setSettings] = useState({
    incCost: false,
    incDeliveryType: false,
    incCategory: false,
    incPrice: false,
  });
  const [priceLevel, setPriceLevel] = useState("GENERAL");
  const [accountPrice, setAccountPrice] = useState(null);
  const [generating, setGenerating] = useState(false);
  function generateDownloadLink() {
    let link = `/api/products/export_items_list/?${Object.keys(settings)
      .map((k) => k + "=" + settings[k])
      .join("&")}`;
    if (settings.incPrice && priceLevel == "ACCOUNT" && accountPrice) {
      link += `&account=${accountPrice?.value}`;
    }
    return link;
  }
  return (
    <Dialog open={open} maxWidth="md" sx={{ minWidth: 500 }}>
      <DialogTitle>Export Items List</DialogTitle>
      <DialogContent>
        <Stack spacing={1} minWidth={500}>
          {Object.keys(settings).map((k) => (
            <FormControlLabel
              value={settings[k]}
              control={
                <Checkbox
                  checked={settings[k]}
                  onChange={(e) => {
                    setSettings({ ...settings, [k]: e.target.checked });
                  }}
                />
              }
              label={k.replace("inc", "Include ")}
            />
          ))}
          {settings.incPrice && (
            <Select
              value={priceLevel}
              onChange={(e) => setPriceLevel(e.target.value)}
              sx={{ zIndex: 19100 }}
              MenuProps={{
                style: { zIndex: 35001 },
              }}
            >
              <MenuItem value="GENERAL">General Price</MenuItem>
              <MenuItem value="ACCOUNT">Account Based Price</MenuItem>
            </Select>
          )}
          {settings.incPrice && priceLevel == "ACCOUNT" && (
            <AccountSelect
              value={accountPrice}
              onChange={(_ev, newVal) => {
                console.log("set account price to: ", newVal);
                setAccountPrice(newVal);
              }}
            />
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack direction={"row"} spacing={1}>
          <Button
            variant="contained"
            color="error"
            onClick={() => setOpen(false)}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              setGenerating(true);
              downloadFile(generateDownloadLink(), "items_list.csv").finally(
                () => setGenerating(false)
              );
            }}
            variant="contained"
            color="success"
            disabled={generating}
          >
            Generate
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
