// TODO:
import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { signOut } from "./user";
import invoicesApi from "../../distflowAPI/invoicesApi";

const initialState = {
  invoices: [],
  invoice: {},
  pageSize: 50,
  page: 1,
  orderBy: [{ field: "issued_date", sort: "asc" }],
  filterBy: [],
  totalCount: 0,
  invoicesLoading: false,
};

export const fetchInvoices = createAsyncThunk(
  "invoice/fetchInvoices",
  async (data, thunkAPI) => {
    let { page, pageSize, filterBy, orderBy, searchParams } = data;
    return await invoicesApi.list(
      pageSize,
      page,
      orderBy,
      filterBy,
      searchParams
    );
  }
);

export const fetchInvoice = createAsyncThunk(
  "invoice/fetchInvoice",
  async (id, thunkAPI) => {
    const res = await invoicesApi.findById(id);
    return res.data;
  }
);

export const invoiceSlice = createSlice({
  name: "invoice",
  initialState,
  reducers: {
    setInvoicesTableSettings(state, { payload }) {
      state[payload.field] = payload.value;
    },
    cleanTableSettings(state) {
      state.pageSize = 50;
      state.page = 1;
      state.orderBy = [];
      state.filterBy = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchInvoices.pending, (state) => {
        state.invoicesLoading = true;
      })
      .addCase(fetchInvoices.fulfilled, (state, action) => {
        state.invoicesLoading = false;
        state.invoices = action.payload.results;
        state.totalCount = action.payload.count;
      })
      .addCase(fetchInvoices.rejected, (state) => {
        state.invoicesLoading = false;
      })
      .addCase(fetchInvoice.fulfilled, (state, action) => {
        state.invoice = action.payload;
      })

      .addCase(signOut, () => initialState);
  },
});
export const { setInvoicesTableSettings, cleanTableSettings } =
  invoiceSlice.actions;

export const selectInvoiceNumberOfPages = createSelector(
  (state) => state.invoice.totalCount,
  (state) => state.invoice.pageSize,
  (totalCount, pageSize) => Math.ceil(totalCount / pageSize)
);

export default invoiceSlice.reducer;
