import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import useAppDispatch from "../../hooks/useAppDispatch";
import {
  createCustomer,
  createCustomerEmails,
} from "../../redux/module/customer";
import { v4 as uuidv4 } from "uuid";
import CustomerForm from "./CustomerForm";

const initialValue = {
  owner: {
    first_name: "",
    last_name: "",
    email: "",
    password: "",
  },
  name: "",
  internal_id: "",
  address: "",
  city: "",
  state: "",
  zipcode: "",
  invoices_net: "",
  emails: [{ id: uuidv4(), email: "" }],
  phones: [{ id: uuidv4(), phone: "" }],
};

const AddCustomerForm = ({ open, handleClose }) => {
  const dispatch = useAppDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [customerInfo, setCustomerInfo] = useState(initialValue);

  async function handleSubmit(event) {
    event.preventDefault();
    setIsSubmitting(true);
    const payload = {
      owner: {
        first_name: customerInfo.owner.first_name,
        last_name: customerInfo.owner.last_name,
        email: customerInfo.owner.email,
        password: customerInfo.owner.password || undefined,
      },
      name: customerInfo.name,
      internal_id: customerInfo.internal_id,
      address: customerInfo.address,
      city: customerInfo.city,
      state: customerInfo.state,
      zipcode: customerInfo.zipcode,
      invoices_net: customerInfo.invoices_net,
    };

    let isHasError = false;

    const data = await dispatch(createCustomer(payload)).finally(() => {
      setIsSubmitting(false);
    });
    if (data.payload.id) {
      // for (const phone of customerInfo.phones) {
      //   const index = customerInfo.phones.indexOf(phone);
      //   if (phone.phone) {
      //     const phoneData = await dispatch(createCustomerPhones({
      //       customerId: data.payload.id,
      //       payload: {
      //         phone: phone.phone,
      //         is_default: index === 0
      //       }
      //     }));
      //     if (!phoneData.payload.id) {
      //       isHasError = true;
      //     }
      //   }
      // }

      for (const email of customerInfo.emails) {
        const index = customerInfo.phones.indexOf(email);
        if (email.email) {
          const emailData = await dispatch(
            createCustomerEmails({
              customerId: data.payload.id,
              payload: {
                email: email.email,
                is_default: index === 0,
              },
            }),
          );
          if (!emailData.payload.id) {
            isHasError = true;
          }
        }
      }
    } else {
      isHasError = true;
    }

    if (!isHasError) {
      handleClose();
    }
  }

  useEffect(() => {
    setCustomerInfo(initialValue);
  }, [open]);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle sx={{ pb: 1 }}>Create a new Customer</DialogTitle>
      <form id="accountCreation" onSubmit={handleSubmit}>
        <DialogContent sx={{ pt: 1, pb: 1 }}>
          <CustomerForm
            customerInfo={customerInfo}
            setCustomerInfo={setCustomerInfo}
            isPasswordRequired
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={isSubmitting}
          >
            Create Customer
          </Button>
          <Button onClick={handleClose} variant="contained" color="error">
            Cancel
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddCustomerForm;
