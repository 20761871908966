import useAppDispatch from "../../../hooks/useAppDispatch";
import TableButtons from "../../../components/TableComponents/TableButtons";
import { removeDriver } from "../../../redux/module/driver";
import { useState } from "react";
import AddDriverForm from "./addDriverForm";

function DriverLinkRenderer(props) {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleDelete = async () => {
    await dispatch(removeDriver(props.data.id));
  };
  const handleEdit = () => {
    handleOpen();
  };

  return (
    <>
      <AddDriverForm
        open={open}
        handleClose={handleClose}
        driver={props.data}
      />
      <TableButtons
        handleEdit={handleEdit}
        handleDelete={handleDelete}
        deletePopupEntity="driver"
        historyData={props.data.history || []}
      />
    </>
  );
}

const driver_first_name = {
  headerName: "First name",
  field: "user__first_name",
  wrapText: true,
  autoHeight: true,
  filterParams: {
    filterOptions: ["contains"],
    suppressAndOrCondition: true,
    textMatcher: (filterValue, cellValue) => true,
  },
  filter: true,
  floatingFilter: true,
  valueGetter: (params) => {
    return `${params.data.user.first_name}`;
  },
  flex: 4,
};
const driver_last_name = {
  headerName: "Last name",
  field: "user__last_name",
  wrapText: true,
  autoHeight: true,
  filterParams: {
    filterOptions: ["contains"],
    suppressAndOrCondition: true,
    textMatcher: (filterValue, cellValue) => true,
  },
  filter: true,
  floatingFilter: true,
  valueGetter: (params) => {
    return `${params.data.user.last_name}`;
  },
  flex: 4,
};

const driver_email = {
  headerName: "Email",
  field: "email",
  wrapText: true,
  autoHeight: true,
  filterParams: {
    filterOptions: ["contains"],
    suppressAndOrCondition: true,
    textMatcher: (filterValue, cellValue) => true,
  },
  filter: true,
  floatingFilter: true,
  valueGetter: (params) => {
    return `${params.data.user.email}`;
  },
  flex: 4,
};

const actions = {
  field: "",
  sorting: false,
  sort: false,
  sortable: false,
  autoHeight: true,
  filter: false,
  floatingFilter: false,
  cellRenderer: DriverLinkRenderer,
  flex: 1,
  minWidth: 120,
  maxWidth: 120,
};

const driverTableColumns = [
  driver_first_name,
  driver_last_name,
  driver_email,
  actions,
];

export default driverTableColumns;
