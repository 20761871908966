import React from "react";
import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";
import Spinner from "./Spinner";
import LoadingButton from "./LoadingButton";
import ErrorPopupBoundary from "./ErrorPopupBoundary";
import { ErrorBoundary } from "react-error-boundary";

/**
 * @param {Object} props
 * @param {string} props.title
 * @param {boolean} props.show
 * @param {() => void} props.onOk
 * @param {() => void} props.onCancel
 * @param {string} [props.okText] defaults to `Yes`
 * @param {string} [props.okVariant] defaults to `error`
 * @param {string} [props.cancelText] defaults to `No`
 * @param {boolean} [props.loading]
 */
const Popconfirm = ({
                      title,
                      show,
                      onOk,
                      onCancel,
                      okText = "Yes",
                      okColor = "error",
                      cancelText = "No",
                      loading = false
                    }) => (
  <Dialog maxWidth="xs" open={show} onClose={onCancel}>
    <ErrorBoundary fallback={<ErrorPopupBoundary onClose={onCancel} />}>
      <DialogTitle mx={3} my={2}>
        {title}
      </DialogTitle>
      <DialogActions sx={{ borderTop: "1px solid lightgray" }}>
        <Button variant="contained" onClick={onCancel}>
          {cancelText}
        </Button>
        <LoadingButton
          variant="contained"
          color={okColor}
          onClick={onOk}
          disabled={loading}
        >
          {loading && <Spinner size="sm" marginRight={8} />} {okText}
        </LoadingButton>
      </DialogActions>
    </ErrorBoundary>
  </Dialog>
);

export default Popconfirm;
