import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { createSettings, getSettings, updateSettings } from "../../distflowAPI/settingsApi";
import SettingsForm from "./SettingsForm";

const CreateUpdateSetting = () => {
    const [errors, setErrors] = useState({})
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [success, setSuccess] = useState('')
    const {id} = useParams()
    const [data, setData] = useState({
        code: '',
        value: '',
        notes: ''
    })
    function dataOnChange(event){
        let newValue = event.target.value
        if(event.target.name == 'code'){
            newValue = event.target.value.toUpperCase().replace(' ', '_')
        }
        setData({...data, [event.target.name]:newValue})
    }
    useEffect(
        ()=>{
            if(id && !isNaN(parseInt(id))){
                getSettings(id).then(
                    res=>{
                        const dataApi = res.data
                        dataApi.value = JSON.stringify(dataApi.value)
                        setData(dataApi)
                    }
                )
            }
        },
        []
    )
    function handleSubmit(event){
        event.preventDefault()
        setIsSubmitting(true)
        const payload = {...data}
        try{
            payload.value = JSON.parse(payload.value)
        }catch(e){
            setErrors({value:['Invalid json value']})
            setIsSubmitting(false)
            return
        }
        if(id && !isNaN(parseInt(id))){
            updateSettings(id, payload).then(
                res=>{
                    setSuccess('updated successfully')
                },
                error=>{
                    setErrors(error.response.data[Object.keys(error.response.data)[0]])
                    setIsSubmitting(false)
                }
            )
        }else{
            createSettings(payload).then(
                res=>{
                    setSuccess('created successfully')
                    window.location.href = `/settings/${res.data.id}`
                },
                error=>{
                    setErrors(error.response.data[Object.keys(error.response.data)[0]])
                    setIsSubmitting(false)
                }
            )
        }
        
    }
  return (<SettingsForm data={data} dataOnChange={dataOnChange} handleSubmit={handleSubmit} errors={errors} success={success} isSubmitting={isSubmitting} id={id}/>);
};


export default CreateUpdateSetting;
