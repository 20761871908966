import { Link } from "react-router-dom";

function ProductVendorNameRenderer(params) {
  return (
    <span className="my-renderer">
      <Link
        to={`/vendors/${params.data.vendor.id}`}
        style={{ textDecoration: "none" }}
      >
        {params.data.vendor.name}
      </Link>
    </span>
  );
}

function ProductCategoryNameRenderer(params) {
  return (
    <span className="my-renderer">
      <Link
        to={`/categories/${params.data.category.id}`}
        style={{ textDecoration: "none" }}
      >
        {params.data.category.name}
      </Link>
    </span>
  );
}

function ProductImageRenderer(params) {
  return params.data.images && params.data.images.length > 0 ? (
    <img
      src={params.data.images[0].image}
      width="40px"
      height="40px"
      style={{
        paddingTop: "5px",
        paddingBottom: "5px",
        objectFit: "contain",
      }}
    />
  ) : (
    ""
  );
}

function ProductNameRenderer(params) {
  return (
    <span className="my-renderer">
      <Link
        to={`/products/${params.data.id}`}
        style={{ textDecoration: "none" }}
      >
        {params.data.name}
      </Link>
    </span>
  );
}

function SkusRenderer(params) {
  return <span>{params.data.variants.map((item) => item.sku).join(", ")}</span>;
}

const product_image = {
  autoHeight: true,
  minWidth: 120,
  rowSelection: "multiple",
  headerCheckboxSelection: true,
  checkboxSelection: true,
  cellRendererSelector: () => {
    return {
      component: ProductImageRenderer,
    };
  },
  filter: false,
  flex: 1,
};

const product_name = {
  headerName: "Product",
  field: "name",
  valueGetter: (params) => {
    return `${params.data.name}`;
  },
  cellRendererSelector: () => {
    return {
      component: ProductNameRenderer,
    };
  },
  wrapText: true,
  autoHeight: true,
  // filterParams: {
  //   filterOptions: ["contains"],
  //   maxNumConditions: 1,
  // },
  // filter: true,
  // floatingFilter: true,
  sortable: true,
  comparator: () => {}, //disable default sorting
  minWidth: 300,
  flex: 5,
};

const product_category = {
  headerName: "Category",
  minWidth: 150,
  colId: "category__name",
  field: "category.name",
  valueGetter: (params) => {
    return `${params.data.category.name}`;
  },
  cellRendererSelector: () => {
    return {
      component: ProductCategoryNameRenderer,
    };
  },
  wrapText: true,
  autoHeight: true,
  // filterParams: {
  //   filterOptions: ["contains"],
  //   maxNumConditions: 1,
  // },
  // filter: true,
  // floatingFilter: true,
  sortable: true,
  comparator: () => {}, //disable default sorting
  flex: 3,
};

const product_skus = {
  headerName: "SKU",
  minWidth: 150,
  field: "variants__sku",
  valueGetter: (params) => {
    return `(${params.data.variants.length}) ${params.data.variants
      .map((item) => item.sku)
      .join(", ")}`;
  },
  cellRendererSelector: () => {
    return {
      component: SkusRenderer,
    };
  },
  wrapText: true,
  autoHeight: true,
  // filterParams: {
  //   filterOptions: ["contains"],
  //   maxNumConditions: 1,
  // },
  // filter: true,
  // floatingFilter: true,
  sortable: true,
  comparator: () => {}, //disable default sorting
  flex: 2,
};

const product_vendor = {
  headerName: "Vendor",
  minWidth: 150,
  colId: "vendor__name",
  field: "vendor.name",
  valueGetter: (params) => {
    return `${params.data.vendor.name}`;
  },
  cellRendererSelector: () => {
    return {
      component: ProductVendorNameRenderer,
    };
  },
  wrapText: true,
  autoHeight: true,
  // filterParams: {
  //   filterOptions: ["contains"],
  //   maxNumConditions: 1,
  // },
  // filter: true,
  // floatingFilter: true,
  sortable: true,
  comparator: () => {}, //disable default sorting
  flex: 2,
};

const product_delivery_type = {
  headerName: "Delivery Type",
  field: "delivery_type",
  minWidth: 150,
  wrapText: true,
  autoHeight: true,
  // filterParams: {
  //   filterOptions: ["contains"],
  //   maxNumConditions: 1,
  // },
  // filter: true,
  // floatingFilter: true,
  sortable: true,
  comparator: () => {}, //disable default sorting
  flex: 2,
};

const product_tags = {
  headerName: "Tags",
  field: "tags",
  minWidth: 150,
  valueGetter: (params) => {
    return `(${params.data.tags.length}) ${params.data.tags
      .map((item) => item.name)
      .join(", ")}`;
  },
  wrapText: true,
  autoHeight: true,
  // filterParams: {
  //   filterOptions: ["contains"],
  //   maxNumConditions: 1,
  // },
  // filter: true,
  // floatingFilter: true,
  // sortable: true,
  // comparator: () => {}, //disable default sorting
  flex: 2,
};

const product_is_active = {
  headerName: "Active",
  field: "is_active",
  minWidth: 100,
  wrapText: true,
  autoHeight: true,
  // filterParams: {
  //   filterOptions: ["contains"],
  //   maxNumConditions: 1,
  // },
  // filter: true,
  // floatingFilter: true,
  // sortable: true,
  // comparator: () => {}, //disable default sorting
  flex: 1.3,
};

const productTableColumns = [
  product_image,
  product_name,
  product_category,
  product_skus,
  product_vendor,
  product_delivery_type,
  product_tags,
  product_is_active,
];

export default productTableColumns;
