import React, { useEffect, useState } from "react";
import { Box, Button, Stack, Switch, Typography } from "@mui/material";
import DataTable from "../../components/DataTable";
import { useSearchParams } from "react-router-dom";
import {
  cleanTableSettings,
  fetchCustomers,
  selectCustomerNumberOfPages,
  setCustomerTableSettings,
} from "../../redux/module/customer";
import customerTableColumns from "./customerTableColumns";
import AddCustomerForm from "./AddCustomerForm";
import {
  formatAgGridFilters,
  formatAgGridSorting,
} from "../../distflowAPI/common";
import { getNoPages, useSearchDebounce } from "../../utils";
import PageLoading from "../../components/PageLoading";
import { getCustomers } from "../../distflowAPI/customersApi";

const defaultColDef = {
  flex: 1,
  filter: false,
  floatingFilter: false,
};

const Customers = () => {
  const [searchParams, setSearchParams] = useSearchParams({
    ordering: "internal_id",
  });
  const [customers, setCustomers] = useState([]);
  const [customersLoading, setCustomersLoading] = useState(false);
  const [filterBy, setFilterBy] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [myCustomers, setMyCustomers] = useState(false);
  const [open, setOpen] = useState(false);
  const [noPages, setNoPages] = useState(1);

  function refreshTable() {
    setCustomersLoading(true);
    const lf = filterBy.filter((f) => f.column != "myCustomers");
    lf.push({
      column: "myCustomers",
      value: myCustomers,
      filterType: "equals",
    });
    //{ column: "myCustomers", value: myCustomers }
    getCustomers(page, pageSize, lf, [], searchParams).then((res) => {
      setCustomers(res.data.results);
      setNoPages(getNoPages(res.data.count, pageSize));
      setCustomersLoading(false);
    });
  }
  useEffect(() => {
    refreshTable();
  }, [page]);
  useEffect(() => {
    if (page == 1) {
      refreshTable();
    } else {
      setPage(1);
    }
  }, [pageSize, filterBy, myCustomers, searchParams]);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleChange = (field, value) => {
    if (field === "orderBy") {
      handleSortParams(value);
    }
  };
  function handleSortParams(value) {
    let newSortingValue = "";
    if (value?.length > 0) {
      newSortingValue = value[0]?.field;
      if (value[0]?.sort != "asc") {
        newSortingValue = "-" + newSortingValue;
      }
    }
    setSearchParams((params) => {
      const newParams = new URLSearchParams(params);
      newParams.set("ordering", newSortingValue);
      return newParams;
    });
  }
  return (
    <>
      <PageLoading dataLoading={customersLoading} />

      <Typography
        fontSize="23px"
        fontWeight={800}
        component="h2"
        data-testid="page-title"
      >
        Customers
      </Typography>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        alignItems={{ xs: "space-between", sm: "center" }}
        justifyContent="space-between"
        spacing={1.5}
        my="25px"
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          spacing="15px"
          width="100%"
        >
          <Button onClick={handleOpen} variant="contained">
            Add New
          </Button>
          {/*<OutlinedInput*/}
          {/*  sx={{ height: "40px" }}*/}
          {/*  name="Search"*/}
          {/*  placeholder="Search"*/}
          {/*  fullWidth*/}
          {/*  value={search}*/}
          {/*  onChange={(e) => setSearch(e.target.value)}*/}
          {/*/>*/}
          <Stack direction="row" alignItems="center">
            <Typography>My customers only</Typography>
            <Switch
              checked={myCustomers}
              onChange={(e) => setMyCustomers(e.target.checked)}
              inputProps={{ "aria-label": "controlled" }}
            />
          </Stack>
        </Stack>
      </Stack>

      <Box className="table-wrapper">
        <DataTable
          rowData={customers}
          columnDefs={customerTableColumns}
          defaultColDef={defaultColDef}
          suppressSorting={true} //disable default sorting
          rowHeight={53}
          headerHeight={60}
          loading={customersLoading}
          onSortChanged={(e) => {
            handleChange(
              "orderBy",
              formatAgGridSorting(e.columnApi.columnModel.gridColumns),
            );
          }}
          onFilterChanged={(event) => {
            setFilterBy(formatAgGridFilters(event.api.getFilterModel()));
          }}
          page={page}
          pageSize={pageSize}
          setPageSize={(pageSize) => setPageSize(pageSize)}
          noPages={noPages}
          setPage={(page) => setPage(page)}
        />
      </Box>
      <AddCustomerForm open={open} handleClose={handleClose} />
    </>
  );
};

export default Customers;
