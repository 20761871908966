import React from "react";
import AsyncAutocompleteSelect from "./AsyncAutocompleteSelect";
import categoriesApi from "../../distflowAPI/categoriesApi";
import { listVendors } from "../../distflowAPI/vendorsApi";

/**
 * @param {Omit<
 *  import("./AsyncAutocompleteSelect").AsyncAutocompleteSelectProps<
 *    import("../../redux/module/category").Category
 *  >,
 *  'fetchOptions'
 * >} props
 */
const VendorSelect = (props) => (
  <AsyncAutocompleteSelect
    fetchOptions={(searchTerm) =>
      listVendors(10, 1, searchTerm)
        .then((res) =>
          res.results.map((item) => ({
            value: item.id,
            label: item.name,
          }))
        )
    }
    {...props}
  />
);

export default VendorSelect;
