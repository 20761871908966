import React from "react";
import { Box, Grid, Stack, Typography } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import useAppSelector from "../../hooks/useAppSelector";
import Separator from "../Separator";
import Block from "../Block";
import dayjs from "dayjs";
import { DATE_FORMAT_FRONT } from "../../utils/constants";
import { fixedNumber } from "../../utils";

function stringAvatar(name) {
  return {
    sx: {
      width: 56,
      height: 56,
    },
    children: `${name.split(" ")[0][0]}${
      name.split(" ")[1] ? name.split(" ")[1][0] : ""
    }`,
  };
}

const CustomerGeneralInfo = () => {
  const { customerOverrides, customerAccounts, customer, customerFinancials } =
    useAppSelector((state) => state.customer);
  function getCustomerUpcharge() {
    return customerOverrides.find((el) => el.is_current)?.override;
  }
  return (
    <>
      <Block>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Avatar {...stringAvatar(customer.name)} />
          <Typography component="p" variant="label">
            {customer.name}
          </Typography>
        </Box>

        {(!!customer.emails?.length || !!customer.phones?.length) && (
          <Separator />
        )}

        {!!customer.emails?.length && (
          <Stack direction="column" sx={{ width: "100%" }}>
            <Typography
              component="p"
              variant="caption"
              sx={{ fontWeight: 500 }}
            >
              Emails:
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                justifyContent: "center",
                marginBottom: "8px",
              }}
            >
              {customer.emails.map((el) => (
                <Typography component="p" variant="caption">
                  {el.email}
                </Typography>
              ))}
            </Box>
          </Stack>
        )}

        {!!customer.phones?.length && (
          <Stack direction="column" sx={{ width: "100%" }}>
            <Typography
              component="p"
              variant="caption"
              sx={{ fontWeight: 500 }}
            >
              Phones:
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                justifyContent: "center",
              }}
            >
              {customer.phones?.map((el) => (
                <Typography component="p" variant="caption">
                  {el.phone}
                </Typography>
              ))}
            </Box>
          </Stack>
        )}
        <Separator />
        <Box>
          <Stack direction="row" justifyContent="space-between">
            <Typography
              component="p"
              variant="caption"
              sx={{ fontWeight: 500 }}
            >
              Created:
            </Typography>
            <Typography component="p" variant="caption" textAlign="center">
              {dayjs.utc(customer.created_at).format(DATE_FORMAT_FRONT)}
            </Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Typography
              component="p"
              variant="caption"
              sx={{ fontWeight: 500 }}
            >
              Updated:
            </Typography>
            <Typography component="p" variant="caption" textAlign="center">
              {dayjs.utc(customer.updated_at).format(DATE_FORMAT_FRONT)}
            </Typography>
          </Stack>
        </Box>
        <Separator />
        <Stack
          direction="row"
          justifyContent="space-around"
          width="100%"
          gap={1}
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Typography component="p" variant="label">
              {customerAccounts.length}
            </Typography>
            <Typography component="p" variant="caption">
              Accounts
            </Typography>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Typography component="p" variant="label">
              {getCustomerUpcharge() || `0,00`}
              {"% "}
            </Typography>
            <Typography component="p" variant="caption">
              Upcharge
            </Typography>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Typography component="p" variant="label">
              {customer.invoice_net || 30}
            </Typography>
            <Typography component="p" variant="caption" textAlign="center">
              Invoice net
            </Typography>
          </Box>
        </Stack>
      </Block>
      <Block>
        <Grid container spacing={1}>
          <Grid item md={12}>
            <Typography component="p" variant="caption">
              Balance & Credits summary
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography component="p" variant="label" textAlign="center">
              ${fixedNumber(+customerFinancials.balance)}
            </Typography>
            <Typography component="p" variant="caption" textAlign="center">
              Balance
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography component="p" variant="label" textAlign="center">
              ${fixedNumber(+customerFinancials.credit)}
            </Typography>
            <Typography component="p" variant="caption" textAlign="center">
              Credit
            </Typography>
          </Grid>
          <Grid item md={12}>
            <Separator
              sx={{
                margin: { xs: "16px -16px", sm: "2px -24px 2px" },
              }}
            />
          </Grid>
          {(customerFinancials.balance_by_account || []).map((bc) => (
            <Grid item md={12}>
              <Typography component="p" variant="caption">
                Balance by account
              </Typography>
              <Typography>
                {bc.account}: ${bc.balance}
              </Typography>
            </Grid>
          ))}
          <Grid item md={12}>
            <Separator
              sx={{
                margin: { xs: "16px -16px", sm: "2px -24px 2px" },
              }}
            />
          </Grid>
          {(customerFinancials.credit_by_account || []).map((bc) => (
            <Grid item md={12}>
              <Typography component="p" variant="caption">
                Credits by account
              </Typography>
              <Typography>
                {bc.account}: ${bc.credit}
              </Typography>
            </Grid>
          ))}
          <Grid item md={12}>
            <Separator
              sx={{
                margin: { xs: "16px -16px", sm: "2px -24px 2px" },
              }}
            />
            <Typography component="p" variant="caption">
              Open invoices (greater than or equals)
            </Typography>
          </Grid>
          {Object.keys(customerFinancials.open_since || {}).map((bc) => (
            <Grid item md={6}>
              <Typography>
                {bc} {"Days"}: {customerFinancials.open_since[bc].total}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Block>
    </>
  );
};

export default CustomerGeneralInfo;
