import {
  createStatementGroup,
  deleteStatementGroup,
  listStatementGroups,
  processStatementGroup,
} from "../../distflowAPI/invoicesApi";
import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import DataTable from "../../components/DataTable";
import {
  formatAgGridFilters,
  formatAgGridSorting,
} from "../../distflowAPI/common";
import { statementGroupCols } from "./statementGroupCols";
import AccountSelect from "../../components/AsyncSelects/AccountSelect";
import DatePickerCustom2 from "../../components/DatePicker2";
import { downloadFile } from "../../distflowAPI/downloadFile";
import TerritorySelect from "../../components/AsyncSelects/TerritorySelect";
import ConfirmDialog from "../../components/ConfirmDialog";

function GroupStatements() {
  const [showAddGroup, setShowAddGroup] = useState(false);
  const [statementGroups, setStatementGroups] = useState([]);
  const [orderBy, setOrderBy] = useState([]);
  const [filterBy, setFilterBy] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [dataLoading, setDataLoading] = useState(false);
  const [noPages, setNoPages] = useState(1);
  const [showGenerate, setShowGenerate] = useState(false);
  const [statementToDelete, setStatementToDelete] = useState(false);
  function refreshTable() {
    listStatementGroups(100, 1, [], []).then((res) =>
      setStatementGroups(res.data.results),
    );
  }

  useEffect(() => {
    refreshTable();
  }, []);
  function getStatementCols() {
    const cols = statementGroupCols.concat({
      headerName: "Actions",
      cellRenderer: (params) => (
        <Button
          variant="contained"
          color={"error"}
          onClick={() => setStatementToDelete(params.data)}
        >
          Delete
        </Button>
      ),
      flex: 2,
    });
    return cols;
  }
  const cols = getStatementCols();
  function handleDeleteStatementGroup(id) {
    deleteStatementGroup(id).then((res) => {
      setStatementToDelete(false);
      refreshTable();
    });
  }
  return (
    <Box>
      <ConfirmDialog
        open={statementToDelete}
        setOpen={(val) => {
          if (!val) {
            setStatementToDelete(false);
          }
        }}
        onConfirm={() => handleDeleteStatementGroup(statementToDelete?.id)}
      >
        <Typography>
          Are you sure you want to delete: <b>'{statementToDelete?.name}'</b>
        </Typography>
      </ConfirmDialog>
      <AddStatementGroup
        open={showAddGroup}
        setOpen={setShowAddGroup}
        refreshTable={refreshTable}
      />
      <GenerateGroupStatement
        open={showGenerate}
        setOpen={setShowGenerate}
        groups={statementGroups}
      />
      <Box
        sx={{ display: "flex", justifyContent: "space-between", mb: "20px" }}
      >
        <Box
          sx={{ display: "flex", justifyContent: "space-between", mb: "20px" }}
        >
          <Typography
            fontSize="23px"
            fontWeight={800}
            component="h2"
            data-testid="page-title"
          >
            Statement Groups
          </Typography>
        </Box>
        <Stack direction={"row"} spacing={1}>
          <Button
            variant="contained"
            color="success"
            onClick={() => {
              setShowAddGroup(true);
            }}
          >
            Add a Statement Group
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setShowGenerate(true);
            }}
          >
            Generate Statement
          </Button>
        </Stack>
      </Box>
      <Box>
        <DataTable
          columnDefs={cols}
          rowData={statementGroups}
          rowHeight={53}
          headerHeight={60}
          loading={dataLoading}
          onSortChanged={(event) =>
            setOrderBy(
              formatAgGridSorting(event.columnApi.columnModel.gridColumns),
            )
          }
          onFilterChanged={(event) =>
            setFilterBy(formatAgGridFilters(event.api.getFilterModel()) || [])
          }
          page={page}
          pageSize={pageSize}
          setPageSize={(pageSize) => setPageSize(pageSize)}
          noPages={noPages}
          setPage={(page) => setPage(page)}
        />
      </Box>
    </Box>
  );
}

export default GroupStatements;

function AddStatementGroup({ open, setOpen, refreshTable }) {
  const [name, setName] = useState("");
  const [groupType, setGroupType] = useState("MERGE_AND_DOWNLOAD");

  function handleSubmit() {
    const payload = {
      name,
      accounts: -1,
      gt: groupType,
    };
    createStatementGroup(payload).then((res) => {
      refreshTable();
      setOpen(false);
    });
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle sx={{ fontWeight: "bolder", mb: "30px" }}>
          Add a new Statement Group
        </DialogTitle>
        <DialogContent>
          <TextField
            required={true}
            name="name"
            label="Group name"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
            fullWidth
            sx={{ margin: "8px" }}
          />
          <Typography>Generation type</Typography>
          <Select
            fullWidth
            onChange={(e) => setGroupType(e.target.value)}
            value={groupType}
            MenuProps={{
              style: {
                maxHeight: 400,
                zIndex: 1500,
              },
            }}
            label="Generation type"
            required
          >
            <MenuItem value={"MERGE_AND_DOWNLOAD"}>Merge and download</MenuItem>
            <MenuItem value={"EMAIL"}>
              Individual account statements (email)
            </MenuItem>
            <MenuItem value={"FAX"}>
              Individual account statements (fax)
            </MenuItem>
          </Select>
        </DialogContent>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            m: "20px",
            gap: "10px",
          }}
        >
          <Button
            variant="contained"
            color="success"
            onClick={() => handleSubmit()}
          >
            Create
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              setOpen(false);
            }}
          >
            Cancel
          </Button>
        </Box>
      </Dialog>
    </>
  );
}

function GenerateGroupStatement({ open, setOpen, groups }) {
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [generating, setGenerating] = useState(false);
  const [selectedTerritory, setSelectedTerritory] = useState(null);

  function handleSubmit() {
    setGenerating(true);
    const st = startDate.format("YYYY-MM-DD");
    const ed = endDate.format("YYYY-MM-DD");
    const selected_ = groups.filter((g) => g.id == selectedGroup);
    if (selected_ && selected_[0].gt == "MERGE_AND_DOWNLOAD") {
      let url = `/api/accounting/generate_group_statement/?startDate=${st}&endDate=${ed}&groupId=${selectedGroup}`;
      if (selectedTerritory) {
        url += `&territory=${selectedTerritory?.value}`;
      }
      downloadFile(
        url,
        `${selected_[0].name} ${startDate.format(
          "MM/DD/YYYY",
        )} ${endDate.format("MM/DD/YYYY")}.pdf`,
      ).finally(() => setGenerating(false));
    } else {
      processStatementGroup(selectedGroup, st, ed)
        .then((res) => {
          if (res.data && res.data.group) {
            window.location.href = `/invoices/group-statements/${res.data.group}/batches`;
          }
          setOpen(false);
        })
        .finally(() => setGenerating(false));
    }

    //setOpen(false);
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        maxWidth="sm"
        fullWidth
        sx={{ zIndex: 1 }}
      >
        <DialogTitle sx={{ fontWeight: "bolder", mb: "30px" }}>
          Generate Statement
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item md={12}>
              <Select
                onChange={(e) => setSelectedGroup(e.target.value)}
                fullWidth
                MenuProps={{
                  style: {
                    maxHeight: 400,
                    zIndex: 1500,
                  },
                }}
              >
                {groups.map((g) => (
                  <MenuItem
                    value={g.id}
                    selected={g.id == selectedGroup}
                    sx={{ zIndex: 9199 }}
                  >
                    {g.name}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item md={12}>
              <Stack spacing={1}>
                <Stack direction={"row"} spacing={1}>
                  <DatePickerCustom2
                    controlledValue={startDate}
                    setControlledValue={setStartDate}
                    label={"start date"}
                  />
                  <DatePickerCustom2
                    controlledValue={endDate}
                    setControlledValue={setEndDate}
                    label={"end date"}
                  />
                </Stack>
              </Stack>
              <Grid item md={12}></Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            m: "20px",
            gap: "10px",
          }}
        >
          <Button
            variant="contained"
            color="success"
            onClick={() => handleSubmit()}
            disabled={generating}
          >
            {generating ? "Generating..." : "Generate"}
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              setOpen(false);
            }}
          >
            Cancel
          </Button>
        </Box>
      </Dialog>
    </>
  );
}
