import { useState, useRef, useEffect } from "react";
import {
  Table,
  TableCell,
  TableBody,
  TableRow,
  Stack,
  Alert,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TableHead,
  Typography,
} from "@mui/material";
import useFile from "../../../hooks/useFile";
import dayjs from "dayjs";
import { importSteadies } from "../../../distflowAPI/ordersApi";
import ExcelJS from "exceljs";

function ImportSteadies({
  openImportSteadies,
  setOpenImportSteadies,
  refreshTable,
}) {
  const [selectedFile, setSelectedFile] = useState();
  const [steadies, setSteadies] = useState([]);
  const fileRef = useRef(null);
  const handleFileClick = () => fileRef.current.click();
  const { isFileValid } = useFile(["xlsx"]);
  const [fileData, setFileData] = useState({});
  const [errors, setErrors] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  useEffect(() => {
    readFile();
  }, [selectedFile]);
  const onUploadFile = () => {
    const file = fileRef.current.files[0];
    if (isFileValid(file)) {
      setSelectedFile(file);
    } else {
      fileRef.current.value = "";
    }
  };

  async function readFile() {
    if (!selectedFile) {
      return;
    }
    const wb = new ExcelJS.Workbook();
    const reader = new FileReader();
    reader.readAsArrayBuffer(selectedFile);
    reader.onload = () => {
      const newErrors = {};
      const buffer = reader.result;
      wb.xlsx.load(buffer).then((workbook) => {
        const newData = {};
        const sheet = workbook.getWorksheet(1);
        const headerVals = {
          A1: sheet.getCell("A1").value,
          B1: sheet.getCell("B1").value,
          C1: sheet.getCell("C1").value,
          D1: sheet.getCell("D1").value,
          E1: sheet.getCell("E1").value,
          G1: sheet.getCell("G1").value,
          H1: sheet.getCell("H1").value,
        };
        const validHeaders = {
          A1: "AUTONO",
          B1: "CUSTNO",
          C1: "ITEM",
          D1: "DESCRIP",
          E1: "QTYSHP",
          G1: "LASTBILL",
          H1: "NEXTBILL",
        };
        Object.keys(headerVals).map((k) => {
          if (headerVals[k] != validHeaders[k]) {
            newErrors[
              k
            ] = `${k} should be ${validHeaders[k]} found ${headerVals[k]} instead`;
          }
        });
        setErrors(newErrors);
        if (Object.keys(newErrors).length > 0) {
          return;
        }
        const newSteadies = [];
        for (let i = 2; i < sheet.rowCount + 1; i++) {
          const frequency = Number(sheet.getCell("I" + i).value);
          const last_bill = sheet.getCell("G" + i).value;
          const nextBill = sheet.getCell("H" + i).value;
          newSteadies.push({
            day: sheet.getCell("A" + i).value.slice(1, 4),
            freq: parseFrequency(sheet.getCell("A" + i).value.slice(5)),
            account: sheet.getCell("B" + i).value,
            sku: sheet.getCell("C" + i).value,
            descript: sheet.getCell("D" + i).value,
            qty: sheet.getCell("E" + i).value,
            lastBill: parseBill(last_bill),
            nextBill: parseBill(nextBill),
          });
        }
        setSteadies(newSteadies);
      });
    };
  }

  function parseFrequency(val) {
    const parsed = parseInt(val);
    if (!parsed || isNaN(parsed)) {
      return 1;
    }
    return parsed;
  }

  function parseBill(val) {
    if (val) {
      try {
        const parsed = dayjs(val, "MM/DD/YY").format("YYYY-MM-DD");
        return parsed;
      } catch (e) {
        return null;
      }
    }
    return null;
  }

  function handleImport() {
    setIsSaving(true);
    importSteadies(steadies).then(
      (res) => {
        setIsSaving(false);
        setOpenImportSteadies(false);
      },
      (error) => {
        alert("An error has occurred, please contact support");
      },
    );
  }

  return (
    <Dialog
      open={openImportSteadies}
      onClose={() => setOpenImportSteadies(false)}
      sx={{
        "& .MuiPaper-root": {
          width: "100%",
          minWidth: "800px",
          minHeight: "600px",
        },
      }}
    >
      <DialogTitle
        sx={{ fontWeight: "bolder", marginBottom: "0px", marginTop: "0px" }}
      >
        Import Steadies
        <Button
          onClick={() => setOpenImportSteadies(false)}
          color="inherit"
          style={{
            position: "absolute",
            right: "16px",
            top: "16px",
            fontSize: "18px",
          }}
        >
          X
        </Button>
      </DialogTitle>
      <DialogContent>
        {selectedFile && Object.keys(errors).length > 0 && (
          <Stack spacing={2}>
            {Object.keys(errors).map((key) => (
              <Alert
                severity="error"
                sx={{
                  maxHeight: "60px",
                  minHeight: "20px !important",
                  width: "100% !important",
                  minWidth: "0px !important",
                }}
              >
                {key}: {errors[key]}
              </Alert>
            ))}
          </Stack>
        )}
        {!selectedFile && (
          <Stack
            direction="row"
            alignContent={"center"}
            alignItems={"center"}
            spacing={3}
          >
            <Typography>Start by importing a file</Typography>
            <Button variant="contained" onClick={handleFileClick}>
              Choose file
            </Button>
            <input
              style={{ display: "none" }}
              type="file"
              name="fileImport"
              accept=".xlsx"
              ref={fileRef}
              onChange={onUploadFile}
            />
          </Stack>
        )}
        {selectedFile && (
          <Stack spacing={3}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Day</TableCell>
                  <TableCell>Frequency</TableCell>
                  <TableCell>account</TableCell>
                  <TableCell>SKU</TableCell>
                  <TableCell>description</TableCell>
                  <TableCell>qty</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {steadies.map((st) => (
                  <TableRow>
                    <TableCell>{st.day}</TableCell>
                    <TableCell>
                      {Number(st.freq) > 1 ? `week / ${st.freq}` : "weekly"}
                    </TableCell>
                    <TableCell>{st.account}</TableCell>
                    <TableCell>{st.sku}</TableCell>
                    <TableCell>{st.descript}</TableCell>
                    <TableCell>{st.qty}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Button
              variant="outlined"
              onClick={handleImport}
              disabled={isSaving}
            >
              {isSaving ? "Processing..." : "Import"}
            </Button>
          </Stack>
        )}
      </DialogContent>
    </Dialog>
  );
}

export default ImportSteadies;
