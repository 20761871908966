import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import productsApi, {
  getProductVariant,
  listProductOrders,
  listVariantOrders,
} from "../../distflowAPI/products";
import {
  formatAgGridFilters,
  formatAgGridSorting,
} from "../../distflowAPI/common";
import { enqueueSnackbar } from "notistack";
import { arrayToDictionary, getNearestPrimitiveValue } from "../../utils";
import { signOut } from "./user";
import { listVariants } from "../../distflowAPI/ordersApi";

/**
 * @typedef {Object} Product
 * @property {number} Product.id
 * @property {string} Product.name
 * @property {boolean} Product.is_active
 * @property {string} Product.delivery_type
 * @property {Array<import("./tag").Tag>} Product.tags
 * @property {import("./category").Category} Product.category
 * @property {import("./vendor").Vendor} Product.vendor
 * @property {Array<{ sku: string }>} Product.variants
 * @property {Array<{ id: number; image: string }>} Product.images
 */

/**
 * @type {{
 *  products: Array<Product>;
 *  keyedProducts: Record<number, Product>;
 *  keyedVariants: Record<Number, {}>;
 *  productsLoading: boolean;
 *  pageSize: number;
 *  page: number;
 *  orderBy: [];
 *  filterBy: {};
 *  totalCount: number;
 *  createLoading: boolean;
 *  updateLoading: boolean;
 *  formModalOpen: boolean;
 *  formInitialData: Partial<Product>|null;
 * }}
 */
const initialState = {
  products: [],
  variants: [],
  ordersHistory: [],
  keyedProducts: {},
  keyedVariants: {},
  productsLoading: false,
  productOrdersHistoryLoading: false,
  pageSize: 50,
  page: 1,
  orderBy: [],
  filterBy: [],
  totalCount: 0,
  createLoading: false,
  updateLoading: false,
  formModalOpen: false,
  formInitialData: null,
};

export const fetchProducts = createAsyncThunk(
  "product/fetchProducts",
  (arg, { dispatch, getState }) => {
    const productState = getState().product;
    const {
      pageSize = productState.pageSize,
      page = productState.page,
      orderBy = productState.orderBy,
      filterBy = productState.filterBy,
      searchParams,
    } = arg;
    const formattedFilterBy = formatAgGridFilters(filterBy);
    let order_by = orderBy;
    if (order_by.length == 0) {
      order_by = [
        {
          field: "variants__sku",
          sort: "asc",
          colId: "variants__sku",
        },
      ];
    }
    const formattedOrderBy = formatAgGridSorting(order_by);
    // if we change sort, filter or pageSize - dispatch first page
    const requestPage =
      pageSize !== productState.pageSize ||
      orderBy !== productState.orderBy ||
      filterBy !== productState.filterBy
        ? 1
        : page;

    dispatch(setProductsPagination({ pageSize, page: requestPage }));
    dispatch(setProductsFilters({ orderBy, filterBy }));
    return productsApi.list(
      pageSize,
      requestPage,
      formattedOrderBy,
      formattedFilterBy,
      searchParams,
    );
  },
);

export const fetchProductVariantsList = createAsyncThunk(
  "product/fetchProductVariantsList",
  (arg) => {
    const { pageSize, page, filters, searchParams } = arg;

    return listVariants(pageSize, page, filters, searchParams);
  },
);

export const fetchProductOrdersList = createAsyncThunk(
  "product/fetchProductOrdersList",
  (arg) => {
    const { id, pageSize, page, filterBy, orderBy, searchParams } = arg;

    return listProductOrders(
      id,
      pageSize,
      page,
      orderBy,
      filterBy,
      searchParams,
    );
  },
);
export const fetchVariantOrdersList = createAsyncThunk(
  "product/fetchVariantOrdersList",
  (arg) => {
    const { id, pageSize, page, filterBy, orderBy, searchParams } = arg;

    return listVariantOrders(
      id,
      pageSize,
      page,
      orderBy,
      filterBy,
      searchParams,
    );
  },
);

// export const createProduct = createAsyncThunk(
//   "product/createProduct",
//   async (data) => {
//     try {
//       const response = await productsApi.create(data);
//       return response.data;
//     } catch (err) {
//       return rejectWithValue(err.response.data);
//     }
//   }
// );

export const updateProduct = createAsyncThunk(
  "product/updateProduct",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await productsApi.update(id, data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const fetchProductById = createAsyncThunk(
  "product/fetchProductById",
  async ({ id }, { getState, rejectWithValue }) => {
    const state = getState().product;
    if (state.keyedProducts[id]) {
      return state.keyedProducts[id];
    }
    try {
      const response = await productsApi.findById(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const fetchVariantById = createAsyncThunk(
  "product/fetchVariantById",
  async ({ productId, variantId }, { getState, rejectWithValue }) => {
    const state = getState().product;

    if (state.keyedVariants[variantId]) {
      return state.keyedVariants[variantId];
    }

    try {
      const response = await getProductVariant(productId, variantId);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    setProductsTableSettings(state, { payload }) {
      state[payload.field] = payload.value;
    },
    setProductsPagination(state, action) {
      state.pageSize = action.payload.pageSize;
      state.page = action.payload.page;
    },
    setProductsFilters(state, action) {
      state.orderBy = action.payload.orderBy;
      state.filterBy = action.payload.filterBy;
    },
    openFormModal(state, action) {
      state.formModalOpen = true;
      state.formInitialData = action.payload || null;
    },
    closeFormModal(state) {
      state.formModalOpen = false;
      state.formInitialData = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProducts.pending, (state) => {
        state.productsLoading = true;
      })
      .addCase(fetchProducts.fulfilled, (state, action) => {
        state.productsLoading = false;
        state.products = action.payload.results;
        state.totalCount = action.payload.count;
        state.keyedProducts = {
          ...state.keyedProducts,
          ...arrayToDictionary(action.payload.results, "id"),
        };
      })
      .addCase(fetchProductVariantsList.pending, (state) => {
        state.productsLoading = true;
      })
      .addCase(fetchProductVariantsList.fulfilled, (state, action) => {
        state.productsLoading = false;
        state.variants = action.payload.results;
      })
      .addCase(fetchProductOrdersList.pending, (state) => {
        state.productOrdersHistoryLoading = true;
      })
      .addCase(fetchProductOrdersList.fulfilled, (state, action) => {
        state.productOrdersHistoryLoading = false;
        state.ordersHistory = action.payload.results;
        state.totalCount = action.payload.count;
      })
      .addCase(fetchVariantOrdersList.pending, (state) => {
        state.productOrdersHistoryLoading = true;
      })
      .addCase(fetchVariantOrdersList.fulfilled, (state, action) => {
        state.productOrdersHistoryLoading = false;
        state.ordersHistory = action.payload.results;
        state.totalCount = action.payload.count;
      })
      .addCase(fetchProducts.rejected, (state) => {
        state.productsLoading = false;
      })
      // .addCase(createProduct.pending, (state) => {
      //   state.createLoading = true;
      // })
      // .addCase(createProduct.fulfilled, (state, action) => {
      //   state.createLoading = false;
      //   // TODO: get product name from action properly
      //   enqueueSnackbar(
      //     `Product "${action.meta.arg.name}" created successfully`,
      //     { variant: "success" }
      //   );
      //   state.totalCount = state.totalCount + 1;
      //   if (state.products.length < state.pageSize) {
      //     state.products = [...state.products, action.payload];
      //   }
      // })
      // .addCase(createProduct.rejected, (state) => {
      //   state.createLoading = false;

      //   let message = `Error creating Route \r\n`;
      //   for (const key in action.payload) {
      //     message += `${key} - ${action.payload[key][0]} `;
      //   }

      //   enqueueSnackbar(message, { variant: "error" });
      // })
      .addCase(updateProduct.pending, (state) => {
        state.updateLoading = true;
      })
      .addCase(updateProduct.fulfilled, (state, action) => {
        state.updateLoading = false;
        enqueueSnackbar("Product Updated Successfully", {
          variant: "success",
        });
        state.products = state.products.map((item) =>
          item.id === action.meta.arg.id
            ? {
                ...item,
                ...action.payload,
              }
            : item,
        );
      })
      .addCase(updateProduct.rejected, (state, action) => {
        state.updateLoading = false;

        let message = `Error updating product \r\n`;
        for (const key in action.payload) {
          message += `${key} - ${getNearestPrimitiveValue(
            action.payload[key],
          )} `;
        }

        enqueueSnackbar(message, { variant: "error" });
      })
      .addCase(fetchProductById.fulfilled, (state, action) => {
        state.keyedProducts[action.meta.arg.id] = action.payload;
      })
      .addCase(fetchVariantById.fulfilled, (state, action) => {
        state.keyedVariants[action.payload.id] = action.payload;
      })
      .addCase(signOut, () => initialState);
  },
});

export const {
  setProductsTableSettings,
  setProductsPagination,
  setProductsFilters,
  openFormModal,
  closeFormModal,
} = productSlice.actions;

export const selectProductNumberOfPages = createSelector(
  (state) => state.product.totalCount,
  (state) => state.product.pageSize,
  (totalCount, pageSize) => Math.ceil(totalCount / pageSize),
);

export default productSlice.reducer;
