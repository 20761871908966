import { Box, Stack, Typography } from "@mui/material";
import { Link, NavLink } from "react-router-dom";
const defaultCellStyle = { "padding-left": "10px", "padding-right": "10px" };

const VariantRenderer = (props) => {
  return (
    <Stack direction="row" alignItems="center" gap="14px">
      <Link
        to={`/products/${props.data.product.id}/variants/${props.data.id}`}
        component={NavLink}
        sx={{ color: "#535151", fontWeight: 600 }}
      >
        {props.data.name}
      </Link>
    </Stack>
  );
};
const variant_name = {
  headerName: "Variant",
  field: "name",
  minWidth: 240,
  cellRenderer: VariantRenderer,
  sortable: true,
  minWidth: 500,
  comparator: () => {}, //disable default sorting
};
const product_tags = {
  headerName: "Tags",
  field: "tags",
  minWidth: 150,
  valueGetter: (params) => {
    return `(${params.data.tags.length}) ${params.data.tags
      .map((item) => item.name)
      .join(", ")}`;
  },
  wrapText: true,
  autoHeight: true,
  // filterParams: {
  //   filterOptions: ["contains"],
  //   maxNumConditions: 1,
  // },
  // filter: true,
  // floatingFilter: true,
  sortable: true,
  comparator: () => {}, //disable default sorting
  flex: 2,
};
const variant_sku = {
  headerName: "SKU",
  field: "sku",
  sortable: true,
  // cellRenderer: SkuRenderer,
  comparator: () => {}, //disable default sorting
  minWidth: 100,
};

const variant_category = {
  headerName: "Category",
  field: "product__category__name",
  sortable: true,
  // cellRenderer: SkuRenderer,
  valueGetter: (params) => params.data?.category,
  minWidth: 250,
  comparator: () => {}, //disable default sorting
};

const variant_vendor = {
  headerName: "Vendor",
  field: "product__vendor__name",
  // cellRenderer: UpchargeRenderer,
  maxWidth: 150,
  sortable: true,
  valueGetter: (params) => params.data?.vendor,
  comparator: () => {}, //disable default sorting
};

const variant_cost = {
  headerName: "G.Price",
  field: "general_price",
  sortable: false,
  // cellRenderer: CostRenderer,
  cellStyle: defaultCellStyle,
  cellRenderer: (params) => (
    <Typography>${params?.data?.general_price}</Typography>
  ),
  comparator: () => {}, //disable default sorting
};

const variant_soldBy = {
  headerName: "Sold By",
  field: "sold_by",
  minWidth: 110,
  // cellRenderer: PriceRenderer,
  sortable: true,
  comparator: () => {}, //disable default sorting
};

const variantsColumnsDef = [
  variant_name,
  variant_vendor,
  variant_sku,
  variant_category,
  variant_cost,
  product_tags,
  variant_soldBy,
];

export default variantsColumnsDef;
