import api from "./api";
import { orderingToApi, filtersToApi } from "./common";
export function listSalesmen(page, pageSize, search) {
  //const orderingParam = order.length ? `&ordering=${orderingToApi(order)}` : "";
  //const filterParam = filter.length ? `&${filtersToApi(filter)}` : "";
  //const params = `page_size=${pageSize}&page=${page}${orderingParam}${filterParam}`;
  return api.get(
    `/api/accounting/salesmen/?page=${page}&page_size=${pageSize}&name=${search}`,
  );
}




export function createSalesman(payload) {
  return api.post(`/api/accounting/salesmen/`, payload);
}

export function getSalesman(salesmanId) {
  return api.get(`/api/accounting/salesmen/${salesmanId}/`);
}
export function patchSalesman(salesmanId, payload) {
  return api.patch(`/api/accounting/salesmen/${salesmanId}/`, payload);
}

export function deleteSalesman(salesmanId) {
  return api.delete(`/api/accounting/salesmen/${salesmanId}/`);
}

export function listComissions(
  salesManId,
  page = 1,
  pageSize = 50,
  filter = [],
  order = [],
) {
  const orderingParam = order.length ? `&ordering=${orderingToApi(order)}` : "";
  const filterParam = filter.length ? `&${filtersToApi(filter)}` : "";
  const params = `page_size=${pageSize}&page=${page}${orderingParam}${filterParam}`;
  return api.get(
    `/api/accounting/salesmen/${salesManId}/comissions/?${params}`,
  );
}

export function listPayOuts(
  salesManId,
  page = 1,
  pageSize = 50,
  filter = [],
  order = [],
) {
  const orderingParam = order.length ? `&ordering=${orderingToApi(order)}` : "";
  const filterParam = filter.length ? `&${filtersToApi(filter)}` : "";
  const params = `page_size=${pageSize}&page=${page}${orderingParam}${filterParam}`;
  return api.get(`/api/accounting/salesmen/${salesManId}/payouts/?${params}`);
}

export function createPayOut(salesManId, payload) {
  return api.post(`/api/accounting/salesmen/${salesManId}/payouts/`, payload);
}

export function patchPayOut(salesManId, id, payload) {
  return api.patch(
    `/api/accounting/salesmen/${salesManId}/payouts/${id}/`,
    payload,
  );
}

export function deletePayout(salesManId, id) {
  return api.delete(`/api/accounting/salesmen/${salesManId}/payouts/${id}/`);
}

export function getPayout(salesManId, id) {
  return api.get(`/api/accounting/salesmen/${salesManId}/payouts/${id}/`);
}

export async function calculatePayout(salesManId, startDate, endDate) {
  return api.get(
    `/api/accounting/payout_calculator/?sales_man_id=${salesManId}&start_date=${startDate}&end_date=${endDate}`,
  );
}
export async function createCalculatedPayout(
  salesManId,
  startDate,
  endDate,
  note,
) {
  return api.post(`/api/accounting/payout_calculator/`, {
    salesManId,
    startDate,
    endDate,
    note,
  });
}
