import { Box, IconButton, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Edit from "../../images/edit.svg";
import Trash from "../../images/trash-alt.svg";
import Link from "../../images/link-bold.svg";
import Duplicate from "../../images/duplicate.svg";
import useAppDispatch from "../../hooks/useAppDispatch";
import useAppSelector from "../../hooks/useAppSelector";
import {
  setRouteModalAccounts,
  setRouteModalVendors,
  setRouteToDelete,
} from "../../redux/module/route";
import { setRouteToEdit } from "../../redux/module/addEditRoute";

const defaultCellStyle = { lineHeight: "22px" };

const ActionRenderer = (params) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const to = () => {
    navigate(`/routes/route?id=${params.data.id}`);
  };

  const onEdit = () => {
    navigate(`/routes/edit-route/${params.data.id}`);
  };

  const onDelete = () => {
    dispatch(setRouteToDelete(params.data));
  };

  const onDuplicate = () => {
    dispatch(setRouteToEdit(params.data));
    navigate("/routes/add-route");
  };

  return (
    <Stack direction="row" gap="2px">
      {/* <IconButton onClick={to}>
        <img src={Link} alt="link" />
      </IconButton> */}
      <IconButton onClick={onEdit}>
        <img src={Edit} alt="edit" />
      </IconButton>
      <IconButton onClick={onDelete}>
        <img src={Trash} alt="trash" />
      </IconButton>
      <IconButton onClick={onDuplicate}>
        <img src={Duplicate} alt="duplicate" />
      </IconButton>
    </Stack>
  );
};

const AccountRenderer = (params) => {
  const dispatch = useAppDispatch();
  const filteredData = params.value?.filter(x => !x.schedule_change) ?? [];

  return (
    <Stack direction="row" gap="20px">
      {filteredData?.length}
      {!!filteredData?.length && (
        <IconButton
          component="span"
          sx={{ alignSelf: "center" }}
          onClick={() => { dispatch(setRouteModalAccounts(filteredData)) }}
        >
          <img src={Link} alt="link" />
        </IconButton>
      )}
    </Stack>
  );
};

const DaysRenderer = (params) => {
  return (
    <Stack direction="row" gap="7px">
      {params.value.map((day) => (
        <Box
          key={day}
          bgcolor="#F5F5F5"
          border="1px solid #DBDFEA"
          borderRadius="5px"
          p="2px 9px"
        >
          {day}
        </Box>
      ))}
    </Stack>
  );
};

const DriverRenderer = (props) => {
  const drivers = useAppSelector((state) => state.addEditRoute.allDrivers);
  return drivers.find((x) => x.id == props.data.driver)?.user.first_name;
};

const VendorRenderer = (props) => {
  const disabled_vendor_rules = props.value.filter(x => x.type == 'DISABLED');
  const vendors = useAppSelector((state) => state.vendor.vendors);
  const newVendors = vendors.filter(x => disabled_vendor_rules.find(dv => dv.vendor.id == x.id) == null);

  const dispatch = useAppDispatch();

  return (
    <Stack direction="row" gap="20px">
      {newVendors.length}
      {!!newVendors.length && (
        <IconButton
          component="span"
          sx={{ alignSelf: "center" }}
          onClick={() => dispatch(setRouteModalVendors(newVendors))}
        >
          <img src={Link} alt="link" />
        </IconButton>
      )}
    </Stack>
  );
};

const routeTableColumns = [
  {
    headerName: "Route Name",
    field: "name",
    headerCheckboxSelection: true,
    checkboxSelection: true,
    flex: 1,
    minWidth: 300,
    cellStyle: defaultCellStyle,
  },
  {
    headerName: "Is active",
    field: "is_active",
    flex: 1,
    minWidth: 130,
    cellStyle: defaultCellStyle,
    cellRenderer: (props) => (props.value ? "Yes" : "No"),
  },
  {
    headerName: "Driver Name",
    field: "driverName",
    flex: 1,
    minWidth: 130,
    cellStyle: defaultCellStyle,
    cellRenderer: DriverRenderer,
  },
  {
    headerName: "Account",
    field: "accounts",
    flex: 1,
    minWidth: 100,
    cellRenderer: AccountRenderer,
    cellStyle: defaultCellStyle,
  },
  {
    headerName: "Vendors",
    field: "vendor_rules",
    flex: 1,
    minWidth: 100,
    cellRenderer: VendorRenderer,
    cellStyle: defaultCellStyle,
  },
  {
    headerName: "Days",
    field: "days",
    flex: 1,
    minWidth: 230,
    cellRenderer: DaysRenderer,
    cellStyle: defaultCellStyle,
  },
  {
    headerName: "",
    field: "actions",
    maxWidth: 110,
    cellRenderer: ActionRenderer,
    cellStyle: defaultCellStyle,
  },
];

export default routeTableColumns;
