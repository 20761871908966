import { useModal } from "mui-modal-provider";
import {
  Stack,
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogTitle,
  TextField,
  DialogContent,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { updateInvoice } from "../../../distflowAPI/invoicesApi";
import { useState } from "react";
import { downloadFile } from "../../../distflowAPI/downloadFile";
import HistoryModal from "./HistoryModal";
import { emailInvoice } from "../../../distflowAPI/invoicesApi";

function InvoiceHeader({ data, dispatch, handleSave, handleReset }) {
  const [isEmailing, setIsEmailing] = useState(false);
  const [open, setOpen] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const navigate = useNavigate();
  const handleCreatePaymentClick = () => {
    navigate(
      `/invoices/payments/new/?invoice_id=${data.id}&account_id=${data.order.account.id}&account_name=${data.order.account.name}`
    );
  };
  function inVoide() {
    updateInvoice(data.id, { status: "UNPAID" }).then(
      (res) => {
        //setInv(res.data);
        //dispatch here
      },
      (err) => {
        alert("Error invoiding invoice");
      }
    );
  }
  function getSavedStatus() {
    let color = "success";
    let saveStatus = "";
    let state = false;
    if (data.config.changed && !data.config.saved) {
      saveStatus = "(Unsaved)";
      color = "error";
      state = true;
    }
    return {
      status: saveStatus,
      color,
      state,
    };
  }
  const { showModal } = useModal();

  return (
    <Stack
      direction={{ xs: "column", sm: "row" }}
      alignItems="center"
      justifyContent="space-between"
      spacing="20px"
      mb={5}
    >
      <VoidInvoiceDialog open={open} setOpen={setOpen} invoiceId={data.id} />
      <Stack
        direction="row"
        alignItems="center"
        justifyContent={{ xs: "space-between", sm: "flex-start" }}
        sx={{ width: { xs: "100%", sm: "initial" }, gap: "20px" }}
      >
        <Button
          className="btn-height"
          // onClick={() => navigate("/invoices/invoices")}
          onClick={() => navigate(-1)}
          variant="outlined"
          startIcon={<ArrowBackIcon />}
        >
          Back
        </Button>
        <Typography
          variant="pageTitle"
          noWrap
          component="p"
          data-testid="page-title"
        >
          Invoice #{data.id}
        </Typography>
        <Stack direction="row" gap={1}>
          {(data.status === "UNPAID" || data.status === "PARTIALLY_PAID") && (
            <Button
              size="small"
              onClick={handleCreatePaymentClick}
              color="success"
              variant="contained"
              sx={{ maxHeight: 30 }}
            >
              Create payment
            </Button>
          )}
          {data.status != "VOIDED" && (
            <Button
              color="error"
              variant="contained"
              sx={{ marginLeft: "5px", maxHeight: 30 }}
              disabled={isUpdating}
              onClick={() => setOpen(true)}
            >
              Void
            </Button>
          )}
          {data.status == "VOIDED" && (
            <Button
              color="success"
              variant="contained"
              sx={{ marginLeft: "5px", maxHeight: 30 }}
              onClick={() => inVoide()}
            >
              Unvoid
            </Button>
          )}
          {/*<Button variant="outlined" size="small" onClick={handleEditOrder}>
      Edit order
    </Button>
     */}
          <Button
            variant="contained"
            size="small"
            onClick={() => {
              let fileName = ``;
              console.log("inv is: ", data);
              if (data?.po_id) {
                fileName = `PO#${data?.po_id}`;
              } else {
                fileName = `INVOICE #${data?.id}`;
              }
              downloadFile(
                `/api/accounting/invoices_download/?download_type=invoice&invoice_id=${data.id}`,
                `${fileName}.pdf`
              );
            }}
            sx={{ maxHeight: 30 }}
          >
            Download PDF
          </Button>
          <Button
            color="warning"
            variant="contained"
            size="small"
            disabled={isEmailing}
            onClick={() => {
              setIsEmailing(true);
              emailInvoice(data.id)
                .then((res) => {
                  alert("Invoice Email Successfully: " + res?.data?.ok);
                  setIsEmailing(false);
                  console.log("res", res);
                })
                .catch((err) => {
                  alert(
                    "ERROR: Could not email invoice: " +
                      err?.response?.data?.error
                  );
                  setIsEmailing(false);
                  console.log("catch", err);
                })
                .finally(() => setIsEmailing(false));
            }}
            sx={{ maxHeight: 30 }}
          >
            Email Invoice
          </Button>
        </Stack>
      </Stack>
      <Stack direction="row" gap={1}>
        <Button
          variant="contained"
          color="primary"
          sx={{ maxHeight: 30 }}
          onClick={() =>
            showModal(HistoryModal, {
              invoiceId: data.id,
            })
          }
        >
          History
        </Button>
        <Button
          variant="contained"
          color="error"
          sx={{ maxHeight: 30 }}
          disabled={!getSavedStatus().state}
          onClick={handleReset}
        >
          Reset
        </Button>
        <Button
          variant="contained"
          color="success"
          sx={{ maxHeight: 30 }}
          onClick={() => {
            handleSave();
          }}
          disabled={!getSavedStatus().state}
        >
          {isNaN(parseInt(data.id)) ? "Create" : "Update"}
        </Button>
      </Stack>
    </Stack>
  );
}

export default InvoiceHeader;

function VoidInvoiceDialog({ open, setOpen, invoiceId }) {
  const [voidReason, setVoidReason] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  function handleSubmit(e) {
    e.preventDefault();
    setIsSubmitting(true);
    updateInvoice(invoiceId, { status: "VOIDED", void_note: voidReason }).then(
      (res) => {
        setIsSubmitting(false);
        setOpen(false);
      },
      (error) => {
        alert("Error voiding invoide");
      }
    );
  }

  return (
    <Dialog open={open} onClose={() => setOpen(false)} maxWidth="sm" fullWidth>
      <DialogTitle sx={{ pb: 1 }}>Void Invoice</DialogTitle>
      <form id="voideInvoice" onSubmit={handleSubmit}>
        <DialogContent sx={{ pt: 1, pb: 1 }}>
          <Typography>Void Reason</Typography>
          <TextField
            value={voidReason}
            onChange={(e) => setVoidReason(e.target.value)}
            fullWidth
            rows={5}
            multiline
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={isSubmitting}
          >
            Void Invoice
          </Button>
          <Button
            onClick={() => setOpen(false)}
            variant="contained"
            color="error"
            disabled={isSubmitting}
          >
            Cancel
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
