import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Stack, TextField, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate, useParams } from "react-router-dom";
import Block from "../../components/Block";
import useAppSelector from "../../hooks/useAppSelector";
import useAppDispatch from "../../hooks/useAppDispatch";
import {
  createVendorOverride,
  fetchVendor,
  fetchVendorOverrides, removeVendor,
  removeVendorOverride,
  updateVendor, updateVendorOverride
} from "../../redux/module/vendor";
import UpchargeAddRefactoredForm from "../../components/UpchargeAddRefactoredForm";
import OverridesRefactoredTable from "../../components/OverridesRefactoredTable";
import ConfirmDialog from "../../components/ConfirmDialog";
import UpdateItemTitle from "../../components/UpdateItemTitle";
import CustomTextField from "../../components/CustomTextField";
import PageLoading from "../../components/PageLoading";

const UpdateVendor = () => {
  const { id } = useParams();
  const { vendor, vendorOverrides, vendorsLoading, vendorOverridesLoading } = useAppSelector((state) => state.vendor);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isOpenDiscard, setIsOpenDiscard] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [cleanOverrideFormFlag, setCleanOverrideFormFlag] = useState(false);
  const [overrides, setOverrides] = useState([]);
  const [vendorInfo, setVendorInfo] = useState({
    name: "",
    address: "",
    internal_id: ""
  });

  async function handleSubmit(event) {
    event.preventDefault();
    setIsSubmitting(true);
    const payload = {
      name: vendorInfo.name,
      address: vendorInfo.address,
      internal_id: vendorInfo.internal_id?.toUpperCase() || undefined
    };
    await dispatch(updateVendor({ vendorId: id, vendorInfo: payload }));
    setIsSubmitting(false);
  }

  useEffect(() => {
    if (id) {
      dispatch(fetchVendor(id));
      dispatch(fetchVendorOverrides(id));
    }
  }, [id]);

  useEffect(() => {
    if (vendor?.id === +id) {
      setVendorInfo({
        name: vendor.name,
        address: vendor.address || "",
        internal_id: vendor.internal_id || ""
      });
    }
  }, [vendor]);

  useEffect(() => {
    if (vendorOverrides[0]?.vendor === +id || !vendorOverrides.length) {
      setOverrides(vendorOverrides)
    }
  }, [vendorOverrides]);

  const getIsAllChargeInactive = () => {
    return !vendorOverrides.filter(el => el.is_current === true).length;
  };

  const formFields = [
    {
      id: 1, name: "name", label: "Vendor name", type: "text", value: vendorInfo.name, required: true},
    {
      id: 2,
      name: "address",
      label: "Vendor address",
      type: "text",
      value: vendorInfo.address,
      required: false
    },
    {
      id: 3,
      name: "internal_id",
      label: "Internal Identifier",
      type: "text",
      value: vendorInfo.internal_id,
      required: false
    }
  ];
  
  const handleDiscard = () => {
    dispatch(fetchVendor(id))
    setCleanOverrideFormFlag(true)
  }

  const handleDelete = async () => {
    const resp = await dispatch(removeVendor(id))
    if (resp.payload === id) {
      navigate(-1)
    }
  }
  
  return (
    <Box maxWidth="1394px" margin=" 30px auto 0">
      <PageLoading dataLoading={vendorsLoading || vendorOverridesLoading} />
      
      <ConfirmDialog
        title="Discard updates?"
        open={isOpenDiscard}
        setOpen={setIsOpenDiscard}
        onConfirm={handleDiscard}
      >
        {`Are you sure you want to discard all changes?`}
      </ConfirmDialog>
      <ConfirmDialog
        title="Delete user?"
        open={isOpenDelete}
        setOpen={setIsOpenDelete}
        onConfirm={handleDelete}
      >
        {`Are you sure you want to delete this vendor?`}
      </ConfirmDialog>
      <form id="CategoryUpdate" onSubmit={handleSubmit}>
        <UpdateItemTitle
          item={"Vendor"}
          setIsOpenDelete={setIsOpenDelete}
          setIsOpenDiscard={setIsOpenDiscard}
          isSubmitting={isSubmitting}
        />
        <Grid container columnSpacing={{ xs: 1, sm: 3, xl: "50px" }} margin="20px 0 0">
          <Grid item md={9} lg={9} sx={{ maxWidth: "908px", width: "100%" }}>
            <Block>
              {formFields.map(el => (
                <CustomTextField
                  key={el.id}
                  required={el.required}
                  name={el.name}
                  label={el.label}
                  type={el.type}
                  value={el.value}
                  onChange={(e) => setVendorInfo({ ...vendorInfo, [el.name]: e.target.value })}
                />
              ))}
            </Block>
          </Grid>
        </Grid>
      </form>
      <UpchargeAddRefactoredForm
        fetchOverrides={fetchVendorOverrides}
        createOverride={createVendorOverride}
        cleanOverrideFormFlag={cleanOverrideFormFlag}
        setCleanOverrideFormFlag={setCleanOverrideFormFlag}
        elementKey="vendor"/>
      <Grid container columnSpacing={{ xs: 1, sm: 3, xl: "50px" }} margin="20px 0 0">
        <Grid item md={9} lg={9} sx={{ maxWidth: "908px", width: "100%" }}>
          <Block>
            <Typography component="p" variant="label" mb="11px">
              Vendor Upcharges {getIsAllChargeInactive() && <span style={{ color: "red" }}>(No active up charge)</span>}
            </Typography>
            <Box sx={{ margin: { xs: "0 -16px -16px", sm: "0 -24px -24px" } }}>
              <OverridesRefactoredTable
                overrides={overrides}
                fetchOverrides={fetchVendorOverrides}
                removeOverride={removeVendorOverride}
                updateOverride={updateVendorOverride}
                elementKey="vendor"
              />
            </Box>
          </Block>
        </Grid>
      </Grid>
    </Box>
  );
};


export default UpdateVendor;
