import {
  Box,
  Button,
  Grid,
  Input,
  InputAdornment,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { smallButton } from "../../utils/smallButton";
import Separator from "../../components/Separator";
import React from "react";
import dayjs from "dayjs";
import { DATE_FORMAT_FRONT } from "../../utils/constants";

function SelectedInvoices({
  data,
  payments,
  excludeInvoice,
  setPaymentAmount,
  setIsOpenSelectInvoices,
}) {
  return (
    <>
      {data.customer?.value || data.account?.value ? (
        <Grid item md={12}>
          <Stack direction={"row"} alignItems={"center"}>
            <Typography
              fontSize={20}
              fontWeight={600}
              marginBottom="10px"
              marginTop="10px"
            >
              Pay for invoices
            </Typography>
            <Button
              onClick={() => setIsOpenSelectInvoices(true)}
              variant={"outlined"}
              sx={{ ...smallButton, maxWidth: 200, ml: 2 }}
            >
              Select Invoices
            </Button>
          </Stack>

          <Box key={`invoices-to-pay`}>
            <Grid item width="100%" xl={12}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography fontSize="15px" fontWeight="bold">
                        Invoice
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography fontSize="15px" fontWeight="bold">
                        Date
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography fontSize="15px" fontWeight="bold">
                        Account
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography fontSize="15px" fontWeight="bold">
                        Invoice Amount
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography fontSize="15px" fontWeight="bold">
                        Amount paid
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography fontSize="15px" fontWeight="bold">
                        Amount to pay
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography fontSize="15px" fontWeight="bold">
                        Remaining Amount
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography fontSize="15px" fontWeight="bold">
                        Action
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {payments.map((invoice, index) => (
                    <TableRow>
                      <TableCell>
                        <Typography fontSize={15}>
                          {`#${invoice.invoice}`}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography fontSize={15}>
                          {`${dayjs(invoice.date).format(DATE_FORMAT_FRONT)}`}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography fontSize={15}>
                          {`${invoice.account}`}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          fontSize={15}
                        >{`$${invoice.invoice_amount}`}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography fontSize={15}>
                          ${invoice.total_paid}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Input
                          fullWidth
                          size="small"
                          type="number"
                          startAdornment={
                            <InputAdornment position="start">$</InputAdornment>
                          }
                          value={invoice.amount}
                          onChange={(e) =>
                            setPaymentAmount(index, e.target.value)
                          }
                          required
                        />
                      </TableCell>
                      <TableCell>
                        <Typography fontSize={15}>{`$${(
                          Number(invoice.invoice_amount) -
                          Number(invoice.amount) -
                          Number(invoice?.total_paid)
                        ).toFixed(2)}`}</Typography>
                      </TableCell>
                      <TableCell>
                        <Button
                          variant=""
                          onClick={() => excludeInvoice(invoice.invoice)}
                          size="large"
                        >
                          Exclude
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell>
                      <Typography fontWeight={600}>Total</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography fontWeight={600}>-</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography fontWeight={600}>
                        {`$${payments
                          .reduce(
                            (total, invoice) =>
                              total + Number(invoice.invoice_amount),
                            0,
                          )
                          .toFixed(2)}`}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography fontWeight={600}>{`$${payments
                        .reduce(
                          (total, invoice) =>
                            total + Number(invoice.total_paid),
                          0,
                        )
                        .toFixed(2)}`}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography fontWeight={600}>
                        $
                        {payments
                          .reduce(
                            (total, invoice) => total + Number(invoice.amount),
                            0,
                          )
                          .toFixed(2)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography fontWeight={600}>
                        {`$${payments
                          .reduce(
                            (total, invoice) =>
                              total +
                              Number(invoice.invoice_amount - invoice.amount),
                            0,
                          )
                          .toFixed(2)}`}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography fontSize={15}>-</Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
          </Box>
          <Separator />
        </Grid>
      ) : (
        <Grid item md={12}>
          <Typography
            fontSize={20}
            fontWeight={600}
            marginBottom="10px"
            marginTop="10px"
          >
            Pay for invoices
          </Typography>
          <Typography>Select an Account/Customer to proceed</Typography>
          <Separator />
        </Grid>
      )}
    </>
  );
}

export default SelectedInvoices;
