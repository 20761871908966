import { IconButton, Link, Stack, Typography, Box } from "@mui/material";
import { NavLink } from "react-router-dom";
import Eye from "../../images/eye.svg";
import Edit from "../../images/edit.svg";
import Trash from "../../images/trash-alt.svg";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";

const VariantRenderer = (params) => {
  return (
    <Stack direction="row" alignItems="center" gap="14px">
      <Box sx={{ maxWidth: "50px", "& img": { width: "100%" } }}>
        <img src={params.value.img} alt="" />
      </Box>
      <Typography sx={{ color: "#535151", fontWeight: 600 }}>
        {params.value.name}
      </Typography>
    </Stack>
  );
};

const SkuRenderer = (params) => {
  return <Typography sx={{ color: "#1D1C1C" }}>{params.value}</Typography>;
};

const CostRenderer = (params) => {
  return (
    <Stack>
      <Typography sx={{ color: "#1D1C1C" }}>{params.value.value}</Typography>
      <Link
        to=""
        component={NavLink}
        sx={{
          color: "#535151",
          fontSize: "11px",
          textDecorationColor: "#535151",
        }}
      >
        {params.value.link}
      </Link>
    </Stack>
  );
};

const PriceRenderer = (params) => {
  return (
    <Stack>
      <Typography sx={{ color: "#1D1C1C" }}>{params.value.value}</Typography>
      <Typography sx={{ color: "#535151", fontSize: "11px" }}>
        {params.value.info}
      </Typography>
    </Stack>
  );
};

const ActionRenderer = (param) => {
  return (
    <>
      <IconButton onClick={param.data.editFunction}>
        <EditOutlinedIcon color="basic" />
      </IconButton>
      <IconButton onClick={param.data.deleteFunction}>
        <DeleteForeverOutlinedIcon />
      </IconButton>
    </>
  );
};

const RestrictionRenderer = (params) => {
  return (
    <Link
      to=""
      component={NavLink}
      sx={{ color: "#252525", textDecorationColor: "#252525" }}
    >
      {params.value}
    </Link>
  );
};

const variantConfigTableColumns = [
  {
    headerName: "variant",
    field: "variant",
    minWidth: 230,
    cellRenderer: VariantRenderer,
  },
  {
    headerName: "SKU",
    field: "sku",
    maxWidth: 100,
    cellRenderer: SkuRenderer,
  },
  {
    headerName: "Cost",
    field: "cost",
    maxWidth: 80,
    cellRenderer: CostRenderer,
  },
  {
    headerName: "Upcharge",
    field: "upcharge",
    minWidth: 130,
    cellRenderer: CostRenderer,
  },
  { headerName: "Price", 
    field: "price", 
    cellRenderer: PriceRenderer,
    minWidth: 240,
  },
  // {
  //   headerName: "Restrictions",
  //   field: "restrictions",
  //   minWidth: 140,
  //   cellRenderer: RestrictionRenderer,
  // },
  {
    headerName: "",
    field: "actions",
    minWidth: 100,
    maxWidth: 100,
    cellRenderer: ActionRenderer,
  },
];

export default variantConfigTableColumns;
