import { createManager } from "../../distflowAPI/managersApi";
import { useState } from "react";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import Block from "../../components/Block";
import { errorToJson } from "../../utils";
import AccountSelect from "../../components/AsyncSelects/AccountSelect";

function AddCustomerManagerForm({ open, handleClose }) {
  const [userInfo, setUserInfo] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
  });
  const [selectedAccounts, setSelectedAccounts] = useState();
  const [selectedAccountsAccounting, setSelectedAccountsAccounting] =
    useState();

  const [accountSearch, setAccountSearch] = useState("");
  const [accountSearchAccounting, setAccountSearchAccounting] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState({});
  function cleanUp() {
    setUserInfo({
      first_name: "",
      last_name: "",
      email: "",
      password: "",
    });
    setSelectedAccounts([]);
    setSelectedAccountsAccounting([]);
  }
  const formFields = [
    // { id: 1, name: "userName", label: "User name", type: "text", value: userInfo.userName },
    {
      id: 2,
      name: "first_name",
      label: "First name",
      type: "text",
      value: userInfo.first_name,
      required: true,
    },
    {
      id: 3,
      name: "last_name",
      label: "Last name",
      type: "text",
      value: userInfo.last_name,
      required: true,
    },
    {
      id: 4,
      name: "email",
      label: "Email",
      type: "email",
      value: userInfo.email,
      required: true,
    },
    {
      id: 5,
      name: "password",
      label: "Password",
      type: "password",
      value: userInfo.password,
      required: true,
    },
  ];

  function handleSubmit() {
    const selectedAccountsArray = selectedAccounts;
    const selectedAccountsAccountingArray = selectedAccountsAccounting;
    const payload = {
      user: {
        ...userInfo,
        group_name: "manager",
      },
      accounts: selectedAccountsArray.map((item) => item.value),
      accounting_managers: selectedAccountsAccountingArray.map(
        (item) => item.value,
      ),
    };
    createManager(payload).then(
      (res) => {
        cleanUp();
        handleClose();
      },
      (err) =>
        setErrors(
          errorToJson(err?.response?.data || { error: "Unexpected error" }),
        ),
    );
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle sx={{ pb: 1 }}>Create a new Manager</DialogTitle>
      <Block>
        {Object.keys(errors).map((key) => (
          <Alert severity="error">
            {key}: {errors[key]}
          </Alert>
        ))}

        <DialogContent sx={{ pt: 1, pb: 1 }}>
          {formFields.map((el) => (
            <TextField
              key={el.id}
              required={el.required}
              name={el.name}
              label={el.label}
              type={el.type}
              value={el.value}
              onChange={(e) =>
                setUserInfo({ ...userInfo, [el.name]: e.target.value })
              }
              fullWidth
              sx={{ margin: "8px" }}
            />
          ))}

          <AccountSelect
            style={{ margin: "8px" }}
            name="account"
            label="Manage Orders For"
            required
            value={selectedAccounts}
            onChange={(_event, newValue) => {
              setSelectedAccounts([newValue]);
            }}
            setAccountSearch={setAccountSearch}
          />
          <AccountSelect
            style={{ margin: "8px" }}
            name="account"
            label="Manage Accounting For"
            required
            value={selectedAccountsAccounting}
            onChange={(_event, newValue) => {
              setSelectedAccountsAccounting([newValue]);
            }}
            setAccountSearch={setAccountSearchAccounting}
          />
        </DialogContent>
        <DialogActions style={{ padding: "0px" }}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={isSubmitting}
            onClick={() => handleSubmit()}
          >
            Create Manager
          </Button>
          <Button onClick={handleClose} variant="contained" color="error">
            Cancel
          </Button>
        </DialogActions>
      </Block>
    </Dialog>
  );
}

export default AddCustomerManagerForm;
