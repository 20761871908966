import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import { Box } from "@mui/material";

const buttonsRenderer = () => {
  return (
    <Box>
      <EditOutlinedIcon />
      <DeleteForeverOutlinedIcon />
    </Box>
  );
};

export const columns = [
  {
    headerName: "Credit ID",
    field: "id",
    sortable: true,
  },
  {
    headerName: "Date",
    field: "date",
    sortable: true,
  },
  {
    headerName: "Account",
    field: "account.name",
    sortable: true,
  },
  {
    headerName: "Amount",
    field: "amount",
    sortable: true,
  },
  { headerName: "Payment", field: "payment", sortable: true },
  { headerName: "Actions", cellRenderer: buttonsRenderer, maxWidth: 100 },
];
