import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import Spinner from "./Spinner";

const PageLoading = ({dataLoading}) => {

  const [renderCount, setRenderCount] = useState(0);
  const [pageLoading, setPageLoading] = useState(true);
  
  useEffect(() => {
    if(dataLoading && renderCount < 1) {
      setPageLoading(true)
    } else {
      setPageLoading(false)
      setRenderCount(prev => prev += 1)
    }
  }, [dataLoading]);

  
  return (
    <>
      {pageLoading  &&  <Box
        height="100%"
        display="flex"
        justifyContent="center"
        sx={{
          position: "absolute",
          backgroundColor: "#f6f6f79e",
          width: "calc(100% - 24px)",
          zIndex: 2
        }}
      >
        <Box sx={{
          position: "fixed",
          top: "50%",
          right: "50%"
        }}>
          <Spinner size="lg" />
        </Box>
      </Box>}
    </>
  );
};

export default PageLoading;