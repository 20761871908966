import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { VisibilityRounded } from "@mui/icons-material";
import { useModal } from "mui-modal-provider";
import dayjs from "dayjs";
import ErrorPopupBoundary from "./ErrorPopupBoundary";
import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import HistoryIcon from "@mui/icons-material/History";

{
  /* This component shows a history icon, once clicked, a modal is displayed showing all the changes made to an object */
}

function get_history_diff(his1, his2, excluded_fields) {
  const DEFAULT_EXCLUDED = [
    "history_date",
    "history_id",
    "re_routing",
    "history_user_id",
    "history_type",
  ];
  const changes = {};
  Object.keys(his1).map((key) => {
    if ([...excluded_fields, ...DEFAULT_EXCLUDED].includes(key)) {
      return;
    }
    const val1 = his1[key] ? his1[key] : null;
    const val2 = his2[key] ? his2[key] : null;
    if (`${val1}` != `${val2}`) {
      changes[key] = {
        old: his1[key],
        new: his2[key],
      };
    }
  });
  return changes;
}
function getHistoryData(historyData, excluded_fields) {
  if (historyData.length <= 2) {
    return historyData;
  }
  const newHistoryData = {};
  for (let i = 1; i < historyData.length; i++) {
    const diff = get_history_diff(
      historyData[i - 1],
      historyData[i],
      excluded_fields,
    );
    if (Object.keys(diff).length > 0) {
      newHistoryData[historyData[i].history_id] = {
        ...diff,
        history_date: historyData[i].history_date,
        user: historyData[i].user,
      };
      //newHistoryData[historyData[i - 1].history_id] = historyData[i - 1];
    }
  }
  return Object.keys(newHistoryData).map((key) => newHistoryData[key]);
}
const FIELDS_TO_IGNORE = [
  "user",
  "history_user_id",
  "history_type",
  "history_date",
  "history_id",
  "updated_at",
  "created_at",
  "id",
  "history_change_reason",
  "territory_id",
  "history_id",
  "re_routing",
  "history_user_id",
  "history_type",
  "route_id",
  "order_id",
  "variant_id",
];
function ChangesHistory({
  historyData,
  fontSize = "medium",
  excluded_fields,
  historyIcon,
}) {
  const { showModal } = useModal();
  const hd = getHistoryData(historyData, excluded_fields || []);
  return (
    <Tooltip
      title={`${hd.length} changes`}
      onClick={() => showModal(ChangesModal, { historyData: hd })}
    >
      <IconButton>
        {historyIcon ? (
          <HistoryIcon fontSize={"medium"} color={"black"} />
        ) : (
          <VisibilityRounded fontSize={fontSize} />
        )}
      </IconButton>
    </Tooltip>
  );
}

export function ChangesModal(props) {
  const { ...restProps } = props;
  return (
    <Dialog {...restProps} maxWidth="md" fullWidth>
      <ErrorBoundary
        fallback={<ErrorPopupBoundary onClose={restProps.onClose} />}
      >
        <DialogTitle sx={{ pb: 1 }}>
          Changes History ({props.historyData.length})
        </DialogTitle>
        <DialogContent sx={{ pt: 1, pb: 1 }}>
          {props.historyData.length == 0 && (
            <Typography>This item has no change history</Typography>
          )}
          {props.historyData.length > 0 &&
            props.historyData.map((history) => {
              return (
                <Stack
                  direction="row"
                  alignItems="flex-end"
                  flexWrap="wrap"
                  gap="17px"
                  mb="20px"
                >
                  <Box sx={{ width: "100%" }}>
                    <Typography>
                      Change
                      <span className="change-date">
                        {dayjs(history["history_date"]).format(
                          "MMMM DD, YYYY h:ma",
                        )}
                      </span>{" "}
                      By{" "}
                      <b>
                        {history.user.username} ({history.user.first_name}{" "}
                        {history.user.last_name})
                      </b>
                    </Typography>
                    <Grid container margin="20px 0 0">
                      {Object.keys(history).map((key) => {
                        if (FIELDS_TO_IGNORE.includes(key)) {
                          return "";
                        }
                        return (
                          <Grid item md={4}>
                            <Typography component="p" variant="label" mb="11px">
                              {key}: {history[key]["old"]} Changed to{" "}
                              {history[key]["new"]}
                            </Typography>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Box>
                </Stack>
              );
            })}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => props.onClose()}
            variant="contained"
            color="primary"
          >
            close
          </Button>
        </DialogActions>
      </ErrorBoundary>
    </Dialog>
  );
}

export default ChangesHistory;
