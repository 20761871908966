import React, { useCallback, useEffect } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import { NavLink } from "react-router-dom";
import DataTable from "../../components/DataTable";
import useAppDispatch from "../../hooks/useAppDispatch";
import {
  deleteRoute,
  fetchRoutes,
  selectRouteNumberOfPages,
  setRouteModalAccounts,
  setRouteModalVendors,
  setRouteToDelete,
} from "../../redux/module/route";
import useAppSelector from "../../hooks/useAppSelector";
import routeTableColumns from "./routeTableColumns";
import Popconfirm from "../../components/Popconfirm";
import { fetchDrivers } from "../../redux/module/addEditRoute";
import { fetchVendors } from "../../redux/module/vendor";

const Routes = () => {
  const dispatch = useAppDispatch();
  const routes = useAppSelector((state) => state.route.routes);
  const page = useAppSelector((state) => state.route.page);
  const pageSize = useAppSelector((state) => state.route.pageSize);
  const numberOfPages = useAppSelector(selectRouteNumberOfPages);
  const routeToDelete = useAppSelector((state) => state.route.routeToDelete);
  const routeDeleteLoading = useAppSelector(
    (state) => state.route.routeDeleteLoading
  );
  const routeModalAccounts = useAppSelector(
    (state) => state.route.routeModalAccounts
  );
  const routeModalVendors = useAppSelector(
    (state) => state.route.routeModalVendors
  );

  const fetchTableData = useCallback(
    (params = {}) => {
      dispatch(fetchRoutes(params));
    },
    [dispatch]
  );

  useEffect(() => {
    fetchTableData();
  }, [fetchTableData]);

  useEffect(() => {
    dispatch(fetchDrivers());
    dispatch(fetchVendors({ pageSize: 200, filterBy: {}, orderBy: {}, page: 1 }));
  }, []);

  return (
    <Box maxWidth="1570px" margin={{ xs: "0 auto 15px", md: "30px auto" }}>
      <Popconfirm
        title="Are you sure?"
        show={!!routeToDelete?.id}
        onOk={() => dispatch(deleteRoute(routeToDelete.id))}
        onCancel={() => dispatch(setRouteToDelete(null))}
        loading={routeDeleteLoading}
      />
      <Dialog
        open={!!routeModalAccounts}
        onClose={() => dispatch(setRouteModalAccounts(null))}
      >
        <DialogTitle>Accounts</DialogTitle>
        <DialogContent>
          {routeModalAccounts?.map((account) => (
            <div key={account.id}>{account.account.name}</div>
          ))}
        </DialogContent>
      </Dialog>
      <Dialog
        open={!!routeModalVendors}
        onClose={() => dispatch(setRouteModalVendors(null))}
      >
        <DialogTitle>Vendors</DialogTitle>
        <DialogContent>
          {routeModalVendors?.map((vendor) => (
            <div key={vendor.id}>{vendor.name}</div>
          ))}
        </DialogContent>
      </Dialog>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        spacing={1.5}
        mt="25px"
        mb="34px"
      >
        <Typography fontSize="23px" fontWeight={800} component="h2">
          Routes
        </Typography>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          spacing="15px"
        >
          <Button
            to="/routes/add-route"
            color="success"
            component={NavLink}
            variant="contained"
          >
            Add New
          </Button>
        </Stack>
      </Stack>

      <Box className="table-wrapper routes-table">
        <DataTable
          rowData={routes}
          columnDefs={routeTableColumns}
          rowHeight={44}
          headerHeight={60}
          onSortChanged={(event) =>
            fetchTableData({
              orderBy: event.columnApi.columnModel.gridColumns,
            })
          }
          onFilterChanged={(event) =>
            fetchTableData({ filterBy: event.api.getFilterModel() })
          }
          page={page}
          pageSize={pageSize}
          setPageSize={(pageSize) => fetchTableData({ pageSize })}
          noPages={numberOfPages}
          setPage={(page) => fetchTableData({ page })}
        />
      </Box>
    </Box>
  );
};

export default Routes;
