import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import DatePicker from "./DatePicker2";
import { useState } from "react";
import dayjs from "dayjs";
import { DATE_FORMAT } from "../utils/constants";


const UpdateOverrideModal = ({ override, updateOverrideFunc, updateOverridesTable, ...props }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [upcharge, setUpcharge] = useState(Number(override.override));
  const [startDate, setStartDate] = useState(override.start_date ? dayjs.utc(override.start_date) : dayjs.utc());
  const [endDate, setEndDate] = useState(override.end_date ? dayjs.utc(override.end_date) : null);

  function handleUpdateUpcharge(event) {
    event.preventDefault();
    setIsSubmitting(true);
    const start_date = startDate?.isValid() ? startDate?.format(DATE_FORMAT) : null;
    const end_date = endDate?.isValid() ? endDate?.format(DATE_FORMAT) : null;
    const payload = {
      override: upcharge,
      start_date,
      end_date,
      variant: override.variant
    };

    // params: variantId, overrideId, payload
    updateOverrideFunc(override.variant, override.id, payload, props.onClose);
    setIsSubmitting(false);
  }

  return (
    <Dialog {...props} maxWidth="sm" fullWidth>
      <DialogTitle sx={{ pb: 1 }}>Update upcharge</DialogTitle>
      <form id="overrideUpdateForm" onSubmit={handleUpdateUpcharge}>
        <DialogContent sx={{ pt: 1, pb: 1 }}>
          <Stack direction="row" alignItems="flex-end" flexWrap="wrap" gap="17px" mb="20px">
            <Box>
              <Typography component="p" variant="label" mb="11px">
                Upcharge
              </Typography>
              <TextField
                fullWidth
                data-testid={`test-upcharge-id`}
                type="number"
                variant="outlined"                
                required
                value={upcharge}
                onChange={(e) => setUpcharge(e.target.value)} />
            </Box>
          </Stack>
          <Grid container columnSpacing={{ xs: 1, sm: 3, xl: "10px" }} margin="20px 0 0">
            <Grid item md={6} lg={6}>
              <Typography component="p" variant="label" mb="11px">
                Start Date
              </Typography>
              <DatePicker controlledValue={startDate} setControlledValue={setStartDate} />
            </Grid>
            <Grid item md={6} lg={6}>
              <Typography component="p" variant="label" mb="11px">
                End Date
              </Typography>
              <DatePicker controlledValue={endDate} setControlledValue={setEndDate} />
            </Grid>
          </Grid>          
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" type="submit" disabled={isSubmitting}>Update Override</Button>
          <Button onClick={() => props.onClose()} variant="contained" color="error">Cancel</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default UpdateOverrideModal;
