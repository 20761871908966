import { useState, useEffect, useRef } from "react";
import {
  Alert,
  Button,
  TableBody,
  TableCell,
  Table,
  TableRow,
  Box,
  IconButton,
  TextField,
  Typography,
  DialogTitle,
  Dialog,
  DialogContent,
  Stack,
  Tab,
  Input,
  TableHead,
  InputAdornment,
} from "@mui/material";
import {
  listVariants,
  listAccountOrderRecommendations,
} from "../../../distflowAPI/ordersApi";
import { Download, Search, Upload } from "@mui/icons-material";
import AccountSelect from "../../../components/AsyncSelects/AccountSelect";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import useFile from "../../../hooks/useFile";
import { getAccountApi } from "../../../distflowAPI/accountsApi";
import Spinner from "../../../components/Spinner";
import Product from "../../manageAutoOrders/Product";
import royalLogo from "../../../images/royalLogo.png";
import { DATE_FORMAT } from "../../../utils/constants";
import dayjs from "dayjs";
import {
  checkSaleable,
  SkuToVariant,
  createOrder,
} from "../../../distflowAPI/ordersApi";

function ImportExportAccountOrders({ open, setOpen, refreshTable }) {
  //const [date, setDate] = dayjs(new Date())
  const [months, setMonths] = useState(1);
  const [account, setAccount] = useState();
  const [actionVal, setActionVal] = useState("EXPORT");
  const [recommendations, setRecommendations] = useState({});
  const [variantSearch, setVariantSearch] = useState("");
  const [isProductsLoading, setIsProductsLoading] = useState(false);
  const [variants, setVariants] = useState([]);
  const [productsPage, setProductsPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [error, setError] = useState("");

  function cleanRecommendations(recommendations) {
    const result = {};
    recommendations.map((rec) => (result[`${rec.sku}-${rec.unit}`] = rec));
    return result;
  }

  const fetchImageAsBlob = async (imagePath) => {
    const response = await fetch(imagePath);
    const blob = await response.blob();
    return blob;
  };
  const convertBlobToBase64 = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  async function exportOrders() {
    const wb = new ExcelJS.Workbook();
    const royalBlob = await fetchImageAsBlob(royalLogo);
    const royalLogoB64 = await convertBlobToBase64(royalBlob);
    const imgId = wb.addImage({
      base64: royalLogoB64,
      extension: "png",
    });
    const sheet = wb.addWorksheet("order");
    sheet.getCell("A1").value = "Royal Kosher Logo Here";
    sheet.addImage(imgId, "A1:D5");
    sheet.getCell("A6").value = "Account";
    sheet.getCell("B6").value = account.label;
    sheet.getCell("A7").value = "Account Id";
    sheet.getCell("B7").value = account.value;
    sheet.getCell("A8").value = "IMPORT DATE";
    sheet.getCell("B8").value = "IMPORT DATE HERE(MM/DD/YYYY)";
    sheet.getCell("A10").value = "Description";
    sheet.getCell("B10").value = "SKU";
    sheet.getCell("C10").value = "QTY";
    sheet.getCell("D10").value = "Unit";
    Object.keys(recommendations).map((key, index) => {
      sheet.getCell(`A${11 + index}`).value = recommendations[key]["name"];
      sheet.getCell(`B${11 + index}`).value = recommendations[key]["sku"];
      sheet.getCell(`C${11 + index}`).value = recommendations[key]["qty"];
      sheet.getCell(`D${11 + index}`).value = recommendations[key]["unit"];
    });
    const buf = await wb.xlsx.writeBuffer();
    saveAs(new Blob([buf]), `${account.label}.xlsx`);
  }

  function onClose() {
    setOpen(false);
  }

  useEffect(() => {
    setError("");
    if (!months || isNaN(parseInt(months))) {
      setError("number of months should be a valid number");
      return;
    }
    if (account && months) {
      listAccountOrderRecommendations(account.value, parseInt(months)).then(
        (res) => {
          setRecommendations(cleanRecommendations(res.data.recommendations));
        },
      );
    }
  }, [account, months]);
  const loadMore = async () => {
    setIsProductsLoading(true);
    await listVariants(
      25,
      productsPage + 1,
      [
        { value: account.value, column: "accountId" },
        { value: variantSearch, column: "search" },
      ],
      null,
    ).then((res) => {
      const newVariants = res.results.map((v) => {
        const item = {
          ...v,
          label: v.name,
          ordered: false,
          ordered_pc: false,
        };
        if (recommendations[item.sku + "-" + item.sold_by]) {
          item.ordered = true;
        }
        if (recommendations[item.sku + "-PC"]) {
          item.ordered_pc = true;
        }
        return item;
      });
      setVariants((prev) => [...prev, ...newVariants]);
      setProductsPage((prev) => prev + 1);
      setIsProductsLoading(false);
    });
  };

  function addItem(data, index, qty) {
    setRecommendations({
      ...recommendations,
      [data.item.sku + "-" + (data.unit || data.item.sold_by)]: {
        sku: data.item.sku,
        qty,
        unit: data.unit || data.item.sold_by,
        name: data.item.name,
      },
    });
    let newVariants = [...variants];
    if (data.unit) {
      newVariants[index]["ordered_pc"] = true;
    } else {
      newVariants[index]["ordered"] = true;
    }
    setVariantSearch("");
    setVariants(newVariants);
  }

  function updateVariantsList() {
    if (account && account.value) {
      setIsProductsLoading(true);
      listVariants(
        25,
        productsPage,
        [
          { value: account.value, column: "accountId" },
          { value: variantSearch, column: "search" },
        ],
        null,
      )
        .then((res) => {
          const newVariants = res.results.map((v) => {
            const item = {
              ...v,
              label: v.name,
              ordered: false,
              ordered_pc: false,
              vendor_details: {
                vendorName: v.vendor,
              },
            };
            if (recommendations[item.sku + "-" + item.sold_by]) {
              item.ordered = true;
            }
            if (recommendations[item.sku + "-PC"]) {
              item.ordered_pc = true;
            }
            return item;
          });
          setVariants(newVariants);
          setTotalPages(Math.ceil(res.count / 25));
        })
        .finally(() => {
          setIsProductsLoading(false);
        });
    } else {
      setVariants([]);
    }
  }

  useEffect(() => {
    setTimeout(() => {
      if (productsPage > 1) {
        setProductsPage(1);
      }
      updateVariantsList();
    }, 200);
  }, [account, months, variantSearch]);
  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        "& .MuiPaper-root": {
          width: "100%",
          minWidth: "800px",
          minHeight: "600px",
        },
      }}
    >
      <DialogTitle
        sx={{ fontWeight: "bolder", marginBottom: "0px", marginTop: "0px" }}
      >
        Import/Export account orders
        <Button
          onClick={() => setOpen(false)}
          color="inherit"
          style={{
            position: "absolute",
            right: "16px",
            top: "16px",
            fontSize: "18px",
          }}
        >
          X
        </Button>
      </DialogTitle>
      <DialogContent>
        <TabContext value={actionVal}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={(e, v) => setActionVal(v)}
              aria-label="basic tabs example"
            >
              <Tab
                icon={<Download />}
                iconPosition="start"
                label="Export"
                value="EXPORT"
              />
              <Tab
                icon={<Upload />}
                iconPosition="start"
                label="Import"
                value="IMPORT"
              />
            </TabList>
          </Box>
          <TabPanel value="IMPORT">
            <ImportOrdersTab refreshTable={refreshTable} />
          </TabPanel>
          <TabPanel value="EXPORT">
            <Stack spacing={1}>
              {<Typography color={"red"}>{error}</Typography>}
              {!account && !months && (
                <Typography>
                  Start by selecting an account and number of months to see
                  recommendations
                </Typography>
              )}
              {account &&
                months &&
                Object.keys(recommendations).length == 0 && (
                  <Typography>
                    No recommendations for this account for the specified
                    months, add items manually
                  </Typography>
                )}
              <Stack direction={"row"} spacing={3}>
                <AccountSelect
                  name="account"
                  value={account}
                  fullWidth
                  label="account"
                  onChange={(event, newValue) => {
                    setAccount(null);
                    if (newValue) {
                      if (newValue.value) {
                        setAccount(newValue);
                      }
                    }
                  }}
                />

                <Input
                  value={months}
                  onChange={(e) => setMonths(e.target.value)}
                  label={"months"}
                />
              </Stack>

              {account && months && (
                <Box>
                  <Box
                    mt={1}
                    component="form"
                    sx={{
                      width: "100%",
                      "& .MuiFormControl-root": {
                        width: "100%",
                        minHeight: "40px",
                      },
                    }}
                  >
                    <TextField
                      placeholder="Search for your products..."
                      data-testid="test-search-id"
                      value={variantSearch}
                      onChange={(e) => setVariantSearch(e.target.value)}
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <IconButton>
                              <img src={Search} alt="" />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                  {variantSearch && (
                    <Box
                      sx={{
                        maxHeight: "400px",
                        overflow: "auto",
                        display: "flex",
                        flexDirection: "column",
                        gap: "5px",
                        mt: 1,
                        paddingRight: "5px",
                        "&::-webkit-scrollbar": {
                          borderRadius: "10px",
                          width: "10px",
                          height: "10px",
                        },
                        "&::-webkit-scrollbar-track": {
                          backgroundColor: "#dddddd",
                        },
                        "&::-webkit-scrollbar-thumb": {
                          boxShadow: "inset 0 0 6px rgba(155, 145, 250, 0.3)",
                          backgroundColor: "#95989a",
                          borderRadius: "10px",
                        },
                      }}
                    >
                      {variants.map((item, index) => (
                        <Product
                          parent
                          item={item}
                          account={account}
                          // add item modal
                          customAdd={(data) => addItem(data, index, 1)}
                        />
                      ))}
                      {variantSearch &&
                        !!variants.length &&
                        productsPage < totalPages && (
                          <Button
                            sx={{
                              display: "flex",
                              margin: "10px auto",
                              height: "40px",
                            }}
                            onClick={loadMore}
                            disabled={isProductsLoading}
                          >
                            {isProductsLoading ? (
                              <Spinner size="md" />
                            ) : (
                              "Load more..."
                            )}
                          </Button>
                        )}
                    </Box>
                  )}
                </Box>
              )}
              {account && months && Object.keys(recommendations).length > 0 && (
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Description</TableCell>
                      <TableCell>SKU</TableCell>
                      <TableCell>QTY</TableCell>
                      <TableCell>Unit</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Object.keys(recommendations).map((key) => (
                      <TableRow>
                        <TableCell>{recommendations[key].name}</TableCell>
                        <TableCell>{recommendations[key].sku}</TableCell>
                        <TableCell>{recommendations[key].qty}</TableCell>
                        <TableCell>{recommendations[key].unit}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              )}
              <Button
                variant="outlined"
                onClick={exportOrders}
                disabled={Object.keys(recommendations).length == 0}
              >
                Export
              </Button>
            </Stack>
          </TabPanel>
        </TabContext>
      </DialogContent>
    </Dialog>
  );
}

export default ImportExportAccountOrders;

function ImportOrdersTab(props) {
  const [selectedFile, setSelectedFile] = useState();
  const fileRef = useRef(null);
  const handleFileClick = () => fileRef.current.click();
  const { isFileValid } = useFile(["xlsx"]);
  const [fileData, setFileData] = useState({});
  const [errors, setErrors] = useState({});
  const [accountData, setAccountData] = useState({});
  const [orderCreated, setOrderCreated] = useState(false);
  const [variantsSkuMap, setVariantsSkuMap] = useState({});
  useEffect(() => {
    readFile();
  }, [selectedFile]);
  const onUploadFile = () => {
    const file = fileRef.current.files[0];
    if (isFileValid(file)) {
      setSelectedFile(file);
    } else {
      fileRef.current.value = "";
    }
  };
  useEffect(() => {}, [fileData]);

  async function readFile() {
    if (!selectedFile) {
      return;
    }
    const wb = new ExcelJS.Workbook();
    const reader = new FileReader();
    reader.readAsArrayBuffer(selectedFile);
    const HEADERS = ["Description", "SKU", "QTY", "Unit"];
    const SUPPORTED_UNITS = [
      "PC",
      "BX",
      "BK",
      "BT",
      "CT",
      "DZ",
      "EA",
      "LB",
      "PK",
      "PL",
      "SH",
      "ST",
    ];
    reader.onload = () => {
      const newErrors = {};
      const buffer = reader.result;
      wb.xlsx.load(buffer).then(async (workbook) => {
        const newData = {};
        const sheet = workbook.getWorksheet("order");
        console.log(
          "Header: ",
          sheet.getCell("A10").value,
          sheet.getCell("B10").value,
          sheet.getCell("C10").value,
          sheet.getCell("D10").value,
        );
        if (
          !sheet.getCell("B7").value ||
          parseInt(sheet.getCell("B7").value) == NaN
        ) {
          newErrors["Account Id"] = "Account Id should be a number (B7)";
        }
        newData["accountId"] = sheet.getCell("B7").value;
        if (
          !sheet.getCell("B7").value ||
          !dayjs(sheet.getCell("B8").value, "MM/DD/YYYY").isValid()
        ) {
          newErrors["Date"] = "Invalid Date on B8";
        }
        newData["date"] = dayjs(sheet.getCell("B8").value, "MM/DD/YYYY");
        if (newData["date"].isBefore(dayjs().date())) {
          newData["date"] = "Date can not be in the past";
        }
        if (sheet.getCell("B10").value != "SKU") {
          newErrors["B10"] = "should be SKU";
        }
        if (sheet.getCell("C10").value != "QTY") {
          newErrors["C10"] = "should be QTY";
        }
        if (sheet.getCell("D10").value != "Unit") {
          newErrors["D10"] = "should be Unit";
        }
        setErrors(newErrors);
        newData["items"] = {};
        for (let i = 11; i < sheet.rowCount + 1; i++) {
          const sku = sheet.getCell("B" + i).value;
          if (sku == null) {
            break;
          }
          const saleableRes = await checkSaleable(sku);
          // continue here
          if (
            sheet.getCell("D" + i).value &&
            sheet.getCell("B" + i).value &&
            parseFloat(sheet.getCell("C" + i).value) > 0 &&
            saleableRes.data.salebale
          ) {
            newData["items"][
              `${sheet.getCell("B" + i).value}-${sheet.getCell("D" + i).value}`
            ] = {
              name: sheet.getCell("A" + i).value,
              sku: sheet.getCell("B" + i).value,
              qty: sheet.getCell("C" + i).value,
              unit: sheet.getCell("D" + i).value,
            };
          }
        }
        getAccountApi(newData["accountId"]).then(
          (res) => {
            setAccountData(res.data);
            setFileData(newData);
            SkuToVariant(
              Object.keys(newData.items).map((item) => newData.items[item].sku),
            ).then((res) => {
              const newItems = {};
              Object.keys(newData.items).map((ok) => {
                if (res.data.variants[newData.items[ok].sku]) {
                  newItems[ok] = {
                    ...newData.items[ok],
                    variant: res.data.variants[newData.items[ok].sku],
                  };
                }
              });
              newData["items"] = newItems;
              setFileData(newData);
              if (res.data.missing.length > 0) {
                newErrors["Invalid SKUs"] = res.data.missing.join(", ");
              }
            });
          },
          (error) => {
            newErrors["account"] =
              "Could not find account with the specified ID";
            setErrors(newErrors);
          },
        );
      });
    };
  }

  function handleCreateOrder() {
    const payload = {
      account_id: accountData.id,
      date: fileData.date.format(DATE_FORMAT),
      fulfillment_date: fileData.date.format(DATE_FORMAT),
      creation_mode: "MANUAL",
      items: [],
    };
    Object.keys(fileData.items).map((item) => {
      if (fileData.items[item].qty > 0) {
        payload.items.push({
          variant: fileData.items[item].variant,
          quantity: fileData.items[item].qty,
          unit: fileData.items[item].unit,
          status: "APPROVED",
        });
      }
    });
    payload["create_new"] = true;
    createOrder(payload).then(
      (res) => {
        props.refreshTable(true);
        setOrderCreated(true);
      },
      (error) => {
        const errorField = Object.keys(error.response.data);
        const errorMessage = error.response.data[errorField][0];
        setErrors({ [errorField]: errorMessage });
      },
    );
  }

  return (
    <>
      {orderCreated && (
        <Alert
          sx={{
            maxHeight: "60px",
            minHeight: "20px !important",
            width: "100% !important",
            minWidth: "0px !important",
          }}
        >
          Order created successfully
        </Alert>
      )}
      {!selectedFile && (
        <Stack
          direction="row"
          alignContent={"center"}
          alignItems={"center"}
          spacing={3}
        >
          <Typography>Start by importing a file</Typography>
          <Button variant="contained" onClick={handleFileClick}>
            Choose file
          </Button>
          <input
            style={{ display: "none" }}
            type="file"
            name="fileImport"
            accept=".xlsx"
            ref={fileRef}
            onChange={onUploadFile}
          />
        </Stack>
      )}
      {selectedFile && Object.keys(errors).length > 0 && (
        <Stack spacing={2}>
          {Object.keys(errors).map((key) => (
            <Alert
              severity="error"
              sx={{
                maxHeight: "60px",
                minHeight: "20px !important",
                width: "100% !important",
                minWidth: "0px !important",
              }}
            >
              {key}: {errors[key]}
            </Alert>
          ))}
        </Stack>
      )}
      {selectedFile &&
        Object.keys(fileData).length > 0 &&
        Object.keys(errors).length == 0 && (
          <Stack spacing={3}>
            <Stack
              direction={"row"}
              spacing={2}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography>
                Account: <b>{accountData.name}</b>
              </Typography>
              <Typography>
                Date: <b>{fileData.date.format("MM/DD/YYYY")}</b>
              </Typography>
            </Stack>

            {fileData["items"] && Object.keys(fileData["items"]).length > 0 && (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Description</TableCell>
                    <TableCell>SKU</TableCell>
                    <TableCell>QTY</TableCell>
                    <TableCell>Unit</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.keys(fileData["items"]).map((key) => (
                    <TableRow>
                      <TableCell>{fileData["items"][key].name}</TableCell>
                      <TableCell>{fileData["items"][key].sku}</TableCell>
                      <TableCell>{fileData["items"][key].qty}</TableCell>
                      <TableCell>{fileData["items"][key].unit}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
            <Button
              variant="outlined"
              onClick={handleCreateOrder}
              disabled={orderCreated}
            >
              Create Order
            </Button>
          </Stack>
        )}
    </>
  );
}
