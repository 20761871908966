import { Link } from "react-router-dom";
import { fixedNumber } from "../../utils";
import { DATE_FORMAT_FRONT } from "../../utils/constants";
import dayjs from "dayjs";

function InvoiceNameRenderer(params) {
  return (
    <span className="my-renderer">
      <Link
        to={`/invoices/invoices/${params.data.id}`}
        style={{ textDecoration: "none" }}
      >
        #{params.data.id}
      </Link>
    </span>
  );
}

const INVOICES_STATUS = {
  PAID: "Paid",
  UNPAID: "Unpaid",
  PARTIALLY_PAID: "Partially Paid",
  VOIDED: "Voided",
  CREDIT: "CREDIT",
  OVERPAID: "OVERPAID",
};

const invoice_id = {
  headerName: "Invoice",
  field: "id",
  sortable: true,
  comparator: () => {},
  cellRendererSelector: () => {
    return {
      component: InvoiceNameRenderer,
    };
  },
  minWidth: 100,
};

const invoice_status = {
  sortable: true,
  comparator: () => {},
  flex: 2,
  headerName: "Status",
  field: "status",
  valueGetter: (params) => `${INVOICES_STATUS[params.data.status]}`,
  minWidth: 100,
};

const invoice_total_amount = {
  sortable: true,
  comparator: () => {},
  flex: 2,
  headerName: "Amount",
  field: "total_amount",
  valueGetter: (params) => `$${params.data.total_amount}`,
  minWidth: 100,
};

const amount_remaining = {
  sortable: true,
  comparator: () => {},
  flex: 2,
  headerName: "Balance",
  field: "balance",
  valueGetter: (params) =>
    `$${fixedNumber(params.data.total_amount - params.data.total_paid)}`,
  minWidth: 100,
};
const invoice_date = {
  sortable: true,
  comparator: () => {},
  flex: 2,
  headerName: "Date",
  field: "issued_date",
  minWidth: 100,
  valueGetter: (params) =>
    dayjs.utc(params.data.issued_date).format(DATE_FORMAT_FRONT),
};
const invoice_account = {
  sortable: true,
  comparator: () => {},
  flex: 2,
  headerName: "Account",
  field: "account__name",
  minWidth: 270,
  valueGetter: (params) => params.data.account?.name,
};
const updatePaymentTableColumns = [
  invoice_id,
  invoice_account,
  invoice_status,
  invoice_total_amount,
  amount_remaining,
  invoice_date,
];

export default updatePaymentTableColumns;
