import DataTable from "../../components/DataTable";
import {
  Box,
  Stack,
  Typography,
  Button,
  Alert,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import invoiceTableColumns from "./invoiceTableColumns";
import {
  formatAgGridFilters,
  formatAgGridSorting,
} from "../../distflowAPI/common";
import InvoiceFilters from "./InvoiceFilters";
import { useSearchParams } from "react-router-dom";
import useAppDispatch from "../../hooks/useAppDispatch";
import useAppSelector from "../../hooks/useAppSelector";
import {
  cleanTableSettings,
  fetchInvoices,
  selectInvoiceNumberOfPages,
  setInvoicesTableSettings,
} from "../../redux/module/invoice";
import PageLoading from "../../components/PageLoading";
import { downloadFile } from "../../distflowAPI/downloadFile";
import { generateStatement } from "../../distflowAPI/invoicesApi";
import DatePicker from "../../components/DatePicker2";

const defaultColDef = {
  flex: 1,
  filter: false,
  floatingFilter: false,
};

const ListInvoices = () => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const { invoices, pageSize, page, orderBy, filterBy, invoicesLoading } =
    useAppSelector((state) => state.invoice);
  const numberOfPages = useAppSelector(selectInvoiceNumberOfPages);
  const [open, setOpen] = useState(false);

  const [order, setOrder] = useState([]);

  useEffect(() => {
    dispatch(
      fetchInvoices({ page, pageSize, filterBy, orderBy, searchParams }),
    );
  }, [pageSize, page, filterBy, orderBy, searchParams]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  function handleSortParams(value) {
    let newSortingValue = "";
    if (value?.length > 0) {
      newSortingValue = value[0]?.field;
      if (value[0]?.sort != "asc") {
        newSortingValue = "-" + newSortingValue;
      }
    }
    setSearchParams((params) => {
      const newParams = new URLSearchParams(params);
      newParams.set("ordering", newSortingValue);
      return newParams;
    });
  }
  //{"field":"orderBy","value":[{"field":"issued_date","sort":"asc"}]}
  const handleChange = (field, value) => {
    if (field === "orderBy") {
      handleSortParams(value);
    }
    if (field === "filterBy" || field === "pageSize") {
      dispatch(setInvoicesTableSettings({ field: "page", value: 1 }));
    }
    dispatch(setInvoicesTableSettings({ field, value }));
  };

  useEffect(() => {
    return () => {
      dispatch(cleanTableSettings());
    };
  }, []);

  const getRowStyle = (params) => {
    if (params.data.status === "VOIDED") {
      return { background: "#fff0f0" };
    }
  };
  const [generated, setGenerated] = useState(false);
  const [generateReport, setGenerateReport] = useState(false);
  const [reportStartDate, setReportStartDate] = useState();
  const [reportEndDate, setReportEndDate] = useState();
  function closeGenerateReport() {
    setGenerateReport(false);
  }

  /*
            )*/
  return (
    <>
      <PageLoading dataLoading={invoicesLoading} />
      <Stack direction={"row"} spacing={4} justifyContent={"space-between"}>
        <Typography
          fontSize="23px"
          fontWeight={800}
          component="h2"
          data-testid="page-title"
        >
          Invoices
        </Typography>
        <Stack direction={"row"} spacing={1}>
          <Button
            variant="contained"
            onClick={() => setGenerateReport(true)}
            color="secondary"
          >
            Download Invoices
          </Button>
          <Button
            variant="contained"
            onClick={() => (window.location.href = "/invoices/invoices/new")}
            color="primary"
          >
            New Invoice
          </Button>
        </Stack>
      </Stack>

      <Stack mt={2}>
        {generated && (
          <Alert>
            Statement generated successfull,{" "}
            <a href="/invoices/statement_reports">
              click here for list of statements
            </a>
          </Alert>
        )}
        <Box>
          <InvoiceFilters />
        </Box>
        <Box className="table-wrapper">
          <DataTable
            rowData={invoices}
            columnDefs={invoiceTableColumns}
            getRowStyle={getRowStyle}
            defaultColDef={defaultColDef}
            loading={invoicesLoading}
            suppressSorting={true} //disable default sorting
            rowHeight={53}
            headerHeight={72}
            onSortChanged={(event) =>
              handleChange(
                "orderBy",
                formatAgGridSorting(event.columnApi.columnModel.gridColumns),
              )
            }
            onFilterChanged={(event) =>
              handleChange(
                "filterBy",
                formatAgGridFilters(event.api.getFilterModel()),
              )
            }
            page={page}
            pageSize={pageSize}
            setPageSize={(pageSize) => handleChange("pageSize", pageSize)}
            noPages={numberOfPages}
            setPage={(page) => handleChange("page", page)}
          />
        </Box>
      </Stack>
      <Dialog
        open={generateReport}
        onClose={() => setGenerateReport(false)}
        aria-labelledby="confirm-dialog"
        style={{
          zIndex: "2000!impotant",
        }}
      >
        <DialogTitle id="confirm-dialog">
          Generate Invoices and Statements report
        </DialogTitle>
        <DialogContent>
          <Stack
            direction="row"
            alignItems="flex-end"
            flexWrap="wrap"
            gap="17px"
            mb="20px"
          >
            <Box maxWidth={200}>
              <DatePicker
                label="start date"
                controlledValue={reportStartDate}
                setControlledValue={setReportStartDate}
                required
              />
            </Box>
            <Box maxWidth={200}>
              <DatePicker
                label="end date"
                controlledValue={reportEndDate}
                setControlledValue={setReportEndDate}
                required
              />
            </Box>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color={"error"}
            onClick={() => setGenerateReport(false)}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              generateStatement(
                reportStartDate?.isValid()
                  ? reportStartDate?.format("YYYY-MM-DD")
                  : null,
                reportEndDate?.isValid()
                  ? reportEndDate?.format("YYYY-MM-DD")
                  : null,
              ).then((res) => {
                setGenerated(true);
                setGenerateReport(false);
              });
            }}
            color={"primary"}
          >
            Generate
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ListInvoices;
