export const EMAILS_INIT = {
  customers_list: [],
  email_content: "",
  email_files: [],
};

export const REDUCER_ACTIONS = {
  ADD_CUSTOMER: "ADD_CUSTOMER",
  DELETE_CUSTOMER: "DELETE_CUSTOMER",
  ADD_FILE: "ADD_FILE",
  REMOVE_FILE: "REMOVE_FILE",
};

export function emailsReducer(state, action) {
  switch (action.type) {
    case REDUCER_ACTIONS.ADD_CUSTOMER: {
      const newCustomer = action.payload.newCustomer;
      console.log("new customer is", newCustomer);
      const customerExists = state.customers_list.some(
        (customer) => customer.value === newCustomer.value,
      );
      if (!customerExists && newCustomer?.value) {
        return {
          ...state,
          customers_list: [...state.customers_list, newCustomer],
        };
      }
      return state;
    }

    case REDUCER_ACTIONS.DELETE_CUSTOMER: {
      const currentCustomer = action.payload;
      const filteredCustomers = state.customers_list.filter(
        (customer) => customer.value !== currentCustomer.value,
      );
      return {
        ...state,
        customers_list: filteredCustomers,
      };
    }

    case REDUCER_ACTIONS.ADD_FILE: {
      const newFiles = Array.from(action.payload);
      return {
        ...state,
        email_files: [...state.email_files, ...newFiles],
      };
    }

    case REDUCER_ACTIONS.REMOVE_FILE: {
      const selectedFile = action.payload;
      console.log("delete", selectedFile);
      const filteredFiles = state.email_files.filter(
        (file) => file !== selectedFile,
      );
      return {
        ...state,
        email_files: filteredFiles,
      };
    }

    default:
      return state;
  }
}
