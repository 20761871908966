import {
    Box
} from "@mui/material";
import { styled } from "@mui/system";

const DeliveryAccount = styled(Box)((props) => {
    return {
        display: "flex",
        alignItems: "center",
        color: "#252525",
        gap: "9px",
        fontWeight: 600,
        "&::before": {
            content: "''",
            display: "inline-flex",
            width: 12,
            height: 12,
            flexShrink: 0,
            borderRadius: "50%",
            backgroundColor: props.status === "DELIVERED" ? "#3FC6A5" : "#E2E2E2",
        },
    };
});

export default DeliveryAccount;
