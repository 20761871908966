import { useState, useEffect } from "react";
import { Button, Typography, Stack, Box } from "@mui/material";
import DataTable from "../../components/DataTable";
import AddGroupFrom from "./AddGroupFrom";
import { useSearchParams } from "react-router-dom";
import { fetchGroups, selectGroupNumberOfPages, setGroupTableSettings, cleanTableSettings } from "../../redux/module/group";
import useAppSelector from "../../hooks/useAppSelector";
import useAppDispatch from "../../hooks/useAppDispatch";
import groupTableColumns from "./groupTableColumns";
import { formatAgGridFilters, formatAgGridSorting } from "../../distflowAPI/common";
import PageLoading from "../../components/PageLoading";

const defaultColDef = {
  flex: 1,
  filter: true,
  floatingFilter: true,
};

const Groups = () => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const { groups, pageSize, page, orderBy, filterBy, groupsLoading }= useAppSelector((state) => state.group);
  const numberOfPages = useAppSelector(selectGroupNumberOfPages);

  const [open, setOpen] = useState(false);


  useEffect(() => {
    dispatch(fetchGroups({ page, pageSize, filterBy, orderBy }));
  }, [pageSize, page, filterBy, orderBy]);

  useEffect(() => {
    const params = new URLSearchParams();
    filterBy.forEach((item) => {
      params.set(item.column, item.value);
    });
    setSearchParams(params);
  }, [filterBy]);
  
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleChange = (field, value) => {
    if (field === "filterBy" || field === "pageSize") {
      dispatch(setGroupTableSettings({ field: "page", value: 1 }));
    }
    dispatch(setGroupTableSettings({ field, value }));
  };

  useEffect(() => {
    return () => {
      dispatch(cleanTableSettings())
    }
  }, []);
  
  return (
    <>
      <PageLoading dataLoading={groupsLoading} />
      
      <Typography fontSize="23px" fontWeight={800} component="h2" data-testid="page-title">
        Account Types
      </Typography>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        alignItems={{ xs: "space-between", sm: "center" }}
        justifyContent="space-between"
        spacing={1.5}
        my="25px"
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          spacing="15px"
        >
          <Button onClick={handleOpen} variant="contained">
            Add New
          </Button>
        </Stack>
      </Stack>

      <Box className="table-wrapper">
        <DataTable
          rowData={groups}
          columnDefs={groupTableColumns}
          defaultColDef={defaultColDef}
          suppressSorting={true} //disable default sorting
          loading={groupsLoading}
          rowHeight={53}
          headerHeight={60}
          onSortChanged={(event) =>
            handleChange("orderBy", formatAgGridSorting(event.columnApi.columnModel.gridColumns))
          }
          onFilterChanged={(event) =>
            handleChange("filterBy", formatAgGridFilters(event.api.getFilterModel()) || [])
          }
          page={page}
          pageSize={pageSize}
          setPageSize={(pageSize) => handleChange("pageSize", pageSize)}
          noPages={numberOfPages}
          setPage={(page) => handleChange("page", page)}
        />
      </Box>
      <AddGroupFrom open={open} handleClose={handleClose} />
    </>
  );
};

export default Groups;
