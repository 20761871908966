import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  IconButton,
  OutlinedInput,
  Stack,
  Typography,
} from "@mui/material";
import { NavLink, useSearchParams } from "react-router-dom";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import DataTable from "../../components/DataTable";
import useAppDispatch from "../../hooks/useAppDispatch";
import useAppSelector from "../../hooks/useAppSelector";
import {
  fetchProducts,
  selectProductNumberOfPages,
} from "../../redux/module/product";
import productTableColumns from "./productTableColumns";
import { formatAgGridFilters } from "../../distflowAPI/common";
import ConfirmDialog from "../../components/ConfirmDialog";
import productsApi from "../../distflowAPI/products";
import {
  fireErrorNotification,
  fireSuccessNotification,
  useSearchDebounce,
} from "../../utils";
import ProductFilters from "./ProductFilters";
import * as PropTypes from "prop-types";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import PageLoading from "../../components/PageLoading";

const defaultColDef = {
  flex: 1,
  filter: false,
  floatingFilter: false,
};

function InputButton(props) {
  return null;
}

InputButton.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.node,
};
const Products = () => {
  const dispatch = useAppDispatch();
  const products = useAppSelector((state) => state.product.products);
  const productsLoading = useAppSelector(
    (state) => state.product.productsLoading,
  );
  const page = useAppSelector((state) => state.product.page);
  const pageSize = useAppSelector((state) => state.product.pageSize);
  const numberOfPages = useAppSelector(selectProductNumberOfPages);
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchProductDB, setSearchProductDB] = useSearchDebounce();

  const [filterBy, setFilterBy] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [searchProduct, setSearchProduct] = useState(
    searchParams.get("search") || "",
    searchParams.get("show_hidden") || "false",
  );
  const [showFilters, setShowFilters] = useState(false);

  const fetchTableData = useCallback(
    (params) => {
      dispatch(fetchProducts(params));
    },
    [dispatch],
  );
  useEffect(() => {
    fetchTableData({ filterBy: {}, searchParams });
  }, [fetchTableData, searchParams]);

  const onSelectionChanged = (e) => {
    const selected = e.api.getSelectedRows();
    setSelectedProducts(selected);
  };

  const handleDeleteSelected = () => {
    if (selectedProducts.length) {
      selectedProducts.forEach((el) => {
        productsApi
          .delete(el.id)
          .then(() => {
            fireSuccessNotification(`Product ${el.name} deleted successfully`);
          })
          .catch((error) => {
            // const protected_elements = error.payload.data.protected_elements;
            // fireErrorNotification(`Protected by:
            // ${protected_elements.map(el => ` id: ${el.id} label: ${el.label}`)}`);
            fireErrorNotification(
              `Protected Error, you can't delete this product: ${el.name}`,
            );
          });
      });
    }
  };

  const handleChange = (value) => {
    setSearchParams((params) => {
      const newParams = new URLSearchParams(params);
      if (!!value) {
        newParams.set("search", value);
      } else {
        newParams.delete("search");
      }
      return newParams;
    });
  };

  useEffect(() => {
    setSearchProductDB(searchProduct);
  }, [searchProduct]);

  useEffect(() => {
    handleChange(searchProductDB);
  }, [searchProductDB]);

  return (
    <>
      <PageLoading dataLoading={productsLoading} />

      <Typography
        fontSize="23px"
        fontWeight={800}
        component="h2"
        data-testid="page-title"
      >
        Products
      </Typography>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        alignItems={{ xs: "space-between", sm: "center" }}
        justifyContent="space-between"
        spacing={1.5}
        my="25px"
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          spacing="15px"
        >
          <Button
            to="/products/add-product"
            component={NavLink}
            variant="contained"
            sx={{
              display: "flex",
              flexShrink: "0",
            }}
          >
            Add New
          </Button>
          <ConfirmDialog
            title="Delete products?"
            open={isOpenDelete}
            setOpen={setIsOpenDelete}
            onConfirm={handleDeleteSelected}
          >
            {`Are you sure you want to delete selected products?`}
          </ConfirmDialog>
          <Button
            className="btn-height"
            variant="outlined"
            onClick={() => setIsOpenDelete(true)}
            sx={{
              display: "flex",
              flexShrink: "0",
            }}
          >
            Delete Selected
          </Button>
          <OutlinedInput
            placeholder="Search product"
            size="small"
            sx={{ height: "40px" }}
            fullWidth
            value={searchProduct}
            onChange={(e) => setSearchProduct(e.target.value)}
          />
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          spacing="15px"
        >
          <Button
            className="btn-height"
            variant="outlined"
            endIcon={showFilters ? <FilterAltOffIcon /> : <FilterAltIcon />}
            onClick={() => setShowFilters(!showFilters)}
          >
            Filters
          </Button>
          <Button
            className="btn-height"
            variant="outlined"
            endIcon={<FileUploadIcon />}
          >
            Import
          </Button>
          <Button
            className="btn-height"
            variant="outlined"
            endIcon={<FileDownloadIcon />}
          >
            Export
          </Button>
        </Stack>
      </Stack>

      <ProductFilters showFilters={showFilters} />

      <Box className="table-wrapper">
        <DataTable
          rowData={products}
          columnDefs={productTableColumns}
          defaultColDef={defaultColDef}
          loading={productsLoading}
          rowHeight={53}
          headerHeight={60}
          suppressSorting={true} //disable default sorting
          rowSelection={"multiple"}
          onSelectionChanged={onSelectionChanged}
          onSortChanged={(event) =>
            fetchTableData({
              orderBy: event.columnApi.columnModel.gridColumns,
            })
          }
          onFilterChanged={(event) => {
            fetchTableData({ filterBy: event.api.getFilterModel() });
            setFilterBy(formatAgGridFilters(event.api.getFilterModel()) || []);
            console.log("fetching data with: ", {
              filterBy: event.api.getFilterModel(),
            });
            console.log(
              "setting filter by to: ",
              formatAgGridFilters(event.api.getFilterModel()),
            );
          }}
          page={page}
          pageSize={pageSize}
          setPageSize={(pageSize) => fetchTableData({ pageSize })}
          noPages={numberOfPages}
          setPage={(page) => fetchTableData({ page })}
        />
      </Box>
    </>
  );
};

export default Products;
