import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
  DialogTitle,
  DialogContent,
  Grid,
  Paper,
  Divider,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Select,
  MenuItem,
} from "@mui/material";
import ConfirmDialog from "../../components/ConfirmDialog";
import { transactionTableColumns } from "../payments/paymentTableColumns";
import paymentTableColumns from "../payments/paymentTableColumns";
import { useState, useEffect, useMemo } from "react";
import DataTable from "../../components/DataTable";
import useAppSelector from "../../hooks/useAppSelector";
import {
  formatAgGridFilters,
  formatAgGridSorting,
} from "../../distflowAPI/common";
import payment, {
  cleanTableSettings,
  fetchPayments,
  selectPaymentNumberOfPages,
  setPaymentsTableSettings,
} from "../../redux/module/payment";
import useAppDispatch from "../../hooks/useAppDispatch";
import { useNavigate } from "react-router-dom";

import TransactionPaymentFilters from "./TransactionsPaymentFilter";
import paymentsApi from "../../distflowAPI/paymentsApi";
import { cCupdateBankDeposit } from "../../distflowAPI/ordersApi";
import { transactionsColumns } from "../transactions";
import { listTransactions } from "../../distflowAPI/invoicesApi";
import { useSearchParams } from "react-router-dom";
import dayjs from "dayjs";
import { DATE_FORMAT_FRONT } from "../../utils/constants";
import { smallButton } from "../../utils/smallButton";
import DatePickerCustom2 from "../../components/DatePicker2";

const defaultColDef = {
  flex: 1,
  filter: false,
  floatingFilter: false,
};

export default function MatchingTransactionsModal({
  open,
  onClose,
  title,
  content,
  onConfirm,
  statements,
  selectedTrans,
  setOpen,
  updateStatementTable,
  ...props
}) {
  const [selectedMatch, setSelectedMatch] = useState();
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);
  const [tableUpdated, setTableUpdated] = useState(false);
  const dispatch = useAppDispatch();
  const [payments, setPayments] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [numberOfPages, setNumberOfPages] = useState(1);
  const navigate = useNavigate();
  const transaction = statements[selectedTrans];
  const [paymentsLoading, setPaymentsLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [filterBy, setFilterBy] = useState({
    account__name: searchParams.get("account__name") || "",
    //amount_paid__gte: searchParams.get("amount_paid__gte") || "",
    //amount_paid__lte: searchParams.get("amount_paid__lte") || "",
    payment_date__gte: searchParams.get("payment_date__gte") || "",
    payment_date__lte: searchParams.get("payment_date__lte") || "",
    method: searchParams.get("method") || "",
    reference: searchParams.get("reference") || "",
    //customer__name: searchParams.get("customer__name") || "",
    //notes: searchParams.get("notes") || "",
  });

  const [showAllTransactions, setShowAllTransactions] = useState(false);
  useEffect(() => {
    setPaymentsLoading();
    if (!showAllTransactions) {
      setPayments([]);
      return;
    }
    listTransactions(page, pageSize, [], [], searchParams).then((res) => {
      setPayments(res.data.results);
      setNumberOfPages(parseInt(res.data.coun / pageSize) + 1);
    });
  }, [pageSize, page, tableUpdated, searchParams, showAllTransactions]);
  const handleChange = (value) => {
    setSearchParams((params) => {
      const newParams = new URLSearchParams(params);
      Object.keys(value).forEach((key) => {
        if (!!value[key]) {
          newParams.set(key, value[key]);
        } else {
          newParams.delete(key);
        }
      });
      return newParams;
    });
  };

  useEffect(() => {
    handleChange(filterBy);
  }, [filterBy]);
  function handleSortParams(value) {
    let newSortingValue = "";
    if (value?.length > 0) {
      newSortingValue = value[0]?.field;
      if (value[0]?.sort != "asc") {
        newSortingValue = "-" + newSortingValue;
      }
    }
    setSearchParams((params) => {
      const newParams = new URLSearchParams(params);
      newParams.set("ordering", newSortingValue);
      return newParams;
    });
  }
  function cleanPayments(payments) {
    const possibleMatches = [];
    if (
      transaction &&
      transaction.cc_possible_matches &&
      transaction.cc_possible_matches.length > 0
    ) {
      transaction.cc_possible_matches.map((pm) =>
        possibleMatches.push({
          ...pm.payment,
          possibleMatch: {
            match_probability: pm.match_probability,
            note: pm.note,
          },
        })
      );
    }
    const possibleMatchesIds = possibleMatches.map((pm) => pm.id);
    const newPayments = [...possibleMatches];
    payments.map((p) => {
      if (!possibleMatchesIds.includes(p.id)) {
        newPayments.push({
          ...p,
          possibleMatch: {
            match_probability: "LOW",
            note: "no matching points",
          },
        });
      }
    });
    return newPayments;
  }

  function MatchSelector(matchIndex) {
    return (
      <Stack direction={"row"} spacing={2} alignItems={"center"}>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => {
            setSelectedMatch(matchIndex);
            setIsOpenConfirm(true);
          }}
          sx={smallButton}
        >
          Match
        </Button>
      </Stack>
    );
  }
  const action = {
    field: "",
    sorting: false,
    sort: false,
    sortable: false,
    autoHeight: true,
    filter: false,
    headerName: "Actions",
    cellRenderer: (param) => MatchSelector(param.rowIndex),
    minWidth: 120,
    maxWidth: 120,
  };
  const probability = {
    field: "",
    sorting: true,
    sort: "desc",
    sortable: true,
    autoHeight: true,
    filter: false,
    headerName: "Probability",
    //{param.data?.possibleMatch?.note}
    valueGetter: (params) => {
      let score = 0;
      if (params.data?.possibleMatch) {
        if (params.data?.possibleMatch?.match_probability == "HIGH") {
          return 3;
        }
        if (params.data?.possibleMatch?.match_probability == "MID") {
          return 2;
        }
        if (params.data?.possibleMatch?.match_probability == "LOW") {
          return 1;
        }
      }
      return score;
    },
    cellRenderer: (param) => (
      <Typography>
        {param.data?.possibleMatch
          ? `${param.data?.possibleMatch?.match_probability}`
          : `Not a match`}
      </Typography>
    ),

    minWidth: 120,
    maxWidth: 120,
  };
  const cleanedPayments = useMemo(
    () => cleanPayments(payments),
    [payments, transaction]
  );
  if (transaction) {
    console.log("triggered...");
    console.log("TRANSACTION TO MATCH IS: ", transaction);
    return (
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
          //setShowAllTransactions(false);
        }}
        sx={{
          "& .MuiPaper-root": {
            width: "100%",
            minWidth: "1200px",
            minHeight: "800px",
          },
        }}
      >
        <DialogTitle
          sx={{ fontWeight: "bolder", marginBottom: "0px", marginTop: "0px" }}
        >
          Match a payment
          <Button
            onClick={() => {
              setOpen(false);
              //setShowAllTransactions(false);
            }}
            color="inherit"
            style={{
              position: "absolute",
              right: "16px",
              top: "16px",
              fontSize: "18px",
            }}
          >
            X
          </Button>
        </DialogTitle>
        <DialogContent>
          <Stack spacing={1}>
            <Divider />
            <Grid container spacing={-1} paddingLeft={6}>
              <Grid item xs={12} md={2}>
                <Typography variant="h6" sx={{ fontSize: "17px" }} gutterBottom>
                  Date:
                </Typography>
                <Typography sx={{ fontSize: "17px" }} variant="body1">
                  {transaction.date}
                </Typography>
              </Grid>
              <Grid item xs={12} md={3}>
                <Typography variant="h6" sx={{ fontSize: "17px" }} gutterBottom>
                  CC digits
                </Typography>
                <Typography sx={{ fontSize: "17px" }} variant="body1">
                  {transaction.cc_digit} ({transaction.card_type})
                </Typography>
              </Grid>
              <Grid item xs={12} md={2}>
                <Typography variant="h6" sx={{ fontSize: "17px" }} gutterBottom>
                  Amount
                </Typography>
                <Typography variant="body1" sx={{ fontSize: "17px" }}>
                  ${transaction.amount}
                </Typography>
              </Grid>
              <Grid item xs={12} md={3}>
                <Typography variant="h6" sx={{ fontSize: "17px" }} gutterBottom>
                  Ref Number
                </Typography>
                <Typography sx={{ fontSize: "17px" }} variant="body1">
                  {transaction.ref}
                </Typography>
              </Grid>
              <Grid item xs={12} md={2}>
                <Typography variant="h6" sx={{ fontSize: "17px" }} gutterBottom>
                  Bank ID:
                </Typography>
                <Typography variant="body1" sx={{ fontSize: "17px" }}>
                  {transaction.bank_id}
                </Typography>
              </Grid>

              {transaction.note && (
                <Grid item xs={12} md={12}>
                  <Typography
                    variant="h6"
                    sx={{ fontSize: "17px", mb: 1 }}
                    gutterBottom
                  >
                    Note:
                  </Typography>
                  <Typography variant="body1" sx={{ fontSize: "17px" }}>
                    {transaction.note}
                  </Typography>
                </Grid>
              )}
            </Grid>
            <Divider />

            <div style={{ padding: "10px" }}>
              <Stack
                direction={"row"}
                alignContent={"center"}
                spacing={1}
                alignItems={"center"}
              >
                <Button
                  variant={!showAllTransactions ? "contained" : "outlined"}
                  color="success"
                  onClick={() => setShowAllTransactions(false)}
                >
                  P.M only
                </Button>
                <Button
                  variant={showAllTransactions ? "contained" : "outlined"}
                  color="success"
                  onClick={() => setShowAllTransactions(true)}
                >
                  All tran.
                </Button>
                <TextField
                  label="Reference"
                  sx={{ maxWidth: 150 }}
                  value={filterBy.reference}
                  onChange={(e) =>
                    setFilterBy({ ...filterBy, reference: e.target.value })
                  }
                />
                <TextField
                  label="Account"
                  sx={{ maxWidth: 150 }}
                  value={filterBy.account__name}
                  onChange={(e) =>
                    setFilterBy({ ...filterBy, account__name: e.target.value })
                  }
                />
                <DatePickerCustom2 maxWidth={150} label={"Date GTE"} />
                <DatePickerCustom2 maxWidth={150} label={"Date LTE"} />
                <Button variant="outlined" color="success">
                  Check
                </Button>
                <Button variant="outlined" color="success">
                  Credit Card
                </Button>
              </Stack>
            </div>
            <Divider />
            <Box className="table-wrapper" marginTop={"10px"}>
              <DataTable
                rowData={cleanedPayments}
                columnDefs={[...transactionsColumns, probability, action]}
                defaultColDef={defaultColDef}
                suppressSorting={true} //disable default sorting
                loading={paymentsLoading}
                rowHeight={10}
                headerHeight={40}
                onSortChanged={(event) => {
                  handleSortParams(
                    formatAgGridSorting(event.columnApi.columnModel.gridColumns)
                  );
                }}
                page={page}
                pageSize={pageSize}
                setPageSize={(pageSize) => setPageSize(pageSize)}
                noPages={numberOfPages}
                setPage={(page) => setPage(page)}
                overlayNoRowsTemplate={
                  "<span style=\"\">This is a custom 'no rows' overlay</span>"
                }
              />
            </Box>

            <ConfirmDialog
              title="Confirm the match"
              open={isOpenConfirm}
              setOpen={setIsOpenConfirm}
              onConfirm={() => {
                cCupdateBankDeposit(statements[selectedTrans].id, {
                  payment: cleanPayments(payments)[selectedMatch].id,
                }).then(
                  (res) => {
                    setTableUpdated((prev) => !prev);
                    updateStatementTable();
                    setOpen(false);
                  },
                  (err) => {}
                );
              }}
            >
              Are you sure, you want to match ?
            </ConfirmDialog>
          </Stack>
        </DialogContent>
      </Dialog>
    );
  }
}
