import React, { useState } from "react";
import {
  Button,
  Typography,
  Box,
  Stack,
  Alert,
  Collapse,
  IconButton,
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Container from "@mui/material/Container";
import CloseIcon from "@mui/icons-material/Close";

import OTPInput from "../../components/OTPInput";
import { resendOtp } from "../../distflowAPI/user";
import useAppDispatch from "../../hooks/useAppDispatch";
import { verifyOtp } from "../../redux/module/user";

const OtpVerification = () => {
  const [otp, setOtp] = useState("");
  const [open, setOpen] = React.useState(false);

  const username = new URLSearchParams(window.location.search).get("username");
  const email = new URLSearchParams(window.location.search).get("email");
  const dispatch = useAppDispatch();

  const handleOtpChange = (e) => {
    if (otp.length === 4) {
      dispatch(verifyOtp({ otp, username }));
    }
  };

  const resend = () => {
    console.log("Resend OTP");
    if (username) {
      setOpen(true);
      resendOtp({ username })
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  React.useEffect(() => {
    if (!username) {
      window.location.href = "/auth/login";
    }
  }, []);

  return (
    <Box sx={{ position: "relative" }}>
      <Collapse in={open}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpen(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          OTP has been resent to your email address {email}
        </Alert>
      </Collapse>
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Stack spacing={1} alignItems="center" justifyContent="center">
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Otp Verification
            </Typography>
            <Typography
              component="p"
              variant="body1"
              textAlign={"center"}
              fontSize={14}
            >
              Please enter the OTP sent to your email{" "}
              <Typography
                component="span"
                variant="caption"
                sx={{ fontWeight: "bold" }}
                color="primary"
              >
                {email}
              </Typography>
            </Typography>
          </Stack>
        </Box>
        <Box>
          <Box noValidate sx={{ mt: 1 }}>
            <OTPInput
              length={4}
              onComplete={(otp) => {
                setOtp(otp);
              }}
            />
            <Typography
              component="p"
              variant="caption"
              sx={{ mt: 1 }}
              textAlign={"center"}
            >
              Didn't receive the OTP?{" "}
              <Typography
                component="span"
                variant="caption"
                sx={{ fontWeight: "bold", cursor: "pointer" }}
                color="primary"
                onClick={() => resend()}
              >
                Resend OTP
              </Typography>
            </Typography>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={(e) => handleOtpChange(e)}
            >
              Verify OTP
            </Button>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default OtpVerification;
