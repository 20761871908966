import React, { useEffect, useState } from "react";
import DataTable from "../../components/DataTable";
import getCols from "./productOrdersHistoryTable";
import { Stack, TextField, Typography, Box, Button } from "@mui/material";
import dayjs from "dayjs";
import {
  listProductOrders,
  listProductReturns,
  listVariantOrders,
  listVariantReturns,
} from "../../distflowAPI/products";
import {
  formatAgGridFilters,
  formatAgGridSorting,
} from "../../distflowAPI/common";
import DatePickerCustom2 from "../../components/DatePicker2";
import { getProductCSV, getVariantCSV } from "../../distflowAPI/products";

import { downloadFile } from "../../distflowAPI/downloadFile";
import { DATE_FORMAT } from "../../utils/constants";
import { getNoPages } from "../../utils";
const defaultColDef = {
  flex: 1,
  height: "30px",
  filter: false,
  floatingFilter: false,
};

const ProductOrdersHistory = ({ type, id }) => {
  const [oType, setOType] = useState("ITEMS");
  const [rowData, setRowData] = useState([]);
  const [productOrdersHistoryLoading, setProductOrdersHistoryLoading] =
    useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [numberOfPages, setNumberOfPage] = useState(1);
  const [gridApi, setGridApi] = useState(null);
  const [selectedIds, setSelectedIds] = useState([]);
  const [idsString, setIdsString] = useState("");
  const [filterBy, setFilterBy] = useState({
    account: null,
    fulfillment_date__gte: null,
    fulfillment_date__lte: null,
    quantity__gte: null,
    quantity__lte: null,
    route__name: null,
  });
  const [orderBy, setOrderBy] = useState([
    {
      field: "order__fulfillment_date",
      sort: "desc",
    },
  ]);

  const onSelectionChanged = (event) => {
    setSelectedIds(event.api.getSelectedNodes());
  };
  useEffect(() => {
    if (selectedIds.length > 0) {
      setIdsString(
        selectedIds
          .map((row) => {
            return row.data.id;
          })
          .join(",")
      );
    }
  }, [selectedIds]);
  function handleDownload() {
    const queryFilter = [];
    Object.keys(filterBy).map((fk) => {
      if (filterBy[fk]) {
        queryFilter.push({ column: fk, value: filterBy[fk] });
      }
    });
    if (type === "product") {
      getProductCSV(id, queryFilter, idsString).then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "products-order-history.csv");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      });
    }
    if (type === "variant") {
      getVariantCSV(id, queryFilter, idsString).then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "variants-order-history.csv");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      });
    }
  }
  console.log("rows are", rowData);
  useEffect(() => {
    setProductOrdersHistoryLoading(true);
    const queryFilter = [];
    Object.keys(filterBy).map((fk) => {
      if (filterBy[fk]) {
        queryFilter.push({ column: fk, value: filterBy[fk] });
      }
    });
    if (type == "product") {
      if (oType == "ITEMS") {
        listProductOrders(id, pageSize, page, orderBy, queryFilter)
          .then((res) => {
            setRowData(res.data.results);
            setProductOrdersHistoryLoading(false);
          })
          .finally(() => setProductOrdersHistoryLoading(false));
      } else {
        listProductReturns(id, pageSize, page, orderBy, queryFilter)
          .then((res) => {
            setRowData(res.data.results);
            setProductOrdersHistoryLoading(false);
          })
          .finally(() => setProductOrdersHistoryLoading(false));
      }
    } else {
      if (oType == "ITEMS") {
        listVariantOrders(id, pageSize, page, orderBy, queryFilter)
          .then((res) => {
            setRowData(res.data.results);
            setProductOrdersHistoryLoading(false);
            setNumberOfPage(getNoPages(res.data.count, pageSize));
          })
          .finally(() => setProductOrdersHistoryLoading(false));
      } else {
        listVariantReturns(id, pageSize, page, orderBy, queryFilter)
          .then((res) => {
            setRowData(res.data.results);
            setProductOrdersHistoryLoading(false);
            setNumberOfPage(getNoPages(res.data.count, pageSize));
          })
          .finally(() => setProductOrdersHistoryLoading(false));
      }
    }
  }, [type, id, page, pageSize, filterBy, orderBy, oType]);
  function handleGetSelectedRows() {
    if (!gridApi) return;
    const selectedRows = gridApi.getSelectedRows();
    setSelectedIds(
      selectedRows.map((row) => {
        return row.id;
      })
    );
  }

  const ProductHistoryTableCols = getCols({ type });
  return (
    <div
      className="variant-table orders-history table-pagination-padding-right"
      style={{
        paddingBottom: "12px",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Stack>
          <Stack direction="row" spacing={1} paddingLeft={2}>
            <Button
              variant="contained"
              color={oType == "ITEMS" ? "success" : "primary"}
              onClick={() => setOType("ITEMS")}
            >
              Items
            </Button>
            <Button
              variant="contained"
              color={oType == "RETURNS" ? "success" : "primary"}
              onClick={() => setOType("RETURNS")}
            >
              Returns
            </Button>
          </Stack>
          <Stack direction={"row"} spacing={1}>
            <DataFilter filterBy={filterBy} setFilterBy={setFilterBy} />
            <Stack paddingTop={2}>
              <Button
                //sx={{ mr: "10px" }}
                variant="contained"
                onClick={() => handleDownload()}
              >
                Download
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Box>
      {!productOrdersHistoryLoading && rowData.length == 0 ? (
        <Typography marginLeft={3}>This Product has no orders</Typography>
      ) : (
        <DataTable
          rowData={rowData}
          loading={productOrdersHistoryLoading}
          columnDefs={ProductHistoryTableCols}
          defaultColDef={defaultColDef}
          rowHeight={40}
          rowSelection="multiple"
          page={page}
          pageSize={pageSize}
          setPageSize={(pageSize) => setPageSize(pageSize)}
          noPages={numberOfPages}
          setPage={(page) => setPageSize(page)}
          onSortChanged={(event) =>
            setOrderBy(
              formatAgGridSorting(event.columnApi.columnModel.gridColumns)
            )
          }
          onFilterChanged={(event) =>
            setFilterBy(formatAgGridFilters(event.api.getFilterModel()) || [])
          }
          onGridReady={(params) => {
            setGridApi(params.api);
          }}
          onSelectionChanged={onSelectionChanged}
        />
      )}
    </div>
  );
};

export default ProductOrdersHistory;

function DataFilter({ filterBy, setFilterBy }) {
  return (
    <Stack direction={"row"} padding={2} spacing={2}>
      <DatePickerCustom2
        label={"From"}
        controlledValue={
          filterBy.fulfillment_date__gte
            ? dayjs(filterBy.fulfillment_date__gte)
            : null
        }
        setControlledValue={(v) =>
          setFilterBy({
            ...filterBy,
            fulfillment_date__gte: v ? v.format("YYYY-MM-DD") : null,
          })
        }
      />
      <DatePickerCustom2
        label={"To"}
        controlledValue={
          filterBy.fulfillment_date__lte
            ? dayjs(filterBy.fulfillment_date__lte)
            : null
        }
        setControlledValue={(v) =>
          setFilterBy({
            ...filterBy,
            fulfillment_date__lte: v ? v.format("YYYY-MM-DD") : null,
          })
        }
      />
      <TextField
        label={"Account"}
        value={filterBy.account}
        onChange={(e) => setFilterBy({ ...filterBy, account: e.target.value })}
      />
      <TextField
        label={"Route"}
        value={filterBy.route__name}
        onChange={(e) =>
          setFilterBy({ ...filterBy, route__name: e.target.value })
        }
      />
    </Stack>
  );
}
