import { Button, Stack, Typography } from "@mui/material";
import dayjs from "dayjs";
import { DATE_FORMAT_FRONT } from "../../utils/constants";
import { deletePayout } from "../../distflowAPI/salesmenApi";
import { useState } from "react";
import { downloadFile } from "../../distflowAPI/downloadFile";

const payoutsCols = (extraParams) => [
  {
    headerName: "ID#",
    field: "id",
    flex: 1,
  },
  {
    headerName: "Amount",
    field: "value",
    cellRenderer: (params) => <Typography>${params.data.value}</Typography>,
    flex: 2,
  },
  {
    headerName: "Date",
    field: "created_date",
    cellRenderer: (params) => (
      <Typography>
        {dayjs.utc(params.data.created_date).format(DATE_FORMAT_FRONT)}
      </Typography>
    ),
    flex: 2,
  },
  {
    headerName: "Note",
    field: "note",
    flex: 10,
  },
  {
    headerName: "Actions",
    cellRenderer: (params) => (
      <ActionsRenderer params={params} extraParams={extraParams} />
    ),
    flex: 4.5,
  },
];

export default payoutsCols;

function ActionsRenderer({ params, extraParams }) {
  const [isDeleting, setIsDeleting] = useState(false);
  return (
    <Stack direction={"row"} spacing={2}>
      <Button
        variant={"contained"}
        color={"success"}
        onClick={() => extraParams.setPayoutToEdit(params.data.id)}
      >
        Edit
      </Button>
      <Button
        variant={"contained"}
        color={"error"}
        onClick={() => {
          setIsDeleting(true);
          deletePayout(params.data.sales_man, params.data.id).then((res) => {
            extraParams.refreshTable();
            setIsDeleting(false);
          });
        }}
        disabled={isDeleting}
      >
        Delete
      </Button>
      <Button
        variant={"contained"}
        color={"primary"}
        onClick={() =>
          downloadFile(
            `/api/accounting/payout_export/?payoutId=${params.data.id}`,
          )
        }
      >
        Export
      </Button>
    </Stack>
  );
}
