import { useEffect, useState } from "react";
import {
  createTransaction,
  listTransactions,
} from "../../distflowAPI/invoicesApi";
import PageLoading from "../../components/PageLoading";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Block from "../../components/Block";
import DataTable from "../../components/DataTable";
import { useNavigate, useSearchParams } from "react-router-dom";
import DatePicker2 from "../../components/DatePicker2";
import DatePickerCustom2 from "../../components/DatePicker2";
import ArrowUpwardOutlinedIcon from "@mui/icons-material/ArrowUpwardOutlined";
import ArrowDownwardOutlinedIcon from "@mui/icons-material/ArrowDownwardOutlined";
import { DATE_FORMAT } from "../../utils/constants";
import dayjs from "dayjs";
import { getNoPages, useSearchDebounce } from "../../utils";
import AccountSelect from "../../components/AsyncSelects/AccountSelect";
import CloseIcon from "@mui/icons-material/Close";
import AmountSetter from "../../components/AmountSetter";
import {
  formatAgGridFilters,
  formatAgGridSorting,
} from "../../distflowAPI/common";
import HtmlTooltip from "../../components/HtmlToolTip";
import { Flag } from "@mui/icons-material";

function getReference(data) {
  if (data?.reference) {
    return `#${data.reference}`;
  }
  if (data?.cc_digits) {
    return `#CC: ${data.cc_digits}`;
  }
  return "-";
}

export const transactionsColumns = [
  {
    headerName: "Reference",
    field: "reference",
    valueGetter: (params) => getReference(params.data),
    maxWidth: 150,
    field: "reference",
    sortable: true,
    comparator: () => {},
    cellRenderer: (params) => {
      return (
        <Stack direction={"row"}>
          {params?.data?.matched == 0 && (
            <HtmlTooltip
              title={
                <div>
                  <Typography fontWeight={800} color={"red"}>
                    No matching payment found
                  </Typography>
                </div>
              }
            >
              <IconButton>
                <Flag color="warning" />
              </IconButton>
            </HtmlTooltip>
          )}

          <Typography>{getReference(params.data)}</Typography>
        </Stack>
      );
    },
  },
  {
    headerName: "Account",
    cellRenderer: (params) => (
      <Typography
        onClick={() =>
          (window.location.href = `/customers/dashboard/${
            params.data?.customer?.id || params.data?.account?.customer?.id
          }/transactions`)
        }
      >
        {params.data.account?.name || `*${params.data.customer?.name}`}
      </Typography>
    ),
    maxWidth: 300,
    field: "account__name",
    sortable: true,
    comparator: () => {},
  },
  {
    headerName: "Method",
    field: "method",
    //valueGetter: (params)=>
    valueGetter: (params) =>
      params.data.method == "CHEQUE"
        ? "check"
        : (params.data.method || "")
            .replace("_", " ")
            .toLowerCase()
            .replace("customer ", ""),
    maxWidth: 130,
  },
  {
    headerName: "date",
    valueGetter: (params) =>
      dayjs(params.data.payment_date, "YYYY-MM-DD").format("MMM DD, YYYY"),
    maxWidth: 150,
    field: "payment_date",
    sortable: true,
    comparator: () => {},
  },

  {
    headerName: "Amount",
    field: "amount_paid",
    valueGetter: (params) =>
      `$${params.data.amount_paid} ${params?.data?.fee ? "($" + params?.data?.fee + " fee)" : ""}`,
    maxWidth: 130,
    field: "amount_paid",
    sortable: true,
    comparator: () => {},
  },

  {
    headerName: "Payment",
    cellRenderer: (params) => (
      <Typography
        onClick={() =>
          (window.location.href = `${
            params.data.group
              ? "/invoices/payments/" + params.data.group + "/details"
              : "#"
          }`)
        }
      >
        {params.data.group ? `#${params.data.group}` : "Not applied"}
      </Typography>
    ),
    maxWidth: 150,
    sortable: true,
    field: "group",
    comparator: () => {},
  },
  {
    headerName: "Memo",
    field: "notes",
    cellRenderer: (params) => (
      <Typography
        onClick={() =>
          (window.location.href = `${
            params.data.generated_from
              ? "/invoices/payments/" + params.data.generated_from + "/details"
              : "#"
          }`)
        }
      >
        {params.data.notes || "-"}
      </Typography>
    ),
    sortable: true,
    field: "notes",
    comparator: () => {},
  },
];
const defaultColDef = {
  flex: 1,
  filter: false,
  floatingFilter: false,
};

function Transactions() {
  const navigate = useNavigate();
  const [transactions, setTransactions] = useState([]);
  const [transactionsLoading, setTransactionsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [noPages, setNoPages] = useState(1);
  const [filterBy, setFilterBy] = useState({});
  const [orderBy, setOrderBy] = useState([
    {
      colId: "-id",
    },
  ]);
  const [openCreateCredit, setOpenCreateCredit] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  function refreshTable() {
    listTransactions(
      1,
      50,
      formatAgGridFilters(filterBy),
      [],
      searchParams
    ).then((res) => {
      setTransactions(res.data.results);
      setNoPages(getNoPages(res.data.count, pageSize));
    });
  }
  useEffect(() => {
    refreshTable();
  }, [page, pageSize, filterBy, orderBy, searchParams]);
  function handleSortParams(value) {
    let newSortingValue = "";
    if (value?.length > 0) {
      newSortingValue = value[0]?.field;
      if (value[0]?.sort != "asc") {
        newSortingValue = "-" + newSortingValue;
      }
    }
    setSearchParams((params) => {
      const newParams = new URLSearchParams(params);
      newParams.set("ordering", newSortingValue);
      return newParams;
    });
  }

  return (
    <>
      <PageLoading dataLoading={transactionsLoading} />

      <Typography
        fontSize="23px"
        fontWeight={800}
        component="h2"
        data-testid="page-title"
      >
        Transactions
      </Typography>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        alignItems={{ xs: "space-between", sm: "center" }}
        justifyContent="space-between"
        spacing={1.5}
        my="25px"
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          spacing="15px"
        >
          <Button onClick={() => setOpenCreateCredit(true)} variant="contained">
            Create Credit
          </Button>
        </Stack>
      </Stack>
      <Block>
        <TransactionsFilters />
      </Block>
      <Box className="table-wrapper" marginTop={"10px"}>
        <DataTable
          rowData={transactions}
          columnDefs={transactionsColumns}
          defaultColDef={defaultColDef}
          suppressSorting={true} //disable default sorting
          loading={transactionsLoading}
          rowHeight={53}
          headerHeight={72}
          onSortChanged={(event) => {
            handleSortParams(
              formatAgGridSorting(event.columnApi.columnModel.gridColumns)
            );
          }}
          onFilterChanged={(event) => {
            setFilterBy(event.api.getFilterModel());
          }}
          page={page}
          pageSize={pageSize}
          setPageSize={(pageSize) => setPageSize(pageSize)}
          noPages={noPages}
          setPage={(page) => setPage(page)}
        />
      </Box>
      <CreateCredit
        open={openCreateCredit}
        setOpen={setOpenCreateCredit}
        refreshTable={refreshTable}
      />
    </>
  );
}

export default Transactions;

const TransactionsFilters = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [filterDB, setFilterDB] = useSearchDebounce();

  const [filters, setFilters] = useState({
    account__name: searchParams.get("account__name") || "",
    amount_paid__gte: searchParams.get("amount_paid__gte") || "",
    amount_paid__lte: searchParams.get("amount_paid__lte") || "",
    payment_date__gte: searchParams.get("payment_date__gte") || "",
    payment_date__lte: searchParams.get("payment_date__lte") || "",
    method: searchParams.get("method") || "",
    reference: searchParams.get("reference") || "",
    customer__name: searchParams.get("customer__name") || "",
    notes: searchParams.get("notes") || "",
    matched: searchParams.get("matched") || "",
  });

  const handleChange = (value) => {
    setSearchParams((params) => {
      const newParams = new URLSearchParams(params);
      Object.keys(value).forEach((key) => {
        if (!!value[key]) {
          newParams.set(key, value[key]);
        } else {
          newParams.delete(key);
        }
      });
      return newParams;
    });
  };

  useEffect(() => {
    setFilterDB(filters);
  }, [filters]);

  useEffect(() => {
    handleChange(filterDB);
  }, [filterDB]);

  function handleToday() {
    setFilters({
      ...filters,
      payment_date__lte: dayjs().utc().format(DATE_FORMAT),
      payment_date__gte: dayjs().utc().format(DATE_FORMAT),
    });
  }
  function handleThisWeek() {
    setFilters({
      ...filters,
      payment_date__lte: dayjs().utc().format(DATE_FORMAT),
      payment_date__gte: dayjs().weekday(0).format(DATE_FORMAT),
    });
  }
  function handleMTD() {
    setFilters({
      ...filters,
      payment_date__lte: dayjs().utc().format(DATE_FORMAT),
      payment_date__gte: dayjs().startOf("month").format(DATE_FORMAT),
    });
  }
  function handleAll() {
    setFilters({
      ...filters,
      payment_date__lte: null,
      payment_date__gte: null,
    });
  }

  return (
    <Grid container spacing={1} rowGap="10px">
      <Grid md={12}>
        <Grid container spacing={1}>
          <Grid item md={2} marginLeft={1}>
            <Typography mb="5px">Created after</Typography>
            <DatePicker2
              controlledValue={
                filters.payment_date__gte
                  ? dayjs.utc(filters.payment_date__gte)
                  : null
              }
              setControlledValue={(newValue) => {
                setFilters({
                  ...filters,
                  payment_date__gte: newValue
                    ? dayjs(newValue).format(DATE_FORMAT)
                    : "",
                });
              }}
              additionlStyles={{
                height: "40px",
                width: "100%",
                "& .MuiInputBase-root.Mui-error .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor: "#DBDFEA",
                  },
              }}
            />
          </Grid>
          <Grid item md={2}>
            <Typography mb="5px">Created Before</Typography>
            <DatePicker2
              controlledValue={
                filters.payment_date__lte
                  ? dayjs.utc(filters.payment_date__lte)
                  : null
              }
              setControlledValue={(newValue) => {
                setFilters({
                  ...filters,
                  payment_date__lte: newValue
                    ? dayjs(newValue).format(DATE_FORMAT)
                    : "",
                });
              }}
              additionlStyles={{
                height: "40px",
                width: "100%",
                "& .MuiInputBase-root.Mui-error .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor: "#DBDFEA",
                  },
              }}
            />
          </Grid>
          <Grid item md={4} marginLeft={0}>
            <Stack direction={"row"} spacing={1} paddingTop={3.5}>
              <Button variant="contained" onClick={handleToday}>
                Today
              </Button>
              <Button variant="contained" onClick={handleThisWeek}>
                This week
              </Button>
              <Button variant="contained" onClick={handleMTD}>
                Month to date
              </Button>
              <Button variant="contained" onClick={handleAll}>
                All
              </Button>
            </Stack>
          </Grid>
          <Grid item md={3} marginLeft={0}>
            <Stack direction={"row"} spacing={1} paddingTop={3.5}>
              <Button
                variant="contained"
                onClick={() => setFilters({ ...filters, matched: "" })}
                color={filters.matched === "" ? "success" : "primary"}
              >
                ALL
              </Button>
              <Button
                variant="contained"
                onClick={() => setFilters({ ...filters, matched: true })}
                color={filters.matched === true ? "success" : "primary"}
              >
                MATCHED
              </Button>
              <Button
                variant="contained"
                onClick={() => setFilters({ ...filters, matched: "false" })}
                color={filters.matched === "false" ? "success" : "primary"}
              >
                NON_MATCHED
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={2}>
        <Typography mb="5px">Method</Typography>
        <Stack direction={"row"} gap={1}>
          <Select
            value={`${filters?.method || " "}`}
            onChange={(e) => {
              setFilters({ ...filters, method: e.target.value });
            }}
            fullWidth
            sx={{ maxHeight: 40 }}
          >
            <MenuItem value=" ">All</MenuItem>
            <MenuItem value="CREDIT_CARD">Credit Card</MenuItem>
            <MenuItem value="QUICK_PAY">QUICK PAY</MenuItem>
            <MenuItem value="CASH">CASH</MenuItem>
            <MenuItem value="ACH">ACH</MenuItem>
            <MenuItem value="CUSTOMER_CREDIT">CUSTOMER CREDIT</MenuItem>
            <MenuItem value="CHEQUE">CHEQUE</MenuItem>
            <MenuItem value="WIRE_TRANSFER">WIRE TRANSFER</MenuItem>
          </Select>
        </Stack>
      </Grid>
      <Grid item md={2}>
        <Typography mb="5px">Reference</Typography>
        <Stack direction={"row"} gap={1}>
          <OutlinedInput
            size="small"
            sx={{ height: "40px" }}
            fullWidth
            value={filters.reference}
            type="text"
            onChange={(e) =>
              setFilters({ ...filters, reference: e.target.value })
            }
          />
        </Stack>
      </Grid>

      <Grid item md={2}>
        <Typography mb="5px">Account</Typography>
        <OutlinedInput
          size="small"
          sx={{ height: "40px" }}
          fullWidth
          value={filters.account__name}
          onChange={(e) =>
            setFilters({ ...filters, account__name: e.target.value })
          }
        />
      </Grid>
      <Grid item md={2}>
        <Typography mb="5px">Customer</Typography>
        <OutlinedInput
          size="small"
          sx={{ height: "40px" }}
          fullWidth
          value={filters.customer__name}
          onChange={(e) =>
            setFilters({ ...filters, customer__name: e.target.value })
          }
        />
      </Grid>
      <Grid item md={1}>
        <Typography mb="5px">Total GTE</Typography>
        <Stack direction={"row"} gap={1}>
          <OutlinedInput
            size="small"
            sx={{ height: "40px" }}
            fullWidth
            value={filters.amount_paid__gte}
            type="number"
            onChange={(e) =>
              setFilters({ ...filters, amount_paid__gte: e.target.value })
            }
          />
        </Stack>
      </Grid>
      <Grid item md={1}>
        <Typography mb="5px">Total LTE</Typography>
        <Stack direction={"row"} gap={1}>
          <OutlinedInput
            size="small"
            sx={{ height: "40px" }}
            fullWidth
            value={filters.amount_paid__lte}
            type="number"
            onChange={(e) =>
              setFilters({ ...filters, amount_paid__lte: e.target.value })
            }
          />
        </Stack>
      </Grid>
      <Grid item md={2}>
        <Typography mb="5px">Memo</Typography>
        <Stack direction={"row"} gap={1}>
          <OutlinedInput
            size="small"
            sx={{ height: "40px" }}
            fullWidth
            value={filters.notes}
            type="text"
            onChange={(e) => setFilters({ ...filters, notes: e.target.value })}
          />
        </Stack>
      </Grid>
    </Grid>
  );
};

export function CreateCredit({ open, setOpen, customerId, refreshTable }) {
  const [account, setAccount] = useState();
  const [note, setNote] = useState("");
  const [amount, setAmount] = useState(0);
  const [date, setDate] = useState(dayjs());
  const [errors, setErrors] = useState({});
  const handleClick = () => {
    setErrors({});
    const newErrors = {};
    if (!date) {
      setErrors({ date: "please select a date" });
      return;
    }
    if (date && !date.isValid()) {
      setErrors({ date: "set a valid date" });
      return;
    }
    if (!account || !account?.value) {
      setErrors({ account: "please choose an account first" });
      return;
    }
    if (amount <= 0) {
      setErrors({ amount: "amount should be greater than 0" });
      return;
    }
    createTransaction({
      account: account.value,
      amount_paid: parseFloat(amount).toFixed(2),
      notes: note,
      payment_date: date.format("YYYY-MM-DD"),
      method: "CUSTOMER_CREDIT",
    }).then(
      (res) => {
        setOpen(false);
        refreshTable();
      },
      (err) => {}
    );
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      maxWidth="xl"
      sx={{
        "& .MuiPaper-root": {
          width: "100%",
          maxWidth: "800px",
        },
      }}
    >
      <DialogTitle>
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
          }}
        >
          <CloseIcon
            sx={{ cursor: "pointer" }}
            onClick={() => {
              setOpen(false);
            }}
          />
        </div>
        <Typography
          variant="pageTitle"
          noWrap
          textAlign="center"
          component="p"
          mb="20px"
        >
          Create a Credit
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={"20px"} sx={{ paddingTop: "20px" }}>
          {Object.keys(errors).map((ek) => (
            <Grid item md={12}>
              <Alert severity="error">
                {ek}:{errors[ek]}
              </Alert>
            </Grid>
          ))}
          <Grid item md={6}>
            <AccountSelect
              name="account"
              value={account}
              fullWidth
              label="Account"
              customerId={customerId}
              onChange={(event, newValue) => {
                setAccount(null);
                if (newValue) {
                  if (newValue.value) {
                    setAccount(newValue);
                  }
                }
              }}
            />
          </Grid>
          <Grid item md={3}>
            <DatePickerCustom2
              controlledValue={date}
              setControlledValue={setDate}
              label={"date"}
            />
          </Grid>
          <Grid item md={3}>
            <AmountSetter value={amount} setValue={setAmount} />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Note"
              onChange={(e) => {
                setNote(e.target.value);
              }}
              value={note}
              required
              fullWidth
              multiline
            ></TextField>
          </Grid>
        </Grid>
      </DialogContent>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "10px",
          marginBottom: "20px",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleClick()}
        >
          Confirm
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={() => setOpen(false)}
        >
          Cancel
        </Button>
      </Box>
    </Dialog>
  );
}
