import React, { memo } from "react";
import { useDraggable } from "@dnd-kit/core";
import { Box, Button, Stack, Typography } from "@mui/material";
import Dots from "../../images/dots.svg";

const DraggableDriver = memo((props) => {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: props.id,
    data: {
      supports: ["driver"],
    },
  });
  const style = transform
    ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
        zIndex: 999,
      }
    : undefined;

  return (
    <Stack
      ref={setNodeRef}
      direction="row"
      alignItems="center"
      gap="8px"
      sx={{
        touchAction: "none",
        backgroundColor: "#F1F1F1",
        border: "1px solid #DBDFEA",
        borderRadius: "5px",
        padding: "7px 11px",
      }}
      style={style}
    >
      <Button
        sx={{
          minWidth: "8px",
          padding: "4px 3px 4px 4px",
          display: "flex",
          cursor: "grab",
          flexShrink: 0,
          "&:hover": {
            backgroundColor: "#dcdcdc",
          },
        }}
        {...listeners}
        {...attributes}
      >
        <img src={Dots} alt="" />
      </Button>
      <Box width="100%">
        <Typography sx={{ color: "#252525" }}>{props.title}</Typography>
        {props.subtitle ? (
          <Typography fontSize="11px" sx={{ color: "#535151" }}>
            {props.subtitle}
          </Typography>
        ) : null}
      </Box>
    </Stack>
  );
});

export default DraggableDriver;
