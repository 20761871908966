import React, { useEffect, useMemo, useState } from "react";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import OrderTitleModal from "./OrderTitleModal";
import CloseIcon from "@mui/icons-material/Close";
import OrderItemWrapper from "./OrderItemWrapper";
import OrderRouteModalItem from "./OrderRouteModalItem";
import RouteSelect from "../AsyncSelects/RouteSelect";
import { DATE_FORMAT } from "../../utils/constants";
import { updateOrder } from "../../distflowAPI/ordersApi";
import dayjs from "dayjs";
import { fireErrorNotification } from "../../utils";

const OrderRouteModal = ({
  state,
  account,
  orderId,
  date,
  accountId,
  ...restProps
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xl"));

  const [isSaving, setIsSaving] = useState(false);
  const [errors, setErrors] = useState([]);
  const [items, setItems] = useState([...restProps.items]);
  const [allItemsRoute, setAllItemsRoute] = useState({
    value: null,
    label: "",
  });

  const saveChanges = async () => {
    setIsSaving(true);

    const payload = {
      items: [],
      account_id: accountId || account.value,
      date: dayjs.utc(date).format(DATE_FORMAT),
      fulfillment_date: dayjs.utc(date).format(DATE_FORMAT),
    };
    items.forEach((item) => {
      payload["items"].push({
        variant: item.item,
        unit: item.unit || item.item_details.sold_by,
        quantity: item.qty,
        status: item.status === "AUTO_APPROVE" ? "APPROVED" : item.status,
        invoice_note: item.invoice_note,
        route: item.route?.id,
        manual_route: item.manual_route,
      });
    });

    await updateOrder(payload)
      .then(
        (res) => {
          restProps.refreshTable(true);
          restProps.onClose();
        },
        (error) => {
          const errorFields = Object.keys(error.response.data);
          errorFields.forEach((field) => {
            fireErrorNotification(error.response.data[field][0]);
          });
        }
      )
      .finally(() => setIsSaving(false));
  };

  const handleItemChange = (itemId, newValue) => {
    setItems((prev) => {
      return prev.map((el) => {
        if (el.item === itemId) {
          return { ...el, ...newValue };
        } else {
          return el;
        }
      });
    });
  };

  const handleChangeAllItemsRoute = (newValue) => {
    setItems((prev) =>
      prev.map((el) => ({
        ...el,
        route: {
          id: newValue ? newValue.value : null,
          name: newValue ? newValue.label : "",
        },
        manual_route: true,
      }))
    );
  };

  useEffect(() => {
    if (items.length) {
      const itemsRoutesId = items.map((el) => el.route?.id || "");
      const isAllRoutesSame = itemsRoutesId.every(
        (element) => element === itemsRoutesId[0]
      );
      if (isAllRoutesSame) {
        setAllItemsRoute({
          value: items[0].route?.id || null,
          label: items[0].route?.name || "",
        });
      }
    }
  }, [items]);

  const pendingItems = useMemo(() => {
    return items.filter((oi) => oi.status === "PENDING");
  }, [items]);
  const approvedItems = useMemo(() => {
    return items.filter((oi) => oi.status === "APPROVED");
  }, [items]);
  const autoApprovedItems = useMemo(() => {
    return items.filter((oi) => oi.status === "AUTO_APPROVE");
  }, [items]);
  const deliveredItems = useMemo(() => {
    return items.filter((oi) => oi.status === "DELIVERED");
  }, [items]);
  const pickedUpItems = useMemo(() => {
    return items.filter((oi) => oi.status === "PICKED_UP");
  }, [items]);
  const canceledItems = useMemo(() => {
    return items.filter((oi) => oi.status === "CANCELED");
  }, [items]);

  return (
    <Dialog
      {...restProps}
      fullScreen={fullScreen}
      maxWidth="xl"
      sx={{
        "& .MuiPaper-root": {
          width: "100%",
          maxWidth: "750px",
        },
        zIndex: 1500,
      }}
    >
      {isSaving && (
        <Alert
          sx={{
            position: "sticky",
            top: "0",
            zIndex: 5000,
          }}
        >
          Saving...
        </Alert>
      )}
      <Box sx={{ padding: "18px 18px 22px 18px" }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb="22px"
        >
          <OrderTitleModal
            state={state}
            account={account}
            orderId={orderId}
            date={date}
          />
          <IconButton
            onClick={() => restProps.onClose()}
            sx={{
              color: "#515151",
              marginRight: "-5px",
            }}
          >
            <CloseIcon />
          </IconButton>
        </Stack>

        <Stack direction="column" gap={1}>
          <Typography>Select route for all items:</Typography>
          <RouteSelect
            name="route"
            placeholder="Select route"
            value={allItemsRoute}
            onChange={(_e, newValue) => {
              handleChangeAllItemsRoute(newValue);
            }}
          />
        </Stack>
        <Stack diregtion="column" gap={2}>
          {pendingItems.length > 0 && (
            <OrderItemWrapper status="pending" count={pendingItems.length}>
              {pendingItems.map((item, i) => (
                <OrderRouteModalItem
                  key={i}
                  item={item}
                  handleItemChange={handleItemChange}
                />
              ))}
            </OrderItemWrapper>
          )}
          {autoApprovedItems.length > 0 && (
            <OrderItemWrapper status="auto" count={autoApprovedItems.length}>
              {autoApprovedItems.map((item, i) => (
                <OrderRouteModalItem
                  key={i}
                  item={item}
                  handleItemChange={handleItemChange}
                />
              ))}
            </OrderItemWrapper>
          )}
          {approvedItems.length > 0 && (
            <OrderItemWrapper status="approved" count={approvedItems.length}>
              {approvedItems.map((item, i) => (
                <OrderRouteModalItem
                  key={i}
                  item={item}
                  handleItemChange={handleItemChange}
                />
              ))}
            </OrderItemWrapper>
          )}
          {deliveredItems.length > 0 && (
            <OrderItemWrapper status="delivered" count={deliveredItems.length}>
              {deliveredItems.map((item, i) => (
                <OrderRouteModalItem
                  key={i}
                  item={item}
                  handleItemChange={handleItemChange}
                />
              ))}
            </OrderItemWrapper>
          )}
          {pickedUpItems.length > 0 && (
            <OrderItemWrapper status="picked" count={pickedUpItems.length}>
              {pickedUpItems.map((item, i) => (
                <OrderRouteModalItem
                  key={i}
                  item={item}
                  handleItemChange={handleItemChange}
                />
              ))}
            </OrderItemWrapper>
          )}
          {canceledItems.length > 0 && (
            <OrderItemWrapper status="cancelled" count={canceledItems.length}>
              {canceledItems.map((item, i) => (
                <OrderRouteModalItem
                  key={i}
                  item={item}
                  handleItemChange={handleItemChange}
                />
              ))}
            </OrderItemWrapper>
          )}
        </Stack>

        <DialogActions sx={{ marginTop: "16px" }}>
          <Button
            onClick={() => restProps.onClose()}
            variant="outlined"
            size="small"
          >
            Cancel
          </Button>
          {state !== "PICKED_UP" && state !== "DELIVERED" && (
            <Button
              disabled={isSaving}
              onClick={saveChanges}
              color="primary"
              variant="contained"
              data-testid="saveButton"
              size="small"
            >
              Save
            </Button>
          )}
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default OrderRouteModal;
