import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import {
  createVendorApi,
  deleteVendor,
  deleteVendorOverride,
  getVendor,
  getVendorOverrides,
  getVendors,
  patchVendor,
  patchVendorOverride,
  postVendorOverride
} from "../../distflowAPI/vendorsApi";
import { signOut } from "./user";
import { arrayToDictionary, fireErrorNotification, fireSuccessNotification } from "../../utils";

/**
 * @typedef {Object} Vendor
 * @property {number} Vendor.id
 * @property {string} Vendor.name
 * @property {string} Vendor.address
 * @property {number} Vendor.products_count
 * @property {string} Vendor.internal_id
 */

/**
 * @type {{
 *  vendor: Vendor;
 *  vendors: Array<Vendor>;
 *  vendorMap: {};
 *  vendorsLoading: boolean;
 *  pageSize: number;
 *  page: number;
 *  orderBy: [];
 *  vendorOverrides: [];
 *  filterBy: {};
 *  totalCount: number;
 *  createLoading: boolean;
 * }}
 */
const initialState = {
  vendor: {
    name: "",
    address: "",
    internal_id: "",
    products_count: 0
  },
  vendorOverrides: [],
  vendors: [],
  vendorMap: {},
  vendorsLoading: false,
  vendorOverridesLoading: false,
  pageSize: 25,
  page: 1,
  orderBy: [],
  filterBy: [],
  totalCount: 0,
  createLoading: false
};

export const fetchVendors = createAsyncThunk(
  "vendor/fetchVendors",
  async (data, thunkAPI) => {
    let { page, pageSize, filterBy, orderBy } = data;
    
    const res = await getVendors(page, pageSize, filterBy, orderBy);
    return res.data;
  }
);

export const fetchVendor = createAsyncThunk(
  "vendor/fetchVendor",
  async (vendorId, thunkAPI) => {
    const res = await getVendor(vendorId);
    return res.data;
  }
);

export const fetchVendorOverrides = createAsyncThunk(
  "vendor/fetchVendorOverrides",
  async (vendorId, thunkAPI) => {
    const res = await getVendorOverrides(vendorId);
    return res.data.results;
  }
);

export const createVendor = createAsyncThunk(
  "vendor/createVendor",
  async (vendor, {rejectWithValue}) => {
    try {
      const res = await createVendorApi(vendor);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateVendor = createAsyncThunk("vendor/updateVendor", async (data, {rejectWithValue}) => {
  const { vendorId, vendorInfo } = data;

  try {
    const res = await patchVendor(vendorId, vendorInfo);
    return res.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const removeVendor = createAsyncThunk("vendor/removeVendor", async (vendorId, {rejectWithValue}) => {
  try {
    const res = await deleteVendor(vendorId);
    return vendorId;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const createVendorOverride = createAsyncThunk(
  "vendor/createVendorOverride",
  async (data, {rejectWithValue}) => {
    const { id, ...payload } = data;

    try {
      const res = await postVendorOverride(id, payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const updateVendorOverride = createAsyncThunk(
  "vendor/updateVendorOverride",
  async (data, {rejectWithValue}) => {
    const { id, overrideId, payload } = data;
    
    try {
      const res = await patchVendorOverride(id, overrideId, payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const removeVendorOverride = createAsyncThunk(
  "vendor/removeVendorOverride",
  async (data) => {
    const { id, overrideId } = data;
    const res = await deleteVendorOverride(id, overrideId);
    return { overrideId };
  }
);

export const vendorSlice = createSlice({
  name: "vendor",
  initialState,
  reducers: {
    setVendorsTableSettings(state, { payload }) {
      state[payload.field] = payload.value;
    },
    cleanTableSettings(state) {
      state.pageSize = 25
      state.page = 1
      state.orderBy = []
      state.filterBy = []
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchVendors.pending, (state) => {
        state.vendorsLoading = true;
      })
      .addCase(fetchVendors.fulfilled, (state, action) => {
        state.vendorsLoading = false;
        state.vendors = action.payload.results;
        state.vendorMap = arrayToDictionary(action.payload.results, "id");
        state.totalCount = action.payload.count;
      })
      .addCase(fetchVendors.rejected, (state) => {
        state.vendorsLoading = false;
      })
      
      .addCase(fetchVendor.fulfilled, (state, action) => {
        state.vendor = action.payload;
      })
      
      .addCase(fetchVendorOverrides.pending, (state, action) => {
        state.vendorOverridesLoading = true;

      })
      .addCase(fetchVendorOverrides.fulfilled, (state, action) => {
        state.vendorOverrides = action.payload;
        state.vendorOverridesLoading = false;       
      })
      
      .addCase(updateVendor.fulfilled, (state, action) => {
        state.vendor = action.payload;
        fireSuccessNotification(`Vendor "${action.meta.arg.vendorInfo.name}" updated successfully`);
      })
      .addCase(updateVendor.rejected, (state, action) => {
        const errorFields = Object.keys(action.payload)
        errorFields.forEach(field => {
          fireErrorNotification(`${field}: ${action.payload[field][0]}`)
        })
      })
      .addCase(removeVendor.fulfilled, (state, action) => {
        fireSuccessNotification(`Vendor deleted successfully`)
        state.vendor = { };
        state.vendors = state.vendors.filter(el => el.id !== action.payload)
      })
      .addCase(removeVendor.rejected, (state, action) => {
        const protected_elements = action.payload.protected_elements;
        fireErrorNotification(`Protected by: 
          ${protected_elements.map(el => ` id: ${el.id} label: ${el.label}`)}`);
      })
      .addCase(createVendor.pending, (state) => {
        state.createLoading = true;
      })
      .addCase(createVendor.fulfilled, (state, action) => {
        state.createLoading = false;
        fireSuccessNotification(`Vendor "${action.meta.arg.name}" created successfully`);
        state.totalCount = state.totalCount + 1;
        if (state.vendors.length < state.pageSize) {
          state.vendors = [...state.vendors, action.payload];
        }
      })
      .addCase(createVendor.rejected, (state, action) => {
        state.createLoading = false;
        const errorFields = Object.keys(action.payload)
        errorFields.forEach(field => {
          fireErrorNotification(`${field}: ${action.payload[field][0]}`)
        })
      })

      .addCase(createVendorOverride.fulfilled, (state, action) => {
        fireSuccessNotification(`Vendor override created successfully`);
        state.vendorOverrides.push(action.payload);
      })
      .addCase(createVendorOverride.rejected, (state, action) => {
        const errorFields = Object.keys(action.payload)
        errorFields.forEach(field => {
          fireErrorNotification(`${field}: ${action.payload[field][0]}`)
        })
      })
      
      .addCase(updateVendorOverride.fulfilled, (state, action) => {
        fireSuccessNotification(`Override updated successfully`);
        state.vendorOverrides = state.vendorOverrides.map(el => {
          if (el.id === action.payload.id) {
            return action.payload;
          } else {
            return el;
          }
        });
      })
      .addCase(updateVendorOverride.rejected, (state, action) => {
        const errorFields = Object.keys(action.payload)
        errorFields.forEach(field => {
          fireErrorNotification(`${field}: ${action.payload[field][0]}`)
        })
      })
      
      .addCase(removeVendorOverride.fulfilled, (state, action) => {
        fireSuccessNotification(`Override deleted successfully`);
        state.vendorOverrides = state.vendorOverrides.filter(el => el.id !== action.payload.overrideId);
      })
      
      .addCase(signOut, () => initialState);
  }
});

export const { setVendorsTableSettings, cleanTableSettings } = vendorSlice.actions;

export const selectVendorNumberOfPages = createSelector(
  (state) => state.vendor.totalCount,
  (state) => state.vendor.pageSize,
  (totalCount, pageSize) => Math.ceil(totalCount / pageSize)
);

export default vendorSlice.reducer;
