import { useState, useEffect } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import TerritorySelect from "../../components/AsyncSelects/TerritorySelect";
import useAppDispatch from "../../hooks/useAppDispatch";
import { createTerritory } from "../../redux/module/territory";

const initialValue = {
  name: "",
  parent: { 
    value: "",
    label: "",
  }
}

const AddTerritoryFrom = ({ open, handleClose }) => {
  const dispatch = useAppDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [territoryInfo, setTerritoryInfo] = useState(initialValue);

  async function handleSubmit(event) {
    event.preventDefault();
    setIsSubmitting(true);

    const data = await dispatch(createTerritory({ 
      name: territoryInfo.name,
      parent: territoryInfo.parent.value
    }));
    
    if (data.payload.id) {
      handleClose();
    }
    setIsSubmitting(false);
  }

  useEffect(() => {
    setTerritoryInfo(initialValue)
  }, [open]);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle sx={{ pb: 1 }}>Create a new Territory</DialogTitle>
      <form id="TerritoryCreation" onSubmit={handleSubmit}>
        <DialogContent sx={{ pt: 2, pb: 1 }}>
            <TextField
              required
              name="name"
              data-testid="test-name-id"
              label="Territory name"
              type="text"
              id="name"
              fullWidth
              value={territoryInfo.name}
              autoComplete="off"
              onChange={(e) => setTerritoryInfo({ ...territoryInfo, name: e.target.value })}

            />
            <TerritorySelect
              label="Parent territory"
              name="parent"
              fullWidth
              onChange={(_e, newValue) => setTerritoryInfo({ ...territoryInfo, parent: newValue })}
            />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={isSubmitting}
          >
            Create Territory
          </Button>
          <Button onClick={handleClose} variant="contained" color="error">
            Cancel
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default AddTerritoryFrom;
