import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
  Checkbox,
  Grid,
  FormControlLabel,
  Alert,
} from "@mui/material";

import {
  emailVendorOrders,
  listVendors,
} from "../../../distflowAPI/vendorsApi";
import { useEffect, useState } from "react";
import { downloadFile } from "../../../distflowAPI/downloadFile";
import { notificationsCount } from "../../../distflowAPI/notifications";

function EmailVendorModal({ open, setOpen, date }) {
  const [vendors, setVendors] = useState([]);
  const [vendorsLoading, setVendorsLoading] = useState(true);
  const [selectedVendors, setSelectedVendors] = useState([]);
  const [canEmail, setCanEmail] = useState(false);
  const [isDownloadingVendorExport, setIsDownloadingVendorExport] =
    useState(false);
  const [nCount, setNcount] = useState(0);
  useEffect(() => {
    setVendorsLoading(true);
    listVendorsDay().finally(() => setVendorsLoading(false));
    notificationsCount().then((res) => setNcount(res.data.count));
  }, [date]);
  useEffect(() => {
    if (selectedVendors.length > 0) {
      setCanEmail(true);
    } else {
      setCanEmail(false);
    }
  }, [selectedVendors]);
  async function listVendorsDay() {
    await emailVendorOrders(date, [], "list").then((res) =>
      setVendors(res.data)
    );
  }
  const handleEmail = () => {
    emailVendorOrders(date, selectedVendors, "send").then((res) =>
      alert("res")
    );
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      fullWidth={true}
      maxWidth={"sm"}
    >
      <DialogTitle>
        <Typography sx={{ fontWeight: "bold", fontSize: 20 }}>
          Email Vendor exports
        </Typography>
      </DialogTitle>
      <DialogContent
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "0 20px 20px 20px",
        }}
      >
        {nCount > 0 && (
          <Alert color="error">
            There are {nCount} unacknwoledged notification!
          </Alert>
        )}
        {vendorsLoading ? (
          <Stack alignItems={"center"}>
            <Typography sx={{ position: "fixed", marginTop: 2 }}>
              Loading...
            </Typography>
          </Stack>
        ) : (
          <Stack spacing={1} my={2} mx={1}>
            <Grid
              container
              rowGap={1}
              columnGap={20}
              display="flex"
              alignItems="center"
            >
              {vendors.map((vendor) => (
                <Grid item xs={4} key={vendor.id} display="flex">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedVendors.includes(vendor.id)}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setSelectedVendors([...selectedVendors, vendor.id]);
                          } else {
                            setSelectedVendors(
                              selectedVendors.filter((id) => id !== vendor.id)
                            );
                          }
                        }}
                      />
                    }
                    label={vendor.name}
                  />
                </Grid>
              ))}
            </Grid>
          </Stack>
        )}
      </DialogContent>
      <DialogActions>
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={1}
          m={2}
        >
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setOpen(false)}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleEmail()}
            disabled={!canEmail}
          >
            Email
          </Button>
          <Button
            className="btn-height"
            variant="outlined"
            onClick={() => {
              setIsDownloadingVendorExport(true);
              downloadFile(
                `/api/orders/export_orders/?date=${date}`,
                `orders_by_vendor_${date}.zip`
              ).then(
                (res) => setIsDownloadingVendorExport(false),
                (err) => setIsDownloadingVendorExport(false)
              );
            }}
            disabled={isDownloadingVendorExport}
          >
            Export Vendor Orders
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}

export default EmailVendorModal;
