import { Typography, Grid } from "@mui/material";
import IssueListItem from "./IssueListItem";
import PickListItem from "./PickListItem";

const IssueList = ({ issues }) => {
  const variants = Array.from(new Set(issues.map(x => x.variantId)));
  const getIssuesByVariantId = (variant) => issues.filter(x => x.variantId == variant).filter(x => x.quantity != x.pickedAmount);

  if (issues && issues.length == 0) {
    return <Typography>No issues to show</Typography>
  }

  return (
    <Grid container columnSpacing={{ xs: 1 }}>
      {variants && variants.map((variant, index) => <Grid key={index} item width="50%"><PickListItem renderAsIssues={true} items={getIssuesByVariantId(variant)} /></Grid>)}
    </Grid>
  );
}

export default IssueList;