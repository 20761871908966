import api from "./api";
import { orderingToApi, filtersToApi } from "./common";
export async function listManagers(
  page = 1,
  pageSize = 100,
  filter = [],
  order = []
) {
  const orderingParam = order.length ? `&ordering=${orderingToApi(order)}` : "";
  const filterParam = filter.length ? `&${filtersToApi(filter)}` : "";
  const params = `page_size=${pageSize}&page=${page}${orderingParam}${filterParam}`;
  const res = await api.get(`/api/managers/?${params}`);
  return res;
}

export async function createManager(payload) {
  const res = await api.post(`/api/managers/`, payload);
  return res;
}
export async function getManager(id) {
  const res = await api.get(`/api/managers/${id}/`);
  return res;
}

export async function updateManager(id, payload) {
  const res = await api.patch(`/api/managers/${id}/`, payload);
  return res;
}




export async function deleteManager(id) {
  const res = await api.delete(`/api/managers/${id}/`);
  return res;
}
