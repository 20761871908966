import {
  Button,
  Grid,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  IconButton,
  TextField,
} from "@mui/material";
import Block from "../../components/Block";
import DataTable from "../../components/DataTable";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import {
  postAccountNote,
  getNoteAccount,
  deleteNoteAccount,
  updateNoteAccount,
} from "../../distflowAPI/accountsApi";
import { useEffect, useState } from "react";
import { parseISO, format } from "date-fns";
import { useParams } from "react-router-dom";
import ConfirmDialog from "../../components/ConfirmDialog";
export default function AccountNotes() {
  const { id } = useParams();
  const columns = [
    { headerName: "Notes", field: "note", minWidth: 400, sortable: true },
    {
      headerName: "Creation Date",
      field: "created_at",
      sortable: true,
      valueGetter: (params) => {
        return format(parseISO(params.data.created_at), "dd MMMM yyyy");
      },
    },
    {
      headerName: "Update Date",
      field: "updated_at",
      sortable: true,
      valueGetter: (params) => {
        return format(parseISO(params.data.updated_at), "dd MMMM yyyy");
      },
    },
    { headerName: "Actions", cellRenderer: buttonsRenderer, maxWidth: 100 },
  ];

  const defaultColDef = {
    flex: 1,
    filter: false,
    floatingFilter: false,
  };
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [rowData, setRowData] = useState([]);
  function fetchNotes() {
    getNoteAccount(id).then((res) => {
      setRowData(res.data.results);
    });
  }

  useEffect(() => {
    fetchNotes();
  }, []);
  function buttonsRenderer(params) {
    const rowData = params.data;
    const handleEditClick = () => {
      fetchNotes();
    };

    const handleDeleteClick = () => {
      setConfirmModalOpen(true);
    };

    function EditNoteModal() {
      const [open, setOpen] = useState(false);
      const [note, setNote] = useState(rowData.note);
      const id = rowData.id;
      const account = rowData.account;
      function handleClick() {
        updateNoteAccount(id, note, account)
          .then((res) => {
            fetchNotes();
          })
          .catch((error) => {
            console.error(error);
            setOpen(false);
          });
      }

      return (
        <>
          <IconButton onClick={() => setOpen(true)}>
            <EditOutlinedIcon />
          </IconButton>
          <Dialog
            open={open}
            onClose={() => setOpen(false)}
            style={{
              zIndex: "2000!impotant",
            }}
          >
            <DialogTitle>Edit Note</DialogTitle>
            <DialogContent>
              <TextField
                fullWidth
                multiline
                placeholder="Type the note..."
                variant="outlined"
                sx={{ width: "500px" }}
                value={note}
                onChange={(e) => setNote(e.target.value)}
              />
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                onClick={() => {
                  setOpen(false);
                  handleClick();
                  fetchNotes();
                }}
                color="success"
              >
                Confirm
              </Button>
              <Button variant="contained" onClick={() => setOpen(false)}>
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        </>
      );
    }

    return (
      <div>
        <ConfirmDialog
          title="Note Delete"
          children="Do you want to delete this Note ?"
          open={confirmModalOpen}
          setOpen={setConfirmModalOpen}
          onConfirm={() => {
            deleteNoteAccount(rowData.id).then((res) => {
              fetchNotes();
            });
          }}
        />
        <EditNoteModal />
        <IconButton
          onClick={() => {
            handleDeleteClick();
          }}
        >
          <DeleteForeverOutlinedIcon />
        </IconButton>
      </div>
    );
  }

  function AddNoteModal(props) {
    const [open, setOpen] = useState(false);
    const [note, setNote] = useState("");
    const { id } = useParams();

    function handleClick() {
      postAccountNote(id, note)
        .then((res) => {
          fetchNotes();
        })
        .catch((error) => {
          console.error(error);
          setOpen(false);
        });
    }

    return (
      <>
        <Button
          type="submit"
          variant="contained"
          sx={{ pt: 1, pb: 1, mb: 1 }}
          onClick={() => setOpen(true)}
        >
          Create a Note
        </Button>

        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          style={{
            zIndex: "2000!impotant",
          }}
        >
          <DialogTitle>Add a New Note</DialogTitle>
          <DialogContent>
            <TextField
              fullWidth
              multiline
              placeholder="Type the note..."
              variant="outlined"
              sx={{ width: "500px" }}
              value={note}
              onChange={(e) => setNote(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              onClick={() => {
                setOpen(false);
                handleClick();
                fetchNotes();
              }}
              color="success"
            >
              Confirm
            </Button>
            <Button variant="contained" onClick={() => setOpen(false)}>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }

  return (
    <>
      <Grid
        container
        columnSpacing={{ xs: 1, sm: 3, xl: "50px" }}
        margin="20px 0 0"
      >
        <Grid item md={9} lg={9} sx={{ maxWidth: "908px", width: "100%" }}>
          <Block>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <h3 style={{ fontWeight: 600 }}>Account Notes</h3>
              <AddNoteModal style={{ marginBottom: "0" }} />
            </div>
            <DataTable
              columnDefs={columns}
              rowData={rowData}
              defaultColDef={defaultColDef}
              // gridOptions={gridOptions}
            />
          </Block>
        </Grid>
      </Grid>
    </>
  );
}
