import { useEffect, useState } from "react";
import { Box, Button, Grid, Stack, TextField, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate, useParams } from "react-router-dom";
import useAppSelector from "../../hooks/useAppSelector";
import useAppDispatch from "../../hooks/useAppDispatch";
import Block from "../../components/Block";
import { deleteGroup, fetchGroup, removeGroup, updateGroup } from "../../redux/module/group";
import ConfirmDialog from "../../components/ConfirmDialog";
import UpdateItemTitle from "../../components/UpdateItemTitle";

const UpdateGroup = () => {
  const { id } = useParams();
  const group = useAppSelector((state) => state.group.group);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isOpenDiscard, setIsOpenDiscard] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [groupInfo, setGroupInfo] = useState({
    name: "",
  });

  async function handleSubmit(event) {
    event.preventDefault();
    setIsSubmitting(true);
    await dispatch(updateGroup({ id, payload: { name: groupInfo.name } }));
    setIsSubmitting(false);
  }

  useEffect(() => {
    if (id) {
      dispatch(fetchGroup(id));
    }
  }, [id]);

  useEffect(() => {
    if (group.id === +id) {
      setGroupInfo({
        name: group.name,
      });
    }
  }, [group]);

  const handleDiscard = () => {
    dispatch(fetchGroup(id));
  };

  const handleDelete = async () => {
    const resp = await dispatch(deleteGroup(id));
    if (resp.payload === id) {
      navigate(-1);
    }
  };
  return (
    <Box maxWidth="1394px" margin=" 30px auto 0">
      <ConfirmDialog
        title="Discard updates?"
        open={isOpenDiscard}
        setOpen={setIsOpenDiscard}
        onConfirm={handleDiscard}
      >
        {`Are you sure you want to discard all changes?`}
      </ConfirmDialog>
      <ConfirmDialog
        title="Delete group?"
        open={isOpenDelete}
        setOpen={setIsOpenDelete}
        onConfirm={handleDelete}
      >
        {`Are you sure you want to delete this group?`}
      </ConfirmDialog>
      <form id="groupUpdate" onSubmit={handleSubmit}>
        <UpdateItemTitle
          item={"Group"}
          setIsOpenDelete={setIsOpenDelete}
          setIsOpenDiscard={setIsOpenDiscard}
          isSubmitting={isSubmitting}
        />
        <Grid
          container
          columnSpacing={{ xs: 1, sm: 3, xl: "50px" }}
          margin="20px 0 0"
        >
          <Grid item md={9} lg={9} sx={{ maxWidth: "908px", width: "100%" }}>
            <Block>
              <TextField
                required
                name="name"
                label="Group"
                type="text"
                id="name"
                fullWidth
                autoComplete="off"
                value={groupInfo.name}
                onChange={(e) =>
                  setGroupInfo({ ...groupInfo, name: e.target.value })
                }
                sx={{ pt: 1, pb: 1 }}
              />
            </Block>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default UpdateGroup;
