import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import DatePickerCustom2 from "../../../components/DatePicker2";
import dayjs from "dayjs";
import { useState } from "react";
import { generatePriceMismatches } from "../../../distflowAPI/ordersApi";
import { DATE_FORMAT } from "../../../utils/constants";

function GenerateMismatches({ open, setOpen }) {
  const [startDate, setStartDate] = useState(dayjs());
  const [endDtate, setEndDate] = useState(dayjs());
  const [emailingMismatch, setEMailingMismatch] = useState(false);
  function emailMismatchReport() {
    setEMailingMismatch(true);
    generatePriceMismatches(
      startDate.format(DATE_FORMAT),
      endDtate.format(DATE_FORMAT),
    )
      .then((res) => setEMailingMismatch(false))
      .finally(() => {
        alert("an email will arrive shortly!");
        setOpen(false);
      });
  }
  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>
        <Typography>Generate Pricing mismatches</Typography>
      </DialogTitle>
      <DialogContent sx={{ padding: 2 }}>
        <Stack paddingTop={2}>
          <Stack direction={"row"} spacing={1}>
            <DatePickerCustom2
              controlledValue={startDate}
              setControlledValue={setStartDate}
              label={"start date"}
            />
            <DatePickerCustom2
              controlledValue={endDtate}
              setControlledValue={setEndDate}
              label={"end date"}
            />
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack direction={"row"} spacing={2}>
          <Button
            disabled={emailingMismatch}
            variant="contained"
            color="error"
            onClick={() => setOpen(false)}
          >
            Close
          </Button>
          <Button
            disabled={emailingMismatch}
            onClick={emailMismatchReport}
            variant="contained"
            color="success"
          >
            Generate
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}

export default GenerateMismatches;
