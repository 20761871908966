import { Typography, Tooltip, IconButton } from "@mui/material";
import DoneAllTwoToneIcon from "@mui/icons-material/DoneAllTwoTone";
import dayjs from "dayjs";
import {
  DATE_FORMAT,
  DATE_FORMAT_HOUR,
  DATE_FORMAT_US,
} from "../../utils/constants";

const columns = (setNotificationAsAcknowledged) => [
  {
    headerName: "Content",
    field: "content",
    sortable: true,
    flex: 6,
  },
  {
    headerName: "Date",
    field: "created_at",
    sortable: true,
    valueFormatter: (params) => dayjs(params.value).format(DATE_FORMAT_US),
    flex: 1,
  },
  {
    headerName: "Acknowledged by",
    field: "acknowledged_by",
    cellRenderer: (params) => {
      return <Typography>{params.data.acknowledged_by ?? "N/A"}</Typography>;
    },
    sortable: true,
    flex: 1,
  },
  {
    headerName: "Acknowledged at",
    field: "acknowledged_at",
    valueFormatter: (params) =>
      params.value ? dayjs(params.value).format(DATE_FORMAT_HOUR) : "N/A",
    sortable: true,
    flex: 1,
  },
  {
    headerName: "Actions",
    cellRenderer: (params) => {
      return params.data.acknowledged_by ? (
        <Tooltip title="Acknowledged">
          <DoneAllTwoToneIcon style={{ color: "blue" }} />
        </Tooltip>
      ) : (
        <Tooltip title="Set as Acknowledged">
          <IconButton
            onClick={() => setNotificationAsAcknowledged(params.data.id)}
          >
            <DoneAllTwoToneIcon style={{ color: "grey" }} />
          </IconButton>
        </Tooltip>
      );
    },
    minWidth: 120,
    maxWidth: 120,
  },
];

export default columns;
