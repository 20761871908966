import { useState } from "react";
import Block from "../../components/Block";
import {
  Alert,
  Box,
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import DatePickerCustom2 from "../../components/DatePicker2";
import { vdc } from "../../distflowAPI/ordersApi";
import VendorSelect from "../../components/AsyncSelects/VendorSelect";
import { downloadFile } from "../../distflowAPI/downloadFile";
import dayjs from "dayjs";
function VendorReportComparator() {
  const [difference, setDifference] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [startDate, setStartDate] = useState(dayjs());
  const [endDate, setEndDate] = useState(dayjs());
  const [csvFiles, setCsvFiles] = useState();
  const [errors, setErrors] = useState({});
  const [selectedVendor, setSelectedVendor] = useState();
  const [isDownloading, setIsDownloading] = useState(false);

  function handleSubmit() {
    const toCheck = [
      { name: "start date", value: startDate },
      { name: "end date", value: endDate },
      { name: "csv file", value: csvFiles },
    ];
    const newErrors = {};
    toCheck.map((item) => {
      if (!item.value) {
        newErrors[item.name] = `${item.name} can not be empty`;
      }
    });
    setErrors(newErrors);
    if (Object.keys(newErrors).length > 0) {
      return;
    }
    const payload = new FormData();
    payload.append("start_date", startDate.format("YYYY-MM-DD"));
    payload.append("end_date", endDate.format("YYYY-MM-DD"));
    for (let i = 0; i < csvFiles.length; i++) {
      payload.append(`csv_files.${i}`, csvFiles[i], csvFiles[i].name);
    }

    vdc(payload).then((res) => setDifference(res.data));
  }

  function handleExportStatement() {
    setIsDownloading(true);
    const toCheck = [
      { name: "start date", value: startDate },
      { name: "end date", value: endDate },
      { name: "vendor", value: selectedVendor },
    ];
    const newErrors = {};
    toCheck.map((item) => {
      if (!item.value) {
        newErrors[item.name] = `${item.name} can not be empty`;
      }
    });
    setErrors(newErrors);
    if (Object.keys(newErrors).length > 0) {
      setIsDownloading(false);
      return;
    }
    const payload = {};
    const start_date = startDate.format("YYYY-MM-DD");
    const end_date = endDate.format("YYYY-MM-DD");
    downloadFile(
      `/api/orders/vendor_statement_export/?start_date=${start_date}&end_date=${end_date}&vendor_id=${selectedVendor.value}&include_extra=${false}&include_pd=${false}`,
      `${selectedVendor.label}.xlsx`
    ).then(
      (res) => setIsDownloading(false),
      (err) => setIsDownloading(false)
    );
  }

  return (
    <>
      <h1>Vendor Statement Export/Comparator</h1>
      {Object.keys(errors).map((error) => (
        <Alert severity="error" style={{ marginBottom: 2 }}>
          {errors[error]}
        </Alert>
      ))}
      <Block>
        <Typography marginBottom={2}>
          Start by selecting a start date and end date, then upload the file
          containing vendor's statement then hit submit
        </Typography>
        {!submitted && (
          <Stack direction={"row"} spacing={3}>
            <Box maxWidth={175}>
              <Typography>Start Date</Typography>
              <DatePickerCustom2
                controlledValue={startDate}
                setControlledValue={setStartDate}
              />
            </Box>
            <Box maxWidth={175}>
              <Typography>End Date</Typography>
              <DatePickerCustom2
                controlledValue={endDate}
                setControlledValue={setEndDate}
              />
            </Box>
            <Box maxWidth={175} minWidth={175}>
              <Typography>Vendor</Typography>
              <VendorSelect
                label="vendor"
                name="vendor"
                value={selectedVendor}
                fullWidth
                onChange={(_event, newValue) => {
                  setSelectedVendor(null);
                  if (newValue) {
                    if (newValue.value) {
                      setSelectedVendor(newValue);
                      //setVendor(newValue.value)
                    }
                  }
                }}
              />
            </Box>
            <Box>
              <Button
                variant="outlined"
                style={{ marginTop: 23 }}
                onClick={handleExportStatement}
                disabled={isDownloading}
              >
                {isDownloading ? "Downloading..." : "Export Statement"}
              </Button>
            </Box>
            <Box sx={{ borderRight: "5px solid" }}></Box>
            <Box>
              <h1>OR</h1>
            </Box>
            <Box sx={{ borderRight: "5px solid" }}></Box>
            <Box>
              <Typography marginTop={1}>vendor statement file</Typography>
              <input
                type="file"
                onChange={(e) => setCsvFiles(e.target.files)}
                style={{ marginTop: 12 }}
                multiple
              />
            </Box>
            <Box>
              <Button
                variant="contained"
                style={{ marginTop: 22 }}
                onClick={handleSubmit}
              >
                Upload and Compare
              </Button>
            </Box>
          </Stack>
        )}
        {difference &&
          difference.difference &&
          Object.keys(difference.difference).length > 0 && (
            <Block style={{ marginTop: 8 }}>
              <Alert severity="error">Data mismatch!</Alert>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Item</TableCell>
                    <TableCell>Ordered</TableCell>
                    <TableCell>Billed</TableCell>
                    <TableCell>Difference</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.keys(difference.difference).map((item) => (
                    <TableRow>
                      <TableCell>{item}</TableCell>
                      <TableCell>
                        {difference.difference[item].ordered}
                      </TableCell>
                      <TableCell>
                        {difference.difference[item].billed}
                      </TableCell>
                      <TableCell>
                        {difference.difference[item].billed -
                          difference.difference[item].ordered}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Block>
          )}
        {difference &&
          difference.difference &&
          Object.keys(difference.difference).length == 0 && (
            <Alert>Data match !!</Alert>
          )}
      </Block>
    </>
  );
}

export default VendorReportComparator;
