import { Box, Typography } from "@mui/material";

import dayjs from "dayjs";

import React from "react";
import { EmailDetails } from "./EmailDetailsModal";
const weekOfYear = require("dayjs/plugin/weekOfYear");

dayjs.extend(weekOfYear);

const EmailQueueRecipientsRenderer = (row) => {
  const [open, setOpen] = React.useState(false);
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          gap: "6px",
        }}
        onClick={() => setOpen(true)}
      >
        {row.data.recipients.split(",").map((recipient, index) => {
          return (
            <Typography
              key={index}
              variant="body2"
              sx={{
                textDecoration: "underline",
                cursor: "pointer",
                color: "#3f51b5",
              }}
            >
              {recipient + (index < row.data.recipients.length - 1 ? ", " : "")}
            </Typography>
          );
        })}
      </Box>
      {open && (
        <EmailDetails
          row={row.data}
          onClose={() => {
            setOpen(false);
          }}
        />
      )}
    </>
  );
};

const subject = {
  headerName: "Subject",
  field: "subject",
  sortable: true,
  comparator: () => {},
  width: 500,
};
const recipients = {
  headerName: "Recipients",
  field: "recipients",
  cellRenderer: EmailQueueRecipientsRenderer,
  width: 450,
  sortable: true,
};

const emailed = {
  headerName: "emailed",
  field: "emailed",
  sortable: true,
  maxWidth: 100,
  cellRenderer: (props) => {
    return <Typography>{props.data.emailed ? "Yes" : "No"}</Typography>;
  },
  align: "center",
};

const created_at = {
  headerName: "Created At",
  field: "created_att",
  cellRenderer: (props) => {
    return (
      <Typography>
        {dayjs(props.data.created_att).format("ddd, D MMM YYYY h:mm A")}
      </Typography>
    );
  },
  minWidth: 364,
  sortable: true,
};

const EmailQueueColumnsDef = [recipients, subject, emailed, created_at];

export default EmailQueueColumnsDef;
