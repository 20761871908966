import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import categoriesApi, {
  deleteCategoryOverride,
  getCategory,
  getCategoryOverrides,
  patchCategoryOverride,
  postCategoryOverride,
} from "../../distflowAPI/categoriesApi";
import { signOut } from "./user";
import { fireErrorNotification, fireSuccessNotification } from "../../utils";
import accountsApi from "../../distflowAPI/accountsApi";
import { fetchAccountOverrides, removeAccount } from "./account";

/**
 * @typedef {Object} Category
 * @property {number} Category.id
 * @property {string} Category.name
 * @property {number} Category.parent
 * @property {any} Category.icon
 * @property {string} Category.tree_name combination of parent names and name (parent name > name)
 * @property {number} Category.products_count
 */

/**
 * @type {{
 *  category: Category;
 *  categories: Array<Category>;
 *  categoriesLoading: boolean;
 *  pageSize: number;
 *  page: number;
 *  orderBy: [];
 *  filterBy: {};
 *  totalCount: number;
 *  createLoading: boolean;
 *  updateLoading: boolean;
 *  formModalOpen: boolean;
 *  formInitialData: Partial<Category>|null;
 * }}
 */
const initialState = {
  category: {},
  categories: [],
  categoriesLoading: false,
  pageSize: 50,
  page: 1,
  orderBy: [],
  filterBy: [],
  totalCount: 0,
  categoryOverrides: [],
  categoryOverridesLoading: false,
  createLoading: false,
  updateLoading: false,
  formModalOpen: false,
  formInitialData: null,
};

export const fetchCategories = createAsyncThunk(
  "category/fetchCategories",
  async (data, { dispatch, getState }) => {
    const { page, pageSize, filterBy, orderBy } = data;
    return await categoriesApi.list(pageSize, page, orderBy, filterBy);
  },
);

export const fetchCategory = createAsyncThunk(
  "category/fetchCategory",
  async (categoryId, thunkAPI) => {
    const res = await getCategory(categoryId);
    return res.data;
  },
);

export const createCategory = createAsyncThunk(
  "category/createCategory",
  async (data, { rejectWithValue }) => {
    try {
      const res = await categoriesApi.create(data);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const updateCategory = createAsyncThunk(
  "category/updateCategory",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await categoriesApi.update(id, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const removeCategory = createAsyncThunk(
  "category/removeCategory",
  async (categoryId, { rejectWithValue }) => {
    try {
      const res = await categoriesApi.delete(categoryId);
      return categoryId;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const fetchCategoryOverrides = createAsyncThunk(
  "category/fetchCategoryOverrides",
  async (categoryId, thunkAPI) => {
    const res = await getCategoryOverrides(categoryId);
    return res.data.results;
  },
);

export const createCategoryOverride = createAsyncThunk(
  "category/createCategoryOverride",
  async (data, { rejectWithValue }) => {
    const { id, ...payload } = data;

    try {
      const res = await postCategoryOverride(id, payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const updateCategoryOverride = createAsyncThunk(
  "category/updateCategoryOverride",
  async (data, { rejectWithValue }) => {
    const { id, overrideId, payload } = data;

    try {
      const res = await patchCategoryOverride(id, overrideId, payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const removeCategoryOverride = createAsyncThunk(
  "category/removeCategoryOverride",
  async (data) => {
    const { id, overrideId } = data;
    const res = await deleteCategoryOverride(id, overrideId);
    return { overrideId };
  },
);

export const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {
    setCategoriesTableSettings(state, { payload }) {
      state[payload.field] = payload.value;
    },
    cleanTableSettings(state) {
      state.pageSize = 50;
      state.page = 1;
      state.orderBy = [];
      state.filterBy = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCategories.pending, (state) => {
        state.categoriesLoading = true;
      })
      .addCase(fetchCategories.fulfilled, (state, action) => {
        state.categoriesLoading = false;
        state.categories = action.payload.results;
        state.totalCount = action.payload.count;
      })
      .addCase(fetchCategories.rejected, (state) => {
        state.categoriesLoading = false;
      })
      .addCase(fetchCategory.pending, (state, action) => {
        state.categoriesLoading = true;
      })
      .addCase(fetchCategory.fulfilled, (state, action) => {
        state.category = action.payload;
        state.categoriesLoading = false;
      })
      .addCase(createCategory.pending, (state) => {
        state.createLoading = true;
      })
      .addCase(createCategory.fulfilled, (state, action) => {
        state.createLoading = false;
        fireSuccessNotification(
          `Category "${action.meta.arg.get("name")}" created successfully`,
        );
        state.totalCount = state.totalCount + 1;
        if (state.categories.length < state.pageSize) {
          state.categories = [...state.categories, action.payload];
        }
      })
      .addCase(createCategory.rejected, (state, action) => {
        state.createLoading = false;
        const errorFields = Object.keys(action.payload);
        errorFields.forEach((field) => {
          fireErrorNotification(`${field}: ${action.payload[field][0]}`);
        });
      })

      .addCase(updateCategory.pending, (state) => {
        state.updateLoading = true;
      })
      .addCase(updateCategory.fulfilled, (state, action) => {
        state.updateLoading = false;
        state.category = action.payload;
        fireSuccessNotification(
          `Categroy "${action.meta.arg.data.get("name")}" updated successfully`,
        );
      })
      .addCase(updateCategory.rejected, (state, action) => {
        state.updateLoading = false;
        const errorFields = Object.keys(action.payload);
        errorFields.forEach((field) => {
          fireErrorNotification(`${field}: ${action.payload[field][0]}`);
        });
      })
      .addCase(removeCategory.fulfilled, (state, action) => {
        fireSuccessNotification(`Category deleted successfully`);
        state.category = {};
        state.categories = state.categories.filter(
          (el) => el.id !== action.payload,
        );
      })
      .addCase(removeCategory.rejected, (state, action) => {
        const protected_elements = action.payload.protected_elements;
        fireErrorNotification(`Protected by: 
          ${protected_elements.map(
            (el) => ` id: ${el.id} label: ${el.label}`,
          )}`);
      })
      .addCase(fetchCategoryOverrides.pending, (state, action) => {
        state.categoryOverridesLoading = true;
      })
      .addCase(fetchCategoryOverrides.fulfilled, (state, action) => {
        state.categoryOverrides = action.payload;
        state.categoryOverridesLoading = false;
      })
      .addCase(createCategoryOverride.fulfilled, (state, action) => {
        fireSuccessNotification(`Сategory override created successfully`);
        state.categoryOverrides.push(action.payload);
      })
      .addCase(createCategoryOverride.rejected, (state, action) => {
        const errorFields = Object.keys(action.payload);
        errorFields.forEach((field) => {
          fireErrorNotification(`${field}: ${action.payload[field][0]}`);
        });
      })

      .addCase(updateCategoryOverride.fulfilled, (state, action) => {
        fireSuccessNotification(`Override updated successfully`);
        state.categoryOverrides = state.categoryOverrides.map((el) => {
          if (el.id === action.payload.id) {
            return action.payload;
          } else {
            return el;
          }
        });
      })
      .addCase(updateCategoryOverride.rejected, (state, action) => {
        const errorFields = Object.keys(action.payload);
        errorFields.forEach((field) => {
          fireErrorNotification(`${field}: ${action.payload[field][0]}`);
        });
      })

      .addCase(removeCategoryOverride.fulfilled, (state, action) => {
        fireSuccessNotification(`Override deleted successfully`);
        state.categoryOverrides = state.categoryOverrides.filter(
          (el) => el.id !== action.payload.overrideId,
        );
      })

      .addCase(signOut, () => initialState);
  },
});

export const { setCategoriesTableSettings, cleanTableSettings } =
  categorySlice.actions;

export const selectNumberOfPages = createSelector(
  (state) => state.category.totalCount,
  (state) => state.category.pageSize,
  (totalCount, pageSize) => Math.ceil(totalCount / pageSize),
);

export default categorySlice.reducer;
