import { useEffect, useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import InputAdornment from "@mui/material/InputAdornment";
import { Typography } from "@mui/material";
import { DATE_FORMAT_FRONT } from "../utils/constants";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

const DatePickerCustom2 = ({
  maxWidth,
  size,
  testId,
  startText,
  controlledValue,
  setControlledValue,
  label,
  maxDate,
  minDate,
  shouldDisableDate,
  additionlStyles,
  height,
  defaultValue,
  open
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label={label}
        views={["year", "month", "day"]}
        format={DATE_FORMAT_FRONT}
        value={controlledValue}
        onChange={(newValue) => {
          setControlledValue(newValue);
        }}
        maxDate={maxDate}
        minDate={minDate}
        shouldDisableDate={shouldDisableDate}
        defaultValue={defaultValue || dayjs()}
        open={open}
        slotProps={{
          actionBar: {
            actions: ["clear"],
          },
          textField: (props) => ({
            InputProps: {
              ...props.InputProps,
              "data-testid": testId,
              startAdornment: startText ? (
                <InputAdornment position="start" sx={{ marginRight: "2px" }}>
                  <Typography fontWeight={600} sx={{ color: "#000" }}>
                    {startText}
                  </Typography>
                </InputAdornment>
              ) : null,
            },
          }),
        }}
        sx={{
          maxWidth: maxWidth,
          "& input": {
            fontSize: size === "small" ? "15px" : "16px",
            padding:
              size === "small" ? "7.2px 8px 7.2px !important" : "11.5px 12px",
            "&::placeholder": { fontSize: "12px" },
            height: height || null,
          },
          "& svg": {
            width: size === "small" ? "20px" : "24px",
            height: size === "small" ? "20px" : "24px",
          },
          "& button": { padding: size === "small" ? "4px" : "8px" },
          "& .MuiInputAdornment-positionEnd": {
            marginLeft: size === "small" ? "-4px" : "8px",
          },
          ...additionlStyles,
        }}
      />
    </LocalizationProvider>
  );
};

export default DatePickerCustom2;
