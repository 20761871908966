import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import breakpoints from "../pages/routesReport/swiperBreakpoints";
import { Box, Button, Stack, Typography } from "@mui/material";
import dayjs from "dayjs";
import useScreenSize from "../hooks/useScreenSize";
import SliderArrows from "../pages/routesReport/SliderArrows";
import DatePicker2 from "./DatePicker2";
import { DATE_FORMAT } from "../utils/constants";
import { useLocation } from "react-router-dom";

const weekOfYear = require("dayjs/plugin/weekOfYear");
dayjs.extend(weekOfYear);
function getDateXDaysFromNow(x) {
  const today = new Date(); // Get the current date
  const targetDate = new Date(); // Create a new date object
  targetDate.setDate(today.getDate() + x); // Set the new date to x days from now
  return targetDate;
}
function generateDaysFromToday() {
  const daysOfWeek = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];
  const today = new Date();
  // fix dates here...
  const fiveDaysAgo = getDateXDaysFromNow(-1200);
  const endOfYear = getDateXDaysFromNow(200); // Last day of current year
  const days = [];

  // Loop through each day from 5 days ago until the end of the year
  for (
    let d = new Date(fiveDaysAgo);
    d <= endOfYear;
    d.setDate(d.getDate() + 1)
  ) {
    if (d.getDay() === 6) {
      continue;
    }

    const dayNumber = d.getDate().toString().padStart(2, "0"); // Convert day number to string with leading zero
    const weekDay = daysOfWeek[d.getDay()]; // Get day name from daysOfWeek array
    const date = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate())); // Set timezone to UTC
    days.push({
      dayNumber,
      weekDay,
      date,
      isoDate: date.toISOString().split("T")[0],
    });
  }
  return days;
}

function getWeekNumberInMonth(date) {
  const dt = dayjs(date); // создаем объект даты из строки
  return dt.week() - dt.startOf("month").week() + 1;
}

const calendar = generateDaysFromToday();
function expandCalendar(days) {
  const daysOfWeek = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];
  const lastDay = calendar[calendar.length - 1];
  const firstDay = calendar[0];

  let d = new Date(lastDay.isoDate);
  for (let i = 0; i < days; i++) {
    d.setDate(d.getDate() + 1);

    const dayNumber = d.getDate().toString().padStart(2, "0"); // Convert day number to string with leading zero
    const weekDay = daysOfWeek[d.getDay()]; // Get day name from daysOfWeek array
    const date = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate())); // Set timezone to UTC
    calendar.push({
      dayNumber,
      weekDay,
      date,
      isoDate: date.toISOString().split("T")[0],
    });
  }
}
const getTodaysCalendarIndex = (date = null) => {
  if (date === null) {
    date = dayjs(new Date()).format(DATE_FORMAT);
  }
  const element = calendar.findIndex((x) => x.isoDate === date);
  if (element === -1) {
    date = dayjs().add(1, "day").format(DATE_FORMAT);
    return calendar.findIndex((x) => x.isoDate === date) || 0;
  }
  return element;
};

const CalendarSwiper = ({ date, setDate, dateType, searchParams }) => {
  const screenSize = useScreenSize();
  const { pathname } = useLocation();
  const [swiper, setSwiper] = useState(null);
  const [weekText, setWeekText] = useState();
  const [currentYear, setCurrentYear] = useState();
  const [currentMonth, setCurrentMonth] = useState();
  const [activeSlide, setActiveSlide] = useState(getTodaysCalendarIndex(date));

  useEffect(() => {
    console.log("Active slide is: ", activeSlide);
    if (dateType === "day") {
      calculateMonthYear(activeSlide);
      calculateWeekText(
        activeSlide,
        activeSlide + getSwipeBreakpointsPerScreenSize().slidesPerView - 1
      );
      if (swiper && activeSlide >= 0) {
        swiper.slideTo(activeSlide, 800, true);
      }

      setDate(calendar[activeSlide].isoDate);
    }
  }, [activeSlide, dateType]);

  const navigateToToday = () => {
    const todayIndex = getTodaysCalendarIndex();
    setActiveSlide(todayIndex);
  };

  const slideNext = () => {
    const activeIndex = swiper.activeIndex;
    const breakpoint = getSwipeBreakpointsPerScreenSize();
    const newIndex = activeIndex + breakpoint.slidesPerView;
    const lastPosibleIndex = calendar.length - breakpoint.slidesPerView;
    if (newIndex > lastPosibleIndex) {
      expandCalendar(14);

      setActiveSlide(newIndex);
    } else {
      setActiveSlide(newIndex);
    }
  };

  const slidePrev = () => {
    const activeIndex = swiper.activeIndex;
    const breakpoint = getSwipeBreakpointsPerScreenSize();
    const newIndex = activeIndex - breakpoint.slidesPerView;
    if (newIndex < 0) {
      expandCalendar(-14);
      setActiveSlide(newIndex + 7);
    } else {
      setActiveSlide(newIndex);
    }
  };

  const calculateWeekText = (oldIndex, newIndex) => {
    const startWeekNumber = getWeekNumberInMonth(calendar[oldIndex]?.date);
    const endWeekNumber = getWeekNumberInMonth(calendar[newIndex]?.date);

    if (startWeekNumber > endWeekNumber) {
      setWeekText(String(startWeekNumber));
    } else {
      setWeekText(`${startWeekNumber} - ${endWeekNumber}`);
    }
  };

  const calculateMonthYear = (index) => {
    const currentItem = calendar[index];
    if (currentItem) {
      const date = dayjs(currentItem.date);
      setCurrentMonth(date.format("MMMM"));
      setCurrentYear(date.format("YYYY"));
    }
  };

  const getSwipeBreakpointsPerScreenSize = () => {
    const { width } = screenSize;

    if (width < 480) {
      return breakpoints[480];
    }

    if (width > 480 && width < 640) {
      return breakpoints[480];
    }

    if (width > 640 && width < 768) {
      return breakpoints[640];
    }

    if (width > 768 && width < 1300) {
      return breakpoints[768];
    }

    return breakpoints[1300];
  };

  const onCardClick = (index, obj) => {
    setActiveSlide(index);
  };

  const datePickerDateChanged = (date) => {
    const datePickerDate = dayjs(date).format(DATE_FORMAT);
    const index = calendar.findIndex((x) => x.isoDate === datePickerDate);
    if (index >= 0) {
      setActiveSlide(index);
    }

    setDate(datePickerDate);
  };

  const disableSaturday = (date) => {
    const currentDate = dayjs.utc(date).format("ddd");
    return currentDate === "Sat";
  };

  return (
    <>
      {dateType === "day" ? (
        <Box
          bgcolor="#fff"
          border="1px solid #DBDFEA"
          borderRadius="5px"
          padding="15px 15px 0 15px"
          mb="20px"
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            flexWrap="wrap"
            gap="15px"
            mb="20px"
          >
            <Typography fontWeight={700} color="#0B0B0B">
              {currentMonth} {currentYear} / Week {weekText}
            </Typography>
            <Stack direction="row" gap="10px">
              <Button
                color="black"
                variant="contained"
                sx={{
                  width: "131px",
                  fontSize: "18px",
                  fontWeight: 600,
                  lineHeight: 1.3,
                }}
                onClick={navigateToToday}
              >
                Today
              </Button>
              <SliderArrows onPrevClick={slidePrev} onNextClick={slideNext} />
            </Stack>
          </Stack>
          <Swiper
            slidesPerView={3}
            freeMode={true}
            centeredSlides={false}
            initialSlide={activeSlide}
            onSwiper={(s) => {
              setSwiper(s);
            }}
            breakpoints={breakpoints}
            style={{ paddingBottom: "15px" }}
          >
            {calendar.map((v, index) => (
              <SwiperSlide
                style={{ cursor: "pointer" }}
                key={v.isoDate}
                onClick={(e) => {
                  onCardClick(index, v);
                }}
              >
                {() => (
                  <Box
                    position="relative"
                    textAlign="center"
                    sx={{
                      ":before": {
                        content: activeSlide === index ? "''" : "none",
                        position: "absolute",
                        bottom: "-15px",
                        left: "50%",
                        transform: "translateX(-50%)",
                        width: "80%",
                        height: "2px",
                        borderRadius: "5px",
                        bgcolor: "#6576FF",
                        cursor: "pointer",
                      },
                    }}
                  >
                    <Typography
                      color={activeSlide === index ? "#6576FF" : "#808080"}
                      fontSize="36px"
                      fontWeight={700}
                    >
                      {v.dayNumber}
                    </Typography>
                    <Typography
                      color={activeSlide === index ? "#6576FF" : "#808080"}
                      textTransform={
                        activeSlide === index ? "capitalize" : "none"
                      }
                      fontSize="14px"
                      fontWeight={500}
                    >
                      {v.weekDay}
                    </Typography>
                  </Box>
                )}
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>
      ) : (
        <Box mb="20px">
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            flexWrap="wrap"
            gap="15px"
            mb="20px"
          >
            <DatePicker2
              controlledValue={dayjs.utc(date)}
              setControlledValue={datePickerDateChanged}
              shouldDisableDate={disableSaturday}
              // maxDate={dayjs(calendar.pop().date)}
            />
            <Button
              color="black"
              variant="contained"
              sx={{
                width: "131px",
                fontSize: "18px",
                fontWeight: 600,
                lineHeight: 1.3,
              }}
              onClick={() => datePickerDateChanged(new Date())}
            >
              Today
            </Button>
          </Stack>
        </Box>
      )}
    </>
  );
};

export default CalendarSwiper;
