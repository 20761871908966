import React, { useEffect, useState } from "react";
import { explainMissingRoutes } from "../../../distflowAPI/routesTwo";
import { Button, IconButton, Stack, Typography } from "@mui/material";
import HtmlTooltip from "../../HtmlToolTip";
import { NavLink } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import ErrorPopupBoundary from "../../ErrorPopupBoundary";
import OrderRouteModal from "../OrderRouteModal";
import { Flag } from "@mui/icons-material";

const RouteRenderer = (params, extraParams) => {
  const MAX_NAME_LENGTH = 8; // Maximum length before truncation
  const [routes, setRoutes] = useState([]);
  const [reRouting, setRerouting] = useState([]);
  const [missingRoutes, setMissingRoutes] = useState([]);
  const [missingRoutesExplain, setMissingRoutesExplain] = useState({
    routes: [],
    unsupporedVendors: [],
  });
  const [incompatibleRoutes, setIncompatibleRoutes] = useState([]);
  useEffect(() => {
    const nMissingRoutes = [];
    const nRoutes = [];
    const nRe = [];
    const nIn = [];
    params.data.items
      .filter((it) => it.status != "CANCELED")
      .map((item) => {
        if (item.route && !nRoutes.map((r) => r.id).includes(item.route.id)) {
          nRoutes.push(item.route);
        }
        if (!item.route && !item.re_routing) {
          nMissingRoutes.push(item);
        }
        if (item.re_routing && !item.route?.id) {
          nRe.push(item);
        }
        if (!item.route_compatible) {
          if (item.route_compatibility_issue) {
            nIn.push(item.route_compatibility_issue);
          }
        }
      });
    setMissingRoutes(nMissingRoutes);
    setRoutes(nRoutes);
    setRerouting(nRe);
    setIncompatibleRoutes(nIn);
  }, [params]);
  useEffect(() => {
    if (missingRoutes.length) {
      explainMissingRoutes({
        accountId: params.data.accountId,
        vendorsIds: missingRoutes.map((item) => item.vendor),
      }).then((res) => {
        setMissingRoutesExplain(res.data);
      });
    }
  }, [missingRoutes]);

  function explainUnsupportedVendors() {
    let text = "";
    const l = missingRoutesExplain.unsupporedVendors.length;
    missingRoutesExplain.unsupporedVendors.map((uv, index) => {
      if (l >= 2) {
        if (index == l - 1) {
          text += " and ";
        } else {
          if (index > 0) {
            text += ", ";
          }
        }
      }
      text += uv;
    });
    return text;
  }
  return (
    <Stack
      direction={"row"}
      sx={{
        flexWrap: "wrap",
        gap: "8px",
        width: "100%",
        padding: "4px 0",
      }}
    >
      {routes?.length > 0 &&
        routes.map((route) => {
          const truncatedName =
            route.name?.length > MAX_NAME_LENGTH
              ? `${route.name.substring(0, MAX_NAME_LENGTH)}...`
              : route.name;

          return (
            <HtmlTooltip
              key={route.id}
              title={
                <div style={{ maxWidth: "400px" }}>
                  <Stack direction="column">
                    <NavLink
                      to={`/routes2/routes/${route.id}`}
                      sx={{ color: "#000", textDecoration: "none" }}
                    >
                      {route.name}
                    </NavLink>
                  </Stack>
                </div>
              }
            >
              <Button
                size="small"
                variant="gray"
                // endIcon={<OpenIc />}
                component={NavLink}
                onClick={() => {
                  if (params.data?.state == "DELIVERED") {
                    window.location.href = `/invoices/invoices/${params.data.invoice.id}`;
                    return;
                  }
                  extraParams.showModal((props) => (
                    <ErrorBoundary fallback={<ErrorPopupBoundary />}>
                      <OrderRouteModal
                        key={params.data.accountId}
                        items={params.data.items}
                        account={params.data.account}
                        accountId={params.data.accountId}
                        date={params.data.date}
                        orderId={params.data.orderId}
                        state={params.data.state}
                        invoice={params.data.invoice}
                        refreshTable={extraParams.refreshTable}
                        {...props}
                      />
                    </ErrorBoundary>
                  ));
                }}
                sx={{
                  bgcolor: "#F5F5F5",
                  border: "1px solid #DBDFEA !important",
                  padding: "0 9px !important",
                  fontSize: "14px !important",
                  width: "100px",
                  justifyContent: "flex-start",
                }}
              >
                {truncatedName}
              </Button>
            </HtmlTooltip>
          );
        })}
      {reRouting.length > 0 && (
        <HtmlTooltip
          title={
            <div>
              <Typography fontWeight={800} color={"red"}>
                Items being re routed:
              </Typography>
              {reRouting.map((item, i) => (
                <Typography key={i}>
                  {item.item_details?.name} ({item.vendor_details?.vendorName})
                </Typography>
              ))}
            </div>
          }
        >
          <IconButton>
            <Flag color="warning" />
          </IconButton>
        </HtmlTooltip>
      )}
      {incompatibleRoutes.length > 0 && (
        <HtmlTooltip
          title={
            <div>
              <Typography fontWeight={800} color={"red"}>
                Incompatible routes:
              </Typography>
              {incompatibleRoutes.map((issue, i) => (
                <Typography key={i}>{issue}</Typography>
              ))}
            </div>
          }
        >
          <IconButton>
            <Flag color="warning" />
          </IconButton>
        </HtmlTooltip>
      )}
      {missingRoutes.length > 0 && (
        <HtmlTooltip
          title={
            <div>
              <Typography fontWeight={600} color={"red"}>
                Items missing routes:
              </Typography>
              {missingRoutes.map((item, i) => (
                <Typography key={i}>
                  {item.item_details?.name} ({item.vendor_details?.vendorName})
                </Typography>
              ))}
              {missingRoutesExplain.routes.length == 0 && (
                <Stack>
                  <Typography fontWeight={600} color={"red"}>
                    Explanation
                  </Typography>
                  <Typography>No route ships to this account</Typography>
                </Stack>
              )}
              {missingRoutesExplain.routes.length > 0 && (
                <Stack>
                  <Typography fontWeight={600} color={"red"}>
                    Explanation
                  </Typography>
                  <Typography>
                    Account has the following routes:{" "}
                    {missingRoutesExplain.routes.map((r, index) => (
                      <span style={{ marginRight: 1, marginLeft: 1 }}>{r}</span>
                    ))}
                    .{" "}
                  </Typography>
                  <Typography>
                    However, none of them support vendors:{" "}
                    {explainUnsupportedVendors()}
                  </Typography>
                </Stack>
              )}
            </div>
          }
        >
          <IconButton>
            <Flag color="error" />
          </IconButton>
        </HtmlTooltip>
      )}
    </Stack>
  );
};

export default RouteRenderer;
