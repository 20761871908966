import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { ReactComponent as CalendarDay } from "../images/calendar-day.svg";
import { ReactComponent as CalendarMonth } from "../images/calendar-month.svg";

const CalendarSwitcher = (props) => {
  return (
    <ToggleButtonGroup
      value={props.dateType}
      exclusive
      onChange={props.onChange}
      sx={{
        bgcolor: "#DEDEDE",
        "& button": {
          padding: "8px",
        },
        "& button.Mui-selected": {
          backgroundColor: "#6576FF",
          "& svg": { fill: "#fff" },
          "&:hover": { backgroundColor: "#6576FF" },
        },
      }}
    >
      <ToggleButton value="day">
        <CalendarDay fill="#526484" />
      </ToggleButton>
      <ToggleButton value="month">
        <CalendarMonth fill="#526484" />
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default CalendarSwitcher;
