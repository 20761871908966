import TableButtons from "../../components/TableComponents/TableButtons";
import useAppDispatch from "../../hooks/useAppDispatch";
import { removeVendor } from "../../redux/module/vendor";
import { Link } from "react-router-dom";

function VendorLinkRenderer(props) {
  const dispatch = useAppDispatch();

  const handleDelete = async () => {
    await dispatch(removeVendor(props.data.id));
  };
  return (
    <TableButtons
      link={`/vendors/${props.data.id}`}
      handleDelete={handleDelete}
      deletePopupEntity="vendor"
      historyData={props.data.history || []}
    />
  );
}

function VendorProductsCountRenderer(params) {
  return (
    <span className="my-renderer">
      <Link
        to={`/products/?vendor__name=${params.data.name}`}
        style={{ textDecoration: "none" }}
      >
        {params.data.products_count}
      </Link>
    </span>
  );
}

const vendor_name = {
  headerName: "Vendor",
  field: "name",
  wrapText: true,
  autoHeight: true,
  filter: true,
  sortable: true,
  comparator: () => {}, //disable default sorting
  filterParams: {
    filterOptions: ["contains"],
    suppressAndOrCondition: true,
  },
  floatingFilter: true,
  flex: 8,
  minWidth: 200,
};
const vendor_address = {
  headerName: "Address",
  field: "address",
  wrapText: true,
  sortable: true,
  comparator: () => {}, //disable default sorting
  autoHeight: true,
  flex: 8,
  filter: true,
  filterParams: {
    filterOptions: ["contains"],
    suppressAndOrCondition: true,
  },
  floatingFilter: true,
  minWidth: 200,
};
const vendor_products_count = {
  headerName: "Products",
  field: "products_count",
  sortable: true,
  comparator: () => {}, //disable default sorting
  filter: false,
  cellRendererSelector: () => {
    return {
      component: VendorProductsCountRenderer,
    };
  },
  floatingFilter: true,
  autoHeight: true,
  flex: 8,
  minWidth: 200,
};
const internal_id = {
  headerName: "Internal Identifier",
  field: "internal_id",
  sortable: true,
  comparator: () => {}, //disable default sorting
  filter: true,
  filterParams: {
    filterOptions: ["contains"],
    suppressAndOrCondition: true,
  },
  floatingFilter: true,
  autoHeight: true,
  flex: 8,
  minWidth: 200,
};

const vendor_link = {
  field: "",
  sorting: false,
  sort: false,
  sortable: false,
  autoHeight: true,
  filter: false,
  cellRenderer: VendorLinkRenderer,
  flex: 2,
  minWidth: 120,
};

const vendorsTableColumns = [
  vendor_name,
  vendor_address,
  vendor_products_count,
  internal_id,
  vendor_link,
];

export default vendorsTableColumns;
