import { TextField, Box, InputAdornment, IconButton } from "@mui/material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

export default function AmountSetter({ value, setValue }) {
  const buttonStyle = {
    fontWeight: "bolder",
    textAlign: "center",
    width: "20px",
    height: "20px",
    backgroundColor: "white",
    border: 0,
    color: "black",
    cursor: "pointer",

  };
  const handleInputChange = (e) => {
    setValue(parseFloat(e.target.value));
  };
  return (
    <>
      <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <TextField
          type="number"
          sx={{ paddingRight: 0 }}
          value={value}
          onChange={handleInputChange}
          disableUnderline={true}
          InputProps={{
            startAdornment: (
              <InputAdornment
                position="start"
                sx={{ caretColor: "transparent" }}
              >
                $
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end" sx={{ caretColor: "transparent" }}>
                <Box sx={{ display: "flex", flexDirection: "column", gap: 0 }}>
                  <ArrowDropUpIcon
                    sx={buttonStyle}
                    onClick={() => {
                      setValue((oldVal) => {
                        if (isNaN(oldVal)) {
                          return 0;
                        } else {
                          return oldVal + 1;
                        }
                      });
                    }}
                  />

                  <ArrowDropDownIcon
                    size="large"
                    sx={buttonStyle}
                    onClick={() => {
                      setValue((oldVal) => {
                        if (oldVal <= 0 || isNaN(oldVal)) {
                          return 0;
                        } else if (oldVal > 0) {
                          return parseFloat(oldVal - 1);
                        }
                      });
                    }}
                  />
                </Box>
              </InputAdornment>
            ),
          }}
        />
      </div>
    </>
  );
}
