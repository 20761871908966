import AsyncAutocompleteSelect from "./AsyncAutocompleteSelect";
import React from "react";
import { listCustomerGroups } from "../../distflowAPI/territoriesApi";

export default function AccountTypeSelect(props) {
  return (
    <AsyncAutocompleteSelect
      fetchOptions={(searchTerm) =>
        listCustomerGroups(searchTerm).then((res) =>
          res.data.results.map((item) => {
            return {
              value: item.id,
              label: item.name,
            };
          })
        )
      }
      {...props}
    />
  );
}
