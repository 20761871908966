import { useEffect, useState } from "react";
import { Typography, Stack, Box } from "@mui/material";
import "swiper/css";
import "swiper/css/navigation";
import CalendarSwitcher from "../../components/CalendarSwitcher";
import Route from "./Route";
import CircularProgress from "@mui/material/CircularProgress";
import useAppDispatch from "../../hooks/useAppDispatch";
import { fetchVendors } from "../../redux/module/vendor";
import useAppSelector from "../../hooks/useAppSelector";
import Alert from "../../components/Alert";
import { fetchAccounts } from "../../redux/module/account";
import { useSearchParams } from "react-router-dom";
import { fetchRoutes } from "../../redux/module/route";
import {
  fetchRouteReports,
  setShowErrorMessage,
} from "../../redux/module/routeReports";
import { fetchDrivers } from "../../redux/module/driver";
import { fetchAllUsers } from "../../redux/module/user";
import CalendarSwiper from "../../components/CalendarSwiper";

const RoutesReport = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const dispatch = useAppDispatch();

  const [date, setDate] = useState();
  const [dateType, setDateType] = useState("day");

  const routeReports = useAppSelector((state) => state.routeReports.reports);
  const accounts = useAppSelector((state) => state.account.accounts);

  const routeReportsLoading = useAppSelector((state) => state.routeReports.loading);
  const allDriversLoading = useAppSelector((state) => state.user.allUsersLoading);
  const accountsLoading = useAppSelector((state) => state.account.accountsLoading);
  const vendorsLoading = useAppSelector((state) => state.vendor.vendorsLoading);
  const routesLoading = useAppSelector((state) => state.route.routesLoading);
  const driversLoading = useAppSelector((state) => state.driver.driversLoading);

  const isDataLoading = routeReportsLoading || allDriversLoading || accountsLoading || vendorsLoading || routesLoading || driversLoading;

  const showErrorMessage = useAppSelector(
    (state) => state.routeReports.showErrorMessage
  );

  const errorMessage = useAppSelector(
    (state) => state.routeReports.errorMessage
  );

  useEffect(() => {
    dispatch(fetchAllUsers());
    dispatch(fetchAccounts({ filterBy: [], pageSize: 200, orderBy: [], page: 1 }));
    dispatch(fetchVendors({ filterBy: [], pageSize: 200, orderBy: [], page: 1 }));
    dispatch(fetchRoutes({ pageSize: 200 }));
    dispatch(fetchDrivers());
  }, []);

  useEffect(() => {
    if (accounts.length > 0 && date) {
      setSearchParams({ date });
      reloadRouteReports();
    }
  }, [accounts, date]);

  const reloadRouteReports = (showWarningOfMissingOrders = true) => {

    dispatch(fetchRouteReports({ date, showWarningOfMissingOrders }));
  };

  const changeDateType = (event, newAlignment) => {
    if (newAlignment !== null) {
      setDateType(newAlignment);
    }
  };

  const closeNoOrdersDialog = () => {
    dispatch(setShowErrorMessage(false));
  };

  return (
    <Box maxWidth="1522px" margin="0 auto">
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        mb="25px"
      >
        <Typography fontSize="23px" fontWeight={800} component="h2">
          Routes report
        </Typography>
        <CalendarSwitcher dateType={dateType} onChange={changeDateType} />
      </Stack>

      <CalendarSwiper
        date={date}
        setDate={setDate}
        dateType={dateType}
        searchParams={searchParams}
      />

      {isDataLoading && (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      )}

      <Alert
        show={showErrorMessage}
        title={errorMessage}
        onCancel={closeNoOrdersDialog}
      />

      {!isDataLoading && (
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr",
            gap: "15px",
            "@media (max-width: 1500px)": { gridTemplateColumns: "1fr 1fr" },
            "@media (max-width: 860px)": { gridTemplateColumns: "1fr" },
          }}
        >
          {routeReports?.map((report, index) => (
            <Route
              key={index}
              routeReport={report}
              calculation_date={report.calculation_date}
              deliveries={
                report.route_report?.deliveries?.filter(
                  (x) => x.state == "DELIVERED"
                ) ?? []
              }
            />
          ))}
        </Box>
      )}
    </Box>
  );
};

export default RoutesReport;
