import api from "./api";
import ApiBase from "./apiBase";

class CategoriesApi extends ApiBase {
  constructor() {
    super("/products/categories");
  }

  createCategoryOverride(id, formData) {
    return api.post(`/api${this.urlPrefix}/${id}/overrides/`, formData);
  }

  listCategoryOverrides(id) {
    return api.get(`/api${this.urlPrefix}/${id}/overrides/`);
  }

  updateCategoryOverride(id, oid, formData) {
    return api.patch(`/api${this.urlPrefix}/${id}/overrides/${oid}/`, formData);
  }

  deleteCategoryOverride(id, oid) {
    return api.delete(`/api${this.urlPrefix}/${id}/overrides/${oid}/`);
  }
}

const categoriesApi = new CategoriesApi();

export default categoriesApi;

export function getCategory(categoryId) {
  return api.get(`/api/products/categories/${categoryId}/`);
}

export function getCategoryOverrides(id) {
  return api.get(`/api/products/categories/${id}/overrides/`);
}

export function postCategoryOverride(categoryId, data) {
  return api.post(`/api/products/categories/${categoryId}/overrides/`, data);
}

export function patchCategoryOverride(id, oid, formData) {
  return api.patch(
    `/api/products/categories/${id}/overrides/${oid}/`,
    formData,
  );
}

export function deleteCategoryOverride(id, oid) {
  return api.delete(`/api/products/categories/${id}/overrides/${oid}/`);
}

// Category Restrictions


export async function getCategoryRestrictions(categoryId, pageSize, page) {
  const params = `categoryId=${categoryId}&page_size=${pageSize}&page=${page}`;
  const result = await api.get(
    `/api/products/categories/category_customer_restrictions/?${params}`,
  );
  return result;
}

export async function postCategoryRestrictions(categoryId, customerId, note) {
  const result = await api.post(
    `/api/products/categories/category_customer_restrictions/`,
    {
      category: categoryId,
      customer: customerId,
      note: note,
    },
  );
  return result;
}

export async function deleteCategoryRestrictions(restrictionId) {
  const result = await api.delete(
    `/api/products/categories/category_customer_restrictions/${restrictionId}/`,
  );
  return result;
}
