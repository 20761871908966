import { useMemo } from "react";
import { arrayToDictionary } from "../utils";

/**
 * Same as `arrayToDictionary`, just wrapped in `useMemo`
 * @template T
 * @param {Array<T>} collection
 * @param {keyof T | ((item: T) => string)} iterator
 * @returns {Record<string, T>}
 */
export default function useKeyBy(collection, iterator) {
  return useMemo(
    () => arrayToDictionary(collection, iterator),
    [collection, iterator]
  );
}
