import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import Header from "./header/Header";
import Sidebar from "./sidebar/Sidebar";
import Spinner from "./Spinner";
import { signOut } from "../redux/module/user";
import useAppSelector from "../hooks/useAppSelector";
import useAppDispatch from "../hooks/useAppDispatch";
import { setRouteHistory } from "../redux/module/utils";

const drawerWidth = 210;

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
const ProtectedLayout = () => {
  const dispatch = useAppDispatch();
  const [mobileOpen, setMobileOpen] = useState(false);
  const authUser = useAppSelector((state) => state.user.authUser);
  const isInitialized = useAppSelector((state) => state.user.isInitialized);

  const location = useLocation();

  useEffect(() => {
    dispatch(setRouteHistory(location));
  }, [location, dispatch]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  if (!isInitialized) {
    return (
      <Box
        height="100vh"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Spinner size="lg" />
      </Box>
    );
  }

  if (!authUser) {
    return <Navigate to="/auth/login" />;
  }

  return (
    <Box sx={{ display: "flex", paddingTop: "64px" }}>
      <ScrollToTop />
      <Header
        drawerWidth={drawerWidth}
        handleDrawerToggle={handleDrawerToggle}
        user={authUser}
        signOut={() => dispatch(signOut())}
      />
      <Box
        component="nav"
        sx={{ width: { lg: drawerWidth }, flexShrink: { lg: 0 } }}
      >
        <Sidebar
          drawerWidth={drawerWidth}
          mobileOpen={mobileOpen}
          handleDrawerToggle={handleDrawerToggle}
        />
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: { xs: 2, sm: 3 },
          position: "relative",
          width: { xs: "100%", lg: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Outlet />
      </Box>
    </Box>
  );
};

export default ProtectedLayout;
