import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import {
  Box,
  Button,
  IconButton,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import { memo } from "react";
import { NavLink } from "react-router-dom";
import useAppSelector from "../../hooks/useAppSelector";
import Dots from "../../images/dots.svg";
import { ReactComponent as Trash } from "../../images/trash-alt.svg";
import { ReactComponent as Undo } from "../../images/undo.svg";

export const SortableVendor = memo(
  ({
    id,
    pickup_route,
    disabled,
    toggleVendor,
    title,
    subtitle,
    openManagePickupsDialog,
  }) => {
    const { attributes, listeners, setNodeRef, transform, transition } =
      useSortable({ id });

    const style = {
      transform: CSS.Transform.toString(transform),
      transition,
    };

    const disabledStyle = {
      textDecoration: disabled ? "line-through" : "none",
    };

    const toggleVendorWrapper = () => {
      toggleVendor(id, !disabled);
    };

    const routes = useAppSelector((state) => state.route.routes);

    return (
      <Box
        ref={setNodeRef}
        sx={{
          position: "relative",
          display: "grid",
          gridTemplateColumns: "repeat(3, 1fr)",
          alignItems: "center",
          gap: "20px",
          backgroundColor: disabled ? "#f0f0f0" : "#fff",
          borderTop: "1px solid #DBDFEA",
          padding: "10px 22px 10px 16px",
          touchAction: "none",
        }}
        style={style}
      >
        <Stack direction="row" alignItems="center" gap="11px">
          <Button
            sx={{
              minWidth: "8px",
              padding: "4px 3px 4px 4px",
              display: "flex",
              cursor: "grab",
              flexShrink: 0,
              "&:hover": {
                backgroundColor: "#dcdcdc",
              },
            }}
            {...listeners}
            {...attributes}
          >
            <img src={Dots} alt="" />
          </Button>
          <Box>
            <Typography sx={{ color: "#252525", ...disabledStyle }}>
              {title}
            </Typography>
            <Typography
              fontSize="11px"
              sx={{ color: "#535151", ...disabledStyle }}
            >
              {subtitle}
            </Typography>
          </Box>
        </Stack>
        <Typography fontSize="11px" sx={{ color: "#535151" }}>
          {pickup_route &&
            `Picking up for route ${
              routes.find((x) => x.id == pickup_route)?.name
            }`}
        </Typography>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          gap="20px"
        >
          {!!openManagePickupsDialog && (
            <Button
              onClick={() => openManagePickupsDialog(id)}
              variant="outlined"
              size="small"
              sx={{
                fontWeight: "normal",
                bgcolor: "#FEF9CE",
                padding: "4px 12px",
                "&:hover": { bgcolor: "#FAF3B5", color: "#000" },
              }}
            >
              Manage Pickups
            </Button>
          )}
          {!!toggleVendor && (
            <IconButton onClick={toggleVendorWrapper}>
              {disabled ? <Undo /> : <Trash />}
            </IconButton>
          )}
        </Stack>
      </Box>
    );
  }
);
