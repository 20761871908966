/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Box, Button, Stack, Switch, Typography } from "@mui/material";
import DataTable from "../../components/DataTable";
import { useSearchParams } from "react-router-dom";
import {
  fetchAccounts,
  selectAccountNumberOfPages,
  setAccountTableSettings,
  cleanTableSettings,
} from "../../redux/module/account";
import useAppDispatch from "../../hooks/useAppDispatch";
import useAppSelector from "../../hooks/useAppSelector";
import AddAccountForm from "./AddAccountForm";
import accountTableColumns from "./accountTableColumns";
import {
  formatAgGridFilters,
  formatAgGridSorting,
} from "../../distflowAPI/common";
import PageLoading from "../../components/PageLoading";
import { downloadFile } from "../../distflowAPI/downloadFile";

const defaultColDef = {
  flex: 1,
  filter: false,
  floatingFilter: false,
};

const Accounts = () => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const { accounts, pageSize, page, orderBy, accountsLoading } = useAppSelector(
    (state) => state.account
  );
  const numberOfPages = useAppSelector(selectAccountNumberOfPages);
  const { authUser } = useAppSelector((state) => state.user);
  const [myCustomers, setMyCustomers] = useState(false);
  const [filterBy, setFilterBy] = useState([
    { column: "myCustomers", value: false },
  ]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    dispatch(
      fetchAccounts({ page, pageSize, filterBy, orderBy, searchParams })
    );
  }, [pageSize, page, orderBy, searchParams]);

  useEffect(() => {
    const params = new URLSearchParams();
    filterBy.forEach((item) => {
      params.set(item.column, item.value);
    });
    setSearchParams(params);
  }, [filterBy]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleChange = (field, value) => {
    if (field === "filterBy") {
      setFilterBy([...value, { column: "myCustomers", value: myCustomers }]);
      dispatch(setAccountTableSettings({ field: "page", value: 1 }));
    } else {
      dispatch(setAccountTableSettings({ field, value }));
    }
  };

  const handleShowMyChange = (_, checked) => {
    setMyCustomers(checked);
  };

  useEffect(() => {
    setFilterBy([
      ...filterBy.filter((item) => item.column != "myCustomers"),
      { column: "myCustomers", value: myCustomers },
    ]);
  }, [myCustomers]);

  useEffect(() => {
    return () => {
      dispatch(cleanTableSettings());
    };
  }, []);

  return (
    <>
      <PageLoading dataLoading={accountsLoading} />

      <Typography
        fontSize="23px"
        fontWeight={800}
        component="h2"
        data-testid="page-title"
      >
        Accounts
      </Typography>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        alignItems={{ xs: "space-between", sm: "center" }}
        justifyContent="space-between"
        spacing={1.5}
        my="25px"
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          spacing="15px"
          width="100%"
        >
          <Stack direction={"row"} spacing={1}>
            <Button onClick={handleOpen} variant="contained">
              Add New
            </Button>
            <Button
              variant="outlined"
              onClick={() =>
                downloadFile(
                  "/api/accounts/accounts_export/",
                  "accounts_export.csv"
                )
              }
            >
              Export
            </Button>
          </Stack>

          <Stack direction="row" alignItems="center">
            <Typography>Customers I manage</Typography>
            <Switch
              checked={myCustomers}
              onChange={handleShowMyChange}
              inputProps={{ "aria-label": "controlled" }}
            />
          </Stack>
        </Stack>
      </Stack>

      <Box className="table-wrapper">
        <DataTable
          rowData={accounts}
          columnDefs={accountTableColumns}
          defaultColDef={defaultColDef}
          loading={accountsLoading}
          suppressSorting={true} //disable default sorting
          rowHeight={53}
          headerHeight={60}
          onSortChanged={(event) =>
            handleChange(
              "orderBy",
              formatAgGridSorting(event.columnApi.columnModel.gridColumns)
            )
          }
          onFilterChanged={(event) => {
            handleChange(
              "filterBy",
              formatAgGridFilters(event.api.getFilterModel()),
              []
            );
          }}
          page={page}
          pageSize={pageSize}
          setPageSize={(pageSize) => handleChange("pageSize", pageSize)}
          noPages={numberOfPages}
          setPage={(page) => handleChange("page", page)}
        />
      </Box>
      <AddAccountForm open={open} handleClose={handleClose} />
    </>
  );
};

export default Accounts;
