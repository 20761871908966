import { useState } from "react";
import {
  Box,
  Button,
  Collapse,
  Divider,
  Stack,
  Typography,
  IconButton,
} from "@mui/material";
import { useModal } from "mui-modal-provider";
import AddOrdersModal from "./AddOrdersModal";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ReactComponent as Plus } from "../../images/plus.svg";
import { ReactComponent as Trash } from "../../images/trash-alt.svg";
import HtmlTooltip from "../../components/HtmlToolTip";
import ConfirmDialog from "../../components/ConfirmDialog";
import { deleteAutomaticOrder } from "../../distflowAPI/ordersApi";
import { fixedNumber } from "../../utils";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import dayjs from "dayjs";
import { DATE_FORMAT_FRONT } from "../../utils/constants";

const soldByValMapper = {
  PC: "Piece",
  BX: "Box",
  BK: "Bucket",
  BT: "Batter",
  CT: "Container",
  DZ: "Dozen",
  EA: "Each",
  LB: "Lb",
  PK: "Package",
  PL: "pale / bucket",
  SH: "Sheet",
};
const daysMapper = {
  mon: "Monday",
  tue: "Tuesday",
  wed: "Wednesday",
  thu: "Thursday",
  fri: "Friday",
  sat: "Saturday",
  sun: "Sunday",
};
const Product = (props) => {
  const { showModal } = useModal();
  const [expanded, setExpanded] = useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const item = props.item || { images: [], per_box: 0.0 };

  function getQtyRange() {
    const dt = props.orderData;
    const qtys = [];
    if (dt) {
      const days = ["mon", "tue", "wed", "thu", "fri", "sun"];
      days.map((d) =>
        dt[d + "_qty"] != null ? qtys.push(dt[d + "_qty"]) : ""
      );
    }
    const min = Math.min(...qtys);
    const max = Math.max(...qtys);
    return { min, max };
  }
  const [confirmOpen, setConfirmOpen] = useState(false);
  function handleDelete(event) {
    deleteAutomaticOrder(props.orderData, props.day).then((res) => {
      props.updateAccountRelated();
    });
  }
  function overrideText(override) {
    if (override.territory) {
      return override.territory;
    }
    if (override.account) {
      return override.account;
    }
    if (override.customer) {
      return override.customer;
    }
    if (override.category) {
      return override.category;
    }
    return "other";
  }
  function isOrdered(item) {
    if (item.break_into_pieces) {
      return item.ordered && item.ordered_pc;
    }
    if (item.sold_by == "PC") {
      return item.ordered_pc;
    }
    return item.ordered;
  }

  const isAutoConfirm = props.day
    ? props.orderData[`${props.day}_auto_confirm`]
    : false;

  let todaysDateName = dayjs().format("ddd").toLowerCase();
  try {
    if (props.date && props.date?.isValid()) {
      todaysDateName = props.date.format("ddd").toLowerCase();
    }
  } catch (e) {}

  const checkAvailability = () => {
    const day = `avail_${todaysDateName}`;
    return item[day];
  };

  return (
    <Stack
      data-testid={`product-${item.id}`}
      bgcolor={props.child ? "#F4F4F4" : "#fff"}
      border="1px solid #DBDFEA"
      borderBottom={isAutoConfirm ? "3px solid #3FC6A5" : "1px solid #DBDFEA"}
      borderRadius="5px"
      p="11px"
    >
      <Stack direction="row" justifyContent="space-between">
        <Box maxWidth="400px">
          <Stack direction="row" alignItems="center" gap="15px" mb="15px">
            <Box maxWidth="32px" display="flex" flexShrink={0}>
              <img
                src={
                  item.images.length > 0
                    ? "http://3.133.159.145" +
                      item.images[0].image.replace("http://3.133.159.145", "")
                    : ""
                }
                alt=""
              />
            </Box>
            <Typography fontFamily="Roboto" color="#000" lineHeight="16px">
              {item.name}{" "}
              {props.orderData?.unit && (
                <small>({soldByValMapper[props.orderData?.unit]})</small>
              )}
            </Typography>
            <Box sx={{ ml: 1 }}>
              {checkAvailability() ? (
                <Typography
                  fontFamily="Roboto"
                  fontSize="10px"
                  fontWeight="500"
                  style={{
                    color: "green",
                    backgroundColor: "#E6F4EA",
                    padding: "2px 5px",
                    borderRadius: "5px",
                  }}
                >
                  Available Today
                </Typography>
              ) : (
                <Typography
                  fontFamily="Roboto"
                  fontSize="10px"
                  fontWeight="500"
                  style={{
                    color: "red",
                    padding: "2px 5px",
                    borderRadius: "5px",
                    backgroundColor: "#FDE8E8",
                  }}
                >
                  Not Available Today
                </Typography>
              )}
            </Box>
            {!item.saleable && (
              <Box sx={{ ml: 1 }}>
                <Typography
                  fontFamily="Roboto"
                  fontSize="10px"
                  fontWeight="500"
                  style={{
                    color: "red",
                    backgroundColor: "#FDE8E8",
                    padding: "2px 5px",
                    borderRadius: "5px",
                  }}
                >
                  Non Saleable
                </Typography>
              </Box>
            )}
            {item.delivery_type === "SALES_MAN" && (
              <Box sx={{ ml: 0.3 }}>
                <Typography
                  fontFamily="Roboto"
                  fontSize="10px"
                  fontWeight="500"
                  style={{
                    color: "red",
                    backgroundColor: "#FDE8E8",
                    padding: "2px 5px",
                    borderRadius: "5px",
                  }}
                >
                  SALESMAN ONLY
                </Typography>
              </Box>
            )}
          </Stack>
          <Box display="grid" gridTemplateColumns="1fr 1fr">
            <Box
              borderRight="1px solid #D9D9D9"
              sx={{ wordBreak: "break-all" }}
            >
              <Typography fontFamily="Roboto" fontSize="12px" color="#000">
                {item.vendor}
              </Typography>
              <Typography fontFamily="Roboto" fontSize="12px" color="#B5B5B5">
                {item.category}
              </Typography>
              {props.orderData &&
                props.orderData?.disabled_from &&
                (!props.orderData?.disabled_till ||
                  (props.orderData?.disabled_till &&
                    dayjs(props.orderData?.disabled_till).isValid() &&
                    dayjs(props.orderData?.disabled_till).isAfter(
                      dayjs()
                    ))) && (
                  <Typography fontFamily="Roboto" fontSize="12px" color="red">
                    disabled from{" "}
                    {dayjs(props.orderData?.disabled_from).format(
                      DATE_FORMAT_FRONT
                    )}
                  </Typography>
                )}
            </Box>
            <Box paddingLeft="17px" sx={{ wordBreak: "break-all" }}>
              <Typography fontFamily="Roboto" fontSize="12px" color="#7A7A7A">
                Sold by:{" "}
                <Typography
                  fontFamily="Roboto"
                  fontSize="12px"
                  component="span"
                  color="#000000"
                >
                  {soldByValMapper[item.sold_by]}
                  {item.break_into_pieces ? "/Piece" : ""}
                </Typography>
              </Typography>
              <Typography fontSize="12px">{item.sku}</Typography>
              {props.orderData &&
                props.orderData?.disabled_till &&
                dayjs(props.orderData?.disabled_till).isValid() &&
                dayjs(props.orderData?.disabled_till).isAfter(dayjs()) && (
                  <Typography fontFamily="Roboto" fontSize="12px" color="red">
                    disabled Until{" "}
                    {dayjs(props.orderData?.disabled_till).format(
                      DATE_FORMAT_FRONT
                    )}
                  </Typography>
                )}
            </Box>
          </Box>
        </Box>

        <Divider
          orientation="vertical"
          sx={{ height: "auto", marginX: "14px" }}
        />

        <Stack
          minWidth="110px"
          alignItems={props.multiple ? "center" : "flex-end"}
          justifyContent="space-between"
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent={props.multiple ? "center" : "flex-end"}
            gap="10px"
          >
            <Typography fontFamily="Roboto" fontSize="12px" color="#7A7A7A">
              {item.sold_by_qty} pcs
            </Typography>
            <HtmlTooltip
              title={
                <div>
                  {item?.price?.overrides.map((override, index) => (
                    <Typography
                      color="inherit"
                      key={override.id || index}
                      fontWeight={override.isCost ? 600 : 400}
                    >
                      {override.type}: {overrideText(override)}({override.level}
                      ):{override.isCost && <>$</>}
                      {fixedNumber(override.value)}
                      {!override.isCost && <>%</>}
                    </Typography>
                  ))}
                  {props.orderData?.price?.overrides.map((override, index) => (
                    <Typography
                      color="inherit"
                      key={override.id || index}
                      fontWeight={override.isCost ? 600 : 400}
                    >
                      {override.type}: {overrideText(override)}({override.level}
                      ):{override.isCost && <>$</>}
                      {fixedNumber(override.value)}
                      {!override.isCost && <>%</>}
                    </Typography>
                  ))}
                </div>
              }
            >
              <Typography fontFamily="Roboto" fontWeight="500">
                $
                {isNaN(Number(item.price?.price))
                  ? ""
                  : fixedNumber(item.price?.price)}
                {isNaN(Number(props.orderData?.price?.price))
                  ? ""
                  : fixedNumber(props.orderData?.price?.price)}
              </Typography>
            </HtmlTooltip>
          </Stack>
          {!props.showView && (
            <IconButton
              onClick={() => {
                const newTab = window.open(
                  `/products/${item.product}/variants/${item.id}`,
                  "_blank"
                );
                newTab.noopener = true;
              }}
            >
              <OpenInNewIcon />
            </IconButton>
          )}

          {item.break_into_pieces && (
            <Typography fontFamily="Roboto" fontSize="12px" color="#7A7A7A">
              <b>
                $
                {fixedNumber(
                  (item.price?.price || props.orderData?.price?.price) /
                    item.sold_by_qty
                )}
              </b>{" "}
              per piece
            </Typography>
          )}
          {props.multiple ? (
            <>
              <Stack
                height="25px"
                direction="row"
                gap="3px"
                sx={{
                  "& button": {
                    fontSize: "10px",
                    fontWeight: 500,
                    minWidth: "auto",
                    p: "6px",
                  },
                }}
              >
                <Button variant="contained" sx={{ height: "25px !important" }}>
                  View
                </Button>
                <Button
                  variant="contained"
                  color="info"
                  sx={{ height: "25px !important" }}
                  onClick={() =>
                    isOrdered(item)
                      ? ""
                      : showModal(AddOrdersModal, {
                          item: item,
                          account: props.account,
                          updateAccountRelated: props.updateAccountRelated,
                          orderData: props.orderData,
                        })
                  }
                >
                  Edit
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  sx={{
                    width: "25px",
                    height: "25px !important",
                    backgroundColor: "#DBDFEA",
                    p: "0 !important",
                    ":hover svg": {
                      fill: "#fff",
                    },
                  }}
                  onClick={(e) => setConfirmOpen(true)}
                >
                  <Trash width={12} height={12} />
                </Button>
                <ConfirmDialog
                  title="Delete item?"
                  open={confirmOpen}
                  setOpen={setConfirmOpen}
                  onConfirm={handleDelete}
                >
                  Are you sure you want to delete this item from{" "}
                  {props.day == "all"
                    ? "all days"
                    : daysMapper[props.day.toLowerCase()]}
                  ?
                </ConfirmDialog>
              </Stack>
              {props.day &&
                props.day != "all" &&
                props.orderData[`${props.day}_next_order`] && (
                  <Typography fontSize="12px" mt={1}>
                    <b>Next:</b>{" "}
                    {dayjs(props.orderData[`${props.day}_next_order`]).format(
                      DATE_FORMAT_FRONT
                    )}
                  </Typography>
                )}

              <Typography fontSize="12px" fontWeight={500} color="#7A7A7A">
                qty{" "}
                <Typography
                  fontSize="12px"
                  fontWeight={500}
                  color="#000"
                  component="span"
                >
                  {props.orderData[`${props.day}_qty`] ||
                    `${getQtyRange().min}-${getQtyRange().max}`}{" "}
                  {props.orderData?.advanced_configs?.length > 0
                    ? `(${props.orderData?.advanced_configs?.length} advanced configs)`
                    : ""}
                </Typography>
              </Typography>
              {!item.saleable && (
                <Typography color={"red"} fontSize={12}>
                  Not saleable
                </Typography>
              )}
            </>
          ) : (
            <Stack direction="row" gap="10px">
              {props.parent && props.item?.related?.length ? (
                <Button
                  variant="text"
                  endIcon={<ExpandMoreIcon />}
                  onClick={handleExpandClick}
                  sx={{
                    fontSize: "10px",
                    fontWeight: 500,
                    color: "#000",
                    p: 0,
                    "& svg": {
                      fontSize: "17px",
                      transform: !expanded ? "rotate(0deg)" : "rotate(180deg)",
                    },
                    "& .MuiButton-endIcon": {
                      marginLeft: "2px",
                    },
                    ":hover": {
                      bgcolor: "transparent",
                    },
                  }}
                >
                  Related
                </Button>
              ) : null}
              {props.hideAdd
                ? ""
                : props.customAdd &&
                  !item.ordered && (
                    <Button
                      onClick={() =>
                        isOrdered(item)
                          ? ""
                          : props.customAdd({
                              item: item,
                              account: props.account,
                              updateAccountRelated: props.updateAccountRelated,
                            })
                      }
                      variant="contained"
                      color="success"
                      /*  sx={{
                        minWidth: "auto",
                        fontSize: "14px",
                        fontWeight: 500,
                        color: "#333",
                        padding: "2.5px 5px",
                        "& .MuiButton-startIcon": {
                          marginLeft: 0,
                        },
                        ":hover svg": {
                          fill: "#fff",
                        },
                      }} */
                      disabled={(item?.restrictions || []).length > 0}
                    >
                      {
                        (item?.restrictions || []).length > 0
                          ? "RESTRICTED"
                          : "Add" /* add when clicking enter */
                      }
                      {item.break_into_pieces && (
                        <small> ({item.sold_by})</small>
                      )}
                    </Button>
                  )}
              {props.hideAdd
                ? ""
                : !props.customAdd &&
                  !item.ordered && (
                    <Button
                      onClick={() =>
                        isOrdered(item)
                          ? ""
                          : showModal(AddOrdersModal, {
                              item: item,
                              account: props.account,
                              updateAccountRelated: props.updateAccountRelated,
                              orderData: props.orderData,
                            })
                      }
                      variant="contained"
                      color="success"
                      /*      sx={{
                        minWidth: "auto",
                        fontSize: "14px",
                        fontWeight: 500,
                        color: "#333",
                        padding: "2.5px 5px",
                        ":hover svg": {
                          fill: "#fff",
                        },
                      }} */
                      disabled={(item?.restrictions || []).length > 0}
                    >
                      {(item?.restrictions || []).length > 0
                        ? "RESTRICTED"
                        : "Add"}
                      {item.break_into_pieces && (
                        <small> ({item.sold_by})</small>
                      )}
                    </Button>
                  )}
              {props.customAdd &&
                !item.ordered_pc &&
                item.break_into_pieces && (
                  <Button
                    onClick={() =>
                      isOrdered(item)
                        ? ""
                        : props.customAdd({
                            item: item,
                            unit: "PC",
                            account: props.account,
                            updateAccountRelated: props.updateAccountRelated,
                          })
                    }
                    variant="contained"
                    color="success"
                    /* sx={{
                      minWidth: "auto",
                      fontSize: "14px",
                      fontWeight: 500,
                      color: "#333",

                      padding: "2.5px 5px",
                      "& .MuiButton-startIcon": {
                        marginLeft: 0,
                      },
                      ":hover svg": {
                        fill: "#fff",
                      },
                    }} */
                  >
                    Add piece
                  </Button>
                )}
              {props.showView ? (
                <Button
                  variant="outlined"
                  onClick={() => {
                    window.open(
                      `/products/${item.product}/variants/${item.id}`,
                      "_blank"
                    );
                  }}
                >
                  View
                </Button>
              ) : (
                ""
              )}
              {props.customAdd &&
                (item?.restrictions || []).length == 0 &&
                item.customizable && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() =>
                      props.customAdd({
                        item: item,
                        account: props.account,
                        updateAccountRelated: props.updateAccountRelated,
                        custom: true,
                      })
                    }
                    disabled={(item?.restrictions || []).length > 0}
                  >
                    {(item?.restrictions || []).length > 0
                      ? "RESTRICTED"
                      : "Customize"}
                  </Button>
                )}
            </Stack>
          )}
          {props.orderDetails ? (
            <Stack>
              <Typography
                fontFamily="Roboto"
                fontWeight="500"
                fontSize="14px"
                textAlign={"end"}
              >
                Quantity: {props.orderDetails.qty}
              </Typography>

              <Typography
                fontFamily="Roboto"
                fontWeight="500"
                fontSize="14px"
                textAlign={"end"}
              >
                Total: $
                {Number(
                  props.orderDetails.qty * props.orderDetails.price
                ).toFixed(2)}
              </Typography>
            </Stack>
          ) : (
            ""
          )}
        </Stack>
      </Stack>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Stack gap="9px" mt="15px">
          {props.children}
        </Stack>
      </Collapse>
    </Stack>
  );
};

export default Product;
