import { Link, useNavigate } from "react-router-dom";
import { Button, IconButton, Stack } from "@mui/material";
import { removeUser } from "../../redux/module/user";
import useAppDispatch from "../../hooks/useAppDispatch";
import TableButtons from "../../components/TableComponents/TableButtons";
import { ReactComponent as OpenIc } from "../../images/open-ic.svg";
import { deleteManager } from "../../distflowAPI/managersApi";

export default function getCols(extraParams){
  const ItemsRenderer = (params) => {
  const navigate = useNavigate();
  // const handleNavigateToClonedOrder = (date, account) => {
  //   navigate(`/orders?date=${dayjs.utc(date).format(DATE_FORMAT)}&search=${account}`)
  // }

  const handleAccountClick = (id) => {
    navigate(`/accounts/${id}`);
  };

  return (
    <Stack direction={"row"}>
      {params.data?.accounts?.map((account) => (
        <Button
          size="small"
          variant="gray"
          endIcon={<OpenIc />}
          sx={{
            bgcolor: "#F5F5F5",
            border: "1px solid #DBDFEA !important",
            padding: "0 9px !important",
            fontSize: "14px !important",
            marginRight: 1,
          }}
          onClick={() => handleAccountClick(account.id)}
        >
          {account.name}
        </Button>
      ))}
    </Stack>
  );
};

const AccountingManagersRenderer = (params) => {
  const navigate = useNavigate();
  // const handleNavigateToClonedOrder = (date, account) => {
  //   navigate(`/orders?date=${dayjs.utc(date).format(DATE_FORMAT)}&search=${account}`)
  // }

  const handleAccountClick = (id) => {
    navigate(`/accounts/${id}`);
  };

  return (
    <Stack direction={"row"}>
      {params.data?.accounting_managers?.map((accountingManager) => (
        <Button
          size="small"
          variant="gray"
          endIcon={<OpenIc />}
          sx={{
            bgcolor: "#F5F5F5",
            border: "1px solid #DBDFEA !important",
            padding: "0 9px !important",
            fontSize: "14px !important",
            marginRight: 1,
          }}
          onClick={() => handleAccountClick(accountingManager.id)}
        >
          {accountingManager.name}
        </Button>
      ))}
    </Stack>
  );
};

function ManagerLinkRenderer(props) {
  const dispatch = useAppDispatch();
  const handleDelete = async () => {
    //await dispatch(removeVendor(props.data.id));
    await deleteManager(props.data.id)
    extraParams.refreshTable()
  };
  return (
    <TableButtons
      link={`/managers/${props.data.id}`}
      handleDelete={handleDelete}
      deletePopupEntity="manager"
      historyData={props.data.history || []}
    />
  );
}

const first_name = {
  headerName: "First Name",
  field: "user__first_name",
  valueGetter: (params) => params.data.user.first_name,
  filter: true,
  filterParams: {
    filterOptions: ["contains"],
    suppressAndOrCondition: true,
  },
  floatingFilter: true,
  sortable: true,
  flex: 2,
  comparator: () => {},
};
const last_name = {
  headerName: "Last Name",
  field: "user__last_namename",
  valueGetter: (params) => params.data.user.last_name,
  filter: true,
  filterParams: {
    filterOptions: ["contains"],
    suppressAndOrCondition: true,
  },
  floatingFilter: true,
  sortable: true,
  flex: 2,
  comparator: () => {},
};
const email = {
  headerName: "Email",
  field: "user__email",
  valueGetter: (params) => params.data.user.email,
  filter: true,
  filterParams: {
    filterOptions: ["contains"],
    suppressAndOrCondition: true,
  },
  floatingFilter: true,
  sortable: true,
  flex: 3,
  comparator: () => {},
};
const accounting_managers = {
  headerName: "Accounting Managers",
  field: "accounting_managers__name",
  cellRenderer: AccountingManagersRenderer,
  filter: true,
  filterParams: {
    filterOptions: ["contains"],
    suppressAndOrCondition: true,
  },
  floatingFilter: true,
  sortable: true,
  flex: 3,
  comparator: () => {},
};

const manager_accounts = {
  headerName: "Accounts",
  field: "accounts__name",
  wrapText: true,
  autoHeight: true,
  cellRenderer: ItemsRenderer,
  filter: true,
  filterParams: {
    filterOptions: ["contains"],
    suppressAndOrCondition: true,
    textMatcher: (filterValue, cellValue) => true,
  },
  floatingFilter: true,
  cellStyle: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  flex: 6,
  sortable: true,
  comparator: () => {},
};

const manager_link = {
  field: "",
  sorting: false,
  sort: false,
  sortable: false,
  autoHeight: true,
  filter: false,
  cellRenderer: ManagerLinkRenderer,
  flex: 1,
  minWidth: 120,
};

return [
  first_name,
  last_name,
  email,
  manager_accounts,
  accounting_managers,
  manager_link,
]
}


