import { useState, useEffect } from "react";
import DataTable from "../../components/DataTable";
import payoutsCols from "./payoutsCols";
import { listInvoices } from "../../distflowAPI/invoicesApi";
import { Link, NavLink } from "react-router-dom";
import {
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
} from "@mui/material";
import dayjs from "dayjs";
import { DATE_FORMAT_FRONT } from "../../utils/constants";
import { getNoPages } from "../../utils";
import DatePickerCustom2 from "../../components/DatePicker2";

function AccountNameRenderer(params) {
  return (
    <Stack direction={"row"} sx={{ width: "100%", flexWrap: "wrap" }} p={1}>
      <Link
        to={`/accounts/${params.data.account.id}`}
        style={{ textDecoration: "none", display: "flex", width: "100%" }}
      >
        {params.data.account.name}
      </Link>
    </Stack>
  );
}
function InvoiceNameRenderer(params) {
  return (
    <span className="my-renderer">
      <Link
        to={`/invoices/invoices/${params.data.id}`}
        style={{ textDecoration: "none" }}
      >
        {params.data?.po_id
          ? `PO# ${params.data.po_id}`
          : `# ${params.data.id}`}
      </Link>
    </span>
  );
}

const invoice_id = {
  headerName: "Invoice",
  sortable: true,
  comparator: () => {},
  cellRendererSelector: () => {
    return {
      component: InvoiceNameRenderer,
    };
  },
  minWidth: 120,
  maxWidth: 120,
};

const INVOICES_STATUS = {
  PAID: "Paid",
  UNPAID: "Unpaid",
  PARTIALLY_PAID: "Partially Paid",
  VOIDED: "Voided",
  CREDIT: "Credit",
};
const invoice_status = {
  sortable: true,
  comparator: () => {},
  wrapText: true,
  minWidth: 110,
  maxWidth: 110,
  headerName: "Status",
  field: "status",
  valueGetter: (params) => `${INVOICES_STATUS[params.data.status]}`,
};
const invoice_created_date = {
  sortable: true,
  comparator: () => {},
  //filter: true,
  //floatingFilter: true,
  minWidth: 130,
  maxWidth: 130,
  headerName: "Created",
  field: "issued_date",
  valueGetter: (params) =>
    dayjs.utc(params.data.issued_date).format(DATE_FORMAT_FRONT),
};

const invoice_total_amount = {
  sortable: true,
  comparator: () => {},
  //filter: true,
  //floatingFilter: true,
  minWidth: 100,
  maxWidth: 120,
  headerName: "Total",
  field: "total_amount",
  valueGetter: (params) => `$${params.data.total_amount}`,
};

const amount_paid = {
  sortable: true,
  comparator: () => {},
  minWidth: 100,
  maxWidth: 120,
  headerName: "Paid",
  field: "total_paid",
  valueGetter: (params) => `$${params.data.total_paid}`,
};
const amount_remaining = {
  sortable: true,
  comparator: () => {},
  minWidth: 100,
  maxWidth: 120,
  headerName: "Balance",
  field: "balance",
  valueGetter: (params) => `$${params.data.balance}`,
};
const account_name = {
  headerName: "Account",
  wrapText: true,
  autoHeight: true,
  sortable: true,
  comparator: () => {}, //disable default sorting
  filterParams: {
    filterOptions: ["contains"],
    suppressAndOrCondition: true,
  },
  field: "account__name",
  //filter: true,
  //floatingFilter: true,
  valueGetter: (params) => {
    return `${params.data.account.name}`;
  },
  cellRendererSelector: () => {
    return {
      component: AccountNameRenderer,
    };
  },
  minWidth: 300,
};
const invoiceTableColumns = [
  invoice_id,
  account_name,
  invoice_total_amount,
  amount_paid,
  amount_remaining,
  invoice_status,
  invoice_created_date,
];
export default function SalesManInvoices({ salesManId }) {
  const [invoices, setInvoices] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [noPages, setNoPages] = useState(1);
  const [filterBy, setFilterBy] = useState({
    column: "sales_man",
    value: salesManId,
  });
  useEffect(() => {
    const filtersList = Object.keys(filterBy).map((fk) => {
      return {
        column: fk,
        value: filterBy[fk],
      };
    });
    listInvoices(1, 50, filtersList).then((res) => {
      setInvoices(res.data.results);
      setNoPages(getNoPages(res.data.cout, pageSize));
    });
  }, [salesManId, page, pageSize, filterBy]);
  return (
    <Stack spacing={2}>
      <FilterComponent filterBy={filterBy} setFilterBy={setFilterBy} />
      <DataTable
        columnDefs={invoiceTableColumns}
        rowData={invoices}
        page={page}
        pageSize={pageSize}
        setPage={(p) => setPage(p)}
        setPageSize={(ps) => setPageSize(ps)}
        noPages={noPages}
      />
    </Stack>
  );
}

function FilterComponent({ filterBy, setFilterBy }) {
  const [minDate, setMinDate] = useState(dayjs().subtract(7, "days"));
  const [maxDate, setMaxDate] = useState(dayjs());
  function setFilterStatus(value) {
    const newFilter = { ...filterBy };
    newFilter["status"] = value;
    setFilterBy(newFilter);
  }

  function setMinDateFilter(value) {
    const newFilter = { ...filterBy };
    newFilter["created__gte"] = minDate.format("YYYY-MM-DD");
    setFilterBy(newFilter);
  }
  function setMaxDateFilter(value) {
    const newFilter = { ...filterBy };
    newFilter["created__lt"] = maxDate.format("YYYY-MM-DD");
    setFilterBy(newFilter);
  }
  useEffect(() => {
    setMinDateFilter(minDate);
  }, [minDate]);
  useEffect(() => {
    setMaxDateFilter(maxDate);
  }, [maxDate]);
  return (
    <Stack direction={"row"} spacing={2}>
      <TextField
        label={"account search"}
        value={filterBy.search}
        onChange={(e) =>
          setFilterBy({
            ...filterBy,
            search: e.target.value,
          })
        }
      />
      <RadioGroup
        value={filterBy["status"]}
        onChange={(e) => setFilterStatus(e.target.value)}
        row
      >
        <FormControlLabel control={<Radio />} label={"All"} value={""} />
        <FormControlLabel control={<Radio />} label={"Paid"} value={"PAID"} />
        <FormControlLabel
          control={<Radio />}
          label={"UnPaid"}
          value={"UNPAID"}
        />
        <FormControlLabel
          control={<Radio />}
          label={"Partially paid"}
          value={"PARTIALLY_PAID"}
        />
      </RadioGroup>
      <DatePickerCustom2
        label={"min date"}
        controlledValue={minDate}
        setControlledValue={setMinDate}
        maxWidth={180}
      />
      <DatePickerCustom2
        label={"max date"}
        controlledValue={maxDate}
        setControlledValue={setMaxDate}
        maxWidth={180}
      />
    </Stack>
  );
}
