import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import customersApi, {
  deleteCustomerEmail,
  deleteCustomerOverride,
  deleteCustomerPhone,
  getCustomerEmails,
  getCustomerFinancials,
  getCustomerOverrides,
  getCustomerPhones,
  patchCustomerEmail,
  patchCustomerOverride,
  patchCustomerPhone,
  postCustomerEmail,
  postCustomerOverride,
  postCustomerPhone,
} from "../../distflowAPI/customersApi";
import { signOut } from "./user";
import { fireErrorNotification, fireSuccessNotification } from "../../utils";
import accountsApi from "../../distflowAPI/accountsApi";
import actualOrdersApi, {
  listAccountAutomaticOrders,
} from "../../distflowAPI/ordersApi";
import invoicesApi from "../../distflowAPI/invoicesApi";
import paymentsApi from "../../distflowAPI/paymentsApi";

/**
 * @typedef {Object} Customer
 * @property {number} Customer.id
 * @property {string} Customer.name
 * @property {string} Customer.created_at
 * @property {string} Customer.updated_at
 * @property {{
 *   "id": number,
 *   "email": string;
 *   "first_name": string;
 *   "last_name": string;
 *   "is_active": boolean;
 * }} Customer.owner // TODO: define type
 * @property {number} Customer.accounts_count
 */

/**
 * @type {{
 *  customer: Customer;
 *  customers: Array<Customer>;
 *  customersLoading: boolean;
 *  pageSize: number;
 *  page: number;
 *  orderBy: [];
 *  filterBy: {};
 *  totalCount: number;
 *  createLoading: boolean;
 * }}
 */
const initialState = {
  customer: {},
  customerOverrides: [],
  customers: [],
  customerAccounts: [],
  customerInvoices: [],
  customerAutoOrders: [],
  customerActualOrders: [],
  customerPayments: [],
  customerFinancials: {},
  customersLoading: false,
  customerEmailsLoading: false,
  customerOverridesLoading: false,
  selectedTab: "1",
  pageSize: 50,
  page: 1,
  orderBy: [{ field: "internal_id", sort: "asc" }],
  filterBy: [],
  totalCount: 0,
  createLoading: false,
};

export const fetchCustomers = createAsyncThunk(
  "customer/fetchCustomers",
  async (data) => {
    const { page, pageSize, filterBy, orderBy } = data;
    return await customersApi.list(pageSize, page, orderBy, filterBy);
  },
);

export const createCustomer = createAsyncThunk(
  "customer/createCustomer",
  async (customer, { rejectWithValue }) => {
    try {
      const res = await customersApi.create(customer);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);
export const createCustomerEmails = createAsyncThunk(
  "customer/createCustomerEmails",
  async (data, { rejectWithValue }) => {
    const { customerId, payload } = data;
    try {
      const res = await postCustomerEmail(customerId, payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);
export const createCustomerPhones = createAsyncThunk(
  "customer/createCustomerPhones",
  async (data, { rejectWithValue }) => {
    const { customerId, payload } = data;
    try {
      const res = await postCustomerPhone(customerId, payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const updateCustomerEmails = createAsyncThunk(
  "customer/updateCustomerEmails",
  async (data, { rejectWithValue }) => {
    const { customerId, id, payload } = data;
    try {
      const res = await patchCustomerEmail(customerId, id, payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);
export const updateCustomerPhones = createAsyncThunk(
  "customer/updateCustomerPhones",
  async (data, { rejectWithValue }) => {
    const { customerId, payload, id } = data;
    try {
      const res = await patchCustomerPhone(customerId, id, payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const removeCustomerEmails = createAsyncThunk(
  "customer/removeCustomerEmails",
  async (data, { rejectWithValue }) => {
    const { customerId, id } = data;
    try {
      const res = await deleteCustomerEmail(customerId, id);
      return id;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);
export const removeCustomerPhones = createAsyncThunk(
  "customer/removeCustomerPhones",
  async (data, { rejectWithValue }) => {
    const { customerId, id } = data;
    try {
      const res = await deleteCustomerPhone(customerId, id);
      return id;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const fetchCustomer = createAsyncThunk(
  "customer/fetchCustomer",
  async (customerId) => {
    const res = await customersApi.findById(customerId);
    return res.data;
  },
);
export const fetchCustomerAccounts = createAsyncThunk(
  "customer/fetchCustomerAccounts",
  async (customerId) => {
    const res = await accountsApi.list(
      100,
      1,
      [],
      [{ column: "customer", value: customerId }],
    );
    return res.results;
  },
);

export const removeCustomerAccount = createAsyncThunk(
  "customer/removeCustomerAccount",
  async (accountId, { rejectWithValue }) => {
    try {
      const res = await accountsApi.delete(accountId);
      return accountId;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const fetchCustomerAutoOrders = createAsyncThunk(
  "customer/fetchCustomerAutoOrders",
  async (data) => {
    const { accountId, day } = data;
    const res = await listAccountAutomaticOrders(accountId, day);
    return res.data.results;
  },
);
export const fetchCustomerActualOrders = createAsyncThunk(
  "customer/fetchCustomerActualOrders",
  async (data) => {
    const { page, pageSize, filterBy, orderBy, searchParams } = data;
    return await actualOrdersApi.list(
      pageSize,
      page,
      orderBy,
      filterBy,
      searchParams,
    );
  },
);
export const fetchCustomerInvoices = createAsyncThunk(
  "customer/fetchCustomerInvoices",
  async (data) => {
    const { page, pageSize, filterBy, orderBy, searchParams } = data;
    return await invoicesApi.list(
      pageSize,
      page,
      orderBy,
      filterBy,
      searchParams,
    );
  },
);
export const fetchCustomerFinancials = createAsyncThunk(
  "customer/fetchCustomerFinancials",
  async (castomerId) => {
    const filter = [{ column: "customer_id", value: castomerId }];
    const res = await getCustomerFinancials(castomerId);
    return res.data;
  },
);
export const fetchCustomerPayments = createAsyncThunk(
  "customer/fetchCustomerPayments",
  async (data) => {
    const { page, pageSize, filterBy, orderBy, searchParams } = data;
    return await paymentsApi.list(
      pageSize,
      page,
      orderBy,
      filterBy,
      searchParams,
    );
  },
);
export const fetchCustomerEmails = createAsyncThunk(
  "customer/fetchCustomerEmails",
  async (id) => {
    const res = await getCustomerEmails(id);
    return res.data.results;
  },
);
export const fetchCustomerPhones = createAsyncThunk(
  "customer/fetchCustomerPhones",
  async (id) => {
    const res = await getCustomerPhones(id);
    return res.data.results;
  },
);
export const updateCustomer = createAsyncThunk(
  "customer/updateCustomer",
  async (data, { rejectWithValue }) => {
    const { customerId, customerInfo } = data;
    try {
      const res = await customersApi.update(customerId, customerInfo);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const removeCustomer = createAsyncThunk(
  "customer/removeUser",
  async (customerId, { rejectWithValue }) => {
    try {
      const res = await customersApi.delete(customerId);
      return customerId;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const fetchCustomerOverrides = createAsyncThunk(
  "customer/fetchCustomerOverrides",
  async (customerId) => {
    const res = await getCustomerOverrides(customerId);
    return res.data.results;
  },
);

export const createCustomerOverride = createAsyncThunk(
  "customer/createCustomerOverride",
  async (data, { rejectWithValue }) => {
    const { id, ...payload } = data;
    try {
      const res = await postCustomerOverride(id, payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const updateCustomerOverride = createAsyncThunk(
  "customer/updateCustomerOverride",
  async (data, { rejectWithValue }) => {
    const { id, overrideId, payload } = data;
    try {
      const res = await patchCustomerOverride(id, overrideId, payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const removeCustomerOverride = createAsyncThunk(
  "customer/removeCustomerOverride",
  async (data) => {
    const { id, overrideId } = data;
    const res = await deleteCustomerOverride(id, overrideId);
    return { overrideId };
  },
);

export const customerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    setCustomerTableSettings(state, { payload }) {
      state[payload.field] = payload.value;
    },
    setSelectedTab(state, { payload }) {
      state.selectedTab = payload;
    },
    cleanTableSettings(state) {
      state.pageSize = 50;
      state.page = 1;
      state.orderBy = state.orderBy.length
        ? [{ field: "internal_id", sort: "asc" }]
        : state.orderBy;
      state.filterBy = state.filterBy.length
        ? []
        : state.filterBy;
    },
    cleanCustomerAutoOrders(state) {
      state.customerAutoOrders = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCustomerAccounts.fulfilled, (state, action) => {
        state.customerAccounts = action.payload;
      })
      .addCase(fetchCustomerAutoOrders.pending, (state, action) => {
        state.customersLoading = true;
      })
      .addCase(fetchCustomerAutoOrders.fulfilled, (state, action) => {
        state.customerAutoOrders = action.payload;
        state.customersLoading = false;
      })
      .addCase(fetchCustomerActualOrders.pending, (state, action) => {
        state.customersLoading = true;
      })
      .addCase(fetchCustomerActualOrders.fulfilled, (state, action) => {
        state.customerActualOrders = action.payload.results;
        state.totalCount = action.payload.count;
        state.customersLoading = false;
      })
      .addCase(fetchCustomerInvoices.pending, (state, action) => {
        state.customersLoading = true;
      })
      .addCase(fetchCustomerInvoices.fulfilled, (state, action) => {
        state.customerInvoices = action.payload.results;
        state.totalCount = action.payload.count;
        state.customersLoading = false;
      })
      .addCase(fetchCustomerFinancials.fulfilled, (state, action) => {
        state.customerFinancials = action.payload;
      })
      .addCase(fetchCustomerPayments.pending, (state, action) => {
        state.customersLoading = true;
      })
      .addCase(fetchCustomerPayments.fulfilled, (state, action) => {
        state.customerPayments = action.payload.results;
        state.totalCount = action.payload.count;
        state.customersLoading = false;
      })

      // all customers
      .addCase(fetchCustomers.pending, (state) => {
        state.customersLoading = true;
      })
      .addCase(fetchCustomers.fulfilled, (state, action) => {
        state.customersLoading = false;
        state.customers = action.payload.results;
        state.totalCount = action.payload.count;
      })
      .addCase(fetchCustomers.rejected, (state) => {
        state.customersLoading = false;
      })

      //customer CRUD
      .addCase(fetchCustomer.pending, (state, action) => {
        state.customersLoading = true;
      })
      .addCase(fetchCustomer.fulfilled, (state, action) => {
        state.customer = action.payload;
        state.customersLoading = false;
      })
      .addCase(createCustomer.pending, (state) => {
        state.createLoading = true;
      })
      .addCase(createCustomer.fulfilled, (state, action) => {
        state.createLoading = false;
        state.customer = action.payload;
        fireSuccessNotification(
          `Customer "${action.meta.arg.name}" created successfully`,
        );
        state.totalCount = state.totalCount + 1;
        if (state.customers.length < state.pageSize) {
          state.customers = [...state.customers, action.payload];
        }
      })
      .addCase(createCustomer.rejected, (state, action) => {
        state.createLoading = false;
        const errorFields = Object.keys(action.payload);
        errorFields.forEach((field) => {
          if (field === "owner") {
            const errorOwnerFields = Object.keys(action.payload[field]);
            errorOwnerFields.forEach((ownerField) => {
              fireErrorNotification(
                `${ownerField}: ${action.payload[field][ownerField][0]}`,
              );
            });
          } else {
            fireErrorNotification(`${field}: ${action.payload[field][0]}`);
          }
        });
      })
      .addCase(updateCustomer.fulfilled, (state, action) => {
        state.customer = { ...action.payload, phones: state.customer.phones };
        fireSuccessNotification(
          `Customer "${action.meta.arg.customerInfo.name}" updated successfully`,
        );
      })
      .addCase(updateCustomer.rejected, (state, action) => {
        const errorFields = Object.keys(action.payload);
        errorFields.forEach((field) => {
          if (field === "owner") {
            const errorOwnerFields = Object.keys(action.payload[field]);
            errorOwnerFields.forEach((ownerField) => {
              fireErrorNotification(
                `${ownerField}: ${action.payload[field][ownerField][0]}`,
              );
            });
          } else {
            fireErrorNotification(`${field}: ${action.payload[field][0]}`);
          }
        });
      })
      .addCase(removeCustomer.fulfilled, (state, action) => {
        fireSuccessNotification(`Customer deleted successfully`);
        state.customer = {};
        state.customers = state.customers.filter(
          (el) => el.id !== action.payload,
        );
      })
      .addCase(removeCustomer.rejected, (state, action) => {
        const protected_elements = action.payload.protected_elements;
        fireErrorNotification(`Protected by: 
          ${protected_elements.map(
            (el) => ` id: ${el.id} label: ${el.label}`,
          )}`);
      })

      // customer phones
      .addCase(fetchCustomerPhones.fulfilled, (state, action) => {
        state.customer.phones = action.payload;
      })
      .addCase(createCustomerPhones.fulfilled, (state, action) => {
        state.customer.phones = state.customer.phones?.length
          ? state.customer.phones.concat(action.payload)
          : [action.payload];
      })
      .addCase(createCustomerPhones.rejected, (state, action) => {
        const errorFields = Object.keys(action.payload);
        errorFields.forEach((field) => {
          fireErrorNotification(`${field}: ${action.payload[field][0]}`);
        });
      })
      .addCase(updateCustomerPhones.fulfilled, (state, action) => {
        const index = state.customer.phones.findIndex(
          (item) => item.id === action.payload.id,
        );
        if (index !== -1) {
          state.customer.phones[index] = action.payload;
        }
      })
      .addCase(updateCustomerPhones.rejected, (state, action) => {
        const errorFields = Object.keys(action.payload);
        errorFields.forEach((field) => {
          fireErrorNotification(`${field}: ${action.payload[field][0]}`);
        });
      })
      .addCase(removeCustomerPhones.fulfilled, (state, action) => {
        state.customer.phones = state.customer.phones.filter(
          (el) => el.id !== action.payload,
        );
      })

      // customer emails
      .addCase(fetchCustomerEmails.pending, (state, action) => {
        state.customerEmailsLoading = true;
      })
      .addCase(fetchCustomerEmails.fulfilled, (state, action) => {
        state.customerEmailsLoading = false;
        state.customer.emails = state.customer.emails?.length
          ? state.customer.emails.concat(action.payload)
          : action.payload;
      })
      .addCase(createCustomerEmails.fulfilled, (state, action) => {
        state.customer.emails = state.customer.emails?.length
          ? state.customer.emails.concat(action.payload)
          : [action.payload];
      })
      .addCase(createCustomerEmails.rejected, (state, action) => {
        const errorFields = Object.keys(action.payload);
        errorFields.forEach((field) => {
          fireErrorNotification(`${field}: ${action.payload[field][0]}`);
        });
      })
      .addCase(updateCustomerEmails.fulfilled, (state, action) => {
        const index = state.customer.emails.findIndex(
          (item) => item.id === action.payload.id,
        );
        if (index !== -1) {
          state.customer.emails[index] = action.payload;
        }
      })
      .addCase(updateCustomerEmails.rejected, (state, action) => {
        const errorFields = Object.keys(action.payload);
        errorFields.forEach((field) => {
          fireErrorNotification(`${field}: ${action.payload[field][0]}`);
        });
      })
      .addCase(removeCustomerEmails.fulfilled, (state, action) => {
        state.customer.emails = state.customer.emails.filter(
          (el) => el.id !== action.payload,
        );
      })

      // customer overrides
      .addCase(fetchCustomerOverrides.pending, (state, action) => {
        state.customerOverridesLoading = true;
      })
      .addCase(fetchCustomerOverrides.fulfilled, (state, action) => {
        state.customerOverrides = action.payload;
        state.customerOverridesLoading = false;
      })
      .addCase(createCustomerOverride.fulfilled, (state, action) => {
        fireSuccessNotification(`Customer override created successfully`);
        state.customerOverrides.push(action.payload);
      })
      .addCase(createCustomerOverride.rejected, (state, action) => {
        const errorFields = Object.keys(action.payload);
        errorFields.forEach((field) => {
          fireErrorNotification(`${field}: ${action.payload[field][0]}`);
        });
      })
      .addCase(updateCustomerOverride.fulfilled, (state, action) => {
        fireSuccessNotification(`Override updated successfully`);
        state.customerOverrides = state.customerOverrides.map((el) => {
          if (el.id === action.payload.id) {
            return action.payload;
          } else {
            return el;
          }
        });
      })
      .addCase(updateCustomerOverride.rejected, (state, action) => {
        const errorFields = Object.keys(action.payload);
        errorFields.forEach((field) => {
          fireErrorNotification(`${field}: ${action.payload[field][0]}`);
        });
      })
      .addCase(removeCustomerOverride.fulfilled, (state, action) => {
        fireSuccessNotification(`Override deleted successfully`);
        state.customerOverrides = state.customerOverrides.filter(
          (el) => el.id !== action.payload.overrideId,
        );
      })
      .addCase(removeCustomerAccount.fulfilled, (state, action) => {
        fireSuccessNotification(`Account deleted successfully`);
        state.customerAccounts = state.customerAccounts.filter(
          (el) => el.id !== action.payload,
        );
      })
      .addCase(removeCustomerAccount.rejected, (state, action) => {
        const protected_elements = action.payload.protected_elements;
        fireErrorNotification(`Protected by: 
          ${protected_elements.map(
            (el) => ` id: ${el.id} label: ${el.label}`,
          )}`);
      })
      .addCase(signOut, () => initialState);
  },
});

// TODO: clear all redux state on logout
// TODO: refactor all modules to use rejectWithValue
export const {
  setCustomerTableSettings,
  cleanCustomerAutoOrders,
  cleanTableSettings,
  setSelectedTab,
} = customerSlice.actions;

export const selectCustomerNumberOfPages = createSelector(
  (state) => state.customer.totalCount,
  (state) => state.customer.pageSize,
  (totalCount, pageSize) => Math.ceil(totalCount / pageSize),
);

export default customerSlice.reducer;
