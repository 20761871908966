import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import DatePicker from "./DatePicker2";
import React, { useState } from "react";
import dayjs from "dayjs";
import { fireErrorNotification, fireSuccessNotification } from "../utils";
import { DATE_FORMAT } from "../utils/constants";
import ErrorPopupBoundary from "./ErrorPopupBoundary";
import { ErrorBoundary } from "react-error-boundary";


function UpdateOverrideProductModal({
                                      override,
                                      productId,
                                      variantId,
                                      updateOverride,
                                      updateOverridesTable,
                                      elementKey,
                                      ...props
                                    }) {

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [upcharge, setUpcharge] = useState(Number(override.override));
  const [startDate, setStartDate] = useState(override.start_date ? dayjs.utc(override.start_date) : dayjs(new Date()));
  const [endDate, setEndDate] = useState(override.end_date ? dayjs.utc(override.end_date) : null);


  const handleSubmit = async (event) => {
    setIsSubmitting(true);
    event.preventDefault();
    const start_date = startDate?.isValid() ? startDate?.format(DATE_FORMAT) : null;
    const end_date = endDate?.isValid() ? endDate?.format(DATE_FORMAT) : null;
    const payload = {
      override: upcharge,
      start_date,
      end_date,
      product: productId,
      variant: variantId,
      [elementKey]: override[elementKey]
    };
    // (priductId, overrideId, overrideData, overrideType, variantId)
    await updateOverride(productId, override.id, payload, elementKey, variantId).then(
      res => {
        fireSuccessNotification(`Product ${variantId ? "variant" : ""} ${elementKey} override updated successfully`);
        setIsSubmitting(false);
        updateOverridesTable();
        props.onClose();
      },
      error => {
        const errorFields = Object.keys(error.response.data);
        errorFields.forEach(field => {
          fireErrorNotification(error.response.data[field][0]);
        });
        setIsSubmitting(false);
      }
    );
    setIsSubmitting(false);
  };

  return (
    <Dialog {...props} maxWidth="sm" fullWidth>
      <ErrorBoundary fallback={<ErrorPopupBoundary onClose={props.onClose} />}>
        <DialogTitle sx={{ pb: 1 }}>Update {elementKey} upcharge</DialogTitle>
        <form id="overrideUpdateForm" onSubmit={handleSubmit}>
          <DialogContent sx={{ pt: 1, pb: 1 }}>
            <Stack direction="row" alignItems="flex-end" flexWrap="wrap" gap="17px" mb="20px">
              <Box>
                <Typography component="p" variant="label" mb="11px">
                  Upcharge
                </Typography>
                <TextField
                  data-testid="test-upcharge-id"
                  fullWidth
                  type="number"
                  variant="outlined"
                  name="upcharge"
                  required
                  value={upcharge}
                  onChange={(e) => setUpcharge(e.target.value)} />
              </Box>
            </Stack>
            <Grid container columnSpacing={{ xs: 1, sm: 3, xl: "10px" }} margin="20px 0 0">
              <Grid item md={6} lg={6}>
                <Typography component="p" variant="label" mb="11px">
                  Start Date
                </Typography>
                <DatePicker controlledValue={startDate} setControlledValue={setStartDate} />
              </Grid>
              <Grid item md={6} lg={6}>
                <Typography component="p" variant="label" mb="11px" name="endDate">
                  End Date
                </Typography>
                <DatePicker controlledValue={endDate} setControlledValue={setEndDate} />
              </Grid>
            </Grid>

          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="primary" type="submit" disabled={isSubmitting}>Update Override</Button>
            <Button onClick={() => props.onClose()} variant="contained" color="error">Cancel</Button>
          </DialogActions>
        </form>
      </ErrorBoundary>
    </Dialog>
  );
}

export default UpdateOverrideProductModal;
