import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import routeScheduleChangesApi from "../../distflowAPI/routeScheduleChangesApi";
import {
  formatAgGridSorting,
  formatAgGridFilters,
} from "../../distflowAPI/common";
import { signOut } from "./user";
import { arrayToDictionary, fireSuccessNotification } from "../../utils";
import { fireErrorNotification } from "../../utils";

/**
 * @typedef {Object} ScheduleChangeVendorRule
 * @property {number} ScheduleChangeVendorRule.id
 * @property {import("./route").Vendor & { latitude: string | null; longitude: string | null; upcharge: { id: number | null, value: number } }} ScheduleChangeVendorRule.vendor
 * @property {number | null} ScheduleChangeVendorRule.pickup_route
 * @property {"PICKUP_ROUTE" | "DISABLED"} ScheduleChangeVendorRule.type
 * @typedef {Object} ScheduleChangeAccount
 * @property {number} ScheduleChangeAccount.id
 * @property {number} ScheduleChangeAccount.route
 * @property {"ADDED_TO_ROUTE" | "DISABLED"} ScheduleChangeAccount.type
 * @property {import("./account").Account & { territory: number; customer: number; upcharge: { id: number | null, value: number } }} ScheduleChangeAccount.account
 * @typedef {Object} RouteChange
 * @property {number} RouteChange.id
 * @property {number} RouteChange.route
 * @property {Array<ScheduleChangeVendorRule>} RouteChange.schedule_change_vendor_rules
 * @property {Array<ScheduleChangeAccount>} RouteChange.schedule_change_accounts
 * @property {boolean} RouteChange.is_sunday
 * @property {boolean} RouteChange.is_monday
 * @property {boolean} RouteChange.is_tuesday
 * @property {boolean} RouteChange.is_wednesday
 * @property {boolean} RouteChange.is_thursday
 * @property {boolean} RouteChange.is_friday
 * @property {boolean} RouteChange.is_saturday
 * @property {number} RouteChange.driver
 * @property {string} RouteChange.start_date
 * @property {string} RouteChange.end_date
 * @property {boolean} RouteChange.is_active
 */

/**
 * @type {{
 *  routeChanges: Array<RouteChange>;
 *  routeChangeMap: {};
 *  routeChangesLoading: boolean;
 *  pageSize: number;
 *  page: number;
 *  orderBy: [];
 *  filterBy: {};
 *  totalCount: number;
 *  createLoading: boolean;
 *  routeChangeToDelete: RouteChange | null;
 *  routeChangeDeleteLoading: boolean;
 * }}
 */
const initialState = {
  routeChanges: [],
  routeChangeMap: {},
  routeChangesLoading: false,
  pageSize: 50,
  page: 1,
  orderBy: [],
  filterBy: {},
  totalCount: 0,
  createLoading: false,
  routeChangeToDelete: null,
  routeChangeDeleteLoading: false,
};

export const fetchRouteChanges = createAsyncThunk(
  "routeChange/fetchRouteChanges",
  (arg, { dispatch, getState }) => {
    const routeChangeState = getState().routeChange;
    const {
      pageSize = routeChangeState.pageSize,
      page = routeChangeState.page,
      orderBy = routeChangeState.orderBy,
      filterBy = routeChangeState.filterBy,
      searchParams,
    } = arg;

    const formattedFilterBy = formatAgGridFilters(filterBy);
    const formattedOrderBy = formatAgGridSorting(orderBy);

    dispatch(setRouteChangesPagination({ pageSize, page }));
    dispatch(setRouteChangesFilters({ orderBy, filterBy }));
    return routeScheduleChangesApi.list(
      pageSize,
      page,
      formattedOrderBy,
      formattedFilterBy,
      searchParams
    );
  }
);

export const deleteSeletedRouteChange = createAsyncThunk(
  "routeChange/deleteSeletedRouteChange",
  (_, { getState, rejectWithValue }) => {
    const { routeChangeToDelete } = getState().routeChange;

    if (!routeChangeToDelete) {
      throw new Error("No route change seleted to delete!");
    }

    try {
      return routeScheduleChangesApi.delete(routeChangeToDelete.id);
    }
    catch (err) {
      return rejectWithValue(err.data.error);
    }
  }
);

export const routeChangeSlice = createSlice({
  name: "routeChange",
  initialState,
  reducers: {
    setRouteChangesPagination(state, action) {
      state.pageSize = action.payload.pageSize;
      state.page = action.payload.page;
    },
    setRouteChangesFilters(state, action) {
      state.orderBy = action.payload.orderBy;
      state.filterBy = action.payload.filterBy;
    },
    setRouteChangeToDelete(state, action) {
      state.routeChangeToDelete = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRouteChanges.pending, (state) => {
        state.routeChangesLoading = true;
      })
      .addCase(fetchRouteChanges.fulfilled, (state, action) => {
        state.routeChangesLoading = false;
        state.routeChanges = action.payload.results;
        state.routeChangeMap = arrayToDictionary(action.payload.results, "id");
        state.totalCount = action.payload.count;
      })
      .addCase(fetchRouteChanges.rejected, (state) => {
        state.routeChangesLoading = false;
      })
      .addCase(deleteSeletedRouteChange.pending, (state) => {
        state.routeChangeDeleteLoading = true;
      })
      .addCase(deleteSeletedRouteChange.fulfilled, (state) => {
        state.routeChangeDeleteLoading = false;
        state.routeChanges = state.routeChanges.filter(
          (item) => item.id !== state.routeChangeToDelete.id
        );
        state.routeChangeToDelete = null;
        fireSuccessNotification("Route change deleted successfully!")
      })
      .addCase(deleteSeletedRouteChange.rejected, (state, { payload }) => {
        state.routeChangeDeleteLoading = false;
        state.routeChangeToDelete = null;
        fireErrorNotification("Failed to delete route change!");
      })
      .addCase(signOut, () => initialState);
  },
});

export const {
  setRouteChangesPagination,
  setRouteChangesFilters,
  setRouteChangeToDelete,
} = routeChangeSlice.actions;

export const selectRouteChangeNumberOfPages = createSelector(
  (state) => state.routeChange.totalCount,
  (state) => state.routeChange.pageSize,
  (totalCount, pageSize) => Math.ceil(totalCount / pageSize)
);

export default routeChangeSlice.reducer;
