import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Button, Typography, Stack, Box } from "@mui/material";
import DataTable from "../../components/DataTable";
import AddTerritoryFrom from "./AddTerritoryFrom";
import {
  fetchTerritories,
  selectTerritoryNumberOfPages,
  setTerritoryTableSettings,
  cleanTableSettings,
} from "../../redux/module/territory";
import useAppDispatch from "../../hooks/useAppDispatch";
import useAppSelector from "../../hooks/useAppSelector";
import territoryTableColumns from "./territoryTableColumns";
import {
  formatAgGridFilters,
  formatAgGridSorting,
} from "../../distflowAPI/common";
import PageLoading from "../../components/PageLoading";

const defaultColDef = {
  flex: 1,
  filter: true,
  floatingFilter: true,
};

const Territories = () => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const { territories, pageSize, page, orderBy, filterBy, territoriesLoading } =
    useAppSelector((state) => state.territory);
  const numberOfPages = useAppSelector(selectTerritoryNumberOfPages);

  const [open, setOpen] = useState(false);

  useEffect(() => {
    dispatch(fetchTerritories({ page, pageSize, filterBy, orderBy }));
  }, [pageSize, page, filterBy, orderBy]);

  useEffect(() => {
    const params = new URLSearchParams();
    filterBy.forEach((item) => {
      params.set(item.column, item.value);
    });
    setSearchParams(params);
  }, [filterBy]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleChange = (field, value) => {
    if (field === "filterBy" || field === "pageSize") {
      dispatch(setTerritoryTableSettings({ field: "page", value: 1 }));
    }
    dispatch(setTerritoryTableSettings({ field, value }));
  };

  useEffect(() => {
    return () => {
      dispatch(cleanTableSettings());
    };
  }, []);

  return (
    <>
      <PageLoading dataLoading={territoriesLoading} />

      <Typography
        fontSize="23px"
        fontWeight={800}
        component="h2"
        data-testid="page-title"
      >
        Territories
      </Typography>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        alignItems={{ xs: "space-between", sm: "center" }}
        justifyContent="space-between"
        spacing={1.5}
        my="25px"
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          spacing="15px"
        >
          <Button onClick={handleOpen} variant="contained">
            Add New
          </Button>
        </Stack>
      </Stack>

      <Box className="table-wrapper">
        <DataTable
          rowData={territories}
          columnDefs={territoryTableColumns}
          defaultColDef={defaultColDef}
          suppressSorting={true} //disable default sorting
          rowHeight={53}
          headerHeight={60}
          loading={territoriesLoading}
          onSortChanged={(event) =>
            handleChange(
              "orderBy",
              formatAgGridSorting(event.columnApi.columnModel.gridColumns),
            )
          }
          onFilterChanged={(event) =>
            handleChange(
              "filterBy",
              formatAgGridFilters(event.api.getFilterModel()) || [],
            )
          }
          page={page}
          pageSize={pageSize}
          setPageSize={(pageSize) => handleChange("pageSize", pageSize)}
          noPages={numberOfPages}
          setPage={(page) => handleChange("page", page)}
        />
      </Box>
      <AddTerritoryFrom open={open} handleClose={handleClose} />
    </>
  );
};

export default Territories;
