import React, { useEffect, useState } from "react";
import Block from "../../components/Block";
import { Box, FormControlLabel, Grid, OutlinedInput, Radio, RadioGroup, Typography } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useSearchDebounce } from "../../utils";
import TagSelect from "../../components/AsyncSelects/TagSelect";

const ProductFilters = ({ showFilters }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [filterDB, setFilterDB] = useSearchDebounce();
  const [selectedTags, setSelectedTags] = useState([]);

  const [filters, setFilters] = useState({
    // search: searchParams.get("search") || "",
    name: searchParams.get("name") || "",
    delivery_type: searchParams.get("delivery_type") || "",
    category__name: searchParams.get("category__name") || "",
    vendor__name: searchParams.get("vendor__name") || "",
    tags: searchParams.get("tags") || "",
    variants__sku: searchParams.get("variants__sku") || "",
    is_active: searchParams.get("is_active") || ""
  });

  const handleChange = (value) => {
    setSearchParams((params) => {
      const newParams = new URLSearchParams(params);
      Object.keys(value).forEach(key => {
        if (!!value[key]) {
          newParams.set(key, value[key]);
        } else {
          newParams.delete(key);
        }
      });
      return newParams;
    });
  };

  useEffect(() => {
    setFilterDB(filters);
  }, [filters]);

  useEffect(() => {
    handleChange(filterDB);
  }, [filterDB]);

  useEffect(() => {
    const tagsList = selectedTags.length ? selectedTags.map(el => el.label).join(",") : "";
    setFilters({ ...filters, tags: tagsList });
  }, [selectedTags]);

  return (
    <>
      {
        showFilters &&
        <Block sx={{ padding: { xs: "16px", sm: "13px 24px" }, marginBottom: "24px" }}>
          <Grid container spacing={1} rowGap="10px">
            {/*<Grid item xs={12} sm={3}>*/}
            {/*  <Typography mb="5px">Search</Typography>*/}
            {/*  <OutlinedInput*/}
            {/*    size="small"*/}
            {/*    sx={{ height: "40px" }}*/}
            {/*    fullWidth*/}
            {/*    value={filters.search}*/}
            {/*    onChange={(e) => setFilters({ ...filters, search: e.target.value })}*/}
            {/*  />*/}
            {/*</Grid>*/}
            <Grid item xs={12} sm={3}>
              <Typography mb="5px">Product name</Typography>
              <OutlinedInput
                size="small"
                sx={{ height: "40px" }}
                fullWidth
                value={filters.name}
                onChange={(e) => setFilters({ ...filters, name: e.target.value })}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography mb="5px">Category name</Typography>
              <OutlinedInput
                size="small"
                sx={{ height: "40px" }}
                fullWidth
                value={filters.category__name}
                onChange={(e) => setFilters({ ...filters, category__name: e.target.value })}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography mb="5px">Vendor name</Typography>
              <OutlinedInput
                size="small"
                sx={{ height: "40px" }}
                fullWidth
                value={filters.vendor__name}
                onChange={(e) => setFilters({ ...filters, vendor__name: e.target.value })}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography mb="5px">SKU</Typography>
              <OutlinedInput
                size="small"
                sx={{ height: "40px" }}
                fullWidth
                value={filters.variants__sku}
                onChange={(e) => setFilters({ ...filters, variants__sku: e.target.value })}
              />
            </Grid>
            <Grid xs={12} sm={3} item>
              <Box>
                <Typography mb="5px">Tags</Typography>
                <TagSelect
                  limitTags={3}
                  multiple
                  id="multiple-limit-tags"
                  value={selectedTags}
                  onChange={(_event, newValue) => {
                    setSelectedTags(newValue);
                  }}
                  sx={{
                    "& .MuiInputBase-root": {
                      height: "40px",
                      paddingTop: "0px",
                      paddingBottom: "0px"
                      // "& .MuiButtonBase-root": {
                      //   display: "none"
                      // },
                      // "& .MuiAutocomplete-tag": {
                      //   display: "none"
                      // }
                    }
                  }}
                />
              </Box>
            </Grid>
            <Grid xs={12} sm={3} item>
              <Typography mb="5px">Delivery type</Typography>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                sx={{ border: "none !important", padding: "0 !important" }}
                value={filters.delivery_type}
                onChange={(e) =>
                  setFilters({ ...filters, delivery_type: e.target.value })
                }
              >
                <FormControlLabel value="" control={<Radio />} label="All" />
                <FormControlLabel value="MORNING" control={<Radio />} label="Morning" />
                <FormControlLabel value="SALES_MAN" control={<Radio />} label="Salesman" />
              </RadioGroup>
            </Grid>
            <Grid xs={12} sm={3} item>
              <Typography mb="5px">Is active</Typography>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                sx={{ border: "none !important", padding: "0 !important" }}
                value={filters.is_active}
                onChange={(e) => {
                  setFilters({ ...filters, is_active: e.target.value });
                }
                }
              >
                <FormControlLabel value="" control={<Radio />} label="All" />
                <FormControlLabel value="true" control={<Radio />} label="Active" />
                <FormControlLabel value="false" control={<Radio />} label="Inactive" />
              </RadioGroup>
            </Grid>

          </Grid>
        </Block>
      }
    </>    
  );
};

export default ProductFilters;