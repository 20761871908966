import DataTable from "../../components/DataTable";
import { Box, Button, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { getPaymentColumns } from "./paymentTableColumns";
import {
  formatAgGridFilters,
  formatAgGridSorting,
} from "../../distflowAPI/common";

import { useNavigate, useSearchParams } from "react-router-dom";
import PaymentFilters from "./PaymentFilters";
import PageLoading from "../../components/PageLoading";
import Block from "../../components/Block";
import { listPayments } from "../../distflowAPI/paymentsApi";
import { getNoPages } from "../../utils";

const defaultColDef = {
  flex: 1,
  filter: false,
  floatingFilter: false,
};

const ListPayments = () => {
  const [searchParams, setSearchParams] = useSearchParams({
    ordering: "payment_date",
  });
  const [payments, setPayments] = useState([]);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [filterBy, setFilterBy] = useState({});
  const [orderBy, setOrderBy] = useState([]);

  const [paymentsLoading, setPaymentsLoading] = useState(false);
  const [numberOfPages, setNumberOfPages] = useState(1);

  function fetchPayments() {
    listPayments(
      page,
      pageSize,
      formatAgGridFilters(filterBy),
      orderBy,
      searchParams
    ).then((res) => {
      setPayments(res.data.results);
      setNumberOfPages(getNoPages(res.data.count, pageSize));
    });
  }

  useEffect(() => {
    fetchPayments(page, pageSize, filterBy, orderBy, searchParams);
  }, [pageSize, page, filterBy, searchParams]);

  useEffect(() => {
    if (orderBy.length == 0) {
      return;
    }
    let newSortingValue = "";
    newSortingValue = orderBy[0]?.field;
    if (orderBy[0]?.sort != "asc") {
      newSortingValue = "-" + newSortingValue;
    }
    setSearchParams((params) => {
      const newParams = new URLSearchParams(params);
      newParams.set("ordering", newSortingValue);
      return newParams;
    });
  }, [orderBy]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const navigate = useNavigate();
  const cols = getPaymentColumns({ updateTable: fetchPayments });
  return (
    <>
      <PageLoading dataLoading={paymentsLoading} />

      <Typography
        fontSize="23px"
        fontWeight={800}
        component="h2"
        data-testid="page-title"
      >
        Payments
      </Typography>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        alignItems={{ xs: "space-between", sm: "center" }}
        justifyContent="space-between"
        spacing={1.5}
        my="25px"
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          spacing="15px"
        >
          <Button
            onClick={() => navigate("/invoices/payments/new")}
            variant="contained"
          >
            Create Payment
          </Button>
        </Stack>
      </Stack>
      <Block>
        <PaymentFilters />
      </Block>
      <Box className="table-wrapper" marginTop={"10px"}>
        <DataTable
          rowData={payments}
          columnDefs={cols}
          defaultColDef={defaultColDef}
          suppressSorting={true} //disable default sorting
          loading={paymentsLoading}
          rowHeight={53}
          headerHeight={72}
          onSortChanged={(event) =>
            setOrderBy(
              formatAgGridSorting(event.columnApi.columnModel.gridColumns)
            )
          }
          onFilterChanged={(event) =>
            setFilterBy(formatAgGridFilters(event.api.getFilterModel()))
          }
          page={page}
          pageSize={pageSize}
          setPageSize={(pageSize) => setPageSize(pageSize)}
          noPages={numberOfPages}
          setPage={(page) => setPage(page)}
        />
      </Box>
    </>
  );
};

export default ListPayments;
