import React from "react";
import { Box, Grid, IconButton, Stack, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { DATE_FORMAT, DATE_FORMAT_FRONT } from "../../utils/constants";

const CustomerPersonal = ({customer}) => {
  const navigate = useNavigate();
  
  const info = [
    {id: 1, label: "Name", value: customer.name},
    {id: 5, label: "State", value: customer.state},
    {id: 2, label: "Internal id", value: customer.internal_id},
    {id: 4, label: "City", value: customer.city},
    {id: 8, label: "Email address", value: customer.emails[0]?.email},    
    {id: 9, label: "Address", value: customer.address},
    {id: 7, label: "Group", value: customer.group?.name},
    {id: 6, label: "Zip code", value: customer.zipcode},
  ]
  
  return (
    <Box>
      <Stack direction='row' justifyContent="space-between" alignItems="center" mb={3}>
        <Typography component="p" variant="h6" >
          Personal information
        </Typography>
        <IconButton onClick={() => navigate(`/customers/${customer.id}`)} width="25px" height="25px" sx={{
          "&:hover": {
            backgroundColor: "transparent", // Set the background color to transparent on hover
          },
        }}>
          <EditOutlinedIcon />
        </IconButton>
      </Stack>
      <Grid
        container
        columnSpacing={{ xs: 1, sm: 3, xl: 5 }}
        rowSpacing={{xs: 3}}
        margin="20px 0 0"
      >
        {info.map(el => (
          <Grid item md={6} key={el.id}>
            <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{
              wordBreak: "break-word",
              gap: "24px",
            }}> 
              <Typography variant="body1" component="p">
                {el.label}
              </Typography>
              <Typography variant="body2" component="p" textAlign="right">
                {el.value}
              </Typography>
            </Stack>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default CustomerPersonal;