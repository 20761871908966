import { useState, useEffect } from "react";
import DataTable from "../../components/DataTable";
import payoutsCols from "./payoutsCols";
import {
  createPayOut,
  listComissions,
  listPayOuts,
  getPayout,
  patchPayOut,
  calculatePayout,
  createCalculatedPayout,
} from "../../distflowAPI/salesmenApi";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  Checkbox,
  TextField,
  Alert,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import dayjs from "dayjs";
import { DATE_FORMAT, DATE_FORMAT_FRONT } from "../../utils/constants";
import DatePickerCustom2 from "../../components/DatePicker2";

export default function CommissionPayoutsTable({ salesManId }) {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [openCreatePayout, setOpenCreatePayout] = useState(false);
  const [filterBy, setFilterBy] = useState([]);
  const [payoutToEdit, setPayoutToEdit] = useState(null);

  function refreshTable() {
    listPayOuts(salesManId, page, pageSize, filterBy).then((res) =>
      setData(res.data.results)
    );
  }
  useEffect(() => {
    refreshTable();
  }, [salesManId, page, pageSize, filterBy]);
  const cols = payoutsCols({
    refreshTable: refreshTable,
    setPayoutToEdit: setPayoutToEdit,
  });
  return (
    <>
      <CreatePayoutModal
        salesManId={salesManId}
        open={openCreatePayout}
        setOpen={setOpenCreatePayout}
        refreshTable={refreshTable}
      />
      <EditPayoutModal
        salesManId={salesManId}
        open={payoutToEdit}
        setOpen={setPayoutToEdit}
        refreshTable={refreshTable}
      />
      <Stack spacing={2}>
        <Stack justifyContent={"end"} alignItems={"end"}>
          <Button
            variant="contained"
            color="success"
            sx={{ maxWidth: "10%" }}
            onClick={() => setOpenCreatePayout(true)}
          >
            Create Payout
          </Button>
        </Stack>
        {/*<PayoutFilters filterBy={filterBy} setFilterBy={setFilterBy}/>*/}
        <Stack>
          <DataTable columnDefs={cols} rowData={data} />
        </Stack>
      </Stack>
    </>
  );
}

function CreatePayoutModal({ salesManId, open, setOpen, refreshTable }) {
  const [note, setNote] = useState("");
  const [errors, setErrors] = useState({});
  const [startDate, setDtartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isCalculating, setIsCalculating] = useState(false);
  const [payoutTotal, setPayoutTotal] = useState(0);
  function handleSubmit() {
    if (startDate && endDate) {
      createCalculatedPayout(
        salesManId,
        startDate.format(DATE_FORMAT),
        endDate.format(DATE_FORMAT),
        note
      ).then((res) => {
        console.log("payout created... ", res);
        refreshTable();
      });
    }
  }

  useEffect(() => {
    if (startDate && endDate) {
      setIsCalculating(true);
      calculatePayout(
        salesManId,
        startDate.format(DATE_FORMAT),
        endDate.format(DATE_FORMAT)
      ).then((res) => {
        console.log("result: ", res);
        setIsCalculating(false);
        setPayoutTotal(res.data.total_commissions);
      });
    }
  }, [startDate, endDate]);
  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>Create Payout</DialogTitle>
      <DialogContent>
        <Stack spacing={1}>
          {Object.keys(errors).map((ek) => (
            <Alert severity={"error"}>
              {ek}:{errors[ek]}
            </Alert>
          ))}

          <Stack direction={"row"} spacing={1} padding={1}>
            <DatePickerCustom2
              controlledValue={startDate}
              setControlledValue={setDtartDate}
              label={"start date"}
            />
            <DatePickerCustom2
              controlledValue={endDate}
              setControlledValue={setEndDate}
              label={"start date"}
            />
          </Stack>
          {!startDate && !endDate && (
            <Typography>Select start date and end date</Typography>
          )}
          {startDate && endDate && (
            <>
              <Typography sx={{ marginTop: 2 }}>
                Total to pay: ${isCalculating ? "Calculating" : payoutTotal}
              </Typography>
              <TextField
                sx={{ marginTop: 2 }}
                value={note}
                onChange={(e) => setNote(e.target.value)}
                label={"memo"}
              />
            </>
          )}
        </Stack>
      </DialogContent>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          m: "20px",
          gap: "10px",
        }}
      >
        <Button
          variant="contained"
          color="success"
          onClick={() => handleSubmit()}
          disabled={!startDate && !endDate}
        >
          Create
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={() => {
            setOpen(false);
          }}
        >
          Cancel
        </Button>
      </Box>
    </Dialog>
  );
}

function PayoutFilters({ filterBy, setFilterBy }) {
  function getStatusFilter() {
    const statusFilter = filterBy.filter((f) => f.column == "status");
    if (statusFilter.length == 0) {
      return null;
    }
    return statusFilter[0].value;
  }
  function setFilterStatus(value) {
    const statusFilterIndex = filterBy.findIndex((f) => f.column == "status");
    const newFilter = [...filterBy];
    if (statusFilterIndex >= 0) {
      newFilter[statusFilterIndex]["value"] = value;
    } else {
      newFilter.push({ column: "status", value });
    }

    setFilterBy(newFilter);
  }
  return (
    <Stack direction={"row"} spacing={2}>
      <RadioGroup
        value={getStatusFilter()}
        onChange={(e) => setFilterStatus(e.target.value)}
        row
      >
        <FormControlLabel control={<Radio />} label={"All"} value={""} />
        <FormControlLabel control={<Radio />} label={"Paid"} value={"PAID"} />
        <FormControlLabel
          control={<Radio />}
          label={"UnPaid"}
          value={"PENDING"}
        />
      </RadioGroup>
    </Stack>
  );
}

function EditPayoutModal({ salesManId, open, setOpen, refreshTable }) {
  const [payoutData, setPayoutData] = useState({
    commissions: [],
  });
  const [note, setNote] = useState("");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (open && !isNaN(parseInt(open))) {
      setLoading(true);
      getPayout(salesManId, open).then((res) => {
        setPayoutData(res.data);
        setNote(res.data.note);
        setLoading(false);
      });
    }
  }, [open]);
  function handleSubmit() {
    const payload = {
      note,
    };
    patchPayOut(salesManId, open, payload).then((res) => {
      setPayoutData({ commissions: [] });
      setNote("");
      setOpen(null);
      refreshTable();
    });
  }
  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <DialogTitle>Edit Payout {open}</DialogTitle>
      <DialogContent>
        {loading && <Typography>Loading....</Typography>}
        {!loading && (
          <Stack>
            <Typography>Paid commissions</Typography>
            <Table size={"small"}>
              <TableHead>
                <TableRow>
                  <TableCell>Customer</TableCell>
                  <TableCell>Account</TableCell>
                  <TableCell>Invoice</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Percentage</TableCell>
                  <TableCell>Commission</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {payoutData?.commissions.map((item) => (
                  <TableRow>
                    <TableCell>{item.customer}</TableCell>
                    <TableCell>{item.account}</TableCell>
                    <TableCell>
                      {item.po_id ? `PO# ${item.po_id}` : `#${item.invoice}`}
                    </TableCell>
                    <TableCell>
                      {dayjs.utc(item.creation_date).format(DATE_FORMAT_FRONT)}
                    </TableCell>
                    <TableCell>{item.percentage}%</TableCell>
                    <TableCell>${item.value}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TextField
              sx={{ marginTop: 2 }}
              value={note}
              onChange={(e) => setNote(e.target.value)}
              label={"memo"}
            />
          </Stack>
        )}
      </DialogContent>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          m: "20px",
          gap: "10px",
        }}
      >
        <Button
          variant="contained"
          color="success"
          onClick={() => handleSubmit()}
        >
          Update
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={() => {
            setOpen(null);
          }}
        >
          Cancel
        </Button>
      </Box>
    </Dialog>
  );
}
