import { useEffect, useState } from "react";
import {
  listWhitelistedIps,
  updateWhitelistedIps,
} from "../../distflowAPI/whitelistedIpsApi";

export const useWhitelistedIps = () => {
  const [whitelistedIps, setWhitelistedIps] = useState([]);
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);

  const fetchWhitelistedIps = () => {
    listWhitelistedIps().then((res) => {
      if (res.data) {
        setData(res.data);
        setWhitelistedIps(res.data.value.ips);
        setCount(res.data.value.ips.length);
      }
    });
  };

  const updateWhitelistedIpsData = async (data, type) => {
    await updateWhitelistedIps(data).then((res) => {
      if (res.data) {
        setWhitelistedIps(res.data.value.ips);
        setData(res.data);
        setCount(res.data.value.ips.length);
      }
    });
  };

  useEffect(() => {
    fetchWhitelistedIps();
  }, []);

  return { whitelistedIps, data, count, updateWhitelistedIpsData };
};
