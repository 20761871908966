import {
  Typography,
  Box,
  IconButton,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import Eye from "../../images/eye.svg";
import Edit from "../../images/edit.svg";
import Trash from "../../images/trash-alt.svg";
import Calendar from "../../images/calendar.svg";
import Driver from "../../images/driver.svg";
import Location from "../../images/location.svg";
import Vendor from "../../images/vendor.svg";
import useAppSelector from "../../hooks/useAppSelector";
import dayjs from "dayjs";
import { DATE_FORMAT_US, DATE_FORMAT } from "../../utils/constants";
import { useMemo } from "react";
import { useModal } from "mui-modal-provider";
import useAppDispatch from "../../hooks/useAppDispatch";
import { setRouteChangeToDelete } from "../../redux/module/routeChange";
import { useNavigate } from "react-router-dom";
import {
  setCurrentRoute,
  setRouteChangeToEdit,
} from "../../redux/module/addRouteChanges";

const RouteNameRenderer = (props) => {
  const routesMap = useAppSelector((state) => state.route.routesMap);

  return <span>{routesMap[props.value]?.name}</span>;
};

const CellItem = ({ title, icon }) => {
  return (
    <Typography sx={{ display: "flex", alignItems: "flex-start", gap: "8px" }}>
      <Typography component="span" sx={{ flexShrink: 0 }}>
        <img src={icon} alt="" />
      </Typography>
      {title}
    </Typography>
  );
};

const ChangesRenderer = (props) => {
  const driversMap = useAppSelector((state) => state.driver.driversMap);
  const routesMap = useAppSelector((state) => state.route.routesMap);

  const originalRoute = routesMap[props.data.route];

  return (
    <Box>
      {driversMap[props.data.driver] &&
        props.data.driver !== originalRoute?.driver && (
          <CellItem
            title={driversMap[props.data.driver].user.first_name}
            icon={Driver}
          />
        )}
      {!!props.data.schedule_change_accounts?.length && (
        <CellItem
          title={props.data.schedule_change_accounts
            .map((item) => item.account.name)
            .join(", ")}
          icon={Location}
        />
      )}
      {!!props.data.schedule_change_vendor_rules?.length && (
        <CellItem
          title={props.data.schedule_change_vendor_rules
            .map((item) => item.vendor.name)
            .join(", ")}
          icon={Vendor}
        />
      )}
    </Box>
  );
};

const DatesRenderer = (params) => {
  return (
    <CellItem
      title={
        <span>
          <span style={{ whiteSpace: "nowrap" }}>
            {dayjs(params.data.start_date).format(DATE_FORMAT_US)}
          </span>
          {params.data.start_date !== params.data.end_date && (
            <>
              <span> - </span>
              <span style={{ whiteSpace: "nowrap" }}>
                {dayjs(params.data.end_date).format(DATE_FORMAT_US)}
              </span>
            </>
          )}
        </span>
      }
      icon={Calendar}
    />
  );
};

const StatusRenderer = (props) => {
  return (
    <Typography>
      {dayjs(props.value, DATE_FORMAT).isBefore(Date.now())
        ? "Past"
        : "Upcoming"}
    </Typography>
  );
};

const AccountsModal = ({ addedToRoute, disabled, ...restProps }) => {
  return (
    <Dialog {...restProps}>
      <DialogTitle>Accounts changes</DialogTitle>
      <DialogContent>
        <Stack gap={2}>
          {!!addedToRoute.length && (
            <Stack>
              <Typography>
                <Typography fontWeight={700}>Added to route: </Typography>
                {addedToRoute.map((item) => item.account.name).join(", ")}
              </Typography>
            </Stack>
          )}
          {!!disabled.length && (
            <Stack>
              <Typography>
                <Typography fontWeight={700}>Disabled: </Typography>
                {disabled.map((item) => item.account.name).join(", ")}
              </Typography>
            </Stack>
          )}
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

const AccountsRenderer = (props) => {
  const { showModal } = useModal();

  const { ADDED_TO_ROUTE: addedToRoute = [], DISABLED: disabled = [] } =
    useMemo(
      () =>
        props.value.reduce((acc, item) => {
          if (!acc[item.type]) {
            acc[item.type] = [];
          }
          acc[item.type].push(item);
          return acc;
        }, {}),
      props.value
    );

  if (!addedToRoute.length && !disabled.length) {
    return <Typography> - </Typography>;
  }

  return (
    <Stack direction="row" alignItems="center">
      <Stack>
        {!!addedToRoute.length && (
          <Typography sx={{ whiteSpace: "nowrap" }}>
            Added: {addedToRoute.length}
          </Typography>
        )}
        {!!disabled.length && (
          <Typography sx={{ whiteSpace: "nowrap" }}>
            Disabled: {disabled.length}
          </Typography>
        )}
      </Stack>
      <IconButton
        sx={{ ml: 1 }}
        onClick={() => showModal(AccountsModal, { addedToRoute, disabled })}
      >
        <img src={Eye} alt="" />
      </IconButton>
    </Stack>
  );
};

const VendorsModal = ({ pickupRoute, disabled, ...restProps }) => {
  return (
    <Dialog {...restProps}>
      <DialogTitle>Vendors changes</DialogTitle>
      <DialogContent>
        <Stack gap={2}>
          {!!pickupRoute.length && (
            <Stack>
              <Typography>
                <Typography fontWeight={700}>Pick up route: </Typography>
                {pickupRoute.map((item) => item.vendor.name).join(", ")}
              </Typography>
            </Stack>
          )}
          {!!disabled.length && (
            <Stack>
              <Typography>
                <Typography fontWeight={700}>Disabled: </Typography>
                {disabled.map((item) => item.vendor.name).join(", ")}
              </Typography>
            </Stack>
          )}
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

const VendorsRenderer = (props) => {
  const { showModal } = useModal();

  const { PICKUP_ROUTE: pickupRoute = [], DISABLED: disabled = [] } = useMemo(
    () =>
      props.value.reduce((acc, item) => {
        if (!acc[item.type]) {
          acc[item.type] = [];
        }
        acc[item.type].push(item);
        return acc;
      }, {}),
    props.value
  );

  if (!pickupRoute.length && !disabled.length) {
    return <Typography> - </Typography>;
  }

  return (
    <Stack direction="row" alignItems="center">
      <Stack>
        {!!pickupRoute.length && (
          <Typography sx={{ whiteSpace: "nowrap" }}>
            Pickup: {pickupRoute.length}
          </Typography>
        )}
        {!!disabled.length && (
          <Typography sx={{ whiteSpace: "nowrap" }}>
            Disabled: {disabled.length}
          </Typography>
        )}
      </Stack>
      <IconButton
        sx={{ ml: 1 }}
        onClick={() => showModal(VendorsModal, { pickupRoute, disabled })}
      >
        <img src={Eye} alt="" />
      </IconButton>
    </Stack>
  );
};

const ActionRenderer = (props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const routesMap = useAppSelector((state) => state.route.routesMap);

  return (
    <>
      <IconButton
        onClick={() => {
          navigate(`/routes/edit-route-change/${props.data.id}`);
          // TODO: remove this two dispatches
          // when the findById for route-schedule-changes is implemented in the backend
          dispatch(
            setCurrentRoute({
              ...routesMap[props.data.route],
              is_sunday: props.data.is_sunday,
              is_monday: props.data.is_monday,
              is_tuesday: props.data.is_tuesday,
              is_wednesday: props.data.is_wednesday,
              is_thursday: props.data.is_thursday,
              is_friday: props.data.is_friday,
              is_saturday: props.data.is_saturday,
              driver: props.data.driver,
            })
          );
          dispatch(setRouteChangeToEdit(props.data));
        }}
      >
        <img src={Edit} alt="edit" />
      </IconButton>
      <IconButton onClick={() => dispatch(setRouteChangeToDelete(props.data))}>
        <img src={Trash} alt="trash" />
      </IconButton>
    </>
  );
};

const routeChangesTableColumns = [
  {
    headerName: "Route Name",
    field: "route",
    cellRenderer: RouteNameRenderer,
  },
  {
    headerName: "Changes",
    wrapText: true,
    autoHeight: true,
    cellRenderer: ChangesRenderer,
  },
  {
    headerName: "Dates",
    wrapText: true,
    autoHeight: true,
    flex: 2,
    cellRenderer: DatesRenderer,
  },
  {
    headerName: "Status",
    field: "start_date",
    cellRenderer: StatusRenderer,
  },
  {
    headerName: "Accounts",
    field: "schedule_change_accounts",
    cellRenderer: AccountsRenderer,
  },
  {
    headerName: "Vendors",
    field: "schedule_change_vendor_rules",
    cellRenderer: VendorsRenderer,
  },
  {
    headerName: "",
    field: "actions",
    cellRenderer: ActionRenderer,
    flex: 0.8,
  },
];

export default routeChangesTableColumns;
