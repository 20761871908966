import { useState, useEffect } from 'react';
import useAppSelector from "../../hooks/useAppSelector";
import useAppDispatch from "../../hooks/useAppDispatch";

import dayjs from 'dayjs';

import {
    createRouteReportPickupVendor,
    fetchCurrentSelectedRouteReport,
    updateRouteReportPickupStatus
} from "../../redux/module/routeReports";

import {
    Box,
    Typography,
    Stack,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Grid,
    Menu,
    MenuItem
} from "@mui/material";

import Clock from "../../images/clock.svg";

import PickupVendor from "./PickupVendor";
import PickupDetails from "./PickupDetails";
import PickupSummary from "./PickupSummary";
import { vendorStatusTextMap } from "./constants";

const PickupItem = ({ pickup }) => {
    const dispatch = useAppDispatch();
    const [anchorElement, setAnchorElement] = useState();
    const open = Boolean(anchorElement);

    const selectedReport = useAppSelector(
        (state) => state.routeReports.selectedReport
    );

    const keyedVendors = useAppSelector(
        (state) => state.vendor.vendorMap
    );

    const routes = useAppSelector(
        (state) => state.route.routes
    );

    const { items_calc } = selectedReport.route;
    const { vendor_rules } = selectedReport.route;

    const handleStatusClose = () => {
        setAnchorElement(null);
    }

    const getPickupItemsData = (vendorId) => {
        const items = items_calc.filter(x => x.items__variant__product__vendor == vendorId);
        return items;
    }

    const isPickingUpFromDifferentRoute = (vendor) => {
        const data = vendor_rules.find(x => x.type === "PICKUP_ROUTE" && x.vendor == vendor);
        return data != null;
    }

    const getPickupRouteName = (vendor) => {
        const item = vendor_rules.find(x => x.type === "PICKUP_ROUTE" && x.vendor == vendor);
        if (!item) {
            return '';
        }

        const foundRoute = routes.find(x => x.id == item.pickup_route);
        if (foundRoute) {
            return foundRoute.name;
        }

        return '';
    }

    const onStatusClick = (event) => {
        event.stopPropagation();
        setAnchorElement(event.target);
    }

    const setPickupStatus = (event, state) => {
        event.stopPropagation();

        let pickedUpTimestamp = null;
        if (state == "PICKED_UP") {
            pickedUpTimestamp = new Date().toISOString();
        }

        if (pickup.id) {
            dispatch(updateRouteReportPickupStatus({ id: pickup.id, state, pickedUpTimestamp }));
        }
        else {
            dispatch(createRouteReportPickupVendor({ vendor: pickup.vendor, state, pickedUpTimestamp }));
        }

        dispatch(fetchCurrentSelectedRouteReport());
        setAnchorElement(null);
    }

    return (
        <Accordion
            key={pickup.vendor}
            sx={{
                boxShadow: "none",
                border: "1px solid #DBDFEA",
                borderRadius: "5px",
                "&.Mui-expanded": {
                    margin: 0,
                },
                "&::before": {
                    content: "none",
                },
                "& .MuiAccordionSummary-root.Mui-expanded": {
                    minHeight: "49px",
                },
                "& .MuiAccordionSummary-content.Mui-expanded": {
                    margin: 0,
                },
            }}
        >
            <AccordionSummary
                id={pickup.vendor}
                sx={{
                    "& .MuiAccordionSummary-content": { display: "grid", gridTemplateColumns: "repeat(3, 1fr)" },
                }}
            >
                <PickupVendor status={pickup.state}>
                    {keyedVendors[pickup.vendor]?.name}
                    {isPickingUpFromDifferentRoute(pickup.vendor) && <Typography fontSize={12} color="#A0A0A0">
                        Pickup From {getPickupRouteName(pickup.vendor)}
                    </Typography>}
                </PickupVendor>

                <PickupSummary pickup={getPickupItemsData(pickup.vendor)} />
                <Stack direction="row" alignContent="end" justifyContent="end" alignSelf="center">
                    <img src={Clock} alt="" height="24" width="20" />
                    <>
                        <Typography
                            id={'vendor-status'}
                            aria-controls={open ? 'basic-menu' : null}
                            aria-haspopup={true}
                            aria-expanded={open ? 'true' : undefined}
                            onClick={onStatusClick}
                            sx={{ color: "#252525" }}>
                            {pickup.state === "PICKED_UP" ? `${dayjs(pickup.picked_up_timestamp).format('h:mm a')}` : vendorStatusTextMap[pickup.state]}
                        </Typography>
                        <Menu
                            id="basic-menu"
                            style={{ zIndex: 9999 }}
                            anchorEl={anchorElement}
                            open={open}
                            onClose={handleStatusClose}
                            MenuListProps={{
                                'aria-labelledby': 'vendor-status',
                            }}>
                            <MenuItem onClick={(e) => setPickupStatus(e, "ARRIVED_AT_PICKUP_LOCATION")}>Started</MenuItem>
                            {/* <MenuItem onClick={(e) => setPickupStatus(e, "NOT_PICKED_UP")}>Not picked up</MenuItem> */}
                            <MenuItem onClick={(e) => setPickupStatus(e, "PICKED_UP")}>Completed</MenuItem>
                            {/* <MenuItem onClick={(e) => setPickupStatus(e, "REPORTED_ISSUE")}>Reported issue</MenuItem> */}
                        </Menu>
                    </>
                </Stack>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: "0 14px 14px" }}>
                <Box>
                    <Grid container columnSpacing={{ xs: 1 }}>
                        <Grid item width="100%">
                            <PickupDetails
                                pickup={pickup}
                                vendor={pickup.vendor} />
                        </Grid>
                    </Grid>
                </Box>
            </AccordionDetails>
        </Accordion>);
}

export default PickupItem;
