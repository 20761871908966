import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { listDrivers } from "../../distflowAPI/driversApi";
import accountsApi from "../../distflowAPI/accountsApi";
import routeScheduleChangesApi from "../../distflowAPI/routeScheduleChangesApi";
import { enqueueSnackbar } from "notistack";
import { arrayMove } from "@dnd-kit/sortable";
import { signOut } from "./user";
import routesApi from "../../distflowAPI/routesApi";
import dayjs from "dayjs";

const initialState = {
  currentRoute: null,
  note: "",
  selectedDays: {
    is_sunday: false,
    is_monday: false,
    is_tuesday: false,
    is_wednesday: false,
    is_thursday: false,
    is_friday: false,
    is_saturday: false,
  },
  vendors: [],
  vendor_rules: [],
  // Temp storage for notes only
  accounts_calc: [],
  accounts: [],
  allAccounts: [],
  selectedDriverId: null,
  allDrivers: [],
  driverSearchTerm: "",
  accountSearchTerm: "",
  loading: false,
  createLoading: false,
  updateLoading: false,
  formErrors: {},
  isRangeChange: true,
  changeDates: {
    start: new Date().toISOString(),
    end: new Date().toISOString(),
  },
  routeToEdit: null,
};

export const fetchDrivers = createAsyncThunk(
  "addRouteChanges/fetchDrivers",
  () => listDrivers()
);

export const fetchRouteAccounts = createAsyncThunk(
  "addRouteChanges/fetchRouteAccounts",
  () => accountsApi.list(100)
);

// TODO: rename to `createOrUpdateRouteChange`
// or create separate action for `updateRouteChange`
export const createRouteChange = createAsyncThunk(
  "addRouteChanges/createRouteChange",
  async (route, { getState, rejectWithValue }) => {
    const {
      note,
      selectedDriverId,
      selectedDays,
      currentRoute,
      isRangeChange,
      changeDates,
      routeToEdit,
    } = getState().addRouteChanges;

    if (!currentRoute || !currentRoute.id) {
      return rejectWithValue({ error: "A route must be selected" });
    }

    if (changeDates.end < dayjs(new Date()).startOf("day").toISOString()) {
      return rejectWithValue({ error: "End date can't be in past!" });
    }
    if (changeDates.start > changeDates.end) {
      return rejectWithValue({ error: "End date must be after start date!" });
    }

    const accountChanges = [];
    const vendorRulesChanges = [];
    for (const item of route.accounts) {
      if (
        !currentRoute.accounts.some(
          (routeAccount) => routeAccount.account.id === item.account
        )
      ) {
        accountChanges.push({ ...item, type: "ADDED_TO_ROUTE" });
      }
    }

    for (const routeAccount of currentRoute.accounts) {
      if (
        !route.accounts.some((item) => item.account === routeAccount.account.id)
      ) {
        accountChanges.push({
          account: routeAccount.account.id,
          type: "DISABLED",
        });
      }
    }

    for (const rule of route.vendor_rules) {
      const existingRule = currentRoute.vendor_rules.find(
        (r) => r.vendor.id === rule.vendor
      );
      if (!existingRule) {
        vendorRulesChanges.push({
          ...rule,
          type: !rule.pickup_route ? "DISABLED" : "PICKUP_ROUTE",
        });
      }
    }

    try {
      const apiFunc = (data) =>
        routeToEdit
          ? routeScheduleChangesApi.update(routeToEdit.id, data)
          : routeScheduleChangesApi.create(data);
      const response = await apiFunc({
        note,
        driver: Number(selectedDriverId),
        route: currentRoute.id,
        schedule_change_accounts: accountChanges.map((item) => ({
          ...item,
          display_order: item.display_order || 1,
        })),
        schedule_change_vendor_rules: vendorRulesChanges,
        start_date: changeDates.start.substring(0, 10),
        end_date: (isRangeChange
          ? changeDates.end
          : changeDates.start
        ).substring(0, 10),
        ...selectedDays,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchRouteChangeToEdit = createAsyncThunk(
  "addRouteChanges/fetchRouteChangeToEdit",
  async (id, { dispatch }) => {
    const response = await routeScheduleChangesApi.findById(id);
    const routeResponse = await routesApi.findById(response.data.route);
    dispatch(
      setCurrentRoute({
        ...routeResponse.data,
        is_sunday: response.data.is_sunday,
        is_monday: response.data.is_monday,
        is_tuesday: response.data.is_tuesday,
        is_wednesday: response.data.is_wednesday,
        is_thursday: response.data.is_thursday,
        is_friday: response.data.is_friday,
        is_saturday: response.data.is_saturday,
        driver: response.data.driver,
      })
    );
    dispatch(
      setRouteChangeToEdit({
        ...response.data,
        accounts: routeResponse.data.accounts,
        vendor_rules: routeResponse.data.vendor_rules,
      })
    );
    return response.data;
  }
);

export const addRouteChangesSlice = createSlice({
  name: "addRouteChanges",
  initialState,
  reducers: {
    setNote(state, action) {
      state.note = action.payload;
      delete state.formErrors.note;
    },
    setSelectedDays(state, action) {
      for (const dayKey of Object.keys(action.payload)) {
        state.selectedDays[dayKey] = action.payload[dayKey];
      }
      delete state.formErrors.selectedDays;
    },
    setSelectedDriver(state, action) {
      state.selectedDriverId = action.payload.split("-")[1];
      delete state.formErrors.driver;
    },
    removeSelectedDriver(state) {
      state.selectedDriverId = null;
    },
    setDriverSearchTerm(state, action) {
      state.driverSearchTerm = action.payload;
      state.allDrivers = state.allDrivers.map((x) => ({
        ...x,
        show: x.user.first_name
          .toLowerCase()
          .includes(action.payload.toLowerCase()),
      }));
    },
    setAccountSearchTerm(state, action) {
      state.accountSearchTerm = action.payload;
      state.allAccounts = state.allAccounts.map((x) => ({
        ...x,
        show: x.name.toLowerCase().includes(action.payload.toLowerCase()),
      }));
    },
    addNoteToAccount(state, action) {
      const { accountId, note } = action.payload;
      state.allAccounts = state.allAccounts.map((account) => {
        if (account.id === accountId) {
          return { ...account, note };
        }
        return account;
      });
    },
    addAccount(state, action) {
      state.accounts = [...state.accounts, action.payload];
      delete state.formErrors.accounts;
    },
    removeAccount(state, action) {
      state.accounts = state.accounts.filter((item) => item !== action.payload);
    },
    handleAccountsSort(state, action) {
      const { activeId, overId } = action.payload;

      if (activeId === overId) {
        return;
      }
      const oldIndex = state.accounts.indexOf(activeId);
      const newIndex = state.accounts.indexOf(overId);

      state.accounts = arrayMove(state.accounts, oldIndex, newIndex);
    },
    setCurrentRoute(state, action) {
      state.currentRoute = action.payload;
      state.note = action.payload.note || action.payload.route_calc?.note;
      state.selectedDriverId = String(action.payload.driver);
      state.selectedDays = {
        is_sunday: action.payload.is_sunday,
        is_monday: action.payload.is_monday,
        is_tuesday: action.payload.is_tuesday,
        is_wednesday: action.payload.is_wednesday,
        is_thursday: action.payload.is_thursday,
        is_friday: action.payload.is_friday,
        is_saturday: action.payload.is_saturday,
      };

      state.vendor_rules = action.payload.vendor_rules;
      state.accounts_calc = action.payload.accounts_calc;

      state.accounts = (
        action.payload.accounts ||
        action.payload.accounts_calc ||
        []
      )
        .filter((x) => !x.schedule_change)
        .filter((item) => item.type !== "DISABLED")
        .map((item) => `account-${item.account.id || item.account}`);
    },
    setRouteFormErrors(state, action) {
      state.formErrors = action.payload;
      state.createLoading = false;
      state.updateLoading = false;
    },
    setIsRangeChange(state, action) {
      state.isRangeChange = action.payload;
    },
    setChangeDates(state, action) {
      state.changeDates.start = action.payload.start ?? state.changeDates.start;
      state.changeDates.end = action.payload.end ?? state.changeDates.end;
    },
    /** @param {import("@reduxjs/toolkit").PayloadAction<import("./routeChange").RouteChange>} action */
    setRouteChangeToEdit(state, action) {
      state.routeToEdit = action.payload;
      state.changeDates = {
        start: action.payload.start_date,
        end: action.payload.end_date,
      };
      action.payload.schedule_change_accounts.forEach((accountChange) => {
        if (accountChange.type === "ADDED_TO_ROUTE") {
          state.accounts.push(`account-${accountChange.account.id}`);
        } else {
          state.accounts = state.accounts.filter(
            (item) => item !== `account-${accountChange.account.id}`
          );
        }
      });
      state.vendor_rules.push(...action.payload.schedule_change_vendor_rules);
    },
    resetState: () => ({ ...initialState }),
  },
  extraReducers(builder) {
    builder
      .addCase(fetchDrivers.fulfilled, (state, action) => {
        state.allDrivers = action.payload.results.map((x) => ({
          ...x,
          show: true,
        }));
      })
      .addCase(fetchRouteAccounts.fulfilled, (state, action) => {
        state.allAccounts = action.payload.results.map((x) => ({
          ...x,
          show: true,
        }));
      })
      .addCase(createRouteChange.pending, (state) => {
        state.createLoading = true;
      })
      .addCase(createRouteChange.fulfilled, (state) => {
        state.createLoading = false;
        enqueueSnackbar(
          `Route change for "${state.currentRoute.name}" was ${
            state.routeToEdit ? "updated" : "created"
          } successfuly`,
          {
            variant: "success",
          }
        );
      })
      .addCase(createRouteChange.rejected, (state, { payload }) => {
        state.createLoading = false;
        let message = `Error ${
          state.routeToEdit ? "updating" : "creating"
        } Route change`;
        if (payload?.error) {
          message = payload.error;
        }
        enqueueSnackbar(message, { variant: "error" });
      })
      .addCase(signOut, () => initialState);
  },
});

export const {
  setNote,
  setSelectedDays,
  setSelectedDriver,
  removeSelectedDriver,
  setDriverSearchTerm,
  setAccountSearchTerm,
  addNoteToAccount,
  addAccount,
  removeAccount,
  handleAccountsSort,
  setCurrentRoute,
  setRouteFormErrors,
  setIsRangeChange,
  setChangeDates,
  setRouteChangeToEdit,
  resetState,
} = addRouteChangesSlice.actions;

export default addRouteChangesSlice.reducer;
